import React from 'react';

const ModalImage = ({ open, onClose, onConfirm, titleMessage, urlImage, btnCancelText, btnConfirmText }) => {

    if (!open) return null;

    return (
        <div className="popcard-add-wilaya-diver">
            <div className="popcard-add-wilaya-diver_inside">
                <a className="popcard-add-wilaya-diver_inside-close" onClick={onClose}>&#10006;</a>
                <h2 className="popcard-add-wilaya-diver_inside-title">{titleMessage}</h2>
                <div className="popcard-add-wilaya-diver_inside-form">
                    <div className="wrap-info">
                        <div className="wrap-one"
                            style={{
                                backgroundImage: `url(${new URL(urlImage)})`,
                                backgroundPosition: 'center center',
                                backgroundSize: 'cover',
                                height: '30rem',
                                width: '25rem'
                            }}
                        >
                        </div>
                    </div>
                    <div className="popcard-operation-pickup-details_inside-zone">
                        <div className="buttons">
                            {/* <a href="#wilaya-sec" className="btn-annuler" onClick={onClose}>{btnCancelText}</a> */}
                            <input type="submit" value={btnConfirmText} className="btn-submit-form-new" onClick={onConfirm} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ModalImage;
