import React, { useEffect, useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import { useFormik } from 'formik';
import { CustomToast } from '../../components/Toast/CustomToast';
import ConfirmationDialog from '../../components/Confirmation/ConfirmationDialog';
import { axiosInstance, Proprietaire } from '../../services/api';
import { AuthContext } from '../../contexts/AuthContext';

const ListProprietaires = () => {
    const { authData } = useContext(AuthContext);

    const [proprietaires, setProprietaires] = useState([]);
    const [filtredProprietaires, setFiltredProprietaires] = useState([]);
    const [pageNumber, setPageNumber] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalItems, setTotalItems] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [loading, setLoading] = useState(true);
    const [idProprietaireDelete, setIdProprietaireDelete] = useState('');
    const [idProprietaireDetails, setIdProprietaireDetails] = useState('');

    const [filters, setFilters] = useState({
        statut: null,
        raisonSociale: null,
        type: null
    });

    const [search, setSearch] = useState("");

    const [showDetailsModal, setShowDetailsModal] = useState(false);
    const [confirmDialogOpenDelete, setConfirmDialogOpenDelete] = useState(false);
    const [confirmDialogOpenArchive, setConfirmDialogOpenArchive] = useState(false);

    //Table management
    const [sortBy, setSortBy] = useState(''); // Column name to sort by
    const [sortOrder, setSortOrder] = useState('asc'); // 'asc' or 'desc'

    const handleSort = (column) => {
        setSortBy(column);
        setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');

        let sortedTable = [...filtredProprietaires].sort((a, b) => {
            const aValue = a[sortBy] || '';
            const bValue = b[sortBy] || '';

            if (sortOrder === 'asc') {
                return aValue.localeCompare(bValue);
            } else {
                return bValue.localeCompare(aValue);
            }
        });

        setFiltredProprietaires(sortedTable);
    };

    const [openAboutList, setOpenAboutList] = useState(null);

    const toggleAboutList = (idMarque) => {
        // console.log(idMarque)
        setOpenAboutList(openAboutList === idMarque ? null : idMarque);
    };

    const handlePageChange = (page) => {
        setPageNumber(page);
        //consolelog("setPageNumber="+pageNumber)
    };

    const handlePerRowsChange = (newPerPage) => {
        setPageSize(newPerPage);
        //console.log("setPageSize="+pageSize);
    };

    const showHideDetailsModal = (val) => {
        setShowDetailsModal(val);
        formik.resetForm();
        //console.log(idProprietaireDetails)
        if (val) {
            getProprietaire(idProprietaireDetails);
        } else {
            setIdProprietaireDetails('');
        }
        handleCancelDelete();
    };

    //Supprimer proprietaire
    const deleteProprietaire = async (idProprietaire) => {
        try {
            const response = await axiosInstance.delete(`${Proprietaire}/${idProprietaire}`);
            getProprietaires(pageNumber, pageSize);
            CustomToast('Le propriétaire a été supprimé avec succès', 'success');
        }
        catch (error) {
            if (!error?.response) {
                CustomToast("Aucune réponse du serveur", 'error');
            } else if (error.response?.status === 400 && error.response?.data === "Propriétaire already used") {
                CustomToast("Véhicule déjà utilisé", 'error');
            } else if (error.response?.status === 401) {
                CustomToast("Non autorisé", 'error');
            } else {
                CustomToast("Demande échoué", 'error');
            }
            console.log(error);
        }
    }

    const handleConfirmDelete = (idProprietaire) => {
        // Show the modal when the confirm delete button is clicked
        //deleteProprietaire(idProprietaire);
        setIdProprietaireDelete(idProprietaire);
        setIdProprietaireDetails(idProprietaire);
        setConfirmDialogOpenDelete(true);
    };

    const handleDelete = async () => {
        try {
            await axiosInstance.delete(`${Proprietaire}/${idProprietaireDelete}`);
            setIdProprietaireDelete('');
            setIdProprietaireDetails('');
            CustomToast('Le propriétaire a été supprimé avec succès', 'success');
            getProprietaires(pageNumber, pageSize);
            setConfirmDialogOpenDelete(false);
        }
        catch (error) {
            // Handle error
            if (!error?.response) {
                CustomToast("Aucune réponse du serveur", 'error');
            } else if (error.response?.status === 400 && error.response?.data === "Propriétaire already used") {
                // CustomToast("Propriétaire déjà utilisé", 'error');
                CustomToast(<div>Ce propriétaire est en cours d'utilisation et ne peut pas être supprimé. <a className='act' style={{ textDecoration: 'underline' }} onClick={() => showHideDetailsModal(true)}>Afficher l'utilisation</a></div>, 'error');
            } else if (error.response?.status === 401) {
                CustomToast("Non autorisé", 'error');
            } else {
                CustomToast("Demande échoué", 'error');
            }
            console.log(error);
        }
    }

    const handleCancelDelete = () => {
        setConfirmDialogOpenDelete(false);
    };

    //archiver proprietaire
    const archiveProprietaire = async (idProprietaire) => {
        try {

            const request = {
                idProprietaire: idProprietaire,
                idUtilisateur: authData.idUser
            }
            const response = await axiosInstance.put(`${Proprietaire}/archive`, request);
            getProprietaires(pageNumber, pageSize);
            CustomToast('Le propriétaire a été archivé avec succès', 'success');
        }
        catch (err) {
            console.log(err);
        }
    }

    const handleConfirmArchive = (idProprietaire) => {
        // Show the modal when the confirm delete button is clicked
        setIdProprietaireDelete(idProprietaire);
        setConfirmDialogOpenArchive(true);
    };

    const handleArchive = async () => {
        try {
            const request = {
                idProprietaire: idProprietaireDelete,
                idUtilisateur: authData.idUser
            }
            const response = await axiosInstance.put(`${Proprietaire}/archive`, request);
            setIdProprietaireDelete('');
            CustomToast('Le propriétaire a été archivé avec succès', 'success');
            getProprietaires(pageNumber, pageSize);
            setConfirmDialogOpenArchive(false);
        }
        catch (error) {
            // Handle error
            if (!error?.response) {
                CustomToast("Aucune réponse du serveur", 'error');
            } else if (error.response?.status === 400 && error.response?.data === "Proprietaire does not exist") {
                CustomToast("Le propriétaire n'existe pas", 'error');
            } else if (error.response?.status === 401) {
                CustomToast("Non autorisé", 'error');
            } else {
                CustomToast("Demande échoué", 'error');
            }
            console.log(error);
        }
    }

    const handleCancelArchive = () => {
        setConfirmDialogOpenArchive(false);
    };

    //restaurer propriétaire
    const restoreVehicule = async () => {
        try {

            const request = {
                idProprietaire: idProprietaireDelete,
                idUtilisateur: authData.idUser
            }
            const response = await axiosInstance.put(`${Proprietaire}/restore`, request);
            getProprietaires(pageNumber, pageSize);
            CustomToast('Le propriétaire a été restauré avec succès', 'success');
        }
        catch (err) {
            console.log(err);
        }
    }

    const handleConfirmRestore = (idProprietaire) => {
        // Show the modal when the confirm delete button is clicked
        restoreVehicule(idProprietaire);
        setIdProprietaireDelete(idProprietaire);
    };

    const handleRestore = async () => {
        try {
            const request = {
                idVehicule: idProprietaireDelete,
                idUtilisateur: authData.idUser
            }
            const response = await axiosInstance.put(`${Proprietaire}/restore`, request);
            setIdProprietaireDelete('');
            CustomToast('Le propriétaire a été restauré avec succès', 'success');
            getProprietaires(pageNumber, pageSize);
        }
        catch (error) {
            // Handle error
            if (!error?.response) {
                CustomToast("Aucune réponse du serveur", 'error');
            } else if (error.response?.status === 400 && error.response?.data === "Proprietaire does not exist") {
                CustomToast("Le propriétaire n'existe pas", 'error');
            } else if (error.response?.status === 401) {
                CustomToast("Non autorisé", 'error');
            } else {
                CustomToast("Demande échoué", 'error');
            }
            console.log(error);
        }
    }

    //Load data

    const formik = useFormik({
        initialValues: {
            countVehicule: 0,
            countEmploye: 0
        }
    });

    const getProprietaires = async (page, newPerPage) => {
        try {
            setLoading(true);
            const queryParams = new URLSearchParams({
                pageNumber: page,
                pageSize: newPerPage
            });

            if (filters.statut != null) {
                queryParams.append("statut", filters.statut);
            }

            if (filters.raisonSociale != null) {
                queryParams.append("raisonSociale", filters.raisonSociale);
            }

            if (filters.type != null) {
                queryParams.append("type", filters.type);
            }

            const response = await axiosInstance.get(`${Proprietaire}?${queryParams}`);
            const resp = await response.data;
            setProprietaires(resp.data);
            if (search != '') {
                const result = proprietaires?.filter((item) =>
                    Object.values(item).some(
                        (value) =>
                            value &&
                            value.toString().toLowerCase().includes(search.toLowerCase())
                    )
                );
                setFiltredProprietaires(result);
            } else {
                setFiltredProprietaires(resp.data);
            }
            //setFiltredProprietaires(resp.data);
            setPageNumber(resp.pageNumber);
            setPageSize(resp.pageSize);
            setTotalItems(resp.totalItems);
            setTotalPages(resp.totalPages);
            setLoading(false);
        }
        catch (err) {
            setLoading(false);
            console.log(err);
        }
    }

    const getProprietaire = async (idProprietaire) => {
        try {
            const queryParams = new URLSearchParams({
                idProprietaire: idProprietaire
            });
            const response = await axiosInstance.get(`${Proprietaire}/GetProprietaireUsage?${queryParams}`);
            const resp = await response.data;
            // formik.values.countVehicule = resp[0].countVehicule;
            // formik.values.countEmploye = resp[0].countEmploye;

            formik.setValues((prevValues) => ({
                ...prevValues,
                countVehicule: resp[0].countVehicule || 0,
                countEmploye: resp[0].countEmploye || 0,
            }));

        }
        catch (err) {
            console.log(err);
        }
    }

    useEffect(() => {
        //Load Data

        getProprietaires(pageNumber, pageSize);
        return () => {
            // Perform any necessary cleanup (e.g., cancel pending requests)
        };
    }, [authData, pageNumber, pageSize, filters]);

    useEffect(() => {
        // const result=vehicules.filter(row=>{
        //     return row.vehiculeNom && row.vehiculeNom.toLowerCase().match(search.toLowerCase());   
        // })
        const result = proprietaires.filter((item) =>
            Object.values(item).some(
                (value) =>
                    value &&
                    value.toString().toLowerCase().includes(search.toLowerCase())
            )
        );
        setFiltredProprietaires(result);
    }, [search])

    return (
        <section className="donnee-section" onClick={() => { if (openAboutList !== null) { setOpenAboutList(null) } }}>
            <div className="container">
                <div className="propretaire-table wrap-all">
                    <Link to="/ajouterproprietaire" className="fournisseur-add color-primary-2 decoration-none">
                        <div className="add-new" id="add-vehc">
                            <span className="material-symbols-outlined">add</span>
                            Ajouter un propriétaire
                        </div>
                    </Link>

                    {filtredProprietaires?.length > 0 && <><div className="group-elements">
                        <div className="voir-elements">
                            <p></p>
                            <select
                                name="pageSize"
                                id="pageSize"
                                onChange={(e) => { handlePerRowsChange(e.target.value || 10); setPageNumber(1); }}
                            >
                                <option value="10" defaultValue={true}>10</option>
                                <option value="15">15</option>
                                <option value="20">20</option>
                            </select>
                        </div>
                        {/* <div className="search-element">
                            <p>Rechercher</p>
                            <input type="text" />
                        </div> */}
                    </div>
                        <div className="table-wrap">
                            <table>
                                <thead>
                                    <tr>
                                        <th onClick={() => handleSort('raisonSociale')}>Raison sociale</th>
                                        <th onClick={() => handleSort('statut')}>Statut</th>
                                        <th>Type</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {filtredProprietaires?.map((row) => (
                                        <tr key={row.idProprietaire}>
                                            <td>
                                                {
                                                    row.typeProprietaire === "freelancer" ? (
                                                        <>{row.nom + ' ' + row.prenom}</>
                                                    ) : (
                                                        <>{row.raisonSociale}</>
                                                    )
                                                }
                                            </td>
                                            <td>
                                                {
                                                    row.statut === "actif" ? (
                                                        <>
                                                            <svg height="11.87" width="15"><circle cx="6" cy="6" r="6" fill="green" /></svg>Actif
                                                        </>
                                                    ) : row.statut === "inactif" ? (
                                                        <>
                                                            <svg height="11.87" width="15"><circle cx="6" cy="6" r="6" fill="red" /></svg>Inactif
                                                        </>
                                                    ) : row.statut === "archivé" ? (
                                                        <>
                                                            <svg height="11.87" width="15"><circle cx="6" cy="6" r="6" fill="grey" /></svg>Archivé
                                                        </>
                                                    ) : row.statut === "suspendu" ? (
                                                        <>
                                                            <svg height="11.87" width="15"><circle cx="6" cy="6" r="6" fill="blue" /></svg>Suspendu
                                                        </>
                                                    ) : row.statut === "licencié" ? (
                                                        <>
                                                            <svg height="11.87" width="15"><circle cx="6" cy="6" r="6" fill="red" /></svg>Licencié
                                                        </>
                                                    ) : null
                                                }
                                            </td>
                                            <td>
                                                {
                                                    row.typeProprietaire === "entreprise" ? (
                                                        <>Entreprise</>
                                                    ) : row.typeProprietaire === "freelancer" ? (
                                                        <>Freelancer</>
                                                    ) : row.typeProprietaire === "location" ? (
                                                        <>Location</>
                                                    ) : null
                                                }
                                            </td>
                                            <td className="txt-center relative">
                                                <span className="material-symbols-outlined about-btn" onClick={() => toggleAboutList(row.idProprietaire)}>
                                                    more_horiz
                                                </span>
                                                {openAboutList === row.idProprietaire && (
                                                    <div className="about-list">
                                                        <ul>
                                                            <li><Link className='row row-center' to={`/modifierproprietaire/${row.idProprietaire}`} style={{ textDecoration: "none" }}>
                                                                <span className="material-symbols-outlined">edit</span>Modifier
                                                            </Link></li>

                                                            <li><Link className='row row-center' to="/listproprietaires" style={{ textDecoration: "none" }} onClick={() => handleConfirmDelete(row.idProprietaire)}>
                                                                <span className="material-symbols-outlined">delete</span>Supprimer
                                                            </Link></li>

                                                            {(row.statut != "archivé") && <li><Link className='row row-center' to="/listproprietaires" style={{ textDecoration: "none" }} onClick={() => handleConfirmArchive(row.idProprietaire)}>
                                                                <span className="material-symbols-outlined">archive</span>Archiver
                                                            </Link></li>}

                                                            {(row.statut == "archivé") && <li><Link className='row row-center' to="/listproprietaires" style={{ textDecoration: "none" }} onClick={() => handleConfirmRestore(row.idProprietaire)}>
                                                                <span className="material-symbols-outlined">restore</span>Restaurer
                                                            </Link></li>}
                                                        </ul>
                                                    </div>
                                                )}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        <div className="pagination">
                            <Link to="#" onClick={() => handlePageChange(pageNumber - 1)}
                                className={(pageNumber === 1) ? 'pgn disabled' : 'pgn'}
                                disabled={(pageNumber === 1) ? true : false}
                            >&laquo;</Link>
                            {Array.from({ length: Math.min(5, totalPages) }, (_, i) => {
                                const page = pageNumber - 2 + i;
                                return page <= totalPages && page > 0 && (
                                    <Link
                                        key={page}
                                        to="#"
                                        className={pageNumber === page ? 'pgn active' : 'pgn'}
                                        onClick={() => handlePageChange(page)}
                                    >
                                        {page}
                                    </Link>
                                );
                            })}
                            <Link to="#" onClick={() => handlePageChange(pageNumber + 1)}
                                className={(pageNumber === totalPages || totalPages === 0) ? 'pgn disabled' : 'pgn'}
                                disabled={(pageNumber === totalPages || totalPages === 0) ? true : false}
                            >&raquo;</Link>
                            <span> Affichage de {(pageNumber === 1)?1:((pageNumber - 1) * pageSize+1)} à {pageNumber * pageSize} sur {totalItems} entrées</span>
                        </div>
                        </>}
                </div>
                <ConfirmationDialog
                    open={confirmDialogOpenDelete}
                    onClose={handleCancelDelete}
                    onConfirm={handleDelete}
                    titleMessage="Confirmer la suppression du propriètaire"
                    bodyMessage={"Voulez-vous vraiment supprimer ce propriètaire ? Toutes les données seront définitivement perdues."}
                    btnCancelText="Annuler"
                    btnConfirmText="Supprimer le propriètaire"
                />
                <ConfirmationDialog
                    open={confirmDialogOpenArchive}
                    onClose={handleCancelArchive}
                    onConfirm={handleArchive}
                    titleMessage="Confirmer l'archivage du propriètaire"
                    bodyMessage={"Voulez-vous vraiment archiver cet enregistrement ? L'archivage d'un enregistrement ne le supprime pas de <i>FleetGo</i>, mais il ne sera plus utilisable."}
                    btnCancelText="Annuler"
                    btnConfirmText="Archiver le propriètaire"
                />

                {showDetailsModal && <div className="popcard-add">
                    <div className="popcard-add_inside">
                        <Link className="popcard-add_inside-close" to="#" onClick={() => setShowDetailsModal(false)}>&#10006;</Link>
                        <h2 className="popcard-add_inside-title">Utilisation du propriétaire</h2>
                        <div className="popcard-add_inside-form">
                            <div className="wrap-info">
                                <div className="wrap-one">
                                    <label>Véhicule(s) : {formik.values.countVehicule}</label>
                                </div>
                                <div className="wrap-one">
                                    <label>Employé(s) : {formik.values.countEmploye}</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>}
            </div>
        </section>
    );
}

export default ListProprietaires;
