import React, { useEffect, useState, useContext } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import moment from 'moment/moment';
import { CustomToast } from '../../components/Toast/CustomToast';
import { axiosInstance, Vehicule, VehiculeCompteur } from '../../services/api';
import { AuthContext } from '../../contexts/AuthContext';

const ModifierCompteur = () => {
    const { authData } = useContext(AuthContext);
    const navigate = useNavigate();
    const { id } = useParams();//idHstoriqueVehiculeCompteur

    const [isLoaded, setIsLoaded] = useState();
    const [kiloDebLess, setKiloDebLess] = useState({ display: false, msg: "" });
    const [kiloDebGreater, setKiloDebGreater] = useState({ display: false, msg: "" });
    const [kiloDebBetween, setKiloDebBetween] = useState({ display: false, msg: "" });

    //Load data  
    const getVehicules = async (idVehicule) => {
        try {
            //console.log("getVehicules")
            //if(idVehicule==''){return}
            const response = await axiosInstance.get(`${Vehicule}/GetById/${idVehicule}`);
            const resp = await response.data;

            setFieldValue('vehicule', resp.nom + ' ' + resp.immatriculation);
        }
        catch (err) {
            console.log(err);
        }
    }

    const getCompteur = async (idHstoriqueVehiculeCompteur) => {
        try {
            const response = await axiosInstance.get(`${VehiculeCompteur}/${idHstoriqueVehiculeCompteur}`);
            const resp = await response.data;
            setIsLoaded(resp);
            // values.idVehicule = resp.idVehicule;           
            // values.dateRemplissage = moment(resp.dateRemplissage).format('YYYY-MM-DD');
            // values.valeur = resp.valeur;
            // values.source = resp.source;

            // setFieldValue('idVehicule', resp.idVehicule);
            // values.vehicule = '';
            setValues(prevValues => ({
                ...prevValues,
                vehicule: '',
                idVehicule: resp.idVehicule || '',
                valeur: resp.valeur || '',
                dateRemplissage: moment(resp.dateRemplissage).isValid() ? moment(resp.dateRemplissage).format('YYYY-MM-DD') : '' || '',
                source: resp.source || 'manuel',
            }));
            //console.log(values)
        }
        catch (error) {
            // Handle error
            console.log(error);
            //navigate("/listevehicules", { replace: true });
        }
    }


    const { handleChange, values, handleSubmit, touched, errors, setFieldValue, setValues } = useFormik({
        initialValues: {
            vehicule: '',
            idVehicule: '',
            valeur: '',
            dateRemplissage: '',
            source: 'manuel',
        },
        validationSchema: Yup.object({
            dateRemplissage: Yup.string()
                .required("Champ obligatoire"),
            valeur: Yup.number()
                .required('Champ obligatoire')
                .min(0, "Valeur de compteur ne peut pas être négative"),
        }),
        onSubmit: async (values) => {
            try {
                //console.log(values)
                const postData = {
                    idHstoriqueVehiculeCompteur: id,
                    idVehicule: values.idVehicule,
                    dateRemplissage: values.dateRemplissage,
                    valeur: values.valeur.toString(),
                    source: values.source,
                    idUtilisateur: authData.idUser,
                };

                //const response = await axiosInstance.put(VehiculeCompteur, postData);
                CustomToast("L'entrée de compteur a été modifiée avec succès", 'success');
                navigate('/listcompteurs', { replace: true });
            } catch (error) {
                // Handle error
                if (!error?.response) {
                    CustomToast("Aucune réponse du serveur", 'error');
                    setKiloDebGreater({ display: false, msg: "" });
                    setKiloDebLess({ display: false, msg: "" });
                    setKiloDebBetween({ display: false, msg: "" });
                } else if (error.response?.status === 400 && error.response?.data === "Vehicle not found") {
                    CustomToast("Véhicule introuvable", 'error');
                    setKiloDebGreater({ display: false, msg: "" });
                    setKiloDebLess({ display: false, msg: "" });
                    setKiloDebBetween({ display: false, msg: "" });

                } else if (error.response?.status === 400 && error.response?.data?.msg === "Incorrect value, must be greater") {
                    CustomToast("L'entrée de compteur de véhicule n'a pas pu être modifiée", 'error');
                    setKiloDebGreater({ display: true, msg: `Doit être supérieur à ${error.response?.data?.val1}` });
                    setKiloDebLess({ display: false, msg: "" });
                    setKiloDebBetween({ display: false, msg: "" });
                } else if (error.response?.status === 400 && error.response?.data?.msg === "Incorrect value, must be less") {
                    CustomToast("L'entrée de compteur de véhicule n'a pas pu être modifiée", 'error');
                    setKiloDebLess({ display: true, msg: `Doit être inférieur à ${error.response?.data?.val1}` });
                    setKiloDebGreater({ display: false, msg: "" });
                    setKiloDebBetween({ display: false, msg: "" });
                } else if (error.response?.status === 400 && error.response?.data?.msg === "Incorrect value, must be between") {
                    CustomToast("L'entrée de compteur de véhicule n'a pas pu être modifiée", 'error');
                    setKiloDebBetween({ display: true, msg: `Doit être entre ${error.response?.data?.val1} et ${error.response?.data?.val2}` });
                    setKiloDebGreater({ display: false, msg: "" });
                    setKiloDebLess({ display: false, msg: "" });
                } else if (error.response?.status === 401) {
                    CustomToast("Non autorisé", 'error');
                    setKiloDebGreater({ display: false, msg: "" });
                    setKiloDebLess({ display: false, msg: "" });
                    setKiloDebBetween({ display: false, msg: "" });
                } else {
                    CustomToast("Demande échoué", 'error');
                    setKiloDebGreater({ display: false, msg: "" });
                    setKiloDebLess({ display: false, msg: "" });
                    setKiloDebBetween({ display: false, msg: "" });
                }
                console.log(error);
            }
        }
    });

    useEffect(() => {
        getCompteur(id);
    }, [id]);

    useEffect(() => {
        // console.log("depenseLoaded");
        if (values.idVehicule != '' && values.vehicule == '') {
            getVehicules(values.idVehicule);
        }

    }, [isLoaded])

    // if (values.idVehicule != '' && values.vehicule == '') {
    //     //console.log("useEffect 1");
    //     getVehicules(values.idVehicule);
    // }

    return (
        <section className="vehicles-section">
            <div className="container">
                <form onSubmit={handleSubmit} className="compteur-form">
                    <div className="">
                        <div className="grid grid--2-cols">
                            <div className="form-sec">
                                <div className="retour" id="retour-compteur">
                                    <Link to="/listcompteurs" style={{ textDecoration: "none" }}>
                                        <div className="arrow-back" id="arrow-back-affectation-form">
                                            <span className="material-symbols-outlined" id="arrow">arrow_back</span>
                                            <h3 className="heading-primary"></h3>
                                        </div>
                                    </Link>
                                </div>
                                {isLoaded && <div className="form-details">

                                    <div className="detail-form" id="identification-form">
                                        <div className="col">
                                            <div className="grp">
                                                <div className="gp">
                                                    <label>Véhicule<span className='requiredField'>*</span></label>
                                                    <input
                                                        id="vehicule"
                                                        name="vehicule"
                                                        type='text'
                                                        defaultValue={values.vehicule}
                                                        disabled={true}
                                                    />
                                                </div>
                                            </div>
                                            <div className="grp">

                                                <div className="gp">
                                                    <label>Compteur<span className='requiredField'>*</span></label>
                                                    <input
                                                        type="number"
                                                        id="valeur"
                                                        name="valeur"
                                                        placeholder=""
                                                        defaultValue={values.valeur}
                                                        onChange={handleChange}
                                                        step="any"
                                                    />
                                                    {errors.valeur && touched.valeur && (
                                                        <div className="invalid-feedback">{errors.valeur}</div>
                                                    )}
                                                    {kiloDebLess.display && (
                                                        <div className="invalid-feedback">{kiloDebLess.msg}</div>
                                                    )}
                                                    {kiloDebGreater.display && (
                                                        <div className="invalid-feedback">{kiloDebGreater.msg}</div>
                                                    )}
                                                    {kiloDebBetween.display && (
                                                        <div className="invalid-feedback">{kiloDebBetween.msg}</div>
                                                    )}
                                                </div>
                                                <div className="gp">
                                                    <label>Date de lecture<span className='requiredField'>*</span></label>
                                                    <input type="date"
                                                        id="dateRemplissage"
                                                        name="dateRemplissage"
                                                        style={(touched.dateRemplissage && errors.dateRemplissage) ? { borderColor: "red" } : null}
                                                        placeholder=""
                                                        defaultValue={values.dateRemplissage}
                                                        onChange={handleChange}
                                                    />
                                                    {touched.dateRemplissage && errors.dateRemplissage ? (
                                                        <div className="invalid-feedback">{errors.dateRemplissage}</div>
                                                    ) : null}
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="submit-container">
                                        <button type="submit" className="submit-btn">Enregistrer</button>
                                    </div>
                                </div>}
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </section>
    );
}

export default ModifierCompteur;
