import jsPDF from "jspdf";
import "jspdf-autotable";
import moment from 'moment/moment';
import { formatCurrency } from '../../../utils/Utils'

const ExportEntryToPdf = (dataJson, fileName, uniteDistance = '', uniteMonnei = '') => {
    if (!dataJson) return;

    const doc = new jsPDF({
        orientation: 'portrait',
        unit: 'mm',
        format: 'a4',
        putOnlyUsedFonts: true,
        compress: false
    });

    const totalPages = [];

    // Function to add page numbers to the footer
    const addPageNumbers = (data) => {
        const pageHeight = doc.internal.pageSize.height || 297; // A4 size by default
        const pageWidth = doc.internal.pageSize.width || 210; // A4 size by default
        const pageNumber = doc.internal.getNumberOfPages();

        doc.setFontSize(8);
        doc.setTextColor(0, 0, 0);
        doc.text(`Page ${pageNumber} sur ${totalPages.length}`, pageWidth - 30, pageHeight - 8);
    };

    doc.setFontSize(12);
    doc.setFont('helvetica', 'bold');
    doc.text("DÉTAILS D’ENTRÉE AU SERVICE", 10, 30);

    doc.setLineWidth(0.5);
    doc.line(10, 32, doc.internal.pageSize.width - 10, 32);

    doc.setFont('helvetica', 'bold');
    doc.setFontSize(8);
    doc.text("Véhicule: ", 10, 40);
    doc.setFont('helvetica', 'normal');
    doc.setFontSize(8);
    doc.text(`${dataJson.marqueVehicule}[${dataJson.modeleVehicule}]`, 23, 40);
    doc.text(`${dataJson.immatriculationVehicule}`, 23, 45);

    doc.setFont('helvetica', 'bold');
    doc.setFontSize(8);
    doc.text("Date de début: ", 10, 50);
    doc.setFont('helvetica', 'normal');
    doc.setFontSize(8);
    doc.text(`${moment(dataJson.dateDebutReelTravail).isValid()?moment(dataJson.dateDebutReelTravail).format('DD-MM-YYYY'):''}`, 30, 50);

    doc.setFont('helvetica', 'bold');
    doc.setFontSize(8);
    doc.text("Date d'achèvement: ", 10, 55);
    doc.setFont('helvetica', 'normal');
    doc.setFontSize(8);
    doc.text(`${moment(dataJson.dateFinReelTravail).isValid()?moment(dataJson.dateFinReelTravail).format('DD-MM-YYYY'):''}`, 37, 55);

    doc.setFont('helvetica', 'bold');
    doc.setFontSize(8);
    doc.text(`Compteur: `, 65, 55);
    doc.setFont('helvetica', 'normal');
    doc.setFontSize(8);
    doc.text((dataJson.valeuCompteur?dataJson.valeuCompteur:'') + ' ' + uniteDistance, 80, 55);

    doc.setFont('helvetica', 'bold');
    doc.setFontSize(8);
    doc.text("Fournisseur: ", 10, 60);
    doc.setFont('helvetica', 'normal');
    doc.setFontSize(8);
    doc.text(dataJson.fournisseur?dataJson.fournisseur:'', 28, 60);

    doc.setFontSize(12);
    doc.setFont('helvetica', 'bold');
    doc.text("TÂCHES", 10, 80);

    doc.setLineWidth(0.5);
    doc.line(10, 82, doc.internal.pageSize.width - 10, 82);


    let rows = dataJson.listeItems?.map((item) => [item.libelleTacheService, item.coutMainOeuvre, item.coutPiecesRechange, item.coutTotal]);

    doc.autoTable({
        head: [["Tâches", "Main d'oeuvre", "Pièces", "Total"]],
        body: rows,
        startY: 90,
        //startX: 10,
        theme: 'grid',
        headStyles: {
            //fillColor: [241, 196, 15,0],
            textColor: "black",
            fontSize: 10,
            fontStyle: 'normal',
        },
        footStyles: {
            //fillColor: [241, 196, 15],
            fontSize: 10,
        },
        bodyStyles: {
            //fillColor: [52, 73, 94],
            textColor: "black",
            fontSize: 8
        },
        alternateRowStyles: {
            //fillColor: [74, 96, 117],
        },
        columnStyles: {//style pour cell
            0: {
                cellWidth: 96,
                halign: 'left'
            },
            1: {
                cellWidth: 30,
                halign: 'right'
            },
            2: {
                cellWidth: 30,
                halign: 'right'
            },
            3: {
                cellWidth: 30,
                halign: 'right'
            }
        },
        allSectionHooks: true,
        didDrawPage: function (data) {
            totalPages.push(1);
            addPageNumbers(data);
        }
    });

    const tableHeight = doc.lastAutoTable.finalY;

    doc.setFont('helvetica', 'bold');
    doc.setFontSize(10);
    doc.text('NOTES GÉNÉRALES', 10, tableHeight + 10);

    var textLines = doc.splitTextToSize(dataJson.remarqesGenerale?dataJson.remarqesGenerale:'', 100);
    var yPosition = tableHeight + 15; // initial y-position
    for (var i = 0; i < textLines.length; i++) {
        doc.setFont('helvetica', 'normal');
        doc.setFontSize(8);
        doc.text(textLines[i], 10, yPosition);
        yPosition += 5; // Adjust the y-position for the next line
    }

    doc.setFont('helvetica', 'bold');
    doc.setFontSize(10);
    doc.text('RÉSUMÉ DES CÔTES', 100, tableHeight + 10);

    doc.setLineWidth(0.5);
    doc.line(100, tableHeight + 12, doc.internal.pageSize.width - 10, tableHeight + 12);

    doc.setFont('helvetica', 'bold');
    doc.setFontSize(8);
    doc.text("Main-d'œuvre", 100, tableHeight + 15);

    doc.setFont('helvetica', 'normal');
    doc.setFontSize(8);
    doc.text(dataJson.coutMainOeuvre + ' ' + uniteMonnei, 160, tableHeight + 15);

    doc.setFont('helvetica', 'bold');
    doc.setFontSize(8);
    doc.text("Pièces", 100, tableHeight + 20);

    doc.setFont('helvetica', 'normal');
    doc.setFontSize(8);
    doc.text(dataJson.coutPiecesRechange + ' ' + uniteMonnei, 160, tableHeight + 20);

    doc.setFont('helvetica', 'bold');
    doc.setFontSize(8);
    doc.text("Total", 100, tableHeight + 25);

    doc.setFont('helvetica', 'normal');
    doc.setFontSize(8);
    doc.text(dataJson.coutTotal + ' ' + uniteMonnei, 160, tableHeight + 25);

    // console.log(tableHeight)

    doc.output('dataurlnewwindow');
    //doc.save(`${fileName}.pdf`);

};

export { ExportEntryToPdf };