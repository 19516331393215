import React, { useEffect, useState, useRef, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { CustomToast } from '../../components/Toast/CustomToast';
import { axiosInstance, ModeleInspection } from '../../services/api';
import { AuthContext } from '../../contexts/AuthContext';
const AjouterFormulaire = () => {
    const { authData } = useContext(AuthContext);
    const navigate = useNavigate();

    const [details, setDetails] = useState(true);
    const [controle, setControle] = useState(false);

    const [detailsClass, setDetailsClass] = useState("line act");
    const [controleClass, setControleClass] = useState("line");

    const showDetails = () => {
        setDetails(true);
        setControle(false);

        setDetailsClass("line act");
        setControleClass("line");
    };

    const showControle = () => {
        setDetails(false);
        setControle(true);

        setDetailsClass("line");
        setControleClass("line act");
    };

    //Liste des éléménts d'inspection

    const formik1 = useFormik({
        initialValues: {
            titre: '',
            description: '',
            typeModele: '',
            statutModele: 'actif',
            items: [],
        },
        validationSchema: Yup.object({
            titre: Yup.string()
                .required("Champ obligatoire"),
        }),
        onSubmit: async (values) => {
            try {

                if (items.length === 0) {
                    CustomToast("Aucun élément d'inspection n'a été ajouté", 'error');
                    return
                }

                const transformedItems = items.map((item) => ({
                    itemTitre: item.itemTitre,
                    description: item.descriptionItem,
                    itemOblogatoire: item.itemOblogatoire,
                    pathImageItemOblogatoire: item.pathImageItemOblogatoire,
                    observationItemOblogatoire: item.observationItemOblogatoire
                }));

                const postData = {
                    titre: values.titre,
                    description: values.description,
                    typeModele: values.typeModele,
                    statutModele: values.statutModele,
                    items: transformedItems,
                    idUtilisateur: authData.idUser,
                };

                //console.log(postData);

                const response = await axiosInstance.post(ModeleInspection, postData);

                CustomToast("Le formulaire d'inspection a été créé avec succès", 'success');
                navigate('/listformulaires', { replace: true });
            } catch (error) {
                // Handle error
                if (!error?.response) {
                    CustomToast("Aucune réponse du serveur", 'error');
                } else if (error.response?.status === 400 && error.response?.data === "Template existe") {
                    CustomToast("Template existe", 'error');
                } else if (error.response?.status === 400 && error.response?.data === "Inspection form items are required.") {
                    CustomToast("Inspection form items are required.", 'error');
                } else if (error.response?.status === 401) {
                    CustomToast("Non autorisé", 'error');
                } else {
                    CustomToast("Demande échoué", 'error');
                }
                //CustomToast(error.message, 'error');
                console.log(error);
            }
        }
    });

    const [formData, setFormData] = useState({ itemTitre: '', descriptionItem: '', itemOblogatoire: false, pathImageItemOblogatoire: false, observationItemOblogatoire: false });

    const [items, setItems] = useState([]);

    const [errorItem, setErrorItem] = useState({ error: false, message: '' });

    const addItem = (newItem) => {
        setItems([...items, newItem]);
    };

    const deleteItem = (itemIndex) => {
        const updatedItems = items.filter((item, index) => index !== itemIndex);
        setItems(updatedItems);
    };

    const updateItem = (itemIndex, updatedItem) => {
        const updatedItems = items.map((item, index) =>
            index === itemIndex ? { ...item, ...updatedItem } : item
        );
        setItems(updatedItems);
    };

    const handleInputChange = (event) => {
        const { name, value, type, checked } = event.target;
        const inputValue = type === 'checkbox' ? checked : value;
        setFormData({ ...formData, [name]: inputValue });
    };

    const handleAddElement = (event) => {
        event.preventDefault();

        if (!formData.itemTitre) {
            setErrorItem({ error: true, message: 'Champ obligatoire' });
            return;
        }

        const existingItem = items.find((item) => item.itemTitre === formData.itemTitre);
        if (existingItem) {
            setErrorItem({ error: true, message: 'Un élément avec le même titre existe déjà. Veuillez choisir un autre titre' });
            return;
        }

        addItem(formData);
        setFormData({ itemTitre: '', descriptionItem: '', itemOblogatoire: false, pathImageItemOblogatoire: false, observationItemOblogatoire: false });
        setErrorItem({ error: false, message: '' });
    };

    return (
        <section className="inspection-section">
            <div className="container">
                <form onSubmit={formik1.handleSubmit} className='formulaireinspection-sec wrap-all'>
                    <div className="">
                        <div className="grid grid--small-form">
                            <div className="form-sec">
                                <div className="retour" id="retour-formulaireinspc">
                                    <Link to="/listformulaires" style={{ textDecoration: "none"}}>
                                        <div className="arrow-back">
                                            <span className="material-symbols-outlined" id="arrow">arrow_back</span>
                                        </div>
                                    </Link>
                                </div>
                                <div className="small-menu-sec">
                                    <div className={detailsClass} id="detail-formulaire-inspection" onClick={() => showDetails()}>
                                        <span className="material-symbols-outlined">info</span>
                                        <h3 className="heading-primary">Détails</h3>
                                        {(formik1.errors.titre) && <span className="material-symbols-outlined danger">error</span>}
                                    </div>
                                    <div className={controleClass} id="control-formulaire-inspection" onClick={() => showControle()}>
                                        <span className="material-symbols-outlined">article</span>
                                        <h3 className="heading-primary">Eléments d'inspection</h3>
                                    </div>
                                </div>
                            </div>

                            <div className="form-sec">
                                <div className="retour" id="retour-formulaire-inspection">
                                    <div className="arrow-back-inspection">
                                    </div>
                                    <button type="submit" className="submit-btn">Enregistrer</button>
                                </div>
                                <div className="form-details">
                                    {details && <div className="detail-form" id="detail-formulaire-inspection-form">
                                        <h3>Détails</h3>
                                        <div className="col">
                                            <div className="grp">
                                                <div className="gp">
                                                    <label >Titre <span className='requiredField'>*</span></label>
                                                    <input type="text"
                                                        id="titre"
                                                        name="titre"
                                                        style={(formik1.touched.titre && formik1.errors.titre) ? { borderColor: "red" } : null}
                                                        placeholder=""
                                                        defaultValue={formik1.values.titre}
                                                        onChange={formik1.handleChange}
                                                    />
                                                    {formik1.touched.titre && formik1.errors.titre ? (
                                                        <div className="invalid-feedback">{formik1.errors.titre}</div>
                                                    ) : null}
                                                </div>
                                                <div className="gp">
                                                    <label>Statut</label>
                                                    <select
                                                        id="statutModele"
                                                        name="statutModele"
                                                        value={formik1.values.statutModele}
                                                        onChange={formik1.handleChange}
                                                    >
                                                        <option value="actif" defaultValue={true}>Actif</option>
                                                        <option value="inactif">Inactif</option>
                                                        <option value="archivé">Archivé</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="grp">
                                                <div className="gp">
                                                    <label>Description</label>
                                                    <textarea type="text"
                                                        id="description"
                                                        name="description"
                                                        placeholder=""
                                                        defaultValue={formik1.values.description}
                                                        onChange={formik1.handleChange}
                                                        rows={4}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>}

                                    {controle && <div className="document-form" id="element-formulaire-inspection">
                                        <div className="detail-form">
                                            <h3>Ajouter un élément d'inspection</h3>
                                            <div className="col">
                                                <div className="grp">
                                                    <div className="gp">
                                                        <label >Titre <span className='requiredField'>*</span></label>
                                                        <input type="text"
                                                            id="itemTitre"
                                                            name="itemTitre"
                                                            placeholder=""
                                                            style={(errorItem.error) ? { borderColor: "red" } : null}
                                                            value={formData.itemTitre}
                                                            onChange={handleInputChange}
                                                        />
                                                        {errorItem.error && errorItem.message ? (
                                                            <div className="invalid-feedback">{errorItem.message}</div>
                                                        ) : null}
                                                    </div>
                                                </div>
                                                <div className="grp-check">
                                                    <input
                                                        type="checkbox"
                                                        className="box"
                                                        id="itemOblogatoire"
                                                        name="itemOblogatoire"
                                                        checked={formData.itemOblogatoire}
                                                        onChange={handleInputChange}
                                                    />
                                                    <label > Elément obligatoire</label>
                                                </div>
                                                <div className="grp-check">
                                                    <input
                                                        type="checkbox"
                                                        className="box"
                                                        id="pathImageItemOblogatoire"
                                                        name="pathImageItemOblogatoire"
                                                        checked={formData.pathImageItemOblogatoire}
                                                        onChange={handleInputChange}
                                                    />
                                                    <label > Exiger une photo si "Échec"</label>
                                                </div>
                                                <div className="grp-check">
                                                    <input
                                                        type="checkbox"
                                                        className="box"
                                                        id="observationItemOblogatoire"
                                                        name="observationItemOblogatoire"
                                                        checked={formData.observationItemOblogatoire}
                                                        onChange={handleInputChange}
                                                    />
                                                    <label > Exiger un commentaire si "Échec"</label>
                                                </div>
                                                <div className="grp">
                                                    <div className="gp">
                                                        <label>Courte description</label>
                                                        <textarea type="text"
                                                            id="descriptionItem"
                                                            name="descriptionItem"
                                                            placeholder=""
                                                            value={formData.descriptionItem}
                                                            onChange={handleInputChange}
                                                            rows={2}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="grp" style={{ justifyContent: "center" }}>
                                                    <button type="button" className="btn-small " onClick={(event) => handleAddElement(event)}>
                                                        <span className="material-symbols-outlined success">add</span>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>}

                                    {controle && <div className="document-form" id="element-formulaire-inspection">
                                        <div className="detail-form">
                                            <h3>Eléments d'inspection</h3>
                                            <div className="col">
                                                <table className="element-inspec-table">
                                                    <thead>
                                                        <tr>
                                                            <th>Titre</th>
                                                            <th>Description</th>
                                                            <th>Supprimer</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {items.map((item, index) => (
                                                            <tr key={index}>
                                                                <td><label>{item.itemTitre}</label></td>
                                                                <td><label>{item.descriptionItem}</label></td>
                                                                <td>
                                                                    <button type="button" onClick={() => deleteItem(index)} style={{ border: "none", background: "none" }}>
                                                                        <span className="material-symbols-outlined danger">delete</span>
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>}

                                    <div className="submit-container">
                                        <button type="submit" className="submit-btn">Enregistrer</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </section>
    );
}

export default AjouterFormulaire;
