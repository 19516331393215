import React, { useEffect, useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import moment from 'moment/moment';
import { CustomToast } from '../../components/Toast/CustomToast';
import ConfirmationDialog from '../../components/Confirmation/ConfirmationDialog';
import { axiosInstance, Profil } from '../../services/api';
import { AuthContext } from '../../contexts/AuthContext';
import useDebounce from '../../hooks/useDebounce';

const ListRoles = () => {
    const { authData } = useContext(AuthContext);

    const [profils, setProfils] = useState([]);
    const [filtredProfils, setFiltredProfils] = useState([]);
    const [pageNumber, setPageNumber] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalItems, setTotalItems] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [loading, setLoading] = useState(true);
    const [idProfilDelete, setIdProfilDelete] = useState('');

    const [search, setSearch] = useState("");
    const debouncedSearchText = useDebounce(search, 300);
    const [confirmDialogOpenDelete, setConfirmDialogOpenDelete] = useState(false);

    //Table management
    const [sortBy, setSortBy] = useState(''); // Column name to sort by
    const [sortOrder, setSortOrder] = useState('asc'); // 'asc' or 'desc'

    const handleSort = (column) => {
        setSortBy(column);
        setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');

        let sortedTable = [...filtredProfils].sort((a, b) => {
            const aValue = a[sortBy] || '';
            const bValue = b[sortBy] || '';

            if (sortOrder === 'asc') {
                return aValue.localeCompare(bValue);
            } else {
                return bValue.localeCompare(aValue);
            }
        });

        setFiltredProfils(sortedTable);
    };

    const [openAboutList, setOpenAboutList] = useState(null);

    const toggleAboutList = (idMarque) => {
        // console.log(idMarque)
        setOpenAboutList(openAboutList === idMarque ? null : idMarque);
    };

    const handlePageChange = (page) => {
        setPageNumber(page);
        //consolelog("setPageNumber="+pageNumber)
        const startIndex = (page - 1) * pageSize;
        const endIndex = startIndex + pageSize;
        const paginatedData = profils.slice(startIndex, endIndex);

        setFiltredProfils(paginatedData);
    };

    const handlePerRowsChange = (newPerPage) => {
        setPageSize(newPerPage);
        //console.log("setPageSize="+pageSize);
    };

    //Supprimer Profil
    const deleteProfil = async (idProfil) => {
        try {
            const response = await axiosInstance.delete(`${Profil}/${idProfil}`);
            getProfils();
            CustomToast('Le rôle a été supprimé avec succès', 'success');
        }
        catch (error) {
            if (!error?.response) {
                CustomToast("Aucune réponse du serveur", 'error');
            } else if (error.response?.status === 400 && error.response?.data === "Vendor is already used. Cannot delete.") {
                CustomToast("Profil déjà utilisé", 'error');
            } else if (error.response?.status === 401) {
                CustomToast("Non autorisé", 'error');
            } else {
                CustomToast("Demande échoué", 'error');
            }
            console.log(error);
        }
    }

    const handleConfirmDelete = (idProfil) => {
        // Show the modal when the confirm delete button is clicked
        //deleteProfil(idProfil);
        setIdProfilDelete(idProfil);
        setConfirmDialogOpenDelete(true);
    };

    const handleDelete = async () => {
        try {
            const response = await axiosInstance.delete(`${Profil}/${idProfilDelete}`);
            setIdProfilDelete('');
            CustomToast('Le rôle a été supprimé avec succès', 'success');
            getProfils();
            setConfirmDialogOpenDelete(false);
        }
        catch (error) {
            // Handle error
            if (!error?.response) {
                CustomToast("Aucune réponse du serveur", 'error');
            } else if (error.response?.status === 400 && error.response?.data === "Vendor is already used. Cannot delete.") {
                CustomToast("Rôle déjà utilisé", 'error');
            } else if (error.response?.status === 401) {
                CustomToast("Non autorisé", 'error');
            } else {
                CustomToast("Demande échoué", 'error');
            }
            console.log(error);
        }
    }

    const handleCancelDelete = () => {
        setConfirmDialogOpenDelete(false);
    };

    //Load data

    const getProfils = async () => {
        try {
            setLoading(true);
            const queryParams = new URLSearchParams();

            if (authData?.estSuperAdmin === true) {
                queryParams.append("estSuperAdmin", true);
            }
            else {
                queryParams.append("estSuperAdmin", false);
            }

            const response = await axiosInstance.get(`${Profil}/GetProfilsUsage?${queryParams}`);
            const resp = await response.data;
            setProfils(resp);
            //console.log(resp);
            let filteredResult = resp;

            if (search !== '') {
                filteredResult = resp.filter((item) =>
                    Object.values(item).some(
                        (value) =>
                            value &&
                            value.toString().toLowerCase().includes(search.toLowerCase())
                    )
                );
            }

            // Pagination
            const startIndex = (pageNumber - 1) * pageSize;
            const endIndex = startIndex + pageSize;
            const paginatedResult = filteredResult.slice(startIndex, endIndex);

            setFiltredProfils(paginatedResult);
            setPageNumber(1);
            setTotalItems(filteredResult.length);
            setTotalPages(Math.ceil(filteredResult.length / pageSize));
            setLoading(false);
        }
        catch (err) {
            setLoading(false);
            console.log(err);
        }
    }

    const handleSearch = (searchTerm) => {

        const result = profils?.filter((item) =>
            Object.values(item).some(
                (value) =>
                    value &&
                    value.toString().toLowerCase().includes(searchTerm.toLowerCase())
            )
        );
        //console.log(searchTerm,result)
        // Pagination
        const startIndex = (pageNumber - 1) * pageSize;
        const endIndex = startIndex + pageSize;
        const paginatedResult = result.slice(startIndex, endIndex);
        setFiltredProfils(paginatedResult);
        setPageNumber(1);
        setTotalItems(result.length);
        setTotalPages(Math.ceil(result.length / pageSize));
        setSearch(searchTerm);
    };

    useEffect(() => {
        //Load Data
        //if (authData) {
        if (authData) {
            getProfils();
        }
        //}

        return () => {
            // Perform any necessary cleanup (e.g., cancel pending requests)
        };
    }, [authData, pageSize]);

    // useEffect(() => {
    //     //if (!profils) { return }
    //     const result = profils.filter((item) =>
    //         Object.values(item).some(
    //             (value) =>
    //                 value &&
    //                 value.toString().toLowerCase().includes(search.toLowerCase())
    //         )
    //     );
    //     setFiltredProfils(result);
    // }, [search])

    useEffect(() => {
        //Load Data
        handleSearch(debouncedSearchText);
        return () => {
            // Perform any necessary cleanup (e.g., cancel pending requests)
        };
    }, [debouncedSearchText]);

    return (
        <section className="donnee-section" onClick={() => { if (openAboutList !== null) { setOpenAboutList(null) } }}>
            <div className="container">
                <div className="roledoirtaccee-sec wrap-all">
                    <Link to="/ajouterrole" className="color-primary-2 decoration-none add-droit-access">
                        <div className="add-new" id="add-vehc">
                            <span className="material-symbols-outlined">add</span>
                            Ajouter un rôle
                        </div>
                    </Link>
                    <div className="group-elements">
                        <div className="voir-elements">
                            <p></p>
                            <select
                                name="pageSize"
                                id="pageSize"
                                onChange={(e) => { handlePerRowsChange(e.target.value || 10); setPageNumber(1); }}
                            >
                                <option value="10" defaultValue={true}>10</option>
                                <option value="15">15</option>
                                <option value="20">20</option>
                            </select>
                        </div>
                        <div className="search-element">
                            <p>Rechercher</p>
                            <input
                                type="text"
                                value={search}
                                onChange={(e) => handleSearch(e.target.value)}
                            />
                        </div>
                    </div>
                    {filtredProfils?.length > 0 && <div className="table-wrap">
                        <table>
                            <thead>
                                <tr>
                                    <th onClick={() => handleSort('libelleProfil')}>Nom</th>
                                    <th>Description</th>
                                    <th>Statut</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {filtredProfils?.map((row) => (
                                    <tr key={row.idProfil}>
                                        <td>{row.libelleProfil}</td>
                                        <td>{row.description}</td>
                                        <td>
                                            {
                                                row.statut === "actif" ? (
                                                    <>
                                                        <svg height="11.87" width="15"><circle cx="6" cy="6" r="6" fill="green" /></svg>Actif
                                                    </>
                                                ) : row.statut === "inactif" ? (
                                                    <>
                                                        <svg height="11.87" width="15"><circle cx="6" cy="6" r="6" fill="red" /></svg>Inactif
                                                    </>
                                                ) : null
                                            }
                                        </td>
                                        <td className="txt-center relative">
                                            <span className="material-symbols-outlined about-btn" onClick={() => toggleAboutList(row.idProfil)}>
                                                more_horiz
                                            </span>
                                            {openAboutList === row.idProfil && (
                                                <div className="about-list">
                                                    <ul>
                                                        {((row.estSupperAdmin == false) || (row.estSupperAdmin == true && authData.estSuperAdmin == true)) && <li><Link className='row row-center' to={`/modifierrole/${row.idProfil}`} style={{ textDecoration: "none" }}>
                                                            <span className="material-symbols-outlined">edit</span>Modifier
                                                        </Link></li>}

                                                        {((row.estSupperAdmin == true && authData.estSuperAdmin == false)) && <li><Link className='row row-center' to="#" style={{ textDecoration: "none", color: "#BEBEBE", cursor: 'not-allowed' }} onClick={(event) => event.preventDefault()} title="Vous ne pouvez pas modifer ce rôle">
                                                            <span className="material-symbols-outlined">edit</span>Modifier
                                                        </Link></li>}

                                                        {((row.estSupperAdmin == false) && (row.countEmployee == 0)) && <li><Link className='row row-center' to="/listroles" style={{ textDecoration: "none" }} onClick={() => handleConfirmDelete(row.idProfil)}>
                                                            <span className="material-symbols-outlined">delete</span>Supprimer
                                                        </Link></li>}

                                                        {((row.estSupperAdmin == true || row.countEmployee > 0)) && <li><Link className='row row-center' to="#" style={{ textDecoration: "none", color: "#BEBEBE", cursor: 'not-allowed' }} onClick={(event) => event.preventDefault()} title="Vous ne pouvez pas supprimer ce rôle">
                                                            <span className="material-symbols-outlined">delete</span>Supprimer
                                                        </Link></li>}

                                                    </ul>
                                                </div>
                                            )}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>}
                    <div className="pagination">
                        <Link to="#" onClick={() => handlePageChange(pageNumber - 1)}
                            className={(pageNumber === 1) ? 'pgn disabled' : 'pgn'}
                            disabled={(pageNumber === 1) ? true : false}
                        >&laquo;</Link>
                        {Array.from({ length: totalPages }, (_, i) => (
                            <Link
                                key={i + 1}
                                to="#"
                                className={i + 1 === pageNumber ? 'pgn active' : 'pgn'}
                                onClick={() => handlePageChange(i + 1)}
                            >
                                {i + 1}
                            </Link>
                        ))}
                        <Link to="#" onClick={() => handlePageChange(pageNumber + 1)}
                            className={((pageNumber + 1) > totalPages) ? 'pgn disabled' : 'pgn'}
                            disabled={((pageNumber + 1) > totalPages) ? true : false}
                        >&raquo;</Link>
                    </div>

                    <ConfirmationDialog
                        open={confirmDialogOpenDelete}
                        onClose={handleCancelDelete}
                        onConfirm={handleDelete}
                        titleMessage="Confirmer la suppression du rôle"
                        bodyMessage={"Voulez-vous vraiment supprimer ce rôle ? Toutes les données seront définitivement perdues."}
                        btnCancelText="Annuler"
                        btnConfirmText="Supprimer le rôle"
                    />
                </div>
            </div>
        </section>
    );
}

export default ListRoles;
