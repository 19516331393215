import React, { useEffect, useState, useRef, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Select from "react-select";
import moment from 'moment/moment';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { CustomToast } from '../../components/Toast/CustomToast';
import useDebounce from '../../hooks/useDebounce';
import { axiosInstance, Vehicule, VehiculeProbleme, Employee } from '../../services/api';
import { AuthContext } from '../../contexts/AuthContext';

const AjouterProbleme = () => {
    const { authData } = useContext(AuthContext);
    const navigate = useNavigate();

    const [vehicules, setVehicules] = useState([]);
    const [employees, setEmployees] = useState([]);

    const [selectedVehicle, setSelectedVehicle] = useState('');
    const [selectedVehicleId, setSelectedVehicleId] = useState('');
    const [vehicleNotFound, setVehicleNotFound] = useState(false);

    const [kiloDebLess, setKiloDebLess] = useState({ display: false, msg: "" });
    const [kiloDebGreater, setKiloDebGreater] = useState({ display: false, msg: "" });
    const [kiloDebBetween, setKiloDebBetween] = useState({ display: false, msg: "" });

    const [selectedSignalerPar, setSelectedSignalerPar] = useState('');
    const [selectedSignalerParId, setSelectedSignalerParId] = useState('');

    const [enteredVehicleText, setEnteredVehicleText] = useState('');
    const [enteredSignalerParText, setEnteredSignalerParText] = useState('');
    const debouncedVehiculeText = useDebounce(enteredVehicleText, 300);
    const debouncedSignalerParText = useDebounce(enteredSignalerParText, 300);

    const documentImagePathRef = useRef(null);
    const [documentImagePath, setDocumentImagePath] = useState(null);

    const handleVehicleChange = (selectedOption) => {
        setSelectedVehicle(selectedOption);
        setSelectedVehicleId(selectedOption ? selectedOption.idVehicule : '');
        formik1.values.idVehicule = selectedVehicleId;
    };

    const handleSignalerParChange = (selectedOption) => {
        setSelectedSignalerPar(selectedOption);
        setSelectedSignalerParId(selectedOption ? selectedOption.idEmploye : '');
        formik1.values.signalerParId = selectedSignalerParId;
    };

    const getImageFileName = (file) => {
        return file ? file.name : 'No file selected';
    };

    const handleDocumentImagePathChange = (e) => {
        setDocumentImagePath(e.target.files[0]);
    };

    const formik1 = useFormik({
        initialValues: {
            vehicule: '',
            idVehicule: '',
            titre: '',
            description: '',
            commentaire: '',
            statutChangerPar: '',
            statutChangerParId: '',
            sourceStatut: 'probleme',
            priorite: 'aucune',
            signalerPar: '',
            signalerParId: '',
            dateSignalement: '',
            valeurCompteur: '',
            affecterA: '',
            affecterAid: '',
            dateLimit: '',
            source: 'probleme',
            type: '',
            immobiliser: 'non'
        },
        validationSchema: Yup.object({
            vehicule: Yup.object()
                .nullable()
                .required('Champ obligatoire'),
            dateSignalement: Yup.string()
                .required("Champ obligatoire"),
            titre: Yup.string()
                .required("Champ obligatoire"),
            // signalerPar: Yup.object()
            //     .nullable()
            //     .required('Champ obligatoire'),
        }),
        onSubmit: async (values) => {
            try {

                //console.log(values);

                const formData = new FormData();
                formData.append('idVehicule', values.vehicule.idVehicule);
                formData.append('titre', values.titre);
                formData.append('description', values.description);
                //formData.append('commentaire', values.commentaire);
                formData.append('statutChangerPar', authData.nomPrenom);
                formData.append('statutChangerParId', authData.idUser);
                formData.append('sourceStatut', values.sourceStatut);
                formData.append('priorite', values.priorite);

                if (values.signalerPar) {
                    formData.append('signalerPar', values.signalerPar.nom + ' ' + values.signalerPar.prenom);
                    formData.append('signalerParId', values.signalerPar.idEmploye);
                } else {
                    formData.append('signalerPar', authData.nomPrenom);
                    formData.append('signalerParId', authData.idUser);
                }

                formData.append('dateSignalement', values.dateSignalement);
                formData.append('valeurCompteur', values.valeurCompteur);
                // formData.append('affecterA', values.affecterA);
                // formData.append('affecterAid', values.affecterAid);
                formData.append('dateLimit', moment(values.dateLimit).isValid() ? values.dateLimit : '');
                formData.append('source', values.source);
                formData.append('immobiliser', values.immobiliser);
                formData.append('typeProbleme', values.type);
                //formData.append('idSource', values.idSource);
                //formData.append('dateInspection', values.dateInspection);       
                formData.append('documentImagePath', documentImagePath);
                formData.append('idUtilisateurAction', authData.idUser);

                await axiosInstance.post(VehiculeProbleme, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });

                CustomToast("Le problème a été créé avec succès", 'success');
                navigate('/listproblemes', { replace: true });
            } catch (error) {
                // Handle error
                if (!error?.response) {
                    CustomToast("Aucune réponse du serveur", 'error');
                    setVehicleNotFound(false);
                    setKiloDebGreater({ display: false, msg: "" });
                    setKiloDebLess({ display: false, msg: "" });
                    setKiloDebBetween({ display: false, msg: "" });
                } else if (error.response?.status === 400 && error.response?.data === "Vehicle not found") {
                    CustomToast("Véhicule introuvable", 'error');
                    setVehicleNotFound(true);
                    setKiloDebGreater({ display: false, msg: "" });
                    setKiloDebLess({ display: false, msg: "" });
                    setKiloDebBetween({ display: false, msg: "" });
                } else if (error.response?.status === 400 && error.response?.data?.msg === "Incorrect value, must be greater") {
                    CustomToast("L'entrée de compteur de véhicule n'a pas pu être créée", 'error');
                    setKiloDebGreater({ display: true, msg: `Doit être supérieur à ${error.response?.data?.val1}` });
                    setVehicleNotFound(false);
                    setKiloDebLess({ display: false, msg: "" });
                    setKiloDebBetween({ display: false, msg: "" });
                } else if (error.response?.status === 400 && error.response?.data?.msg === "Incorrect value, must be less") {
                    CustomToast("L'entrée de compteur de véhicule n'a pas pu être créée", 'error');
                    setKiloDebLess({ display: true, msg: `Doit être inférieur à ${error.response?.data?.val1}` });
                    setVehicleNotFound(false);
                    setKiloDebGreater({ display: false, msg: "" });
                    setKiloDebBetween({ display: false, msg: "" });
                } else if (error.response?.status === 400 && error.response?.data?.msg === "Incorrect value, must be between") {
                    CustomToast("L'entrée de compteur de véhicule n'a pas pu être créée", 'error');
                    setKiloDebBetween({ display: true, msg: `Doit être entre ${error.response?.data?.val1} et ${error.response?.data?.val2}` });
                    setVehicleNotFound(false);
                    setKiloDebGreater({ display: false, msg: "" });
                    setKiloDebLess({ display: false, msg: "" });
                } else if (error.response?.status === 401) {
                    CustomToast("Non autorisé", 'error');
                    setVehicleNotFound(false);
                    setKiloDebGreater({ display: false, msg: "" });
                    setKiloDebLess({ display: false, msg: "" });
                    setKiloDebBetween({ display: false, msg: "" });
                } else {
                    CustomToast("Demande échoué", 'error');
                    setVehicleNotFound(false);
                    setKiloDebGreater({ display: false, msg: "" });
                    setKiloDebLess({ display: false, msg: "" });
                    setKiloDebBetween({ display: false, msg: "" });
                }
                console.log(error);
            }
        }
    });

    //Load data
    const getVehicules = async (nomVehicule) => {
        try {
            const queryParams = new URLSearchParams({
                pageNumber: 1,
                pageSize: 10
            });

            if (authData?.wilayas != null) {
                const wilayasFilter = authData?.wilayas
                queryParams.append("wilayas", wilayasFilter.join(','));
            }
            else {
                return
            }

            if (authData?.flottesAutorises != null) {
                const flottesAutorisesFilter = authData?.flottesAutorises
                queryParams.append("flottesAutorises", flottesAutorisesFilter.join(','));
            }
            else {
                return
            }

            if (nomVehicule !== '') {
                queryParams.append("nom", nomVehicule);
            }

            const response = await axiosInstance.get(`${Vehicule}/GetVehiculesAutocomplete?${queryParams}`);
            const resp = await response.data;
            setVehicules(resp.data);
        }
        catch (err) {
            console.log(err);
        }
    }

    const getEmployees = async (nom) => {
        try {
            const queryParams = new URLSearchParams({});
            if (nom !== '') {
                queryParams.append("nom", nom);
            }
            const response = await axiosInstance.get(`${Employee}/GetEmployeAutocomplete?${queryParams}`);
            const resp = await response.data;
            setEmployees(resp.data);
        }
        catch (err) {
            console.log(err);
        }
    }

    useEffect(() => {
        //if (debouncedVehiculeText) {
        getVehicules(debouncedVehiculeText);
        //}
        return () => {
            // Perform any necessary cleanup (e.g., cancel pending requests)
        };
    }, [authData, debouncedVehiculeText]);

    useEffect(() => {
        //if (debouncedSignalerParText) {
        getEmployees(debouncedSignalerParText);
        //}
        return () => {
            // Perform any necessary cleanup (e.g., cancel pending requests)
        };
    }, [debouncedSignalerParText]);

    //console.log(formik1.values.vehicule)

    return (
        <section className="problem-section">
            <div className="container">
                <form onSubmit={formik1.handleSubmit} className='problem-form'>
                    <div className="">
                        <div className="grid grid--2-cols">
                            <div className="form-sec">
                                <div className="retour" id="retour-problem">
                                    <Link to="/listproblemes" style={{ textDecoration: "none" }}>
                                        <div className="arrow-back">
                                            <span className="material-symbols-outlined" id="arrow">arrow_back</span>
                                        </div>
                                    </Link>
                                    <button type="submit" className="submit-btn">Enregistrer</button>
                                </div>
                                <div className="form-details">
                                    <div className="detail-form" id="detail-inspection-form">
                                        <h3 className="heading-primary">Détails</h3>
                                        <div className="col">
                                            <div className="grp">
                                                <div className="gp">
                                                    <label >Véhicule<span className='requiredField'>*</span></label>
                                                    <Select
                                                        id="vehicule"
                                                        name="vehicule"
                                                        value={formik1.values.vehicule}
                                                        onChange={(option) => { formik1.setFieldValue('vehicule', option); setVehicleNotFound(false) }}
                                                        options={vehicules}
                                                        onInputChange={(inputValue) => {
                                                            // Update the entered text
                                                            setEnteredVehicleText(inputValue);
                                                        }}
                                                        getOptionLabel={(option) => option.marque + '[' + option.modele + '] ' + option.immatriculation}
                                                        getOptionValue={(option) => option.idVehicule}
                                                        isClearable
                                                        styles={{
                                                            container: (provided, state) => ({
                                                                ...provided,
                                                                boxShadow: state.isFocused ? '0px 0px 5px 0px #65458d' : formik1.errors.vehicule ? 'red' : '#BEBEBE',
                                                            }),
                                                            control: (baseStyles, state) => ({
                                                                ...baseStyles,
                                                                minHeight: '19px',
                                                                outline: '0!important',
                                                                borderRadius: '0.5rem',
                                                                borderWidth: '1px',
                                                                fontSize: '1rem',
                                                                height: '3.1rem',
                                                            }),
                                                            option: (provided, state) => ({
                                                                ...provided,
                                                                fontSize: '1rem'
                                                            }),
                                                            singleValue: (provided, state) => ({
                                                                ...provided,
                                                                fontSize: '1rem',
                                                                margin: '0',
                                                            }),
                                                            valueContainer: (provided, state) => ({
                                                                ...provided,
                                                                alignItems: 'start',
                                                                fontSize: '1rem'
                                                            }),
                                                        }}
                                                    />
                                                    {formik1.touched.vehicule && formik1.errors.vehicule && (
                                                        <div className="invalid-feedback">{formik1.errors.vehicule}</div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        {formik1.values.vehicule && <><div className="col">
                                            <div className="grp">
                                                <div className="gp">
                                                    <label>Résumé<span className='requiredField'>*</span></label>
                                                    <input
                                                        type="text"
                                                        name="titre"
                                                        id="titre"
                                                        style={(formik1.touched.titre && formik1.errors.titre) ? { borderColor: "red" } : null}
                                                        value={formik1.values.titre}
                                                        onChange={formik1.handleChange} />
                                                    {formik1.touched.titre && formik1.errors.titre && (
                                                        <div className="invalid-feedback">{formik1.errors.titre}</div>
                                                    )}
                                                </div>
                                                <div className="gp">
                                                    <label>Priorité</label>
                                                    <select
                                                        name="priorite"
                                                        id="priorite"
                                                        value={formik1.values.priorite}
                                                        onChange={formik1.handleChange}>
                                                        <option value="aucune" defaultValue={true}>Aucune priorité</option>
                                                        <option value="faible">Faible</option>
                                                        <option value="moyen">Moyen</option>
                                                        <option value="élevé">Élevé</option>
                                                        <option value="critique">Critique</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="grp">
                                                <div className="gp">
                                                    <label>Type</label>
                                                    <select
                                                        name="type"
                                                        id="type"
                                                        value={formik1.values.type}
                                                        onChange={formik1.handleChange}>
                                                        <option value="" defaultValue={true}>Séléctionnez type</option>
                                                        <option value="curative">Curative</option>
                                                        <option value="préventive">Préventive</option>
                                                    </select>
                                                </div>
                                                <div className="gp">
                                                    <label>Immobiliser</label>
                                                    <select
                                                        name="immobiliser"
                                                        id="immobiliser"
                                                        value={formik1.values.immobiliser}
                                                        onChange={formik1.handleChange}>
                                                        <option value="non" defaultValue={true}>Non</option>
                                                        <option value="oui">Oui</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                            <div className="col">
                                                <div className="grp">
                                                    <div className="gp">
                                                        <label>Description</label>
                                                        <textarea
                                                            type="text"
                                                            name="description"
                                                            id="description"
                                                            value={formik1.values.description}
                                                            onChange={formik1.handleChange}
                                                            rows={2} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col">
                                                <div className="grp">
                                                    <div className="gp">
                                                        <label>Date de signalement<span className='requiredField'>*</span></label>
                                                        <input
                                                            type="date"
                                                            name="dateSignalement"
                                                            id="dateSignalement"
                                                            style={(formik1.touched.dateSignalement && formik1.errors.dateSignalement) ? { borderColor: "red" } : null}
                                                            value={formik1.values.dateSignalement}
                                                            onChange={formik1.handleChange} />
                                                        {formik1.touched.dateSignalement && formik1.errors.dateSignalement ? (
                                                            <div className="invalid-feedback">{formik1.errors.dateSignalement}</div>
                                                        ) : null}
                                                    </div>
                                                    <div className="gp">
                                                        <label>Signalé par</label>
                                                        <Select
                                                            id="signalerPar"
                                                            name="signalerPar"
                                                            value={formik1.values.signalerPar}
                                                            onChange={(option) => formik1.setFieldValue('signalerPar', option)}
                                                            options={employees}
                                                            onInputChange={(inputValue) => {
                                                                // Update the entered text
                                                                setEnteredSignalerParText(inputValue);
                                                            }}
                                                            getOptionLabel={(option) => option.nom + ' ' + option.prenom}
                                                            getOptionValue={(option) => option.idEmploye}
                                                            isClearable
                                                            styles={{
                                                                control: (baseStyles, state) => ({
                                                                    ...baseStyles,
                                                                    minHeight: '19px',
                                                                    outline: '0!important',
                                                                    borderRadius: '0.5rem',
                                                                    borderWidth: '1px',
                                                                    fontSize: '1rem',
                                                                    height: '3.1rem',
                                                                }),
                                                                option: (provided, state) => ({
                                                                    ...provided,
                                                                    fontSize: '1rem'
                                                                }),
                                                                singleValue: (provided, state) => ({
                                                                    ...provided,
                                                                    fontSize: '1rem',
                                                                    margin: '0',
                                                                }),
                                                                valueContainer: (provided, state) => ({
                                                                    ...provided,
                                                                    alignItems: 'start',
                                                                    fontSize: '1rem'
                                                                }),
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="grp">
                                                    <div className="gp">
                                                        <label>Compteur</label>
                                                        <input
                                                            type="number"
                                                            id="valeurCompteur"
                                                            name="valeurCompteur"
                                                            placeholder=""
                                                            defaultValue={formik1.values.valeurCompteur}
                                                            onChange={formik1.handleChange}
                                                            step="any"
                                                        />
                                                        {kiloDebLess.display && (
                                                            <div className="invalid-feedback">{kiloDebLess.msg}</div>
                                                        )}
                                                        {kiloDebGreater.display && (
                                                            <div className="invalid-feedback">{kiloDebGreater.msg}</div>
                                                        )}
                                                        {kiloDebBetween.display && (
                                                            <div className="invalid-feedback">{kiloDebBetween.msg}</div>
                                                        )}
                                                    </div>
                                                    <div className="gp" title='Date limite à laquelle le problème doit être résolu ou fermé. Le problème est considéré comme en retard après cette date'>
                                                        <label>Date d'échéance</label>
                                                        <input
                                                            type="date"
                                                            name="dateLimit"
                                                            id="dateLimit"
                                                            value={formik1.values.dateLimit}
                                                            onChange={formik1.handleChange} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col">
                                                <div className="grp">
                                                    <div className="gp">
                                                        <label>Documents</label>
                                                        <label className="upload-file">
                                                            upload file
                                                            <input
                                                                ref={documentImagePathRef}
                                                                id="documentImagePath"
                                                                name="documentImagePath"
                                                                type="file"
                                                                onChange={handleDocumentImagePathChange}
                                                            />
                                                        </label>
                                                        {documentImagePath && <span style={{ fontSize: "10px" }}>{getImageFileName(documentImagePath)}
                                                            <span className="material-symbols-outlined" onClick={(e) => { setDocumentImagePath(null); if (documentImagePathRef.current) { documentImagePathRef.current.value = ''; } }}>delete</span>
                                                        </span>}
                                                    </div>
                                                </div>
                                            </div></>}
                                    </div>

                                    <div className="submit-container">
                                        <button type="submit" className="submit-btn">Enregistrer</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </section>
    );
}

export default AjouterProbleme;
