import React, { useEffect, useState, useRef, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { CustomToast } from '../../components/Toast/CustomToast';
import { axiosInstance, ParametresApplication, UniteMesure, UserAuthentication } from '../../services/api';
import { AuthContext } from '../../contexts/AuthContext';

const Params = () => {
    const { authData, login } = useContext(AuthContext);
    const [unitesDistance, setUnitesDistance] = useState([]);
    const [unitesVolume, setUnitesVolume] = useState([]);
    const [unitesPoids, setUnitesPoids] = useState([]);
    const navigate = useNavigate();

    const { handleChange, values, handleSubmit, touched, errors, setFieldValue, setValues } = useFormik({
        initialValues: {
            libelleParametreMonnaie: '',
            libelleParametreDistance: '',
            libelleParametreVolume: '',
            libelleParametrePoids: '',
        },
        validationSchema: Yup.object({
            libelleParametreMonnaie: Yup.string()
                .required("Champ obligatoire"),
            libelleParametreDistance: Yup.string()
                .required("Champ obligatoire"),
            libelleParametreVolume: Yup.string()
                .required("Champ obligatoire"),
            libelleParametrePoids: Yup.string()
                .required("Champ obligatoire"),
        }),
        onSubmit: async (values) => {
            try {
                const postData = [{
                    libelleParametre: 'unite_moneitaire',
                    valeurParametre: values.libelleParametreMonnaie,
                },
                {
                    libelleParametre: 'unite_distance',
                    valeurParametre: values.libelleParametreDistance,
                },
                {
                    libelleParametre: 'unite_volume',
                    valeurParametre: values.libelleParametreVolume,
                },
                {
                    libelleParametre: 'unite_poids',
                    valeurParametre: values.libelleParametrePoids,
                }];

                const response = await axiosInstance.post(ParametresApplication, postData);
                if (response.status === 200) {
                    const request = {
                        accessToken: authData.token,
                        refreshToken: authData.tokenRefresh,
                    };
                    const responseAuth = await axiosInstance.post(`${UserAuthentication}/Refresh`, request);
                    let authInfo = JSON.stringify(responseAuth.data);
                    localStorage.setItem('authData', authInfo);
                    login(JSON.parse(authInfo));
                }
                CustomToast("Le paramétrage a été mis à jour avec succès", 'success');
            } catch (error) {
                // Handle error
                console.log(error);
                if (!error?.response) {
                    CustomToast("Aucune réponse du serveur", 'error');
                } else if (error.response?.status === 401) {
                    CustomToast("Non autorisé", 'error');
                } else {
                    CustomToast("Demande échoué", 'error');
                }
            }
        }
    });

    const customHandleChangeDistance = (event) => {
        const selectedId = event.target.selectedOptions[0].id;
        handleChange(event);
        setFieldValue('libelleParametreDistance', selectedId);
    };

    const customHandleChangeVolume = (event) => {
        const selectedId = event.target.selectedOptions[0].id;
        handleChange(event);
        setFieldValue('libelleParametreVolume', selectedId);
    };

    const customHandleChangePoids = (event) => {
        const selectedId = event.target.selectedOptions[0].id;
        handleChange(event);
        setFieldValue('libelleParametrePoids', selectedId);
    };

    const getUnites = async () => {
        try {
            const response = await axiosInstance.get(`${UniteMesure}`);
            const resp = await response.data;
            //setUnites(resp);

            // Filter the units where "codeTypeUniteMesure=distnce"
            const distanceUnits = resp.filter((unit) => unit.codeTypeUniteMesure === 'longueur');

            // Set the filtered units to the state variable "unitesDistance"
            setUnitesDistance(distanceUnits);

            // Optionally, you can also set other types of units to their respective state variables
            const volumeUnits = resp.filter((unit) => unit.codeTypeUniteMesure === 'volume');
            setUnitesVolume(volumeUnits);

            const poidsUnits = resp.filter((unit) => unit.codeTypeUniteMesure === 'masse');
            setUnitesPoids(poidsUnits);

        }
        catch (err) {
            console.log(err);
        }
    }

    const getParams = async () => {
        try {
            const response = await axiosInstance.get(`${ParametresApplication}`);
            const resp = await response.data;

            //console.log(resp)
            const monneiUnite = resp.find((param) => param.libelleParametre === 'unite_moneitaire');
            const distanceUnits = resp.find((param) => param.libelleParametre === 'unite_distance');
            const volumeUnits = resp.find((param) => param.libelleParametre === 'unite_volume');
            const poidsUnits = resp.find((param) => param.libelleParametre === 'unite_poids');

            setValues(prevValues => ({
                ...prevValues,
                libelleParametreMonnaie: monneiUnite ? monneiUnite.valeurParametre : '',
                libelleParametreDistance: distanceUnits ? distanceUnits.valeurParametre : '',
                libelleParametreVolume: volumeUnits ? volumeUnits.valeurParametre : '',
                libelleParametrePoids: poidsUnits ? poidsUnits.valeurParametre : '',
            }));

        }
        catch (err) {
            console.log(err);
        }
    }

    useEffect(() => {
        //Load Data
        getUnites();
        getParams();
        return () => {
            // Perform any necessary cleanup (e.g., cancel pending requests)
        };
    }, [authData]);

    return (
        <section className="donnee-section">
            <div className="container">
                <form onSubmit={handleSubmit} className="parametreapplication-sec">
                    <div className="">
                        <div className="grid grid--2-cols">
                            <div className="form-sec">
                                <div className="retour" id="retour-login">
                                    <div className="arrow-back">
                                        <span className="material-symbols-outlined" id="arrow" style={{ display: 'none' }}>arrow_back</span>
                                    </div>
                                    <input type="submit" className="submit-btn" style={{ display: 'none' }} />
                                </div>
                                <div className="form-details">
                                    <div className="detail-form" id="identification-form">
                                        <h3>Unités par défaut</h3>
                                        <div className="col">
                                            <div className="grp">
                                                <div className="gp">
                                                    <label>Monnaie<span className='requiredField'>*</span></label>
                                                    <input
                                                        type="text"
                                                        id="libelleParametreMonnaie"
                                                        name="libelleParametreMonnaie"
                                                        style={(touched.libelleParametreMonnaie && errors.libelleParametreMonnaie) ? { borderColor: "red" } : null}
                                                        placeholder=""
                                                        defaultValue={values.libelleParametreMonnaie}
                                                        onChange={handleChange}
                                                    />
                                                    {touched.libelleParametreMonnaie && errors.libelleParametreMonnaie ? (
                                                        <div className="invalid-feedback">{errors.libelleParametreMonnaie}</div>
                                                    ) : null}
                                                </div>
                                            </div>
                                            <div className="grp">
                                                <div className="gp">
                                                    <label>Distance<span className='requiredField'>*</span></label>
                                                    <select
                                                        id="libelleParametreDistance"
                                                        name="libelleParametreDistance"
                                                        value={unitesDistance.filter((unit) => unit.codeUniteMesure === values.libelleParametreDistance)[0]?.libelleUniteMesure}
                                                        onChange={customHandleChangeDistance}
                                                        style={(touched.libelleParametreDistance && errors.libelleParametreDistance) ? { borderColor: "red" } : null}
                                                    >
                                                        <option value="">Veuillez sélectionner</option>
                                                        {unitesDistance.map((unite) => (
                                                            <option key={unite.idUniteMesure} id={unite.codeUniteMesure}>{unite.libelleUniteMesure}</option>
                                                        ))}
                                                    </select>
                                                    {touched.libelleParametreDistance && errors.libelleParametreDistance ? (
                                                        <div className="invalid-feedback">{errors.libelleParametreDistance}</div>
                                                    ) : null}
                                                </div>
                                            </div>
                                            <div className="grp">
                                                <div className="gp">
                                                    <label>Volume de carburant et huile<span className='requiredField'>*</span></label>
                                                    <select
                                                        id="libelleParametreVolume"
                                                        name="libelleParametreVolume"
                                                        value={unitesVolume.filter((unit) => unit.codeUniteMesure === values.libelleParametreVolume)[0]?.libelleUniteMesure}
                                                        onChange={customHandleChangeVolume}
                                                        style={(touched.libelleParametreVolume && errors.libelleParametreVolume) ? { borderColor: "red" } : null}
                                                    >
                                                        <option value="">Veuillez sélectionner</option>
                                                        {unitesVolume.map((unite) => (
                                                            <option key={unite.idUniteMesure} id={unite.codeUniteMesure}>{unite.libelleUniteMesure}</option>
                                                        ))}
                                                    </select>
                                                    {touched.libelleParametreVolume && errors.libelleParametreVolume ? (
                                                        <div className="invalid-feedback">{errors.libelleParametreVolume}</div>
                                                    ) : null}
                                                </div>
                                            </div>
                                            <div className="grp">
                                                <div className="gp">
                                                    <label>Poids<span className='requiredField'>*</span></label>
                                                    <select
                                                        id="libelleParametrePoids"
                                                        name="libelleParametrePoids"
                                                        value={unitesPoids.filter((unit) => unit.codeUniteMesure === values.libelleParametrePoids)[0]?.libelleUniteMesure}
                                                        onChange={customHandleChangePoids}
                                                        style={(touched.libelleParametrePoids && errors.libelleParametrePoids) ? { borderColor: "red" } : null}
                                                    >
                                                        <option value="">Veuillez sélectionner</option>
                                                        {unitesPoids.map((unite) => (
                                                            <option key={unite.idUniteMesure} id={unite.codeUniteMesure}>{unite.libelleUniteMesure}</option>
                                                        ))}
                                                    </select>
                                                    {touched.libelleParametrePoids && errors.libelleParametrePoids ? (
                                                        <div className="invalid-feedback">{errors.libelleParametrePoids}</div>
                                                    ) : null}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="submit-container">
                                        <input type="submit" value="Enregistrer" className="submit-btn" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </section>
    );
}

export default Params;
