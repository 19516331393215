import React, { useEffect, useState, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import moment from 'moment/moment';
import Select from "react-select";
import * as Yup from 'yup';
import { CustomToast } from '../../../components/Toast/CustomToast';
import useDebounce from '../../../hooks/useDebounce';
import ConfirmationDialog from '../../../components/Confirmation/ConfirmationDialog';
import { formatCurrency } from '../../../utils/Utils'
import { axiosInstance, TacheService, VehiculeServiceRappelle, Vehicule } from '../../../services/api';
import { AuthContext } from '../../../contexts/AuthContext';

const ListRappels = () => {
    const { authData } = useContext(AuthContext);

    const [rappels, setRappels] = useState([]);
    const [vehicules, setVehicules] = useState([]);
    const [taches, setTaches] = useState([]);
    const [filtredRappels, setFiltredRappels] = useState([]);
    const [pageNumber, setPageNumber] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalItems, setTotalItems] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [loading, setLoading] = useState(true);
    const [idServiceRappelleDelete, setIdServiceRappelleDelete] = useState('');
    const [idServiceRappelleResoudreNote, setIdServiceRappelleResoudreNote] = useState('');
    const [confirmDialogOpenResoudreNote, setConfirmDialogOpenResoudreNote] = useState(false);

    const [selectedVehicle, setSelectedVehicle] = useState('');
    const [selectedVehicleId, setSelectedVehicleId] = useState('');

    const [selectedTask, setSelectedTask] = useState('');
    const [selectedTaskId, setSelectedTaskId] = useState('');

    const [filters, setFilters] = useState({
        idVehicule: '',
        vehicule: '',
        statut: '',
        idTacheService: '',
        tacheService: '',
        dateDebut: moment("00", "hh").add(-4, 'days').format('YYYY-MM-DD'),
        dateFin: moment("00", "hh").add(3, 'days').format('YYYY-MM-DD')
    });

    const [search, setSearch] = useState("");

    const [enteredVehicleText, setEnteredVehicleText] = useState('');
    const [enteredTaskText, setEnteredTaskText] = useState('');

    const debouncedVehiculeText = useDebounce(enteredVehicleText, 300);
    const debouncedTaskText = useDebounce(enteredTaskText, 300);

    const [confirmDialogOpenDelete, setConfirmDialogOpenDelete] = useState(false);

    //Table management
    const [sortBy, setSortBy] = useState(''); // Column name to sort by
    const [sortOrder, setSortOrder] = useState('asc'); // 'asc' or 'desc'

    const handleSort = (column) => {
        setSortBy(column);
        setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');

        let sortedTable = [...filtredRappels].sort((a, b) => {
            const aValue = a[sortBy] || '';
            const bValue = b[sortBy] || '';

            if (sortOrder === 'asc') {
                return aValue.localeCompare(bValue);
            } else {
                return bValue.localeCompare(aValue);
            }
        });

        setFiltredRappels(sortedTable);
    };

    const [openAboutList, setOpenAboutList] = useState(null);

    const toggleAboutList = (idVehicule) => {
        setOpenAboutList(openAboutList === idVehicule ? null : idVehicule);
    };

    const handlePageChange = (page) => {
        setPageNumber(page);
        //consolelog("setPageNumber="+pageNumber)
    };

    const handlePerRowsChange = (newPerPage) => {
        setPageSize(newPerPage);
        //console.log("setPageSize="+pageSize);
    };

    const handleVehicleChange = (selectedOption) => {
        setSelectedVehicle(selectedOption);
        setSelectedVehicleId(selectedOption ? selectedOption.idVehicule : '');
        filters.idVehicule = selectedVehicleId;
        filters.vehicule = selectedOption;

        const updatedFilters = { ...filters, idVehicule: selectedOption ? selectedOption.idVehicule : '' };
        setFilters(updatedFilters);
    };

    const handleTaskChange = (selectedOption) => {
        setSelectedTask(selectedOption);
        setSelectedTaskId(selectedOption ? selectedOption.idTacheService : '');
        filters.idTacheService = selectedTaskId;
        filters.tacheService = selectedOption;

        const updatedFilters = { ...filters, idTacheService: selectedOption ? selectedOption.idTacheService : '' };
        setFilters(updatedFilters);
    };

    //Supprimer rappel
    const handleConfirmDelete = (idServiceRappelle) => {
        // Show the modal when the confirm delete button is clicked
        setIdServiceRappelleDelete(idServiceRappelle);
        setConfirmDialogOpenDelete(true);
    };

    const handleDelete = async () => {
        try {
            await axiosInstance.delete(`${VehiculeServiceRappelle}/${idServiceRappelleDelete}`);
            setIdServiceRappelleDelete('');
            CustomToast('Le rappel de service a été supprimé avec succès', 'success');
            getRappels(pageNumber, pageSize);
            setConfirmDialogOpenDelete(false);
        }
        catch (error) {
            // Handle error
            if (!error?.response) {
                CustomToast("Aucune réponse du serveur", 'error');
            } else if (error.response?.status === 401) {
                CustomToast("Non autorisé", 'error');
            } else {
                CustomToast("Demande échoué", 'error');
            }
            console.log(error);
        }
    }

    const handleCancelDelete = () => {
        setConfirmDialogOpenDelete(false);
    };

    //Résoudre problème avec note
    const handleConfirmResoudreNote = (idProbleme) => {
        setIdServiceRappelleResoudreNote(idProbleme);
        setConfirmDialogOpenResoudreNote(true);
        formikResoudreNote.resetForm();
    };

    const handleCancelResoudreNote = () => {
        setConfirmDialogOpenResoudreNote(false);
    };

    const formikResoudreNote = useFormik({
        initialValues: {
            motifResoudreNote: '',
        },
        validationSchema: Yup.object({
            motifResoudreNote: Yup.string()
                .required('Champ obligatoire')
        }),
        onSubmit: async (values) => {
            try {
                const postData = {
                    idServiceRappelle: idServiceRappelleResoudreNote,
                    motif: values.motifResoudreNote,
                    idUtilisateurAction: authData.idUser
                };

                const response = await axiosInstance.put(`${VehiculeServiceRappelle}/resoudre`, postData);
                setIdServiceRappelleResoudreNote('');
                setConfirmDialogOpenResoudreNote(false);
                CustomToast('Le rappel a été résoulu avec succès', 'success');
                getRappels(pageNumber, pageSize);
            } catch (error) {
                // Handle error
                if (!error?.response) {
                    CustomToast("Aucune réponse du serveur", 'error');
                } else if (error.response?.status === 400 && error.response?.data === "Reminder does not exist") {
                    CustomToast("Rappel n'existe pas", 'error');
                } else if (error.response?.status === 401) {
                    CustomToast("Non autorisé", 'error');
                } else {
                    CustomToast("Demande échoué", 'error');
                }
                console.log(error);
            }
        }
    });

    //Load data
    const getRappels = async (page, newPerPage) => {
        try {
            setLoading(true);
            const queryParams = new URLSearchParams({
                pageNumber: page,
                pageSize: newPerPage
            });

            if (authData?.wilayas != null) {
                const wilayasFilter = authData?.wilayas
                queryParams.append("wilayas", wilayasFilter.join(','));
            }
            else {
                setLoading(false);
                return
            }

            if (authData?.flottesAutorises != null) {
                const flottesAutorisesFilter = authData?.flottesAutorises
                queryParams.append("flottesAutorises", flottesAutorisesFilter.join(','));
            }
            else {
                setLoading(false);
                return
            }

            if (filters.idVehicule !== '') {
                queryParams.append("idVehicule", filters.idVehicule);
            }

            if (filters.statut !== '') {
                queryParams.append("statut", filters.statut);
            }

            if (filters.idTacheService !== '') {
                queryParams.append("idTacheService", filters.idTacheService);
            }

            if (filters.dateDebut !== '') {
                queryParams.append("dateDebut", filters.dateDebut);
            }

            if (filters.dateFin !== '') {
                queryParams.append("dateFin", filters.dateFin);
            }

            const response = await axiosInstance.get(`${VehiculeServiceRappelle}/GetRappels?${queryParams}`);
            const resp = await response.data;
            setFiltredRappels(resp.data);
            setPageNumber(resp.pageNumber);
            setPageSize(resp.pageSize);
            setTotalItems(resp.totalItems);
            setTotalPages(resp.totalPages);
            setLoading(false);
        }
        catch (err) {
            setLoading(false);
            console.log(err);
        }
    }

    const getTaches = async (nomTache) => {
        try {
            const queryParams = new URLSearchParams({
                pageNumber: 1,
                pageSize: 10
            });

            if (nomTache !== '') {
                queryParams.append("nom", nomTache);
            }
            const response = await axiosInstance.get(`${TacheService}/GetTachesAutocomplete?${queryParams}`);
            const resp = await response.data;
            setTaches(resp.data);
        }
        catch (err) {
            console.log(err);
        }
    }

    const getVehicules = async (nomVehicule) => {
        try {
            const queryParams = new URLSearchParams({
                pageNumber: 1,
                pageSize: 10
            });

            if (authData?.wilayas != null) {
                const wilayasFilter = authData?.wilayas
                queryParams.append("wilayas", wilayasFilter.join(','));
            }
            else {
                return
            }

            if (authData?.flottesAutorises != null) {
                const flottesAutorisesFilter = authData?.flottesAutorises
                queryParams.append("flottesAutorises", flottesAutorisesFilter.join(','));
            }
            else {
                return
            }

            if (nomVehicule !== '') {
                queryParams.append("nom", nomVehicule);
            }
            const response = await axiosInstance.get(`${Vehicule}/GetVehiculesAutocomplete?${queryParams}`);
            const resp = await response.data;
            setVehicules(resp.data);
        }
        catch (err) {
            console.log(err);
        }
    }

    //Search
    const handleSearch = (e) => {
        e.preventDefault();
        setPageNumber(1); // Reset page number when new search is triggered
        getRappels(1, pageSize); // Fetch reminders with updated filters
    };

    useEffect(() => {
        //if (debouncedVehiculeText) {
        getVehicules(debouncedVehiculeText);
        //}
        return () => {
            // Perform any necessary cleanup (e.g., cancel pending requests)
        };
    }, [authData, debouncedVehiculeText]);

    useEffect(() => {
        //if (debouncedTaskText) {
        getTaches(debouncedTaskText);
        //}
        return () => {
            // Perform any necessary cleanup (e.g., cancel pending requests)
        };
    }, [debouncedTaskText]);

    useEffect(() => {
        //Load Data

        getRappels(pageNumber, pageSize);
        return () => {
            // Perform any necessary cleanup (e.g., cancel pending requests)
        };
    }, [authData, pageNumber, pageSize]);

    return (
        <section className="service-section" onClick={() => { if (openAboutList !== null) { setOpenAboutList(null) } }}>
            <div className="container">
                <div className="rappelservice-sec wrap-all">
                    <Link to="/ajouterrappel" className="rappel-service-add color-primary-2 decoration-none">
                        <div className="add-new" id="add-vehc">
                            <span className="material-symbols-outlined">add</span>
                            Ajouter rappel de service
                        </div>
                    </Link>

                    <form onSubmit={handleSearch}>
                        <div className="filter-wrap">

                            <div className="date-container">
                                <input
                                    type="date"
                                    value={filters.dateDebut}
                                    onChange={(e) => setFilters({ ...filters, dateDebut: e.target.value })}
                                    className='date-filter'
                                />
                            </div>

                            <div className="date-container">
                                <input
                                    type="date"
                                    value={filters.dateFin}
                                    onChange={(e) => setFilters({ ...filters, dateFin: e.target.value })}
                                    className='date-filter'
                                />
                            </div>

                            <Select
                                id="vehicule"
                                name="vehicule"
                                value={filters.vehicule}
                                onChange={(selectedOption) => {
                                    handleVehicleChange(selectedOption);
                                }}
                                onInputChange={(inputValue) => {
                                    // Update the entered text
                                    setEnteredVehicleText(inputValue);
                                }}
                                options={vehicules}
                                getOptionLabel={(option) => option.marque + '[' + option.modele + '] ' + option.immatriculation}
                                getOptionValue={(option) => option.idVehicule}
                                isClearable
                                placeholder="Sélectionnez véhicule"
                                styles={{
                                    control: (baseStyles, state) => ({
                                        ...baseStyles,
                                        borderRadius: '1rem',
                                        borderWidth: '1px',
                                        height: '3rem',
                                        borderColor: state.isFocused ? 'rgba(115, 128, 236, 0.9)' : '#BEBEBE',
                                        backgroundColor: '#65458d',
                                        cursor: 'pointer',
                                        color: '#fff',
                                    }),
                                    option: (provided, state) => ({
                                        ...provided,
                                        backgroundColor: state.isFocused ? '#2581DC' : '#65458d',
                                        color: '#fff',
                                        cursor: 'pointer',
                                    }),
                                    singleValue: (provided, state) => ({
                                        ...provided,
                                        color: '#fff',
                                    }),
                                    placeholder: (provided, state) => ({
                                        ...provided,
                                        color: '#fff'
                                    }),
                                    input: (provided, state) => ({
                                        ...provided,
                                        color: '#fff',
                                    }),
                                }}
                            />

                            <Select
                                id="tacheService"
                                name="tacheService"
                                value={filters.tacheService}
                                onChange={(selectedOption) => {
                                    handleTaskChange(selectedOption);
                                }}
                                options={taches}
                                onInputChange={(inputValue) => {
                                    // Update the entered text
                                    setEnteredTaskText(inputValue);
                                }}
                                getOptionLabel={(option) => option.libelle}
                                getOptionValue={(option) => option.idTacheService}
                                isClearable
                                placeholder="Sélectionnez une tâche"
                                styles={{
                                    control: (baseStyles, state) => ({
                                        ...baseStyles,
                                        borderRadius: '1rem',
                                        borderWidth: '1px',
                                        height: '3rem',
                                        borderColor: state.isFocused ? 'rgba(115, 128, 236, 0.9)' : '#BEBEBE',
                                        backgroundColor: '#65458d',
                                        cursor: 'pointer',
                                        color: '#fff',
                                    }),
                                    option: (provided, state) => ({
                                        ...provided,
                                        backgroundColor: state.isFocused ? '#2581DC' : '#65458d',
                                        color: '#fff',
                                        cursor: 'pointer',
                                    }),
                                    singleValue: (provided, state) => ({
                                        ...provided,
                                        color: '#fff',
                                    }),
                                    placeholder: (provided, state) => ({
                                        ...provided,
                                        color: '#fff'
                                    }),
                                    input: (provided, state) => ({
                                        ...provided,
                                        color: '#fff',
                                    }),
                                }}
                            />

                            <select
                                id="statut"
                                name="statut"
                                className="select-filter"
                                value={filters.statut || ''}
                                onChange={(e) => setFilters({ ...filters, statut: e.target.value || '' })}
                            >
                                <option value="" defaultValue={true}>Séléctionnez statut</option>
                                <option value="a venir">A venir</option>
                                <option value="bientot">Bientôt</option>
                                <option value="en retard">En retard</option>
                                <option value="complété">Complété</option>
                            </select>

                            <div className="filter">
                                {/* <a href="#"><span className="material-symbols-outlined">filter_alt</span></a> */}
                                <button type="submit" className="linkButton">
                                    <span className="material-symbols-outlined">filter_alt</span>
                                </button>
                            </div>
                        </div>
                    </form>

                    {filtredRappels?.length > 0 && <><div className="group-elements">
                        <div className="voir-elements">
                            <p></p>
                            <select
                                name="pageSize"
                                id="pageSize"
                                onChange={(e) => { handlePerRowsChange(e.target.value || 10); setPageNumber(1); }}
                            >
                                <option value="10" defaultValue={true}>10</option>
                                <option value="15">15</option>
                                <option value="20">20</option>
                            </select>
                        </div>
                        {/* <div className="search-element">
                            <p>Rechercher</p>
                            <input type="text" />
                        </div> */}
                    </div>
                        <div className="table-wrap">
                            <table>
                                <thead>
                                    <tr>
                                        <th onClick={() => handleSort('nomVehicule')}>Véhicule</th>
                                        <th>Tâche de service</th>
                                        <th>Prochaine échéance</th>
                                        <th>Date d'échéance</th>
                                        <th>Statut</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {filtredRappels?.map((vehicule) => (
                                        <tr key={vehicule.idServiceRappelle}>
                                            <td>{vehicule.nomVehicule + ' ' + vehicule.immatriculationVehicule}</td>
                                            <td>{vehicule.libelleTacheService}</td>
                                            <td>{vehicule.prochaineDateEcheanceDebut ? (moment(vehicule.prochaineDateEcheanceDebut).format('DD-MM-YYYY HH:mm')) : ''}</td>
                                            <td>{vehicule.prochaineDateEcheanceFin ? (moment(vehicule.prochaineDateEcheanceFin).format('DD-MM-YYYY HH:mm')) : ''}</td>
                                            <td>
                                                {
                                                    vehicule.statutRappelle === "a venir" ? (
                                                        <>
                                                            <svg height="11.87" width="15"><circle cx="6" cy="6" r="6" fill="green" /></svg>A venir
                                                        </>
                                                    ) : vehicule.statutRappelle === "bientot" ? (
                                                        <>
                                                            <svg height="11.87" width="15"><circle cx="6" cy="6" r="6" fill="blue" /></svg>Bientôt
                                                        </>
                                                    ) : vehicule.statutRappelle === "en retard" ? (
                                                        <>
                                                            <svg height="11.87" width="15"><circle cx="6" cy="6" r="6" fill="red" /></svg>En retard
                                                        </>
                                                    ) : vehicule.statutRappelle === "complété" ? (
                                                        <>
                                                            <svg height="11.87" width="15"><circle cx="6" cy="6" r="6" fill="blue" /></svg>Complété
                                                        </>
                                                    ) : (
                                                        <> {/* Default case */}
                                                        </>
                                                    )
                                                }
                                            </td>
                                            <td className="txt-center relative">
                                                <span className="material-symbols-outlined about-btn" onClick={() => toggleAboutList(vehicule.idServiceRappelle)}>
                                                    more_horiz
                                                </span>
                                                {openAboutList === vehicule.idServiceRappelle && (
                                                    <div className="about-list">
                                                        <ul>
                                                            {(vehicule.statutRappelle !== "complété") && <li>
                                                                <Link className='row row-center' to={`/modifierrappel/${vehicule.idServiceRappelle}`} style={{ textDecoration: "none" }}>
                                                                    <span className="material-symbols-outlined">edit</span>Modifier
                                                                </Link>
                                                            </li>}

                                                            {(vehicule.statutRappelle === "complété") && <li>
                                                                <Link className='row row-center' to="#" style={{ textDecoration: "none", color: "#BEBEBE", cursor: 'not-allowed' }} onClick={(event) => event.preventDefault()}>
                                                                    <span className="material-symbols-outlined">edit</span>Modifier
                                                                </Link>
                                                            </li>}

                                                            {(vehicule.statutRappelle !== "complété") && <li>
                                                                <Link className='row row-center' to="/listrappels" style={{ textDecoration: "none" }} onClick={() => handleConfirmResoudreNote(vehicule.idServiceRappelle)} title='Résoudre avec une note lorsque le travil est terminé'>
                                                                    <span className="material-symbols-outlined">check_circle</span>Résoudre avec note
                                                                </Link>
                                                            </li>}

                                                            {(vehicule.statutRappelle === "complété") && <li>
                                                                <Link className='row row-center' to="#" style={{ textDecoration: "none", color: "#BEBEBE", cursor: 'not-allowed' }} onClick={(event) => event.preventDefault()} title='Résoudre avec une note lorsque le travil est terminé'>
                                                                    <span className="material-symbols-outlined">check_circle</span>Résoudre avec note
                                                                </Link>
                                                            </li>}

                                                            <li>
                                                                <Link className='row row-center' to="/listrappels" style={{ textDecoration: "none" }} onClick={() => handleConfirmDelete(vehicule.idServiceRappelle)}>
                                                                    <span className="material-symbols-outlined">delete</span>Supprimer
                                                                </Link>
                                                            </li>

                                                        </ul>
                                                    </div>
                                                )}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        {/* <div className="pagination">
                            <Link to="#" onClick={() => handlePageChange(pageNumber - 1)}
                                className={(pageNumber === 1) ? 'pgn disabled' : 'pgn'}
                                disabled={(pageNumber === 1) ? true : false}
                            >&laquo;</Link>
                            {Array.from({ length: totalPages }, (_, i) => (
                                <Link
                                    key={i + 1}
                                    to="#"
                                    className={i + 1 === pageNumber ? 'pgn active' : 'pgn'}
                                    onClick={() => handlePageChange(i + 1)}
                                >
                                    {i + 1}
                                </Link>
                            ))}
                            <Link to="#" onClick={() => handlePageChange(pageNumber + 1)}
                                className={((pageNumber + 1) > totalPages) ? 'pgn disabled' : 'pgn'}
                                disabled={((pageNumber + 1) > totalPages) ? true : false}
                            >&raquo;</Link>
                        </div> */}
                        <div className="pagination">
                            <Link to="#" onClick={() => handlePageChange(pageNumber - 1)}
                                className={(pageNumber === 1) ? 'pgn disabled' : 'pgn'}
                                disabled={(pageNumber === 1) ? true : false}
                            >&laquo;</Link>
                            {Array.from({ length: Math.min(5, totalPages) }, (_, i) => {
                                const page = pageNumber - 2 + i;
                                return page <= totalPages && page > 0 && (
                                    <Link
                                        key={page}
                                        to="#"
                                        className={pageNumber === page ? 'pgn active' : 'pgn'}
                                        onClick={() => handlePageChange(page)}
                                    >
                                        {page}
                                    </Link>
                                );
                            })}
                            <Link to="#" onClick={() => handlePageChange(pageNumber + 1)}
                                className={(pageNumber === totalPages || totalPages === 0) ? 'pgn disabled' : 'pgn'}
                                disabled={(pageNumber === totalPages || totalPages === 0) ? true : false}
                            >&raquo;</Link>
                            <span> Affichage de {(pageNumber === 1)?1:((pageNumber - 1) * pageSize+1)} à {pageNumber * pageSize} sur {totalItems} entrées</span>
                        </div>
                    </>}

                    <ConfirmationDialog
                        open={confirmDialogOpenDelete}
                        onClose={handleCancelDelete}
                        onConfirm={handleDelete}
                        titleMessage="Confirmer la suppression du rappel de service"
                        bodyMessage="Voulez-vous vraiment supprimer ce rappel de service? Toutes les données seront définitivement perdues."
                        btnCancelText="Annuler"
                        btnConfirmText="Supprimer le rappel de service"
                    />

                    {confirmDialogOpenResoudreNote && <div className="popcard-add">
                        <div className="popcard-add_inside">
                            <Link className="popcard-add_inside-close" to="#" onClick={handleCancelResoudreNote}>&#10006;</Link>
                            <h2 className="popcard-add_inside-title">Résoudre le rappel</h2>
                            <div className="popcard-add_inside-form">
                                <form onSubmit={formikResoudreNote.handleSubmit}>
                                    <div className="wrap-info">
                                        <div className="wrap-one">
                                            <label>Commentaire<span className='requiredField'>*</span></label>
                                            <textarea type="text"
                                                id="motifResoudreNote"
                                                name="motifResoudreNote"
                                                placeholder=""
                                                defaultValue={formikResoudreNote.values.motifResoudreNote}
                                                onChange={formikResoudreNote.handleChange}
                                                rows={4}
                                                style={(formikResoudreNote.touched.motifResoudreNote && formikResoudreNote.errors.motifResoudreNote) ? { borderColor: "red" } : null}
                                            />
                                            {formikResoudreNote.touched.motifResoudreNote && formikResoudreNote.errors.motifResoudreNote && (
                                                <div className="invalid-feedback">{formikResoudreNote.errors.motifResoudreNote}</div>
                                            )}
                                        </div>
                                    </div>

                                    <div className="popcard-operation-pickup-details_inside-zone">
                                        <div className="buttons">
                                            <input type="submit" value="Enregistrer" className="submit-btn" />
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>}

                </div>
            </div>
        </section>
    );
}

export default ListRappels;
