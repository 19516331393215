import React, { useEffect, useState, useContext, useRef } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import moment from 'moment/moment';
import Select from "react-select";
import { CustomToast } from '../../components/Toast/CustomToast';
import { axiosInstance, Vehicule, VehiculeCarburant, VehiculeTypeCarburant, Fournisseur, ModePaiement } from '../../services/api';
import { AuthContext } from '../../contexts/AuthContext';
const ModifierCarburant = () => {
    const { authData } = useContext(AuthContext);
    const navigate = useNavigate();
    const { id } = useParams();//idConsommationCarburant

    const [isLoaded, setIsLoaded] = useState();
    const [typesCarburant, setTypesCarburant] = useState([]);
    const [fournisseurs, setFournisseurs] = useState([]);
    const [modePaiements, setModePaiements] = useState([]);

    const pathImageBonCarburantRef = useRef(null);
    const [pathImageBonCarburant, setPathImageBonCarburant] = useState(null);
    const [pathImageBonCarburantName, setPathImageBonCarburantName] = useState(null);

    //Errors handle
    const [kiloDebLess, setKiloDebLess] = useState({ display: false, msg: "" });//back
    const [kiloDebGreater, setKiloDebGreater] = useState({ display: false, msg: "" });//back
    const [kiloDebBetween, setKiloDebBetween] = useState({ display: false, msg: "" });//back

    const getImageFileName = (file) => {
        return file ? file.name : 'No file selected';
    };

    //Load data

    const handlePathImageBonCarburantChange = (e) => {
        setPathImageBonCarburant(e.target.files[0]);
    };

    const getFournisseurs = async () => {
        try {
            const queryParams = new URLSearchParams({
                carburant: true
            });

            const response = await axiosInstance.get(`${Fournisseur}/GetFournisseurType?${queryParams}`);
            const resp = await response.data;
            setFournisseurs(resp);
        }
        catch (err) {
            console.log(err);
        }
    }

    const getTypesCarburant = async () => {
        try {
            const response = await axiosInstance.get(`${VehiculeTypeCarburant}`);
            const resp = await response.data;
            setTypesCarburant(resp);
        }
        catch (err) {
            console.log(err);
        }
    }

    const getVehicules = async (idVehicule) => {
        try {
            console.log("getVehicules")
            //if(idVehicule==''){return}
            const response = await axiosInstance.get(`${Vehicule}/GetById/${idVehicule}`);
            const resp = await response.data;

            setFieldValue('vehicule', resp.nom + ' ' + resp.immatriculation || '');
        }
        catch (err) {
            console.log(err);
        }
    }

    const getModesPaiements = async () => {
        try {
            const response = await axiosInstance.get(`${ModePaiement}`);
            const resp = await response.data;
            setModePaiements(resp);
        }
        catch (err) {
            console.log(err);
        }
    }

    const getCarburantVehicule = async (idConsommationCarburant) => {
        try {
            const response = await axiosInstance.get(`${VehiculeCarburant}/${idConsommationCarburant}`);
            const resp = await response.data;
            setIsLoaded(resp);
            // values.idVehicule = resp.idVehicule;
            // values.idTypeCarburant = resp.idTypeCarburant;
            // values.quantite = resp.quantite;
            // values.cout = resp.cout;
            // values.dateRemplissage = moment(resp.dateRemplissage).format('YYYY-MM-DD') || '';
            // values.valeurCompteur = resp.valeurCompteur;
            // values.idFournisseur = resp.idFournisseur || '';
            // values.observation = resp.observation || '';
            // values.reference = resp.reference || '';
            setValues(prevValues => ({
                ...prevValues,
                vehicule: '',
                idVehicule: resp.idVehicule || '',
                idTypeCarburant: resp.idTypeCarburant || '',
                quantite: resp.quantite || '',
                cout: resp.cout || '',
                dateRemplissage: moment(resp.dateRemplissage).isValid() ? moment(resp.dateRemplissage).format('YYYY-MM-DD') : '' || '',
                valeurCompteur: resp.valeurCompteur || '',
                idFournisseur: resp.idFournisseur || '',
                observation: resp.observation || '',
                reference: resp.reference || '',
                idModePaiement: resp.idModePaiement || ''
            }));
            setPathImageBonCarburantName(resp.pathImageBonCarburant || '')
            // setFieldValue('idTypeCarburant',resp.idTypeCarburant);
            //values.vehicule = '';

        }
        catch (err) {
            console.log(err);
        }
    }

    const getTypeCarburant = async (idTypeCarburant) => {
        try {
            const response = await axiosInstance.get(`${VehiculeTypeCarburant}/${idTypeCarburant}`);
            const resp = await response.data;
        }
        catch (err) {
            console.log(err);
        }
    }

    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            borderColor: state.isFocused ? 'blue' : 'gray',
            width: '50%',
            padding: '.3rem',
            borderRadius: '.5rem',
            outline: 'none',
            border: '1.5px solid #BEBEBE',
            transition: 'all .2s ease-in-out',
        }),
        // Add other style properties for the other elements of the Select component
        // For example, you can style the menu, option, etc.
    };

    const { handleChange, values, handleSubmit, touched, errors, setFieldValue, setValues } = useFormik({
        initialValues: {
            vehicule: '',
            idVehicule: '',
            idTypeCarburant: '',
            quantite: '',
            cout: '',
            dateRemplissage: '',
            valeurCompteur: '',
            idFournisseur: '',
            observation: '',
            reference: '',
            idModePaiement: ''
        },
        validationSchema: Yup.object({
            dateRemplissage: Yup.string()
                .required("Champ obligatoire"),
            idTypeCarburant: Yup.string()
                .required("Champ obligatoire"),
            quantite: Yup.number()
                .min(0, "Le volume ne peut pas être négative"),
            cout: Yup.number()
                .required("Champ obligatoire")
                .min(0, "Le coût ne peut pas être négative"),
            valeurCompteur: Yup.number()
                .required("Champ obligatoire")
                .min(0, "Le relevé kilométrique ne peut pas être négative"),

        }),
        onSubmit: async (values) => {
            try {
                // const postData = {
                //     idConsommationCarburant: id,
                //     idVehicule: values.vehicule.idVehicule,
                //     idTypeCarburant: values.idTypeCarburant,
                //     quantite: values.quantite,
                //     cout: values.cout.toString() || '',
                //     dateRemplissage: values.dateRemplissage,
                //     valeurCompteur: values.valeurCompteur,
                //     idFournisseur: values.idFournisseur,
                //     observation: values.observation,
                //     reference: values.reference,
                //     pathImageBonCarburant: pathImageBonCarburant,
                //     idUtilisateur: authData.idUser,
                // };
                // console.log(postData);
                const formData = new FormData();
                formData.append('idConsommationCarburant', id);
                formData.append('idVehicule', values.idVehicule);
                formData.append('idTypeCarburant', values.idTypeCarburant);
                formData.append('quantite', values.quantite.toString());
                formData.append('cout', values.cout.toString());

                formData.append('dateRemplissage', values.dateRemplissage);
                formData.append('valeurCompteur', values.valeurCompteur.toString());
                formData.append('idFournisseur', values.idFournisseur);
                formData.append('observation', values.observation);
                formData.append('reference', values.reference);
                formData.append('idModePaiement', values.idModePaiement);
                formData.append('pathImageBonCarburant', pathImageBonCarburant);
                formData.append('pathImageBonCarburantName', pathImageBonCarburantName);
                formData.append('idUtilisateur', authData.idUser);

                const response = await axiosInstance.put(VehiculeCarburant, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });

                CustomToast("L'entrée de carburant a été modifiée avec succès", 'success');
                navigate('/listcarburants', { replace: true });
            } catch (error) {
                // Handle error
                if (!error?.response) {
                    CustomToast("Aucune réponse du serveur", 'error');

                    setKiloDebGreater({ display: false, msg: "" });
                    setKiloDebLess({ display: false, msg: "" });
                    setKiloDebBetween({ display: false, msg: "" });
                } else if (error.response?.status === 400 && error.response?.data === "Vehicle not found") {
                    CustomToast("Véhicule introuvable", 'error');;

                    setKiloDebGreater({ display: false, msg: "" });
                    setKiloDebLess({ display: false, msg: "" });
                    setKiloDebBetween({ display: false, msg: "" });
                } else if (error.response?.status === 400 && error.response?.data?.msg === "Incorrect start value, must be greater") {
                    CustomToast("L'entrée de carburant n'a pas pu être créée", 'error');
                    setKiloDebGreater({ display: true, msg: `Doit être supérieur à ${error.response?.data?.val1}` });

                    setKiloDebLess({ display: false, msg: "" });
                    setKiloDebBetween({ display: false, msg: "" });
                } else if (error.response?.status === 400 && error.response?.data?.msg === "Incorrect start value, must be less") {
                    CustomToast("L'entrée de carburant n'a pas pu être créée", 'error');
                    setKiloDebLess({ display: true, msg: `Doit être inférieur à ${error.response?.data?.val1}` });

                    setKiloDebGreater({ display: false, msg: "" });
                    setKiloDebBetween({ display: false, msg: "" });
                } else if (error.response?.status === 400 && error.response?.data?.msg === "Incorrect start value, must be between") {
                    CustomToast("L'entrée de carburant n'a pas pu être créée", 'error');
                    setKiloDebBetween({ display: true, msg: `Doit être entre ${error.response?.data?.val1} et ${error.response?.data?.val2}` });

                    setKiloDebGreater({ display: false, msg: "" });
                    setKiloDebLess({ display: false, msg: "" });
                } else if (error.response?.status === 400 && error.response?.data?.msg === "Incorrect end value, must be greater") {
                    CustomToast("L'entrée de carburant n'a pas pu être créée", 'error');
                    setKiloDebGreater({ display: true, msg: `Doit être supérieur à ${error.response?.data?.val1}` });

                    setKiloDebLess({ display: false, msg: "" });
                    setKiloDebBetween({ display: false, msg: "" });
                } else if (error.response?.status === 400 && error.response?.data?.msg === "Incorrect end value, must be less") {
                    CustomToast("L'entrée de carburant n'a pas pu être créée", 'error');
                    setKiloDebLess({ display: true, msg: `Doit être inférieur à ${error.response?.data?.val1}` });

                    setKiloDebGreater({ display: false, msg: "" });
                    setKiloDebBetween({ display: false, msg: "" });
                } else if (error.response?.status === 400 && error.response?.data?.msg === "Incorrect end value, must be between") {
                    CustomToast("L'entrée de carburant n'a pas pu être créée", 'error');
                    setKiloDebBetween({ display: true, msg: `Doit être entre ${error.response?.data?.val1} et ${error.response?.data?.val2}` });

                    setKiloDebGreater({ display: false, msg: "" });
                    setKiloDebLess({ display: false, msg: "" });
                } else if (error.response?.status === 401) {
                    CustomToast("Non autorisé", 'error');

                    setKiloDebGreater({ display: false, msg: "" });
                    setKiloDebLess({ display: false, msg: "" });
                    setKiloDebBetween({ display: false, msg: "" });
                } else {
                    CustomToast("Demande échoué", 'error');

                    setKiloDebGreater({ display: false, msg: "" });
                    setKiloDebLess({ display: false, msg: "" });
                    setKiloDebBetween({ display: false, msg: "" });
                }
                console.log(error);
            }
        }
    });

    useEffect(() => {
        //Load type de carburant
        getTypesCarburant();
        //Load Fournisseurs
        getFournisseurs();
        //Load modes de paements
        getModesPaiements();
        //Load entrée carburant
        getCarburantVehicule(id);
        // if(values.vehicule){
        //     getTypeCarburantVehicule(values.vehicule.idVehicule);
        // }
    }, [id])

    useEffect(() => {
        // console.log("depenseLoaded");
        if (values.idVehicule !== '' && values.vehicule === '') {
            getVehicules(values.idVehicule);
        }

    }, [isLoaded])
    // useEffect(() => {
    //     getTypeCarburant(values.idTypeCarburant);
    //     console.log(values.idTypeCarburant);
    // }, [id,values.idTypeCarburant])

    // if (values.idVehicule != '' && values.vehicule == '') {
    //     //console.log("useEffect 1");
    //     getVehicules(values.idVehicule);
    // }

    return (
        <section className="vehicles-section">
            <div className="container">
                <form onSubmit={handleSubmit} className='carburant-form'>
                    <div className="">
                        <div className='grid grid--2-cols'>
                            <div className="form-sec">
                                <div className="retour" id="retour-carburant">
                                    <Link to="/listcarburants" style={{ textDecoration: "none" }}>
                                        <div className="arrow-back" id="arrow-back-affectation-form">
                                            <span className="material-symbols-outlined" id="arrow">arrow_back</span>
                                            <h3 className="heading-primary"></h3>
                                        </div>
                                    </Link>
                                </div>
                                {isLoaded && <div className="form-details">
                                    <div className="detail-form" id="identification-form">
                                        <h3 className="heading-primary"></h3>
                                        <div className="col">
                                            <div className="grp">
                                                <div className="gp">
                                                    <label >Véhicule<span className='requiredField'>*</span></label>
                                                    <input
                                                        id="vehicule"
                                                        name="vehicule"
                                                        type='text'
                                                        defaultValue={values.vehicule}
                                                        disabled={true}
                                                    />
                                                </div>
                                            </div>
                                            <div className="grp">
                                                <div className="gp">
                                                    <label >Date d'entrée du carburant<span className='requiredField'>*</span></label>
                                                    <input
                                                        type="date"
                                                        id="dateRemplissage"
                                                        name="dateRemplissage"
                                                        style={(touched.dateRemplissage && errors.dateRemplissage) ? { borderColor: "red" } : null}
                                                        placeholder=""
                                                        defaultValue={values.dateRemplissage}
                                                        onChange={handleChange}
                                                    />
                                                    {touched.dateRemplissage && errors.dateRemplissage ? (
                                                        <div className="invalid-feedback">{errors.dateRemplissage}</div>
                                                    ) : null}
                                                </div>
                                                <div className="gp">
                                                    <label >Compteur<span className='requiredField'>*</span></label>
                                                    <input
                                                        type="number"
                                                        id="valeurCompteur"
                                                        name="valeurCompteur"
                                                        placeholder=""
                                                        defaultValue={values.valeurCompteur}
                                                        onChange={handleChange}
                                                        step="any"
                                                    />
                                                    {errors.valeurCompteur && touched.valeurCompteur && (
                                                        <div className="invalid-feedback">{errors.valeurCompteur}</div>
                                                    )}
                                                    {kiloDebLess.display && (
                                                        <div className="invalid-feedback">{kiloDebLess.msg}</div>
                                                    )}
                                                    {kiloDebGreater.display && (
                                                        <div className="invalid-feedback">{kiloDebGreater.msg}</div>
                                                    )}
                                                    {kiloDebBetween.display && (
                                                        <div className="invalid-feedback">{kiloDebBetween.msg}</div>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="grp">
                                                <div className="gp">
                                                    <label >Type de carburant<span className='requiredField'>*</span></label>
                                                    <select
                                                        id="idTypeCarburant"
                                                        name="idTypeCarburant"
                                                        value={values.idTypeCarburant}
                                                        onChange={handleChange}
                                                        style={(touched.idTypeCarburant && errors.idTypeCarburant) ? { borderColor: "red" } : null}
                                                    >
                                                        <option value="">Veuillez sélectionner</option>
                                                        {typesCarburant.map((typeCarburant) => (
                                                            <option key={typeCarburant.idTypeCarburant} value={typeCarburant.idTypeCarburant}>{typeCarburant.libelleTypeCarburant}</option>
                                                        ))}
                                                    </select>
                                                    {touched.idTypeCarburant && errors.idTypeCarburant ? (
                                                        <div className="invalid-feedback">{errors.idTypeCarburant}</div>
                                                    ) : null}
                                                </div>
                                                <div className="gp" title='Le volume de carburant'>
                                                    <label>Volume</label>
                                                    <input
                                                        type="text"
                                                        id="quantite"
                                                        name="quantite"
                                                        placeholder=""
                                                        defaultValue={values.quantite}
                                                        onChange={handleChange}
                                                        step="any"
                                                    />
                                                    {errors.quantite && touched.quantite && (
                                                        <div className="invalid-feedback">{errors.quantite}</div>
                                                    )}
                                                </div>
                                                <div className="gp">
                                                    <label>Coût<span className='requiredField'>*</span></label>
                                                    <input
                                                        type="number"
                                                        id="cout"
                                                        name="cout"
                                                        placeholder=""
                                                        defaultValue={values.cout}
                                                        onChange={handleChange}
                                                        step="any"
                                                    />
                                                    {errors.cout && touched.cout && (
                                                        <div className="invalid-feedback">{errors.cout}</div>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="grp">
                                                <div className="gp">
                                                    <label >Mode de paiement</label>
                                                    <select
                                                        id="idModePaiement"
                                                        name="idModePaiement"
                                                        value={values.idModePaiement}
                                                        onChange={handleChange}
                                                    >
                                                        <option value="">Veuillez sélectionner</option>
                                                        {modePaiements.map((modeP) => (
                                                            <option key={modeP.idModePaiement} value={modeP.idModePaiement}>{modeP.libelleModePaiement}</option>
                                                        ))}
                                                    </select>
                                                </div>
                                                <div className="gp">
                                                    <label >Fournisseur</label>
                                                    <select
                                                        id="idFournisseur"
                                                        name="idFournisseur"
                                                        value={values.idFournisseur}
                                                        onChange={handleChange}
                                                    >
                                                        <option value="">Veuillez sélectionner</option>
                                                        {fournisseurs.map((fournisseur) => (
                                                            <option key={fournisseur.idFournisseur} value={fournisseur.idFournisseur}>{fournisseur.raisonSociale}</option>
                                                        ))}
                                                    </select>
                                                </div>
                                                <div className="gp" title='Par exemple: numéro de facture, ID de transaction, etc'>
                                                    <label>Référence</label>
                                                    <input
                                                        type="text"
                                                        id="reference"
                                                        name="reference"
                                                        placeholder=""
                                                        defaultValue={values.reference}
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                            </div>
                                            <div className="grp">
                                                <div className="gp" title='Par exemple: facture, ticket, etc'>
                                                    <label>Justificatif</label>
                                                    <label class="upload-file">
                                                        upload file
                                                        <input
                                                            ref={pathImageBonCarburantRef}
                                                            id="pathImageBonCarburant"
                                                            name="pathImageBonCarburant"
                                                            type="file"
                                                            onChange={handlePathImageBonCarburantChange}
                                                        />
                                                    </label>
                                                    {pathImageBonCarburant && <span style={{ fontSize: "10px" }}>{getImageFileName(pathImageBonCarburant)}
                                                        <span className="material-symbols-outlined" onClick={(e) => { setPathImageBonCarburant(null); if (pathImageBonCarburantRef.current) { pathImageBonCarburantRef.current.value = ''; } }}>delete</span>
                                                    </span>}
                                                    {pathImageBonCarburantName && <span style={{ fontSize: "10px" }}>{pathImageBonCarburantName}
                                                        <span className="material-symbols-outlined" onClick={(e) => { setPathImageBonCarburant(null); setPathImageBonCarburantName(''); if (pathImageBonCarburantRef.current) { pathImageBonCarburantRef.current.value = ''; } }}>delete</span>
                                                    </span>}
                                                </div>
                                            </div>
                                            <div className="grp">
                                                <div className="gp">
                                                    <label>Remarques</label>
                                                    <textarea
                                                        type="text"
                                                        rows={4}
                                                        id="observation"
                                                        name="observation"
                                                        placeholder=""
                                                        defaultValue={values.observation}
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="submit-container">
                                        <button type="submit" className="btn-submit-form-new">Enregistrer</button>
                                    </div>
                                </div>}
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </section>
    );
}

export default ModifierCarburant;
