import React, { useEffect, useState, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { CustomToast } from '../../../components/Toast/CustomToast';
import ConfirmationDialog from '../../../components/Confirmation/ConfirmationDialog';
import { axiosInstance, TacheService } from '../../../services/api';
import { AuthContext } from '../../../contexts/AuthContext';

const ListTaches = () => {
    const { authData } = useContext(AuthContext);
    const navigate = useNavigate();

    const [taches, setTaches] = useState([]);
    const [tache, setTache] = useState([]);
    const [filtredTaches, setFiltredTaches] = useState([]);
    const [pageNumber, setPageNumber] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalItems, setTotalItems] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [loading, setLoading] = useState(true);
    const [showAddModal, setShowAddModal] = useState(false);
    const [showUpdateModal, setShowUpdateModal] = useState(false);
    const [showDetailsModal, setShowDetailsModal] = useState(false);
    const [idTacheServiceDelete, setIdTacheServiceDelete] = useState('');
    const [idTacheServiceDetails, setIdTacheServiceDetails] = useState('');
    const [idTacheServiceUpdate, setIdTacheServiceUpdate] = useState('');

    const [search, setSearch] = useState('');

    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
    const [confirmDialogOpenArchive, setConfirmDialogOpenArchive] = useState(false);

    //Table management
    const [sortBy, setSortBy] = useState(''); // Column name to sort by
    const [sortOrder, setSortOrder] = useState('asc'); // 'asc' or 'desc'

    const handleSort = (column) => {
        setSortBy(column);
        setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');

        let sortedTable = [...filtredTaches].sort((a, b) => {
            const aValue = a[sortBy] || '';
            const bValue = b[sortBy] || '';

            if (sortOrder === 'asc') {
                return aValue.localeCompare(bValue);
            } else {
                return bValue.localeCompare(aValue);
            }
        });

        setFiltredTaches(sortedTable);
    };

    const [openAboutList, setOpenAboutList] = useState(null);

    const toggleAboutList = (idVehicule) => {
        setOpenAboutList(openAboutList === idVehicule ? null : idVehicule);
    };

    const handlePageChange = (page) => {
        setPageNumber(page);
        //consolelog("setPageNumber="+pageNumber)
    };

    const handlePerRowsChange = (newPerPage) => {
        setPageSize(newPerPage);
        //console.log("setPageSize="+pageSize);
    };

    const showHideAddModal = (val) => {
        setShowAddModal(val);
        setIdTacheServiceUpdate('');
        formik1.resetForm();
        if (val) {
            formik1.values.statut = "actif";
        }
    };

    const showHideUpdateModal = (val, idTacheService, libelle, description, statut) => {
        setShowUpdateModal(val);
        setIdTacheServiceUpdate(idTacheService);
        formik2.resetForm();
        if (val) {
            formik2.values.libelleUpdate = libelle || '';
            formik2.values.descriptionUpdate = description || '';
            formik2.values.statutUpdate = statut || 'actif';
        }
    };

    const showHideDetailsModal = (val) => {
        setShowDetailsModal(val);
        formik3.resetForm();
        if (val) {
            getTache(idTacheServiceDetails);
        } else {
            setIdTacheServiceDetails('');
            setTache(null);
        }
    };

    //Supprimer une tache
    const deleteTache = async (idTacheService) => {
        try {
            const response = await axiosInstance.delete(`${TacheService}/${idTacheService}`);
            getTaches(pageNumber, pageSize);
            CustomToast("La tâche a été supprimée avec succès", 'success');
        }
        catch (error) {
            // Handle error
            if (!error?.response) {
                CustomToast("Aucune réponse du serveur", 'error');
            } else if (error.response?.status === 400 && error.response?.data === "Task is already used. Cannot delete") {
                CustomToast("La tâche en cours d'utilisation. Impossible de suprimer", 'error');
            } else if (error.response?.status === 401) {
                CustomToast("Non autorisé", 'error');
            } else {
                CustomToast("Demande échoué", 'error');
            }
            console.log(error);
        }
    }

    const handleConfirmDelete = (idTacheService) => {
        // Show the modal when the confirm delete button is clicked
        //deleteTache(idTacheService);
        setIdTacheServiceDelete(idTacheService);
        //setIdParcDeleteDetails(idTacheService);
        setConfirmDialogOpen(true);
    };

    const handleDelete = async () => {
        try {
            const response = await axiosInstance.delete(`${TacheService}/${idTacheServiceDelete}`);
            setIdTacheServiceDelete('');
            setIdTacheServiceDetails('');
            CustomToast("La tâche a été supprimée avec succès", 'success');
            getTaches(pageNumber, pageSize);
            setConfirmDialogOpen(false);
        }
        catch (error) {
            // Handle error
            if (!error?.response) {
                CustomToast("Aucune réponse du serveur", 'error');
            } else if (error.response?.status === 400 && error.response?.data === "Task is already used. Cannot delete") {
                CustomToast(<div>La tâche est en cours d'utilisation et ne peut pas être supprimée. <a className='act' style={{ textDecoration: 'underline' }} onClick={() => showHideDetailsModal(true)}>Afficher l'utilisation</a></div>, 'error');
            } else if (error.response?.status === 401) {
                CustomToast("Non autorisé", 'error');
            } else {
                CustomToast("Demande échoué", 'error');
            }
            handleCancelDelete();

            console.log(error);
        }
    }

    const handleCancelDelete = () => {
        setConfirmDialogOpen(false);
    };

    //archiver tache
    const archiveTache = async (idTacheService) => {
        try {

            const request = {
                idTacheService: idTacheService,
                idUtilisateur: authData.idUser
            }
            const response = await axiosInstance.put(`${TacheService}/archive`, request);
            getTaches(pageNumber, pageSize);
            CustomToast('La tâche a été archivée avec succès', 'success');
        }
        catch (err) {
            console.log(err);
        }
    }

    const handleConfirmArchive = (idTacheService) => {
        // Show the modal when the confirm delete button is clicked
        //archiveTache(idTacheService);
        setIdTacheServiceDelete(idTacheService);
        setConfirmDialogOpenArchive(true);
    };

    const handleArchive = async () => {
        try {
            const request = {
                idTacheService: idTacheServiceDelete,
                idUtilisateur: authData.idUser
            }
            const response = await axiosInstance.put(`${TacheService}/archive`, request);
            setIdTacheServiceDelete('');
            CustomToast('La tâche a été archivée avec succès', 'success');
            getTaches(pageNumber, pageSize);
            setConfirmDialogOpenArchive(false);
        }
        catch (error) {
            // Handle error
            if (!error?.response) {
                CustomToast("Aucune réponse du serveur", 'error');
            } else if (error.response?.status === 400 && error.response?.data === "Task does not exist") {
                CustomToast("La tâche n'existe pas", 'error');
            } else if (error.response?.status === 401) {
                CustomToast("Non autorisé", 'error');
            } else {
                CustomToast("Demande échoué", 'error');
            }
            console.log(error);
        }
    }

    const handleCancelArchive = () => {
        setConfirmDialogOpenArchive(false);
    };

    //restaurer tache
    const restoreTache = async (idTacheService) => {
        try {

            const request = {
                idTacheService: idTacheService,
                idUtilisateur: authData.idUser
            }
            const response = await axiosInstance.put(`${TacheService}/restore`, request);
            getTaches(pageNumber, pageSize);
            CustomToast('La tâche a été restaurée avec succès', 'success');
        }
        catch (err) {
            console.log(err);
        }
    }

    const handleConfirmRestore = (idTacheService) => {
        // Show the modal when the confirm delete button is clicked
        restoreTache(idTacheService);
        setIdTacheServiceDelete(idTacheService);
    };

    const handleRestore = async () => {
        try {
            const request = {
                idTacheService: idTacheServiceDelete,
                idUtilisateur: authData.idUser
            }
            const response = await axiosInstance.put(`${TacheService}/restore`, request);
            setIdTacheServiceDelete('');
            CustomToast('La tâche a été restaurée avec succès', 'success');
            getTaches(pageNumber, pageSize);
        }
        catch (error) {
            // Handle error
            if (!error?.response) {
                CustomToast("Aucune réponse du serveur", 'error');
            } else if (error.response?.status === 400 && error.response?.data === "Task does not exist") {
                CustomToast("La tâche n'existe pas", 'error');
            } else if (error.response?.status === 401) {
                CustomToast("Non autorisé", 'error');
            } else {
                CustomToast("Demande échoué", 'error');
            }
            console.log(error);
        }
    }

    //Load data
    const getTaches = async (page, newPerPage) => {
        try {
            setLoading(true);
            const queryParams = new URLSearchParams({
                pageNumber: page,
                pageSize: newPerPage
            });

            // const response = await axiosInstance.get(`${TacheService}`);
            // const resp = await response.data;

            // setTaches(resp);
            // if (search !== '') {
            //     const result = taches?.filter((item) =>
            //         Object.values(item).some(
            //             (value) =>
            //                 value &&
            //                 value.toString().toLowerCase().includes(search.toLowerCase())
            //         )
            //     );
            //     setFiltredTaches(result);
            // } else {
            //     setFiltredTaches(resp);
            // }

            // setLoading(false);

            const response = await axiosInstance.get(`${TacheService}/GetTaches?${queryParams}`);
            const resp = await response.data;
            setTaches(resp.data);
            setFiltredTaches(resp.data);
            setPageNumber(resp.pageNumber);
            setPageSize(resp.pageSize);
            setTotalItems(resp.totalItems);
            setTotalPages(resp.totalPages);
            setLoading(false);
        }
        catch (err) {
            setLoading(false);
            console.log(err);
        }
    }

    const getTache = async (idTacheService) => {
        try {
            const queryParams = new URLSearchParams({
                idTacheService: idTacheService
            });
            const response = await axiosInstance.get(`${TacheService}/GetTacheUsage?${queryParams}`);
            const resp = await response.data;
            formik3.values.countVehicule = resp[0].countVehicule;
        }
        catch (err) {
            console.log(err);
        }
    }

    //Ajouter une tâche
    const formik1 = useFormik({
        initialValues: {
            libelle: '',
            description: '',
            statut: "actif",
        },
        validationSchema: Yup.object({
            libelle: Yup.string()
                .required('Champ obligatoire'),
            statut: Yup.string()
                .required('Champ obligatoire')

        }),
        onSubmit: async (values) => {
            try {
                const postData = {
                    libelle: values.libelle,
                    description: values.description,
                    statut: values.statut,
                    idUtilisateurAction: authData.idUser
                };

                const response = await axiosInstance.post(TacheService, postData);
                CustomToast("La tâche a été créé avec succès", 'success');
                getTaches(pageNumber, pageSize);
                setShowAddModal(false);
            } catch (error) {
                // Handle error
                if (!error?.response) {
                    CustomToast("Aucune réponse du serveur", 'error');
                } else if (error.response?.status === 400 && error.response?.data === "Task existe") {
                    CustomToast("Tâche existe", 'error');
                } else if (error.response?.status === 401) {
                    CustomToast("Non autorisé", 'error');
                } else {
                    CustomToast("Demande échoué", 'error');
                }
                console.log(error);
            }
        }
    });

    //Modifier une tâche
    const formik2 = useFormik({
        initialValues: {
            libelleUpdate: '',
            descriptionUpdate: '',
            statutUpdate: "actif",
        },
        validationSchema: Yup.object({
            libelleUpdate: Yup.string()
                .required('Champ obligatoire'),
            statutUpdate: Yup.string()
                .required('Champ obligatoire')

        }),
        onSubmit: async (values) => {
            try {
                const postData = {
                    idTacheService: idTacheServiceUpdate,
                    libelle: values.libelleUpdate,
                    description: values.descriptionUpdate,
                    statut: values.statutUpdate,
                    idUtilisateurAction: authData.idUser
                };

                //console.log(postData);               
                const response = await axiosInstance.put(TacheService, postData);
                CustomToast("La tâche a été modifiée avec succès", 'success');
                showHideUpdateModal(false, '', '', '', '');
                getTaches(pageNumber, pageSize);
            } catch (error) {
                // Handle error
                if (!error?.response) {
                    CustomToast("Aucune réponse du serveur", 'error');
                } else if (error.response?.status === 400 && error.response?.data === "Task existe") {
                    CustomToast("Tâche existe", 'error');
                } else if (error.response?.status === 401) {
                    CustomToast("Non autorisé", 'error');
                } else {
                    CustomToast("Demande échoué", 'error');
                }
                console.log(error);
            }
        }
    });

    //Details d'une tâche
    const formik3 = useFormik({
        initialValues: {
            countEntreeService: 0,
            countServiceRappel: 0,
            countOrdreTravail: 0,
        }
    });

    // useEffect(() => {
    //     //Load Data
    //    getTaches(pageNumber, pageSize);
    //     return () => {
    //         // Perform any necessary cleanup (e.g., cancel pending requests)
    //     };
    // }, []);

    useEffect(() => {
        //Load Data
        getTaches(pageNumber, pageSize);
        return () => {
            // Perform any necessary cleanup (e.g., cancel pending requests)
        };
    }, [authData, pageNumber, pageSize]);

    useEffect(() => {
        const result = taches?.filter((item) =>
            Object.values(item).some(
                (value) =>
                    value &&
                    value.toString().toLowerCase().includes(search.toLowerCase())
            )
        );
        setFiltredTaches(result);
    }, [search, taches])

    return (
        <section className="service-section" onClick={() => { if (openAboutList !== null) { setOpenAboutList(null) } }}>
            <div className="container">
                <div className="tacheservice-sec wrap-all">
                    <Link to="#" className="tache-service-add color-primary-2 decoration-none" onClick={() => showHideAddModal(true)}>
                        <div className="add-new" id="add-vehc">
                            <span className="material-symbols-outlined">add</span>
                            Ajouter une tâche
                        </div>
                    </Link>

                    {filtredTaches?.length > 0 && <><div className="group-elements">
                        <div className="voir-elements">
                            <p></p>
                            <select
                                name="pageSize"
                                id="pageSize"
                                onChange={(e) => { handlePerRowsChange(e.target.value || 10); setPageNumber(1); }}
                            >
                                <option value="10" defaultValue={true}>10</option>
                                <option value="15">15</option>
                                <option value="20">20</option>
                            </select>
                        </div>
                        {/* <div className="search-element">
                            <p>Rechercher</p>
                            <input type="text" />
                        </div> */}
                    </div>
                        <div className="table-wrap">
                            <table>
                                <thead>
                                    <tr>
                                        <th onClick={() => handleSort('libelle')}>Nom</th>
                                        <th>Description</th>
                                        <th>Statut</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {filtredTaches?.map((vehicule) => (
                                        <tr key={vehicule.idTacheService}>
                                            <td>{vehicule.libelle}</td>
                                            <td>{vehicule.description}</td>
                                            <td>
                                                {
                                                    (() => {
                                                        switch (vehicule.statut) {
                                                            case "actif":
                                                                return <>
                                                                    <svg height="11.87" width="15"><circle cx="6" cy="6" r="6" fill="green" /></svg>Actif
                                                                </>;
                                                            case "inactif":
                                                                return <>
                                                                    <svg height="11.87" width="15"><circle cx="6" cy="6" r="6" fill="red" /></svg>Inactif
                                                                </>;
                                                            case "archivé":
                                                                return <>
                                                                    <svg height="11.87" width="15"><circle cx="6" cy="6" r="6" fill="grey" /></svg>Archivé
                                                                </>;
                                                            default:
                                                                return <></>;
                                                        }
                                                    })()
                                                }
                                            </td>
                                            <td className="txt-center relative">
                                                <span className="material-symbols-outlined about-btn" onClick={() => toggleAboutList(vehicule.idTacheService)}>
                                                    more_horiz
                                                </span>
                                                {openAboutList === vehicule.idTacheService && (
                                                    <div className="about-list">
                                                        <ul>
                                                            <li>
                                                                <Link className='row row-center' to="#" style={{ textDecoration: "none" }} onClick={() => showHideUpdateModal(true, vehicule.idTacheService, vehicule.libelle, vehicule.description, vehicule.statut)}>
                                                                    <span className="material-symbols-outlined">edit</span>Modifier
                                                                </Link>
                                                            </li>

                                                            {(vehicule.statut !== "archivé") && <li>
                                                                <Link className='row row-center' to="/listtaches" style={{ textDecoration: "none" }} onClick={() => handleConfirmArchive(vehicule.idTacheService)}>
                                                                    <span className="material-symbols-outlined">archive</span>Archiver
                                                                </Link>
                                                            </li>}

                                                            {(vehicule.statut === "archivé") && <li>
                                                                <Link className='row row-center' to="/listtaches" style={{ textDecoration: "none" }} onClick={() => handleConfirmRestore(vehicule.idTacheService)}>
                                                                    <span className="material-symbols-outlined">restore</span>Restaurer
                                                                </Link>
                                                            </li>}

                                                            <li>
                                                                <Link className='row row-center' to="/listtaches" style={{ textDecoration: "none" }} onClick={() => handleConfirmDelete(vehicule.idTacheService)}>
                                                                    <span className="material-symbols-outlined">delete</span>Supprimer
                                                                </Link>
                                                            </li>

                                                        </ul>
                                                    </div>
                                                )}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        {/* <div className="pagination">
                            <Link to="#" onClick={() => handlePageChange(pageNumber - 1)}
                                className={(pageNumber === 1) ? 'pgn disabled' : 'pgn'}
                                disabled={(pageNumber === 1) ? true : false}
                            >&laquo;</Link>
                            {Array.from({ length: totalPages }, (_, i) => (
                                <Link
                                    key={i + 1}
                                    to="#"
                                    className={i + 1 === pageNumber ? 'pgn active' : 'pgn'}
                                    onClick={() => handlePageChange(i + 1)}
                                >
                                    {i + 1}
                                </Link>
                            ))}
                            <Link to="#" onClick={() => handlePageChange(pageNumber + 1)}
                                className={((pageNumber + 1) > totalPages) ? 'pgn disabled' : 'pgn'}
                                disabled={((pageNumber + 1) > totalPages) ? true : false}
                            >&raquo;</Link>
                        </div> */}
                        <div className="pagination">
                            <Link to="#" onClick={() => handlePageChange(pageNumber - 1)}
                                className={(pageNumber === 1) ? 'pgn disabled' : 'pgn'}
                                disabled={(pageNumber === 1) ? true : false}
                            >&laquo;</Link>
                            {Array.from({ length: Math.min(5, totalPages) }, (_, i) => {
                                const page = pageNumber - 2 + i;
                                return page <= totalPages && page > 0 && (
                                    <Link
                                        key={page}
                                        to="#"
                                        className={pageNumber === page ? 'pgn active' : 'pgn'}
                                        onClick={() => handlePageChange(page)}
                                    >
                                        {page}
                                    </Link>
                                );
                            })}
                            <Link to="#" onClick={() => handlePageChange(pageNumber + 1)}
                                className={(pageNumber === totalPages || totalPages === 0) ? 'pgn disabled' : 'pgn'}
                                disabled={(pageNumber === totalPages || totalPages === 0) ? true : false}
                            >&raquo;</Link>
                            <span> Affichage de {(pageNumber === 1)?1:((pageNumber - 1) * pageSize+1)} à {pageNumber * pageSize} sur {totalItems} entrées</span>
                        </div>
                    </>}
                </div>

                {showAddModal && <div className="popcard-add">
                    <div className="popcard-add_inside">
                        <Link className="popcard-add_inside-close" to="#" onClick={() => showHideAddModal(false)}>&#10006;</Link>
                        <h2 className="popcard-add_inside-title">Ajouter une tâche</h2>
                        <div className="popcard-add_inside-form">
                            <form onSubmit={formik1.handleSubmit}>
                                <div className="wrap-info">
                                    <div className="">
                                        <div className="wrap-one">
                                            <label>Nom<span className='requiredField'>*</span></label>
                                            <input type="text"
                                                id="libelle"
                                                name="libelle"
                                                style={(formik1.touched.libelle && formik1.errors.libelle) ? { borderColor: "red" } : null}
                                                placeholder=""
                                                defaultValue={formik1.values.libelle}
                                                onChange={formik1.handleChange}
                                            />
                                            {formik1.touched.libelle && formik1.errors.libelle ? (
                                                <div className="invalid-feedback">{formik1.errors.libelle}</div>
                                            ) : null}
                                        </div>
                                    </div>
                                    <div className="">
                                        <div className="wrap-one">
                                            <label >Statut<span className='requiredField'>*</span></label>
                                            <select
                                                id="statut"
                                                name="statut"
                                                defaultValue={formik1.values.statut}
                                                onChange={formik1.handleChange}
                                            >
                                                <option value="actif">Actif</option>
                                                <option value="inactif">Inactif</option>
                                                <option value="archivé">Archivé</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="wrap-row">
                                        <div className="wrap-one">
                                            <label>Description</label>
                                            <textarea type="text"
                                                id="description"
                                                name="description"
                                                placeholder=""
                                                defaultValue={formik1.values.description}
                                                onChange={formik1.handleChange}
                                                rows={4}
                                                cols={50}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="popcard-operation-pickup-details_inside-zone">
                                    <div className="buttons">
                                        <input type="submit" value="Enregistrer" className="submit-btn" />
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>}

                {showUpdateModal && <div className="popcard-add">
                    <div className="popcard-add_inside">
                        <Link className="popcard-add_inside-close" to="#" onClick={() => showHideUpdateModal(false, '', '', '', '', '', '', '', '', '', '', '')}>&#10006;</Link>
                        <h2 className="popcard-add_inside-title">Modifier une tâche</h2>
                        <div className="popcard-add_inside-form">
                            <form onSubmit={formik2.handleSubmit}>
                                <div className="wrap-info">
                                    <div className="">
                                        <div className="wrap-one">
                                            <label>Nom<span className='requiredField'>*</span></label>
                                            <input type="text"
                                                id="libelleUpdate"
                                                name="libelleUpdate"
                                                style={(formik2.touched.libelleUpdate && formik2.errors.libelleUpdate) ? { borderColor: "red" } : null}
                                                placeholder=""
                                                defaultValue={formik2.values.libelleUpdate}
                                                onChange={formik2.handleChange}
                                            />
                                            {formik2.touched.libelleUpdate && formik2.errors.libelleUpdate ? (
                                                <div className="invalid-feedback">{formik2.errors.libelleUpdate}</div>
                                            ) : null}
                                        </div>
                                    </div>
                                    <div className="">
                                        <div className="wrap-one">
                                            <label >Statut<span className='requiredField'>*</span></label>
                                            <select
                                                id="statutUpdate"
                                                name="statutUpdate"
                                                defaultValue={formik2.values.statutUpdate}
                                                onChange={formik2.handleChange}
                                            >
                                                <option value="actif">Actif</option>
                                                <option value="inactif">Inactif</option>
                                                <option value="archivé">Archivé</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="wrap-row">
                                        <div className="wrap-one">
                                            <label>Description</label>
                                            <textarea type="text"
                                                id="descriptionUpdate"
                                                name="descriptionUpdate"
                                                placeholder=""
                                                defaultValue={formik2.values.descriptionUpdate}
                                                onChange={formik2.handleChange}
                                                rows={4}
                                                cols={50}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="popcard-operation-pickup-details_inside-zone">
                                    <div className="buttons">
                                        <input type="submit" value="Enregistrer" className="submit-btn" />
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>}

                {showDetailsModal && <div className="popcard-add">
                    <div className="popcard-add_inside">
                        <Link className="popcard-add_inside-close" to="#" onClick={() => setShowDetailsModal(false)}>&#10006;</Link>
                        <h2 className="popcard-add_inside-title">Utilisation de la tâche</h2>
                        <div className="popcard-add_inside-form">
                            <div className="wrap-info">
                                <div className="">
                                    <div className="wrap-one">
                                        <label>Entrée(s) de services: {formik3.values.countEntreeService}</label>
                                    </div>
                                </div>
                                <div className="">
                                    <div className="wrap-one">
                                        <label>Ordre(s) de travail: {formik3.values.countOrdreTravail}</label>
                                    </div>
                                </div>
                                <div className="">
                                    <div className="wrap-one">
                                        <label>Rappel(s) de service: {formik3.values.countServiceRappel}</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>}

                <ConfirmationDialog
                    open={confirmDialogOpen}
                    onClose={handleCancelDelete}
                    onConfirm={handleDelete}
                    titleMessage="Confirmer la suppression de la tâche"
                    bodyMessage="Voulez-vous vraiment supprimer cette tâche? Toutes les données seront définitivement perdues."
                    btnCancelText="Annuler"
                    btnConfirmText="Supprimer la tâche"
                />

                <ConfirmationDialog
                    open={confirmDialogOpenArchive}
                    onClose={handleCancelArchive}
                    onConfirm={handleArchive}
                    titleMessage="Confirmer l'archivage de la tâche"
                    bodyMessage={"Voulez-vous vraiment archiver cet enregistrement ? L'archivage d'un enregistrement ne le supprime pas de <i>FleetGo</i>, mais il ne sera plus utilisable."}
                    btnCancelText="Annuler"
                    btnConfirmText="Archiver la tâche"
                />
            </div>
        </section>
    );
}

export default ListTaches;
