import React, { useEffect, useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import { CustomToast } from '../../components/Toast/CustomToast';
import ConfirmationDialog from '../../components/Confirmation/ConfirmationDialog';
import { axiosInstance, Employee, EmplyeeImg, Proprietaire, Groupe } from '../../services/api';
import { AuthContext } from '../../contexts/AuthContext';
const ListEmployes = () => {
    const { authData } = useContext(AuthContext);

    const [employes, setEmployes] = useState([]);
    const [proprietaires, setProprietaires] = useState([]);
    const [filtredEmployes, setFiltredEmployes] = useState([]);
    const [pageNumber, setPageNumber] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalItems, setTotalItems] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [loading, setLoading] = useState(true);
    const [idEmployeDelete, setIdEmployeDelete] = useState('');
    const [confirmDialogOpenDelete, setConfirmDialogOpenDelete] = useState(false);
    const [confirmDialogOpenArchive, setConfirmDialogOpenArchive] = useState(false);

    const [filters, setFilters] = useState({
        nom: '',
        prenom: '',
        matricule: '',
        statut: '',
        idEntreprise: ''
    });

    //Table management
    const [sortBy, setSortBy] = useState(''); // Column name to sort by
    const [sortOrder, setSortOrder] = useState('asc'); // 'asc' or 'desc'

    const handleSort = (column) => {
        setSortBy(column);
        setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');

        let sortedTable = [...filtredEmployes].sort((a, b) => {
            const aValue = a[sortBy] || '';
            const bValue = b[sortBy] || '';

            if (sortOrder === 'asc') {
                return aValue.localeCompare(bValue);
            } else {
                return bValue.localeCompare(aValue);
            }
        });

        setFiltredEmployes(sortedTable);
    };

    const [openAboutList, setOpenAboutList] = useState(null);

    const toggleAboutList = (idVehicule) => {
        setOpenAboutList(openAboutList === idVehicule ? null : idVehicule);
    };

    const handlePageChange = (page) => {
        setPageNumber(page);
        //consolelog("setPageNumber="+pageNumber)
    };

    const handlePerRowsChange = (newPerPage) => {
        setPageSize(newPerPage);
        //console.log("setPageSize="+pageSize);
    };

    //Load data

    const getProprietaires = async () => {
        try {
            const queryParams = new URLSearchParams({
                typeProprietaire: "entreprise,location,freelancer"
            });

            const response = await axiosInstance.get(`${Proprietaire}/GetProprietaireByType?${queryParams}`);
            const resp = await response.data;
            setProprietaires(resp);
        }
        catch (err) {
            console.log(err);
        }
    }

    const getEmployes = async (page, newPerPage) => {
        try {
            setLoading(true);

            const queryParams = new URLSearchParams({
                pageNumber: page,
                pageSize: newPerPage
            });

            if (filters.nom != null) {
                queryParams.append("nom", filters.nom);
            }

            if (filters.prenom != null) {
                queryParams.append("prenom", filters.prenom);
            }

            if (filters.matricule != null) {
                queryParams.append("matricule", filters.matricule);
            }

            if (filters.statut != null) {
                queryParams.append("statut", filters.statut);
            }

            if (filters.idEntreprise != null) {
                queryParams.append("idEntreprise", filters.idEntreprise);
            }

            const response = await axiosInstance.get(`${Employee}/GetEmployes?${queryParams}`);
            const resp = await response.data;
            setEmployes(resp.data);
            setFiltredEmployes(resp.data);
            setPageNumber(resp.pageNumber);
            setPageSize(resp.pageSize);
            setTotalItems(resp.totalItems);
            setTotalPages(resp.totalPages);
            setLoading(false);
            //console.log(authData.estSuperAdmin);
        }
        catch (err) {
            console.log(err);
        }
    }

    //Supprimer employée
    const handleConfirmDelete = (idEmploye) => {
        // Show the modal when the confirm delete button is clicked
        //deleteEmploye(idEmploye);
        setIdEmployeDelete(idEmploye);
        setConfirmDialogOpenDelete(true);
    };

    const handleDelete = async () => {
        try {
            await axiosInstance.delete(`${Employee}/${idEmployeDelete}`);
            setIdEmployeDelete('');
            CustomToast("Le compte a été supprimé avec succès", 'success');
            setConfirmDialogOpenDelete(false);
            getEmployes(pageNumber, pageSize);
        }
        catch (error) {
            // Handle error
            console.log(error);
        }
    }

    const handleCancelDelete = () => {
        setConfirmDialogOpenDelete(false);
    };

    //archiver employé

    const handleConfirmArchive = (idEmploye) => {
        // Show the modal when the confirm delete button is clicked
        //archiveEmploye(idEmploye);
        setIdEmployeDelete(idEmploye);
        setConfirmDialogOpenArchive(true);
    };

    const handleArchive = async () => {
        try {
            const request = {
                idEmploye: idEmployeDelete,
                idUtilisateur: authData.idUser
            }
            await axiosInstance.put(`${Employee}/archive`, request);
            setIdEmployeDelete('');
            setConfirmDialogOpenArchive(false);
            getEmployes(pageNumber, pageSize);
        }
        catch (error) {
            // Handle error
            console.log(error);
        }
    }

    const handleCancelArchive = () => {
        setConfirmDialogOpenArchive(false);
    };

    //restaurer employé
    const restoreEmploye = async (idEmploye) => {
        try {

            const request = {
                idEmploye: idEmploye,
                idUtilisateur: authData.idUser
            }
            await axiosInstance.put(`${Employee}/restore`, request);
            getEmployes(pageNumber, pageSize);
            CustomToast("Le compte a été restauré avec succès", 'success');
        }
        catch (err) {
            console.log(err);
        }
    }

    const handleConfirmRestore = (idVehicule) => {
        // Show the modal when the confirm delete button is clicked
        restoreEmploye(idVehicule);
        //setIdEmployeDelete(idVehicule);
    };

    //Search
    const handleSearch = (e) => {
        e.preventDefault();
        setPageNumber(1); // Reset page number when new search is triggered
        getEmployes(1, pageSize); // Fetch inspections with updated filters
    };

    useEffect(() => {
        //Load Data

        getProprietaires();
        return () => {
            // Perform any necessary cleanup (e.g., cancel pending requests)
        };
    }, [authData]);

    useEffect(() => {
        //Load Data

        getEmployes(pageNumber, pageSize);
        return () => {
            // Perform any necessary cleanup (e.g., cancel pending requests)
        };
    }, [authData, pageNumber, pageSize]);

    // useEffect(() => {
    //     // const result=vehicules.filter(row=>{
    //     //     return row.vehiculeNom && row.vehiculeNom.toLowerCase().match(search.toLowerCase());   
    //     // })
    //     const result = employes.filter((item) =>
    //         Object.values(item).some(
    //             (value) =>
    //                 value &&
    //                 value.toString().toLowerCase().includes(search.toLowerCase())
    //         )
    //     );
    //     setFiltredEmployes(result);
    // }, [search])

    return (
        <section className="donnee-section" onClick={() => { if (openAboutList !== null) { setOpenAboutList(null) } }}>
            <div className="container">
                <div className="wrap-all employe-table">
                    <Link to="/ajouteremploye" className="add-employe color-primary-2 decoration-none">
                        <div className="add-new" id="add-vehc">
                            <span className="material-symbols-outlined">add</span>
                            Ajouter un employé
                        </div>
                    </Link>
                    <form onSubmit={handleSearch}>
                        <div className="filter-wrap">

                            <input type="text"
                                id="nom"
                                name="nom"
                                placeholder='Nom'
                                value={filters.nom || ''}
                                onChange={(e) => setFilters({ ...filters, nom: e.target.value || '' })}
                                className='input-filter'
                            />

                            <input type="text"
                                id="prenom"
                                name="prenom"
                                placeholder='Prénom'
                                value={filters.prenom || ''}
                                onChange={(e) => setFilters({ ...filters, prenom: e.target.value || '' })}
                                className='input-filter'
                            />

                            <input type="text"
                                id="matricule"
                                name="matricule"
                                placeholder='Matricule'
                                value={filters.matricule || ''}
                                onChange={(e) => setFilters({ ...filters, matricule: e.target.value || '' })}
                                className='input-filter'
                            />

                            <select
                                id="idEntreprise"
                                name="idEntreprise"
                                className="select-filter"
                                value={filters.idEntreprise || ''}
                                onChange={(e) => setFilters({ ...filters, idEntreprise: e.target.value || '' })}
                            >
                                <option value="">Séléctionnez entreprise</option>
                                {proprietaires.map((proprietaire) => (
                                    <option key={proprietaire.idProprietaire} value={proprietaire.idProprietaire}>{proprietaire.typeProprietaire !== "freelancer" ? proprietaire.raisonSociale : proprietaire.nom + " " + proprietaire.prenom}</option>
                                ))}
                            </select>
                            <select
                                id="statut"
                                name="statut"
                                className="select-filter"
                                value={filters.statut || ''}
                                onChange={(e) => setFilters({ ...filters, statut: e.target.value || '' })}
                            >
                                <option value="" defaultValue={true}>Statut</option>
                                <option value="actif">Actif</option>
                                <option value="inactif">Inactif</option>
                                <option value="suspendu">Suspendu</option>
                                <option value="licencié">Licencié</option>
                                <option value="archivé">Archivé</option>
                            </select>

                            <div className="filter">
                                {/* <Link to="#" type="submit"><span className="material-symbols-outlined">filter_alt</span></Link> */}
                                <button type="submit" className="linkButton">
                                    <span className="material-symbols-outlined">filter_alt</span>
                                </button>
                            </div>
                        </div>
                    </form>
                    <div className="group-elements">
                        <div className="voir-elements">
                            <p></p>
                            <select
                                name="pageSize"
                                id="pageSize"
                                onChange={(e) => { handlePerRowsChange(e.target.value || 10); setPageNumber(1); }}
                            >
                                <option value="10" defaultValue={true}>10</option>
                                <option value="15">15</option>
                                <option value="20">20</option>
                            </select>
                        </div>
                        {/* <div className="search-element">
                            <p>Rechercher</p>
                            <input type="text" />
                        </div> */}
                    </div>
                    <div className="table-wrap">
                        <table>
                            <thead>
                                <tr>
                                    <th onClick={() => handleSort('nom')}>Nom</th>
                                    <th onClick={() => handleSort('prenom')}>Prénom</th>
                                    <th onClick={() => handleSort('genre')}>Genre</th>
                                    <th onClick={() => handleSort('matricule')}>Matricule</th>
                                    <th onClick={() => handleSort('statut')}>Statut</th>
                                    <th onClick={() => handleSort('entreprise')}>Entreprise</th>
                                    <th onClick={() => handleSort('categorieEmploye')}>Catégorie</th>
                                    <th onClick={() => handleSort('profil')}>Rôle</th>
                                    <th>Classfication</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {filtredEmployes?.map((emp) => (
                                    <tr key={emp.idEmploye}>
                                        <td>{emp.nom}</td>
                                        <td>{emp.prenom}</td>
                                        <td>
                                            {
                                                emp.genre === "H" ? <>homme</> :
                                                    emp.genre === "F" ? <>femme</> :
                                                        null
                                            }
                                        </td>
                                        <td>{emp.matricule}</td>
                                        <td>
                                            {
                                                (() => {
                                                    switch (emp.statut) {
                                                        case "actif":
                                                            return <>
                                                                <svg height="11.87" width="15"><circle cx="6" cy="6" r="6" fill="green" /></svg>Actif
                                                            </>;
                                                        case "inactif":
                                                            return <>
                                                                <svg height="11.87" width="15"><circle cx="6" cy="6" r="6" fill="orangered" /></svg>Inactif
                                                            </>;
                                                        case "archivé":
                                                            return <>
                                                                <svg height="11.87" width="15"><circle cx="6" cy="6" r="6" fill="grey" /></svg>Archivé
                                                            </>;
                                                        case "suspendu":
                                                            return <>
                                                                <svg height="11.87" width="15"><circle cx="6" cy="6" r="6" fill="blue" /></svg>Suspendu
                                                            </>;
                                                        case "licencié":
                                                            return <>
                                                                <svg height="11.87" width="15"><circle cx="6" cy="6" r="6" fill="red" /></svg>Licencié
                                                            </>;
                                                        default:
                                                            return <></>;
                                                    }
                                                })()
                                            }
                                        </td>
                                        <td>{emp.entreprise}</td>
                                        <td>
                                            {
                                                emp.categorieEmploye && emp.categorieEmploye === "externe" ? <>Freelance</> : "Interne"
                                            }
                                        </td>
                                        <td>{emp.profil}</td>
                                        <td>
                                            {
                                                (emp.estEmployee && emp.estChauffeur && emp.estTechnicien) ? <>Employé<br />Chauffeur<br />Technicien</> :
                                                    (emp.estEmployee && emp.estChauffeur && !emp.estTechnicien) ? <>Employé<br />Chauffeur</> :
                                                        (emp.estEmployee && !emp.estChauffeur && emp.estTechnicien) ? <>Employé<br />Technicien</> :
                                                            (emp.estEmployee && !emp.estChauffeur && !emp.estTechnicien) ? <>Employé</> :
                                                                (!emp.estEmployee && emp.estChauffeur && emp.estTechnicien) ? <>Chauffeur<br />Technicien</> :
                                                                    (!emp.estEmployee && emp.estChauffeur && !emp.estTechnicien) ? <>Chauffeur</> :
                                                                        (!emp.estEmployee && !emp.estChauffeur && emp.estTechnicien) ? <>Technicien</> :
                                                                            null
                                            }
                                        </td>
                                        <td className="txt-center relative">
                                            <span className="material-symbols-outlined about-btn" onClick={() => toggleAboutList(emp.idEmploye)}>
                                                more_horiz
                                            </span>
                                            {openAboutList === emp.idEmploye && (
                                                <div className="about-list">
                                                    <ul>
                                                        {((emp.estSupperAdmin === false) || (emp.estSupperAdmin === true && authData.estSuperAdmin === true)) && <li><Link className='row row-center' to={`/modifieremploye/${emp.idEmploye}`} style={{ textDecoration: "none" }}>
                                                            <span className="material-symbols-outlined">edit</span>Modifier
                                                        </Link></li>}

                                                        {((emp.estSupperAdmin === true && authData.estSuperAdmin === false)) && <li><Link className='row row-center' to="#" style={{ textDecoration: "none", color: "#BEBEBE", cursor: 'not-allowed' }} onClick={(event) => event.preventDefault()} title="Vous ne pouvez pas modifer cet utilisateur">
                                                            <span className="material-symbols-outlined">edit</span>Modifier
                                                        </Link></li>}

                                                        {((emp.estSupperAdmin === false) || (emp.estSupperAdmin === true && authData.estSuperAdmin === true)) && <li><Link className='row row-center' to="/listemployes" style={{ textDecoration: "none" }} onClick={() => handleConfirmDelete(emp.idEmploye)}>
                                                            <span className="material-symbols-outlined">delete</span>Supprimer
                                                        </Link></li>}

                                                        {((emp.estSupperAdmin === true && authData.estSuperAdmin === false)) && <li><Link className='row row-center' to="#" style={{ textDecoration: "none", color: "#BEBEBE", cursor: 'not-allowed' }} onClick={(event) => event.preventDefault()} title="Vous ne pouvez pas supprimer cet utilisateur">
                                                            <span className="material-symbols-outlined">delete</span>Supprimer
                                                        </Link></li>}

                                                        {((emp.statut !== "archivé") && ((emp.estSupperAdmin === false) || (emp.estSupperAdmin === true && authData.estSuperAdmin == true))) && <li><Link className='row row-center' to="/listemployes" style={{ textDecoration: "none" }} onClick={() => handleConfirmArchive(emp.idEmploye)}>
                                                            <span className="material-symbols-outlined">archive</span>Archiver
                                                        </Link></li>}

                                                        {(emp.statut !== "archivé") && (((emp.estSupperAdmin === true && authData.estSuperAdmin === false))) && <li><Link className='row row-center' to="#" style={{ textDecoration: "none", color: "#BEBEBE", cursor: 'not-allowed' }} onClick={(event) => event.preventDefault()} title="Vous ne pouvez pas archver cet utilisateur">
                                                            <span className="material-symbols-outlined">archive</span>Archiver
                                                        </Link></li>}

                                                        {((emp.statut === "archivé") && ((emp.estSupperAdmin === false) || (emp.estSupperAdmin === true && authData.estSuperAdmin === true))) && <li><Link className='row row-center' to="/listemployes" style={{ textDecoration: "none" }} onClick={() => handleConfirmRestore(emp.idEmploye)}>
                                                            <span className="material-symbols-outlined">restore</span>Restaurer
                                                        </Link></li>}

                                                        {((emp.statut === "archivé") && ((emp.estSupperAdmin === true && authData.estSuperAdmin === false))) && <li><Link className='row row-center' to="#" style={{ textDecoration: "none", color: "#BEBEBE", cursor: 'not-allowed' }} onClick={(event) => event.preventDefault()} title="Vous ne pouvez pas restaurer cet utilisateur">
                                                            <span className="material-symbols-outlined">restore</span>Restaurer
                                                        </Link></li>}
                                                    </ul>
                                                </div>
                                            )}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    {/* <div className="pagination">
                        <Link to="#" onClick={() => handlePageChange(pageNumber - 1)}
                            className={(pageNumber === 1) ? 'pgn disabled' : 'pgn'}
                            disabled={(pageNumber === 1) ? true : false}
                        >&laquo;</Link>
                        {Array.from({ length: totalPages }, (_, i) => (
                            <Link
                                key={i + 1}
                                to="#"
                                className={i + 1 === pageNumber ? 'pgn active' : 'pgn'}
                                onClick={() => handlePageChange(i + 1)}
                            >
                                {i + 1}
                            </Link>
                        ))}
                        <Link to="#" onClick={() => handlePageChange(pageNumber + 1)}
                            className={((pageNumber + 1) > totalPages) ? 'pgn disabled' : 'pgn'}
                            disabled={((pageNumber + 1) > totalPages) ? true : false}
                        >&raquo;</Link>
                    </div> */}
                    <div className="pagination">
                        <Link to="#" onClick={() => handlePageChange(pageNumber - 1)}
                            className={(pageNumber === 1) ? 'pgn disabled' : 'pgn'}
                            disabled={(pageNumber === 1) ? true : false}
                        >&laquo;</Link>
                        {Array.from({ length: Math.min(5, totalPages) }, (_, i) => {
                            const page = pageNumber - 2 + i;
                            return page <= totalPages && page > 0 && (
                                <Link
                                    key={page}
                                    to="#"
                                    className={pageNumber === page ? 'pgn active' : 'pgn'}
                                    onClick={() => handlePageChange(page)}
                                >
                                    {page}
                                </Link>
                            );
                        })}
                        <Link to="#" onClick={() => handlePageChange(pageNumber + 1)}
                            className={(pageNumber === totalPages || totalPages === 0) ? 'pgn disabled' : 'pgn'}
                            disabled={(pageNumber === totalPages || totalPages === 0) ? true : false}
                        >&raquo;</Link>
                        <span> Affichage de {(pageNumber === 1)?1:((pageNumber - 1) * pageSize+1)} à {pageNumber * pageSize} sur {totalItems} entrées</span>
                    </div>
                    <ConfirmationDialog
                        open={confirmDialogOpenDelete}
                        onClose={handleCancelDelete}
                        onConfirm={handleDelete}
                        titleMessage="Confirmer la suppression du compte"
                        bodyMessage={"Voulez-vous vraiment supprimer ce compte ? Toutes les données seront définitivement perdues."}
                        btnCancelText="Annuler"
                        btnConfirmText="Supprimer le compte"
                    />

                    <ConfirmationDialog
                        open={confirmDialogOpenArchive}
                        onClose={handleCancelArchive}
                        onConfirm={handleArchive}
                        titleMessage="Confirmer l'archivage du compte"
                        bodyMessage={"Voulez-vous vraiment archiver cet enregistrement ? L'archivage d'un enregistrement ne le supprime pas de <i>FleetGo</i>, mais il ne sera plus utilisable."}
                        btnCancelText="Annuler"
                        btnConfirmText="Archiver le compte"
                    />
                </div>
            </div>

        </section>
    );
}

export default ListEmployes;
