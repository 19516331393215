import React, { useEffect, useState, useContext, useRef } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Formik, useFormik } from 'formik';
import * as Yup from 'yup';
import moment from 'moment/moment';
import Select from "react-select";
import { CustomToast } from '../../components/Toast/CustomToast';
import useDebounce from '../../hooks/useDebounce';
import { axiosInstance, Employee, Vehicule, VehiculeAffectation } from '../../services/api';
import { AuthContext } from '../../contexts/AuthContext';
const ModifierAffectation = () => {
    const { authData } = useContext(AuthContext);
    const navigate = useNavigate();
    const { id } = useParams();//idVehiculeAffectation

    const [drivers, setDrivers] = useState([]);
    const [isLoaded, setIsLoaded] = useState();
    //Refs
    const vehiculeRef = useRef();

    const datedebutRef = useRef();
    const timedebutRef = useRef();
    const compteurdebutRef = useRef();

    const dateFintRef = useRef();
    const timeFinRef = useRef();
    const compteurFinRef = useRef();

    //Errors handle
    const [overlapping, setOverlapping] = useState(false);//back

    const [kiloDebLess, setKiloDebLess] = useState({ display: false, msg: "" });
    const [kiloDebGreater, setKiloDebGreater] = useState({ display: false, msg: "" });
    const [kiloDebBetween, setKiloDebBetween] = useState({ display: false, msg: "" });

    const [kiloFinLess, setKiloFinLess] = useState({ display: false, msg: "" });
    const [kiloFinGreater, setKiloFinGreater] = useState({ display: false, msg: "" });
    const [kiloFinBetween, setKiloFinBetween] = useState({ display: false, msg: "" });

    const [enteredDriverText, setEnteredDriverText] = useState('');
    const debouncedDriverText = useDebounce(enteredDriverText, 300);

    //Load data     

    const getAffectation = async (idVehiculeAffectation) => {
        try {
            const response = await axiosInstance.get(`${VehiculeAffectation}/${idVehiculeAffectation}`);
            const resp = await response.data;
            setIsLoaded(resp);
            // values.idVehicule = resp.idVehicule;
            // values.idConducteur = resp.idConducteur;
            // values.dateDebutAffectation = moment(resp.dateDebutAffectation).format('YYYY-MM-DD') || '';
            // values.dateDebutAffectationTime = moment(resp.dateDebutAffectation).format('HH:mm') || '';
            // values.dateFinAffectation = moment(resp?.dateFinAffectation).format('YYYY-MM-DD') || '';
            // values.dateFinAffectationTime = moment(resp.dateFinAffectation).format('HH:mm') || '';
            // values.kilometrageDebutAffectation = resp?.kilometrageDebutAffectation || '';
            // values.kilometrageFinAffectation = resp?.kilometrageFinAffectation || '';
            // values.remarque = resp?.remarque || '';

            // values.conducteur = null;
            // values.vehicule = '';

            setValues(prevValues => ({
                ...prevValues,
                idVehicule: resp.idVehicule,
                idConducteur: resp.idConducteur,
                dateDebutAffectation: moment(resp.dateDebutAffectation).isValid() ? moment(resp.dateDebutAffectation).format('YYYY-MM-DD') : '' || '',
                dateDebutAffectationTime: moment(resp.dateDebutAffectation).format('HH:mm') || '',
                dateFinAffectation: moment(resp.dateFinAffectation).isValid() ? moment(resp.dateFinAffectation).format('YYYY-MM-DD') : '' || '',
                dateFinAffectationTime: moment(resp.dateFinAffectation).format('HH:mm') || '',
                kilometrageDebutAffectation: resp?.kilometrageDebutAffectation || '',
                kilometrageFinAffectation: resp?.kilometrageFinAffectation || '',
                remarque: resp?.remarque || '',
                conducteur: '',
                vehicule: ''
            }));
        }
        catch (error) {
            // Handle error
            console.log(error);
        }
    }

    const getDrivers = async (nomConducteur) => {
        try {
            const queryParams = new URLSearchParams({
                pageNumber: 1,
                pageSize: 10,
                estChauffeur: true
            });

            if (nomConducteur !== '') {
                queryParams.append("nom", nomConducteur);
            }
            const response = await axiosInstance.get(`${Employee}/GetEmployeAutocomplete?${queryParams}`);
            const resp = await response.data;
            setDrivers(resp.data);
        }
        catch (err) {
            console.log(err);
        }
    }

    const getVehicule = async (idVehicule) => {
        try {
            // Fetch vehicle data
            const vehicleResponse = await axiosInstance.get(`${Vehicule}/GetById/${idVehicule}`);
            const vehicleData = vehicleResponse.data;
            setValues((prevValues) => ({
                ...prevValues,
                vehicule: vehicleData.nom + ' ' + vehicleData.immatriculation || '',
            }));
        } catch (err) {
            console.log(err);
        }
    };

    const getDriver = async (idConducteur) => {
        try {
            const queryParams = new URLSearchParams({
                pageNumber: 1,
                pageSize: 10
            });

            if (idConducteur !== '') {
                queryParams.append("idConducteur", idConducteur);
            }

            const response = await axiosInstance.get(`${Employee}/GetEmployeAutocomplete?${queryParams}`);
            const resp = await response.data;
            const driversSelct = resp.data;

            setValues((prevValues) => ({
                ...prevValues,
                conducteur: driversSelct.find((emp) => emp.idEmploye === prevValues.idConducteur) || ''
            }));

        }
        catch (err) {
            console.log(err);
        }
    }

    const isEndDateValid = () => {

        let dateDebutAffectation = new Date(`${values.dateDebutAffectation}T${values.dateDebutAffectationTime ? values.dateDebutAffectationTime : '00:00'}`);
        let dateFinAffectation = null;
        if (values.dateFinAffectation != '') {
            dateFinAffectation = new Date(`${values.dateFinAffectation}T${values.dateFinAffectationTime ? values.dateFinAffectationTime : '00:00'}`);
        }
        if (dateFinAffectation != null) {
            if (dateDebutAffectation > dateFinAffectation) {
                return false;
            }
        }
        return true;

    };

    const isEndKilometrageValid = () => {
        if (values.kilometrageDebutAffectation != '' && values.kilometrageFinAffectation != '' && values.kilometrageDebutAffectation > values.kilometrageFinAffectation) {
            return false;
        }
        return true;
    };

    const { handleChange, values, handleSubmit, touched, errors, setFieldValue, setValues } = useFormik({
        initialValues: {
            idVehiculeAffectation: '',
            idVehicule: '',
            idConducteur: '',
            conducteur: '',
            vehicule: '',
            dateDebutAffectation: '',//moment(new Date()).format('YYYY-MM-DD'),
            dateDebutAffectationTime: '',//moment().format('HH:mm'),
            dateFinAffectation: '',
            dateFinAffectationTime: '',
            kilometrageDebutAffectation: '',
            kilometrageFinAffectation: '',
            remarque: '',
            idUtilisateurAction: '',
        },
        validationSchema: Yup.object({
            conducteur: Yup.object()
                .nullable()
                .required('Champ obligatoire'),
            dateDebutAffectation: Yup.string()
                .required("Champ obligatoire"),
            kilometrageDebutAffectation: Yup.number()
                .min(0, "Kilométrage de départ ne peut pas être négative"),
            kilometrageFinAffectation: Yup.number()
                .min(0, "Le relevé kilométrique de fin doit être supérieur ou égal au relevé de début"),
        }),
        onSubmit: async (values) => {
            try {
                let errors = false;
                let dateDebutAffectation = new Date(`${values.dateDebutAffectation}T${values.dateDebutAffectationTime ? values.dateDebutAffectationTime : '00:00'}`);
                let dateFinAffectation = null;

                if (values.dateFinAffectation != '') {
                    dateFinAffectation = new Date(`${values.dateFinAffectation}T${values.dateFinAffectationTime ? values.dateFinAffectationTime : '00:00'}`);
                }
                if (dateFinAffectation != null) {
                    if (dateDebutAffectation > dateFinAffectation) {
                        errors = true;
                    }
                }
                if (values.kilometrageDebutAffectation != '' && values.kilometrageFinAffectation != '' && values.kilometrageDebutAffectation > values.kilometrageFinAffectation) {
                    errors = true;
                }

                if (errors) {
                    return
                }

                const postData = {
                    idVehiculeAffectation: id,
                    idVehicule: values.idVehicule,
                    idConducteur: values.conducteur.idEmploye,
                    dateDebutAffectation: dateDebutAffectation,
                    dateFinAffectation: dateFinAffectation,
                    kilometrageDebutAffectation: values.kilometrageDebutAffectation.toString() || '',
                    kilometrageFinAffectation: values.kilometrageFinAffectation.toString() || '',
                    remarque: values.remarque,
                    idUtilisateurAction: authData.idUser,
                };

                const response = await axiosInstance.put(VehiculeAffectation, postData);
                CustomToast("L'affectation a été créée avec succès", 'success');
                navigate('/listaffectations', { replace: true });
            } catch (error) {
                // Handle error
                if (!error?.response) {
                    CustomToast("Aucune réponse du serveur", 'error');
                    setOverlapping(false);
                    setKiloDebGreater({ display: false, msg: "" });
                    setKiloDebLess({ display: false, msg: "" });
                    setKiloDebBetween({ display: false, msg: "" });

                    setKiloFinGreater({ display: false, msg: "" });
                    setKiloFinLess({ display: false, msg: "" });
                    setKiloFinBetween({ display: false, msg: "" });
                } else if (error.response?.status === 400 && error.response?.data === "An overlap exists with another assignment") {
                    CustomToast("Un chevauchement existe avec une autre affectation", 'error');
                    setOverlapping(true);
                    setKiloDebGreater({ display: false, msg: "" });
                    setKiloDebLess({ display: false, msg: "" });
                    setKiloDebBetween({ display: false, msg: "" });

                    setKiloFinGreater({ display: false, msg: "" });
                    setKiloFinLess({ display: false, msg: "" });
                    setKiloFinBetween({ display: false, msg: "" });
                } else if (error.response?.status === 400 && error.response?.data === "Vehicle not found") {
                    CustomToast("Véhicule introuvable", 'error');
                    setOverlapping(false);
                    setKiloDebGreater({ display: false, msg: "" });
                    setKiloDebLess({ display: false, msg: "" });
                    setKiloDebBetween({ display: false, msg: "" });

                    setKiloFinGreater({ display: false, msg: "" });
                    setKiloFinLess({ display: false, msg: "" });
                    setKiloFinBetween({ display: false, msg: "" });
                } else if (error.response?.status === 400 && error.response?.data === "Assignment not found") {
                    CustomToast("Affectation introuvable", 'error');
                    setOverlapping(false);
                    setKiloDebGreater({ display: false, msg: "" });
                    setKiloDebLess({ display: false, msg: "" });
                    setKiloDebBetween({ display: false, msg: "" });

                    setKiloFinGreater({ display: false, msg: "" });
                    setKiloFinLess({ display: false, msg: "" });
                    setKiloFinBetween({ display: false, msg: "" });
                } else if (error.response?.status === 400 && error.response?.data?.msg === "Incorrect start value, must be greater") {
                    CustomToast("L'affectation de véhicule n'a pas pu être créée", 'error');
                    setKiloDebGreater({ display: true, msg: `Doit être supérieur à ${error.response?.data?.val1}` });
                    setOverlapping(false);
                    setKiloDebLess({ display: false, msg: "" });
                    setKiloDebBetween({ display: false, msg: "" });

                    setKiloFinGreater({ display: false, msg: "" });
                    setKiloFinLess({ display: false, msg: "" });
                    setKiloFinBetween({ display: false, msg: "" });
                } else if (error.response?.status === 400 && error.response?.data?.msg === "Incorrect start value, must be less") {
                    CustomToast("L'affectation de véhicule n'a pas pu être créée", 'error');
                    setKiloDebLess({ display: true, msg: `Doit être inférieur à ${error.response?.data?.val1}` });
                    setOverlapping(false);
                    setKiloDebGreater({ display: false, msg: "" });
                    setKiloDebBetween({ display: false, msg: "" });

                    setKiloFinGreater({ display: false, msg: "" });
                    setKiloFinLess({ display: false, msg: "" });
                    setKiloFinBetween({ display: false, msg: "" });
                } else if (error.response?.status === 400 && error.response?.data?.msg === "Incorrect start value, must be between") {
                    CustomToast("L'affectation de véhicule n'a pas pu être créée", 'error');
                    setKiloDebBetween({ display: true, msg: `Doit être entre ${error.response?.data?.val1} et ${error.response?.data?.val2}` });
                    setOverlapping(false);
                    setKiloDebGreater({ display: false, msg: "" });
                    setKiloDebLess({ display: false, msg: "" });

                    setKiloFinGreater({ display: false, msg: "" });
                    setKiloFinLess({ display: false, msg: "" });
                    setKiloFinBetween({ display: false, msg: "" });
                } else if (error.response?.status === 400 && error.response?.data?.msg === "Incorrect end value, must be greater") {
                    CustomToast("L'affectation de véhicule n'a pas pu être créée", 'error');
                    setKiloFinGreater({ display: true, msg: `Doit être supérieur à ${error.response?.data?.val1}` });
                    setOverlapping(false);
                    setKiloFinLess({ display: false, msg: "" });
                    setKiloFinBetween({ display: false, msg: "" });

                    setKiloDebGreater({ display: false, msg: "" });
                    setKiloDebLess({ display: false, msg: "" });
                    setKiloDebBetween({ display: false, msg: "" });
                } else if (error.response?.status === 400 && error.response?.data?.msg === "Incorrect end value, must be less") {
                    CustomToast("L'affectation de véhicule n'a pas pu être créée", 'error');
                    setKiloFinLess({ display: true, msg: `Doit être inférieur à ${error.response?.data?.val1}` });
                    setOverlapping(false);
                    setKiloFinGreater({ display: false, msg: "" });
                    setKiloFinBetween({ display: false, msg: "" });

                    setKiloDebGreater({ display: false, msg: "" });
                    setKiloDebLess({ display: false, msg: "" });
                    setKiloDebBetween({ display: false, msg: "" });
                } else if (error.response?.status === 400 && error.response?.data?.msg === "Incorrect end value, must be between") {
                    CustomToast("L'affectation de véhicule n'a pas pu être créée", 'error');
                    setKiloFinBetween({ display: true, msg: `Doit être entre ${error.response?.data?.val1} et ${error.response?.data?.val2}` });
                    setOverlapping(false);
                    setKiloFinGreater({ display: false, msg: "" });
                    setKiloFinLess({ display: false, msg: "" });

                    setKiloDebGreater({ display: false, msg: "" });
                    setKiloDebLess({ display: false, msg: "" });
                    setKiloDebBetween({ display: false, msg: "" });
                } else if (error.response?.status === 401) {
                    CustomToast("Non autorisé", 'error');
                    setOverlapping(false);
                    setKiloDebGreater({ display: false, msg: "" });
                    setKiloDebLess({ display: false, msg: "" });
                    setKiloDebBetween({ display: false, msg: "" });

                    setKiloFinGreater({ display: false, msg: "" });
                    setKiloFinLess({ display: false, msg: "" });
                    setKiloFinBetween({ display: false, msg: "" });
                } else {
                    CustomToast("Demande échoué", 'error');
                    setOverlapping(false);
                    setKiloDebGreater({ display: false, msg: "" });
                    setKiloDebLess({ display: false, msg: "" });
                    setKiloDebBetween({ display: false, msg: "" });

                    setKiloFinGreater({ display: false, msg: "" });
                    setKiloFinLess({ display: false, msg: "" });
                    setKiloFinBetween({ display: false, msg: "" });
                }
                console.log(error);
            }
        }
    });

    //Load data

    useEffect(() => {
        getAffectation(id);
        const fetchData = async () => {
            try {
                // Fetch vehicle data
                if (values.idVehicule !== '') {
                    const vehicleResponse = await axiosInstance.get(`${Vehicule}/GetById/${values.idVehicule}`);
                    const vehicleData = vehicleResponse.data;

                    setValues((prevValues) => ({
                        ...prevValues,
                        vehicule: vehicleData.nom + ' ' + vehicleData.immatriculation || '',
                    }));
                }


                if (values.idConducteur !== '') {
                    // Fetch drivers data
                    const queryParams = new URLSearchParams({
                        pageNumber: 1,
                        pageSize: 10,
                        estChauffeur: true
                    });

                    queryParams.append("idConducteur", values.idConducteur);

                    const driversResponse = await axiosInstance.get(`${Employee}/GetEmployeAutocomplete?${queryParams}`);
                    const driversData = driversResponse.data;
                    const driverData = driversData.data[0];

                    setDrivers(driversData.data);

                    setValues((prevValues) => ({
                        ...prevValues,
                        conducteur: driverData || '',
                    }));
                }
            } catch (err) {
                console.log(err);
            }
        };
        fetchData();
        return () => {
            // Perform any necessary cleanup (e.g., cancel pending requests)
        };
    }, [id, values.idVehicule, values.idConducteur]);

    useEffect(() => {
        //if (debouncedDriverText) {
        getDrivers(debouncedDriverText);
        //}
        return () => {
            // Perform any necessary cleanup (e.g., cancel pending requests)
        };
    }, [isLoaded, debouncedDriverText]);

    //console.log(errors)
    return (
        <section className="vehicles-section">
            <div className="container">
                <form onSubmit={handleSubmit} className="affectation-vehicule-form">
                    <div className="">
                        <div className="grid grid--2-cols">
                            <div className="form-sec">
                                <div className="retour" id="retour-affectation-vehicule">
                                    <Link to="/listaffectations" style={{ textDecoration: "none" }}>
                                        <div className="arrow-back" id="arrow-back-affectation-form">
                                            <span className="material-symbols-outlined" id="arrow">arrow_back</span>
                                            <h3 className="heading-primary"></h3>
                                        </div>
                                    </Link>
                                </div>
                                {isLoaded && <div className="form-details">
                                    <div className="detail-form" id="identification-form">
                                        <div className="col">
                                            <div className="grp">
                                                <div className="gp">
                                                    <label >Véhicule<span className='requiredField'>*</span></label>
                                                    <input
                                                        id="vehicule"
                                                        name="vehicule"
                                                        type='text'
                                                        ref={vehiculeRef}
                                                        defaultValue={values.vehicule}
                                                        disabled={true}
                                                    />
                                                </div>
                                                <div className="gp">
                                                    <label >Chauffeur<span className='requiredField'>*</span></label>
                                                    <Select
                                                        id="conducteur"
                                                        name="conducteur"
                                                        value={values.conducteur}
                                                        onChange={(option) => setFieldValue('conducteur', option)}
                                                        options={drivers}
                                                        onInputChange={(inputValue) => {
                                                            // Update the entered text
                                                            setEnteredDriverText(inputValue);
                                                        }}
                                                        getOptionLabel={(option) => option.nom + ' ' + option.prenom}
                                                        getOptionValue={(option) => option.idEmploye}
                                                        isClearable
                                                        styles={{
                                                            container: (provided, state) => ({
                                                                ...provided,
                                                                boxShadow: state.isFocused ? '0px 0px 5px 0px #65458d' : errors.conducteur ? 'red' : '#BEBEBE',
                                                            }),
                                                            control: (baseStyles, state) => ({
                                                                ...baseStyles,
                                                                minHeight: '19px',
                                                                outline: '0!important',
                                                                borderRadius: '0.5rem',
                                                                borderWidth: '1px',
                                                                fontSize: '1rem',
                                                                height: '3.1rem',
                                                            }),
                                                            option: (provided, state) => ({
                                                                ...provided,
                                                                fontSize: '1rem'
                                                            }),
                                                            singleValue: (provided, state) => ({
                                                                ...provided,
                                                                fontSize: '1rem',
                                                                margin: '0',
                                                            }),
                                                            valueContainer: (provided, state) => ({
                                                                ...provided,
                                                                alignItems: 'start',
                                                                fontSize: '1rem'
                                                            }),
                                                        }}
                                                    />
                                                    {touched.conducteur && errors.conducteur && (
                                                        <div className="invalid-feedback">{errors.conducteur}</div>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="grp">
                                                <div className="gp" title="Lorsque l'affectation commence.">
                                                    <label >Date/heure de début<span className='requiredField'>*</span></label>
                                                    <input type="date"
                                                        ref={datedebutRef}
                                                        id="dateDebutAffectation"
                                                        name="dateDebutAffectation"
                                                        style={(touched.dateDebutAffectation && errors.dateDebutAffectation) ? { borderColor: "red" } : null}
                                                        placeholder=""
                                                        defaultValue={values.dateDebutAffectation}
                                                        onChange={handleChange}
                                                        disabled={values.idVehicule && values.conducteur ? false : true}
                                                    />
                                                    {touched.dateDebutAffectation && errors.dateDebutAffectation ? (
                                                        <div className="invalid-feedback">{errors.dateDebutAffectation}</div>
                                                    ) : null}
                                                    {overlapping && (
                                                        <div className="invalid-feedback">Un chevauchement existe avec une autre affectation</div>
                                                    )}
                                                </div>
                                                <div className="gp">
                                                    <label></label>
                                                    <input
                                                        ref={timedebutRef}
                                                        type="time"
                                                        id="dateDebutAffectationTime"
                                                        name="dateDebutAffectationTime"
                                                        placeholder=""
                                                        defaultValue={values.dateDebutAffectationTime}
                                                        onChange={handleChange}
                                                        disabled={values.idVehicule && values.conducteur ? false : true}
                                                    />
                                                </div>
                                                <div className="gp" title="Relevé du compteur kilométrique au début de l'affectation.">
                                                    <label >Compteur début</label>
                                                    <input
                                                        ref={compteurdebutRef}
                                                        type="number"
                                                        id="kilometrageDebutAffectation"
                                                        name="kilometrageDebutAffectation"
                                                        placeholder=""
                                                        defaultValue={values.kilometrageDebutAffectation}
                                                        onChange={handleChange}
                                                        disabled={values.dateDebutAffectation != '' ? false : true}
                                                        step="any"
                                                    />
                                                    {errors.kilometrageDebutAffectation && touched.kilometrageDebutAffectation && (
                                                        <div className="invalid-feedback">{errors.kilometrageDebutAffectation}</div>
                                                    )}
                                                    {kiloDebLess.display && (
                                                        <div className="invalid-feedback">{kiloDebLess.msg}</div>
                                                    )}
                                                    {kiloDebGreater.display && (
                                                        <div className="invalid-feedback">{kiloDebGreater.msg}</div>
                                                    )}
                                                    {kiloDebBetween.display && (
                                                        <div className="invalid-feedback">{kiloDebBetween.msg}</div>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="grp">
                                                <div className="gp" title="Quand se termine cette affectation ? Peut être passé ou futur. Laisser vide si l'affectation a toujours existé.">
                                                    <label >Date/heure de fin</label>
                                                    <input
                                                        ref={dateFintRef}
                                                        type="date"
                                                        id="dateFinAffectation"
                                                        name="dateFinAffectation"
                                                        placeholder=""
                                                        defaultValue={values.dateFinAffectation}
                                                        onChange={handleChange}
                                                        disabled={values.idVehicule && values.conducteur ? false : true}
                                                    />
                                                    {!isEndDateValid() && (
                                                        <div className="invalid-feedback">La date/heure de fin doit être supérieure ou égale à la date/heure de début</div>
                                                    )}
                                                    {overlapping && (
                                                        <div className="invalid-feedback">Un chevauchement existe avec une autre affectation</div>
                                                    )}
                                                </div>
                                                <div className="gp">
                                                    <label></label>
                                                    <input
                                                        ref={timeFinRef}
                                                        type="time"
                                                        id="dateFinAffectationTime"
                                                        name="dateFinAffectationTime"
                                                        placeholder=""
                                                        defaultValue={values.dateFinAffectationTime}
                                                        onChange={handleChange}
                                                        disabled={values.idVehicule && values.conducteur ? false : true}
                                                    />
                                                </div>
                                                <div className="gp" title="Relevé du compteur kilométrique à la fin de l'affectation.">
                                                    <label >Compteur fin</label>
                                                    <input
                                                        ref={compteurFinRef}
                                                        type="number"
                                                        id="kilometrageFinAffectation"
                                                        name="kilometrageFinAffectation"
                                                        placeholder=""
                                                        defaultValue={values.kilometrageFinAffectation}
                                                        onChange={handleChange}
                                                        disabled={values.dateFinAffectation != '' ? false : true}
                                                        step="any"
                                                    />
                                                    {!isEndKilometrageValid() && (
                                                        <div className="invalid-feedback">Le relevé kilométrique de fin doit être supérieur ou égal au relevé de début</div>
                                                    )}
                                                    {kiloFinLess.display && (
                                                        <div className="invalid-feedback">{kiloFinLess.msg}</div>
                                                    )}
                                                    {kiloFinGreater.display && (
                                                        <div className="invalid-feedback">{kiloFinGreater.msg}</div>
                                                    )}
                                                    {kiloFinBetween.display && (
                                                        <div className="invalid-feedback">{kiloFinBetween.msg}</div>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="grp">
                                                <div className="gp">
                                                    <label >Remarque</label>
                                                    <textarea
                                                        type="text"
                                                        rows={4}
                                                        id="remarque"
                                                        name="remarque"
                                                        placeholder=""
                                                        defaultValue={values.remarque}
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="submit-container">
                                        <button type="submit" className="submit-btn">Enregistrer</button>
                                    </div>
                                </div>}
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </section>
    );
}

export default ModifierAffectation;
