import React, { useEffect, useState, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import moment from 'moment/moment';
import { formatCurrency, formatYAxis } from '../../utils/Utils';
import { Chart as ChartJS, ArcElement, Tooltip, Legend, LinearScale, PointElement, TimeScale, BarElement, CategoryScale, Title } from 'chart.js';
import { Pie, Doughnut, Bar, Bubble } from 'react-chartjs-2';
import 'chartjs-adapter-date-fns';
import { axiosInstance, MyDashboard, DashboardHub } from '../../services/api';
import { isConnectionConnecting, createConnection } from '../../services/SignalRService';
import { AuthContext } from '../../contexts/AuthContext';

const Dashboard = () => {
    const { authData } = useContext(AuthContext);
    ChartJS.register(ArcElement, LinearScale, PointElement, TimeScale, BarElement, CategoryScale, Title, Tooltip, Legend);
    //moment.locale('fr');
    const navigate = useNavigate();

    const [connexionDashboard, setConnexionDashboard] = useState(createConnection(DashboardHub));

    const [vehiculesStatuts, setVehiculesStatuts] = useState([]);
    const [vehiculesTypes, setVehiculesTypes] = useState([]);
    const [vehiculesServices, setVehiculesServices] = useState([]);
    const [vehiculesWilayas, setVehiculesWilayas] = useState([]);
    const [vehiculesAffectations, setVehiculesAffectations] = useState([]);
    const [vehiculesAffectationsStatuts, setVehiculesAffectationsStatuts] = useState([]);
    const [vehiculesProblemes, setVehiculesProblemes] = useState([]);
    const [vehiculesProblemesPriorite, setVehiculesProblemesPriorite] = useState([]);
    const [rappelsService, setRappelsService] = useState([]);
    const [tauxEchecInspection, setTauxEchecInspection] = useState([]);
    const [resumeInspection, setResumeInspection] = useState([]);

    const [assurances, setAssurances] = useState([]);
    const [controleTechnique, setControleTechnique] = useState([]);
    const [employesStatuts, setEmployesStatuts] = useState([]);
    const [permisConduireStatuts, setPermisConduireStatuts] = useState([]);
    const [nombreEntreeService, setNombreEntreeService] = useState([]);
    const [nombreOrdreTravailStatuts, setNombreOrdreTravailStatuts] = useState([]);
    const [nombreOrdreAujourdhuit, setNombreOrdreAujourdhuit] = useState([]);
    const [nombreDepenseCatagorie, setNombreDepenseCatagorie] = useState([]);
    const [totalDistance, setTotalDistance] = useState(0);
    const [totalVehicules, setTotalVehicules] = useState(0);
    const [totalServices, setTotalServices] = useState(0);
    const [totalDepenses, setTotalDepenses] = useState(0);
    const [dernierReleveCompteur, setDernierReleveCompteur] = useState([]);
    const [coutServices, setCoutServices] = useState([]);
    const [coutDepenses, setCoutDepenses] = useState([]);
    const [chauffeursInternesExternes, setChauffeursInternesExternes] = useState([]);

    //Load data
    const getVehiculesStatuts = async () => {
        try {
            const queryParams = new URLSearchParams({});

            if (authData?.wilayas != null) {
                const wilayasFilter = authData?.wilayas
                queryParams.append("wilayas", wilayasFilter.join(','));
            }
            else {
                return
            }

            if (authData?.flottesAutorises != null) {
                const flottesAutorisesFilter = authData?.flottesAutorises
                queryParams.append("flottesAutorises", flottesAutorisesFilter.join(','));
            }
            else {
                return
            }

            const response = await axiosInstance.get(`${MyDashboard}/GetVehiculeStatutsCounts?${queryParams}`);
            const resp = await response.data;
            setVehiculesStatuts(resp);
        }
        catch (err) {
            console.log(err);
        }
    }

    const getVehiculesTypes = async () => {
        try {
            const queryParams = new URLSearchParams({});

            if (authData?.wilayas != null) {
                const wilayasFilter = authData?.wilayas
                queryParams.append("wilayas", wilayasFilter.join(','));
            }
            else {
                return
            }

            if (authData?.flottesAutorises != null) {
                const flottesAutorisesFilter = authData?.flottesAutorises
                queryParams.append("flottesAutorises", flottesAutorisesFilter.join(','));
            }
            else {
                return
            }

            const response = await axiosInstance.get(`${MyDashboard}/GetVehiculeTypesCounts?${queryParams}`);
            const resp = await response.data;
            setVehiculesTypes(resp);
        }
        catch (err) {
            console.log(err);
        }
    }

    ///////////////////////////////////Vehicules par services
    const getVehiculeServiceCounts = async () => {
        try {
            const queryParams = new URLSearchParams({});

            if (authData?.wilayas != null) {
                const wilayasFilter = authData?.wilayas
                queryParams.append("wilayas", wilayasFilter.join(','));
            }
            else {
                return
            }

            if (authData?.flottesAutorises != null) {
                const flottesAutorisesFilter = authData?.flottesAutorises
                queryParams.append("flottesAutorises", flottesAutorisesFilter.join(','));
            }
            else {
                return
            }

            const response = await axiosInstance.get(`${MyDashboard}/GetVehiculeServiceCounts?${queryParams}`);
            const resp = await response.data;
            setVehiculesServices(resp);
        }
        catch (err) {
            console.log(err);
        }
    }

    const dataVehiculesServices = {
        labels: ['Ramassage', 'Navette', 'Transport', 'Restauration', 'Citerne', 'Benne tasseuse'],
        datasets: [
            {
                //label: ['Internes','Externes'],
                data: [vehiculesServices.countRamassage, vehiculesServices.countNavette, vehiculesServices.countTransport, vehiculesServices.countRestauration, vehiculesServices.countCiterne, vehiculesServices.countBenneTasseuse],
                backgroundColor: [
                    'rgba(35, 200, 150, 1)',
                    'rgba(251, 119, 87, 1)',
                    'rgba(255, 206, 86, 1)',
                    'rgba(153, 102, 255, 1)',
                    'rgba(54, 162, 235, 1)',
                    'rgba(255, 159, 64, 1)'
                ],
                borderWidth: 1,
                hoverOffset: 8,
                responsive: true,
                maintainAspectRatio: false,
            },
        ],
    };

    const chartOptionsVehiculesServices = {
        plugins: {
            tooltip: {
                callbacks: {
                    title: () => '',
                    label: (context) => {
                        let labels = ['Ramassage', 'Navette', 'Transport', 'Restauration', 'Citerne', 'Benne tasseuse'];
                        const label = labels[context.dataIndex];
                        const value = dataVehiculesServices.datasets[0].data[context.dataIndex];
                        return `${label}: ${value}`;
                    },
                },
                padding: 10,
                position: 'nearest',
            },
            title: {
                display: true,
                text: "Véhicules par services"
            }
        },
    };

    const getVehiculeWilayaCounts = async () => {
        try {
            const queryParams = new URLSearchParams({});

            if (authData?.wilayas != null) {
                const wilayasFilter = authData?.wilayas
                queryParams.append("wilayas", wilayasFilter.join(','));
            }
            else {
                return
            }

            if (authData?.flottesAutorises != null) {
                const flottesAutorisesFilter = authData?.flottesAutorises
                queryParams.append("flottesAutorises", flottesAutorisesFilter.join(','));
            }
            else {
                return
            }

            const response = await axiosInstance.get(`${MyDashboard}/GetVehiculeWilayaCounts?${queryParams}`);
            const resp = await response.data;
            setVehiculesWilayas(resp);
        }
        catch (err) {
            console.log(err);
        }
    }

    const getVehiculesAffectationsStatuts = async () => {
        try {
            const queryParams = new URLSearchParams({});

            if (authData?.wilayas != null) {
                const wilayasFilter = authData?.wilayas
                queryParams.append("wilayas", wilayasFilter.join(','));
            }
            else {
                return
            }

            if (authData?.flottesAutorises != null) {
                const flottesAutorisesFilter = authData?.flottesAutorises
                queryParams.append("flottesAutorises", flottesAutorisesFilter.join(','));
            }
            else {
                return
            }

            const response = await axiosInstance.get(`${MyDashboard}/GetVehiculeAffectationStatutsCounts?${queryParams}`);
            const resp = await response.data;
            setVehiculesAffectations(resp);
        }
        catch (err) {
            console.log(err);
        }
    }

    const getVehiculeProblemesSatutsCounts = async () => {
        try {
            const queryParams = new URLSearchParams({});

            if (authData?.wilayas != null) {
                const wilayasFilter = authData?.wilayas
                queryParams.append("wilayas", wilayasFilter.join(','));
            }
            else {
                return
            }

            if (authData?.flottesAutorises != null) {
                const flottesAutorisesFilter = authData?.flottesAutorises
                queryParams.append("flottesAutorises", flottesAutorisesFilter.join(','));
            }
            else {
                return
            }

            const response = await axiosInstance.get(`${MyDashboard}/GetVehiculeProblemesStatusCounts?${queryParams}`);
            const resp = await response.data;
            setVehiculesProblemes(resp);
        }
        catch (err) {
            console.log(err);
        }
    }

    const getVehiculeProblemesPrioriteCounts = async () => {
        try {
            const queryParams = new URLSearchParams({});

            if (authData?.wilayas != null) {
                const wilayasFilter = authData?.wilayas
                queryParams.append("wilayas", wilayasFilter.join(','));
            }
            else {
                return
            }

            if (authData?.flottesAutorises != null) {
                const flottesAutorisesFilter = authData?.flottesAutorises
                queryParams.append("flottesAutorises", flottesAutorisesFilter.join(','));
            }
            else {
                return
            }

            const response = await axiosInstance.get(`${MyDashboard}/GetVehiculeProblemesPrioriteCounts?${queryParams}`);
            const resp = await response.data;
            setVehiculesProblemesPriorite(resp);
        }
        catch (err) {
            console.log(err);
        }
    }

    const getAffectationStatutsCounts = async () => {
        try {
            const currentDate = moment();
            const dateDebut = currentDate.clone().subtract(7, 'days');
            const dateFin = currentDate.clone().add(7, 'days');

            const queryParams = new URLSearchParams({
                dateDebut: dateDebut.format('YYYY-MM-DD'),
                dateFin: dateFin.format('YYYY-MM-DD')
            });

            if (authData?.wilayas != null) {
                const wilayasFilter = authData?.wilayas
                queryParams.append("wilayas", wilayasFilter.join(','));
            }
            else {
                return
            }

            if (authData?.flottesAutorises != null) {
                const flottesAutorisesFilter = authData?.flottesAutorises
                queryParams.append("flottesAutorises", flottesAutorisesFilter.join(','));
            }
            else {
                return
            }

            const response = await axiosInstance.get(`${MyDashboard}/GetAffectationStatutsCounts?${queryParams}`);
            const resp = await response.data;
            setVehiculesAffectationsStatuts(resp);
        }
        catch (err) {
            console.log(err);
        }
    }

    const getVehiculeRappelsServiceCounts = async () => {
        try {
            const queryParams = new URLSearchParams({});

            if (authData?.wilayas != null) {
                const wilayasFilter = authData?.wilayas
                queryParams.append("wilayas", wilayasFilter.join(','));
            }
            else {
                return
            }

            if (authData?.flottesAutorises != null) {
                const flottesAutorisesFilter = authData?.flottesAutorises
                queryParams.append("flottesAutorises", flottesAutorisesFilter.join(','));
            }
            else {
                return
            }

            const response = await axiosInstance.get(`${MyDashboard}/GetVehiculeRappelsServiceCounts?${queryParams}`);
            const resp = await response.data;
            setRappelsService(resp);
        }
        catch (err) {
            console.log(err);
        }
    }

    const getTauxEchecElementInspection = async () => {
        try {

            const currentDate = moment();
            const startOfCurrWeek = currentDate.startOf('week');
            const startOfPrevWeek = startOfCurrWeek.clone().subtract(7, 'days');

            const queryParams = new URLSearchParams({
                debSemaineCourante: startOfCurrWeek.format('YYYY-MM-DD'),
                debSemainePrecedente: startOfPrevWeek.format('YYYY-MM-DD')
            });

            if (authData?.wilayas != null) {
                const wilayasFilter = authData?.wilayas
                queryParams.append("wilayas", wilayasFilter.join(','));
            }
            else {
                return
            }

            if (authData?.flottesAutorises != null) {
                const flottesAutorisesFilter = authData?.flottesAutorises
                queryParams.append("flottesAutorises", flottesAutorisesFilter.join(','));
            }
            else {
                return
            }

            const response = await axiosInstance.get(`${MyDashboard}/GetTauxEchecElementInspection?${queryParams}`);
            const resp = await response.data;
            setTauxEchecInspection(resp);
        }
        catch (err) {
            console.log(err);
        }
    }

    ////////////////////// Resumé
    const getResumeInspection = async () => {
        try {

            const currentDate = moment();
            const dateDeb = currentDate.clone().subtract(30, 'days');

            const queryParams = new URLSearchParams({
                dateDeb: dateDeb.format('YYYY-MM-DD')
            });

            if (authData?.wilayas != null) {
                const wilayasFilter = authData?.wilayas
                queryParams.append("wilayas", wilayasFilter.join(','));
            }
            else {
                return
            }

            if (authData?.flottesAutorises != null) {
                const flottesAutorisesFilter = authData?.flottesAutorises
                queryParams.append("flottesAutorises", flottesAutorisesFilter.join(','));
            }
            else {
                return
            }

            const response = await axiosInstance.get(`${MyDashboard}/GetResumeInspection?${queryParams}`);
            const resp = await response.data;
            setResumeInspection(resp);
        }
        catch (err) {
            console.log(err);
        }
    }

    const dataResume = {
        labels: [],
        datasets: [
            {
                //label: ['Véhicules avec 1 ou plusieurs inspections','Véhicules avec 0 inspection'],
                data: [resumeInspection.vehiculesAvecInspectionsPourcentage !== undefined ? resumeInspection.vehiculesAvecInspectionsPourcentage.toFixed(2) : 0, resumeInspection.vehiculesSansInspectionsPourcentage !== undefined ? resumeInspection.vehiculesSansInspectionsPourcentage.toFixed(2) : 0],
                backgroundColor: [
                    'rgba(35, 200, 150, 1)',
                    'rgba(251, 119, 87, 1)'
                ],
                // borderColor: [
                //     'rgba(35, 200, 150, 1)',
                //     'rgba(251, 119, 87, 1)'
                // ],
                // hoverBackgroundColor:[
                //     'rgba(35, 200, 150, 0.2)',
                //     'rgba(251, 119, 87, 0.2)'
                // ],
                borderWidth: 1,
                hoverOffset: 8,
                responsive: true,
                maintainAspectRatio: false,
            },
        ],
    };

    const chartOptionsResume = {
        plugins: {
            tooltip: {
                callbacks: {
                    label: (context) => {
                        let labels = ['Véhicules avec plusieurs inspections', 'Véhicules avec 0 inspection'];
                        const label = labels[context.dataIndex];
                        const value = dataResume.datasets[0].data[context.dataIndex];
                        return `${label}: ${value} %`;
                    },
                },
                padding: 10,
                position: 'nearest',
            },
            title: {
                display: true,
                text: "30 derniers jours"
            }
        },
    };

    const getVehiculeAssuranceCounts = async () => {
        try {
            const queryParams = new URLSearchParams({});

            if (authData?.wilayas != null) {
                const wilayasFilter = authData?.wilayas
                queryParams.append("wilayas", wilayasFilter.join(','));
            }
            else {
                return
            }

            if (authData?.flottesAutorises != null) {
                const flottesAutorisesFilter = authData?.flottesAutorises
                queryParams.append("flottesAutorises", flottesAutorisesFilter.join(','));
            }
            else {
                return
            }

            const response = await axiosInstance.get(`${MyDashboard}/GetVehiculeAssuranceCounts?${queryParams}`);
            const resp = await response.data;
            setAssurances(resp);
        }
        catch (err) {
            console.log(err);
        }
    }

    const getVehiculeControleTechniqueCounts = async () => {
        try {
            const queryParams = new URLSearchParams({});

            if (authData?.wilayas != null) {
                const wilayasFilter = authData?.wilayas
                queryParams.append("wilayas", wilayasFilter.join(','));
            }
            else {
                return
            }

            if (authData?.flottesAutorises != null) {
                const flottesAutorisesFilter = authData?.flottesAutorises
                queryParams.append("flottesAutorises", flottesAutorisesFilter.join(','));
            }
            else {
                return
            }

            const response = await axiosInstance.get(`${MyDashboard}/GetVehiculeControleTechniqueCounts?${queryParams}`);
            const resp = await response.data;
            setControleTechnique(resp);
        }
        catch (err) {
            console.log(err);
        }
    }

    const getEmployeStatutsCounts = async () => {
        try {
            const response = await axiosInstance.get(`${MyDashboard}/GetEmployeStatutsCounts`);
            const resp = await response.data;
            setEmployesStatuts(resp);
        }
        catch (err) {
            console.log(err);
        }
    }

    const getEmployePermisStatutsCounts = async () => {
        try {
            const response = await axiosInstance.get(`${MyDashboard}/GetEmployePermisStatutsCounts`);
            const resp = await response.data;
            setPermisConduireStatuts(resp);
        }
        catch (err) {
            console.log(err);
        }
    }

    const getNombreEntreeService = async () => {
        try {

            const currentDate = moment();
            const dateDeb = currentDate.clone().subtract(30, 'days');

            const queryParams = new URLSearchParams({
                dateDeb: dateDeb.format('YYYY-MM-DD')
            });

            if (authData?.wilayas != null) {
                const wilayasFilter = authData?.wilayas
                queryParams.append("wilayas", wilayasFilter.join(','));
            }
            else {
                return
            }

            if (authData?.flottesAutorises != null) {
                const flottesAutorisesFilter = authData?.flottesAutorises
                queryParams.append("flottesAutorises", flottesAutorisesFilter.join(','));
            }
            else {
                return
            }

            const response = await axiosInstance.get(`${MyDashboard}/GetNombreEntreeService?${queryParams}`);
            const resp = await response.data;
            setNombreEntreeService(resp);
        }
        catch (err) {
            console.log(err);
        }
    }

    const getOrdreTravailStatusCounts = async () => {
        try {

            const currentDate = moment();
            const dateDeb = currentDate.clone().subtract(30, 'days');

            const queryParams = new URLSearchParams({
                dateDeb: dateDeb.format('YYYY-MM-DD')
            });

            if (authData?.wilayas != null) {
                const wilayasFilter = authData?.wilayas
                queryParams.append("wilayas", wilayasFilter.join(','));
            }
            else {
                return
            }

            if (authData?.flottesAutorises != null) {
                const flottesAutorisesFilter = authData?.flottesAutorises
                queryParams.append("flottesAutorises", flottesAutorisesFilter.join(','));
            }
            else {
                return
            }

            const response = await axiosInstance.get(`${MyDashboard}/GetOrdreTravailStatusCounts?${queryParams}`);
            const resp = await response.data;
            setNombreOrdreTravailStatuts(resp);
        }
        catch (err) {
            console.log(err);
        }
    }

    const getNombreOrderTravailAujourdhuit = async () => {
        try {
            const queryParams = new URLSearchParams({});

            if (authData?.wilayas != null) {
                const wilayasFilter = authData?.wilayas
                queryParams.append("wilayas", wilayasFilter.join(','));
            }
            else {
                return
            }

            if (authData?.flottesAutorises != null) {
                const flottesAutorisesFilter = authData?.flottesAutorises
                queryParams.append("flottesAutorises", flottesAutorisesFilter.join(','));
            }
            else {
                return
            }

            const response = await axiosInstance.get(`${MyDashboard}/GetNombreOrderTravailAujourdhuit?${queryParams}`);
            const resp = await response.data;
            setNombreOrdreAujourdhuit(resp);
        }
        catch (err) {
            console.log(err);
        }
    }

    const getNombreDepenseParCategorie = async () => {
        try {
            const queryParams = new URLSearchParams({});

            if (authData?.wilayas != null) {
                const wilayasFilter = authData?.wilayas
                queryParams.append("wilayas", wilayasFilter.join(','));
            }
            else {
                return
            }

            if (authData?.flottesAutorises != null) {
                const flottesAutorisesFilter = authData?.flottesAutorises
                queryParams.append("flottesAutorises", flottesAutorisesFilter.join(','));
            }
            else {
                return
            }

            const response = await axiosInstance.get(`${MyDashboard}/GetNombreDepenseParCategorie?${queryParams}`);
            const resp = await response.data;
            setNombreDepenseCatagorie(resp);
        }
        catch (err) {
            console.log(err);
        }
    }

    const getDistanceParcourue = async () => {
        try {
            const queryParams = new URLSearchParams({});

            if (authData?.wilayas != null) {
                const wilayasFilter = authData?.wilayas
                queryParams.append("wilayas", wilayasFilter.join(','));
            }
            else {
                return
            }

            if (authData?.flottesAutorises != null) {
                const flottesAutorisesFilter = authData?.flottesAutorises
                queryParams.append("flottesAutorises", flottesAutorisesFilter.join(','));
            }
            else {
                return
            }

            const response = await axiosInstance.get(`${MyDashboard}/GetDistanceParcourue?${queryParams}`);
            const resp = await response.data;
            setTotalDistance(resp);
        }
        catch (err) {
            console.log(err);
        }
    }

    const getFlotteCount = async () => {
        try {
            const queryParams = new URLSearchParams({});

            if (authData?.wilayas != null) {
                const wilayasFilter = authData?.wilayas
                queryParams.append("wilayas", wilayasFilter.join(','));
            }
            else {
                return
            }

            if (authData?.flottesAutorises != null) {
                const flottesAutorisesFilter = authData?.flottesAutorises
                queryParams.append("flottesAutorises", flottesAutorisesFilter.join(','));
            }
            else {
                return
            }

            const response = await axiosInstance.get(`${MyDashboard}/GetFlotteCount?${queryParams}`);
            const resp = await response.data;
            setTotalVehicules(resp);
        }
        catch (err) {
            console.log(err);
        }
    }

    const getTotaleService = async () => {
        try {
            const queryParams = new URLSearchParams({});

            if (authData?.wilayas != null) {
                const wilayasFilter = authData?.wilayas
                queryParams.append("wilayas", wilayasFilter.join(','));
            }
            else {
                return
            }

            if (authData?.flottesAutorises != null) {
                const flottesAutorisesFilter = authData?.flottesAutorises
                queryParams.append("flottesAutorises", flottesAutorisesFilter.join(','));
            }
            else {
                return
            }

            const response = await axiosInstance.get(`${MyDashboard}/GetTotaleService?${queryParams}`);
            const resp = await response.data;
            setTotalServices(resp);
        }
        catch (err) {
            console.log(err);
        }
    }

    const getTotaleDepense = async () => {
        try {
            const queryParams = new URLSearchParams({});

            if (authData?.wilayas != null) {
                const wilayasFilter = authData?.wilayas
                queryParams.append("wilayas", wilayasFilter.join(','));
            }
            else {
                return
            }

            if (authData?.flottesAutorises != null) {
                const flottesAutorisesFilter = authData?.flottesAutorises
                queryParams.append("flottesAutorises", flottesAutorisesFilter.join(','));
            }
            else {
                return
            }

            const response = await axiosInstance.get(`${MyDashboard}/GetTotaleDepense?${queryParams}`);
            const resp = await response.data;
            setTotalDepenses(resp);
        }
        catch (err) {
            console.log(err);
        }
    }

    ///////Relevé de compteur
    const getReleveCompteurs = async () => {
        try {
            const queryParams = new URLSearchParams({});

            if (authData?.wilayas != null) {
                const wilayasFilter = authData?.wilayas
                queryParams.append("wilayas", wilayasFilter.join(','));
            }
            else {
                return
            }

            if (authData?.flottesAutorises != null) {
                const flottesAutorisesFilter = authData?.flottesAutorises
                queryParams.append("flottesAutorises", flottesAutorisesFilter.join(','));
            }
            else {
                return
            }

            const response = await axiosInstance.get(`${MyDashboard}/GetReleveCompteurs?${queryParams}`);
            const resp = await response.data;
            setDernierReleveCompteur(resp);
            //console.log(resp)
        }
        catch (err) {
            console.log(err);
        }
    }

    const dataReleve = {
        labels: [],
        datasets: [
            {
                data: dernierReleveCompteur?.map((item) => ({
                    x: moment(item.kilometrageActuelDate).format('YYYY-MM-DD'),//new Date(item.kilometrageActuelDate),// x-coordinate
                    y: item.kilometrageActuel, // y-coordinate 
                    r: 10, // radius (size)
                    marque: item.marque,// Additional data for the tooltip
                    modele: item.modele,
                    immatriculation: item.immatriculation,
                    kilometrageActuel: item.kilometrageActuel,
                    kilometrageActuelDate: moment(item.kilometrageActuelDate).format('YYYY-MM-DD')
                })),
                backgroundColor: 'rgba(35, 200, 150, 0.5)',
                borderWidth: 1,
                hoverOffset: 8,
                responsive: true,
                maintainAspectRatio: false,
            },
        ],
    };

    const chartOptionsReleve = {
        // layout: {
        //     padding: {
        //         right: 50
        //     }
        // },
        scales: {
            x: {
                type: 'time',
                time: {
                    unit: 'month',
                },
                title: {
                    display: false,
                    text: 'Kilometrage Actuel Date',
                },
                min: moment(dernierReleveCompteur[0]?.kilometrageActuelDate).add(-1, 'month').format('YYYY-MM-DD'),
                max: moment().add(1, 'month').format('YYYY-MM-DD')
            },
            y: {
                type: 'linear',
                position: 'bottom',
                beginAtZero: true,
                grace: 1,
                title: {
                    display: false,
                    text: 'Kilometrage Actuel',
                },
            }
        },
        plugins: {
            legend: {
                display: false,
            },
            title: {
                display: false,
                text: 'Bubble Chart',
            },
            tooltip: {
                enabled: true,
                padding: 10,
                position: 'nearest',
                displayColors: false,
                callbacks: {
                    //   title: (context) => {
                    //     // Display the date in the tooltip title
                    //     // return format(context[0].parsed.y, 'dd MMMM yyyy', {
                    //     //   locale: de,
                    //     // });
                    //     //console.log(context)
                    //     return moment(context[0].parsed.y).format('YYYY-MM-DD')
                    //   },
                    title: () => '',
                    label: (context) => {
                        // Display additional information in the tooltip body
                        //const dataPoint = context.parsed;//{totalDistance.toFixed(2)} {authData?.unite_distance}
                        const rawData = context.raw;
                        //console.log(context.raw)
                        return [
                            `Marque: ${rawData.marque}`,
                            `Modele: ${rawData.modele}`,
                            `Immatriculation: ${rawData.immatriculation}`,
                            `Compteur: ${rawData.kilometrageActuel} ${authData?.unite_distance}`,
                            `Date lecture: ${moment(rawData.kilometrageActuelDate).format('DD-MM-YYYY')}`,
                        ];
                    },
                },
            },
        },
    };

    //////// couts services
    const getCoutsServices = async () => {
        try {
            const queryParams = new URLSearchParams({});

            if (authData?.wilayas != null) {
                const wilayasFilter = authData?.wilayas
                queryParams.append("wilayas", wilayasFilter.join(','));
            }
            else {
                return
            }

            if (authData?.flottesAutorises != null) {
                const flottesAutorisesFilter = authData?.flottesAutorises
                queryParams.append("flottesAutorises", flottesAutorisesFilter.join(','));
            }
            else {
                return
            }

            const response = await axiosInstance.get(`${MyDashboard}/GetCoutsServices?${queryParams}`);
            const resp = await response.data;
            setCoutServices(resp);
        }
        catch (err) {
            console.log(err);
        }
    }

    const dataCoutsService = {
        labels: coutServices.map((item) => item.moisNom),
        datasets: [
            {
                label: 'Total Cout',
                data: coutServices.map((item) => item.totalCout),
                backgroundColor: 'rgba(255, 193, 7, 1)', // Bar color
                borderWidth: 1,
            },
        ],
    };

    const chartOptionsCoutsService = {
        responsive: true,
        scales: {
            x: {
                title: {
                    display: false,
                    text: 'Mois',
                },
            },
            y: {
                title: {
                    display: false,
                    text: 'Total Cout',
                },
                beginAtZero: true,
                grace: 1,
                ticks: {
                    callback: (value) => formatYAxis(value), // Use the custom callback for formatting y-axis ticks
                },
            },
        },
        plugins: {
            legend: {
                display: false,
            },
            title: {
                display: false,
                text: 'Bar Chart',
            },
            tooltip: {
                enabled: true,
                padding: 10,
                position: 'nearest',
                displayColors: false,
                callbacks: {
                    title: () => '',
                    label: (context) => {
                        const dataIndex = context.dataIndex;
                        const label = `${coutServices[dataIndex].moisNom}: ${formatCurrency(coutServices[dataIndex].totalCout)} ${authData?.unite_moneitaire}`;
                        return label;
                    },
                },
            },
        },
    };

    //////// couts dépenses

    const getCoutsDepenses = async () => {
        try {
            const queryParams = new URLSearchParams({});

            if (authData?.wilayas != null) {
                const wilayasFilter = authData?.wilayas
                queryParams.append("wilayas", wilayasFilter.join(','));
            }
            else {
                return
            }

            if (authData?.flottesAutorises != null) {
                const flottesAutorisesFilter = authData?.flottesAutorises
                queryParams.append("flottesAutorises", flottesAutorisesFilter.join(','));
            }
            else {
                return
            }

            const response = await axiosInstance.get(`${MyDashboard}/GetCoutsDepenses?${queryParams}`);
            const resp = await response.data;
            setCoutDepenses(resp);
        }
        catch (err) {
            console.log(err);
        }
    }

    const dataCoutsDepenses = {
        labels: coutDepenses.map((item) => item.moisNom),
        datasets: [
            {
                label: 'Total Cout',
                data: coutDepenses.map((item) => item.totalCout),
                backgroundColor: 'rgba(52, 195, 152, 1)', // Bar color
                borderWidth: 1,
            },
        ],
    };

    const chartOptionsCoutsDepenses = {
        responsive: true,
        scales: {
            x: {
                title: {
                    display: false,
                    text: 'Mois',
                },
            },
            y: {
                title: {
                    display: false,
                    text: 'Total Cout',
                },
                beginAtZero: true,
                grace: 1,
                ticks: {
                    callback: (value) => formatYAxis(value), // Use the custom callback for formatting y-axis ticks
                },
            },
        },
        plugins: {
            legend: {
                display: false,
            },
            title: {
                display: false,
                text: 'Bar Chart',
            },
            tooltip: {
                enabled: true,
                padding: 10,
                position: 'nearest',
                displayColors: false,
                callbacks: {
                    title: () => '',
                    label: (context) => {
                        const dataIndex = context.dataIndex;
                        const label = `${coutDepenses[dataIndex].moisNom}: ${formatCurrency(coutDepenses[dataIndex].totalCout)} ${authData?.unite_moneitaire}`;
                        return label;
                    },
                },
            },
        },
    };

    ////////////////////////Répartition chauffeurs

    const getChauffeurCategorie = async () => {
        try {
            const response = await axiosInstance.get(`${MyDashboard}/GetChauffeurCategorie`);
            const resp = await response.data;
            setChauffeursInternesExternes(resp);
        }
        catch (err) {
            console.log(err);
        }
    }

    const dataChauffeursCategorie = {
        labels: ['Internes', 'Externes'],
        datasets: [
            {
                //label: ['Internes','Externes'],
                data: [chauffeursInternesExternes.chauffeursInternes, chauffeursInternesExternes.chauffeursExternes],
                backgroundColor: [
                    'rgba(35, 200, 150, 1)',
                    'rgba(251, 119, 87, 1)'
                ],
                // borderColor: [
                //     'rgba(35, 200, 150, 1)',
                //     'rgba(251, 119, 87, 1)'
                // ],
                // hoverBackgroundColor:[
                //     'rgba(35, 200, 150, 0.2)',
                //     'rgba(251, 119, 87, 0.2)'
                // ],
                borderWidth: 1,
                hoverOffset: 8,
                responsive: true,
                maintainAspectRatio: false,
            },
        ],
    };

    const chartOptionsChauffeursCategorie = {
        plugins: {
            tooltip: {
                callbacks: {
                    title: () => '',
                    label: (context) => {
                        let labels = ['Internes', 'Externes'];
                        const label = labels[context.dataIndex];
                        const value = dataChauffeursCategorie.datasets[0].data[context.dataIndex];
                        return `${label}: ${value}`;
                    },
                },
                padding: 10,
                position: 'nearest',
            },
            title: {
                display: false,
                text: "Répartition des chauffeurs"
            }
        },
    };

    //////// Autres fonctions   

    const openNewTab = (componentName, parameters) => {
        // Pass parameters via state
        //navigate(`/${componentName}`, { state: { param1: 'value1', param2: 'value2' } });
        navigate(`/${componentName}`, { state: parameters }, { replace: true });
    };

    useEffect(() => {
        //Load Data
        getVehiculesStatuts();
        return () => {
            // Perform any necessary cleanup (e.g., cancel pending requests)
        };
    }, [authData]);

    useEffect(() => {
        //Load Data
        getVehiculesTypes();
        return () => {
            // Perform any necessary cleanup (e.g., cancel pending requests)
        };
    }, [authData]);

    useEffect(() => {
        //Load Data
        getVehiculeServiceCounts();
        return () => {
            // Perform any necessary cleanup (e.g., cancel pending requests)
        };
    }, [authData]);

    useEffect(() => {
        //Load Data
        getVehiculeWilayaCounts();
        return () => {
            // Perform any necessary cleanup (e.g., cancel pending requests)
        };
    }, [authData]);

    useEffect(() => {
        //Load Data
        getVehiculeAssuranceCounts();
        return () => {
            // Perform any necessary cleanup (e.g., cancel pending requests)
        };
    }, [authData]);

    useEffect(() => {
        //Load Data
        getVehiculeControleTechniqueCounts();
        return () => {
            // Perform any necessary cleanup (e.g., cancel pending requests)
        };
    }, [authData]);

    useEffect(() => {
        //Load Data
        getVehiculesAffectationsStatuts();
        return () => {
            // Perform any necessary cleanup (e.g., cancel pending requests)
        };
    }, [authData]);

    useEffect(() => {
        //Load Data
        getAffectationStatutsCounts();
        return () => {
            // Perform any necessary cleanup (e.g., cancel pending requests)
        };
    }, [authData]);

    useEffect(() => {
        //Load Data
        getVehiculeProblemesSatutsCounts();
        return () => {
            // Perform any necessary cleanup (e.g., cancel pending requests)
        };
    }, [authData]);

    useEffect(() => {
        //Load Data
        getVehiculeProblemesPrioriteCounts();
        return () => {
            // Perform any necessary cleanup (e.g., cancel pending requests)
        };
    }, [authData]);

    useEffect(() => {
        //Load Data
        getTauxEchecElementInspection();
        return () => {
            // Perform any necessary cleanup (e.g., cancel pending requests)
        };
    }, [authData]);

    useEffect(() => {
        //Load Data
        getResumeInspection();
        return () => {
            // Perform any necessary cleanup (e.g., cancel pending requests)
        };
    }, [authData]);

    useEffect(() => {
        //Load Data
        getEmployeStatutsCounts();
        return () => {
            // Perform any necessary cleanup (e.g., cancel pending requests)
        };
    }, [authData]);

    useEffect(() => {
        //Load Data
        getEmployePermisStatutsCounts();
        return () => {
            // Perform any necessary cleanup (e.g., cancel pending requests)
        };
    }, [authData]);

    useEffect(() => {
        //Load Data
        getNombreEntreeService();
        return () => {
            // Perform any necessary cleanup (e.g., cancel pending requests)
        };
    }, [authData]);

    useEffect(() => {
        //Load Data
        getVehiculeRappelsServiceCounts();
        return () => {
            // Perform any necessary cleanup (e.g., cancel pending requests)
        };
    }, [authData]);

    useEffect(() => {
        //Load Data
        getOrdreTravailStatusCounts();
        return () => {
            // Perform any necessary cleanup (e.g., cancel pending requests)
        };
    }, [authData]);

    useEffect(() => {
        //Load Data
        getNombreOrderTravailAujourdhuit();
        return () => {
            // Perform any necessary cleanup (e.g., cancel pending requests)
        };
    }, [authData]);

    useEffect(() => {
        //Load Data
        getNombreDepenseParCategorie();
        return () => {
            // Perform any necessary cleanup (e.g., cancel pending requests)
        };
    }, [authData]);

    useEffect(() => {
        //Load Data
        getDistanceParcourue();
        return () => {
            // Perform any necessary cleanup (e.g., cancel pending requests)
        };
    }, [authData]);

    useEffect(() => {
        //Load Data
        getFlotteCount();
        return () => {
            // Perform any necessary cleanup (e.g., cancel pending requests)
        };
    }, [authData]);

    useEffect(() => {
        //Load Data
        getTotaleService();
        return () => {
            // Perform any necessary cleanup (e.g., cancel pending requests)
        };
    }, [authData]);

    useEffect(() => {
        //Load Data
        getTotaleDepense();
        return () => {
            // Perform any necessary cleanup (e.g., cancel pending requests)
        };
    }, [authData]);

    useEffect(() => {
        //Load Data
        getReleveCompteurs();
        return () => {
            // Perform any necessary cleanup (e.g., cancel pending requests)
        };
    }, [authData]);

    useEffect(() => {
        //Load Data
        getCoutsServices();
        return () => {
            // Perform any necessary cleanup (e.g., cancel pending requests)
        };
    }, [authData]);

    useEffect(() => {
        //Load Data
        getCoutsDepenses();
        return () => {
            // Perform any necessary cleanup (e.g., cancel pending requests)
        };
    }, [authData]);

    useEffect(() => {
        //Load Data
        getChauffeurCategorie();
        return () => {
            // Perform any necessary cleanup (e.g., cancel pending requests)
        };
    }, [authData]);

    //SignalR: real time events
    useEffect(() => {
        // Subscribe to SignalR events to receive real-time updates
        //console.log("authData=", authData)
        if (isConnectionConnecting(connexionDashboard) && authData?.wilayas != null && authData?.flottesAutorises != null) {
            //console.log("isConnectionConnecting=",connexionDashboard);

            connexionDashboard.on('VehiculeEvent', () => {
                // connexionDashboard.invoke("GetFlotteCount", authData?.wilayas.join(','), authData?.flottesAutorises.join(','))
                //     .catch(error => console.error(error));
                getFlotteCount();
                getDistanceParcourue();
                getVehiculesTypes();
                getVehiculesStatuts();
                getVehiculeWilayaCounts();
                getVehiculeServiceCounts();
                getVehiculeControleTechniqueCounts();
                getVehiculeAssuranceCounts();
                getVehiculesAffectationsStatuts();
                getReleveCompteurs();
            });

            connexionDashboard.on('AffectationEvent', () => {
                getAffectationStatutsCounts();
                getVehiculesAffectationsStatuts();
                getDistanceParcourue();
                getReleveCompteurs();
            });

            connexionDashboard.on('AssuranceEvent', () => {
                getVehiculeAssuranceCounts();
            });

            connexionDashboard.on('ControleTechniqueEvent', () => {
                getVehiculeControleTechniqueCounts();
            });

            connexionDashboard.on('CompteurEvent', () => {
                getDistanceParcourue();
                getReleveCompteurs();
            });

            connexionDashboard.on('DepenseEvent', () => {
                getDistanceParcourue();
                getReleveCompteurs();
                getNombreDepenseParCategorie();
                getCoutsDepenses();
                getTotaleDepense();
            });

            connexionDashboard.on('EntreeServiceEvent', () => {
                getDistanceParcourue();
                getReleveCompteurs();
                getCoutsServices();
                getTotaleService();
                getNombreEntreeService();
                getOrdreTravailStatusCounts();
                getNombreOrderTravailAujourdhuit();
            });

            connexionDashboard.on('OrdreTravailEvent', () => {
                getDistanceParcourue();
                getReleveCompteurs();
                getCoutsServices();
                getTotaleService();
                getNombreEntreeService();
                getOrdreTravailStatusCounts();
                getNombreOrderTravailAujourdhuit();
            });

            connexionDashboard.on('ProblemeEvent', () => {
                getDistanceParcourue();
                getReleveCompteurs();
                getVehiculeProblemesPrioriteCounts();
                getVehiculeProblemesSatutsCounts();
            });

            connexionDashboard.on('InspectionEvent', () => {
                getDistanceParcourue();
                getReleveCompteurs();
                getTauxEchecElementInspection();
                getResumeInspection();
            });

            connexionDashboard.on('EmployeEvent', () => {
                //console.log("EmployeEvent1", new Date())
                getEmployeStatutsCounts();
                //console.log("EmployeEvent2", new Date())
                getEmployePermisStatutsCounts();
                //console.log("EmployeEvent3", new Date())
                getChauffeurCategorie();
                //console.log("EmployeEvent4=", new Date())
            });
            //console.log("EmployeEvent5", new Date())
            // connexionDashboard.on('flotteCount', (data) => {
            //     setTotalVehicules(data);
            // });
        }

        return () => {
            // Perform any necessary cleanup (e.g., cancel pending requests)
            //console.log("EmployeEvent=return=", new Date())
        };
    }, [authData]);

    return (
        <section className="statistics-section">
            <div className="container grid grid--1-cols">
                <div className="grid grid--3-cols">
                    <div className="card">
                        <div className="column">
                            <div className="srvc srvc-one-value">
                                <h3>Nombre de véhicules</h3>
                                <div className="col">
                                    <div className="row-center">
                                        <h2 className="success text-center number-medium">{totalVehicules}</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="card">
                        <div className="column">
                            <div className="srvc srvc-one-value">
                                <h3>Distance parcourue par la flotte</h3>
                                <div className="col">
                                    <div className="row-center">
                                        <h2 className="success text-center number-medium">{totalDistance.toFixed(2)} {authData?.unite_distance}</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="card" title="Total des coûts de service pour l'année courante">
                        <div className="column">
                            <div className="srvc srvc-one-value">
                                <h3>Coûts des services</h3>
                                <div className="col">
                                    <div className="row-center">
                                        <h2 className="success text-center number-medium">{formatCurrency(totalServices.toFixed(2))} {authData?.unite_moneitaire}</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="card" title="Total des coûts des dépenses pour l'année courante">
                        <div className="column">
                            <div className="srvc srvc-one-value">
                                <h3>Coûts des dépenses</h3>
                                <div className="col">
                                    <div className="row-center">
                                        <h2 className="success text-center number-medium">{formatCurrency(totalDepenses.toFixed(2))} {authData?.unite_moneitaire}</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="card" title="Nombre d'entrées de service au cours des 30 derniers jours">
                        <div className="column">
                            <div className="srvc srvc-one-value">
                                <h3>Nombre d'entrées de service</h3>
                                <div className="col">
                                    <div className="row-center">
                                        <h2 className="success text-center number-medium">{nombreEntreeService.nombreEntreeService}</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="card">
                        <div className="column">
                            <div className="srvc srvc-one-value">
                                <h3>Nombre d'ordres de travail ouverts aujourd'huit</h3>
                                <div className="col">
                                    <div className="row-center">
                                        <h2 className="success text-center number-medium">{nombreOrdreAujourdhuit.nombreOrderTravail}</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="grid grid--4-cols">
                    <div className="card" title="Affiche le nombre de véhicules dans différents types">
                        <div className='column'>
                            <div className="etat-vhc">
                                <h3>Types de véhicules</h3>
                                <div className="col scroll-short font-size-small">
                                    {vehiculesTypes.map((item, index) => (
                                        <div className="row" key={index}>
                                            <h3 className="">{item.libelleVehiculeType}</h3>
                                            <h2 className="number primary">{item.vehiculeCount}</h2>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="card" title="Affiche le nombre de véhicules dans différents statuts">
                        <div className="column">
                            <div className="stat-assr">
                                <h3>Statuts du véhicule</h3>
                                <div className="row">
                                    <div className="row row-baseline">
                                        <svg height="11.87" width="15">
                                            <circle cx="6" cy="6" r="6" fill="green" />
                                        </svg>
                                        <h3>Disponibles</h3>
                                    </div>
                                    <h3 className="number number-actif">{vehiculesStatuts.countDisponible}</h3>
                                </div>
                                <div className="row">
                                    <div className="row row-baseline">
                                        <svg height="11.87" width="15">
                                            <circle cx="6" cy="6" r="6" fill="red" />
                                        </svg>
                                        <h3>Hors service</h3>
                                    </div>
                                    <h3 className="number number-danger">{vehiculesStatuts.countHorsService}</h3>
                                </div>
                                <div className="row">
                                    <div className="row row-baseline">
                                        <svg height="11.87" width="15">
                                            <circle cx="6" cy="6" r="6" fill="yellow" />
                                        </svg>
                                        <h3>En cours d'utilisaton</h3>
                                    </div>
                                    <h3 className="number number-yellow">{vehiculesStatuts.countEnCours}</h3>
                                </div>
                                <div className="row">
                                    <div className="row row-baseline">
                                        <svg height="11.87" width="15">
                                            <circle cx="6" cy="6" r="6" fill="orangered" />
                                        </svg>
                                        <h3>Indisponibles</h3>
                                    </div>
                                    <h3 className="number number-orangered">{vehiculesStatuts.countIndisponible}</h3>
                                </div>
                                <div className="row">
                                    <div className="row row-baseline">
                                        <svg height="11.87" width="15">
                                            <circle cx="6" cy="6" r="6" fill="blue" />
                                        </svg>
                                        <h3>Vendus</h3>
                                    </div>
                                    <h3 className="number number-blue">{vehiculesStatuts.countVendu}</h3>
                                </div>
                                <div className="row">
                                    <div className="row row-baseline">
                                        <svg height="11.87" width="15">
                                            <circle cx="6" cy="6" r="6" fill="grey" />
                                        </svg>
                                        <h3>Archivés</h3>
                                    </div>
                                    <h3 className="number number-grey">{vehiculesStatuts.countArchive}</h3>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="card" title="Affiche le nombre de véhicules dans différentes wilayas">
                        <div className="column">
                            <div className="veh-wilaya">
                                <h3>Nombre de véhicules par wiayas</h3>
                                <div className="col scroll-short font-size-small">
                                    {vehiculesWilayas.map((item, index) => (
                                        <div className="row" key={index}>
                                            <h3 className="">{item.nomLatin}</h3>
                                            <h2 className="primary number">{item.numberVehiclesWilaya}</h2>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="card" title="Nombre de véhicules affectés et non affectés">
                        <div className="column column-center">
                            <h3>Affectations de véhicules</h3>
                            <div className="row font-size-small">
                                <div className="col">
                                    <h2 className="success number-medium">{vehiculesAffectations.countAssigned}</h2>
                                    <p className="text-muted">Affectés</p>
                                </div>
                                <div className="col">
                                    <h2 className="warning number-medium">{vehiculesAffectations.countUnassigned}</h2>
                                    <p className="text-muted">Non affectés</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="grid grid--2-cols">
                    <div className="card">
                        <div className="column">
                            <div className="cntr-tech">
                                <h3>Contrôles techniques</h3>
                                <div className="col">
                                    <div className="row linkDiv" onClick={() => openNewTab('controletechniques', { statut: 'actif' })}>
                                        <h3 className="">Actives</h3>
                                        <h2 className="success number-medium">{controleTechnique.countActif}</h2>
                                    </div>
                                    <div className="row linkDiv" onClick={() => openNewTab('controletechniques', { statut: 'bientot expiré' })}>
                                        <h3 className="">Bientôt expirées</h3>
                                        <h2 className="warning number-medium">{controleTechnique.countBientotExpire}</h2>
                                    </div>
                                    <div className="row linkDiv" onClick={() => openNewTab('controletechniques', { statut: 'expiré' })}>
                                        <h3 className="">Expirées</h3>
                                        <h2 className="danger number-medium">{controleTechnique.countExpire}</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="card">
                        <div className="column">
                            <div className="">
                                <h3>Assurances</h3>
                                <div className="row row-center linkDiv" onClick={() => openNewTab('assurances', { statut: 'actif' })}>
                                    <h3 className="">Actives</h3>
                                    <h2 className="success number-medium">{assurances.countActif}</h2>
                                </div>
                                <div className="row row-center linkDiv" onClick={() => openNewTab('assurances', { statut: 'bientot expiré' })}>
                                    <h3 className="">Bientôt expirées</h3>
                                    <h2 className="warning number-medium">{assurances.countBientotExpire}</h2>
                                </div>
                                <div className="row row-center linkDiv" onClick={() => openNewTab('assurances', { statut: 'expiré' })}>
                                    <h3 className="">Expirées</h3>
                                    <h2 className="danger number-medium">{assurances.countExpire}</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="grid grid--2-cols">
                    <div className="card">
                        <div className="column-double">
                            <div className="etat-vhc">
                                <h3 title="Graphique affichant la dernière entrée du compteur pour tous les véhicules actifs">
                                    Derniers relevés de compteurs
                                </h3>
                                <div className="stat">
                                    <Bubble data={dataReleve} options={chartOptionsReleve} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card" title="Affiche le nombre de véhicules dans différents services">
                        <div className="column-double-circle">
                            <div className="etat-vhc">
                                <h3>Véhicules par services</h3>
                                <div className="stat">
                                    <Pie data={dataVehiculesServices} options={chartOptionsVehiculesServices} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='grid grid--3-cols'>
                    <div className="card" title="Nombre d'affectations au cours des 7 derniers jours et 7 jours prochaines">
                        <div className="column">
                            <div className="srvc">
                                <h3>Affectations par statuts</h3>
                                <div className="col">
                                    <div className="row">
                                        <h3 className="">En cours</h3>
                                        <h2 className="success number-medium">{vehiculesAffectationsStatuts.countEnCours}</h2>
                                    </div>
                                    <div className="row">
                                        <h3 className="">Termnés</h3>
                                        <h2 className="danger number-medium">{vehiculesAffectationsStatuts.countTermine}</h2>
                                    </div>
                                    <div className="row">
                                        <h3 className="">Planifiés</h3>
                                        <h2 className="warning number-medium">{vehiculesAffectationsStatuts.countPlanifie}</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card">
                        <div className="column">
                            <div className="prblm">
                                <h3>Stauts des problèmes</h3>
                                <div className="col">
                                    <div className="row">
                                        <h3>Ouverts</h3>
                                        <h2 className="number number-medium primary">{vehiculesProblemes.countOuvert}</h2>
                                    </div>

                                    <div className="row">
                                        <h3>En retards</h3>
                                        <h2 className="number number-medium primary">{vehiculesProblemes.countRetard}</h2>
                                    </div>

                                    <div className="row">
                                        <h3>Reconnus</h3>
                                        <h2 className="number number-medium primary">{vehiculesProblemes.countReconnu}</h2>
                                    </div>

                                    <div className="row">
                                        <h3>Résolus</h3>
                                        <h2 className="number number-medium primary">{vehiculesProblemes.countResolu}</h2>
                                    </div>

                                    <div className="row">
                                        <h3>Fermés</h3>
                                        <h2 className="number number-medium primary">{vehiculesProblemes.countFerme}</h2>
                                    </div>

                                    <div className="row">
                                        <h3>En attentes</h3>
                                        <h2 className="number number-medium primary">{vehiculesProblemes.countEnAttent}</h2>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card">
                        <div className="column">
                            <div className="prblm">
                                <h3>Problèmes par priorité</h3>
                                <div className="col">
                                    <div className="row">
                                        <h3>Aucune</h3>
                                        <h2 className="number number-medium primary">{vehiculesProblemesPriorite.countAucune}</h2>
                                    </div>

                                    <div className="row">
                                        <h3>Faible</h3>
                                        <h2 className="number number-medium primary">{vehiculesProblemesPriorite.countFaible}</h2>
                                    </div>
                                    <div className="row">
                                        <h3>Moyen</h3>
                                        <h2 className="number number-medium primary">{vehiculesProblemesPriorite.countMoyen}</h2>
                                    </div>
                                    <div className="row">
                                        <h3>Élevé</h3>
                                        <h2 className="number number-medium primary">{vehiculesProblemesPriorite.countEleve}</h2>
                                    </div>
                                    <div className="row">
                                        <h3>Critique</h3>
                                        <h2 className="number number-medium primary">{vehiculesProblemesPriorite.countCritique}</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="grid grid--2-cols">
                    <div className="card" title="Affiche le % d'éléments d'inspection ayant échoué pour la semaine en cours, ainsi que le changement depuis la semaine dernière.">
                        <div className="column-double">
                            <div className="inspect">
                                <h3>Taux d'échec des éléments d'inspection</h3>
                                <div className="row">
                                    <div className="col">
                                        <h2 className="primary">{tauxEchecInspection.tauxEchecSemaineCourante !== undefined ? tauxEchecInspection.tauxEchecSemaineCourante.toFixed(2) : ''}%</h2>
                                        <h5 className="text-muted">Cette semain</h5>
                                    </div>
                                    <div className="col">
                                        <h2 className={(tauxEchecInspection.changement !== undefined && tauxEchecInspection.changement <= 0) ? 'success' : 'danger'}>
                                            {/* <div>
                                                <span className="material-symbols-outlined">{tauxEchecInspection.changement <= 0 ? 'arrow_drop_down' : 'arrow_drop_up'}</span>
                                            </div> */}
                                            <div>
                                                <span className="material-symbols-outlined">{tauxEchecInspection.changement <= 0 ? 'arrow_drop_down' : 'arrow_drop_up'}</span>
                                                {tauxEchecInspection.changement !== undefined ? tauxEchecInspection.changement.toFixed(2) : ''}%
                                            </div>
                                        </h2>
                                        <h5 className="text-muted">Changement par rapport à la semaine dernière</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card">
                        <div className="column">
                            <div className="srvc">
                                <h3 title="Affiche le nombre de véhicules avec ou sans soumission d'inspection au cours des 30 derniers jours.">
                                    Résumé des inspections
                                </h3>
                                <Pie data={dataResume} options={chartOptionsResume} />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="grid grid--3-cols">
                    <div className="card" title="Affiche le nombre d'employés dans différents statuts">
                        <div className="column">
                            <div className="">
                                <h3>Statuts employés</h3>
                                <div className="row row-center">
                                    <div className="row row-baseline">
                                        <svg height="11.87" width="15">
                                            <circle cx="6" cy="6" r="6" fill="green" />
                                        </svg>
                                        <h3 className="">Actif</h3>
                                    </div>
                                    <h3 className="number number-actif">{employesStatuts.countActif}</h3>
                                </div>
                                <div className="row row-center">
                                    <div className="row row-baseline">
                                        <svg height="11.87" width="15">
                                            <circle cx="6" cy="6" r="6" fill="orange" />
                                        </svg>
                                        <h3 className="">Inactif</h3>
                                    </div>
                                    <h3 className="number number-warning">{employesStatuts.countInactif}</h3>
                                </div>
                                <div className="row row-center">
                                    <div className="row row-baseline">
                                        <svg height="11.87" width="15">
                                            <circle cx="6" cy="6" r="6" fill="grey" />
                                        </svg>
                                        <h3 className="">Archivé</h3>
                                    </div>
                                    <h3 className="number number-dark">{employesStatuts.countArchive}</h3>
                                </div>
                                <div className="row row-center">
                                    <div className="row row-baseline">
                                        <svg height="11.87" width="15">
                                            <circle cx="6" cy="6" r="6" fill="#7380ec" />
                                        </svg>
                                        <h3 className="">Suspendu</h3>
                                    </div>
                                    <h3 className="number number-primary">{employesStatuts.countSuspendu}</h3>
                                </div>
                                <div className="row row-center">
                                    <div className="row row-baseline">
                                        <svg height="11.87" width="15">
                                            <circle cx="6" cy="6" r="6" fill="red" />
                                        </svg>
                                        <h3 className="">Licencié</h3>
                                    </div>
                                    <h3 className="number number-danger">{employesStatuts.countLicencie}</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card">
                        <div className="column">
                            <div className="srvc">
                                <h3>Permis de conduire par statuts</h3>
                                <div className="col">
                                    <div className="row">
                                        <h3 className="">Actifs</h3>
                                        <h2 className="success">{permisConduireStatuts.permisActives}</h2>
                                    </div>
                                    <div className="row">
                                        <h3 className="">Bientôt expirés</h3>
                                        <h2 className="warning">{permisConduireStatuts.permisBientotExpires}</h2>
                                    </div>
                                    <div className="row">
                                        <h3 className="">Expirés</h3>
                                        <h2 className="danger">{permisConduireStatuts.permisExpires}</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card">
                        <div className="column-double-circle">
                            <div className="etat-vhc">
                                <h3>
                                    Répartition des chauffeurs
                                </h3>
                                <div className="stat">
                                    <Doughnut data={dataChauffeursCategorie} options={chartOptionsChauffeursCategorie} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="grid grid--2-cols">
                    <div className="card">
                        <div className="column-double">
                            <div className="etat-vhc">
                                <h3>
                                    Les coûts de service de l'année courante
                                </h3>
                                <div className="stat">
                                    <Bar data={dataCoutsService} options={chartOptionsCoutsService} />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="card">
                        <div className="column">
                            <div className="rappel-entretien">
                                <h3>Rappels de service</h3>
                                <div className="col">
                                    <div className="row">
                                        <h3 className="">Bientôt</h3>
                                        <h2 className="success number-medium">{rappelsService.countBientot}</h2>
                                    </div>
                                    <div className="row">
                                        <h3 className="">À venir</h3>
                                        <h2 className="warning number-medium">{rappelsService.countAvenir}</h2>
                                    </div>
                                    <div className="row">
                                        <h3 className="">En retard</h3>
                                        <h2 className="danger number-medium">{rappelsService.countRetard}</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="grid grid--3-cols">
                    <div className="card" title="Affiche le nombre d'ordres de travail dans chaque statut (par exemple ouvert, en attente)">
                        <div className="column">
                            <div className="srvc">
                                <h3>Stauts ordres de travail</h3>
                                <div className="col">
                                    <div className="row">
                                        <h3 className="">Ouvert</h3>
                                        <h2 className="primary number-medium">{nombreOrdreTravailStatuts.countOuvert}</h2>
                                    </div>
                                    <div className="row">
                                        <h3 className="">En attente</h3>
                                        <h2 className="primary number-medium">{nombreOrdreTravailStatuts.countEenAttente}</h2>
                                    </div>
                                    <div className="row">
                                        <h3 className="">attribué</h3>
                                        <h2 className="primary number-medium">{nombreOrdreTravailStatuts.countAttribue}</h2>
                                    </div>
                                    <div className="row">
                                        <h3 className="">En cours</h3>
                                        <h2 className="primary number-medium">{nombreOrdreTravailStatuts.countEnCours}</h2>
                                    </div>
                                    <div className="row">
                                        <h3 className="">En attente d'approbation</h3>
                                        <h2 className="primary number-medium">{nombreOrdreTravailStatuts.countApprobation}</h2>
                                    </div>
                                    <div className="row">
                                        <h3 className="">Complété</h3>
                                        <h2 className="primary number-medium">{nombreOrdreTravailStatuts.countComplete}</h2>
                                    </div>
                                    <div className="row">
                                        <h3 className="">En retard</h3>
                                        <h2 className="primary number-medium">{nombreOrdreTravailStatuts.countEnRetard}</h2>
                                    </div>
                                    <div className="row">
                                        <h3 className="">Planifié</h3>
                                        <h2 className="primary number-medium">{nombreOrdreTravailStatuts.countPlanifie}</h2>
                                    </div>
                                    <div className="row">
                                        <h3 className="">Annulé</h3>
                                        <h2 className="primary number-medium">{nombreOrdreTravailStatuts.countAnnule}</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="card">
                        <div className="column">
                            <div className="srvc">
                                <h3>Nombre de dépénses par catégorie</h3>
                                <div className="col scroll">
                                    {nombreDepenseCatagorie.map((item, index) => (
                                        <div className="row" key={index}>
                                            <h3 className="">{item.libelleTypeDepense}</h3>
                                            <h2 className="primary number-medium">{item.depenseCount}</h2>
                                        </div>))}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="card">
                        <div className="column-double">
                            <div className="etat-vhc">
                                <h3>
                                    Les coûts des dépenses de l'année courante.
                                </h3>
                                <div className="stat">
                                    <Bar data={dataCoutsDepenses} options={chartOptionsCoutsDepenses} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section >
    );
}

export default Dashboard;
