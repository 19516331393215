import React, { useEffect, useState, useRef, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { CustomToast } from '../../components/Toast/CustomToast';
import { axiosInstance, Fournisseur } from '../../services/api';
import { AuthContext } from '../../contexts/AuthContext';

const AjouterFournisseur = () => {
    const { authData } = useContext(AuthContext);
    const navigate = useNavigate();

    const { handleChange, values, handleSubmit, touched, errors } = useFormik({
        initialValues: {
            raisonSociale: '',
            adresse: '',
            nrc: '',
            numTele1: '',
            numTele2: '',
            email: '',
            siteWeb: '',
            estFournisseurCarburant: false,
            estFournisseurService: false,
            estFournisseurPieceRechange: false,
            estFournisseurVehicule: false,
            statut: 'actif',
            remarques: ''
        },
        validationSchema: Yup.object({
            raisonSociale: Yup.string()
                .required("Champ obligatoire"),
            email: Yup.string()
                .email("Le champ doit contenir un e-mail valide")
                .max(255, "Le champ doit contenir au maximum 255 caractères"),
        }),
        onSubmit: async (values) => {
            try {
                const postData = {
                    raisonSociale: values.raisonSociale,
                    adresse: values.adresse,
                    nrc: values.nrc,
                    numTele1: values.numTele1,
                    numTele2: values.numTele2,
                    email: values.email,
                    siteWeb: values.siteWeb,
                    estFournisseurCarburant: values.estFournisseurCarburant,
                    estFournisseurService: values.estFournisseurService,
                    estFournisseurPieceRechange: values.estFournisseurPieceRechange,
                    estFournisseurVehicule: values.estFournisseurVehicule,
                    statut: values.statut,
                    remarques: values.remarques,
                    idUtilisateur: authData.idUser,
                };

                const response = await axiosInstance.post(Fournisseur, postData);

                CustomToast("Fournisseur a été créé avec succès", 'success');
                navigate('/listfournisseurs', { replace: true });
            } catch (error) {
                // Handle error
                console.log(error);
                if (!error?.response) {
                    CustomToast("Aucune réponse du serveur", 'error');
                } else if (error.response?.status === 401) {
                    CustomToast("Non autorisé", 'error');
                } else {
                    CustomToast("Demande échoué", 'error');
                }
            }
        }
    });
    return (
        <section className="donnee-section">
            <div className="container">
                <form onSubmit={handleSubmit} className='fournisseur-form'>
                    <div className="">
                        <div className="grid grid--2-cols">
                            <div className="form-sec">
                                <div className="retour fournisseur-form-back" id="retour-login">
                                    <Link to="/listfournisseurs" style={{ textDecoration: "none" }}>
                                        <div className="arrow-back">
                                            <span className="material-symbols-outlined" id="arrow">arrow_back</span>
                                        </div>
                                    </Link>
                                    <button type="submit" className="submit-btn">Enregistrer</button>
                                </div>
                                <div className="form-details">
                                    <div className="detail-form" id="detail-proprietaire">
                                        <h3 className="heading-primary">Détails</h3>
                                        <div className="col">
                                            <div className="grp">
                                                <div className="gp">
                                                    <label >Raison sociale<span className='requiredField'>*</span></label>
                                                    <input type="text"
                                                        id="raisonSociale"
                                                        name="raisonSociale"
                                                        style={(touched.raisonSociale && errors.raisonSociale) ? { borderColor: "red" } : null}
                                                        placeholder=""
                                                        defaultValue={values.raisonSociale}
                                                        onChange={handleChange}
                                                    />
                                                    {touched.raisonSociale && errors.raisonSociale ? (
                                                        <div className="invalid-feedback">{errors.raisonSociale}</div>
                                                    ) : null}
                                                </div>
                                                <div className="gp">
                                                    <label>N° registre du commerce</label>
                                                    <input type="text"
                                                        id="nrc"
                                                        name="nrc"
                                                        placeholder=""
                                                        defaultValue={values.nrc}
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                                <div className="gp">
                                                    <label >Statut<span className='requiredField'>*</span></label>
                                                    <select
                                                        id="statut"
                                                        name="statut"
                                                        value={values.statut}
                                                        onChange={handleChange}
                                                    >
                                                        <option value="actif" defaultValue={true}>Actif</option>
                                                        <option value="inactif">Inactif</option>
                                                        <option value="archivé">Archivé</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="grp">
                                                <div className="gp">
                                                    <label>N° téléphone 1</label>
                                                    <input type="text"
                                                        id="numTele1"
                                                        name="numTele1"
                                                        placeholder=""
                                                        defaultValue={values.numTele1}
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                                <div className="gp">
                                                    <label>N° téléphone 2</label>
                                                    <input type="text"
                                                        id="numTele2"
                                                        name="numTele2"
                                                        placeholder=""
                                                        defaultValue={values.numTele2}
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                                <div className="gp">
                                                    <label>Email</label>
                                                    <input type="email"
                                                        id="email"
                                                        name="email"
                                                        placeholder=""
                                                        defaultValue={values.email}
                                                        style={(touched.email && errors.email) ? { borderColor: "red" } : null}
                                                        onChange={handleChange}
                                                    />
                                                    {touched.email && errors.email ? (
                                                        <div className="invalid-feedback">{errors.email}</div>
                                                    ) : null}
                                                </div>
                                            </div>
                                            <div className="grp">
                                                <div className="gp">
                                                    <label>Site Internet</label>
                                                    <input type="text"
                                                        id="siteWeb"
                                                        name="siteWeb"
                                                        placeholder=""
                                                        defaultValue={values.siteWeb}
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                                <div className="gp">
                                                    <label>Adresse</label>
                                                    <input type="text"
                                                        id="adresse"
                                                        name="adresse"
                                                        placeholder=""
                                                        defaultValue={values.adresse}
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                            </div>
                                            <div className="grp">
                                                <div className="gp">
                                                    <label>Remarques</label>
                                                    <textarea type="text"
                                                        id="remarques"
                                                        name="remarques"
                                                        placeholder=""
                                                        defaultValue={values.remarques}
                                                        onChange={handleChange}
                                                        rows={4}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="cycle-form" id="classification-proprietaire">
                                        <div className="detail-form">
                                            <h3 className="heading-primary">Clasifications</h3>
                                            <div className="col">
                                                <div className="grp-check" title="La classification des carburants permet au fournisseur d'être répertorié sur les entrées de carburant">
                                                    <input
                                                        type="checkbox"
                                                        className="box"
                                                        id="estFournisseurCarburant"
                                                        name="estFournisseurCarburant"
                                                        checked={values.estFournisseurCarburant}
                                                        onChange={handleChange}
                                                    />
                                                    <label > Carburant</label>
                                                </div>
                                                <div className="grp-check" title="La classification des services permet au fournisseur d'être répertorié dans les entrées de service et les ordres de travail">
                                                    <input
                                                        type="checkbox"
                                                        className="box"
                                                        id="estFournisseurService"
                                                        name="estFournisseurService"
                                                        checked={values.estFournisseurService}
                                                        onChange={handleChange}
                                                    />
                                                    <label > Service</label>
                                                </div>
                                                <div className="grp-check" title="La classification des services permet au fournisseur d'être répertorié dans les entrées de service et les ordres de travail">
                                                    <input
                                                        type="checkbox"
                                                        className="box"
                                                        id="estFournisseurPieceRechange"
                                                        name="estFournisseurPieceRechange"
                                                        checked={values.estFournisseurPieceRechange}
                                                        onChange={handleChange}
                                                    />
                                                    <label > Pièces de rechange</label>
                                                </div>
                                                <div className="grp-check" title="La classification des véhicules permet au fournisseur d'être répertorié sur les acquisitions de véhicules">
                                                    <input
                                                        type="checkbox"
                                                        className="box"
                                                        id="estFournisseurVehicule"
                                                        name="estFournisseurVehicule"
                                                        checked={values.estFournisseurVehicule}
                                                        onChange={handleChange}
                                                    />
                                                    <label > Véhicule</label>
                                                </div>

                                            </div>
                                        </div>

                                    </div>
                                    <div className="submit-container">
                                        <button type="submit" className="submit-btn">Enregistrer</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </section>
    );
}

export default AjouterFournisseur;
