import React, { useEffect, useState, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import moment from 'moment/moment';
import { CustomToast } from '../../../../components/Toast/CustomToast';
import ConfirmationDialog from '../../../../components/Confirmation/ConfirmationDialog';
import { axiosInstance, TypeRenouvellementEmployee } from '../../../../services/api';
import { AuthContext } from '../../../../contexts/AuthContext';
import useDebounce from '../../../../hooks/useDebounce';

const ListRappelleEmp = () => {
    const { authData } = useContext(AuthContext);
    const navigate = useNavigate();

    const [types, setTypes] = useState([]);
    const [filtredTypes, setFiltredTypes] = useState([]);
    const [pageNumber, setPageNumber] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalItems, setTotalItems] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [loading, setLoading] = useState(true);
    const [showAddModal, setShowAddModal] = useState(false);
    const [showUpdateModal, setShowUpdateModal] = useState(false);
    const [idTypeDelete, setIdTypeDelete] = useState('');
    const [idTypeUpdate, setIdTypeUpdate] = useState('');

    const [search, setSearch] = useState('');
    const debouncedSearchText = useDebounce(search, 300);

    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);

    //Table management
    const [sortBy, setSortBy] = useState(''); // Column name to sort by
    const [sortOrder, setSortOrder] = useState('asc'); // 'asc' or 'desc'

    const handleSort = (column) => {
        setSortBy(column);
        setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');

        let sortedTable = [...filtredTypes].sort((a, b) => {
            const aValue = a[sortBy] || '';
            const bValue = b[sortBy] || '';

            if (sortOrder === 'asc') {
                return aValue.localeCompare(bValue);
            } else {
                return bValue.localeCompare(aValue);
            }
        });

        setFiltredTypes(sortedTable);
    };

    const handlePageChange = (page) => {
        setPageNumber(page);
        //consolelog("setPageNumber="+pageNumber)
        const startIndex = (page - 1) * pageSize;
        const endIndex = startIndex + pageSize;
        const paginatedData = types.slice(startIndex, endIndex);

        setFiltredTypes(paginatedData);
    };

    const [openAboutList, setOpenAboutList] = useState(null);

    const toggleAboutList = (idMarque) => {
        // console.log(idMarque)
        setOpenAboutList(openAboutList === idMarque ? null : idMarque);
    };

    const handlePerRowsChange = (newPerPage) => {
        setPageSize(newPerPage);
        //console.log("setPageSize="+pageSize);
    };

    const showHideAddModal = (val) => {
        setShowAddModal(val);
        setIdTypeUpdate('');
        formik1.resetForm();
    };

    const showHideUpdateModal = (val, idType, libelleType, descriptionType) => {
        setShowUpdateModal(val);
        setIdTypeUpdate(idType);
        formik2.resetForm();
        if (val) {
            formik2.values.libelleTypeUpdate = libelleType;
            formik2.values.descriptionTypeUpdate = descriptionType;
        }
    };

    //Supprimer PI
    const deleteCategorie = async (idType) => {
        try {
            const response = await axiosInstance.delete(`${TypeRenouvellementEmployee}/${idType}`);
            getCategorie();
            CustomToast("Le type de renouvellement d'employé a été supprimé avec succès", 'success');
        }
        catch (error) {
            // Handle error
            if (!error?.response) {
                CustomToast("Aucune réponse du serveur", 'error');
            } else if (error.response?.status === 401) {
                CustomToast("Non autorisé", 'error');
            } else {
                CustomToast("Demande échoué", 'error');
            }
            console.log(error);
        }
    }

    const handleConfirmDelete = (idType) => {
        // Show the modal when the confirm delete button is clicked
        //getCategorie(idType);
        setIdTypeDelete(idType);
        setConfirmDialogOpen(true);
    };

    const handleDelete = async () => {
        try {
            const response = await axiosInstance.delete(`${TypeRenouvellementEmployee}/${idTypeDelete}`);
            setIdTypeDelete('');
            CustomToast("Le type de renouvellement d'employé a été supprimé avec succès", 'success');
            getCategorie();
            setConfirmDialogOpen(false);
            //navigate('/listrappellevehicule', { replace: true });
        }
        catch (error) {
            // Handle error
            if (!error?.response) {
                CustomToast("Aucune réponse du serveur", 'error');
            } else if (error.response?.status === 401) {
                CustomToast("Non autorisé", 'error');
            } else {
                CustomToast("Demande échoué", 'error');
            }
            console.log(error);
        }
    }

    const handleCancelDelete = () => {
        setConfirmDialogOpen(false);
    };

    //Load data
    const getCategorie = async () => {
        try {
            const response = await axiosInstance.get(`${TypeRenouvellementEmployee}`);
            const resp = await response.data;
            setTypes(resp);
            let filteredResult = resp;

            if (search !== '') {
                filteredResult = resp.filter((item) =>
                    Object.values(item).some(
                        (value) =>
                            value &&
                            value.toString().toLowerCase().includes(search.toLowerCase())
                    )
                );
            }

            // Pagination
            const startIndex = (pageNumber - 1) * pageSize;
            const endIndex = startIndex + pageSize;
            const paginatedResult = filteredResult.slice(startIndex, endIndex);

            setFiltredTypes(paginatedResult);
            setPageNumber(1);
            setTotalItems(filteredResult.length);
            setTotalPages(Math.ceil(filteredResult.length / pageSize));
            setLoading(false);
        }
        catch (err) {
            setLoading(false);
            console.log(err);
        }
    }

    const handleSearch = (searchTerm) => {

        const result = types?.filter((item) =>
            Object.values(item).some(
                (value) =>
                    value &&
                    value.toString().toLowerCase().includes(searchTerm.toLowerCase())
            )
        );
        //console.log(searchTerm,result)
        // Pagination
        const startIndex = (pageNumber - 1) * pageSize;
        const endIndex = startIndex + pageSize;
        const paginatedResult = result.slice(startIndex, endIndex);
        setFiltredTypes(paginatedResult);
        setPageNumber(1);
        setTotalItems(result.length);
        setTotalPages(Math.ceil(result.length / pageSize));
        setSearch(searchTerm);
    };

    const formik1 = useFormik({
        initialValues: {
            libelleType: '',
            descriptionType: '',
        },
        validationSchema: Yup.object({
            libelleType: Yup.string()
                .required('Champ obligatoire')
        }),
        onSubmit: async (values) => {
            try {
                const postData = {
                    libelleType: values.libelleType,
                    descriptionType: values.descriptionType,
                    idUtilisateurAction: authData.idUser,
                };

                const response = await axiosInstance.post(TypeRenouvellementEmployee, postData);
                CustomToast("Le type de renouvellement d'employé a été créé avec succès", 'success');
                getCategorie();
                setShowAddModal(false);
                //navigate('/listrappellevehicule', { replace: true });
            } catch (error) {
                // Handle error
                if (!error?.response) {
                    CustomToast("Aucune réponse du serveur", 'error');
                } else if (error.response?.status === 400 && error.response?.data === "Type existe") {
                    CustomToast("Type de renouvellement existe", 'error');
                } else if (error.response?.status === 401) {
                    CustomToast("Non autorisé", 'error');
                } else {
                    CustomToast("Demande échoué", 'error');
                }
                console.log(error);
            }
        }
    });

    const formik2 = useFormik({
        initialValues: {
            libelleTypeUpdate: '',
            descriptionTypeUpdate: '',
        },
        validationSchema: Yup.object({
            libelleTypeUpdate: Yup.string()
                .required('Champ obligatoire')
        }),
        onSubmit: async (values) => {
            try {
                const postData = {
                    idType: idTypeUpdate,
                    libelleType: values.libelleTypeUpdate,
                    descriptionType: values.descriptionTypeUpdate,
                    idUtilisateurAction: authData.idUser,
                };

                //console.log(postData);               
                const response = await axiosInstance.put(TypeRenouvellementEmployee, postData);
                CustomToast("Le type de renouvellement d'employé a été modifié avec succès", 'success');
                showHideUpdateModal(false, '', '');
                getCategorie();

                //navigate('/listrappellevehicule', { replace: true });
            } catch (error) {
                // Handle error
                if (!error?.response) {
                    CustomToast("Aucune réponse du serveur", 'error');
                } else if (error.response?.status === 400 && error.response?.data === "Type existe") {
                    CustomToast("Type de renouvellement existe", 'error');
                } else if (error.response?.status === 401) {
                    CustomToast("Non autorisé", 'error');
                } else {
                    CustomToast("Demande échoué", 'error');
                }
                console.log(error);
            }
        }
    });

    useEffect(() => {
        //Load Data
        getCategorie();
        return () => {
            // Perform any necessary cleanup (e.g., cancel pending requests)
        };
    }, [authData, pageSize]);

    useEffect(() => {
        //Load Data
        handleSearch(debouncedSearchText);
        return () => {
            // Perform any necessary cleanup (e.g., cancel pending requests)
        };
    }, [debouncedSearchText]);

    // useEffect(() => {
    //     const result = types?.filter((item) =>
    //         Object.values(item).some(
    //             (value) =>
    //                 value &&
    //                 value.toString().toLowerCase().includes(search.toLowerCase())
    //         )
    //     );
    //     setFiltredTypes(result);
    // }, [search, types])

    return (
        <section className="donnee-section" onClick={() => { if (openAboutList !== null) { setOpenAboutList(null) } }}>
            <div className="container diver-section">
                <div className="typeemploye-sec wrap-all">
                    <div className="row row-center">
                        <Link to="/divers" className="retour cursurer" id="vehicule-list-assrc-retour">
                            <div className="arrow-back">
                                <span className="material-symbols-outlined" id="arrow">arrow_back</span>
                            </div>
                        </Link>
                        <Link href="#" className="color-primary-2 decoration-none" onClick={() => showHideAddModal(true)}>
                            <div className="add-new" id="add-vehc">
                                <span className="material-symbols-outlined">add</span>
                                Ajouter un type de renouvellement d'employé
                            </div>
                        </Link>
                    </div>

                    <div className="group-elements">
                        <div className="voir-elements">
                            <p></p>
                            <select
                                name="pageSize"
                                id="pageSize"
                                onChange={(e) => { handlePerRowsChange(e.target.value || 10); setPageNumber(1); }}
                            >
                                <option value="10" defaultValue={true}>10</option>
                                <option value="15">15</option>
                                <option value="20">20</option>
                            </select>
                        </div>
                        <div className="search-element">
                            <p>Rechercher</p>
                            <input
                                type="text"
                                value={search}
                                onChange={(e) => handleSearch(e.target.value)}
                            />
                        </div>
                    </div>
                    {filtredTypes?.length > 0 && <div className="table-wrap">
                            <table>
                                <thead>
                                    <tr>
                                        <th>Nom</th>
                                        <th>Description</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {filtredTypes?.map((row) => (
                                        <tr key={row.idType}>
                                            <td>{row.libelleType}</td>
                                            <td>{row.descriptionType}</td>
                                            <td className="txt-center relative">
                                            <span className="material-symbols-outlined about-btn" onClick={() => toggleAboutList(row.idType)}>
                                                more_horiz
                                            </span>
                                            {openAboutList === row.idType && (
                                                <div className="about-list">
                                                    <ul>
                                                        <li onClick={() => showHideUpdateModal(true, row.idType, row.libelleType,row.descriptionType)}>
                                                            <span className="material-symbols-outlined">edit</span>
                                                            <p>Modifier</p>
                                                        </li>
                                                        <li onClick={() => handleConfirmDelete(row.idType)}>
                                                            <span className="material-symbols-outlined">delete</span>
                                                            <p>Supprimer</p>
                                                        </li>
                                                    </ul>
                                                </div>
                                            )}
                                        </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>}
                        <div className="pagination">
                            <Link to="#" onClick={() => handlePageChange(pageNumber - 1)}
                                className={(pageNumber === 1) ? 'pgn disabled' : 'pgn'}
                                disabled={(pageNumber === 1) ? true : false}
                            >&laquo;</Link>
                            {Array.from({ length: totalPages }, (_, i) => (
                                <Link
                                    key={i + 1}
                                    to="#"
                                    className={i + 1 === pageNumber ? 'pgn active' : 'pgn'}
                                    onClick={() => handlePageChange(i + 1)}
                                >
                                    {i + 1}
                                </Link>
                            ))}
                            <Link to="#" onClick={() => handlePageChange(pageNumber + 1)}
                                className={((pageNumber + 1) > totalPages) ? 'pgn disabled' : 'pgn'}
                                disabled={((pageNumber + 1) > totalPages) ? true : false}
                            >&raquo;</Link>
                        </div>


                    {showAddModal && <div className="popcard-add">
                        <div className="popcard-add_inside">
                            <Link className="popcard-add_inside-close" to="#" onClick={() => showHideAddModal(false)}>&#10006;</Link>
                            <h2 className="popcard-add_inside-title"></h2>
                            <div className="popcard-add_inside-form">
                                <form onSubmit={formik1.handleSubmit}>
                                    <div className="wrap-info">
                                        <div className="wrap-one">
                                            <label>Nom<span className='requiredField'>*</span></label>
                                            <input type="text"
                                                id="libelleType"
                                                name="libelleType"
                                                style={(formik1.touched.libelleType && formik1.errors.libelleType) ? { borderColor: "red" } : null}
                                                placeholder=""
                                                defaultValue={formik1.values.libelleType}
                                                onChange={formik1.handleChange}
                                            />
                                            {formik1.touched.libelleType && formik1.errors.libelleType ? (
                                                <div className="invalid-feedback">{formik1.errors.libelleType}</div>
                                            ) : null}
                                        </div>
                                        <div className="wrap-one">
                                            <label>Description</label>
                                            <textarea type="text"
                                                id="descriptionType"
                                                name="descriptionType"
                                                placeholder=""
                                                defaultValue={formik1.values.descriptionType}
                                                onChange={formik1.handleChange}
                                                rows={3}
                                            />
                                        </div>
                                    </div>

                                    <div className="popcard-operation-pickup-details_inside-zone">
                                        <div className="buttons">
                                            <input type="submit" value="Enregistrer" className="submit-btn" />
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>}

                    {showUpdateModal && <div className="popcard-add">
                        <div className="popcard-add_inside">
                            <Link className="popcard-add_inside-close" to="#" onClick={() => showHideUpdateModal(false, '', '')}>&#10006;</Link>
                            <h2 className="popcard-add_inside-title"></h2>
                            <div className="popcard-add_inside-form">
                                <form onSubmit={formik2.handleSubmit}>
                                    <div className="wrap-info">
                                        <div className="wrap-one">
                                            <label>Nom<span className='requiredField'>*</span></label>
                                            <input type="text"
                                                id="libelleTypeUpdate"
                                                name="libelleTypeUpdate"
                                                style={(formik2.touched.libelleTypeUpdate && formik2.errors.libelleTypeUpdate) ? { borderColor: "red" } : null}
                                                placeholder=""
                                                defaultValue={formik2.values.libelleTypeUpdate}
                                                onChange={formik2.handleChange}
                                            />
                                            {formik2.touched.libelleTypeUpdate && formik2.errors.libelleTypeUpdate ? (
                                                <div className="invalid-feedback">{formik2.errors.libelleTypeUpdate}</div>
                                            ) : null}
                                        </div>
                                        <div className="wrap-one">
                                            <label>Description</label>
                                            <textarea type="text"
                                                id="descriptionTypeUpdate"
                                                name="descriptionTypeUpdate"
                                                placeholder=""
                                                defaultValue={formik2.values.descriptionTypeUpdate}
                                                onChange={formik2.handleChange}
                                                rows={3}
                                            />
                                        </div>
                                    </div>

                                    <div className="popcard-operation-pickup-details_inside-zone">
                                        <div className="buttons">
                                            <input type="submit" value="Enregistrer" className="submit-btn" />
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>}

                    <ConfirmationDialog
                        open={confirmDialogOpen}
                        onClose={handleCancelDelete}
                        onConfirm={handleDelete}
                        titleMessage="Confirmer la suppression du type de renouvellement d'employé"
                        bodyMessage="Voulez-vous vraiment supprimer ce type de renouvellement d'employé? Toutes les données seront définitivement perdues."
                        btnCancelText="Annuler"
                        btnConfirmText="Supprimer le type de renouvellement d'employé"
                    />

                </div>
            </div>
        </section>
    );
}

export default ListRappelleEmp;
