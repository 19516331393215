import React, { createContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [authData, setAuthData] = useState(null);
    const navigate = useNavigate();
    // Load authentication data from localStorage on component mount
    useEffect(() => {
        const storedAuthData = localStorage.getItem('authData');
        if (storedAuthData) {
            setAuthData(JSON.parse(storedAuthData));
        }
        // else{
        //     navigate('/login', { replace: true });
        // }
         //console.log("useEffect with out navigate");
        // console.log(authData);
    }, []);

    useEffect(() => {
        const storedAuthData = localStorage.getItem('authData');
        if (storedAuthData) {
            setAuthData(JSON.parse(storedAuthData));
        }
        // else{
        //     navigate('/login', { replace: true });
        // }
         //console.log("useEffect navigate");
        // console.log(authData);
    }, [navigate]);
    // Save authentication data to localStorage whenever it changes
    useEffect(() => {
        if (authData) {
            localStorage.setItem('authData', JSON.stringify(authData));
        }
        //  else{
        //     navigate('/login', { replace: true });
        // }
         //console.log("useEffect authData");
        // console.log(authData);
    }, [authData]);

    //   const login = (idUser,idProfil,username,email,nom,prenom,pathImageIdentite,estEmployee,estChauffeur,
    //     accesAutorise,permissionsNumEcran,idEntreprise,idGroupe,globalParams,token) => {
    //     const authInfo = {
    //         idUser,
    //         idProfil,
    //         username,
    //         email,
    //         nom,
    //         prenom,
    //         pathImageIdentite,
    //         estEmployee,
    //         estChauffeur,
    //         accesAutorise,
    //         permissionsNumEcran,
    //         idEntreprise,
    //         idGroupe,
    //         globalParams,
    //         wilayas
    //         token
    //     };

    const login = (authInfo) => {
        setAuthData(authInfo);
    };

    const logout = () => {
        setAuthData(null);
        localStorage.removeItem('authData');
    };

    return (
        <AuthContext.Provider value={{ authData, login, logout }}>
            {children}
        </AuthContext.Provider>
    );
};
