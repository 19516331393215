import React, { useEffect, useState, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import moment from 'moment/moment';
import { CustomToast } from '../../../../components/Toast/CustomToast';
import ConfirmationDialog from '../../../../components/Confirmation/ConfirmationDialog';
import { axiosInstance, ModePaiement } from '../../../../services/api';
import { AuthContext } from '../../../../contexts/AuthContext';
import useDebounce from '../../../../hooks/useDebounce';

const ListeModesPaiements = () => {
    const { authData } = useContext(AuthContext);
    const navigate = useNavigate();

    const [modesPaemens, setModesPaemens] = useState([]);
    const [filtredModesPaiements, setFiltredModesPaiements] = useState([]);
    const [loading, setLoading] = useState(true);
    const [pageNumber, setPageNumber] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalItems, setTotalItems] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [showAddModal, setShowAddModal] = useState(false);
    const [showUpdateModal, setShowUpdateModal] = useState(false);
    const [idModePaiementDelete, setIdModePaiementDelete] = useState('');
    const [idModePaiementUpdate, setIdModePaiementUpdate] = useState('');

    const [search, setSearch] = useState('');
    const debouncedSearchText = useDebounce(search, 300);

    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);

     //Table management
     const [sortBy, setSortBy] = useState(''); // Column name to sort by
     const [sortOrder, setSortOrder] = useState('asc'); // 'asc' or 'desc'
 
     const handleSort = (column) => {
         setSortBy(column);
         setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
 
         let sortedTable = [...filtredModesPaiements].sort((a, b) => {
             const aValue = a[sortBy] || '';
             const bValue = b[sortBy] || '';
 
             if (sortOrder === 'asc') {
                 return aValue.localeCompare(bValue);
             } else {
                 return bValue.localeCompare(aValue);
             }
         });
 
         setFiltredModesPaiements(sortedTable);
     };
 
     const [openAboutList, setOpenAboutList] = useState(null);

     const toggleAboutList = (idMarque) => {
         // console.log(idMarque)
         setOpenAboutList(openAboutList === idMarque ? null : idMarque);
     };

     const handlePageChange = (page) => {
         setPageNumber(page);
         //consolelog("setPageNumber="+pageNumber)
         const startIndex = (page - 1) * pageSize;
         const endIndex = startIndex + pageSize;
         const paginatedData = modesPaemens.slice(startIndex, endIndex);
 
         setFiltredModesPaiements(paginatedData);
     };
 
     const handlePerRowsChange = (newPerPage) => {
         setPageSize(newPerPage);
         //console.log("setPageSize="+pageSize);
     };

    const showHideAddModal = (val) => {
        setShowAddModal(val);
        setIdModePaiementUpdate('');
        formik1.resetForm();
    };

    const showHideUpdateModal = (val, idModePaiement, libelleModePaiement) => {
        setShowUpdateModal(val);
        setIdModePaiementUpdate(idModePaiement);
        formik2.resetForm();
        if (val) {
            formik2.values.libelleModePaiementUpdate = libelleModePaiement;
        }
    };

    //Supprimer mode de paiement
     const handleConfirmDelete = (idModePaiement) => {
        // Show the modal when the confirm delete button is clicked
        setIdModePaiementDelete(idModePaiement);
        setConfirmDialogOpen(true);
    };

    const handleDelete = async () => {
        try {
            const response = await axiosInstance.delete(`${ModePaiement}/${idModePaiementDelete}`);
            setIdModePaiementDelete('');
            CustomToast("Le mode de paiement a été supprimé avec succès", 'success');
            getModePaiement();
            setConfirmDialogOpen(false);
        }
        catch (error) {
            // Handle error
            if (!error?.response) {
                CustomToast("Aucune réponse du serveur", 'error');
            } else if (error.response?.status === 401) {
                CustomToast("Non autorisé", 'error');
            } else {
                CustomToast("Demande échoué", 'error');
            }
            console.log(error);
        }
    }

    const handleCancelDelete = () => {
        setConfirmDialogOpen(false);
    };

    //Load data
    const getModePaiement = async () => {
        try {
            const response = await axiosInstance.get(`${ModePaiement}/ModePaiementUsage`);
            const resp = await response.data;
            setModesPaemens(resp);
             let filteredResult = resp;

            if (search !== '') {
                filteredResult = resp.filter((item) =>
                    Object.values(item).some(
                        (value) =>
                            value &&
                            value.toString().toLowerCase().includes(search.toLowerCase())
                    )
                );
            }

            // Pagination
            const startIndex = (pageNumber - 1) * pageSize;
            const endIndex = startIndex + pageSize;
            const paginatedResult = filteredResult.slice(startIndex, endIndex);

            setFiltredModesPaiements(paginatedResult);
            setPageNumber(1);
            setTotalItems(filteredResult.length);
            setTotalPages(Math.ceil(filteredResult.length / pageSize));
            setLoading(false);

            setLoading(false);
        }
        catch (err) {
            setLoading(false);
            console.log(err);
        }
    }

    const handleSearch = (searchTerm) => {

        const result = modesPaemens?.filter((item) =>
            Object.values(item).some(
                (value) =>
                    value &&
                    value.toString().toLowerCase().includes(searchTerm.toLowerCase())
            )
        );
        //console.log(searchTerm,result)
        // Pagination
        const startIndex = (pageNumber - 1) * pageSize;
        const endIndex = startIndex + pageSize;
        const paginatedResult = result.slice(startIndex, endIndex);
        setFiltredModesPaiements(paginatedResult);
        setPageNumber(1);
        setTotalItems(result.length);
        setTotalPages(Math.ceil(result.length / pageSize));
        setSearch(searchTerm);
    };

    const formik1 = useFormik({
        initialValues: {
            libelleModePaiement: '',
        },
        validationSchema: Yup.object({
            libelleModePaiement: Yup.string()
                .required('Champ obligatoire')
        }),
        onSubmit: async (values) => {
            try {
                const postData = {
                    libelleModePaiement: values.libelleModePaiement,
                    idUtilisateur: authData.idUser,
                };

                await axiosInstance.post(ModePaiement, postData);
                CustomToast("Le mode de paiement a été créé avec succès", 'success');
                getModePaiement();
                setShowAddModal(false);
                //navigate('/listemodespaiements', { replace: true });
            } catch (error) {
                // Handle error
                if (!error?.response) {
                    CustomToast("Aucune réponse du serveur", 'error');
                } else if (error.response?.status === 400 && error.response?.data === "Mode existe") {
                    CustomToast("Mode de paiement existe", 'error');
                } else if (error.response?.status === 401) {
                    CustomToast("Non autorisé", 'error');
                } else {
                    CustomToast("Demande échoué", 'error');
                }
                console.log(error);
            }
        }
    });

    const formik2 = useFormik({
        initialValues: {
            libelleModePaiementUpdate: '',
        },
        validationSchema: Yup.object({
            libelleModePaiementUpdate: Yup.string()
                .required('Champ obligatoire')
        }),
        onSubmit: async (values) => {
            try {
                const postData = {
                    idModePaiement: idModePaiementUpdate,
                    libelleModePaiement: values.libelleModePaiementUpdate,
                    idUtilisateur: authData.idUser,
                };

                await axiosInstance.put(ModePaiement, postData);
                CustomToast("Le mode de paiement a été modifié avec succès", 'success');
                showHideUpdateModal(false, '', '');
                getModePaiement();

                //navigate('/listemodespaiements', { replace: true });
            } catch (error) {
                // Handle error
                if (!error?.response) {
                    CustomToast("Aucune réponse du serveur", 'error');
                } else if (error.response?.status === 400 && error.response?.data === "Mode existe") {
                    CustomToast("Mode de paiement existe", 'error');
                } else if (error.response?.status === 401) {
                    CustomToast("Non autorisé", 'error');
                } else {
                    CustomToast("Demande échoué", 'error');
                }
                console.log(error);
            }
        }
    });

    useEffect(() => {
        //Load Data
        getModePaiement();
        return () => {
            // Perform any necessary cleanup (e.g., cancel pending requests)
        };
    }, [authData, pageSize]);

    // useEffect(() => {
    //     const result = modesPaemens?.filter((item) =>
    //         Object.values(item).some(
    //             (value) =>
    //                 value &&
    //                 value.toString().toLowerCase().includes(search.toLowerCase())
    //         )
    //     );
    //     setFiltredModesPaiements(result);
    // }, [search, modesPaemens])

    useEffect(() => {
        //Load Data
        handleSearch(debouncedSearchText);
        return () => {
            // Perform any necessary cleanup (e.g., cancel pending requests)
        };
    }, [debouncedSearchText]);

    return (
        <section className="donnee-section" onClick={() => { if (openAboutList !== null) { setOpenAboutList(null) } }}>
            <div className="container diver-section">
                <div className="wilaya-sec wrap-all">
                <div className="row row-center">
                        <Link to="/divers" className="retour cursurer" id="vehicule-list-assrc-retour">
                            <div className="arrow-back">
                                <span className="material-symbols-outlined" id="arrow">arrow_back</span>
                            </div>
                        </Link>
                        <Link href="#" className="color-primary-2 decoration-none" onClick={() => showHideAddModal(true)}>
                            <div className="add-new" id="add-vehc">
                                <span className="material-symbols-outlined">add</span>
                                Ajouter un mode de paiement
                            </div>
                        </Link>
                    </div>
                    
                   <div className="group-elements">
                        <div className="voir-elements">
                            <p></p>
                            <select
                                name="pageSize"
                                id="pageSize"
                                onChange={(e) => { handlePerRowsChange(e.target.value || 10); setPageNumber(1); }}
                            >
                                <option value="10" defaultValue={true}>10</option>
                                <option value="15">15</option>
                                <option value="20">20</option>
                            </select>
                        </div>
                        <div className="search-element">
                            <p>Rechercher</p>
                            <input
                                type="text"
                                value={search}
                                onChange={(e) => handleSearch(e.target.value)}
                            />
                        </div>
                    </div>
                    {filtredModesPaiements?.length > 0 && <div className="table-wrap">
                            <table>
                                <thead>
                                    <tr>
                                        <th onClick={() => handleSort('libelleModePaiement')}>Nom</th>
                                        <th>Dépenses</th>
                                        <th>Carburants</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {filtredModesPaiements?.map((row) => (
                                        <tr key={row.idModePaiement}>
                                            <td>{row.libelleModePaiement}</td>
                                            <td>{row.countDepense + " dépense(s)"}</td>
                                            <td>{row.countCarburant + " carburant(s)"}</td>
                                            <td className="txt-center relative">
                                            <span className="material-symbols-outlined about-btn" onClick={() => toggleAboutList(row.idModePaiement)}>
                                                more_horiz
                                            </span>
                                            {openAboutList === row.idModePaiement && (
                                                <div className="about-list">
                                                    <ul>
                                                        <li onClick={() => showHideUpdateModal(true, row.idModePaiement, row.libelleModePaiement)}>
                                                            <span className="material-symbols-outlined">edit</span>
                                                            <p>Modifier</p>
                                                        </li>
                                                        {(row.countDepense == 0 && row.countCarburant ==0) && <li onClick={() => handleConfirmDelete(row.idModePaiement)}>
                                                            <span className="material-symbols-outlined">delete</span>
                                                            <p>Supprimer</p>
                                                        </li>}
                                                        {(row.countDepense > 0 || row.countCarburant > 0) && <li style={{ textDecoration: "none", color: "#BEBEBE",cursor:'not-allowed' }} onClick={(event) => event.preventDefault()} title="Mode de paiement en cours d'utilisation">
                                                            <span className="material-symbols-outlined">delete</span>
                                                            <p>Supprimer</p>
                                                        </li>}
                                                    </ul>
                                                </div>
                                            )}
                                        </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>}
                        <div className="pagination">
                            <Link to="#" onClick={() => handlePageChange(pageNumber - 1)}
                                className={(pageNumber === 1) ? 'pgn disabled' : 'pgn'}
                                disabled={(pageNumber === 1) ? true : false}
                            >&laquo;</Link>
                            {Array.from({ length: totalPages }, (_, i) => (
                                <Link
                                    key={i + 1}
                                    to="#"
                                    className={i + 1 === pageNumber ? 'pgn active' : 'pgn'}
                                    onClick={() => handlePageChange(i + 1)}
                                >
                                    {i + 1}
                                </Link>
                            ))}
                            <Link to="#" onClick={() => handlePageChange(pageNumber + 1)}
                                className={((pageNumber + 1) > totalPages) ? 'pgn disabled' : 'pgn'}
                                disabled={((pageNumber + 1) > totalPages) ? true : false}
                            >&raquo;</Link>
                        </div>

                    {showAddModal && <div className="popcard-add">
                        <div className="popcard-add_inside">
                            <Link className="popcard-add_inside-close" to="#" onClick={() => showHideAddModal(false)}>&#10006;</Link>
                            <h2 className="popcard-add_inside-title"></h2>
                            <div className="popcard-add_inside-form">
                                <form onSubmit={formik1.handleSubmit}>
                                    <div className="wrap-info">
                                        <div className="wrap-one">
                                            <label>Nom<span className='requiredField'>*</span></label>
                                            <input type="text"
                                                id="libelleModePaiement"
                                                name="libelleModePaiement"
                                                style={(formik1.touched.libelleModePaiement && formik1.errors.libelleModePaiement) ? { borderColor: "red" } : null}
                                                placeholder=""
                                                defaultValue={formik1.values.libelleModePaiement}
                                                onChange={formik1.handleChange}
                                            />
                                            {formik1.touched.libelleModePaiement && formik1.errors.libelleModePaiement ? (
                                                <div className="invalid-feedback">{formik1.errors.libelleModePaiement}</div>
                                            ) : null}
                                        </div>
                                    </div>

                                    <div className="popcard-operation-pickup-details_inside-zone">
                                        <div className="buttons">
                                            <input type="submit" value="Enregistrer" className="submit-btn" />
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>}

                    {showUpdateModal && <div className="popcard-add">
                        <div className="popcard-add_inside">
                            <Link className="popcard-add_inside-close" to="#" onClick={() => showHideUpdateModal(false, '', '')}>&#10006;</Link>
                            <h2 className="popcard-add_inside-title"></h2>
                            <div className="popcard-add_inside-form">
                                <form onSubmit={formik2.handleSubmit}>
                                <div className="wrap-info">
                                        <div className="wrap-one">
                                            <label>Nom<span className='requiredField'>*</span></label>
                                            <input type="text"
                                                id="libelleModePaiementUpdate"
                                                name="libelleModePaiementUpdate"
                                                style={(formik2.touched.libelleModePaiementUpdate && formik2.errors.libelleModePaiementUpdate) ? { borderColor: "red" } : null}
                                                placeholder=""
                                                defaultValue={formik2.values.libelleModePaiementUpdate}
                                                onChange={formik2.handleChange}
                                            />
                                            {formik2.touched.libelleModePaiementUpdate && formik2.errors.libelleModePaiementUpdate ? (
                                                <div className="invalid-feedback">{formik2.errors.libelleModePaiementUpdate}</div>
                                            ) : null}
                                        </div>
                                    </div>

                                    <div className="popcard-operation-pickup-details_inside-zone">
                                        <div className="buttons">
                                            <input type="submit" value="Enregistrer" className="submit-btn" />
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>}

                    <ConfirmationDialog
                        open={confirmDialogOpen}
                        onClose={handleCancelDelete}
                        onConfirm={handleDelete}
                        titleMessage="Confirmer la suppression"
                        bodyMessage="Voulez-vous vraiment supprimer cette catégorie? Toutes les données seront définitivement perdues."
                        btnCancelText="Annuler"
                        btnConfirmText="Supprimer la catégorie"
                    />

                </div>
            </div>
        </section>
    );
}

export default ListeModesPaiements;

