import React from 'react';
import { toast } from 'react-toastify';

const CustomToast = (message, type, autoClose=5000,zIndex=15000) => {
    const options = {
        position: "top-right",
        autoClose: autoClose,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        style: {
            zIndex: zIndex
          },
    };

    // Set the toast type based on the input
    switch (type) {
        case 'success':
            toast.success(message, options);
//console.log("success")
            break;
        case 'error':
            toast.error(message, options);
            break;
        case 'warning':
            toast.warn(message, options);
            break;
        case 'info':
            toast.info(message, options);
            break;
        default:
            toast(message, options);
    }
    return null;
};

export { CustomToast };