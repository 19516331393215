export const formatCurrency = (value) => {
    // Format the value as currency with appropriate options//fr-FR
    return new Intl.NumberFormat('fr-FR', {
        // style: 'currency',
        // currency: 'DZD', // Currency code
        style: 'decimal',
        minimumFractionDigits: 2,
        //useGrouping: true,
    }).format(value);
};

// export const formatYAxis=(value)=> {
//     if (value >= 1000) {
//         // return (value / 1000).toFixed(1) + 'k';
//         return value % 1 === 0 ? (value / 1000).toFixed(0) + 'k' : (value / 1000).toFixed(1) + 'k';
//     }
//     return value;
// };

 function toThousands(value, fixed = 0) {
    return (value.toFixed(fixed) + '').replace(/\d{1,3}(?=(\d{3})+(\.\d*)?$)/g, '$&,');
  }

export const formatYAxis=(value)=> {
    if (value === null || typeof value === 'undefined') {
      return '--';
    } else if (value === 0) {
      return '0';
    } else if (0 < value && value < 1000) {
      return value;//'< 1k';
    } else if (1000 <= value && value < 1000000) {
      return toThousands(value / 1000) + 'k';
    } else if (1000000 <= value && value < 1000000000) {
      return toThousands(value / 1000000) + 'M';
    } else if (1000000000 <= value && value < 1000000000000) {
      return toThousands(value / 1000000000) + 'B';
    } else if (1000000000000 <= value) {
      return 'INF.';
    }
  }

export function generateSecurePassword() {
    const charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()_+~`|}{[]:;?><,./-=";
    const passwordLength = Math.floor(Math.random() * (16 - 8 + 1)) + 8; // Random length between 8 and 16
    let password = "";
    for (let i = 0; i < passwordLength; i++) {
      const randomIndex = Math.floor(Math.random() * charset.length);
      password += charset[randomIndex];
    }
    return password;
  }