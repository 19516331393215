import React from 'react';

const Empty = () => {
    return (
        <section className="vehicles-section">
            <div className="container">
            </div>
        </section>
    );
}

export default Empty;
