//import React, {useContext } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AuthProvider } from './contexts/AuthContext';
import Layout from './components/Layout/Layout.js';

import Login from './pages/Login/Login.js';

import Empty from "./pages/BlankPage/Empty";

import Dashboard from "./pages/Dashboard/Dashboard";

import ListVehicules from "./pages/Vehicule/ListVehicules";
import AjouterVehicule from "./pages/Vehicule/AjouterVehicule";
import ModifierVehicule from './pages/Vehicule/ModifierVehicule';
import ListAssurances from './pages/Assurance/ListAssurances';
import ListControles from './pages/ControleTechnique/ListControles';

import Assurances from "./pages/Assurance/Assurances";
import AjouterAssurance from './pages/Assurance/AjouterAssurance';
import ModifierAssurance from './pages/Assurance/ModifierAssurance';

import ControleTechniques from "./pages/ControleTechnique/ControleTechniques";
import AjouterControle from "./pages/ControleTechnique/AjouterControle";
import ModifierControle from "./pages/ControleTechnique/ModifierControle";

import ListAffectations from './pages/Affectation/ListAffectations';
import AjouterAffectation from './pages/Affectation/AjouterAffectation';
import ModifierAffectation from './pages/Affectation/ModifierAffectation';

import ListCompteurs from './pages/Compteur/ListCompteurs';
import AjouterCompteur from './pages/Compteur/AjouterCompteur';
import ModifierCompteur from './pages/Compteur/ModifierCompteur';

import ListCarburants from './pages/Carburant/ListCarburants';
import AjouterCarburant from './pages/Carburant/AjouterCarburant';
import ModifierCarburant from './pages/Carburant/ModifierCarburant';

import ListDepenses from './pages/Depense/ListDepenses';
import AjouterDepense from './pages/Depense/AjouterDepense';
import ModifierDepense from './pages/Depense/ModifierDepense';

import ListInspections from './pages/Inspection/ListInspections';
import AjouterInspection from './pages/Inspection/AjouterInspection';
import ApercuInspection from './pages/Inspection/ApercuInspection';
import ListInspectionsEchecs from './pages/Inspection/ListInspectionsEchecs';

import ListProblemes from './pages/Probleme/ListProblemes';
import AjouterProbleme from './pages/Probleme/AjouterProbleme';
import ModifierProbleme from './pages/Probleme/ModifierProbleme';

import ListFormulaires from './pages/Formulaire/ListFormulaires';
import AjouterFormulaire from './pages/Formulaire/AjouterFormulaire';
import ModifierFormulaire from './pages/Formulaire/ModifierFormulaire';

import ListEntrees from './pages/Service/Entree/ListEntrees';
import AjouterEntree from './pages/Service/Entree/AjouterEntree';
import ModifierEntree from './pages/Service/Entree/ModifierEntree';
import ApercuService from './pages/Service/Entree/ApercuService';

import ListOrdres from './pages/Service/Ordre/ListOrdres';
import AjouterOrdre from './pages/Service/Ordre/AjouterOrdre';
import ModifierOrdre from './pages/Service/Ordre/ModifierOrdre';
import ApercuOrdre from './pages/Service/Ordre/ApercuOrdre';

import ListRappels from './pages/Service/Rappel/ListRappels';
import AjouterRappel from './pages/Service/Rappel/AjouterRappel';
import ModifierRappel from './pages/Service/Rappel/ModifierRappel';

import ListTaches from './pages/Service/Tache/ListTaches';

import ListClients from "./pages/Client/ListClients";

import Params from './pages/ParamsApp/Params';

import ListEmployes from './pages/Employe/ListEmployes';
import AjouterEmploye from './pages/Employe/AjouterEmploye';
import ModifierEmploye from './pages/Employe/ModifierEmploye';

import ListRoles from './pages/Role/ListRoles';
import AjouterRole from './pages/Role/AjouterRole';
import ModifierRole from './pages/Role/ModifierRole';

import ListMarques from './pages/Divers/Flotte/Marque/ListMarques';
import ListModeles from './pages/Divers/Flotte/Modele/ListModeles';
import ListTypeVehicules from './pages/Divers/Flotte/TypeVehicule/ListTypeVehicules';
import ListGroupes from './pages/Divers/Flotte/Groupe/ListGroupes';
import ListParcs from './pages/Divers/Flotte/Parc/ListParcs';

import ListTypeDepenses from './pages/Divers/Consommation/Depense/ListTypeDepenses';
import ListTypeCarburant from './pages/Divers/Consommation/TypeCarburant/ListTypeCarburant';

import ListWilayas from './pages/Divers/Ref/Wilaya/ListWilayas';
import ListCommunes from './pages/Divers/Ref/Commune/ListCommunes';

import ListUdm from './pages/Divers/Ref/UDM/ListUdm';
import ListeModesPaiements from './pages/Divers/Ref/ModePaiement/ListeModesPaiements';

import ListCatPermis from './pages/Divers/Administratif/Permis/ListCatPermis';
import ListPi from './pages/Divers/Administratif/PI/ListPi';
import ListTypeAssurance from './pages/Divers/Administratif/TypeAssurance/ListTypeAssurance';

import ListRappelleEmp from './pages/Divers/Rappelle/Employe/ListRappelleEmp';
import ListRappelleVehicule from './pages/Divers/Rappelle/Vehicule/ListRappelleVehicule';

import ListFournisseurs from './pages/Fournisseur/ListFournisseurs';
import AjouterFournisseur from './pages/Fournisseur/AjouterFournisseur';
import ModifierFournisseur from './pages/Fournisseur/ModifierFournisseur';

import ListProprietaires from './pages/Proprietaire/ListProprietaires';
import AjouterProprietaire from './pages/Proprietaire/AjouterProprietaire';
import ModifierProprietaire from './pages/Proprietaire/ModifierProprietaire';

import Divers from './pages/Divers/Divers';

import Compte from './pages/Compte/Compte';

import ProtectedRoute from './services/Securite/ProtectedRoute';
import ProtectedRouteSuperAdmin from './services/Securite/ProtectedRouteSuperAdmin';

import Unauthorized from './pages/Errors/Unauthorized';
import NotFound from './pages/Errors/NotFound';

function App() {

  // const { authData } = useContext(AuthContext);
  // if(authData){
  //   console.log("app")
  // }
  return (
    <>
      {/* basename="/fleetmanagement" */}
      <BrowserRouter>
        <AuthProvider>
          <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/login" element={<Login />} />
            <Route path="unauthorized" element={<Unauthorized />} />

            <Route element={<Layout />} >

              <Route path="/compte" element={<Compte />} />

              <Route path="/" element={<ProtectedRoute allowedRoles={[1]} />}>
                <Route path="/Dashboard" element={<Dashboard />} />
              </Route>

              <Route path="/" element={<ProtectedRoute allowedRoles={[2]} />}>
                <Route path="/listvehicules" element={<ListVehicules />} />
                <Route path="/ajoutervehicule" element={<AjouterVehicule />} />
                <Route path="/modifiervehicule/:id" element={<ModifierVehicule />} />
                <Route path="/listassurances/:id" element={<ListAssurances />} />
                <Route path="/listcontroles/:id" element={<ListControles />} />
              </Route>

              <Route path="/" element={<ProtectedRoute allowedRoles={[21]} />}>
                <Route path="/assurances" element={<Assurances />} />
                <Route path="/ajouterassurance" element={<AjouterAssurance />} />
                <Route path="/modifierassurance/:id" element={<ModifierAssurance />} />
              </Route>

              <Route path="/" element={<ProtectedRoute allowedRoles={[22]} />}>
                <Route path="/controletechniques" element={<ControleTechniques />} />
                <Route path="/ajoutercontrole" element={<AjouterControle />} />
                <Route path="/modifiercontrole/:id" element={<ModifierControle />} />
              </Route>

              <Route path="/" element={<ProtectedRoute allowedRoles={[5]} />}>
                <Route path="/listaffectations" element={<ListAffectations />} />
                <Route path="/ajouteraffectation" element={<AjouterAffectation />} />
                <Route path="/modifieraffectation/:id" element={<ModifierAffectation />} />
              </Route>

              <Route path="/" element={<ProtectedRoute allowedRoles={[3]} />}>
                <Route path="/listcompteurs" element={<ListCompteurs />} />
                <Route path="/ajoutercompteur" element={<AjouterCompteur />} />
                <Route path="/modifiercompteur/:id" element={<ModifierCompteur />} />
              </Route>

              <Route path="/" element={<ProtectedRoute allowedRoles={[4]} />}>
                <Route path="/listcarburants" element={<ListCarburants />} />
                <Route path="/ajoutercarburant" element={<AjouterCarburant />} />
                <Route path="/modifiercarburant/:id" element={<ModifierCarburant />} />
              </Route>

              <Route path="/" element={<ProtectedRoute allowedRoles={[6]} />}>
                <Route path="/listdepenses" element={<ListDepenses />} />
                <Route path="/ajouterdepense" element={<AjouterDepense />} />
                <Route path="/modifierdepense/:id" element={<ModifierDepense />} />
              </Route>

              <Route path="/" element={<ProtectedRoute allowedRoles={[7]} />}>
                <Route path="/listinspections" element={<ListInspections />} />
                <Route path="/ajouterinspection" element={<AjouterInspection />} />
                <Route path="/apercuinspection/:id" element={<ApercuInspection />} />
              </Route>

              <Route path="/" element={<ProtectedRoute allowedRoles={[8]} />}>
                <Route path="/listinspectionsechecs" element={<ListInspectionsEchecs />} />
              </Route>

              <Route path="/" element={<ProtectedRoute allowedRoles={[9]} />}>
                <Route path="/listformulaires" element={<ListFormulaires />} />
                <Route path="/ajouterformulaire" element={<AjouterFormulaire />} />
                <Route path="/modifierformulaire/:id" element={<ModifierFormulaire />} />
              </Route>

              <Route path="/" element={<ProtectedRoute allowedRoles={[10]} />}>
                <Route path="/listproblemes" element={<ListProblemes />} />
                <Route path="/ajouterprobleme" element={<AjouterProbleme />} />
                <Route path="/modifierprobleme/:id" element={<ModifierProbleme />} />
              </Route>

              <Route path="/" element={<ProtectedRoute allowedRoles={[11]} />}>
                <Route path="/listordres" element={<ListOrdres />} />
                <Route path="/ajouterordre" element={<AjouterOrdre />} />
                <Route path="/modifierordre/:id" element={<ModifierOrdre />} />
                <Route path="/apercuordre/:id" element={<ApercuOrdre />} />
              </Route>

              <Route path="/" element={<ProtectedRoute allowedRoles={[12]} />}>
                <Route path="/listentrees" element={<ListEntrees />} />
                <Route path="/ajouterentree" element={<AjouterEntree />} />
                <Route path="/modifierentree/:id" element={<ModifierEntree />} />
                <Route path="/apercuservice/:id" element={<ApercuService />} />
              </Route>

              <Route path="/" element={<ProtectedRoute allowedRoles={[13]} />}>
                <Route path="/listtaches" element={<ListTaches />} />
              </Route>

              <Route path="/" element={<ProtectedRoute allowedRoles={[14]} />}>
                <Route path="/listrappels" element={<ListRappels />} />
                <Route path="/ajouterrappel" element={<AjouterRappel />} />
                <Route path="/modifierrappel/:id" element={<ModifierRappel />} />
              </Route>

              {/* Route pour super-admin seulement */}

              <Route path="/" element={<ProtectedRouteSuperAdmin />}>
                <Route path="/listclients" element={<ListClients />} />
              </Route>

              <Route path="/" element={<ProtectedRoute allowedRoles={[15]} />}>
                <Route path="/params" element={<Params />} />
              </Route>

              <Route path="/" element={<ProtectedRoute allowedRoles={[16]} />}>
                <Route path="/listemployes" element={<ListEmployes />} />
                <Route path="/ajouteremploye" element={<AjouterEmploye />} />
                <Route path="/modifieremploye/:id" element={<ModifierEmploye />} />
              </Route>

              <Route path="/" element={<ProtectedRoute allowedRoles={[17]} />}>
                <Route path="/listroles" element={<ListRoles />} />
                <Route path="/ajouterrole" element={<AjouterRole />} />
                <Route path="/modifierrole/:id" element={<ModifierRole />} />
              </Route>

              <Route path="/" element={<ProtectedRoute allowedRoles={[18]} />}>
                <Route path="/listfournisseurs" element={<ListFournisseurs />} />
                <Route path="/ajouterfournisseur" element={<AjouterFournisseur />} />
                <Route path="/modifierfournisseur/:id" element={<ModifierFournisseur />} />
              </Route>

              <Route path="/" element={<ProtectedRoute allowedRoles={[19]} />}>
                <Route path="/listproprietaires" element={<ListProprietaires />} />
                <Route path="/ajouterproprietaire" element={<AjouterProprietaire />} />
                <Route path="/modifierproprietaire/:id" element={<ModifierProprietaire />} />
              </Route>

              <Route path="/" element={<ProtectedRoute allowedRoles={[20]} />}>
                <Route path="/divers" element={<Divers />} />

                <Route path="/listmarques" element={<ListMarques />} />
                <Route path="/listmodeles" element={<ListModeles />} />
                <Route path="/listtypeVehicules" element={<ListTypeVehicules />} />

                <Route path="/listtypedepenses" element={<ListTypeDepenses />} />
                <Route path="/listtypecarburant" element={<ListTypeCarburant />} />

                <Route path="/listwilayas" element={<ListWilayas />} />
                <Route path="/listcommunes" element={<ListCommunes />} />
                <Route path="/listgroupes" element={<ListGroupes />} />
                <Route path="/listparcs" element={<ListParcs />} />
                <Route path="/listudm" element={<ListUdm />} />
                <Route path="/listemodespaiements" element={<ListeModesPaiements />} />

                <Route path="/listcatpermis" element={<ListCatPermis />} />
                <Route path="/listpi" element={<ListPi />} />
                <Route path="/listtypeassurance" element={<ListTypeAssurance />} />

                <Route path="/listrappelleemp" element={<ListRappelleEmp />} />
                <Route path="/listrappellevehicule" element={<ListRappelleVehicule />} />
              </Route>

              <Route path="/" element={<ProtectedRoute allowedRoles={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22]} />}>
                <Route path="/empty" element={<Empty />} />
              </Route>

              {/* <Route path="*" element={<Empty />} /> */}
            </Route>
            <Route path="*" element={<NotFound />} />
          </Routes>
        </AuthProvider>
      </BrowserRouter>
      <ToastContainer style={{ fontSize: '1.35rem' }} />
    </>
  );
}

export default App;
