import React, { useEffect, useState, useRef, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Select from "react-select";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { CustomToast } from '../../components/Toast/CustomToast';
import useDebounce from '../../hooks/useDebounce';
import { axiosInstance, Vehicule, ModeleInspection, VehiculeInspection } from '../../services/api';
import { AuthContext } from '../../contexts/AuthContext';

const AjouterInspection = () => {
    const { authData } = useContext(AuthContext);
    const navigate = useNavigate();

    const [details, setDetails] = useState(true);
    const [controle, setControle] = useState(false);

    const [detailsClass, setDetailsClass] = useState("line act");
    const [controleClass, setControleClass] = useState("line");

    const [vehicules, setVehicules] = useState([]);
    const [selectedVehicle, setSelectedVehicle] = useState('');
    const [selectedVehicleId, setSelectedVehicleId] = useState('');
    const [formulaires, setFormulaires] = useState([]);
    const [formulaire, setFormulaire] = useState(null);

    const [checklistData, setChecklistData] = useState([]);

    const [fieldErrors, setFieldErrors] = useState({});
    const [validationStatus, setValidationStatus] = useState(false);

    const [enteredVehicleText, setEnteredVehicleText] = useState('');
    const debouncedVehiculeText = useDebounce(enteredVehicleText, 300);

    const showDetails = () => {
        setDetails(true);
        setControle(false);

        setDetailsClass("line act");
        setControleClass("line");
    };

    const showControle = () => {
        setDetails(false);
        setControle(true);

        setDetailsClass("line");
        setControleClass("line act");
    };

    const formik1 = useFormik({
        initialValues: {
            vehicule: '',
            idVehicule: '',
            idModele: '',
        },
        validationSchema: Yup.object({
            vehicule: Yup.object()
                .nullable()
                .required('Champ obligatoire'),
            idModele: Yup.string()
                .required("Champ obligatoire"),
        }),
        onSubmit: async (values) => {
            try {

                // console.log(checklistData);
                // console.log(values);
                // const postData = {
                //     idVehicule: values.vehicule.idVehicule,
                //     idUtilisateur: authData.idUser,
                // };
                // console.log(postData)
                // Check mandatory fields for each checklist item
                const fieldErrors = {};
                let isValid = true;
                checklistData.forEach((item, index) => {
                    if (item.itemOblogatoire && !item.statut) {
                        fieldErrors[`statut-${index}`] = "Champ obligatoire";
                        isValid = false;
                    }

                    if (item.observationItemOblogatoire && !item.commentaire && item.statut === 'fail') {
                        fieldErrors[`commentaire-${index}`] = "Champ obligatoire";
                        isValid = false;
                    }

                    if (item.pathImageItemOblogatoire && !item.pathImageItem && item.statut === 'fail') {
                        fieldErrors[`pathImageItem-${index}`] = "Champ obligatoire";
                        isValid = false;
                    }
                });

                setFieldErrors(fieldErrors);
                setValidationStatus(true);

                //console.log(isValid)
                if (!isValid) {
                    // Show an error message or handle the validation error as needed
                    CustomToast("Veuillez remplir tous les champs obligatoires.", 'error');
                    return;
                }

                // const formData = {
                //     ...values,
                //     checklistData: checklistData.map((item) => ({
                //         itemTitre: item.itemTitre,
                //         description: item.observation,
                //         commentaire: item.commentaire,
                //         pathImageItem: item.pathImageItem,
                //         statut: item.statut,
                //     })),
                // };


                const transformedItems = checklistData.map((item) => ({
                    itemTitre: item.itemTitre,
                    description: item.description,
                    commentaire: item.commentaire,
                    pathImageItem: item.pathImageItem,
                    statut: item.statut,
                }));

                //console.log(checklistData)

                const formData = new FormData();
                formData.append('idVehicule', values.vehicule.idVehicule);
                formData.append('idModeleFormulaire', values.idModele);

                // for (let i = 0; i < transformedItems.length; i++) {
                //     for (let key of Object.keys(transformedItems[i])) {
                //       //console.log(key, transformedItems[i][key]);
                //       formData.append(`inspectionItems[${i}][${key}]`, transformedItems[i][key]);
                //     }
                //   }

                transformedItems.forEach((item, index) => {
                    formData.append(`inspectionItems[${index}][itemTitre]`, item.itemTitre);
                    formData.append(`inspectionItems[${index}][description]`, item.description);
                    formData.append(`inspectionItems[${index}][commentaire]`, item.commentaire);
                    //console.log(item)
                    //formData.append(`inspectionItems[${index}][pathImageItem]`, item.pathImageItem);
                    formData.append(`inspectionItems[${index}].pathImageItem`, item.pathImageItem);
                    formData.append(`inspectionItems[${index}][statut]`, item.statut);
                });

                formData.append('utilisateurCreationInspection', authData.nomPrenom);
                formData.append('utilisateurCreationInspectionId', authData.idUser);

                formData.append('actionIdUtilisateur', authData.idUser);

                // for (const [key, value] of formData.entries()) {
                //     console.log(key, value);
                //   }

                await axiosInstance.post(VehiculeInspection, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });

                CustomToast("L'inspection a été créée avec succès", 'success');
                navigate('/listinspections', { replace: true });
            } catch (error) {
                // Handle error
                if (!error?.response) {
                    CustomToast("Aucune réponse du serveur", 'error');
                } else if (error.response?.status === 401) {
                    CustomToast("Non autorisé", 'error');
                } else {
                    CustomToast("Demande échoué", 'error');
                }
                console.log(error);
            }
        }
    });

    const getImageFileName = (file) => {
        return file ? file.name : 'No file selected';
    };

    const handleDeleteFile = (index) => {
        const newChecklistData = [...checklistData];
        newChecklistData[index].pathImageItem = null;
        setChecklistData(newChecklistData);
    };

    //Load data  
    const handleVehicleChange = (selectedOption) => {
        setSelectedVehicle(selectedOption);
        setSelectedVehicleId(selectedOption ? selectedOption.idVehicule : null);
        formik1.values.idVehicule = selectedVehicleId;
    };

    const getVehicules = async (nomVehicule) => {
        try {
            const queryParams = new URLSearchParams({
                pageNumber: 1,
                pageSize: 10
            });

            if (authData?.wilayas != null) {
                const wilayasFilter = authData?.wilayas
                queryParams.append("wilayas", wilayasFilter.join(','));
            }
            else {
                return
            }

            if (authData?.flottesAutorises != null) {
                const flottesAutorisesFilter = authData?.flottesAutorises
                queryParams.append("flottesAutorises", flottesAutorisesFilter.join(','));
            }
            else {
                return
            }

            if (nomVehicule !== '') {
                queryParams.append("nom", nomVehicule);
            }
            const response = await axiosInstance.get(`${Vehicule}/GetVehiculesAutocomplete?${queryParams}`);
            const resp = await response.data;
            setVehicules(resp.data);
        }
        catch (err) {
            console.log(err);
        }
    }

    const getFormulaires = async () => {
        try {

            const response = await axiosInstance.get(`${ModeleInspection}`);
            const resp = await response.data;
            setFormulaires(resp);
        }
        catch (err) {
            console.log(err);
        }
    }

    const getFormulaire = async (idModele) => {
        try {
            if (idModele) {
                const response = await axiosInstance.get(`${ModeleInspection}/${idModele}`);
                const resp = await response.data;
                setFormulaire(resp);
                //console.log(resp);
                // Initialize the checklist data with default values
                const initialChecklistData = resp.items.map((item) => ({
                    itemTitre: item.itemTitre,
                    description: item.description,
                    commentaire: '',
                    pathImageItem: null,
                    statut: '',
                    itemOblogatoire: item.itemOblogatoire,
                    pathImageItemOblogatoire: item.pathImageItemOblogatoire,
                    observationItemOblogatoire: item.observationItemOblogatoire
                }));

                setChecklistData(initialChecklistData);
            }
            ;
        }
        catch (err) {
            console.log(err);
        }
    }

    useEffect(() => {
        //if (debouncedVehiculeText) {
        getVehicules(debouncedVehiculeText);
        //}
        return () => {
            // Perform any necessary cleanup (e.g., cancel pending requests)
        };
    }, [authData, debouncedVehiculeText]);

    useEffect(() => {
        //getVehicules('');
        getFormulaires();
        return () => {
            // Perform any necessary cleanup (e.g., cancel pending requests)
        };
    }, [])

    useEffect(() => {
        getFormulaire(formik1.values.idModele);
        return () => {
            // Perform any necessary cleanup (e.g., cancel pending requests)
        };
    }, [formik1.values.idModele])

    return (
        <section className="inspection-section">
            <div className="container">
                <form onSubmit={formik1.handleSubmit} className='inspectionlist-form'>
                    <div className="">
                        <div className="grid grid--small-form">
                            <div className="form-sec">
                                <div className="retour" id="retour-inspectionlist">
                                    <Link to="/listinspections" style={{ textDecoration: "none" }}>
                                        <div className="arrow-back">
                                            <span className="material-symbols-outlined" id="arrow">arrow_back</span>
                                        </div>
                                    </Link>
                                </div>
                                <div className="small-menu-sec">
                                    <div className={detailsClass} id="detail-inpection" onClick={() => showDetails()}>
                                        <span className="material-symbols-outlined">info</span>
                                        <h3 className="heading-primary">Détails</h3>
                                    </div>
                                    {(formik1.values.vehicule && formik1.values.idModele) && <div className={controleClass} id="control-inspection" onClick={() => showControle()}>
                                        <span className="material-symbols-outlined">article</span>
                                        <h3 className="heading-primary">Liste de contrôle</h3>
                                    </div>}
                                </div>
                            </div>

                            <div className="form-sec">
                                <div className="retour" id="retour-inspection-list">
                                    <div className="arrow-back-inspection">
                                    </div>
                                    <button type="submit" className="submit-btn">Enregistrer</button>
                                </div>
                                <div className="form-details">
                                    {details && <div className="detail-form" id="detail-inspection-form">
                                        <h3>Détails</h3>
                                        <div className="col">
                                            <div className="grp">
                                                <div className="gp">
                                                    <label>Véhicule <span className='requiredField'>*</span></label>
                                                    <Select
                                                        id="vehicule"
                                                        name="vehicule"
                                                        value={formik1.values.vehicule}
                                                        onChange={(option) => { formik1.setFieldValue('vehicule', option) }}
                                                        options={vehicules}
                                                        onInputChange={(inputValue) => {
                                                            // Update the entered text
                                                            setEnteredVehicleText(inputValue);
                                                        }}
                                                        getOptionLabel={(option) => option.marque + '[' + option.modele + '] ' + option.immatriculation}
                                                        getOptionValue={(option) => option.idVehicule}
                                                        isClearable
                                                        styles={{
                                                            container: (provided, state) => ({
                                                                ...provided,
                                                                boxShadow: state.isFocused ? '0px 0px 5px 0px #65458d' : formik1.errors.vehicule ? 'red' : '#BEBEBE',
                                                            }),
                                                            control: (baseStyles, state) => ({
                                                                ...baseStyles,
                                                                minHeight: '19px',
                                                                outline: '0!important',
                                                                borderRadius: '0.5rem',
                                                                borderWidth: '1px',
                                                                fontSize: '1rem',
                                                                height: '3.1rem',
                                                            }),
                                                            option: (provided, state) => ({
                                                                ...provided,
                                                                fontSize: '1rem'
                                                            }),
                                                            singleValue: (provided, state) => ({
                                                                ...provided,
                                                                fontSize: '1rem',
                                                                margin: '0',
                                                            }),
                                                            valueContainer: (provided, state) => ({
                                                                ...provided,
                                                                alignItems: 'start',
                                                                fontSize: '1rem'
                                                            }),
                                                        }}
                                                    />
                                                    {formik1.touched.vehicule && formik1.errors.vehicule && (
                                                        <div className="invalid-feedback">{formik1.errors.vehicule}</div>
                                                    )}
                                                </div>
                                            </div>
                                            {formik1.values.vehicule && <div className="grp">
                                                <div className="gp">
                                                    <label>Formulaire d'inspection <span className='requiredField'>*</span></label>
                                                    <select
                                                        id="idModele"
                                                        name="idModele"
                                                        value={formik1.values.idModele}
                                                        onChange={formik1.handleChange}
                                                    >
                                                        <option value="">Veuillez sélectionner</option>
                                                        {formulaires.map((formulaire) => (
                                                            <option key={formulaire.idModele} value={formulaire.idModele}>{formulaire.titre}</option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>}
                                        </div>
                                    </div>}

                                    {(controle && formik1.values.vehicule && formik1.values.idModele) && <div className="document-form" id="Liste-control-form">
                                        <div className="detail-form">
                                            <h3>Liste de contrôle</h3>
                                            {formulaire?.items.map((item, index) => (
                                                <div className="col" key={index}>
                                                    <div className="grp">
                                                        <div className="gp">
                                                            <label>{item.itemTitre} {item.itemOblogatoire && <span className='requiredField'>*</span>}</label>
                                                            {item.description && <label className="text-thin">{item.description}</label>}
                                                        </div>
                                                        <div className="gp" style={{ flexDirection: "row", alignItems: "baseline" }}>
                                                            <input
                                                                type="radio"
                                                                className="green-accent"
                                                                style={(validationStatus && item.itemOblogatoire && !checklistData[index].statut) ? { borderColor: "red" } : null}
                                                                checked={checklistData[index].statut === 'pass'}
                                                                onChange={() => {
                                                                    const newChecklistData = [...checklistData];
                                                                    newChecklistData[index].statut = 'pass';
                                                                    setChecklistData(newChecklistData);
                                                                }}
                                                            />
                                                            <label>Passer</label>
                                                            <input
                                                                type="radio"
                                                                className="red-accent"
                                                                style={(validationStatus && item.itemOblogatoire && !checklistData[index].statut) ? { borderColor: "red" } : null}
                                                                checked={checklistData[index].statut === 'fail'}
                                                                onChange={() => {
                                                                    const newChecklistData = [...checklistData];
                                                                    newChecklistData[index].statut = 'fail';
                                                                    setChecklistData(newChecklistData);
                                                                }}
                                                            />
                                                            <label>Échouer</label>
                                                            {validationStatus && item.itemOblogatoire && !checklistData[index].statut && (
                                                                <div className="invalid-feedback">{fieldErrors[`statut-${index}`]}</div>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="grp">
                                                        <div className="gp">
                                                            <label>Observation {(item.observationItemOblogatoire && checklistData[index].statut === 'fail') && <span className='requiredField'>*</span>}</label>
                                                            <textarea
                                                                name="commentaire"
                                                                id={`commentaire-${index}`}
                                                                style={(validationStatus && item.observationItemOblogatoire && !checklistData[index].commentaire && checklistData[index].statut === 'fail') ? { borderColor: "red" } : null}
                                                                value={checklistData[index].commentaire}
                                                                onChange={(event) => {
                                                                    const newChecklistData = [...checklistData];
                                                                    newChecklistData[index].commentaire = event.target.value;
                                                                    setChecklistData(newChecklistData);
                                                                }}
                                                            ></textarea>
                                                            {validationStatus && item.observationItemOblogatoire && !checklistData[index].commentaire && checklistData[index].statut === 'fail' && (
                                                                <div className="invalid-feedback">{fieldErrors[`commentaire-${index}`]}</div>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="grp">
                                                        <div className="gp">
                                                            <label>Document {(item.pathImageItemOblogatoire && checklistData[index].statut === 'fail') && <span className='requiredField'>*</span>}</label>
                                                            <label className="upload-file">
                                                                upload file
                                                                <input
                                                                    name="pathImageItem"
                                                                    id={`pathImageItem-${index}`}
                                                                    style={(validationStatus && item.pathImageItemOblogatoire && !checklistData[index].pathImageItem && checklistData[index].statut === 'fail') ? { borderColor: "red" } : null}
                                                                    type="file"
                                                                    onChange={(event) => {
                                                                        const newChecklistData = [...checklistData];
                                                                        newChecklistData[index].pathImageItem = event.target.files[0];
                                                                        setChecklistData(newChecklistData);
                                                                    }}
                                                                />
                                                            </label>
                                                            {checklistData[index].pathImageItem && (
                                                                <span style={{ fontSize: "10px" }}>
                                                                    {getImageFileName(checklistData[index].pathImageItem)}
                                                                    <span
                                                                        className="material-symbols-outlined"
                                                                        onClick={() => handleDeleteFile(index)}
                                                                        style={{ cursor: 'pointer' }}
                                                                    >
                                                                        delete
                                                                    </span>
                                                                </span>
                                                            )}
                                                            {(validationStatus && item.pathImageItemOblogatoire && !checklistData[index].pathImageItem && checklistData[index].statut === 'fail') && (
                                                                <div className="invalid-feedback">{fieldErrors[`pathImageItem-${index}`]}</div>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="grp">
                                                        <div style={{ height: "5px", marginTop: "4px", marginBottom: "4px", color: "#edf1f2", border: "solid", width: "100%" }}></div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>

                                    </div>}

                                    <div className="submit-container">
                                        <button type="submit" className="submit-btn">Enregistrer</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </section>
    );
}

export default AjouterInspection;
