import React, { useEffect, useState, useRef, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Select from "react-select";
import { CustomToast } from '../../components/Toast/CustomToast';
import { axiosInstance, Profil, Ecran, Wilaya } from '../../services/api';
import { AuthContext } from '../../contexts/AuthContext';

const AjouterRole = () => {
    const { authData } = useContext(AuthContext);
    const navigate = useNavigate();
    const [ecrans, setEcrans] = useState([]);
    const [wilayas, setWilayas] = useState([]);

    const onCheck = (id) => {
        setEcrans((prevEcrans) => {
            return prevEcrans.map((ecran) => {
                if (ecran.idEcran === id) {
                    return {
                        ...ecran,
                        acces: !ecran.acces, // Toggle the value of the access field
                    };
                }
                return ecran;
            });
        });
    };

    const { handleChange, values, handleSubmit, touched, errors, setFieldValue } = useFormik({
        initialValues: {
            libelleProfil: '',
            statut: 'actif',
            description: '',
            permissions: [],
            wilayas: []
        },
        validationSchema: Yup.object({
            libelleProfil: Yup.string()
                .required("Champ obligatoire"),
            wilayas: Yup.array()
                .min(1, "Au moins une wilaya doit être sélectionnée") // Minimum 1 wilaya should be selected
                .required("Champ obligatoire"),
        }),
        onSubmit: async (values) => {
            try {
                const idWilayaArray = values.wilayas.map(item => item.idWilaya);
                const postData = {
                    libelleProfil: values.libelleProfil,
                    statut: values.statut,
                    description: values.description,
                    permissions: ecrans,
                    wilayas: idWilayaArray,
                    idUtilisateur: authData.idUser,
                };

                //console.log(postData)    
                await axiosInstance.post(Profil, postData);

                CustomToast("Le rôle a été créé avec succès", 'success');
                navigate('/listroles', { replace: true });
            } catch (error) {
                // Handle error
                console.log(error);
                if (!error?.response) {
                    CustomToast("Aucune réponse du serveur", 'error');
                } else if (error.response?.status === 400 && error.response?.data === "Profil existe") {
                    CustomToast("Rôle existe", 'error');
                } else if (error.response?.status === 401) {
                    CustomToast("Non autorisé", 'error');
                } else {
                    CustomToast("Demande échoué", 'error');
                }
            }
        }
    });

    //Load data

    const getEcrans = async () => {
        try {
            const response = await axiosInstance.get(`${Ecran}`);
            const resp = await response.data;
            setEcrans(resp);
        }
        catch (err) {
            console.log(err);
        }
    }

    const getWilayas = async () => {
        try {
            const response = await axiosInstance.get(`${Wilaya}`);
            const resp = await response.data;
            setWilayas(resp);
        }
        catch (err) {
            console.log(err);
        }
    }

    useEffect(() => {
        getWilayas();
        return () => {
            // Perform any necessary cleanup (e.g., cancel pending requests)
        };
    }, []);

    useEffect(() => {
        getEcrans();
        return () => {
            // Perform any necessary cleanup (e.g., cancel pending requests)
        };
    }, []);

    // useEffect(() => {
    //     console.log(ecrans);//sera appelée à chaque smise à jour de l'état "ecran".
    //   }, [ecrans]);

    return (
        <section className="donnee-section">
            <div className="container">
                <form onSubmit={handleSubmit} className="role-droit-access-form">
                    <div className="">
                        <div className="grid grid--2-cols">
                            <div className="form-sec">
                                <div className="retour">
                                    <Link to="/listroles" style={{ textDecoration: "none" }}>
                                        <div className="arrow-back">
                                            <span className="material-symbols-outlined" id="arrow">arrow_back</span>
                                        </div>
                                    </Link>
                                    <button type="submit" className="submit-btn">Enregistrer</button>
                                </div>
                                <div className="form-details">
                                    {/* <h2>Ajouter un rôle</h2> */}
                                    <div className="detail-form" id="detail-proprietaire">

                                        <div className="col">
                                            <div className="grp">
                                                <div className="gp">
                                                    <label >Nom<span className='requiredField'>*</span></label>
                                                    <input type="text"
                                                        id="libelleProfil"
                                                        name="libelleProfil"
                                                        style={(touched.libelleProfil && errors.libelleProfil) ? { borderColor: "red" } : null}
                                                        placeholder=""
                                                        defaultValue={values.libelleProfil}
                                                        onChange={handleChange}
                                                    />
                                                    {touched.libelleProfil && errors.libelleProfil ? (
                                                        <div className="invalid-feedback">{errors.libelleProfil}</div>
                                                    ) : null}
                                                </div>
                                                <div className="gp">
                                                    <label >Statut<span className='requiredField'>*</span></label>
                                                    <select
                                                        id="statut"
                                                        name="statut"
                                                        value={values.statut}
                                                        onChange={handleChange}
                                                    >
                                                        <option value="actif" defaultValue={true}>Actif</option>
                                                        <option value="inactif">Inactif</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="grp">
                                                <div className="gp">
                                                    <label >Wilaya(s)<span className='requiredField'>*</span></label>
                                                    <Select
                                                        id="wilayas"
                                                        name="wilayas"
                                                        value={values.wilayas}
                                                        onChange={(option) => { setFieldValue('wilayas', option); }}
                                                        options={wilayas}
                                                        getOptionLabel={(option) => option.nomLatin}
                                                        getOptionValue={(option) => option.idWilaya}
                                                        isClearable
                                                        isMulti
                                                        placeholder="Séléctionnez wilaya(s)..."
                                                        styles={{
                                                            control: (baseStyles, state) => ({
                                                                ...baseStyles,
                                                                minHeight: '19px',
                                                                outline: '0!important',
                                                                borderRadius: '0.5rem',
                                                                borderWidth: '1px',
                                                                fontSize: '1rem',
                                                                borderColor: state.isFocused ? 'rgba(115, 128, 236, 0.9)' : '#BEBEBE'
                                                            }),
                                                            option: (provided, state) => ({
                                                                ...provided,
                                                                fontSize: '1rem'
                                                            }),
                                                            singleValue: (provided, state) => ({
                                                                ...provided,
                                                                fontSize: '1rem',
                                                                margin: '0',
                                                            }),
                                                            valueContainer: (provided, state) => ({
                                                                ...provided,
                                                                alignItems: 'start',
                                                                fontSize: '1rem'
                                                            }),
                                                            input: (provided, state) => ({
                                                                ...provided,
                                                                margin: '0',
                                                            }),
                                                            menu: (provided, state) => ({
                                                                ...provided,
                                                                borderRadius: '0.5rem',
                                                                zIndex: 9999,
                                                                marginTop: 0,
                                                                borderWidth: '1px',
                                                                fontSize: '1rem',
                                                            }),
                                                        }}
                                                    />
                                                    {touched.wilayas && errors.wilayas ? (
                                                        <div className="invalid-feedback">{errors.wilayas}</div>
                                                    ) : null}
                                                </div>
                                            </div>
                                            <div className="grp">
                                                <div className="gp">
                                                    <label>Description</label>
                                                    <textarea type="text"
                                                        id="description"
                                                        name="description"
                                                        placeholder=""
                                                        defaultValue={values.description}
                                                        onChange={handleChange}
                                                        rows={4}
                                                    />
                                                </div>
                                            </div>
                                            <div className="grp">
                                                <div className="multi">
                                                    <div className="">
                                                        <div className="table-header">
                                                            <table>
                                                                <colgroup>
                                                                    <col style={{ width: '35%' }} />
                                                                    <col style={{ width: '35%' }} />
                                                                    <col style={{ width: '30%' }} />
                                                                </colgroup>

                                                                <thead>
                                                                    <tr>
                                                                        <th>Menu</th>
                                                                        <th>Sous-menu</th>
                                                                        <th>Accès</th>
                                                                    </tr>
                                                                </thead>
                                                            </table>
                                                        </div>
                                                        <div className="table-list table-list-med-height-half">
                                                            <table>
                                                                <colgroup>
                                                                    <col style={{ width: '35%' }} />
                                                                    <col style={{ width: '35%' }} />
                                                                    <col style={{ width: '30%' }} />
                                                                </colgroup>
                                                                <tbody>
                                                                    {ecrans?.map((row) => (
                                                                        <tr key={row.idEcran}>
                                                                            <td>{row.menuEcran}</td>
                                                                            <td>{row.libelleEcran}</td>
                                                                            <td>
                                                                                <input type="checkbox"
                                                                                    style={{ accentColor: 'red' }}
                                                                                    onClick={() => onCheck(row.idEcran)} />
                                                                            </td>
                                                                        </tr>
                                                                    ))}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="submit-container">
                                        <button type="submit" className="submit-btn">Enregistrer</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </section>
    );
}

export default AjouterRole;
