import React, { useEffect, useState, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import moment from 'moment/moment';
import { CustomToast } from '../../components/Toast/CustomToast';
import ConfirmationDialog from '../../components/Confirmation/ConfirmationDialog';
import { axiosInstance, ModeleInspection } from '../../services/api';
import { AuthContext } from '../../contexts/AuthContext';

const ListFormulaires = () => {
    const { authData } = useContext(AuthContext);
    const navigate = useNavigate();

    const [formulaires, setFormulaires] = useState([]);
    const [filtredFormulaires, setFiltredFormulaires] = useState([]);
    const [pageNumber, setPageNumber] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalItems, setTotalItems] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [loading, setLoading] = useState(true);
    const [idModeleDelete, setIdModeleDelete] = useState('');

    const [search, setSearch] = useState('');

    const [confirmDialogOpenDelete, setConfirmDialogOpenDelete] = useState(false);
    const [confirmDialogOpenArchive, setConfirmDialogOpenArchive] = useState(false);


    //table management
    const [sortBy, setSortBy] = useState(''); // Column name to sort by
    const [sortOrder, setSortOrder] = useState('asc'); // 'asc' or 'desc'

    const handleSort = (column) => {
        setSortBy(column);
        setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');

        let sortedTable = [...filtredFormulaires].sort((a, b) => {
            const aValue = a[sortBy] || '';
            const bValue = b[sortBy] || '';

            if (sortOrder === 'asc') {
                return aValue.localeCompare(bValue);
            } else {
                return bValue.localeCompare(aValue);
            }
        });

        setFiltredFormulaires(sortedTable);
    };

    const [openAboutList, setOpenAboutList] = useState(null);

    const toggleAboutList = (idVehicule) => {
        setOpenAboutList(openAboutList === idVehicule ? null : idVehicule);
    };

    const handlePageChange = (page) => {
        setPageNumber(page);
        //consolelog("setPageNumber="+pageNumber)
    };

    const handlePerRowsChange = (newPerPage) => {
        setPageSize(newPerPage);
        //console.log("setPageSize="+pageSize);
    };

    //Supprimer modèle
    const deleteFormulaire = async (idModele) => {
        try {
            const response = await axiosInstance.delete(`${ModeleInspection}/${idModele}`);
            getFormulaires();
            CustomToast("Le formulaire d'inspection a été supprimé avec succès", 'success');
        }
        catch (error) {
            if (!error?.response) {
                CustomToast("Aucune réponse du serveur", 'error');
            } else if (error.response?.status === 400 && error.response?.data === "Modele is already used. Cannot delete.") {
                CustomToast("Formulaire d'inspection déjà utilisé", 'error');
            } else if (error.response?.status === 401) {
                CustomToast("Non autorisé", 'error');
            } else {
                CustomToast("Demande échoué", 'error');
            }
            console.log(error);
        }
    }

    const handleConfirmDelete = (idModele) => {
        // Show the modal when the confirm delete button is clicked
        //deleteFormulaire(idModele);
        setIdModeleDelete(idModele);
        setConfirmDialogOpenDelete(true);
    };

    const handleDelete = async () => {
        try {
            const response = await axiosInstance.delete(`${ModeleInspection}/${idModeleDelete}`);
            setIdModeleDelete('');
            CustomToast("Le formulaire d'inspection a été supprimé avec succès", 'success');
            getFormulaires();
            setConfirmDialogOpenDelete(false);
        }
        catch (error) {
            // Handle error
            if (!error?.response) {
                CustomToast("Aucune réponse du serveur", 'error');
            } else if (error.response?.status === 400 && error.response?.data === "Modele is already used. Cannot delete.") {
                CustomToast("Formulaire d'inspection déjà utilisé", 'error');
            } else if (error.response?.status === 401) {
                CustomToast("Non autorisé", 'error');
            } else {
                CustomToast("Demande échoué", 'error');
            }
            console.log(error);
        }
    }

    const handleCancelDelete = () => {
        setConfirmDialogOpenDelete(false);
    };

    //archiver formulaire
    const archiveFormulaire = async (idModele) => {
        try {

            const request = {
                idModele: idModele,
                idUtilisateur: authData.idUser
            }
            const response = await axiosInstance.put(`${ModeleInspection}/archive`, request);
            getFormulaires();
            CustomToast("Le formulaire d'inspection a été archivé avec succès", 'success');
        }
        catch (err) {
            console.log(err);
        }
    }

    const handleConfirmArchive = (idModele) => {
        // Show the modal when the confirm delete button is clicked
        //archiveVehicule(idModele);
        setIdModeleDelete(idModele);
        setConfirmDialogOpenArchive(true);
    };

    const handleArchive = async () => {
        try {
            const request = {
                idModele: idModeleDelete,
                idUtilisateur: authData.idUser
            }
            const response = await axiosInstance.put(`${ModeleInspection}/archive`, request);
            setIdModeleDelete('');
            CustomToast("Le formulaire d'inspection a été archivé avec succès", 'success');
            getFormulaires();
            setConfirmDialogOpenArchive(false);
        }
        catch (error) {
            // Handle error
            if (!error?.response) {
                CustomToast("Aucune réponse du serveur", 'error');
            } else if (error.response?.status === 400 && error.response?.data === "Template does not exist") {
                CustomToast("Formulaire d'inspection n'existe pas", 'error');
            } else if (error.response?.status === 401) {
                CustomToast("Non autorisé", 'error');
            } else {
                CustomToast("Demande échoué", 'error');
            }
            console.log(error);
        }
    }

    const handleCancelArchive = () => {
        setConfirmDialogOpenArchive(false);
    };

    //restaurer formulaire
    const restoreFormulaire = async (idModele) => {
        try {

            const request = {
                idModele: idModele,
                idUtilisateur: authData.idUser
            }
            const response = await axiosInstance.put(`${ModeleInspection}/restore`, request);
            getFormulaires();
            CustomToast("Le formulaire d'inspection a été restauré avec succès", 'success');
        }
        catch (err) {
            console.log(err);
        }
    }

    const handleConfirmRestore = (idModele) => {
        // Show the modal when the confirm delete button is clicked
        restoreFormulaire(idModele);
        setIdModeleDelete(idModele);
    };

    const handleRestore = async () => {
        try {
            const request = {
                idModele: idModeleDelete,
                idUtilisateur: authData.idUser
            }
            const response = await axiosInstance.put(`${ModeleInspection}/restore`, request);
            setIdModeleDelete('');
            CustomToast("Le formulaire d'inspection a été restauré avec succès", 'success');
            getFormulaires();
        }
        catch (error) {
            // Handle error
            if (!error?.response) {
                CustomToast("Aucune réponse du serveur", 'error');
            } else if (error.response?.status === 400 && error.response?.data === "Template does not exist") {
                CustomToast("Formulaire d'inspection n'existe pas", 'error');
            } else if (error.response?.status === 401) {
                CustomToast("Non autorisé", 'error');
            } else {
                CustomToast("Demande échoué", 'error');
            }
            console.log(error);
        }
    }

    //Load data
    const getFormulaires = async () => {
        try {
            setLoading(true);
            const response = await axiosInstance.get(`${ModeleInspection}/GetModeles`);
            const resp = await response.data;
            //setFormulaires(resp);

            //const resp = await response.data;
            setFormulaires(resp.data);
            if (search !== '') {
                const result = formulaires?.filter((item) =>
                    Object.values(item).some(
                        (value) =>
                            value &&
                            value.toString().toLowerCase().includes(search.toLowerCase())
                    )
                );
                setFiltredFormulaires(result);
            } else {
                setFiltredFormulaires(resp);
            }
            setFiltredFormulaires(resp.data);
            setPageNumber(resp.pageNumber);
            setPageSize(resp.pageSize);
            setTotalItems(resp.totalItems);
            setTotalPages(resp.totalPages);
            setLoading(false);
        }
        catch (err) {
            console.log(err);
            setLoading(false);
        }
    }

    useEffect(() => {
        //Load Data
        getFormulaires();
        return () => {
            // Perform any necessary cleanup (e.g., cancel pending requests)
        };
    }, [authData,]);

    useEffect(() => {
        const result = formulaires?.filter((item) =>
            Object.values(item).some(
                (value) =>
                    value &&
                    value.toString().toLowerCase().includes(search.toLowerCase())
            )
        );
        setFiltredFormulaires(result);
    }, [search, formulaires])

    return (
        <section className="inspection-section" onClick={() => { if (openAboutList !== null) { setOpenAboutList(null) } }}>
            <div className="container">
                <div className='formulaireinspection-sec wrap-all'>
                    <Link to="/ajouterformulaire" className="formulaire-add color-primary-2 decoration-none">
                        <div className="add-new" id="add-vehc">
                            <span className="material-symbols-outlined">add</span>
                            Ajouter formulaire d'inspection
                        </div>
                    </Link>
                    {filtredFormulaires?.length > 0 && <><div className="group-elements">
                        <div className="voir-elements">
                            <p></p>
                            <select
                                name="pageSize"
                                id="pageSize"
                                onChange={(e) => { handlePerRowsChange(e.target.value || 10); setPageNumber(1); }}
                            >
                                <option value="10" defaultValue={true}>10</option>
                                <option value="15">15</option>
                                <option value="20">20</option>
                            </select>
                        </div>
                        {/* <div className="search-element">
                            <p>Rechercher</p>
                            <input type="text" />
                        </div> */}
                    </div>
                        <div className="table-wrap">
                            <table>
                                <thead>
                                    <tr>
                                        <th onClick={() => handleSort('titre')}>Titre</th>
                                        <th>Description</th>
                                        <th>Statut</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {filtredFormulaires?.map((vehicule) => (
                                        <tr key={vehicule.idModele}>
                                            <td>{vehicule.titre}</td>
                                            <td>{vehicule.description}</td>
                                            <td>
                                                {vehicule.statutModele === "actif" ? (
                                                    <>
                                                        <svg height="11.87" width="15">
                                                            <circle cx="6" cy="6" r="6" fill="green" />
                                                        </svg>
                                                        Actif
                                                    </>
                                                ) : vehicule.statutModele === "inactif" ? (
                                                    <>
                                                        <svg height="11.87" width="15">
                                                            <circle cx="6" cy="6" r="6" fill="red" />
                                                        </svg>
                                                        Inactif
                                                    </>
                                                ) : vehicule.statutModele === "archivé" ? (
                                                    <>
                                                        <svg height="11.87" width="15">
                                                            <circle cx="6" cy="6" r="6" fill="grey" />
                                                        </svg>
                                                        Archivé
                                                    </>
                                                ) : null}
                                            </td>
                                            <td className="txt-center relative">
                                                <span className="material-symbols-outlined about-btn" onClick={() => toggleAboutList(vehicule.idModele)}>
                                                    more_horiz
                                                </span>
                                                {openAboutList === vehicule.idModele && (
                                                    <div className="about-list">
                                                        <ul>
                                                            <li>
                                                                <Link className='row row-center' to={`/modifierformulaire/${vehicule.idModele}`} style={{ textDecoration: "none" }}>
                                                                    <span className="material-symbols-outlined">edit</span>Modifier
                                                                </Link>
                                                            </li>

                                                            <li>
                                                                <Link className='row row-center' to="/listformulaires" style={{ textDecoration: "none" }} onClick={() => handleConfirmDelete(vehicule.idModele)}>
                                                                    <span className="material-symbols-outlined">delete</span>Supprimer
                                                                </Link>
                                                            </li>

                                                            {(vehicule.statutModele != "archivé") && <li>
                                                                <Link className='row row-center' to="/listformulaires" style={{ textDecoration: "none" }} onClick={() => handleConfirmArchive(vehicule.idModele)}>
                                                                    <span className="material-symbols-outlined">archive</span>Archiver
                                                                </Link>
                                                            </li>}

                                                            {(vehicule.statutModele == "archivé") && <li>
                                                                <Link className='row row-center' to="/listformulaires" style={{ textDecoration: "none" }} onClick={() => handleConfirmRestore(vehicule.idModele)}>
                                                                    <span className="material-symbols-outlined">restore</span>Restaurer
                                                                </Link>
                                                            </li>}
                                                        </ul>
                                                    </div>
                                                )}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        {/* <div className="pagination">
                            <Link to="#" onClick={() => handlePageChange(pageNumber - 1)}
                                className={(pageNumber === 1) ? 'pgn disabled' : 'pgn'}
                                disabled={(pageNumber === 1) ? true : false}
                            >&laquo;</Link>
                            {Array.from({ length: totalPages }, (_, i) => (
                                <Link
                                    key={i + 1}
                                    to="#"
                                    className={i + 1 === pageNumber ? 'pgn active' : 'pgn'}
                                    onClick={() => handlePageChange(i + 1)}
                                >
                                    {i + 1}
                                </Link>
                            ))}
                            <Link to="#" onClick={() => handlePageChange(pageNumber + 1)}
                                className={((pageNumber + 1) > totalPages) ? 'pgn disabled' : 'pgn'}
                                disabled={((pageNumber + 1) > totalPages) ? true : false}
                            >&raquo;</Link>
                        </div> */}
                        <div className="pagination">
                            <Link to="#" onClick={() => handlePageChange(pageNumber - 1)}
                                className={(pageNumber === 1) ? 'pgn disabled' : 'pgn'}
                                disabled={(pageNumber === 1) ? true : false}
                            >&laquo;</Link>
                            {Array.from({ length: Math.min(5, totalPages) }, (_, i) => {
                                const page = pageNumber - 2 + i;
                                return page <= totalPages && page > 0 && (
                                    <Link
                                        key={page}
                                        to="#"
                                        className={pageNumber === page ? 'pgn active' : 'pgn'}
                                        onClick={() => handlePageChange(page)}
                                    >
                                        {page}
                                    </Link>
                                );
                            })}
                            <Link to="#" onClick={() => handlePageChange(pageNumber + 1)}
                                className={(pageNumber === totalPages || totalPages === 0) ? 'pgn disabled' : 'pgn'}
                                disabled={(pageNumber === totalPages || totalPages === 0) ? true : false}
                            >&raquo;</Link>
                            <span> Affichage de {(pageNumber === 1)?1:((pageNumber - 1) * pageSize+1)} à {pageNumber * pageSize} sur {totalItems} entrées</span>
                        </div>
                    </>}

                    <ConfirmationDialog
                        open={confirmDialogOpenDelete}
                        onClose={handleCancelDelete}
                        onConfirm={handleDelete}
                        titleMessage="Confirmer la suppression du formulaire d'inspection"
                        bodyMessage={"Voulez-vous vraiment supprimer ce formulaire d'inspection ? Toutes les données seront définitivement perdues."}
                        btnCancelText="Annuler"
                        btnConfirmText="Supprimer le formulaire d'inspection"
                    />
                    <ConfirmationDialog
                        open={confirmDialogOpenArchive}
                        onClose={handleCancelArchive}
                        onConfirm={handleArchive}
                        titleMessage="Confirmer l'archivage du formulaire d'inspection"
                        bodyMessage={"Voulez-vous vraiment archiver cet enregistrement ? L'archivage d'un enregistrement ne le supprime pas de <i>FleetGo</i>, mais il ne sera plus utilisable."}
                        btnCancelText="Annuler"
                        btnConfirmText="Archiver le formulaire d'inspection"
                    />
                </div>
            </div>
        </section>
    );
}

export default ListFormulaires;
