import React, { useEffect, useState, useContext, useRef } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import moment from 'moment/moment';
import { CustomToast } from '../../components/Toast/CustomToast';
import ConfirmationDialog from '../../components/Confirmation/ConfirmationDialog';
import { axiosInstance, VehiculeAssurance, AssuranceImg, TypeAssuranceVehicule } from '../../services/api';
import { AuthContext } from '../../contexts/AuthContext';

const ListAssurances = () => {
    const { authData } = useContext(AuthContext);
    const navigate = useNavigate();
    const { id } = useParams();//idVehiculeAssurance

    const [assurances, setAssurances] = useState([]);
    const [filtredAssurances, setFiltredAssurances] = useState([]);
    const [typesAssurance, setTypesAssurance] = useState([]);
    const [pageNumber, setPageNumber] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalItems, setTotalItems] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [loading, setLoading] = useState(true);
    const [showAddModal, setShowAddModal] = useState(false);
    const [showUpdateModal, setShowUpdateModal] = useState(false);
    const [idVehiculeAssuranceDelete, setIdVehiculeAssuranceDelete] = useState('');
    const [idVehiculeAssuranceUpdate, setIdVehiculeAssuranceUpdate] = useState('');
    const [pathImageAssurance, setPathImageAssurance] = useState(null);
    const [pathImageAssuranceName, setPathImageAssuranceName] = useState('');
    const pathImageAssuranceRef = useRef(null);

    const [search, setSearch] = useState('');
    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);

    //table management
    const [sortBy, setSortBy] = useState(''); // Column name to sort by
    const [sortOrder, setSortOrder] = useState('asc'); // 'asc' or 'desc'

    const handleSort = (column) => {
        setSortBy(column);
        setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');

        let sortedTable = [...filtredAssurances].sort((a, b) => {
            const aValue = a[sortBy] || '';
            const bValue = b[sortBy] || '';

            if (sortOrder === 'asc') {
                return aValue.localeCompare(bValue);
            } else {
                return bValue.localeCompare(aValue);
            }
        });

        setFiltredAssurances(sortedTable);
    };

    const handlePageChange = (page) => {
        setPageNumber(page);
        //consolelog("setPageNumber="+pageNumber)
        const startIndex = (page - 1) * pageSize;
        const endIndex = startIndex + pageSize;
        const paginatedData = assurances.slice(startIndex, endIndex);

        setFiltredAssurances(paginatedData);

    };

    const handlePerRowsChange = (newPerPage) => {
        setPageSize(newPerPage);
        //console.log("setPageSize="+pageSize);

    };

    const [openAboutList, setOpenAboutList] = useState(null);

    const toggleAboutList = (idVehicule) => {
        setOpenAboutList(openAboutList === idVehicule ? null : idVehicule);
    };

    const showHideAddModal = (val) => {
        setShowAddModal(val);
        setIdVehiculeAssuranceUpdate('');
        setPathImageAssurance(null);
        setPathImageAssuranceName('');
        formik1.resetForm();
        if (val) {
            formik1.values.idVehicule = assurances[0].idVehicule;
            getTypesAssurance();
        }
    };

    const showHideUpdateModal = (val, idVehiculeAssurance, idVehicule, numeroPolice, idTypeAssurance, dateDebut, dateFin, montant, couverture, compagnie, adresse, numTel, email, uniteIntervalleTemps, echeanceTemps, pathImage) => {
        setShowUpdateModal(val);
        setIdVehiculeAssuranceUpdate(idVehiculeAssurance);
        formik2.resetForm();
        setPathImageAssuranceName('');
        if (val) {
            getTypesAssurance();
            // formik2.values.idVehiculeUpdate = idVehicule || '';
            // formik2.values.numeroPoliceUpdate = numeroPolice || '';
            // formik2.values.idTypeAssuranceUpdate = idTypeAssurance || '';
            // formik2.values.dateDebutUpdate = moment(dateDebut).isValid() ? moment(dateDebut).format('YYYY-MM-DD') : '' || '';
            // formik2.values.dateFinUpdate = moment(dateFin).isValid() ? moment(dateFin).format('YYYY-MM-DD') : '' || '';
            // formik2.values.montantUpdate = montant || '';
            // formik2.values.couvertureUpdate = couverture || '';
            // formik2.values.compagnieUpdate = compagnie || '';
            // formik2.values.adresseUpdate = adresse || '';
            // formik2.values.numTelUpdate = numTel || '';
            // formik2.values.emailUpdate = email || '';
            // formik2.values.uniteIntervalleTempsUpdate = uniteIntervalleTemps || 'jour';
            // formik2.values.echeanceTempsUpdate = echeanceTemps == '0' ? '' : echeanceTemps || '';

            formik2.setValues(prevValues => ({
                ...prevValues,
                idVehiculeAssurance: idVehiculeAssurance || '',
                idVehiculeUpdate: idVehicule || '',
                numeroPoliceUpdate: numeroPolice || '',
                idTypeAssuranceUpdate: idTypeAssurance || '',
                dateDebutUpdate: moment(dateDebut).isValid() ? moment(dateDebut).format('YYYY-MM-DD') : '' || '',
                dateFinUpdate: moment(dateFin).isValid() ? moment(dateFin).format('YYYY-MM-DD') : '' || '',
                montantUpdate: montant || '',
                couvertureUpdate: couverture || '',
                compagnieUpdate: compagnie || '',
                adresseUpdate: adresse || '',
                numTelUpdate: numTel || '',
                emailUpdate: email || '',
                uniteIntervalleTempsUpdate: uniteIntervalleTemps || 'jour',
                echeanceTempsUpdate: echeanceTemps || '',
            }));

            setPathImageAssuranceName(pathImage || '');
            //console.log(idVehiculeAssurance, idVehicule, numeroPolice, idTypeAssurance, dateDebut, dateFin, montant, couverture, compagnie, adresse, numTel, email, uniteIntervalleTemps, echeanceTemps, pathImage);
        }
    };

    const handlePathImageAssuranceChange = (e) => {
        setPathImageAssurance(e.target.files[0]);
        setPathImageAssuranceName('');
    };

    const getImageFileName = (file) => {
        return file ? file.name : 'No file selected';
    };

    //Supprimer doddier
    const deleteDossier = async (idVehiculeAssurance) => {
        try {
            const response = await axiosInstance.delete(`${VehiculeAssurance}/${idVehiculeAssurance}`);
            getDossiers();
            CustomToast("Le dossier a été supprimé avec succès", 'success');
        }
        catch (error) {
            // Handle error
            if (!error?.response) {
                CustomToast("Aucune réponse du serveur", 'error');
            } else if (error.response?.status === 401) {
                CustomToast("Non autorisé", 'error');
            } else {
                CustomToast("Demande échoué", 'error');
            }
            console.log(error);
        }
    }

    const handleConfirmDelete = (idVehiculeAssurance) => {
        // Show the modal when the confirm delete button is clicked
        //deleteDossier(idVehiculeAssurance);
        setIdVehiculeAssuranceDelete(idVehiculeAssurance);
        setConfirmDialogOpen(true);
    };

    const handleDelete = async () => {
        try {
            const response = await axiosInstance.delete(`${VehiculeAssurance}/${idVehiculeAssuranceDelete}`);
            //getParcs();
            setIdVehiculeAssuranceDelete('');
            CustomToast("Le dossier a été supprimé avec succès", 'success');
            getDossiers();
            setConfirmDialogOpen(false);
        }
        catch (error) {
            // Handle error
            if (!error?.response) {
                CustomToast("Aucune réponse du serveur", 'error');
            } else if (error.response?.status === 401) {
                CustomToast("Non autorisé", 'error');
            } else {
                CustomToast("Demande échoué", 'error');
            }

            handleCancelDelete();

            console.log(error);
        }
    }

    const handleCancelDelete = () => {
        setConfirmDialogOpen(false);
    };

    //Ajouter/Modifier
    const formik1 = useFormik({
        initialValues: {
            idVehicule: '',
            numeroPolice: '',
            idTypeAssurance: '',
            dateDebut: '',
            dateFin: '',
            montant: '',
            couverture: '',
            compagnie: '',
            adresse: '',
            numTel: '',
            email: '',
            uniteIntervalleTemps: 'jour',
            echeanceTemps: ''
        },
        validationSchema: Yup.object({
            dateDebut: Yup.string()
                .required("Champ obligatoire"),
            dateFin: Yup.string()
                .required("Champ obligatoire"),
            idTypeAssurance: Yup.string()
                .required("Champ obligatoire"),
            email: Yup.string()
                .email("Le champ doit contenir un e-mail valide")
                .max(255, "Le champ doit contenir au maximum 255 caractères"),
            echeanceTemps: Yup.string()
                .required("Champ obligatoire"),
        }),
        onSubmit: async (values) => {
            try {
                //console.log(values);

                const formData = new FormData();

                formData.append('idVehiculeAssurance', "");
                formData.append('numeroPolice', values.numeroPolice);
                formData.append('idVehicule', values.idVehicule);
                formData.append('idTypeAssurance', values.idTypeAssurance);
                formData.append('dateDebut', values.dateDebut);
                formData.append('dateFin', values.dateFin);

                formData.append('montant', values.montant);
                formData.append('couverture', values.couverture);

                formData.append('assureur.compagnie', values.compagnie);
                formData.append('assureur.adresse', values.adresse);
                formData.append('assureur.numTel', values.numTel);
                formData.append('assureur.email', values.email);

                // formData.append('compagnie', values.compagnie);
                // formData.append('adresse', values.adresse);
                // formData.append('numTel', values.numTel);
                // formData.append('email', values.email);

                formData.append('uniteIntervalleTemps', values.uniteIntervalleTemps);
                formData.append('echeanceTemps', values.echeanceTemps);
                formData.append('pathImageAssurance', pathImageAssurance);
                formData.append('idUtilisateurAction', authData.idUser);

                const response = await axiosInstance.post(VehiculeAssurance, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });

                CustomToast("Le dossier a été créé avec succès", 'success');
                getDossiers();
                setShowAddModal(false);
            } catch (error) {
                // Handle error
                if (!error?.response) {
                    CustomToast("Aucune réponse du serveur", 'error');
                } else if (error.response?.status === 400 && error.response?.data === "Overlap existing insurance") {
                    CustomToast("Overlap existing insurance", 'error');
                } else if (error.response?.status === 401) {
                    CustomToast("Non autorisé", 'error');
                } else {
                    CustomToast("Demande échoué", 'error');
                }
                console.log(error);
            }
        }
    });

    const formik2 = useFormik({
        initialValues: {
            idVehiculeAssurance: '',
            idVehiculeUpdate: '',
            numeroPoliceUpdate: '',
            idTypeAssuranceUpdate: '',
            dateDebutUpdate: '',
            dateFinUpdate: '',
            montantUpdate: '',
            couvertureUpdate: '',
            compagnieUpdate: '',
            adresseUpdate: '',
            numTelUpdate: '',
            emailUpdate: '',
            uniteIntervalleTempsUpdate: 'jour',
            echeanceTempsUpdate: ''
        },
        validationSchema: Yup.object({
            dateDebutUpdate: Yup.string()
                .required("Champ obligatoire"),
            dateFinUpdate: Yup.string()
                .required("Champ obligatoire"),
            idTypeAssuranceUpdate: Yup.string()
                .required("Champ obligatoire"),
            emailUpdate: Yup.string()
                .email("Le champ doit contenir un e-mail valide")
                .max(255, "Le champ doit contenir au maximum 255 caractères"),
            echeanceTempsUpdate: Yup.string()
                .required("Champ obligatoire"),
        }),
        onSubmit: async (values) => {
            try {
                //console.log(values);
                const formData = new FormData();

                formData.append('idVehiculeAssurance', values.idVehiculeAssurance);
                formData.append('numeroPolice', values.numeroPoliceUpdate);
                formData.append('idVehicule', values.idVehiculeUpdate);
                formData.append('idTypeAssurance', values.idTypeAssuranceUpdate);
                formData.append('dateDebut', values.dateDebutUpdate);
                formData.append('dateFin', values.dateFinUpdate);

                formData.append('montant', values.montantUpdate);
                formData.append('couverture', values.couvertureUpdate);

                formData.append('assureur.compagnie', values.compagnieUpdate);
                formData.append('assureur.adresse', values.adresseUpdate);
                formData.append('assureur.numTel', values.numTelUpdate);
                formData.append('assureur.email', values.emailUpdate);

                // formData.append('compagnie', values.compagnieUpdate);
                // formData.append('adresse', values.adresseUpdate);
                // formData.append('numTel', values.numTelUpdate);
                // formData.append('email', values.emailUpdate);

                formData.append('uniteIntervalleTemps', values.uniteIntervalleTempsUpdate);
                formData.append('echeanceTemps', values.echeanceTempsUpdate);
                formData.append('pathImageAssurance', pathImageAssurance);
                formData.append('pathImageAssuranceName', pathImageAssuranceName);
                formData.append('idUtilisateurAction', authData.idUserUpdate);

                const response = await axiosInstance.put(VehiculeAssurance, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });

                // //console.log(postData);               
                CustomToast("Le dossier a été modifié avec succès", 'success');
                showHideUpdateModal(false, '', '', '', '', '', '', '', '', '', '', '', '', '', '', '',);
                getDossiers();
            } catch (error) {
                // Handle error
                if (!error?.response) {
                    CustomToast("Aucune réponse du serveur", 'error');
                } else if (error.response?.status === 400 && error.response?.data === "Overlap existing insurance") {
                    CustomToast("Overlap existing insurance", 'error');
                } else if (error.response?.status === 400 && error.response?.data === "Insurance not found") {
                    CustomToast("Insurance not found", 'error');
                } else if (error.response?.status === 401) {
                    CustomToast("Non autorisé", 'error');
                } else {
                    CustomToast("Demande échoué", 'error');
                }
                console.log(error);
            }
        }
    });

    //Load data

    const getDossiers = async () => {
        try {
            const response = await axiosInstance.get(`${VehiculeAssurance}/GetAssuranceVehicule/${id}`);
            const resp = await response.data;

            setAssurances(resp);
            if (search != '') {
                const result = assurances?.filter((item) =>
                    Object.values(item).some(
                        (value) =>
                            value &&
                            value.toString().toLowerCase().includes(search.toLowerCase())
                    )
                );
                setFiltredAssurances(result);
            } else {
                setFiltredAssurances(resp);
            }

            setLoading(false);
        }
        catch (err) {
            setLoading(false);
            console.log(err);
        }
    }

    const getTypesAssurance = async () => {
        try {
            const response = await axiosInstance.get(`${TypeAssuranceVehicule}`);
            const resp = await response.data;
            setTypesAssurance(resp);
        }
        catch (err) {
            console.log(err);
        }
    }

    useEffect(() => {
        //Load Data
        getDossiers();
        return () => {
            // Perform any necessary cleanup (e.g., cancel pending requests)
        };
    }, []);

    useEffect(() => {
        const result = assurances?.filter((item) =>
            Object.values(item).some(
                (value) =>
                    value &&
                    value.toString().toLowerCase().includes(search.toLowerCase())
            )
        );
        setFiltredAssurances(result);
    }, [search, assurances])

    return (
        <section className="vehicles-section" onClick={() => { if (openAboutList !== null) { setOpenAboutList(null) } }}>
            <div className="container">
                <div className="vehicule-sec-assr-about wrap-all">
                    <div className="row row-center w-full">
                        <Link to="/listvehicules" className="retour cursurer" style={{ textDecoration: "none" }}>
                            <div className="arrow-back">
                                <span className="material-symbols-outlined" id="back-assurance">arrow_back</span>
                            </div>
                        </Link>

                        <Link to="#" style={{ textDecoration: "none" }} onClick={() => showHideAddModal(true)} className="color-primary-2 decoration-none">
                            <div className="add-new" id="btn-add-assrance">
                                Ajouter
                                <span className="material-symbols-outlined success">add</span>
                            </div>
                        </Link>
                    </div>

                    <div className="container grid grid--3-cols grid--2-rows">
                        <div className="card grid grid--grid-area-1">
                            <div className="column spec-selec">
                                <div className="row row-center">
                                    <h3 className="heading-primary">Nom</h3>
                                    <p>{assurances[0]?.vehiculeNom}</p>
                                </div>
                                <div className="row row-center">
                                    <h3 className="heading-primary">Marque</h3>
                                    <p>{assurances[0]?.vehiculeMarque}</p>
                                </div>
                                <div className="row row-center">
                                    <h3 className="heading-primary">Modèle</h3>
                                    <p>{assurances[0]?.vehiculeModele}</p>
                                </div>
                                <div className="row row-center">
                                    <h3 className="heading-primary">Matricule</h3>
                                    <p>{assurances[0]?.vehiculeImmatriculation}</p>
                                </div>
                                <div className="row row-center">
                                    <h3 className="heading-primary">Statut</h3>
                                    <p>
                                        {(() => {
                                            if (assurances[0]?.vehiculeStatut === "disponible") {
                                                return <><svg height="11.87" width="15"><circle cx="6" cy="6" r="6" fill="green" /></svg>Disponible</>;
                                            } else if (assurances[0]?.vehiculeStatut === "indisponible") {
                                                return <><svg height="11.87" width="15"><circle cx="6" cy="6" r="6" fill="orangered" /></svg>Indisponible</>;
                                            } else if (assurances[0]?.vehiculeStatut === "archivé") {
                                                return <><svg height="11.87" width="15"><circle cx="6" cy="6" r="6" fill="grey" /></svg>Archivé</>;
                                            } else if (assurances[0]?.vehiculeStatut === "vendu") {
                                                return <><svg height="11.87" width="15"><circle cx="6" cy="6" r="6" fill="blue" /></svg>Vendu</>;
                                            } else if (assurances[0]?.vehiculeStatut === "en cours d'utilisation") {
                                                return <><svg height="11.87" width="15"><circle cx="6" cy="6" r="6" fill="yellow" /></svg>En cours</>;
                                            } else if (assurances[0]?.vehiculeStatut === "hors service") {
                                                return <><svg height="11.87" width="15"><circle cx="6" cy="6" r="6" fill="red" /></svg>Hors service</>;
                                            } else {
                                                return "";
                                            }
                                        })()}
                                    </p>
                                </div>
                                <div className="row row-center">
                                    <h3 className="heading-primary">Type</h3>
                                    <p>{assurances[0]?.vehiculeType}</p>
                                </div>
                            </div>
                        </div>
                        <div className="card grid grid--grid-area-2">
                            <div className="table-wrap">
                                {filtredAssurances?.length > 0 &&
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>Date début de couverture</th>
                                                <th>Date fin de couverture</th>
                                                <th>Couverture</th>
                                                <th>Compagnie d'assurance</th>
                                                <th>Statut</th>
                                                <th>Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {filtredAssurances?.map((row) => (
                                                <tr key={row.idVehiculeAssurance}>
                                                    <td>{row.dateDebut ? (moment(row.dateDebut).format('DD-MM-YYYY')) : ''}</td>
                                                    <td>{row.dateFin ? (moment(row.dateFin).format('DD-MM-YYYY')) : ''}</td>
                                                    <td>{row.couverture}</td>
                                                    <td>{row.assureur?.compagnie}</td>
                                                    <td>
                                                        {
                                                            (() => {
                                                                switch (row.statut) {
                                                                    case "actif":
                                                                        return <><svg height="11.87" width="15"><circle cx="6" cy="6" r="6" fill="green" /></svg>Actif</>;
                                                                    case "expiré":
                                                                        return <><svg height="11.87" width="15"><circle cx="6" cy="6" r="6" fill="red" /></svg>Expiré</>;
                                                                    case "bientot expiré":
                                                                        return <><svg height="11.87" width="15"><circle cx="6" cy="6" r="6" fill="orange" /></svg>Bientôt expiré</>;
                                                                    case "a venir":
                                                                        return <><svg height="11.87" width="15"><circle cx="6" cy="6" r="6" fill="blue" /></svg>A venir</>;
                                                                    default:
                                                                        return <></>;
                                                                }
                                                            })()
                                                        }
                                                    </td>
                                                    <td className="txt-center relative">
                                                        <span className="material-symbols-outlined about-btn" onClick={() => toggleAboutList(row.idVehiculeAssurance)}>
                                                            more_horiz
                                                        </span>
                                                        {openAboutList === row.idVehiculeAssurance && (
                                                            <div className="about-list">
                                                                <ul>
                                                                    <li>
                                                                        <Link to="#" style={{ textDecoration: "none" }} onClick={() => showHideUpdateModal(true, row.idVehiculeAssurance, row.idVehicule, row.numeroPolice, row.idTypeAssurance, row.dateDebut, row.dateFin, row.montant, row.couverture, row.assureur?.compagnie, row.assureur?.adresse, row.assureur?.numTel, row.assureur?.email, row.uniteIntervalleTemps, row.echeanceTemps, row.pathImageAssuranceName)} className='row row-center'>
                                                                            <span className="material-symbols-outlined">edit</span>Modifier
                                                                        </Link>
                                                                    </li>
                                                                    <li>
                                                                        <Link to="#" style={{ textDecoration: "none" }} onClick={() => handleConfirmDelete(row.idVehiculeAssurance)} className='row row-center'>
                                                                            <span className="material-symbols-outlined">delete</span>Supprimer
                                                                        </Link>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        )}
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                }
                            </div>
                        </div>
                    </div>
                    {showAddModal && <div className="popcard-add">
                        <div className="popcard-add_inside">
                            <Link className="popcard-add_inside-close" to="#" onClick={() => showHideAddModal(false)}>&#10006;</Link>
                            <h2 className="popcard-add_inside-title"></h2>
                            <div className="popcard-add_inside-form">
                                <form onSubmit={formik1.handleSubmit}>
                                    <div className="wrap-info">
                                        <div className="wrap-row">
                                            <div className="wrap-one">
                                                <label >Numéro de police</label>
                                                <input
                                                    id="numeroPolice"
                                                    name="numeroPolice"
                                                    type="text"
                                                    value={formik1.values.numeroPolice}
                                                    onChange={formik1.handleChange}
                                                />
                                            </div>
                                            <div className="wrap-one">
                                                <label >Type d'assurance<span className='requiredField'>*</span></label>
                                                <select
                                                    id="idTypeAssurance"
                                                    name="idTypeAssurance"
                                                    value={formik1.values.idTypeAssurance}
                                                    onChange={formik1.handleChange}
                                                    style={(formik1.touched.idTypeAssurance && formik1.errors.idTypeAssurance) ? { borderColor: "red" } : null}
                                                >
                                                    <option value="">Veuillez sélectionner</option>
                                                    {typesAssurance.map((typeAssurance) => (
                                                        <option key={typeAssurance.idTypeAssurance} value={typeAssurance.idTypeAssurance}>{typeAssurance.libelleTypeAssurance}</option>
                                                    ))}
                                                </select>
                                                {formik1.touched.idTypeAssurance && formik1.errors.idTypeAssurance ? (
                                                    <div className="invalid-feedback">{formik1.errors.idTypeAssurance}</div>
                                                ) : null}
                                            </div>
                                        </div>
                                        <div className="wrap-row">
                                            <div className="wrap-one">
                                                <label >Date début de couverture<span className='requiredField'>*</span></label>
                                                <input
                                                    type="date"
                                                    id="dateDebut"
                                                    name="dateDebut"
                                                    style={(formik1.touched.dateDebut && formik1.errors.dateDebut) ? { borderColor: "red" } : null}
                                                    placeholder=""
                                                    defaultValue={formik1.values.dateDebut}
                                                    onChange={formik1.handleChange}
                                                />
                                                {formik1.touched.dateDebut && formik1.errors.dateDebut ? (
                                                    <div className="invalid-feedback">{formik1.errors.dateDebut}</div>
                                                ) : null}
                                            </div>
                                            <div className="wrap-one">
                                                <label >Date fin de couverture<span className='requiredField'>*</span></label>
                                                <input
                                                    type="date"
                                                    id="dateFin"
                                                    name="dateFin"
                                                    style={(formik1.touched.dateFin && formik1.errors.dateFin) ? { borderColor: "red" } : null}
                                                    placeholder=""
                                                    defaultValue={formik1.values.dateFin}
                                                    onChange={formik1.handleChange}
                                                />
                                                {formik1.touched.dateFin && formik1.errors.dateFin ? (
                                                    <div className="invalid-feedback">{formik1.errors.dateFin}</div>
                                                ) : null}
                                            </div>
                                        </div>
                                        <div className="wrap-row">
                                            <div className="wrap-one" title="Le temps à l'avance pendant lequel vous considérez le rappel comme étant '' à échéance prochaine''">
                                                <label >Seuil d'échéance prochaine<span className='requiredField'>*</span></label>
                                                <input
                                                    type="number"
                                                    id="echeanceTemps"
                                                    name="echeanceTemps"
                                                    style={(formik1.touched.echeanceTemps && formik1.errors.echeanceTemps) ? { borderColor: "red" } : null}
                                                    placeholder=""
                                                    defaultValue={formik1.values.echeanceTemps}
                                                    onChange={formik1.handleChange}
                                                />
                                                {formik1.touched.echeanceTemps && formik1.errors.echeanceTemps ? (
                                                    <div className="invalid-feedback">{formik1.errors.echeanceTemps}</div>
                                                ) : null}
                                            </div>
                                            <div className='wrap-one'>
                                                <label style={{ opacity: 0 }}>unité</label>
                                                <select
                                                    id="uniteIntervalleTemps"
                                                    name="uniteIntervalleTemps"
                                                    value={formik1.values.uniteIntervalleTemps}
                                                    style={(formik1.touched.echeanceTemps && formik1.errors.echeanceTemps) ? { borderColor: "red" } : null}
                                                    onChange={formik1.handleChange}
                                                >
                                                    <option value="jour" defaultValue={true}>Jour(s)</option>
                                                    <option value="mois">Mois</option>
                                                </select>
                                                {formik1.touched.echeanceTemps && formik1.errors.echeanceTemps ? (
                                                    <div className="invalid-feedback">{formik1.errors.echeanceTemps}</div>
                                                ) : null}
                                            </div>
                                            <div className="wrap-one">
                                                <label >Montant</label>
                                                <input
                                                    id="montant"
                                                    name="montant"
                                                    type="number"
                                                    value={formik1.values.montant}
                                                    onChange={formik1.handleChange}
                                                    step="any"
                                                />
                                            </div>
                                        </div>
                                        <div className="wrap-row">
                                            <div className="wrap-one">
                                                <label >Couverture</label>
                                                <input
                                                    id="couverture"
                                                    name="couverture"
                                                    type="text"
                                                    value={formik1.values.couverture}
                                                    onChange={formik1.handleChange}
                                                />
                                            </div>
                                            <div className="wrap-one">
                                                <label >Compagnie d'assurance</label>
                                                <input
                                                    id="compagnie"
                                                    name="compagnie"
                                                    type="text"
                                                    value={formik1.values.compagnie}
                                                    onChange={formik1.handleChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="wrap-row">
                                            <div className="wrap-one">
                                                <label >Adresse</label>
                                                <input
                                                    id="adresse"
                                                    name="adresse"
                                                    type="text"
                                                    value={formik1.values.adresse}
                                                    onChange={formik1.handleChange}
                                                />
                                            </div>
                                            <div className="wrap-one">
                                                <label >Email</label>
                                                <input type="email"
                                                    id="email"
                                                    name="email"
                                                    style={(formik1.touched.email && formik1.errors.email) ? { borderColor: "red" } : null}
                                                    placeholder=""
                                                    defaultValue={formik1.values.email}
                                                    onChange={formik1.handleChange}
                                                />
                                                {formik1.touched.email && formik1.errors.email ? (
                                                    <div className="invalid-feedback">{formik1.errors.email}</div>
                                                ) : null}
                                            </div>
                                            <div className="wrap-one">
                                                <label >N° de téléphone</label>
                                                <input
                                                    id="numTel"
                                                    name="numTel"
                                                    type="text"
                                                    value={formik1.values.numTel}
                                                    onChange={formik1.handleChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="">
                                            <div className="wrap-one">
                                                <label >Document</label>
                                                <label className="upload-file">
                                                    upload file
                                                    <input
                                                        ref={pathImageAssuranceRef}
                                                        id="pathImageAssurance"
                                                        name="pathImageAssurance"
                                                        type="file"
                                                        onChange={handlePathImageAssuranceChange}
                                                    />
                                                </label>
                                                {pathImageAssurance && <span style={{ fontSize: "10px" }}>{getImageFileName(pathImageAssurance)}
                                                    <span className="material-symbols-outlined" onClick={(e) => { setPathImageAssurance(null); if (pathImageAssuranceRef.current) { pathImageAssuranceRef.current.value = ''; } }}>delete</span>
                                                </span>}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="popcard-operation-pickup-details_inside-zone">
                                        <div className="buttons">
                                            <input type="submit" value="Enregistrer" className="submit-btn" />
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>}

                    {showUpdateModal && <div className="popcard-add">
                        <div className="popcard-add_inside">
                            <Link className="popcard-add_inside-close" to="#" onClick={() => showHideUpdateModal(false, '', '', '', '', '', '', '', '', '', '', '', '', '', '', '')}>&#10006;</Link>
                            <h2 className="popcard-add_inside-title"></h2>
                            <div className="popcard-add_inside-form">
                                <form onSubmit={formik2.handleSubmit}>
                                    <div className="wrap-info">
                                        <div className="wrap-row">
                                            <div className="wrap-one">
                                                <label >Numéro de police</label>
                                                <input
                                                    id="numeroPoliceUpdate"
                                                    name="numeroPoliceUpdate"
                                                    type="text"
                                                    value={formik2.values.numeroPoliceUpdate}
                                                    onChange={formik2.handleChange}
                                                />
                                            </div>
                                            <div className="wrap-one">
                                                <label >Type d'assurance<span className='requiredField'>*</span></label>
                                                <select
                                                    id="idTypeAssuranceUpdate"
                                                    name="idTypeAssuranceUpdate"
                                                    value={formik2.values.idTypeAssuranceUpdate}
                                                    onChange={formik2.handleChange}
                                                    style={(formik2.touched.idTypeAssuranceUpdate && formik2.errors.idTypeAssuranceUpdate) ? { borderColor: "red" } : null}
                                                >
                                                    <option value="">Veuillez sélectionner</option>
                                                    {typesAssurance.map((typeAssurance) => (
                                                        <option key={typeAssurance.idTypeAssurance} value={typeAssurance.idTypeAssurance}>{typeAssurance.libelleTypeAssurance}</option>
                                                    ))}
                                                </select>
                                                {formik2.touched.idTypeAssuranceUpdate && formik2.errors.idTypeAssuranceUpdate ? (
                                                    <div className="invalid-feedback">{formik2.errors.idTypeAssuranceUpdate}</div>
                                                ) : null}
                                            </div>
                                        </div>
                                        <div className="wrap-row">
                                            <div className="wrap-one">
                                                <label >Date début de couverture<span className='requiredField'>*</span></label>
                                                <input
                                                    type="date"
                                                    id="dateDebutUpdate"
                                                    name="dateDebutUpdate"
                                                    style={(formik2.touched.dateDebutUpdate && formik2.errors.dateDebutUpdate) ? { borderColor: "red" } : null}
                                                    placeholder=""
                                                    defaultValue={formik2.values.dateDebutUpdate}
                                                    onChange={formik2.handleChange}
                                                />
                                                {formik2.touched.dateDebutUpdate && formik2.errors.dateDebutUpdate ? (
                                                    <div className="invalid-feedback">{formik2.errors.dateDebutUpdate}</div>
                                                ) : null}
                                            </div>
                                            <div className="wrap-one">
                                                <label >Date fin de couverture<span className='requiredField'>*</span></label>
                                                <input
                                                    type="date"
                                                    id="dateFinUpdate"
                                                    name="dateFinUpdate"
                                                    style={(formik2.touched.dateFinUpdate && formik2.errors.dateFinUpdate) ? { borderColor: "red" } : null}
                                                    placeholder=""
                                                    defaultValue={formik2.values.dateFinUpdate}
                                                    onChange={formik2.handleChange}
                                                />
                                                {formik2.touched.dateFinUpdate && formik2.errors.dateFinUpdate ? (
                                                    <div className="invalid-feedback">{formik2.errors.dateFinUpdate}</div>
                                                ) : null}
                                            </div>
                                        </div>
                                        <div className="wrap-row">
                                            <div className="wrap-one" title="Le temps à l'avance pendant lequel vous considérez le rappel comme étant '' à échéance prochaine''">
                                                <label >Seuil d'échéance prochaine<span className='requiredField'>*</span></label>
                                                <input
                                                    type="number"
                                                    id="echeanceTempsUpdate"
                                                    name="echeanceTempsUpdate"
                                                    style={(formik2.touched.echeanceTempsUpdate && formik2.errors.echeanceTempsUpdate) ? { borderColor: "red" } : null}
                                                    placeholder=""
                                                    defaultValue={formik2.values.echeanceTempsUpdate}
                                                    onChange={formik2.handleChange}
                                                />
                                                {formik2.touched.echeanceTempsUpdate && formik2.errors.echeanceTempsUpdate ? (
                                                    <div className="invalid-feedback">{formik2.errors.echeanceTempsUpdate}</div>
                                                ) : null}
                                            </div>
                                            <div className='wrap-one'>
                                                <label style={{ opacity: 0 }}>unité</label>
                                                <select
                                                    id="uniteIntervalleTempsUpdate"
                                                    name="uniteIntervalleTempsUpdate"
                                                    value={formik2.values.uniteIntervalleTempsUpdate}
                                                    style={(formik2.touched.echeanceTempsUpdate && formik2.errors.echeanceTempsUpdate) ? { borderColor: "red" } : null}
                                                    onChange={formik2.handleChange}
                                                >
                                                    <option value="jour" defaultValue={true}>Jour(s)</option>
                                                    <option value="mois">Mois</option>
                                                </select>
                                                {formik2.touched.echeanceTempsUpdate && formik2.errors.echeanceTempsUpdate ? (
                                                    <div className="invalid-feedback">{formik2.errors.echeanceTempsUpdate}</div>
                                                ) : null}
                                            </div>
                                            <div className="wrap-one">
                                                <label >Montant</label>
                                                <input
                                                    id="montantUpdate"
                                                    name="montantUpdate"
                                                    type="number"
                                                    value={formik2.values.montantUpdate}
                                                    onChange={formik2.handleChange}
                                                    step="any"
                                                />
                                            </div>
                                        </div>
                                        <div className="wrap-row">
                                            <div className="wrap-one">
                                                <label >Couverture</label>
                                                <input
                                                    id="couvertureUpdate"
                                                    name="couvertureUpdate"
                                                    type="text"
                                                    value={formik2.values.couvertureUpdate}
                                                    onChange={formik2.handleChange}
                                                />
                                            </div>
                                            <div className="wrap-one">
                                                <label >Compagnie d'assurance</label>
                                                <input
                                                    id="compagnieUpdate"
                                                    name="compagnieUpdate"
                                                    type="text"
                                                    value={formik2.values.compagnieUpdate}
                                                    onChange={formik2.handleChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="wrap-row">
                                            <div className="wrap-one">
                                                <label >Adresse</label>
                                                <input
                                                    id="adresseUpdate"
                                                    name="adresseUpdate"
                                                    type="text"
                                                    value={formik2.values.adresseUpdate}
                                                    onChange={formik2.handleChange}
                                                />
                                            </div>
                                            <div className="wrap-one">
                                                <label >Email</label>
                                                <input type="email"
                                                    id="emailUpdate"
                                                    name="emailUpdate"
                                                    style={(formik2.touched.emailUpdate && formik2.errors.emailUpdate) ? { borderColor: "red" } : null}
                                                    placeholder=""
                                                    defaultValue={formik2.values.emailUpdate}
                                                    onChange={formik2.handleChange}
                                                />
                                                {formik2.touched.emailUpdate && formik2.errors.emailUpdate ? (
                                                    <div className="invalid-feedback">{formik2.errors.emailUpdate}</div>
                                                ) : null}
                                            </div>
                                            <div className="wrap-one">
                                                <label >N° de téléphone</label>
                                                <input
                                                    id="numTelUpdate"
                                                    name="numTelUpdate"
                                                    type="text"
                                                    value={formik2.values.numTelUpdate}
                                                    onChange={formik2.handleChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="">
                                            <div className="wrap-one">
                                                <label >Document</label>
                                                <label className="upload-file">
                                                    upload file
                                                    <input
                                                        ref={pathImageAssuranceRef}
                                                        id="pathImageAssuranceUpdate"
                                                        name="pathImageAssuranceUpdate"
                                                        type="file"
                                                        onChange={handlePathImageAssuranceChange}
                                                    />
                                                </label>
                                                {pathImageAssurance && <span style={{ fontSize: "10px" }}>{getImageFileName(pathImageAssurance)}
                                                    <span className="material-symbols-outlined" onClick={(e) => { setPathImageAssurance(null); if (pathImageAssuranceRef.current) { pathImageAssuranceRef.current.value = ''; } }}>delete</span>
                                                </span>}
                                                {pathImageAssuranceName && <span style={{ fontSize: "10px" }}>{pathImageAssuranceName}
                                                    <span className="material-symbols-outlined" onClick={(e) => { setPathImageAssurance(null); setPathImageAssuranceName(''); if (pathImageAssuranceRef.current) { pathImageAssuranceRef.current.value = ''; } }}>delete</span>
                                                </span>}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="popcard-operation-pickup-details_inside-zone">
                                        <div className="buttons">
                                            <input type="submit" value="Enregistrer" className="submit-btn" />
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>}

                    <ConfirmationDialog
                        open={confirmDialogOpen}
                        onClose={handleCancelDelete}
                        onConfirm={handleDelete}
                        titleMessage="Confirmer la suppression du dossier"
                        bodyMessage="Voulez-vous vraiment supprimer ce dossier? Toutes les données seront définitivement perdues."
                        btnCancelText="Annuler"
                        btnConfirmText="Supprimer le dossier"
                    />

                </div>
            </div>
        </section>
    );
}

export default ListAssurances;
