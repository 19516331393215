import React, { useEffect, useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment/moment';
import Select from "react-select";
import { CustomToast } from '../../components/Toast/CustomToast';
import { formatCurrency } from '../../utils/Utils';
import useDebounce from '../../hooks/useDebounce';
import ConfirmationDialog from '../../components/Confirmation/ConfirmationDialog';
import { axiosInstance, VehiculeDepense, TypeDepense, Vehicule, Fournisseur } from '../../services/api';
import { AuthContext } from '../../contexts/AuthContext';

const ListDepenses = () => {
    const { authData } = useContext(AuthContext);

    const [depenses, setDepenses] = useState([]);
    const [typesDepenses, setTypesDepenses] = useState([]);
    const [vehicules, setVehicules] = useState([]);
    const [fournisseurs, setFournisseurs] = useState([]);

    const [filtredDepenses, setFiltredDepenses] = useState([]);
    const [pageNumber, setPageNumber] = useState(1);//Definit par user
    const [pageSize, setPageSize] = useState(10);//Definit par user
    const [totalItems, setTotalItems] = useState(0);//Calculé dans BackEnd: nombre totale d'éléments
    const [totalPages, setTotalPages] = useState(0);//Calculé dans BackEnd
    const [loading, setLoading] = useState(true);
    const [idDepenseDelete, setIdDepenseDelete] = useState('');

    const [selectedVehicle, setSelectedVehicle] = useState('');
    const [selectedVehicleId, setSelectedVehicleId] = useState('');

    const [confirmDialogOpenDelete, setConfirmDialogOpenDelete] = useState(false);

    const [filters, setFilters] = useState({
        idTypeDepense: '',
        idVehicule: '',
        vehicule: '',
        idFournisseur: '',
        dateDepenseDebut: moment("00", "hh").add(-4, 'days').format('YYYY-MM-DD'),
        dateDepenseFin: moment("00", "hh").add(3, 'days').format('YYYY-MM-DD')
    });

    const [search, setSearch] = useState("");

    const [enteredVehicleText, setEnteredVehicleText] = useState('');
    const debouncedVehiculeText = useDebounce(enteredVehicleText, 300);

    //table management
    const [sortBy, setSortBy] = useState(''); // Column name to sort by
    const [sortOrder, setSortOrder] = useState('asc'); // 'asc' or 'desc'

    const handleSort = (column) => {
        setSortBy(column);
        setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');

        let sortedTable = [...filtredDepenses].sort((a, b) => {
            const aValue = a[sortBy] || '';
            const bValue = b[sortBy] || '';

            if (sortOrder === 'asc') {
                return aValue.localeCompare(bValue);
            } else {
                return bValue.localeCompare(aValue);
            }
        });

        setFiltredDepenses(sortedTable);
    };

    const [openAboutList, setOpenAboutList] = useState(null);

    const toggleAboutList = (idVehicule) => {
        setOpenAboutList(openAboutList === idVehicule ? null : idVehicule);
    };

    const handlePageChange = (page) => {
        setPageNumber(page);
        //consolelog("setPageNumber="+pageNumber)
    };

    const handlePerRowsChange = (newPerPage) => {
        setPageSize(newPerPage);
        //console.log("setPageSize="+pageSize);
    };

    const handleVehicleChange = (selectedOption) => {
        setSelectedVehicle(selectedOption);
        setSelectedVehicleId(selectedOption ? selectedOption.idVehicule : '');
        filters.idVehicule = selectedVehicleId;
        filters.vehicule = selectedOption;

        const updatedFilters = { ...filters, idVehicule: selectedOption ? selectedOption.idVehicule : '' };
        setFilters(updatedFilters);
    };

    //Load data

    const getTypesDepenses = async () => {
        try {
            const response = await axiosInstance.get(`${TypeDepense}`);
            const resp = await response.data;
            setTypesDepenses(resp);
        }
        catch (err) {
            console.log(err);
        }
    }

    const getDepenses = async (page, newPerPage) => {
        try {
            setLoading(true);

            const queryParams = new URLSearchParams({
                pageNumber: page,
                pageSize: newPerPage
            });

            if (authData?.wilayas != null) {
                const wilayasFilter = authData?.wilayas
                queryParams.append("wilayas", wilayasFilter.join(','));
            }
            else {
                setLoading(false);
                return
            }

            if (authData?.flottesAutorises != null) {
                const flottesAutorisesFilter = authData?.flottesAutorises
                queryParams.append("flottesAutorises", flottesAutorisesFilter.join(','));
            }
            else {
                setLoading(false);
                return
            }

            if (filters.idTypeDepense !== '') {
                queryParams.append("idTypeDepense", filters.idTypeDepense);
            }

            if (filters.idVehicule !== '') {
                queryParams.append("idVehicule", filters.idVehicule);
            }

            if (filters.idFournisseur !== '') {
                queryParams.append("idVehicule", filters.idFournisseur);
            }

            if (filters.dateDepenseDebut !== '') {
                queryParams.append("dateDepenseDebut", filters.dateDepenseDebut);
            }

            if (filters.dateDepenseFin !== '') {
                queryParams.append("dateDepenseFin", filters.dateDepenseFin);
            }

            const response = await axiosInstance.get(`${VehiculeDepense}?${queryParams}`);
            const resp = await response.data;
            setDepenses(resp.data);
            setFiltredDepenses(resp.data);
            setPageNumber(resp.pageNumber);
            setPageSize(resp.pageSize);
            setTotalItems(resp.totalItems);
            setTotalPages(resp.totalPages);
            setLoading(false);
        }
        catch (err) {
            console.log(err);
            setLoading(false);
        }
    }

    const getVehicules = async (nomVehicule) => {
        try {
            const queryParams = new URLSearchParams({
                pageNumber: 1,
                pageSize: 10
            });

            if (authData?.wilayas != null) {
                const wilayasFilter = authData?.wilayas
                queryParams.append("wilayas", wilayasFilter.join(','));
            }
            else {
                return
            }

            if (authData?.flottesAutorises != null) {
                const flottesAutorisesFilter = authData?.flottesAutorises
                queryParams.append("flottesAutorises", flottesAutorisesFilter.join(','));
            }
            else {
                return
            }

            if (nomVehicule !== '') {
                queryParams.append("nom", nomVehicule);
            }
            const response = await axiosInstance.get(`${Vehicule}/GetVehiculesAutocomplete?${queryParams}`);
            const resp = await response.data;
            setVehicules(resp.data);
        }
        catch (err) {
            console.log(err);
        }
    }

    const getFournisseurs = async () => {
        try {

            const response = await axiosInstance.get(`${Fournisseur}/GetFournisseurType`);
            const resp = await response.data;
            setFournisseurs(resp);
        }
        catch (err) {
            console.log(err);
        }
    }

    //Supprimer une dépense
    const handleConfirmDelete = (idDepense) => {
        // Show the modal when the confirm delete button is clicked
        //deleteDepense(idDepense);
        setIdDepenseDelete(idDepense);
        setConfirmDialogOpenDelete(true);
    };

    const handleDelete = async () => {
        try {
            await axiosInstance.delete(`${VehiculeDepense}/${idDepenseDelete}`);
            CustomToast("L'entrée de dépenses a été supprimée avec succès", 'success');
            setIdDepenseDelete('');
            setConfirmDialogOpenDelete(false);
            getDepenses(pageNumber, pageSize);
        }
        catch (error) {
            // Handle error
            console.log(error);
        }
    }

    const handleCancelDelete = () => {
        setConfirmDialogOpenDelete(false);
    };

    //Search
    const handleSearch = (e) => {
        e.preventDefault();
        setPageNumber(1); // Reset page number when new search is triggered
        getDepenses(1, pageSize); // Fetch inspections with updated filters
    };

    useEffect(() => {
        //if (debouncedVehiculeText) {
        getVehicules(debouncedVehiculeText);
        //}
        return () => {
            // Perform any necessary cleanup (e.g., cancel pending requests)
        };
    }, [authData, debouncedVehiculeText]);

    useEffect(() => {
        //Load Data
        getTypesDepenses();
        getFournisseurs();
        return () => {
            // Perform any necessary cleanup (e.g., cancel pending requests)
        };
    }, []);

    useEffect(() => {
        //Load Data

        getDepenses(pageNumber, pageSize);
        return () => {
            // Perform any necessary cleanup (e.g., cancel pending requests)
        };
    }, [authData, pageNumber, pageSize]);

    // useEffect(() => {
    //     // const result=vehicules.filter(row=>{
    //     //     return row.vehiculeNom && row.vehiculeNom.toLowerCase().match(search.toLowerCase());   
    //     // })
    //     const result = depenses?.filter((item) =>
    //         Object.values(item).some(
    //             (value) =>
    //                 value &&
    //                 value.toString().toLowerCase().includes(search.toLowerCase())
    //         )
    //     );
    //     setFiltredDepenses(result);
    // }, [search])

    return (
        <section className="vehicles-section" onClick={() => { if (openAboutList !== null) { setOpenAboutList(null) } }}>
            <div className="container">
                <div className="affectation-sec wrap-all">
                    <Link to="/ajouterdepense" className="affectation-add color-primary-2 decoration-none">
                        <div className="add-new" id="add-vehc">
                            <span className="material-symbols-outlined">add</span>
                            Ajouter une entrée de dépense
                        </div>
                    </Link>

                    <form onSubmit={handleSearch}>
                        <div className="filter-wrap">

                            <div className="date-container">
                                <input
                                    type="date"
                                    value={filters.dateDepenseDebut}
                                    onChange={(e) => setFilters({ ...filters, dateDepenseDebut: e.target.value })}
                                    className='date-filter'
                                />
                            </div>

                            <div className="date-container">
                                <input
                                    type="date"
                                    value={filters.dateDepenseFin}
                                    onChange={(e) => setFilters({ ...filters, dateDepenseFin: e.target.value })}
                                    className='date-filter'
                                />
                            </div>

                            <Select
                                id="vehicule"
                                name="vehicule"
                                value={filters.vehicule}
                                onChange={(selectedOption) => {
                                    handleVehicleChange(selectedOption);
                                }}
                                onInputChange={(inputValue) => {
                                    // Update the entered text
                                    setEnteredVehicleText(inputValue);
                                }}
                                options={vehicules}
                                getOptionLabel={(option) => option.marque + '[' + option.modele + '] ' + option.immatriculation}
                                getOptionValue={(option) => option.idVehicule}
                                isClearable
                                placeholder="Sélectionnez véhicule"
                                styles={{
                                    control: (baseStyles, state) => ({
                                        ...baseStyles,
                                        borderRadius: '1rem',
                                        borderWidth: '1px',
                                        height: '3rem',
                                        borderColor: state.isFocused ? 'rgba(115, 128, 236, 0.9)' : '#BEBEBE',
                                        backgroundColor: '#65458d',
                                        cursor: 'pointer',
                                        color: '#fff',
                                    }),
                                    option: (provided, state) => ({
                                        ...provided,
                                        backgroundColor: state.isFocused ? '#2581DC' : '#65458d',
                                        color: '#fff',
                                        cursor: 'pointer',
                                    }),
                                    singleValue: (provided, state) => ({
                                        ...provided,
                                        color: '#fff',
                                    }),
                                    placeholder: (provided, state) => ({
                                        ...provided,
                                        color: '#fff'
                                    }),
                                    input: (provided, state) => ({
                                        ...provided,
                                        color: '#fff',
                                    }),
                                }}
                            />

                            <select
                                id="idTypeDepense"
                                name="idTypeDepense"
                                className="select-filter"
                                value={filters.idTypeDepense || ''}
                                onChange={(e) => setFilters({ ...filters, idTypeDepense: e.target.value || '' })}
                            >
                                <option value="">Sélectionnez catégorie</option>
                                {typesDepenses.map((typeDepense) => (
                                    <option key={typeDepense.idTypeDepense} value={typeDepense.idTypeDepense}>{typeDepense.libelleTypeDepense}</option>
                                ))}
                            </select>

                            <select
                                id="idFournisseur"
                                name="idFournisseur"
                                className="select-filter"
                                value={filters.idFournisseur || ''}
                                onChange={(e) => setFilters({ ...filters, idFournisseur: e.target.value || '' })}
                            >
                                <option value="">Sélectionnez fournisseur</option>
                                {fournisseurs.map((fournisseur) => (
                                    <option key={fournisseur.idFournisseur} value={fournisseur.idFournisseur}>{fournisseur.raisonSociale}</option>
                                ))}
                            </select>

                            <div className="filter">
                                {/* <a href="#"><span className="material-symbols-outlined">filter_alt</span></a> */}
                                <button type="submit" className="linkButton">
                                    <span className="material-symbols-outlined">filter_alt</span>
                                </button>
                            </div>
                        </div>
                    </form>

                    {filtredDepenses?.length > 0 && <><div className="group-elements">
                        <div className="voir-elements">
                            <p></p>
                            <select
                                name="pageSize"
                                id="pageSize"
                                onChange={(e) => { handlePerRowsChange(e.target.value || 10); setPageNumber(1); }}
                            >
                                <option value="10" defaultValue={true}>10</option>
                                <option value="15">15</option>
                                <option value="20">20</option>
                            </select>
                        </div>
                        {/* <div className="search-element">
                            <p>Rechercher</p>
                            <input type="text" />
                        </div> */}
                    </div>
                        <div className="table-wrap">
                            <table>
                                <thead>
                                    <tr>
                                        <th onClick={() => handleSort('vehiculeNom')}>Véhicule</th>
                                        <th>Date dépense</th>
                                        <th>Catégorie</th>
                                        <th>Fournisseur</th>
                                        <th>Montant</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {filtredDepenses?.map((vehicule) => (
                                        <tr key={vehicule.idDepense}>
                                            <td>{vehicule.vehiculeNom + ' ' + vehicule.vehiculeImmatriculation}</td>
                                            <td>{vehicule.dateDepense ? (moment(vehicule.dateDepense).format('DD-MM-YYYY HH:mm')) : ''}</td>
                                            <td>{vehicule.typeDepense}</td>
                                            <td>{vehicule.fournisseur}</td>
                                            <td>{formatCurrency(vehicule.montant)}</td>
                                            <td className="txt-center relative">
                                                <span className="material-symbols-outlined about-btn" onClick={() => toggleAboutList(vehicule.idDepense)}>
                                                    more_horiz
                                                </span>
                                                {openAboutList === vehicule.idDepense && (
                                                    <div className="about-list">
                                                        <ul>
                                                            <li>
                                                                <Link className='row row-center' to={`/modifierdepense/${vehicule.idDepense}`} style={{ textDecoration: "none" }}>
                                                                    <span className="material-symbols-outlined">edit</span>Modifier
                                                                </Link>
                                                            </li>

                                                            <li>
                                                                <Link className='row row-center' to="/listdepenses" style={{ textDecoration: "none" }} onClick={() => handleConfirmDelete(vehicule.idDepense)}>
                                                                    <span className="material-symbols-outlined">delete</span>Supprimer
                                                                </Link>
                                                            </li>

                                                        </ul>
                                                    </div>
                                                )}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        {/* <div className="pagination">
                            <Link to="#" onClick={() => handlePageChange(pageNumber - 1)}
                                className={(pageNumber === 1) ? 'pgn disabled' : 'pgn'}
                                disabled={(pageNumber === 1) ? true : false}
                            >&laquo;</Link>
                            {Array.from({ length: totalPages }, (_, i) => (
                                <Link
                                    key={i + 1}
                                    to="#"
                                    className={i + 1 === pageNumber ? 'pgn active' : 'pgn'}
                                    onClick={() => handlePageChange(i + 1)}
                                >
                                    {i + 1}
                                </Link>
                            ))}
                            <Link to="#" onClick={() => handlePageChange(pageNumber + 1)}
                                className={((pageNumber + 1) > totalPages) ? 'pgn disabled' : 'pgn'}
                                disabled={((pageNumber + 1) > totalPages) ? true : false}
                            >&raquo;</Link>
                        </div> */}
                        <div className="pagination">
                            <Link to="#" onClick={() => handlePageChange(pageNumber - 1)}
                                className={(pageNumber === 1) ? 'pgn disabled' : 'pgn'}
                                disabled={(pageNumber === 1) ? true : false}
                            >&laquo;</Link>
                            {Array.from({ length: Math.min(5, totalPages) }, (_, i) => {
                                const page = pageNumber - 2 + i;
                                return page <= totalPages && page > 0 && (
                                    <Link
                                        key={page}
                                        to="#"
                                        className={pageNumber === page ? 'pgn active' : 'pgn'}
                                        onClick={() => handlePageChange(page)}
                                    >
                                        {page}
                                    </Link>
                                );
                            })}
                            <Link to="#" onClick={() => handlePageChange(pageNumber + 1)}
                                className={(pageNumber === totalPages || totalPages === 0) ? 'pgn disabled' : 'pgn'}
                                disabled={(pageNumber === totalPages || totalPages === 0) ? true : false}
                            >&raquo;</Link>
                            <span> Affichage de {(pageNumber === 1)?1:((pageNumber - 1) * pageSize+1)} à {pageNumber * pageSize} sur {totalItems} entrées</span>
                        </div>
                    </>}

                    <ConfirmationDialog
                        open={confirmDialogOpenDelete}
                        onClose={handleCancelDelete}
                        onConfirm={handleDelete}
                        titleMessage="Confirmer la suppression de l'entrée des dépenses"
                        bodyMessage={"Êtes-vous sûr de vouloir supprimer cette entrée de dépenses ? Toutes les données seront définitivement perdues."}
                        btnCancelText="Annuler"
                        btnConfirmText="Supprimer l'entrée de dépenses"
                    />
                </div>
            </div>
        </section>
    );
}

export default ListDepenses;
