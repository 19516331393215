import {HubConnectionBuilder, HubConnectionState, LogLevel} from "@microsoft/signalr";

function isConnectionConnecting(connection) {
    return (
        connection.state === HubConnectionState.Connected ||
        connection.state === HubConnectionState.Reconnecting ||
        connection.state === HubConnectionState.Connecting
    );
}

function createConnection(url) {
    const connection  = new HubConnectionBuilder()
    .withUrl(url)
    .configureLogging(LogLevel.Error)
    .withAutomaticReconnect(true)
    .build();

    connection.start().catch(err => console.error(err));

    return connection;
}

export { isConnectionConnecting, createConnection };