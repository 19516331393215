import React, { useEffect, useState, useRef, useContext } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Select from "react-select";
import moment from 'moment/moment';
import { CustomToast } from '../../components/Toast/CustomToast';
import { axiosInstance, Employee, Proprietaire, Groupe, CategoriePermisConduire, TypePieceIdentite, Profil, UserAuthentication } from '../../services/api';
import { AuthContext } from '../../contexts/AuthContext';
const ModifierEmploye = () => {
    const { authData, login } = useContext(AuthContext);
    const navigate = useNavigate();
    const { id } = useParams();//idEmploye

    const [isLoaded, setIsLoaded] = useState();

    const [identification, setIdentification] = useState(true);
    const [permis, setPermis] = useState(false);
    const [classification, setClassification] = useState(false);
    const [accesUtilisateur, setAccesUtilisateur] = useState(false);

    const [identificationClass, setIdentificationClass] = useState("line act");
    const [permisClass, setPermisClass] = useState("line");
    const [classificationClass, setClassificationClass] = useState("line");
    const [accesUtilisateurClass, setAccesUtilisateurClass] = useState("line");

    //Data to load
    const [roles, setRoles] = useState([]);
    const [piecesIdentites, setPiecesIdentites] = useState([]);
    const [categoriesPermis, setCategoriesPermis] = useState([]);
    const [groupes, setGroupes] = useState([]);
    const [proprietaires, setProprietaires] = useState([]);

    const [proprietairesFlotte, setProprietairesFlotte] = useState([]);

    //Employé data
    const [pathImageEmploye, setPathImageEmploye] = useState(null);
    const [pathImageEmployeName, setPathImageEmployeName] = useState(null);

    const pathImageEmployeRef = useRef(null)

    const showIdentification = () => {
        setIdentification(true);
        setPermis(false);
        setClassification(false);
        setAccesUtilisateur(false);

        setIdentificationClass("line act");
        setPermisClass("line");
        setClassificationClass("line");
        setAccesUtilisateurClass("line");
    };

    const showPermis = () => {
        setIdentification(false);
        setPermis(true);
        setClassification(false);
        setAccesUtilisateur(false);

        setIdentificationClass("line");
        setPermisClass("line act");
        setClassificationClass("line");
        setAccesUtilisateurClass("line");
    };

    const showClassification = () => {
        setIdentification(false);
        setPermis(false);
        setClassification(true);
        setAccesUtilisateur(false);

        setIdentificationClass("line");
        setPermisClass("line");
        setClassificationClass("line act");
        setAccesUtilisateurClass("line");
    };

    const showAccesUtilisateur = () => {
        setIdentification(false);
        setPermis(false);
        setClassification(false);
        setAccesUtilisateur(true);

        setIdentificationClass("line");
        setPermisClass("line");
        setClassificationClass("line");
        setAccesUtilisateurClass("line act");
    };

    const handleResetPassword = () => {
        setFieldValue('resetPassword', !values.resetPassword);
    };

    const { handleChange, values, handleSubmit, touched, errors, setValues, setFieldValue } = useFormik({
        initialValues: {
            matricule: '',
            nom: '',
            prenom: '',
            username: '',
            email: '',
            resetPassword: false,
            newPassword: '',
            dateNaissance: '',
            lieuNais: '',
            adresse: '',
            numTele1: '',
            numTele2: '',
            numIdentite: '',
            idTypePieceIdentite: '',
            numCompteBancaire: '',
            genre: '',
            titreEmploi: '',
            dateDebutEmploi: '',
            dateFinEmploi: '',
            statut: 'actif',
            idProprietaire: '',
            idGroupe: '',
            permisConduire: [],
            estEmployee: false,
            estChauffeur: false,
            estTechnicien: false,
            accesAutorise: false,
            accesAutorisePrecedent: false,
            idProfil: '',
            idEntreperisesFlotte: [],
            idEntreperisesFlotteUpdate: []
        },
        validationSchema: Yup.object({
            nom: Yup.string()
                .required("Champ obligatoire"),
            prenom: Yup.string()
                .required("Champ obligatoire"),
            matricule: Yup.string()
                .required("Champ obligatoire"),
            genre: Yup.string()
                .required("Champ obligatoire"),
            idProprietaire: Yup.string()
                .required("Champ obligatoire"),
            email: Yup.string()
                .email("Le champ doit contenir un e-mail valide")
                .max(255, "Le champ doit contenir au maximum 255 caractères"),
            accesAutorise: Yup.bool(),
            accesAutorisePrecedent: Yup.bool(),
            resetPassword: Yup.bool(),
            username: Yup.string().test('username-validation', 'Invalid username', function (value) {
                const { accesAutorise } = this.parent;

                if (accesAutorise) {
                    if (!value) {
                        return this.createError({ message: 'Champ obligatoire' });
                    }
                    if (value.length < 8 || value.length > 255) {
                        return this.createError({ message: 'Le champ doit contenir entre 8 et 255 caractères' });
                    }
                }

                return true;
            }),
            newPassword: Yup.string().test('password-validation', 'Invalid password', function (value) {
                const accesAutorise = this.resolve(Yup.ref('accesAutorise'));
                const accesAutorisePrecedent = this.resolve(Yup.ref('accesAutorisePrecedent'));
                const resetPassword = this.resolve(Yup.ref('resetPassword'));

                if (accesAutorise && ((accesAutorisePrecedent && resetPassword) || (!accesAutorisePrecedent))) {
                    if (!value) {
                        return this.createError({ message: 'Champ obligatoire' });
                    }
                    if (value.length < 8 || value.length > 16) {
                        return this.createError({ message: 'Le champ doit contenir entre 8 et 16 caractères' });
                    }
                }

                return true;
            }),
            idProfil: Yup.string().test('idProfil-validation', 'Invalid idProfil', function (value) {
                const { accesAutorise } = this.parent;

                if (accesAutorise) {
                    if (!value) {
                        return this.createError({ message: 'Champ obligatoire' });
                    }
                }

                return true;
            }),
            // idEntreperisesFlotte: Yup.array()
            //     .min(1, "Au moins une flotte doit être sélectionnée") // Minimum 1 entreprise should be selected
            //     .required("Champ obligatoire"),
            idEntreperisesFlotte: Yup.array().test('flotte-validation', 'Invalid flotte', function (value) {
                const { accesAutorise } = this.parent;

                if (accesAutorise) {
                    if (!value) {
                        return this.createError({ message: 'Champ obligatoire' });
                    }
                    if (value.length < 1) {
                        return this.createError({ message: 'Au moins une flotte doit être sélectionnée' });
                    }
                }

                return true;
            }),
        }),
        onSubmit: async (values) => {
            try {
                let listIdProprietaires = values.idEntreperisesFlotte?.map(item => item.idProprietaire);
                //console.log(values,listIdProprietaires);
                const formData = new FormData();
                formData.append('idEmploye', id);
                formData.append('matricule', values.matricule);
                formData.append('nom', values.nom);
                formData.append('prenom', values.prenom);
                formData.append('email', values.email);

                formData.append('dateNaissance', values.dateNaissance);
                formData.append('pathImageIdentite', pathImageEmploye);
                formData.append('pathImageIdentiteName', pathImageEmployeName);

                formData.append('lieuNais', values.lieuNais);
                formData.append('adresse', values.adresse);
                formData.append('numTele1', values.numTele1);
                formData.append('numTele2', values.numTele2);
                formData.append('numIdentite', values.numIdentite);
                formData.append('idTypePieceIdentite', values.idTypePieceIdentite);
                formData.append('numCompteBancaire', values.numCompteBancaire);

                formData.append('genre', values.genre);
                formData.append('titreEmploi', values.titreEmploi);
                formData.append('dateDebutEmploi', values.dateDebutEmploi);
                formData.append('dateFinEmploi', values.dateFinEmploi);
                formData.append('statut', values.statut);

                formData.append('idEntreprise', values.idProprietaire);
                formData.append('idGroupe', values.idGroupe);

                for (let i = 0; i < items.length; i++) {
                    for (let key of Object.keys(items[i])) {
                        formData.append(`permisConduire[${i}][${key}]`, items[i][key]);
                    }
                }

                formData.append('estEmployee', values.estEmployee);
                formData.append('estChauffeur', values.estChauffeur);
                formData.append('estTechnicien', values.estTechnicien);
                formData.append('accesAutorise', values.accesAutorise);
                if (values.accesAutorise) {
                    formData.append('username', values.username);
                    formData.append('idProfil', values.idProfil);
                    formData.append('resetPassword', values.resetPassword);
                    if (values.resetPassword) {
                        formData.append('newPassword', values.newPassword);
                    }

                    for (let i = 0; i < listIdProprietaires.length; i++) {
                        formData.append(`idEntreperisesFlotte[${i}]`, listIdProprietaires[i]);
                    }
                }

                formData.append('actionIdUtilisateur', authData.idUser);

                const response = await axiosInstance.put(Employee, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });

                CustomToast("L'employé a été créé avec succès", 'success');
                if (authData.idUser === id && response.status === 200) {
                    // const request = {
                    //     accessToken: authData.token,
                    //     refreshToken: authData.tokenRefresh,
                    // };
                    const request = {
                        refreshToken: authData.tokenRefresh,
                    };
                    const responseAuth = await axiosInstance.post(`${UserAuthentication}/Refresh`, request);
                    let authInfo = JSON.stringify(responseAuth.data);
                    localStorage.setItem('authData', authInfo);
                    login(JSON.parse(authInfo));
                }
                navigate('/listemployes', { replace: true });
            } catch (error) {
                // Handle error
                console.log(error);
                if (!error?.response) {
                    CustomToast("Aucune réponse du serveur", 'error');
                } else if (error.response?.status === 400 && error.response?.data === "Another employee exists with the same username and email") {
                    CustomToast("Un autre employé existe avec le même nom d'utilisateur et e-mail", 'error');
                } else if (error.response?.status === 400 && error.response?.data === "Another employee exists with the same username") {
                    CustomToast("Un autre employé existe avec le même nom d'utilisateur", 'error');
                } else if (error.response?.status === 400 && error.response?.data === "Another employee exists with the same email") {
                    CustomToast("Un autre employé existe avec le même e-mail", 'error');
                } else if (error.response?.status === 401) {
                    CustomToast("Non autorisé", 'error');
                } else {
                    CustomToast("Demande échoué", 'error');
                }
            }
        }
    });

    //Load data

    const getRoles = async () => {
        try {
            if (authData?.estSuperAdmin === true) {
                const response = await axiosInstance.get(`${Profil}/true`);
                const resp = await response.data;
                setRoles(resp);
            }
            else {
                const response = await axiosInstance.get(`${Profil}/false`);
                const resp = await response.data;
                setRoles(resp);
            }


        }
        catch (err) {
            console.log(err);
        }
    }

    const getGroupes = async () => {
        try {
            const response = await axiosInstance.get(`${Groupe}`);
            const resp = await response.data;
            setGroupes(resp);
        }
        catch (err) {
            console.log(err);
        }
    }

    const getProprietaires = async () => {
        try {
            const queryParams = new URLSearchParams({
                typeProprietaire: "entreprise,location,freelancer"
            });
            const response = await axiosInstance.get(`${Proprietaire}/GetProprietaireByType?${queryParams}`);
            const resp = await response.data;
            setProprietaires(resp?.filter(item => item.typeProprietaire === "entreprise"));
            setProprietairesFlotte(resp);
            //console.log("resp",resp);         
            if (resp && values.idEntreperisesFlotteUpdate?.length > 0) {
                //console.log("idEntreperisesFlotteUpdate", values.idEntreperisesFlotteUpdate);
                const selectedEntreprises = resp.filter((option) =>
                    values.idEntreperisesFlotteUpdate.includes(option.idProprietaire)
                );
                //console.log(selectedEntreprises);
                //setProprietairesFlotte(selectedEntreprises);
                setValues((prevValues) => ({
                    ...prevValues,
                    idEntreperisesFlotte: selectedEntreprises,
                }));
            }
        }
        catch (err) {
            console.log(err);
        }
    }

    const getTypesPieceIdentites = async () => {
        try {
            const response = await axiosInstance.get(`${TypePieceIdentite}`);
            const resp = await response.data;
            setPiecesIdentites(resp);
        }
        catch (err) {
            console.log(err);
        }
    }

    const getCategoriePermisConduire = async () => {
        try {
            const response = await axiosInstance.get(`${CategoriePermisConduire}`);
            const resp = await response.data;
            setCategoriesPermis(resp);
        }
        catch (err) {
            console.log(err);
        }
    }
    //Employe document
    const handlePathImageEmployeChange = (e) => {
        setPathImageEmploye(e.target.files[0]);
    };

    const getEmploye = async (idEmploye) => {
        try {
            const response = await axiosInstance.get(`${Employee}/${idEmploye}`);
            const resp = await response.data;
            setIsLoaded(resp);
            setValues((prevValues) => ({
                ...prevValues,
                matricule: resp.matricule || '',
                nom: resp.nom || '',
                prenom: resp.prenom || '',
                username: resp.username || '',
                email: resp.email || '',
                password: '',//resp.password || '',
                dateNaissance: moment(resp.dateNaissance).isValid() ? moment(resp.dateNaissance).format('YYYY-MM-DD') : '' || '',
                lieuNais: resp.lieuNais || '',
                adresse: resp.adresse || '',
                numTele1: resp.numTele1 || '',
                numTele2: resp.numTele2 || '',
                numIdentite: resp.numIdentite || '',
                idTypePieceIdentite: resp.idTypePieceIdentite || '',
                numCompteBancaire: resp.numCompteBancaire || '',
                genre: resp.genre || '',
                titreEmploi: resp.titreEmploi || '',
                dateDebutEmploi: moment(resp.dateDebutEmploi).isValid() ? moment(resp.dateDebutEmploi).format('YYYY-MM-DD') : '' || '',
                dateFinEmploi: moment(resp.dateFinEmploi).isValid() ? moment(resp.dateFinEmploi).format('YYYY-MM-DD') : '' || '',
                statut: resp.statut || 'actif',
                idProprietaire: resp.idEntreprise || '',
                idGroupe: resp.idGroupe || '',
                permisConduire: resp.permisConduire || [],
                estEmployee: resp.estEmployee || false,
                estChauffeur: resp.estChauffeur || false,
                estTechnicien: resp.estTechnicien || false,
                accesAutorise: resp.accesAutorise || false,
                accesAutorisePrecedent: resp.accesAutorise || false,
                idProfil: resp.idProfil || '',
                idEntreperisesFlotte: [],
                idEntreperisesFlotteUpdate: resp.idEntreperisesFlotte || [],
            }));

            if (resp.permisConduire) {
                setItems(resp.permisConduire); // Set the "items" state with the data from the API response
            }
            if (resp.pathImageIdentite) {
                setPathImageEmployeName(resp.pathImageIdentite || '')
            }
        }
        catch (error) {
            // Handle error
            if (!error?.response) {
                CustomToast("Aucune réponse du serveur", 'error');
            } else if (error.response?.status === 400 && error.response?.data === "Another employee exists with the same username and email") {
                CustomToast("Un autre employé existe avec le même nom d'utilisateur et e-mail", 'error');
            } else if (error.response?.status === 400 && error.response?.data === "Another employee exists with the same username") {
                CustomToast("Un autre employé existe avec le même nom d'utilisateur", 'error');
            } else if (error.response?.status === 400 && error.response?.data === "Another employee exists with the same email") {
                CustomToast("Un autre employé existe avec le même e-mail", 'error');
            } else if (error.response?.status === 401) {
                CustomToast("Non autorisé", 'error');
            } else {
                CustomToast("Demande échoué", 'error');
            }
            console.log(error);
            //navigate("/listevehicules", { replace: true });

        }
    }

    const getImageFileName = (file) => {
        return file ? file.name : 'No file selected';
    };

    //Permis de conduire
    const [formData, setFormData] = useState({ idCategoriePermisConduire: '', lieuDelivrance: '', numPermisConduire: '', dateDelivrance: '', dateExpiration: '' });

    const [items, setItems] = useState([]);

    const [errorItem, setErrorItem] = useState({ error: false, message: '' });
    const [errorItemNum, setErrorItemNum] = useState({ error: false, message: '' });
    const [errorItemIdCate, setErrorItemIdCate] = useState({ error: false, message: '' });
    const [errorItemDateDelivrance, setErrorItemDateDelivrance] = useState({ error: false, message: '' });
    const [errorItemDateExpiration, setErrorItemDateExpiration] = useState({ error: false, message: '' });

    const addItem = (newItem) => {
        setItems([...items, newItem]);
    };

    const deleteItem = (itemIndex) => {
        const updatedItems = items.filter((item, index) => index !== itemIndex);
        setItems(updatedItems);
    };


    const updateItem = (itemIndex, updatedItem) => {
        const updatedItems = items.map((item, index) =>
            index === itemIndex ? { ...item, ...updatedItem } : item
        );
        setItems(updatedItems);
    };

    const handleInputChange = (event) => {
        const { name, value, type, checked } = event.target;
        const inputValue = type === 'checkbox' ? checked : value;
        setFormData({ ...formData, [name]: inputValue });
    };

    const handleAddElement = (event) => {
        event.preventDefault();

        let isValid = true;

        if (!formData.numPermisConduire) {
            setErrorItemNum({ error: true, message: 'Champ obligatoire' });
            isValid = false;
            //return;
        }

        if (!formData.idCategoriePermisConduire) {
            setErrorItemIdCate({ error: true, message: 'Champ obligatoire' });
            isValid = false;
            //return;
        }

        if (!formData.dateDelivrance) {
            setErrorItemDateDelivrance({ error: true, message: 'Champ obligatoire' });
            isValid = false;
            //return;
        }

        if (!formData.dateExpiration) {
            setErrorItemDateExpiration({ error: true, message: 'Champ obligatoire' });
            isValid = false;
            //return;
        }

        const existingItem = items.find((item) => item.numPermisConduire === formData.numPermisConduire && item.idCategoriePermisConduire === formData.idCategoriePermisConduire);
        if (existingItem) {
            setErrorItem({ error: true, message: 'Un élément avec le même catégorie existe déjà. Veuillez choisir une autre catégorie' });
            CustomToast("Un élément avec le même catégorie existe déjà. Veuillez choisir une autre catégorie", 'error');
            isValid = false;
            //return;
        }

        if (!isValid) {
            return;
        }

        addItem(formData);
        setFormData({ idCategoriePermisConduire: '', lieuDelivrance: '', numPermisConduire: '', dateDelivrance: '', dateExpiration: '' });
        setErrorItem({ error: false, message: '' });
        setErrorItemNum({ error: false, message: '' });
        setErrorItemIdCate({ error: false, message: '' });
        setErrorItemDateDelivrance({ error: false, message: '' });
        setErrorItemDateExpiration({ error: false, message: '' });
    };

    useEffect(() => {
        //Load roles
        getRoles();
        //console.log("useEffect");
    }, [authData])

    useEffect(() => {
        //Load type pieces d'identité
        getTypesPieceIdentites();
        //Load type permis de conduire
        getCategoriePermisConduire();
        //Load Groupe
        getGroupes();
        //Load roles
    }, [authData])

    useEffect(() => {
        getProprietaires()
        //values.idEntreprise = "";
    }, [isLoaded, values.idEntreperisesFlotteUpdate, authData])

    useEffect(() => {
        //Load employé
        getEmploye(id);
    }, [id])


    return (
        <section className="donnee-section">
            <div className="container">
                <form onSubmit={handleSubmit} className='employe-form'>
                    <div className="">
                        <div className="grid grid--form-cols">
                            <div className="form-sec">
                                <div className="retour" id="retour-employee">
                                    <Link to="/listemployes" style={{ textDecoration: "none" }}>
                                        <div className="arrow-back employe-form-back">
                                            <span className="material-symbols-outlined" id="arrow">arrow_back</span>
                                            <h3 className="heading-primary"></h3>
                                        </div>
                                    </Link>
                                </div>
                                <div className="small-menu-sec">
                                    <div className={identificationClass} id="info-menu" onClick={() => showIdentification()}>
                                        <span className="material-symbols-outlined">info</span>
                                        <h3 className="heading-primary">Détails</h3>
                                        {(errors.nom || errors.prenom || errors.genre || errors.matricule) && <span className="material-symbols-outlined danger">error</span>}
                                    </div>
                                    <div className={permisClass} id="cycle-menu" onClick={() => showPermis()}>
                                        <span className="material-symbols-outlined">fact_check</span>
                                        <h3 className="heading-primary">Permis de conduire</h3>
                                    </div>
                                    <div className={classificationClass} id="equalizer-menu" onClick={() => showClassification()}>
                                        <span className="material-symbols-outlined">format_list_bulleted</span>
                                        <h3 className="heading-primary">Clasifications</h3>
                                    </div>
                                    <div className={accesUtilisateurClass} id="caractirst-menu" onClick={() => showAccesUtilisateur()}>
                                        <span className="material-symbols-outlined">settings_accessibility</span>
                                        <h3 className="heading-primary">Accès utilisateur</h3>
                                        {((errors.username || errors.idProfil) && values.accesAutorise) && <span className="material-symbols-outlined danger">error</span>}
                                    </div>

                                </div>
                            </div>
                            <div className="form-sec">
                                <div className="retour" id="retour-login">
                                    <div className="arrow-back">
                                    </div>
                                    <button type="submit" className="submit-btn">Enregistrer</button>
                                </div>
                                <div className="form-details">

                                    {identification && <div className="detail-form" id="identification-form-employe">
                                        <h3 className="heading-primary">Identification</h3>
                                        <div className="col">
                                            <div className="grp">
                                                <div className="gp">
                                                    <label >Nom<span className='requiredField'>*</span></label>
                                                    <input type="text"
                                                        id="nom"
                                                        name="nom"
                                                        style={(touched.nom && errors.nom) ? { borderColor: "red" } : null}
                                                        placeholder=""
                                                        defaultValue={values.nom}
                                                        onChange={handleChange}
                                                    />
                                                    {touched.nom && errors.nom ? (
                                                        <div className="invalid-feedback">{errors.nom}</div>
                                                    ) : null}
                                                </div>
                                                <div className="gp">
                                                    <label >Prénom<span className='requiredField'>*</span></label>
                                                    <input type="text"
                                                        id="prenom"
                                                        name="prenom"
                                                        style={(touched.prenom && errors.prenom) ? { borderColor: "red" } : null}
                                                        placeholder=""
                                                        defaultValue={values.prenom}
                                                        onChange={handleChange}
                                                    />
                                                    {touched.prenom && errors.prenom ? (
                                                        <div className="invalid-feedback">{errors.prenom}</div>
                                                    ) : null}
                                                </div>
                                            </div>
                                            <div className="grp">
                                                <div className="gp">
                                                    <label >Genre<span className='requiredField'>*</span></label>
                                                    <select
                                                        id="genre"
                                                        name="genre"
                                                        value={values.genre}
                                                        onChange={handleChange}
                                                    >
                                                        <option value="" defaultValue={true}>Veuillez sélectionner</option>
                                                        <option value="H">Homme</option>
                                                        <option value="F">Femme</option>
                                                    </select>
                                                    {touched.genre && errors.genre ? (
                                                        <div className="invalid-feedback">{errors.genre}</div>
                                                    ) : null}
                                                </div>
                                                <div className="gp">
                                                    <label >Matricule<span className='requiredField'>*</span></label>
                                                    <input type="text"
                                                        id="matricule"
                                                        name="matricule"
                                                        style={(touched.matricule && errors.matricule) ? { borderColor: "red" } : null}
                                                        placeholder=""
                                                        defaultValue={values.matricule}
                                                        onChange={handleChange}
                                                    />
                                                    {touched.matricule && errors.matricule ? (
                                                        <div className="invalid-feedback">{errors.matricule}</div>
                                                    ) : null}
                                                </div>

                                                <div className="gp">
                                                    <label >Statut<span className='requiredField'>*</span></label>
                                                    <select
                                                        id="statut"
                                                        name="statut"
                                                        value={values.statut}
                                                        onChange={handleChange}
                                                    >
                                                        <option value="actif" defaultValue={true}>Actif</option>
                                                        <option value="inactif">Inactif</option>
                                                        <option value="suspendu">Suspendu</option>
                                                        <option value="licencié">Licencié</option>
                                                        <option value="archivé">Archivé</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="grp">
                                                <div className="gp">
                                                    <label >Date de naissance</label>
                                                    <input
                                                        type="date"
                                                        id="dateNaissance"
                                                        name="dateNaissance"
                                                        value={values.dateNaissance ? (moment(values.dateNaissance).format('YYYY-MM-DD')) : ''}
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                                <div className="gp">
                                                    <label >Lieu de naissance</label>
                                                    <input type="text"
                                                        id="lieuNais"
                                                        name="lieuNais"
                                                        placeholder=""
                                                        defaultValue={values.lieuNais}
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                                <div className="gp">
                                                    <label >Adresse</label>
                                                    <input type="text"
                                                        id="adresse"
                                                        name="adresse"
                                                        placeholder=""
                                                        defaultValue={values.adresse}
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                            </div>
                                            <div className="grp">
                                                <div className="gp">
                                                    <label >N° téléphone 1</label>
                                                    <input type="text"
                                                        id="numTele1"
                                                        name="numTele1"
                                                        placeholder=""
                                                        defaultValue={values.numTele1}
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                                <div className="gp">
                                                    <label >N° téléphone 2</label>
                                                    <input type="text"
                                                        id="numTele2"
                                                        name="numTele2"
                                                        placeholder=""
                                                        defaultValue={values.numTele2}
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                            </div>
                                            <div className="grp">
                                                <div className="gp">
                                                    <label >Email</label>
                                                    <input
                                                        type="email"
                                                        id="email"
                                                        name="email"
                                                        style={(touched.email && errors.email) ? { borderColor: "red" } : null}
                                                        placeholder=""
                                                        defaultValue={values.email}
                                                        onChange={handleChange}
                                                    />
                                                    {touched.email && errors.email ? (
                                                        <div className="invalid-feedback">{errors.email}</div>
                                                    ) : null}
                                                </div>
                                                <div className="gp">
                                                    <label >N° compte bancaire</label>
                                                    <input type="text"
                                                        id="numCompteBancaire"
                                                        name="numCompteBancaire"
                                                        placeholder=""
                                                        defaultValue={values.numCompteBancaire}
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                            </div>
                                            <div className="grp">
                                                <div className="gp">
                                                    <label >Type pièce d'identité</label>
                                                    <select
                                                        id="idTypePieceIdentite"
                                                        name="idTypePieceIdentite"
                                                        value={values.idTypePieceIdentite}
                                                        onChange={handleChange}
                                                    >
                                                        <option value="">Veuillez sélectionner</option>
                                                        {piecesIdentites.map((categorie) => (
                                                            <option key={categorie.idTypePieceIdentite} value={categorie.idTypePieceIdentite}>{categorie.libelleTypePieceIdentite}</option>
                                                        ))}
                                                    </select>
                                                </div>
                                                <div className="gp">
                                                    <label >N° piece d'identité</label>
                                                    <input type="text"
                                                        id="numIdentite"
                                                        name="numIdentite"
                                                        placeholder=""
                                                        defaultValue={values.numIdentite}
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                            </div>
                                            <div className="grp">
                                                <div className="gp">
                                                    <label >Groupe</label>
                                                    <select
                                                        id="idGroupe"
                                                        name="idGroupe"
                                                        value={values.idGroupe}
                                                        onChange={handleChange}
                                                    >
                                                        <option value="">Veuillez sélectionner</option>
                                                        {groupes.map((groupe) => (
                                                            <option key={groupe.idGroupe} value={groupe.idGroupe}>{groupe.libelleGroupe}</option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="grp">
                                                <div className="gp">
                                                    <label >Photo de profil</label>
                                                    <label className="upload-file">
                                                        uploadfile
                                                        <input
                                                            ref={pathImageEmployeRef}
                                                            id="pathImageIdentite"
                                                            name="pathImageIdentite"
                                                            type="file"
                                                            onChange={handlePathImageEmployeChange}
                                                        />
                                                    </label>
                                                    {pathImageEmploye && <span style={{ fontSize: "10px" }}>{getImageFileName(pathImageEmploye)}
                                                        <span className="material-symbols-outlined" onClick={(e) => { setPathImageEmploye(null); if (pathImageEmployeRef.current) { pathImageEmployeRef.current.value = ''; } }}>delete</span>
                                                    </span>}
                                                    {pathImageEmployeName && <span style={{ fontSize: "10px" }}>{pathImageEmployeName}
                                                        <span className="material-symbols-outlined" onClick={(e) => { setPathImageEmploye(null); setPathImageEmployeName(''); if (pathImageEmployeRef.current) { pathImageEmployeRef.current.value = ''; } }}>delete</span>
                                                    </span>}
                                                </div>
                                            </div>
                                        </div>
                                    </div>}

                                    {identification && <div className="detail-form" id="identification-form-employe">
                                        <h3 className="heading-primary">Contrat</h3>
                                        <div className="col">
                                            <div className="grp">
                                                <div className="gp">
                                                    <label >Entreprise<span className='requiredField'>*</span></label>
                                                    <select
                                                        id="idProprietaire"
                                                        name="idProprietaire"
                                                        value={values.idProprietaire}
                                                        onChange={handleChange}
                                                        style={(touched.idProprietaire && errors.idProprietaire) ? { borderColor: "red" } : null}
                                                    >
                                                        <option value="">Veuillez sélectionner</option>
                                                        {proprietaires.map((proprietaire) => (
                                                            <option key={proprietaire.idProprietaire} value={proprietaire.idProprietaire}>{proprietaire.raisonSociale}</option>
                                                        ))}
                                                    </select>
                                                    {touched.idProprietaire && errors.idProprietaire ? (
                                                        <div className="invalid-feedback">{errors.idProprietaire}</div>
                                                    ) : null}
                                                </div>
                                                <div className="gp">
                                                    <label >Titre d'emploi</label>
                                                    <input type="text"
                                                        id="titreEmploi"
                                                        name="titreEmploi"
                                                        placeholder=""
                                                        defaultValue={values.titreEmploi}
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                            </div>
                                            <div className="grp">
                                                <div className="gp">
                                                    <label >Date de départ</label>
                                                    <input
                                                        type="date"
                                                        id="dateFinEmploi"
                                                        name="dateFinEmploi"
                                                        value={values.dateFinEmploi ? (moment(values.dateFinEmploi).format('YYYY-MM-DD')) : ''}
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                                <div className="gp">
                                                    <label >Date de début</label>
                                                    <input
                                                        type="date"
                                                        id="dateDebutEmploi"
                                                        name="dateDebutEmploi"
                                                        value={values.dateDebutEmploi ? (moment(values.dateDebutEmploi).format('YYYY-MM-DD')) : ''}
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>}

                                    {permis && <div className="cycle-form" id="permis-employe">
                                        <div className="detail-form">
                                            <h3></h3>
                                            <div className="col">
                                                <div className="grp">
                                                    <div className="gp">
                                                        <label >N° permis de conduire</label>
                                                        <input type="text"
                                                            id="numPermisConduire"
                                                            name="numPermisConduire"
                                                            placeholder=""
                                                            value={formData.numPermisConduire}
                                                            onChange={handleInputChange}
                                                            style={(errorItemNum.error) ? { borderColor: "red" } : null}
                                                        />
                                                        {errorItemNum.error && errorItemNum.message ? (
                                                            <div className="invalid-feedback">{errorItemNum.message}</div>
                                                        ) : null}
                                                    </div>

                                                    <div className="gp">
                                                        <label >Catégorie</label>
                                                        <select
                                                            id="idCategoriePermisConduire"
                                                            name="idCategoriePermisConduire"
                                                            value={formData.idCategoriePermisConduire}
                                                            onChange={handleInputChange}
                                                            style={(errorItemNum.error) ? { borderColor: "red" } : null}
                                                        >
                                                            <option value="">Veuillez sélectionner</option>
                                                            {categoriesPermis.map((categorie) => (
                                                                <option key={categorie.idCategoriePermisConduire} value={categorie.idCategoriePermisConduire}>{categorie.libelleCategoriePermisConduire}</option>
                                                            ))}
                                                        </select>
                                                        {errorItemIdCate.error && errorItemIdCate.message ? (
                                                            <div className="invalid-feedback">{errorItemIdCate.message}</div>
                                                        ) : null}
                                                    </div>
                                                </div>
                                                <div className="grp">
                                                    <div className="gp">
                                                        <label >Date de délivrance</label>
                                                        <input
                                                            type="date"
                                                            id="dateDelivrance"
                                                            name="dateDelivrance"
                                                            value={formData.dateDelivrance ? (moment(formData.dateDelivrance).format('YYYY-MM-DD')) : ''}
                                                            onChange={handleInputChange}
                                                            style={(errorItemNum.error) ? { borderColor: "red" } : null}
                                                        />
                                                        {errorItemDateDelivrance.error && errorItemDateDelivrance.message ? (
                                                            <div className="invalid-feedback">{errorItemDateDelivrance.message}</div>
                                                        ) : null}
                                                    </div>
                                                    <div className="gp">
                                                        <label >Date expiration</label>
                                                        <input
                                                            type="date"
                                                            id="dateExpiration"
                                                            name="dateExpiration"
                                                            value={formData.dateExpiration ? (moment(formData.dateExpiration).format('YYYY-MM-DD')) : ''}
                                                            onChange={handleInputChange}
                                                            style={(errorItemNum.error) ? { borderColor: "red" } : null}
                                                        />
                                                        {errorItemDateExpiration.error && errorItemDateExpiration.message ? (
                                                            <div className="invalid-feedback">{errorItemDateExpiration.message}</div>
                                                        ) : null}
                                                    </div>
                                                    <div className="gp">
                                                        <label >Lieu de délivrance</label>
                                                        <input type="text"
                                                            id="lieuDelivrance"
                                                            name="lieuDelivrance"
                                                            placeholder=""
                                                            value={formData.lieuDelivrance}
                                                            onChange={handleInputChange}
                                                        />
                                                    </div>
                                                    <div className="gp" onClick={(event) => handleAddElement(event)}>
                                                        <span class="material-symbols-outlined success cursurer">add</span>
                                                    </div>
                                                </div>
                                                <div className="grp">
                                                    {items?.length > 0 && <div className="table-wrap-permis w-full">
                                                        <table>
                                                            <thead>
                                                                <tr>
                                                                    <th>N° permis</th>
                                                                    <th>Catégorie</th>
                                                                    <th>Date de délivrance</th>
                                                                    <th>Date expiration</th>
                                                                    <th>Lieu de délivrance</th>
                                                                    <th></th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {items?.map((row, index) => (
                                                                    <tr key={row.index}>
                                                                        <td>{row.numPermisConduire}</td>
                                                                        <td>{categoriesPermis.find((el) => el.idCategoriePermisConduire === row.idCategoriePermisConduire).libelleCategoriePermisConduire}</td>
                                                                        <td>{moment(row.dateDelivrance).format('DD-MM-YYYY')}</td>
                                                                        <td>{moment(row.dateExpiration).format('DD-MM-YYYY')}</td>
                                                                        <td>{row.lieuDelivrance}</td>
                                                                        <td style={{ textAlign: 'center' }}>
                                                                            <Link to="#" style={{ textDecoration: "none" }} onClick={() => deleteItem(index)}>
                                                                                <span className="material-symbols-outlined danger cursurer">delete</span>
                                                                            </Link>
                                                                        </td>
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </table>
                                                    </div>}
                                                </div>
                                            </div>
                                        </div>
                                    </div>}

                                    {classification && <div className="finance-form" id="classification-employe">
                                        <div className="detail-form">
                                            <div className="col">
                                                <div className="grp-check">
                                                    <input
                                                        type="checkbox"
                                                        className="box"
                                                        id="estEmployee"
                                                        name="estEmployee"
                                                        checked={values.estEmployee}
                                                        onChange={handleChange}
                                                    />
                                                    <label >Employé</label>
                                                </div>
                                                <div className="grp-check">
                                                    <input
                                                        type="checkbox"
                                                        className="box"
                                                        id="estChauffeur"
                                                        name="estChauffeur"
                                                        checked={values.estChauffeur}
                                                        onChange={handleChange}
                                                    />
                                                    <label >Chauffeur</label>
                                                </div>
                                                <div className="grp-check">
                                                    <input
                                                        type="checkbox"
                                                        className="box"
                                                        id="estTechnicien"
                                                        name="estTechnicien"
                                                        checked={values.estTechnicien}
                                                        onChange={handleChange}
                                                    />
                                                    <label >Technicien</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>}

                                    {accesUtilisateur && <div className="finance-form" id="utilisateur-employe">
                                        <div className="detail-form">
                                            <div className="col">
                                                <div className="grp-check">
                                                    <input
                                                        type="checkbox"
                                                        className="box"
                                                        id="accesAutorise"
                                                        name="accesAutorise"
                                                        checked={values.accesAutorise}
                                                        onChange={handleChange}
                                                    />
                                                    <label >Autoriser l'accès</label>
                                                </div>
                                                {values.accesAutorise === true && <div className="grp">

                                                    <div className="gp">
                                                        <label >Username<span className='requiredField'>*</span></label>
                                                        <input type="text"
                                                            id="username"
                                                            name="username"
                                                            placeholder=""
                                                            autoComplete="false"
                                                            defaultValue={values.username}
                                                            onChange={handleChange}
                                                            style={(touched.username && errors.username) ? { borderColor: "red" } : null}
                                                        />
                                                        {touched.username && errors.username ? (
                                                            <div className="invalid-feedback">{errors.username}</div>
                                                        ) : null}
                                                    </div>
                                                    {(values.accesAutorisePrecedent && !values.resetPassword) && <div className="gp">
                                                        {/* <label >Changer mot de passe</label> */}
                                                        <Link to={`#`} className='act' style={{ fontSize: '1rem' }} onClick={() => handleResetPassword()}>
                                                            {/* <span className="material-symbols-outlined">edit</span> */}
                                                            Changer mot de passe
                                                        </Link>
                                                    </div>}
                                                    {(!values.accesAutorisePrecedent || values.resetPassword) && <div className="gp">
                                                        <label >Mot de passe</label>
                                                        <input
                                                            type="password"
                                                            id="newPassword"
                                                            name="newPassword"
                                                            style={(touched.newPassword && errors.newPassword) ? { borderColor: "red" } : null}
                                                            placeholder=""
                                                            defaultValue={values.newPassword}
                                                            onChange={handleChange}
                                                        />
                                                        {touched.newPassword && errors.newPassword ? (
                                                            <div className="invalid-feedback">{errors.newPassword}</div>
                                                        ) : null}
                                                    </div>}
                                                    <div className="gp">
                                                        <label >Rôle<span className='requiredField'>*</span></label>
                                                        <select
                                                            id="idProfil"
                                                            name="idProfil"
                                                            value={values.idProfil}
                                                            onChange={handleChange}
                                                            style={(touched.idProfil && errors.idProfil) ? { borderColor: "red" } : null}
                                                        >
                                                            <option value="">Veuillez sélectionner</option>
                                                            {roles.map((role) => (
                                                                <option key={role.idProfil} value={role.idProfil}>{role.libelleProfil}</option>
                                                            ))}
                                                        </select>
                                                        {touched.idProfil && errors.idProfil ? (
                                                            <div className="invalid-feedback">{errors.idProfil}</div>
                                                        ) : null}
                                                    </div>
                                                </div>}
                                                {values.accesAutorise === true && <div className="grp">
                                                    <div className="gp">
                                                        <label >Flotte(s)<span className='requiredField'>*</span></label>
                                                        <Select
                                                            id="idEntreperisesFlotte"
                                                            name="idEntreperisesFlotte"
                                                            value={values.idEntreperisesFlotte}
                                                            onChange={(option) => { setFieldValue('idEntreperisesFlotte', option); }}
                                                            options={proprietairesFlotte}
                                                            getOptionLabel={(option) => option.typeProprietaire !== "freelancer" ? option.raisonSociale : option.nom + " " + option.prenom}
                                                            getOptionValue={(option) => option.idProprietaire}
                                                            isClearable
                                                            isMulti
                                                            placeholder="Séléctionnez propriétaire(s)..."
                                                            styles={{
                                                                control: (baseStyles, state) => ({
                                                                    ...baseStyles,
                                                                    minHeight: '19px',
                                                                    outline: '0!important',
                                                                    borderRadius: '0.5rem',
                                                                    borderWidth: '1px',
                                                                    fontSize: '1rem',
                                                                    borderColor: state.isFocused ? 'rgba(115, 128, 236, 0.9)' : '#BEBEBE'
                                                                }),
                                                                option: (provided, state) => ({
                                                                    ...provided,
                                                                    fontSize: '1rem'
                                                                }),
                                                                singleValue: (provided, state) => ({
                                                                    ...provided,
                                                                    fontSize: '1rem',
                                                                    margin: '0',
                                                                }),
                                                                valueContainer: (provided, state) => ({
                                                                    ...provided,
                                                                    alignItems: 'start',
                                                                    fontSize: '1rem'
                                                                }),
                                                                input: (provided, state) => ({
                                                                    ...provided,
                                                                    margin: '0',
                                                                }),
                                                                menu: (provided, state) => ({
                                                                    ...provided,
                                                                    borderRadius: '0.5rem',
                                                                    zIndex: 99990,
                                                                    marginTop: 0,
                                                                    borderWidth: '1px',
                                                                    fontSize: '1rem',
                                                                }),
                                                            }}
                                                        />
                                                        {touched.idEntreperisesFlotte && errors.idEntreperisesFlotte ? (
                                                            <div className="invalid-feedback">{errors.idEntreperisesFlotte}</div>
                                                        ) : null}
                                                    </div>
                                                </div>}
                                            </div>
                                        </div>
                                    </div>}

                                    <div className="submit-container">
                                        <button type="submit" className="submit-btn">Enregistrer</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </section>
    );
}

export default ModifierEmploye;
