import React from "react";
import { useLocation, Navigate, Outlet } from "react-router-dom";
import jwtDecode from 'jwt-decode';

const ProtectedRouteSuperAdmin = () => {
    const location = useLocation();
    const authData = JSON.parse(localStorage.getItem("authData"));
    const estSuperAdmin = authData?.estSuperAdmin || false;
    // const decodedToken = authData?.token ? jwtDecode(authData?.token) : '';
    // console.log(decodedToken)
    // return (
    //     estSuperAdmin
    //         ? <Outlet />
    //         : authData?.token
    //             ? <Navigate to="/unauthorized" state={{ from: location }} replace />
    //             : <Navigate to="/login" state={{ from: location }} replace />
    // );
    return (
        (estSuperAdmin && authData?.token)
            ? <Outlet />
            : (!authData?.token)
            ? <Navigate to="/login" state={{ from: location }} replace />
            : <Navigate to="/unauthorized" state={{ from: location }} replace />
    );
}

export default ProtectedRouteSuperAdmin;