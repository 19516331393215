import React, { useEffect, useState, useContext } from 'react';
import DataTable from 'react-data-table-component';
import { Link } from 'react-router-dom';
import moment from 'moment/moment';
import Select from "react-select";
import { CustomToast } from '../../components/Toast/CustomToast';
import useDebounce from '../../hooks/useDebounce';
import { formatCurrency } from '../../utils/Utils';
import ConfirmationDialog from '../../components/Confirmation/ConfirmationDialog';
import { axiosInstance, Vehicule, VehiculeCarburant, Fournisseur } from '../../services/api';
import $ from 'jquery';
import { AuthContext } from '../../contexts/AuthContext';

const ListCarburants = () => {
    const { authData } = useContext(AuthContext);

    const [carburants, setCarburants] = useState([]);
    const [vehicules, setVehicules] = useState([]);
    const [fournisseurs, setFournisseurs] = useState([]);

    const [filtredCarburants, setFiltredCarburants] = useState([]);
    const [pageNumber, setPageNumber] = useState(1);//Definit par user
    const [pageSize, setPageSize] = useState(10);//Definit par user
    const [totalItems, setTotalItems] = useState(0);//Calculé dans BackEnd: nombre totale d'éléments
    const [totalPages, setTotalPages] = useState(0);//Calculé dans BackEnd
    const [loading, setLoading] = useState(true);
    const [idCarburantDelete, setIdCarburantDelete] = useState('');

    const [selectedVehicle, setSelectedVehicle] = useState('');
    const [selectedVehicleId, setSelectedVehicleId] = useState('');

    const [confirmDialogOpenDelete, setConfirmDialogOpenDelete] = useState(false);

    const [filters, setFilters] = useState({
        idVehicule: '',
        vehicule: '',
        idFournisseur: '',
        dateDebut: moment("00", "hh").add(-4, 'days').format('YYYY-MM-DD'),
        dateFin: moment("00", "hh").add(3, 'days').format('YYYY-MM-DD')
    });

    const [search, setSearch] = useState("");

    const [enteredVehicleText, setEnteredVehicleText] = useState('');
    const debouncedVehiculeText = useDebounce(enteredVehicleText, 300);

    //table management
    const [sortBy, setSortBy] = useState(''); // Column name to sort by
    const [sortOrder, setSortOrder] = useState('asc'); // 'asc' or 'desc'

    const handleSort = (column) => {
        setSortBy(column);
        setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');

        let sortedTable = [...filtredCarburants].sort((a, b) => {
            const aValue = a[sortBy] || '';
            const bValue = b[sortBy] || '';

            if (sortOrder === 'asc') {
                return aValue.localeCompare(bValue);
            } else {
                return bValue.localeCompare(aValue);
            }
        });

        setFiltredCarburants(sortedTable);
    };

    const [openAboutList, setOpenAboutList] = useState(null);

    const toggleAboutList = (idVehicule) => {
        setOpenAboutList(openAboutList === idVehicule ? null : idVehicule);
    };

    const handlePageChange = (page) => {
        setPageNumber(page);
        //consolelog("setPageNumber="+pageNumber)
    };

    const handlePerRowsChange = (newPerPage) => {
        setPageSize(newPerPage);
        //console.log("setPageSize="+pageSize);
    };

    const handleVehicleChange = (selectedOption) => {
        setSelectedVehicle(selectedOption);
        setSelectedVehicleId(selectedOption ? selectedOption.idVehicule : '');
        filters.idVehicule = selectedVehicleId;
        filters.vehicule = selectedOption;

        const updatedFilters = { ...filters, idVehicule: selectedOption ? selectedOption.idVehicule : '' };
        setFilters(updatedFilters);
    };

    //Load date
    const getCarburans = async (page, newPerPage) => {
        try {
            setLoading(true);

            const queryParams = new URLSearchParams({
                pageNumber: page,
                pageSize: newPerPage
            });

            if (authData.wilayas !== '') {
                const wilayasFilter = authData.wilayas
                queryParams.append("wilayas", wilayasFilter.join(','));
            } else {
                setLoading(false);
                return;
            }

            if (authData?.flottesAutorises != null) {
                const flottesAutorisesFilter = authData?.flottesAutorises
                queryParams.append("flottesAutorises", flottesAutorisesFilter.join(','));
            }
            else {
                setLoading(false);
                return
            }

            if (filters.idVehicule !== '') {
                queryParams.append("idVehicule", filters.vehicule.idVehicule);
            }

            if (filters.idFournisseur !== '') {
                queryParams.append("idFournisseur", filters.idFournisseur);
            }

            if (filters.dateDebut !== '') {
                queryParams.append("dateDebut", filters.dateDebut);
            }

            if (filters.dateFin !== '') {
                queryParams.append("dateFin", filters.dateFin);
            }

            const response = await axiosInstance.get(`${VehiculeCarburant}?${queryParams}`);
            const resp = await response.data;
            setCarburants(resp.data);
            setFiltredCarburants(resp.data);
            setPageNumber(resp.pageNumber);
            setPageSize(resp.pageSize);
            setTotalItems(resp.totalItems);
            setTotalPages(resp.totalPages);
            setLoading(false);
        }
        catch (err) {
            console.log(err);
            setLoading(false);
        }
    }

    const getVehicules = async (nomVehicule) => {
        try {
            const queryParams = new URLSearchParams({
                pageNumber: 1,
                pageSize: 10
            });

            if (authData?.wilayas != null) {
                const wilayasFilter = authData?.wilayas
                queryParams.append("wilayas", wilayasFilter.join(','));
            }
            else {
                return
            }

            if (authData?.flottesAutorises != null) {
                const flottesAutorisesFilter = authData?.flottesAutorises
                queryParams.append("flottesAutorises", flottesAutorisesFilter.join(','));
            }
            else {
                return
            }

            if (nomVehicule !== '') {
                queryParams.append("nom", nomVehicule);
            }
            const response = await axiosInstance.get(`${Vehicule}/GetVehiculesAutocomplete?${queryParams}`);
            const resp = await response.data;
            setVehicules(resp.data);
        }
        catch (err) {
            console.log(err);
        }
    }

    const getFournisseurs = async () => {
        try {
            const queryParams = new URLSearchParams({
                carburant: true
            });

            const response = await axiosInstance.get(`${Fournisseur}/GetFournisseurType?${queryParams}`);
            const resp = await response.data;
            setFournisseurs(resp);
        }
        catch (err) {
            console.log(err);
        }
    }

    //Supprimer une entrée de carburant

    const handleConfirmDelete = (idConsommationCarburant) => {
        // Show the modal when the confirm delete button is clicked
        //deleteCarburant(idConsommationCarburant);
        setIdCarburantDelete(idConsommationCarburant);
        setConfirmDialogOpenDelete(true);
    };

    const handleDelete = async () => {
        try {
            const request = {
                idConsommationCarburant: idCarburantDelete,
                idUtilisateur: authData.idUser
            }
            await axiosInstance.delete(`${VehiculeCarburant}`, {
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json'
                },
                data: JSON.stringify(request)
            });
            CustomToast("L'entrée de carburant a été supprimée avec succès", 'success');
            setIdCarburantDelete('');
            setConfirmDialogOpenDelete(false);
            getCarburans(pageNumber, pageSize);
        }
        catch (error) {
            // Handle error
            if (!error?.response) {
                CustomToast("Aucune réponse du serveur", 'error');
            } else if (error.response?.status === 400 && error.response?.data === "Fuel entry not found") {
                CustomToast("Entrée de carburant introuvable", 'error');
            } else if (error.response?.status === 401) {
                CustomToast("Non autorisé", 'error');
            } else {
                CustomToast("Demande échoué", 'error');
            }
            console.log(error);
        }
    }

    const handleCancelDelete = () => {
        setConfirmDialogOpenDelete(false);
    };

    //Search
    const handleSearch = (e) => {
        e.preventDefault();
        setPageNumber(1); // Reset page number when new search is triggered
        getCarburans(1, pageSize); // Fetch inspections with updated filters
    };

    useEffect(() => {
        //if (debouncedVehiculeText) {
        getVehicules(debouncedVehiculeText);
        //}
        return () => {
            // Perform any necessary cleanup (e.g., cancel pending requests)
        };
    }, [authData, debouncedVehiculeText]);

    useEffect(() => {
        //Load Data
        getFournisseurs();
        return () => {
            // Perform any necessary cleanup (e.g., cancel pending requests)
        };
    }, []);

    useEffect(() => {
        //Load Data

        getCarburans(pageNumber, pageSize);
        return () => {
            // Perform any necessary cleanup (e.g., cancel pending requests)
        };
    }, [authData, pageNumber, pageSize]);

    return (
        <section className="vehicles-section" onClick={() => { if (openAboutList !== null) { setOpenAboutList(null) } }}>
            <div className="container">
                <div className="carburant-sec wrap-all">
                    <Link to="/ajoutercarburant" className="compteur-add color-primary-2 decoration-none">
                        <div className="add-new" id="add-vehc">
                            <span className="material-symbols-outlined">add</span>
                            Ajouter une entrée de carburant
                        </div>
                    </Link>
                    <form onSubmit={handleSearch}>
                        <div className="filter-wrap">

                            <div className="date-container">
                                <input
                                    type="date"
                                    value={filters.dateDebut}
                                    onChange={(e) => setFilters({ ...filters, dateDebut: e.target.value })}
                                    className='date-filter'
                                />
                            </div>

                            <div className="date-container">
                                <input
                                    type="date"
                                    value={filters.dateFin}
                                    onChange={(e) => setFilters({ ...filters, dateFin: e.target.value })}
                                    className='date-filter'
                                />
                            </div>

                            <Select
                                id="vehicule"
                                name="vehicule"
                                value={filters.vehicule}
                                onChange={(selectedOption) => {
                                    handleVehicleChange(selectedOption);
                                }}
                                onInputChange={(inputValue) => {
                                    // Update the entered text
                                    setEnteredVehicleText(inputValue);
                                }}
                                options={vehicules}
                                getOptionLabel={(option) => option.marque + '[' + option.modele + '] ' + option.immatriculation}
                                getOptionValue={(option) => option.idVehicule}
                                isClearable
                                placeholder="Sélectionnez véhicule"
                                styles={{
                                    control: (baseStyles, state) => ({
                                        ...baseStyles,
                                        borderRadius: '1rem',
                                        borderWidth: '1px',
                                        height: '3rem',
                                        borderColor: state.isFocused ? 'rgba(115, 128, 236, 0.9)' : '#BEBEBE',
                                        backgroundColor: '#65458d',
                                        cursor: 'pointer',
                                        color: '#fff',
                                    }),
                                    option: (provided, state) => ({
                                        ...provided,
                                        backgroundColor: state.isFocused ? '#2581DC' : '#65458d',
                                        color: '#fff',
                                        cursor: 'pointer',
                                    }),
                                    singleValue: (provided, state) => ({
                                        ...provided,
                                        color: '#fff',
                                    }),
                                    placeholder: (provided, state) => ({
                                        ...provided,
                                        color: '#fff'
                                    }),
                                    input: (provided, state) => ({
                                        ...provided,
                                        color: '#fff',
                                    }),
                                }}
                            />

                            <select
                                id="idFournisseur"
                                name="idFournisseur"
                                className="select-filter"
                                value={filters.idFournisseur || ''}
                                onChange={(e) => setFilters({ ...filters, idFournisseur: e.target.value || '' })}
                            >
                                <option value="">Sélectionnez fournisseur</option>
                                {fournisseurs.map((fournisseur) => (
                                    <option key={fournisseur.idFournisseur} value={fournisseur.idFournisseur}>{fournisseur.raisonSociale}</option>
                                ))}
                            </select>

                            <div className="filter">
                                {/* <a href="#"><span className="material-symbols-outlined">filter_alt</span></a> */}
                                <button type="submit" className="linkButton">
                                    <span className="material-symbols-outlined">filter_alt</span>
                                </button>
                            </div>
                        </div>
                    </form>

                    {filtredCarburants?.length > 0 && <><div className="group-elements">
                        <div className="voir-elements">
                            <p></p>
                            <select
                                name="pageSize"
                                id="pageSize"
                                onChange={(e) => { handlePerRowsChange(e.target.value || 10); setPageNumber(1); }}
                            >
                                <option value="10" defaultValue={true}>10</option>
                                <option value="15">15</option>
                                <option value="20">20</option>
                            </select>
                        </div>
                        {/* <div className="search-element">
                            <p>Rechercher</p>
                            <input type="text" />
                        </div> */}
                    </div>
                        <div className="table-wrap">
                            <table>
                                <thead>
                                    <tr>
                                        <th onClick={() => handleSort('vehiculeNom')}>Véhicule</th>
                                        <th>Date d'entrée</th>
                                        <th>Fournisseur</th>
                                        <th>Compteur</th>
                                        <th>Type carburant</th>
                                        <th>Volume</th>
                                        <th>Coût</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {filtredCarburants?.map((vehicule) => (
                                        <tr key={vehicule.idConsommationCarburant}>
                                            <td>{vehicule.nomVehicule + ' ' + vehicule.immatriculationVehicule}</td>
                                            <td>{vehicule.dateRemplissage ? (moment(vehicule.dateRemplissage).format('DD-MM-YYYY HH:mm')) : ''}</td>
                                            <td>{vehicule.raisonSocialeFournisseur}</td>
                                            <td>{vehicule.valeurCompteur}</td>
                                            <td>{vehicule.libelleTypeCarburant}</td>
                                            <td>{vehicule.quantite}</td>
                                            <td>{formatCurrency(vehicule.cout)}</td>
                                            <td className="txt-center relative">
                                                <span className="material-symbols-outlined about-btn" onClick={() => toggleAboutList(vehicule.idConsommationCarburant)}>
                                                    more_horiz
                                                </span>
                                                {openAboutList === vehicule.idConsommationCarburant && (
                                                    <div className="about-list">
                                                        <ul>
                                                            <li>
                                                                <Link className='row row-center' to={`/modifiercarburant/${vehicule.idConsommationCarburant}`} style={{ textDecoration: "none" }}>
                                                                    <span className="material-symbols-outlined">edit</span>Modifier
                                                                </Link>
                                                            </li>
                                                            <li>
                                                                <Link className='row row-center' to="/listcarburants" style={{ textDecoration: "none" }} onClick={() => handleConfirmDelete(vehicule.idConsommationCarburant)}>
                                                                    <span className="material-symbols-outlined">delete</span>Supprimer
                                                                </Link>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                )}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        {/* <div className="pagination">
                            <Link to="#" onClick={() => handlePageChange(pageNumber - 1)}
                                className={(pageNumber === 1) ? 'pgn disabled' : 'pgn'}
                                disabled={(pageNumber === 1) ? true : false}
                            >&laquo;</Link>
                            {Array.from({ length: totalPages }, (_, i) => (
                                <Link
                                    key={i + 1}
                                    to="#"
                                    className={i + 1 === pageNumber ? 'pgn active' : 'pgn'}
                                    onClick={() => handlePageChange(i + 1)}
                                >
                                    {i + 1}
                                </Link>
                            ))}
                            <Link to="#" onClick={() => handlePageChange(pageNumber + 1)}
                                className={((pageNumber + 1) > totalPages) ? 'pgn disabled' : 'pgn'}
                                disabled={((pageNumber + 1) > totalPages) ? true : false}
                            >&raquo;</Link>
                        </div> */}
                        <div className="pagination">
                        <Link to="#" onClick={() => handlePageChange(pageNumber - 1)}
                            className={(pageNumber === 1) ? 'pgn disabled' : 'pgn'}
                            disabled={(pageNumber === 1) ? true : false}
                        >&laquo;</Link>
                        {Array.from({ length: Math.min(5, totalPages) }, (_, i) => {
                            const page = pageNumber - 2 + i;
                            return page <= totalPages && page > 0 && (
                                <Link
                                    key={page}
                                    to="#"
                                    className={pageNumber === page ? 'pgn active' : 'pgn'}
                                    onClick={() => handlePageChange(page)}
                                >
                                    {page}
                                </Link>
                            );
                        })}
                        <Link to="#" onClick={() => handlePageChange(pageNumber + 1)}
                            className={(pageNumber === totalPages || totalPages === 0) ? 'pgn disabled' : 'pgn'}
                            disabled={(pageNumber === totalPages || totalPages === 0) ? true : false}
                        >&raquo;</Link>
                        <span> Affichage de {(pageNumber === 1)?1:((pageNumber - 1) * pageSize+1)} à {pageNumber * pageSize} sur {totalItems} entrées</span>
                    </div>
                        </>}

                    <ConfirmationDialog
                        open={confirmDialogOpenDelete}
                        onClose={handleCancelDelete}
                        onConfirm={handleDelete}
                        titleMessage="Confirmer la suppression de l'entrée de carburant"
                        bodyMessage={"Êtes-vous sûr de vouloir supprimer cette entrée de carburant ? Toutes les données seront définitivement perdues."}
                        btnCancelText="Annuler"
                        btnConfirmText="Supprimer l'entrée de carburant"
                    />
                </div>
            </div>
        </section>
    );
}

export default ListCarburants;
