// LogoHeader.js
import React from 'react';

const LogoHeader = () => {
  return (
    <>
      <svg
        id="Layer_2"
        data-name="Layer 2"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1137.16 255.12"
      >
        <defs>
          <style>
            {`
              .cls-1 {
                letter-spacing: -.04em;
              }
              .cls-2 {
                letter-spacing: -.05em;
              }
              .cls-3 {
                letter-spacing: -.07em;
              }
              .cls-4 {
                fill: #ffc72f;
              }
              .cls-5 {
                letter-spacing: -.06em;
              }
              .cls-6 {
                fill: #65458d;
              }
              .cls-7, .cls-8 {
                fill: none;
              }
              .cls-9 {
                letter-spacing: -.07em;
              }
              .cls-8 {
                clip-path: url(#clippath);
              }
              .cls-10 {
                font-family: Futura-Bold, Futura;
                font-size: 150px;
                font-weight: 700;
                letter-spacing: .04em;
              }
              .cls-11 {
                letter-spacing: -.06em;
              }
              .cls-12 {
                font-family: Axiforma-Light, Axiforma;
                font-size: 28.1px;
                font-weight: 300;
              }
              .cls-13 {
                letter-spacing: -.04em;
              }
              .cls-14 {
                letter-spacing: -.01em;
              }
            `}
          </style>
          <clipPath id="clippath">
            <path className="cls-7" d="m99.1-2v225h220.61V-2H99.1Zm58.65,106.26c-13.3-14.25-34.74-21.84-55.97-17.1,3.58-20.31,12.95-38.51,27.29-53,20.01,1.96,39.18,8.87,55.18,20.26-16.68,8.84-26.31,26.91-26.5,49.84Z" />
          </clipPath>
        </defs>
        <g id="Layer_1-2" data-name="Layer 1">
          <text className="cls-10" transform="translate(354.59 205.64)">
            <tspan x="0" y="0">FleetGO</tspan>
          </text>
          <circle className="cls-4" cx="930.44" cy="69.05" r="21.71" />
          <g>
            <g>
              <path className="cls-6" d="m310.65,72.27c7.05,29.16,11.42,54.52.22,83.94-12.43,32.28-38.61,51.8-71.86,60.49-62.53,16.33-117.17-14.77-133.5-77.31C88.62,74.74,121.32,20.82,185.44,4.07c40.9-10.68,77.39-.48,104.25,32.24l-43.7,33.4c-12.09-17.14-30.43-25.87-51.28-20.43-34.04,8.89-43.24,45.96-35.31,76.31,8.06,30.87,34.74,57.45,68.78,48.56,22.43-5.86,36.55-21.95,32.66-45.46l-42.22,11.03-10.61-40.63,102.64-26.81Z" />
              <path className="cls-4" d="m223.4,111.14c16.82,64.38-17.5,121.83-81.36,138.51-63.85,16.68-121.88-16.64-138.7-81.03C-12.37,108.46,29.37,52.46,86.63,37.51c57.26-14.96,121.06,13.48,136.77,73.64Zm-166.17,43.69c8.96,34.3,40.84,52.75,71.71,44.69,30.87-8.06,49.65-39.75,40.69-74.05-7.17-27.44-39.05-45.89-69.92-37.83-30.87,8.06-49.65,39.75-42.49,67.19Z" />
              <g className="cls-8">
                <path className="cls-6" d="m310.65,72.27c7.05,29.16,11.42,54.52.22,83.94-12.43,32.28-38.61,51.8-71.86,60.49-62.53,16.33-117.17-14.77-133.5-77.31C88.62,74.74,121.32,20.82,185.44,4.07c40.9-10.68,77.39-.48,104.25,32.24l-43.7,33.4c-12.09-17.14-30.43-25.87-51.28-20.43-34.04,8.89-43.24,45.96-35.31,76.31,8.06,30.87,34.74,57.45,68.78,48.56,22.43-5.86,36.55-21.95,32.66-45.46l-42.22,11.03-10.61-40.63,102.64-26.81Z" />
              </g>
            </g>
            <polygon className="cls-6" points="235.11 112.31 244.79 150.73 206.67 139.9 168.56 129.07 197 101.47 225.44 73.88 235.11 112.31" />
          </g>
        </g>
      </svg>
    </>
  );
};

export default LogoHeader;
