//https://artskydj.github.io/jsPDF/docs/jsPDF.html#addFont
//https://github.com/simonbengtsson/jsPDF-AutoTable/blob/master/examples/examples.js#L722
import jsPDF from "jspdf";
import "jspdf-autotable";
import moment from 'moment/moment';

const ExportInspectionToPdf = (dataJson, fileName) => {
    if (!dataJson) return;

    const doc = new jsPDF({
        orientation: 'portrait',
        unit: 'mm',
        format: 'a4',
        putOnlyUsedFonts: true,
        compress: false
    });

    const totalPages = [];

    // Function to add page numbers to the footer
    const addPageNumbers = (data) => {
        const pageHeight = doc.internal.pageSize.height || 297; // A4 size by default
        const pageWidth = doc.internal.pageSize.width || 210; // A4 size by default
        const pageNumber = doc.internal.getNumberOfPages();

        doc.setFontSize(8);
        doc.setTextColor(0, 0, 0);
        doc.text(`Page ${pageNumber} sur ${totalPages.length}`, pageWidth - 30, pageHeight - 8);
    };

    doc.setFontSize(12);
    doc.setFont('helvetica', 'bold');
    doc.text("Rapport d'inspection du véhicule", 110, 30);
    doc.setFont('helvetica', 'bold');
    doc.setFontSize(8);
    doc.text("Véhicule: ", 10, 30);
    doc.setFont('helvetica', 'normal');
    doc.setFontSize(8);
    doc.text(`${dataJson.vehiculeMarque}[${dataJson.vehiculeModele}]`, 24, 30);
    doc.text(`${dataJson.vehiculeImmatriculation}`, 24, 35);

    doc.setFont('helvetica', 'bold');
    doc.setFontSize(8);
    doc.text("Date inspection: ", 10, 40);
    doc.setFont('helvetica', 'normal');
    doc.setFontSize(8);
    doc.text(`${moment(dataJson.dateInspection).format('DD-MM-YYYY')}`, 33, 40);

    doc.setFont('helvetica', 'bold');
    doc.setFontSize(8);
    doc.text("Soumis par: ", 10, 45);
    doc.setFont('helvetica', 'normal');
    doc.setFontSize(8);
    doc.text(`${dataJson.utilisateurCreationInspection}`, 28, 45);

    doc.setFontSize(10);
    doc.setFont('helvetica', 'bold');
    doc.text("Éléments d'inspection", 10, 55);

    let rows = dataJson.inspectionItems?.map((item) => [item.itemTitre, item.description, item.statut]);

    doc.autoTable({
        head: [['Éléments', 'Description', 'Statut']],
        body: rows,
        startY: 60,
        startX: 10,
        theme: 'grid',
        //styles: {fontSize: 8 , overflow:'visible'},//All table
        //margin: { top: 37 },
        //tableLineColor: [231, 76, 60],//Contour
        //tableLineWidth: 1,
        // styles: {
        //     lineColor: "red",//[44, 62, 80],
        //     lineWidth: 1,
        //   },
        headStyles: {
            //fillColor: [241, 196, 15,0],
            textColor: "black",
            fontSize: 12,
            fontStyle: 'normal',
        },
        footStyles: {
            //fillColor: [241, 196, 15],
            fontSize: 12,
        },
        bodyStyles: {
            //fillColor: [52, 73, 94],
            textColor: "black",
            fontSize: 10
        },
        alternateRowStyles: {
            //fillColor: [74, 96, 117],
        },
        //   columnStyles: {//style pour cell
        //     2: {//indice cell in row
        //       halign: 'center',
        //     },
        //   },
        allSectionHooks: true,
        // Use for customizing texts or styles of specific cells after they have been formatted by this plugin.
        // This hook is called just before the column width and other features are computed.
        // didParseCell: function (data) {
        //     if (data.row.index === 5) {
        //         data.cell.styles.fillColor = [40, 170, 100]
        //     }

        //     if (
        //         (data.row.section === 'head' || data.row.section === 'foot') &&
        //         data.column.dataKey === 'expenses'
        //     ) {
        //         data.cell.text = '' // Use an icon in didDrawCell instead
        //     }

        //     if (data.column.dataKey === 'city') {
        //         data.cell.styles.font = 'mitubachi'
        //         if (data.row.section === 'head') {
        //             data.cell.text = 'シティ'
        //         }
        //         if (data.row.index === 0 && data.row.section === 'body') {
        //             data.cell.text = 'とうきょう'
        //         }
        //     }
        // },
        // Use for changing styles with jspdf functions or customize the positioning of cells or cell text
        // just before they are drawn to the page.
        willDrawCell: function (data) {
            if (data.row.section === 'body' && data.column.dataKey === 2) {
                if (data.cell.raw === 'fail') {
                    doc.setTextColor(231, 76, 60); // Red
                    data.cell.text = 'Échouer'
                    // doc.addImage(
                    //     "https://picsum.photos/id/200/100",
                    //     'JPEG',
                    //     data.cell.x + 20,
                    //     data.cell.y + 2,
                    //     5,
                    //     5
                    // )
                } else {
                    doc.setTextColor(95, 188, 110); // Green
                    data.cell.text = 'Passer'
                }
            }
        },
        // Use for adding content to the cells after they are drawn. This could be images or links.
        // You can also use this to draw other custom jspdf content to cells with doc.text or doc.rect
        // for example.
        // didDrawCell: function (data) {
        //     if (
        //         (data.row.section === 'head' || data.row.section === 'foot') &&
        //         data.column.dataKey === 'expenses' &&
        //         coinBase64Img
        //     ) {
        //         doc.addImage(
        //             coinBase64Img,
        //             'PNG',
        //             data.cell.x + 5,
        //             data.cell.y + 2,
        //             5,
        //             5
        //         )
        //     }
        // },
        // Use this to add content to each page that has the autoTable on it. This can be page headers,
        // page footers and page numbers for example.
        didDrawPage: function (data) {//Entete avant tableau qui se repete dans chaque page
            // doc.setFontSize(18)
            // doc.text('Custom styling with hooks', 10, 22)
            // doc.setFontSize(12)
            // doc.text('Conditional styling of cells, rows and columns, cell and table borders, custom font, image in cell',10,30)
            totalPages.push(1);
            addPageNumbers(data);
          }
    });

    //console.log(doc)

    doc.save(`${fileName}.pdf`);

};

export { ExportInspectionToPdf };