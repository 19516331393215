import React from 'react';
import { Link } from 'react-router-dom';

const Divers = () => {
    return (
        <section className="donnee-section">
            <div className="container">
                <div className="divers-sec wrap-all">
                    <div className="grid grid--3-cols">
                        <div className="card">
                            <div className="row-1-gap row-center">
                                <span className="material-symbols-outlined">public</span>
                                <h3 className="heading-primary">Référentiel global</h3>
                            </div>
                            <div className="column-1-gap column-1-gap-start margin-top-2">
                                <Link to="/listwilayas" className="color-black txt-deco">
                                    <p>Wilayas</p>
                                </Link>
                                <Link to="/listcommunes" className="color-black txt-deco">
                                    <p>Communes</p>
                                </Link>
                                <Link to="/listudm" className="color-black txt-deco">
                                    <p>Unités de mesure</p>
                                </Link>
                                <Link to="/listeModespaiements" className="color-black txt-deco">
                                    <p>Mode de paiement</p>
                                </Link>
                            </div>
                        </div>

                        <div className="card">
                            <div className="row-1-gap row-center">
                                <span className="material-symbols-outlined">directions_car</span>
                                <h3 className="heading-primary">Flotte</h3>
                            </div>
                            <div className="column-1-gap column-1-gap-start margin-top-2">
                                <Link to="/listmarques" className="color-black txt-deco" id="marque-nav-down">
                                    <p>Marques</p>
                                </Link>
                                <Link to="/listmodeles" className="color-black txt-deco" id="modele-nav-down">
                                    <p>Modèles</p>
                                </Link>
                                <Link to="/listtypevehicules" className="color-black txt-deco" id="type-vehicule-nav-down">
                                    <p>Types véhicules</p>
                                </Link>
                                <Link to="/listparcs" className="color-black txt-deco">
                                    <p>Parc</p>
                                </Link>
                                <Link to="/listgroupes" className="color-black txt-deco">
                                    <p>Groupe</p>
                                </Link>
                            </div>
                        </div>

                        <div className="card">
                            <div className="row-1-gap row-center">
                                <span className="material-symbols-outlined">chronic</span>
                                <h3 className="heading-primary">Consommation</h3>
                            </div>
                            <div className="column-1-gap column-1-gap-start margin-top-2">
                                <Link to="/listtypecarburant" className="color-black txt-deco" id="type-carburant-nav-down"><p>Types de carburant</p></Link>
                                <Link to="/listtypedepenses" className="color-black txt-deco" id="type-depense-nav-down"><p>Types de dépenses</p></Link>
                            </div>
                        </div>

                        <div className="card">
                            <div className="row-1-gap row-center">
                                <span className="material-symbols-outlined">shield_person</span>
                                <h3 className="heading-primary">Administratif</h3>
                            </div>
                            <div className="column-1-gap column-1-gap-start margin-top-2">
                                <Link to="/listcatpermis" className="color-black txt-deco" id="categorie-permis-nav-down"><p>Catégories de permis</p></Link>
                                <Link to="/listpi" className="color-black txt-deco" id="type-piece-identite-nav-down"><p>Types pièces d'identités</p></Link>
                                <Link to="/listtypeassurance" className="color-black txt-deco" id="type-assurance-nav-down"><p>Types d'assurances</p></Link>
                            </div>
                        </div>

                        <div className="card">
                            <div className="row-1-gap row-center">
                                <span className="material-symbols-outlined">notifications</span>
                                <h3 className="heading-primary">Rappelle</h3>
                            </div>
                            <div className="column-1-gap column-1-gap-start margin-top-2">
                                <Link to="/listrappelleemp" className="color-black txt-deco" id="employe-nav-down"><p>Employé</p></Link>
                                <Link to="/listrappellevehicule" className="color-black txt-deco" id="vehicule-nav-down"><p>Véhicule</p></Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Divers;
