import { useNavigate } from "react-router-dom"

const Unauthorized = () => {
    const navigate = useNavigate();

    const goBack = () => { navigate("/empty", { replace: true }); }

    return (
        <div style={{ flexGrow: 1, display: "flex", alignItems: "center", flexDirection: "column", flexWrap: "nowrap", marginTop: '10rem' }}>
            <h1 style={{ fontSize: '6rem' }}>
                403!: Unauthorized
            </h1>
            <br />
            <h1>
                The current page is unavailable or you do not have permission to access.
            </h1>
            <div style={{ marginTop: '3rem' }}>
                <button onClick={goBack} className="submit-btn">Revenir en arrière</button>
            </div>
        </div>
    )
}

export default Unauthorized