import React, { useEffect, useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import { useFormik } from 'formik';
import { CustomToast } from '../../components/Toast/CustomToast';
import ConfirmationDialog from '../../components/Confirmation/ConfirmationDialog';
import { axiosInstance, Fournisseur } from '../../services/api';
import { AuthContext } from '../../contexts/AuthContext';

const ListFournisseurs = () => {
    const { authData } = useContext(AuthContext);

    const [fournisseurs, setFournisseurs] = useState([]);
    const [filtredFournisseurs, setFiltredFournisseurs] = useState([]);
    const [pageNumber, setPageNumber] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalItems, setTotalItems] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [loading, setLoading] = useState(true);
    const [idFournisseurDelete, setIdFournisseurDelete] = useState('');
    const [idFournisseurDetails, setIdFournisseurDetails] = useState('');
    const [filters, setFilters] = useState({
        statut: null,
        raisonSociale: null
    });

    const [search, setSearch] = useState("");

    const [showDetailsModal, setShowDetailsModal] = useState(false);
    const [confirmDialogOpenDelete, setConfirmDialogOpenDelete] = useState(false);
    const [confirmDialogOpenArchive, setConfirmDialogOpenArchive] = useState(false);

    //Table management
    const [sortBy, setSortBy] = useState(''); // Column name to sort by
    const [sortOrder, setSortOrder] = useState('asc'); // 'asc' or 'desc'

    const handleSort = (column) => {
        setSortBy(column);
        setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');

        let sortedTable = [...filtredFournisseurs].sort((a, b) => {
            const aValue = a[sortBy] || '';
            const bValue = b[sortBy] || '';

            if (sortOrder === 'asc') {
                return aValue.localeCompare(bValue);
            } else {
                return bValue.localeCompare(aValue);
            }
        });

        setFiltredFournisseurs(sortedTable);
    };

    const [openAboutList, setOpenAboutList] = useState(null);

    const toggleAboutList = (idMarque) => {
        // console.log(idMarque)
        setOpenAboutList(openAboutList === idMarque ? null : idMarque);
    };

    const handlePageChange = (page) => {
        setPageNumber(page);
        //consolelog("setPageNumber="+pageNumber)
    };

    const handlePerRowsChange = (newPerPage) => {
        setPageSize(newPerPage);
        //console.log("setPageSize="+pageSize);
    };

    const showHideDetailsModal = (val) => {
        setShowDetailsModal(val);
        formik.resetForm();
        //console.log(idFournisseurDetails)
        if (val) {
            getFournisseur(idFournisseurDetails);
        } else {
            setIdFournisseurDetails('');
        }
        handleCancelDelete();
    };

    //Supprimer Fournisseur
    const handleConfirmDelete = (idFournisseur) => {
        // Show the modal when the confirm delete button is clicked
        //deleteFournisseur(idFournisseur);
        setIdFournisseurDelete(idFournisseur);
        setIdFournisseurDetails(idFournisseur);
        setConfirmDialogOpenDelete(true);
    };

    const handleDelete = async () => {
        try {
            await axiosInstance.delete(`${Fournisseur}/${idFournisseurDelete}`);
            setIdFournisseurDelete('');
            setIdFournisseurDetails('');
            CustomToast('Le fournisseur a été supprimé avec succès', 'success');
            getFournisseurs(pageNumber, pageSize);
            setConfirmDialogOpenDelete(false);
        }
        catch (error) {
            // Handle error
            if (!error?.response) {
                CustomToast("Aucune réponse du serveur", 'error');
            } else if (error.response?.status === 400 && error.response?.data === "Vendor is already used. Cannot delete.") {
                // CustomToast("Propriétaire déjà utilisé", 'error');
                CustomToast(<div>Ce fournisseur est en cours d'utilisation et ne peut pas être supprimé. <a className='act' style={{ textDecoration: 'underline' }} onClick={() => showHideDetailsModal(true)}>Afficher l'utilisation</a></div>, 'error');
            } else if (error.response?.status === 401) {
                CustomToast("Non autorisé", 'error');
            } else {
                CustomToast("Demande échoué", 'error');
            }
            console.log(error);
        }
    }

    const handleCancelDelete = () => {
        setConfirmDialogOpenDelete(false);
    };

    //archiver fournisseur
    const archiveFournisseur = async (idFournisseur) => {
        try {

            const request = {
                idFournisseur: idFournisseur,
                idUtilisateur: authData.idUser
            }
            const response = await axiosInstance.put(`${Fournisseur}/archive`, request);
            getFournisseurs(pageNumber, pageSize);
            CustomToast('Le fournisseur a été archivé avec succès', 'success');
        }
        catch (err) {
            console.log(err);
        }
    }

    const handleConfirmArchive = (idFournisseur) => {
        // Show the modal when the confirm delete button is clicked
        setIdFournisseurDelete(idFournisseur);
        setConfirmDialogOpenArchive(true);
    };

    const handleArchive = async () => {
        try {
            const request = {
                idFournisseur: idFournisseurDelete,
                idUtilisateur: authData.idUser
            }
            const response = await axiosInstance.put(`${Fournisseur}/archive`, request);
            setIdFournisseurDelete('');
            CustomToast('Le fournisseur a été archivé avec succès', 'success');
            getFournisseurs(pageNumber, pageSize);
            setConfirmDialogOpenArchive(false);
        }
        catch (error) {
            // Handle error
            if (!error?.response) {
                CustomToast("Aucune réponse du serveur", 'error');
            } else if (error.response?.status === 400 && error.response?.data === "Vendor does not exist") {
                CustomToast("Le fournisseur n'existe pas", 'error');
            } else if (error.response?.status === 401) {
                CustomToast("Non autorisé", 'error');
            } else {
                CustomToast("Demande échoué", 'error');
            }
            console.log(error);
        }
    }

    const handleCancelArchive = () => {
        setConfirmDialogOpenArchive(false);
    };

    //restaurer fournisseur
    const restoreVehicule = async () => {
        try {

            const request = {
                idFournisseur: idFournisseurDelete,
                idUtilisateur: authData.idUser
            }
            const response = await axiosInstance.put(`${Fournisseur}/restore`, request);
            getFournisseurs(pageNumber, pageSize);
            CustomToast('Le fournisseur a été restauré avec succès', 'success');
        }
        catch (err) {
            console.log(err);
        }
    }

    const handleConfirmRestore = (idFournisseur) => {
        // Show the modal when the confirm delete button is clicked
        restoreVehicule(idFournisseur);
        setIdFournisseurDelete(idFournisseur);
    };

    const handleRestore = async () => {
        try {
            const request = {
                idVehicule: idFournisseurDelete,
                idUtilisateur: authData.idUser
            }
            const response = await axiosInstance.put(`${Fournisseur}/restore`, request);
            setIdFournisseurDelete('');
            CustomToast('Le fournisseur a été restauré avec succès', 'success');
            getFournisseurs(pageNumber, pageSize);
        }
        catch (error) {
            // Handle error
            if (!error?.response) {
                CustomToast("Aucune réponse du serveur", 'error');
            } else if (error.response?.status === 400 && error.response?.data === "Vendor does not exist") {
                CustomToast("Le fournisseur n'existe pas", 'error');
            } else if (error.response?.status === 401) {
                CustomToast("Non autorisé", 'error');
            } else {
                CustomToast("Demande échoué", 'error');
            }
            console.log(error);
        }
    }

    //Load data

    const formik = useFormik({
        initialValues: {
            countVehicule: 0,
            countDepense: 0,
            countCarburant: 0,
            countService: 0,
            countTravail: 0
        }
    });

    const getFournisseurs = async (page, newPerPage) => {
        try {
            setLoading(true);
            const queryParams = new URLSearchParams({
                pageNumber: page,
                pageSize: newPerPage
            });

            if (filters.statut != null) {
                queryParams.append("statut", filters.statut);
            }

            if (filters.raisonSociale != null) {
                queryParams.append("raisonSociale", filters.raisonSociale);
            }

            const response = await axiosInstance.get(`${Fournisseur}/GetFournisseurs?${queryParams}`);
            const resp = await response.data;
            setFournisseurs(resp.data);
            if (search != '') {
                const result = fournisseurs?.filter((item) =>
                    Object.values(item).some(
                        (value) =>
                            value &&
                            value.toString().toLowerCase().includes(search.toLowerCase())
                    )
                );
                setFiltredFournisseurs(result);
            } else {
                setFiltredFournisseurs(resp.data);
            }
            //setFiltredFournisseurs(resp.data);
            setPageNumber(resp.pageNumber);
            setPageSize(resp.pageSize);
            setTotalItems(resp.totalItems);
            setTotalPages(resp.totalPages);
            setLoading(false);
        }
        catch (err) {
            setLoading(false);
            console.log(err);
        }
    }

    const getFournisseur = async (idFournisseur) => {
        try {
            const queryParams = new URLSearchParams({
                idFournisseur: idFournisseur
            });
            const response = await axiosInstance.get(`${Fournisseur}/GetFournisseurUsage?${queryParams}`);
            const resp = await response.data;
            formik.values.countVehicule = resp.countVehicule;
            formik.values.countDepense = resp.countDepense;
            formik.values.countCarburant = resp.countCarburant;
            formik.values.countService = resp.countService;
            formik.values.countTravail = resp.countTravail;
        }
        catch (err) {
            console.log(err);
        }
    }

    useEffect(() => {
        //Load Data

        getFournisseurs(pageNumber, pageSize);
        return () => {
            // Perform any necessary cleanup (e.g., cancel pending requests)
        };
    }, [authData, pageNumber, pageSize, filters]);

    useEffect(() => {
        // const result=vehicules.filter(row=>{
        //     return row.vehiculeNom && row.vehiculeNom.toLowerCase().match(search.toLowerCase());   
        // })
        const result = fournisseurs.filter((item) =>
            Object.values(item).some(
                (value) =>
                    value &&
                    value.toString().toLowerCase().includes(search.toLowerCase())
            )
        );
        setFiltredFournisseurs(result);
    }, [search])

    return (
        <section className="donnee-section" onClick={() => { if (openAboutList !== null) { setOpenAboutList(null) } }}>
            <div className="container">
                <div className="fournisseur-table wrap-all">
                    <Link to="/ajouterfournisseur" className="fournisseur-add color-primary-2 decoration-none">
                        <div className="add-new" id="add-vehc">
                            <span className="material-symbols-outlined">add</span>
                            Ajouter un fournisseur
                        </div>
                    </Link>

                    {filtredFournisseurs?.length > 0 && <><div className="group-elements">
                        <div className="voir-elements">
                            <p></p>
                            <select
                                name="pageSize"
                                id="pageSize"
                                onChange={(e) => { handlePerRowsChange(e.target.value || 10); setPageNumber(1); }}
                            >
                                <option value="10" defaultValue={true}>10</option>
                                <option value="15">15</option>
                                <option value="20">20</option>
                            </select>
                        </div>
                        {/* <div className="search-element">
                            <p>Rechercher</p>
                            <input type="text" />
                        </div> */}
                    </div>
                        <div className="table-wrap">
                            <table>
                                <thead>
                                    <tr>
                                        <th onClick={() => handleSort('raisonSociale')}>Raison sociale</th>
                                        <th>Adresse</th>
                                        <th onClick={() => handleSort('statut')}>Statut</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {filtredFournisseurs?.map((row) => (
                                        <tr key={row.idFournisseur}>
                                            <td>{row.raisonSociale}</td>
                                            <td>{row.adresse}</td>
                                            <td>
                                                {
                                                    row.statut === "actif" ? (
                                                        <>
                                                            <svg height="11.87" width="15"><circle cx="6" cy="6" r="6" fill="green" /></svg>Actif
                                                        </>
                                                    ) : row.statut === "inactif" ? (
                                                        <>
                                                            <svg height="11.87" width="15"><circle cx="6" cy="6" r="6" fill="red" /></svg>Inactif
                                                        </>
                                                    ) : row.statut === "archivé" ? (
                                                        <>
                                                            <svg height="11.87" width="15"><circle cx="6" cy="6" r="6" fill="grey" /></svg>Archivé
                                                        </>
                                                    ) : null
                                                }
                                            </td>
                                            <td className="txt-center relative">
                                                <span className="material-symbols-outlined about-btn" onClick={() => toggleAboutList(row.idFournisseur)}>
                                                    more_horiz
                                                </span>
                                                {openAboutList === row.idFournisseur && (
                                                    <div className="about-list">
                                                        <ul>
                                                            <li>
                                                                <Link className='row row-center' to={`/modifierfournisseur/${row.idFournisseur}`} style={{ textDecoration: "none" }}>
                                                                    <span className="material-symbols-outlined">edit</span>Modifier
                                                                </Link>
                                                            </li>

                                                            <li><Link className='row row-center' to="/listfournisseurs" style={{ textDecoration: "none" }} onClick={() => handleConfirmDelete(row.idFournisseur)}>
                                                                <span className="material-symbols-outlined">delete</span>Supprimer
                                                            </Link>
                                                            </li>

                                                            {(row.statut != "archivé") && <li><Link className='row row-center' to="/listfournisseurs" style={{ textDecoration: "none" }} onClick={() => handleConfirmArchive(row.idFournisseur)}>
                                                                <span className="material-symbols-outlined">archive</span>Archiver
                                                            </Link>
                                                            </li>}

                                                            {(row.statut == "archivé") && <li><Link className='row row-center' to="/listfournisseurs" style={{ textDecoration: "none" }} onClick={() => handleConfirmRestore(row.idFournisseur)}>
                                                                <span className="material-symbols-outlined">restore</span>Restaurer
                                                            </Link>
                                                            </li>}
                                                        </ul>
                                                    </div>
                                                )}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        {/* <div className="pagination">
                            <Link to="#" onClick={() => handlePageChange(pageNumber - 1)}
                                className={(pageNumber === 1) ? 'pgn disabled' : 'pgn'}
                                disabled={(pageNumber === 1) ? true : false}
                            >&laquo;</Link>
                            {Array.from({ length: totalPages }, (_, i) => (
                                <Link
                                    key={i + 1}
                                    to="#"
                                    className={i + 1 === pageNumber ? 'pgn active' : 'pgn'}
                                    onClick={() => handlePageChange(i + 1)}
                                >
                                    {i + 1}
                                </Link>
                            ))}
                            <Link to="#" onClick={() => handlePageChange(pageNumber + 1)}
                                className={((pageNumber + 1) > totalPages) ? 'pgn disabled' : 'pgn'}
                                disabled={((pageNumber + 1) > totalPages) ? true : false}
                            >&raquo;</Link>
                        </div> */}
                        <div className="pagination">
                            <Link to="#" onClick={() => handlePageChange(pageNumber - 1)}
                                className={(pageNumber === 1) ? 'pgn disabled' : 'pgn'}
                                disabled={(pageNumber === 1) ? true : false}
                            >&laquo;</Link>
                            {Array.from({ length: Math.min(5, totalPages) }, (_, i) => {
                                const page = pageNumber - 2 + i;
                                return page <= totalPages && page > 0 && (
                                    <Link
                                        key={page}
                                        to="#"
                                        className={pageNumber === page ? 'pgn active' : 'pgn'}
                                        onClick={() => handlePageChange(page)}
                                    >
                                        {page}
                                    </Link>
                                );
                            })}
                            <Link to="#" onClick={() => handlePageChange(pageNumber + 1)}
                                className={(pageNumber === totalPages || totalPages === 0) ? 'pgn disabled' : 'pgn'}
                                disabled={(pageNumber === totalPages || totalPages === 0) ? true : false}
                            >&raquo;</Link>
                            <span> Affichage de {(pageNumber === 1)?1:((pageNumber - 1) * pageSize+1)} à {pageNumber * pageSize} sur {totalItems} entrées</span>
                        </div>
                    </>}
                </div>

                <ConfirmationDialog
                    open={confirmDialogOpenDelete}
                    onClose={handleCancelDelete}
                    onConfirm={handleDelete}
                    titleMessage="Confirmer la suppression du fournisseur"
                    bodyMessage={"Voulez-vous vraiment supprimer ce fournisseur ? Toutes les données seront définitivement perdues."}
                    btnCancelText="Annuler"
                    btnConfirmText="Supprimer le fournisseur"
                />
                <ConfirmationDialog
                    open={confirmDialogOpenArchive}
                    onClose={handleCancelArchive}
                    onConfirm={handleArchive}
                    titleMessage="Confirmer l'archivage du fournisseur"
                    bodyMessage={"Voulez-vous vraiment archiver cet enregistrement ? L'archivage d'un enregistrement ne le supprime pas de <i>FleetGo</i>, mais il ne sera plus utilisable."}
                    btnCancelText="Annuler"
                    btnConfirmText="Archiver le fournisseur"
                />

                {showDetailsModal && <div className="popcard-add-wilaya-diver">
                    <div className="popcard-add-wilaya-diver_inside">
                        <Link className="popcard-add-wilaya-diver_inside-close" to="#" onClick={() => setShowDetailsModal(false)}>&#10006;</Link>
                        <h2 className="popcard-add-wilaya-diver_inside-title">Utilisation du fournisseur</h2>
                        <div className="popcard-add-wilaya-diver_inside-form">
                            <div className="wrap-info">
                                <div className="wrap-one">
                                    <label>Véhicule(s) : {formik.values.countVehicule}</label>
                                </div>
                                <div className="wrap-one">
                                    <label>Dépenses(s) : {formik.values.countDepense}</label>
                                </div>
                                <div className="wrap-one">
                                    <label>Carburant(s) : {formik.values.countCarburant}</label>
                                </div>
                                <div className="wrap-one">
                                    <label>Entrée(s) service : {formik.values.countService}</label>
                                </div>
                                <div className="wrap-one">
                                    <label>Ordre(s) de travail: {formik.values.countTravail}</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>}
            </div>
        </section>
    );
}

export default ListFournisseurs;
