import React, { useEffect, useState, useContext, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import moment from 'moment/moment';
import Select from "react-select";
import { CustomToast } from '../../components/Toast/CustomToast';
import useDebounce from '../../hooks/useDebounce';
import { axiosInstance, Vehicule, VehiculeAssurance, TypeAssuranceVehicule } from '../../services/api';
import { AuthContext } from '../../contexts/AuthContext';
const AjouterAssurance = () => {
    const { authData } = useContext(AuthContext);
    const navigate = useNavigate();

    const [vehicules, setVehicules] = useState([]);
    const [typesAssurance, setTypesAssurance] = useState([]);

    //const [searchVehicule, setSearchVehicule] = useState("");
    const [selectedVehicle, setSelectedVehicle] = useState('');
    const [selectedVehicleId, setSelectedVehicleId] = useState('');
    const [enteredVehicleText, setEnteredVehicleText] = useState('');
    const debouncedVehiculeText = useDebounce(enteredVehicleText, 300);

    const [pathImageAssurance, setPathImageAssurance] = useState(null);
    const [pathImageAssuranceName, setPathImageAssuranceName] = useState('');
    const pathImageAssuranceRef = useRef(null);

    const handlePathImageAssuranceChange = (e) => {
        setPathImageAssurance(e.target.files[0]);
        setPathImageAssuranceName('');
    };

    const getImageFileName = (file) => {
        return file ? file.name : 'No file selected';
    };

    //Load data     

    const handleVehicleChange = (selectedOption) => {
        setSelectedVehicle(selectedOption);
        setSelectedVehicleId(selectedOption ? selectedOption.idVehicule : '');
        values.idVehicule = selectedVehicleId;
    };

    const getVehicules = async (nomVehicule) => {
        try {
            const queryParams = new URLSearchParams({
                pageNumber: 1,
                pageSize: 10
            });

            if (authData?.wilayas != null) {
                const wilayasFilter = authData?.wilayas
                queryParams.append("wilayas", wilayasFilter.join(','));
            }
            else {
                return
            }

            if (authData?.flottesAutorises != null) {
                const flottesAutorisesFilter = authData?.flottesAutorises
                queryParams.append("flottesAutorises", flottesAutorisesFilter.join(','));
            }
            else {
                return
            }

            if (nomVehicule !== '') {
                queryParams.append("nom", nomVehicule);
            }
            const response = await axiosInstance.get(`${Vehicule}/GetVehiculesAutocomplete?${queryParams}`);
            const resp = await response.data;
            setVehicules(resp.data);
        }
        catch (err) {
            console.log(err);
        }
    }

    const getTypesAssurance = async () => {
        try {
            const response = await axiosInstance.get(`${TypeAssuranceVehicule}`);
            const resp = await response.data;
            setTypesAssurance(resp);
        }
        catch (err) {
            console.log(err);
        }
    }

    const { handleChange, values, handleSubmit, touched, errors, setFieldValue } = useFormik({
        initialValues: {
            vehicule: '',
            idVehicule: '',
            numeroPolice: '',
            idTypeAssurance: '',
            dateDebut: '',
            dateFin: '',
            montant: '',
            couverture: '',
            compagnie: '',
            adresse: '',
            numTel: '',
            email: '',
            uniteIntervalleTemps: 'jour',
            echeanceTemps: ''
        },
        validationSchema: Yup.object({
            vehicule: Yup.object()
                .nullable()
                .required('Champ obligatoire'),
            dateDebut: Yup.string()
                .required("Champ obligatoire"),
            dateFin: Yup.string()
                .required("Champ obligatoire"),
            idTypeAssurance: Yup.string()
                .required("Champ obligatoire"),
            email: Yup.string()
                .email("Le champ doit contenir un e-mail valide")
                .max(255, "Le champ doit contenir au maximum 255 caractères"),
            echeanceTemps: Yup.string()
                .required("Champ obligatoire"),
        }),
        onSubmit: async (values) => {
            try {
                const formData = new FormData();

                formData.append('idVehiculeAssurance', "");
                formData.append('numeroPolice', values.numeroPolice);
                formData.append('idVehicule', values.vehicule.idVehicule);
                formData.append('idTypeAssurance', values.idTypeAssurance);
                formData.append('dateDebut', values.dateDebut);
                formData.append('dateFin', values.dateFin);

                formData.append('montant', values.montant);
                formData.append('couverture', values.couverture);
                formData.append('compagnie', values.compagnie);
                formData.append('adresse', values.adresse);
                formData.append('numTel', values.numTel);
                formData.append('email', values.email);

                formData.append('uniteIntervalleTemps', values.uniteIntervalleTemps);
                formData.append('echeanceTemps', values.echeanceTemps);
                formData.append('pathImageAssurance', pathImageAssurance);
                formData.append('idUtilisateurAction', authData.idUser);

                const response = await axiosInstance.post(VehiculeAssurance, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });

                CustomToast("Le dossier a été créé avec succès", 'success');
                navigate('/assurances', { replace: true });
            } catch (error) {
                // Handle error
                if (!error?.response) {
                    CustomToast("Aucune réponse du serveur", 'error');
                } else if (error.response?.status === 400 && error.response?.data === "Overlap existing insurance") {
                    CustomToast("Overlap existing insurance", 'error');
                } else if (error.response?.status === 401) {
                    CustomToast("Non autorisé", 'error');
                } else {
                    CustomToast("Demande échoué", 'error');
                }
                console.log(error);
            }
        }
    });

    useEffect(() => {
        getTypesAssurance();
        return () => {

        }
    }, [authData])

    useEffect(() => {
        //if (debouncedVehiculeText) {
        getVehicules(debouncedVehiculeText);
        //}
        return () => {
            // Perform any necessary cleanup (e.g., cancel pending requests)
        };
    }, [authData, debouncedVehiculeText]);


    return (
        <section className="vehicles-section">
            <div className="container">
                <form onSubmit={handleSubmit} className="assurance-form">
                    <div className="">
                        <div className="grid grid--2-cols">
                            <div className="form-sec">
                                <div className="retour" id="retour-assurance">
                                    <Link to="/assurances" style={{ textDecoration: "none" }}>
                                        <div className="arrow-back" id="arrow-back-affectation-form">
                                            <span className="material-symbols-outlined" id="arrow">arrow_back</span>
                                            <h3 className="heading-primary"></h3>
                                        </div>
                                    </Link>
                                </div>
                                <div className="form-details">
                                    <div className="detail-form" id="identification-form">
                                        <h3 className="heading-primary"></h3>
                                        <div className="col">
                                            <div className="grp">
                                                <div className="gp">
                                                    <label >Véhicule<span className='requiredField'>*</span></label>
                                                    <Select
                                                        id="vehicule"
                                                        name="vehicule"
                                                        value={values.vehicule}
                                                        onChange={(option) => { setFieldValue('vehicule', option); }}
                                                        options={vehicules}
                                                        onInputChange={(inputValue) => {
                                                            // Update the entered text
                                                            setEnteredVehicleText(inputValue);
                                                        }}
                                                        getOptionLabel={(option) => option.marque + '[' + option.modele + '] ' + option.immatriculation}
                                                        getOptionValue={(option) => option.idVehicule}
                                                        isClearable
                                                        styles={{
                                                            container: (provided, state) => ({
                                                                ...provided,
                                                                boxShadow: state.isFocused ? '0px 0px 5px 0px #65458d' : errors.vehicule ? 'red' : '#BEBEBE',
                                                            }),
                                                            control: (baseStyles, state) => ({
                                                                ...baseStyles,
                                                                minHeight: '19px',
                                                                outline: '0!important',
                                                                borderRadius: '0.5rem',
                                                                borderWidth: '1px',
                                                                fontSize: '1rem',
                                                                height: '3.1rem',
                                                            }),
                                                            option: (provided, state) => ({
                                                                ...provided,
                                                                fontSize: '1rem'
                                                            }),
                                                            singleValue: (provided, state) => ({
                                                                ...provided,
                                                                fontSize: '1rem',
                                                                margin: '0',
                                                            }),
                                                            valueContainer: (provided, state) => ({
                                                                ...provided,
                                                                alignItems: 'start',
                                                                fontSize: '1rem'
                                                            }),
                                                        }}
                                                    />
                                                    {touched.vehicule && errors.vehicule && (
                                                        <div className="invalid-feedback">{errors.vehicule}</div>
                                                    )}

                                                </div>
                                            </div>
                                            <div className="grp">
                                                <div className="gp">
                                                    <label >Numéro de police</label>
                                                    <input
                                                        id="numeroPolice"
                                                        name="numeroPolice"
                                                        type="text"
                                                        value={values.numeroPolice}
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                                <div className="gp">
                                                    <label >Type d'assurance<span className='requiredField'>*</span></label>
                                                    <select
                                                        id="idTypeAssurance"
                                                        name="idTypeAssurance"
                                                        value={values.idTypeAssurance}
                                                        onChange={handleChange}
                                                        style={(touched.idTypeAssurance && errors.idTypeAssurance) ? { borderColor: "red" } : null}
                                                    >
                                                        <option value="">Veuillez sélectionner</option>
                                                        {typesAssurance.map((typeAssurance) => (
                                                            <option key={typeAssurance.idTypeAssurance} value={typeAssurance.idTypeAssurance}>{typeAssurance.libelleTypeAssurance}</option>
                                                        ))}
                                                    </select>
                                                    {touched.idTypeAssurance && errors.idTypeAssurance ? (
                                                        <div className="invalid-feedback">{errors.idTypeAssurance}</div>
                                                    ) : null}
                                                </div>
                                            </div>
                                            <div className="grp">
                                                <div className="gp">
                                                    <label >Date début de couverture<span className='requiredField'>*</span></label>
                                                    <input
                                                        type="date"
                                                        id="dateDebut"
                                                        name="dateDebut"
                                                        style={(touched.dateDebut && errors.dateDebut) ? { borderColor: "red" } : null}
                                                        placeholder=""
                                                        defaultValue={values.dateDebut}
                                                        onChange={handleChange}
                                                    />
                                                    {touched.dateDebut && errors.dateDebut ? (
                                                        <div className="invalid-feedback">{errors.dateDebut}</div>
                                                    ) : null}
                                                </div>
                                                <div className="gp">
                                                    <label >Date fin de couverture<span className='requiredField'>*</span></label>
                                                    <input
                                                        type="date"
                                                        id="dateFin"
                                                        name="dateFin"
                                                        style={(touched.dateFin && errors.dateFin) ? { borderColor: "red" } : null}
                                                        placeholder=""
                                                        defaultValue={values.dateFin}
                                                        onChange={handleChange}
                                                    />
                                                    {touched.dateFin && errors.dateFin ? (
                                                        <div className="invalid-feedback">{errors.dateFin}</div>
                                                    ) : null}
                                                </div>
                                            </div>
                                            <div className="grp">
                                                <div className="gp" title="Le temps à l'avance pendant lequel vous considérez le rappel comme étant '' à échéance prochaine''">
                                                    <label >Seuil d'échéance prochaine<span className='requiredField'>*</span></label>
                                                    <input
                                                        type="number"
                                                        id="echeanceTemps"
                                                        name="echeanceTemps"
                                                        style={(touched.echeanceTemps && errors.echeanceTemps) ? { borderColor: "red" } : null}
                                                        placeholder=""
                                                        defaultValue={values.echeanceTemps}
                                                        onChange={handleChange}
                                                    />
                                                    {touched.echeanceTemps && errors.echeanceTemps ? (
                                                        <div className="invalid-feedback">{errors.echeanceTemps}</div>
                                                    ) : null}
                                                </div>
                                                <div className="gp">
                                                    <select
                                                        id="uniteIntervalleTemps"
                                                        name="uniteIntervalleTemps"
                                                        value={values.uniteIntervalleTemps}
                                                        style={(touched.echeanceTemps && errors.echeanceTemps) ? { borderColor: "red" } : null}
                                                        onChange={handleChange}
                                                    >
                                                        <option value="jour" defaultValue={true}>Jour(s)</option>
                                                        <option value="mois">Mois</option>
                                                    </select>
                                                    {touched.echeanceTemps && errors.echeanceTemps ? (
                                                        <div className="invalid-feedback">{errors.echeanceTemps}</div>
                                                    ) : null}
                                                </div>
                                            </div>
                                            <div className="grp">
                                                <div className="gp">
                                                    <label >Montant</label>
                                                    <input
                                                        id="montant"
                                                        name="montant"
                                                        type="number"
                                                        value={values.montant}
                                                        onChange={handleChange}
                                                        step="any"
                                                    />
                                                </div>
                                                <div className="gp">
                                                    <label >Couverture</label>
                                                    <input
                                                        id="couverture"
                                                        name="couverture"
                                                        type="text"
                                                        value={values.couverture}
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                            </div>
                                            <div className="grp">
                                                <div className="gp">
                                                    <label >Compagnie d'assurance</label>
                                                    <input
                                                        id="compagnie"
                                                        name="compagnie"
                                                        type="text"
                                                        value={values.compagnie}
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                                <div className="gp">
                                                    <label >Adresse</label>
                                                    <input
                                                        id="adresse"
                                                        name="adresse"
                                                        type="text"
                                                        value={values.adresse}
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                            </div>
                                            <div className="grp">
                                                <div className="gp">
                                                    <label >Email</label>
                                                    <input type="email"
                                                        id="email"
                                                        name="email"
                                                        style={(touched.email && errors.email) ? { borderColor: "red" } : null}
                                                        placeholder=""
                                                        defaultValue={values.email}
                                                        onChange={handleChange}
                                                    />
                                                    {touched.email && errors.email ? (
                                                        <div className="invalid-feedback">{errors.email}</div>
                                                    ) : null}
                                                </div>
                                                <div className="gp">
                                                    <label >N° de téléphone</label>
                                                    <input
                                                        id="numTel"
                                                        name="numTel"
                                                        type="text"
                                                        value={values.numTel}
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                            </div>
                                            <div className="grp">
                                                <div className="gp">
                                                    <label >Document</label>
                                                    <label className="upload-file">
                                                        upload file
                                                        <input
                                                            ref={pathImageAssuranceRef}
                                                            id="pathImageAssurance"
                                                            name="pathImageAssurance"
                                                            type="file"
                                                            onChange={handlePathImageAssuranceChange}
                                                        />
                                                    </label>
                                                    {pathImageAssurance && <span style={{ fontSize: "10px" }}>{getImageFileName(pathImageAssurance)}
                                                        <span className="material-symbols-outlined" onClick={(e) => { setPathImageAssurance(null); if (pathImageAssuranceRef.current) { pathImageAssuranceRef.current.value = ''; } }}>delete</span>
                                                    </span>}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="submit-container">
                                    <button type="submit" className="submit-btn">Enregistrer</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </section>
    );
}

export default AjouterAssurance;
