import React, { useEffect, useState, useContext, useRef } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import moment from 'moment/moment';
import Select from "react-select";
import { CustomToast } from '../../components/Toast/CustomToast';
import { axiosInstance, Vehicule, VehiculeDepense, TypeDepense, Fournisseur, ModePaiement } from '../../services/api';
import { AuthContext } from '../../contexts/AuthContext';

const ModifierDepense = () => {
    const { authData } = useContext(AuthContext);
    const navigate = useNavigate();
    const { id } = useParams();//idDepense

    const [depenseLoaded, setDepenseLoaded] = useState();
    const [typesDepenses, setTypesDepenses] = useState([]);
    const [fournisseurs, setFournisseurs] = useState([]);
    const [modePaiements, setModePaiements] = useState([]);

    const pathImageDepenseRef = useRef(null);
    const [pathImageDepense, setPathImageDepense] = useState(null);
    const [pathImageDepenseName, setPathImageDepenseName] = useState(null);

    const [selectedVehicle, setSelectedVehicle] = useState(null);
    const [selectedVehicleId, setSelectedVehicleId] = useState(null);

    const handleVehicleChange = (selectedOption) => {
        setSelectedVehicle(selectedOption);
        setSelectedVehicleId(selectedOption ? selectedOption.idVehicule : null);
        values.idVehicule = selectedVehicleId;
    };

    const getImageFileName = (file) => {
        return file ? file.name : 'No file selected';
    };

    //Load data

    const handlePathImageDepenseChange = (e) => {
        setPathImageDepense(e.target.files[0]);
    };

    const getDepense = async (idDepense) => {
        try {
            const response = await axiosInstance.get(`${VehiculeDepense}/${idDepense}`);
            const resp = await response.data;
            setDepenseLoaded(resp);
            // values.idVehicule = resp.idVehicule;
            // values.idTypeDepense = resp.idTypeDepense;
            // values.montant = resp.montant;
            // values.dateDepense = moment(resp.dateDepense).format('YYYY-MM-DD') || '';
            // values.idFournisseur = resp.idFournisseur || '';
            // values.remarque = resp.remarque || '';
            setValues(prevValues => ({
                ...prevValues,
                vehicule: null,
                idVehicule: resp.idVehicule || '',
                idTypeDepense: resp.idTypeDepense || '',
                idFournisseur: resp.idFournisseur || '',
                montant: resp.montant || '',
                dateDepense: moment(resp.dateDepense).isValid() ? moment(resp.dateDepense).format('YYYY-MM-DD') : '' || '',
                remarque: resp.remarque || '',
                idModePaiement: resp.idModePaiement || ''
            }));
            setPathImageDepenseName(resp.pathImageDepense || '');
            //values.vehicule = '';
        }
        catch (err) {
            console.log(err);
        }
    }

    const getVehicules = async (idVehicule) => {
        try {
            //console.log("getVehicules")
            //if(idVehicule==''){return}
            const response = await axiosInstance.get(`${Vehicule}/GetById/${idVehicule}`);
            const resp = await response.data;

            setFieldValue('vehicule', resp.nom + ' ' + resp.immatriculation || '');
        }
        catch (err) {
            console.log(err);
        }
    }

    const getFournisseurs = async () => {
        try {

            const response = await axiosInstance.get(`${Fournisseur}/GetFournisseurType`);
            const resp = await response.data;
            setFournisseurs(resp);
        }
        catch (err) {
            console.log(err);
        }
    }

    const getTypesDepenses = async () => {
        try {
            const response = await axiosInstance.get(`${TypeDepense}`);
            const resp = await response.data;
            setTypesDepenses(resp);
        }
        catch (err) {
            console.log(err);
        }
    }

    const getModesPaiements = async () => {
        try {
            const response = await axiosInstance.get(`${ModePaiement}`);
            const resp = await response.data;
            setModePaiements(resp);
        }
        catch (err) {
            console.log(err);
        }
    }

    const { handleChange, values, handleSubmit, touched, errors, setFieldValue, setValues } = useFormik({
        initialValues: {
            vehicule: null,
            idVehicule: '',
            idTypeDepense: '',
            idFournisseur: '',
            montant: '',
            dateDepense: '',
            remarque: '',
            idModePaiement: ''
        },
        validationSchema: Yup.object({
            dateDepense: Yup.string()
                .required("Champ obligatoire"),
            idTypeDepense: Yup.string()
                .required("Champ obligatoire"),
            montant: Yup.number()
                .required("Champ obligatoire")
                .min(0, "Le montant ne peut pas être négative"),
        }),
        onSubmit: async (values) => {
            try {
                // const postData = {
                //     idDepense: id,
                //     idTypeDepense: values.idTypeDepense,
                //     idFournisseur: values.idFournisseur,
                //     montant: values.montant.toString() || '',
                //     dateDepense: values.dateDepense,
                //     remarque: values.remarque,
                //     pathImageDepense: pathImageDepense,
                //     idUtilisateurAction: authData.idUser,
                // };
                // console.log(postData);
                const formData = new FormData();
                formData.append('idDepense', id);
                formData.append('idTypeDepense', values.idTypeDepense);
                formData.append('idFournisseur', values.idFournisseur);
                formData.append('montant', values.montant.toString());
                formData.append('dateDepense', values.dateDepense);
                formData.append('remarque', values.remarque);
                formData.append('idModePaiement', values.idModePaiement);
                formData.append('pathImageDepense', pathImageDepense);
                formData.append('pathImageDepenseName', pathImageDepenseName);
                formData.append('idUtilisateurAction', authData.idUser);

                await axiosInstance.put(VehiculeDepense, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });

                CustomToast("L'entrée de dépenses a été modifiée avec succès", 'success');
                navigate('/listdepenses', { replace: true });
            } catch (error) {
                // Handle error 
                if (!error?.response) {
                    CustomToast("Aucune réponse du serveur", 'error');
                } else if (error.response?.status === 400 && error.response?.data === "Expense not found") {
                    CustomToast("Dépense introuvable", 'error');;
                } else if (error.response?.status === 401) {
                    CustomToast("Non autorisé", 'error');
                } else {
                    CustomToast("Demande échoué", 'error');
                }
                console.log(error);
            }
        }
    });

    useEffect(() => {
        getDepense(id);
        getFournisseurs();
        getTypesDepenses();
        getModesPaiements();
    }, [id])

    useEffect(() => {
        // console.log("depenseLoaded");
        if (values.idVehicule !== '' && values.vehicule == null) {
            getVehicules(values.idVehicule);
        }

    }, [depenseLoaded])

    // if(values.idVehicule!='' && values.vehicule==''){
    //     getVehicules(values.idVehicule);
    // } 

    return (
        <section className="vehicles-section">
            <div className="container">
                <form onSubmit={handleSubmit} className="affectation-vehicule-form">
                    <div className="">
                        <div className="grid grid--2-cols">
                            <div className="form-sec">
                                <div className="retour" id="retour-affectation-vehicule">
                                    <Link to="/listdepenses" style={{ textDecoration: "none" }}>
                                        <div className="arrow-back" id="arrow-back-affectation-form">
                                            <span className="material-symbols-outlined" id="arrow">arrow_back</span>
                                            <h3 className="heading-primary"></h3>
                                        </div>
                                    </Link>
                                </div>
                                {depenseLoaded && <div className="form-details">
                                    <div className="detail-form" id="identification-form">
                                        <div className="col">
                                            <div className="grp">
                                                <div className="gp">
                                                    <label >Véhicule<span className='requiredField'>*</span></label>
                                                    <input
                                                        id="vehicule"
                                                        name="vehicule"
                                                        type='text'
                                                        defaultValue={values.vehicule}
                                                        disabled={true}
                                                    />
                                                </div>
                                            </div>
                                            <div className="grp">
                                                <div className="gp">
                                                    <label >Type de dépense<span className='requiredField'>*</span></label>
                                                    <select
                                                        id="idTypeDepense"
                                                        name="idTypeDepense"
                                                        value={values.idTypeDepense}
                                                        onChange={handleChange}
                                                        style={(touched.idTypeDepense && errors.idTypeDepense) ? { borderColor: "red" } : null}
                                                    >
                                                        <option value="">Veuillez sélectionner</option>
                                                        {typesDepenses.map((typeDepense) => (
                                                            <option key={typeDepense.idTypeDepense} value={typeDepense.idTypeDepense}>{typeDepense.libelleTypeDepense}</option>
                                                        ))}
                                                    </select>
                                                    {touched.idTypeDepense && errors.idTypeDepense ? (
                                                        <div className="invalid-feedback">{errors.idTypeDepense}</div>
                                                    ) : null}
                                                </div>
                                                <div className="gp">
                                                    <label>Montant<span className='requiredField'>*</span></label>
                                                    <input
                                                        type="number"
                                                        id="montant"
                                                        name="montant"
                                                        placeholder=""
                                                        defaultValue={values.montant}
                                                        onChange={handleChange}
                                                        step="any"
                                                    />
                                                    {errors.montant && touched.montant && (
                                                        <div className="invalid-feedback">{errors.montant}</div>
                                                    )}
                                                </div>
                                                <div className="gp">
                                                    <label >Mode de paiement</label>
                                                    <select
                                                        id="idModePaiement"
                                                        name="idModePaiement"
                                                        value={values.idModePaiement}
                                                        onChange={handleChange}
                                                    >
                                                        <option value="">Veuillez sélectionner</option>
                                                        {modePaiements.map((modeP) => (
                                                            <option key={modeP.idModePaiement} value={modeP.idModePaiement}>{modeP.libelleModePaiement}</option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="grp">
                                                <div className="gp">
                                                    <label >Date de dépense<span className='requiredField'>*</span></label>
                                                    <input
                                                        type="date"
                                                        id="dateDepense"
                                                        name="dateDepense"
                                                        style={(touched.dateDepense && errors.dateDepense) ? { borderColor: "red" } : null}
                                                        placeholder=""
                                                        defaultValue={values.dateDepense}
                                                        onChange={handleChange}
                                                    />
                                                    {touched.dateDepense && errors.dateDepense ? (
                                                        <div className="invalid-feedback">{errors.dateDepense}</div>
                                                    ) : null}
                                                </div>
                                                <div className="gp">
                                                    <label >Fournisseur</label>
                                                    <select
                                                        id="idFournisseur"
                                                        name="idFournisseur"
                                                        value={values.idFournisseur}
                                                        onChange={handleChange}
                                                    >
                                                        <option value="">Veuillez sélectionner</option>
                                                        {fournisseurs.map((fournisseur) => (
                                                            <option key={fournisseur.idFournisseur} value={fournisseur.idFournisseur}>{fournisseur.raisonSociale}</option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="grp">
                                                <div className="gp" title='Par exemple: facture, ticket, etc'>
                                                    <label>Justificatif</label>
                                                    <label class="upload-file">
                                                        upload file
                                                        <input
                                                            ref={pathImageDepenseRef}
                                                            id="pathImageDepense"
                                                            name="pathImageDepense"
                                                            type="file"
                                                            onChange={handlePathImageDepenseChange}
                                                        />
                                                    </label>
                                                    {pathImageDepense && <span style={{ fontSize: "10px" }}>{getImageFileName(pathImageDepense)}
                                                        <span className="material-symbols-outlined" onClick={(e) => { setPathImageDepense(null); if (pathImageDepenseRef.current) { pathImageDepenseRef.current.value = ''; } }}>delete</span>
                                                    </span>}
                                                    {pathImageDepenseName && <span style={{ fontSize: "10px" }}>{pathImageDepenseName}
                                                        <span className="material-symbols-outlined" onClick={(e) => { setPathImageDepense(null); setPathImageDepenseName(''); if (pathImageDepenseRef.current) { pathImageDepenseRef.current.value = ''; } }}>delete</span>
                                                    </span>}
                                                </div>
                                            </div>
                                            <div className="grp">
                                                <div className="gp">
                                                    <label>Remarques</label>
                                                    <textarea
                                                        type="text"
                                                        rows={4}
                                                        id="remarque"
                                                        name="remarque"
                                                        placeholder=""
                                                        defaultValue={values.remarque}
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="submit-container">
                                        <button type="submit" className="submit-btn">Enregistrer</button>
                                    </div>
                                </div>}
                            </div>
                        </div>
                    </div>
                </form>
            </div >
        </section >
    );
}

export default ModifierDepense;
