import React, { useEffect, useState, useContext, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import moment from 'moment/moment';
import Select from "react-select";
import { CustomToast } from '../../components/Toast/CustomToast';
import useDebounce from '../../hooks/useDebounce';
import { axiosInstance, Vehicule, VehiculeCompteur } from '../../services/api';
import { AuthContext } from '../../contexts/AuthContext';

const AjouterCompteur = () => {
    const { authData } = useContext(AuthContext);
    const navigate = useNavigate();

    const [vehicules, setVehicules] = useState([]);
    const [selectedVehicle, setSelectedVehicle] = useState('');
    const [selectedVehicleId, setSelectedVehicleId] = useState('');

    const [vehicleNotFound, setVehicleNotFound] = useState(false);
    const [kiloDebLess, setKiloDebLess] = useState({ display: false, msg: "" });
    const [kiloDebGreater, setKiloDebGreater] = useState({ display: false, msg: "" });
    const [kiloDebBetween, setKiloDebBetween] = useState({ display: false, msg: "" });

    const [enteredVehicleText, setEnteredVehicleText] = useState('');
    const debouncedVehiculeText = useDebounce(enteredVehicleText, 300);

    //Load data  
    const handleVehicleChange = (selectedOption) => {
        setSelectedVehicle(selectedOption);
        setSelectedVehicleId(selectedOption ? selectedOption.idVehicule : '');
        values.idVehicule = selectedVehicleId;
    };

    const getVehicules = async (nomVehicule) => {
        try {
            const queryParams = new URLSearchParams({
                pageNumber: 1,
                pageSize: 10
            });

            if (authData?.wilayas != null) {
                const wilayasFilter = authData?.wilayas
                queryParams.append("wilayas", wilayasFilter.join(','));
            }
            else {
                return
            }

            if (authData?.flottesAutorises != null) {
                const flottesAutorisesFilter = authData?.flottesAutorises
                queryParams.append("flottesAutorises", flottesAutorisesFilter.join(','));
            }
            else {
                return
            }

            if (nomVehicule !== '') {
                queryParams.append("nom", nomVehicule);
            }
            const response = await axiosInstance.get(`${Vehicule}/GetVehiculesAutocomplete?${queryParams}`);
            const resp = await response.data;
            setVehicules(resp.data);
        }
        catch (err) {
            console.log(err);
        }
    }

    const { handleChange, values, handleSubmit, touched, errors, setFieldValue } = useFormik({
        initialValues: {
            vehicule: '',
            idVehicule: '',
            valeur: '',
            dateRemplissage: '',
        },
        validationSchema: Yup.object({
            vehicule: Yup.object()
                .nullable()
                .required('Champ obligatoire'),
            dateRemplissage: Yup.string()
                .required("Champ obligatoire"),
            valeur: Yup.number()
                .required('Champ obligatoire')
                .min(0, "Valeur de compteur ne peut pas être négative"),
        }),
        onSubmit: async (values) => {
            try {
                const postData = {
                    idVehicule: values.vehicule.idVehicule,
                    dateRemplissage: values.dateRemplissage,
                    valeur: values.valeur.toString(),
                    source: "manuel",
                    idUtilisateur: authData.idUser,
                };

                const response = await axiosInstance.post(VehiculeCompteur, postData);
                CustomToast("L'entrée de compteur a été créée avec succès", 'success');
                navigate('/listcompteurs', { replace: true });
            } catch (error) {
                // Handle error
                if (!error?.response) {
                    CustomToast("Aucune réponse du serveur", 'error');
                    setVehicleNotFound(false);
                    setKiloDebGreater({ display: false, msg: "" });
                    setKiloDebLess({ display: false, msg: "" });
                    setKiloDebBetween({ display: false, msg: "" });
                } else if (error.response?.status === 400 && error.response?.data === "Vehicle not found") {
                    CustomToast("Véhicule introuvable", 'error');
                    setVehicleNotFound(true);
                    setKiloDebGreater({ display: false, msg: "" });
                    setKiloDebLess({ display: false, msg: "" });
                    setKiloDebBetween({ display: false, msg: "" });

                } else if (error.response?.status === 400 && error.response?.data?.msg === "Incorrect value, must be greater") {
                    CustomToast("L'entrée de compteur de véhicule n'a pas pu être créée", 'error');
                    setKiloDebGreater({ display: true, msg: `Doit être supérieur à ${error.response?.data?.val1}` });
                    setVehicleNotFound(false);
                    setKiloDebLess({ display: false, msg: "" });
                    setKiloDebBetween({ display: false, msg: "" });
                } else if (error.response?.status === 400 && error.response?.data?.msg === "Incorrect value, must be less") {
                    CustomToast("L'entrée de compteur de véhicule n'a pas pu être créée", 'error');
                    setKiloDebLess({ display: true, msg: `Doit être inférieur à ${error.response?.data?.val1}` });
                    setVehicleNotFound(false);
                    setKiloDebGreater({ display: false, msg: "" });
                    setKiloDebBetween({ display: false, msg: "" });
                } else if (error.response?.status === 400 && error.response?.data?.msg === "Incorrect value, must be between") {
                    CustomToast("L'entrée de compteur de véhicule n'a pas pu être créée", 'error');
                    setKiloDebBetween({ display: true, msg: `Doit être entre ${error.response?.data?.val1} et ${error.response?.data?.val2}` });
                    setVehicleNotFound(false);
                    setKiloDebGreater({ display: false, msg: "" });
                    setKiloDebLess({ display: false, msg: "" });
                } else if (error.response?.status === 401) {
                    CustomToast("Non autorisé", 'error');
                    setVehicleNotFound(false);
                    setKiloDebGreater({ display: false, msg: "" });
                    setKiloDebLess({ display: false, msg: "" });
                    setKiloDebBetween({ display: false, msg: "" });
                } else {
                    CustomToast("Demande échoué", 'error');
                    setVehicleNotFound(false);
                    setKiloDebGreater({ display: false, msg: "" });
                    setKiloDebLess({ display: false, msg: "" });
                    setKiloDebBetween({ display: false, msg: "" });
                }
                console.log(error);
            }
        }
    });

    //Load data

    useEffect(() => {
        //if (debouncedVehiculeText) {
        getVehicules(debouncedVehiculeText);
        //}
        return () => {
            // Perform any necessary cleanup (e.g., cancel pending requests)
        };
    }, [authData, debouncedVehiculeText]);

    return (
        <section className="vehicles-section">
            <div className="container">
                <form onSubmit={handleSubmit} className="compteur-form">
                    <div className="">
                        <div className="grid grid--2-cols">
                            <div className="form-sec">
                                <div className="retour" id="retour-compteur">
                                    <Link to="/listcompteurs" style={{ textDecoration: "none" }}>
                                        <div className="arrow-back" id="arrow-back-affectation-form">
                                            <span className="material-symbols-outlined" id="arrow">arrow_back</span>
                                            <h3 className="heading-primary"></h3>
                                        </div>
                                    </Link>
                                </div>
                                <div className="form-details">
                                    <div className="detail-form" id="identification-form">
                                        <div className="col">
                                            <div className="grp">
                                                <div className="gp">
                                                    <label>Véhicule<span className='requiredField'>*</span></label>
                                                    <Select
                                                        id="vehicule"
                                                        name="vehicule"
                                                        value={values.vehicule}
                                                        onChange={(option) => { setFieldValue('vehicule', option); setVehicleNotFound(false) }}
                                                        options={vehicules}
                                                        onInputChange={(inputValue) => {
                                                            // Update the entered text
                                                            setEnteredVehicleText(inputValue);
                                                        }}
                                                        getOptionLabel={(option) => option.marque + '[' + option.modele + '] ' + option.immatriculation}
                                                        getOptionValue={(option) => option.idVehicule}
                                                        isClearable
                                                        styles={{
                                                            container: (provided, state) => ({
                                                                ...provided,
                                                                boxShadow: state.isFocused ? '0px 0px 5px 0px #65458d' : errors.vehicule ? 'red' : '#BEBEBE',
                                                            }),
                                                            control: (baseStyles, state) => ({
                                                                ...baseStyles,
                                                                minHeight: '19px',
                                                                outline: '0!important',
                                                                borderRadius: '0.5rem',
                                                                borderWidth: '1px',
                                                                fontSize: '1rem',
                                                                height: '3.1rem',
                                                            }),
                                                            option: (provided, state) => ({
                                                                ...provided,
                                                                fontSize: '1rem'
                                                            }),
                                                            singleValue: (provided, state) => ({
                                                                ...provided,
                                                                fontSize: '1rem',
                                                                margin: '0',
                                                            }),
                                                            valueContainer: (provided, state) => ({
                                                                ...provided,
                                                                alignItems: 'start',
                                                                fontSize: '1rem'
                                                            }),
                                                        }}
                                                    />
                                                    {touched.vehicule && errors.vehicule && (
                                                        <div className="invalid-feedback">{errors.vehicule}</div>
                                                    )}
                                                    {vehicleNotFound && (
                                                        <div className="invalid-feedback">Véhicule introuvable</div>
                                                    )}
                                                </div>
                                            </div>
                                            {values.vehicule && <div className="grp">
                                                <div className="gp">
                                                    <label>Compteur<span className='requiredField'>*</span></label>
                                                    <input
                                                        type="number"
                                                        id="valeur"
                                                        name="valeur"
                                                        placeholder=""
                                                        defaultValue={values.valeur}
                                                        onChange={handleChange}
                                                        step="any"
                                                    />
                                                    {errors.valeur && touched.valeur && (
                                                        <div className="invalid-feedback">{errors.valeur}</div>
                                                    )}
                                                    {kiloDebLess.display && (
                                                        <div className="invalid-feedback">{kiloDebLess.msg}</div>
                                                    )}
                                                    {kiloDebGreater.display && (
                                                        <div className="invalid-feedback">{kiloDebGreater.msg}</div>
                                                    )}
                                                    {kiloDebBetween.display && (
                                                        <div className="invalid-feedback">{kiloDebBetween.msg}</div>
                                                    )}
                                                </div>
                                                <div className="gp">
                                                    <label>Date de lecture<span className='requiredField'>*</span></label>
                                                    <input type="date"
                                                        id="dateRemplissage"
                                                        name="dateRemplissage"
                                                        style={(touched.dateRemplissage && errors.dateRemplissage) ? { borderColor: "red" } : null}
                                                        placeholder=""
                                                        defaultValue={values.dateRemplissage}
                                                        onChange={handleChange}
                                                    />
                                                    {touched.dateRemplissage && errors.dateRemplissage ? (
                                                        <div className="invalid-feedback">{errors.dateRemplissage}</div>
                                                    ) : null}
                                                </div>
                                            </div>}
                                        </div>
                                    </div>
                                    {values.vehicule && <div className="submit-container">
                                        <button type="submit" className="submit-btn">Enregistrer</button>
                                    </div>}
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </section>
    );
}

export default AjouterCompteur;
