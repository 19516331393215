import React, { useState, useContext, useRef, useEffect } from 'react';
import './Login.css'
import { useNavigate } from 'react-router-dom';
import { CustomToast } from '../../components/Toast/CustomToast';
import { axiosInstance, UserAuthentication } from '../../services/api';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { AuthContext } from '../../contexts/AuthContext';
import LogoHeader from './LogoHeader';
import LogoFooter from './LogoFooter.js';
const Login = () => {
    const { login } = useContext(AuthContext);
    const [isAuthLoading, setAuthLoading] = useState(false);
    const navigate = useNavigate();

    const usernameRef = useRef(null);
    const passwordRef = useRef(null);

    const [isUsedUsername, setIsUsedUsername] = useState(false);
    const [isUsedPassword, setIsUsedPassword] = useState(false);
    const [isHoveredLoginButton, setIsHoveredLoginButton] = useState(false);

    const handleMouseEnterFormLogin = () => {
        setIsHoveredLoginButton(true);
    };

    const handleMouseLeaveFormLogin = () => {
        setIsHoveredLoginButton(false);
    };

    const handleInputBlurUsername = (event) => {
        const inputValue = event.target.value;
        setIsUsedUsername(inputValue.length > 0);
    };

    const handleInputBlurPassword = (event) => {
        const inputValue = event.target.value;
        setIsUsedPassword(inputValue.length > 0);
    };

    const handleClickRipple = (event) => {
        const rippleElement = event.currentTarget.querySelector('.ripplesCircle');
        const offset = event.currentTarget.parentElement.getBoundingClientRect();
        const x = event.pageX - offset.left;
        const y = event.pageY - offset.top;

        rippleElement.style.top = `${y}px`;
        rippleElement.style.left = `${x}px`;

        event.currentTarget.classList.add('is-active');
        console.log("handleClickRipple")
    };

    const handleRippleAnimationEnd = (event) => {
        const rippleElement = event.currentTarget.querySelector('.ripplesCircle');
        rippleElement.parentElement.classList.remove('is-active');
        console.log("handleRippleAnimationEnd")
    };

    const { handleChange, values, handleSubmit, touched, errors } = useFormik({
        initialValues: {
            username: '',
            password: ''
        },
        validationSchema: Yup.object({
            username: Yup.string()
                .required("Username obligatoire"),
            password: Yup.string()
                .required("Mot de passe obligatoire")
        }),
        onSubmit: async (values) => {
            try {
                const response = await axiosInstance.post(`${UserAuthentication}/authenticate`, values);

                let authInfo = JSON.stringify(response.data);
                localStorage.setItem('authData', authInfo);

                login(JSON.parse(authInfo));
                setAuthLoading(true);
                CustomToast(`Bienvenue chez FleetGo, ${values.username}!`, 'success');
                const permissions = response.data?.permissionsNumEcran || [];
                if (permissions.includes(1)) {
                    localStorage.setItem('menuShow', JSON.stringify({
                        dashboard: true,//opned, last opened
                        vehicule: false,
                        inspection: false,
                        probleme: false,
                        service: false,
                        donnees: false,
                        profil: false,
                        selectedSubMenu: ''//sub-menu actif
                    }));
                    navigate('/dashboard', { replace: true });
                } else {
                    navigate('/empty', { replace: true });
                }
            } catch (error) {
                setAuthLoading(false);
                // Handle error
                if (!error?.response) {
                    CustomToast("Aucune réponse du serveur", 'error');
                } else if (error.response?.status === 400 && error.response?.data.statutResponse === "User not found - Incorrect username") {
                    CustomToast("Username ou mot de passe incorrectes", 'error');
                } else if (error.response?.status === 400 && error.response?.data.statutResponse === "User not found - Incorrect password") {
                    CustomToast("Username ou mot de passe incorrectes", 'error');
                } else if (error.response?.status === 400 && error.response?.data.statutResponse === "User is not authorized") {
                    CustomToast("L'utilisateur n'est pas autorisé à se connecter", 'error');
                } else {
                    CustomToast("Demande échoué", 'error');
                }
                console.log(error);
            }
        }
    });

    useEffect(() => {
        // Focus the input element when the component mounts
        passwordRef.current.focus();
        usernameRef.current.focus();
        localStorage.removeItem('authData');
        localStorage.removeItem('menuShow');
        return () => {
            //localStorage.removeItem('authData');
            //localStorage.removeItem('menuShow');
        }
    }, []);


    return (
        <div className='.body-login'>
            <hgroup>
                <h1>Driving safe to your destination</h1>
            </hgroup>

            <form
                onSubmit={handleSubmit}
                className={`form-login ${isHoveredLoginButton ? 'color-primary' : ''}`}
            >
                <div className="group">
                    <div className="logo-container-login">
                        <LogoHeader />
                    </div>
                </div>
                <div className="group">
                    <input
                        id="username"
                        name="username"
                        type="text"
                        ref={usernameRef}
                        style={(touched.username && errors.username) ? { borderColor: "red" } : null}
                        placeholder=""
                        defaultValue={values.username}
                        onChange={handleChange}
                        onBlur={handleInputBlurUsername}
                        className={`username-login ${isUsedUsername ? 'used' : ''}`}
                    />
                    {touched.username && errors.username ? (
                        <div className="invalid-feedback2">{errors.username}</div>
                    ) : null}
                    <span className="highlight"></span>
                    <span className="bar"></span>
                    <label className='label-login'>Nom d'utilisateur</label>
                </div>
                <div className="group">
                    <input
                        id="password"
                        name="password"
                        type="password"
                        ref={passwordRef}
                        style={(touched.password && errors.password) ? { borderColor: "red" } : null}
                        placeholder=""
                        defaultValue={values.password}
                        onChange={handleChange}
                        onBlur={handleInputBlurPassword}
                        className={`password-login ${isUsedPassword ? 'used' : ''}`}
                    />
                    {touched.password && errors.password ? (
                        <div className="invalid-feedback2">{errors.password}</div>
                    ) : null}
                    <span className="highlight"></span>
                    <span className="bar"></span>
                    <label className='label-login'>Mot de passe</label>
                </div>
                <div className="row">
                        {/* <input type="checkbox"/> */}
                            <p></p>
                    </div>
                <button type="submit"
                    className="button-login buttonBlue"
                    onClick={handleClickRipple}
                    onMouseEnter={handleMouseEnterFormLogin}
                    onMouseLeave={handleMouseLeaveFormLogin}
                >Se connecter
                    <div className="ripples buttonRipples"><span className="ripplesCircle"></span></div>
                </button>
            </form>

            <footer>
                <div className="wrap-login">
                    <p>Designed by</p>
                    <div className="logo-container-footer">
                        <LogoFooter />
                    </div>
                </div>
            </footer>
        </div>
    );
}

export default Login;
