import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './dashboard.css'
import { Outlet } from "react-router-dom"
import { CustomToast } from '../Toast/CustomToast';
import { AuthContext } from '../../contexts/AuthContext';
const Layout = () => {
    const { authData, logout } = useContext(AuthContext);

    ///Permissions
    const [dashboard, setDashboard] = useState(true);//1

    const [vehicule, setVehicule] = useState(true);//2,3,4,5,6,21,22
    const [listeVehicule, setListeVehicule] = useState(true);//2
    const [assurance, setAssurance] = useState(true);//21
    const [controleTech, setControleTech] = useState(true);//22
    const [historiqueCompteur, setHistoriqueCompteur] = useState(true);//3
    const [historiqueCarburant, setHistoriqueCarburant] = useState(true);//4
    const [affectation, setAffectation] = useState(true);//5
    const [depense, setDepense] = useState(true);//6

    const [inspection, setInspection] = useState(true);//7,8,9
    const [listeInspection, setListeInspection] = useState(true);//7
    const [elementsDefectuex, setElementsDefectuex] = useState(true);//8
    const [formulaireInspection, setFormulaireInspection] = useState(true);//9

    const [probleme, setProbleme] = useState(true);//10

    const [service, setService] = useState(true);//11,12,13,14
    const [ordreTravail, setOrdreTravail] = useState(true);//11
    const [entreeServce, setEntreeServce] = useState(true);//12
    const [tacheService, setTacheService] = useState(true);//13
    const [rappelService, setRappelService] = useState(true);//14

    const [donnees, setDonnees] = useState(true);//15,16,17,18,19,20
    const [apiKeys, setApiKeys] = useState(true);//si role=super admin
    const [parametres, setParametres] = useState(true);//15
    const [employees, setEmployees] = useState(true);//16
    const [roles, setRoles] = useState(true);//17
    const [fournisseurs, setFournisseurs] = useState(true);//18
    const [proprietaires, setProprietaires] = useState(true);//19
    const [divers, setDivers] = useState(true);//20

    // Define your state variables for menu and submenu visibility
    const [menuShow, setMenuShow] = useState({
        dashboard: false,//opned, last opened
        vehicule: false,
        inspection: false,
        probleme: false,
        service: false,
        donnees: false,
        profil: false,
        selectedSubMenu: ''//sub-menu actif
    });

    const [showDetailsInfo, setShowDetailsInfo] = useState(false);

    // Function to display drop menu
    const showDetails = () => {
        setShowDetailsInfo(!showDetailsInfo);
    };

    const showDetailsValue = (value) => {
        setShowDetailsInfo(value);
    };

    // Function to update the submenu state and close other menus
    const updateSubMenuState = (field, isOpened) => {
        const updatedMenuShow = { ...menuShow };

        for (const key in updatedMenuShow) {
            if (key !== field && key !== 'selectedSubMenu') {
                updatedMenuShow[key] = false;
            }
            // if (key === 'selectedSubMenu') {
            //     updatedMenuShow['selectedSubMenu'] = '';
            // }
        }

        updatedMenuShow[field] = isOpened;

        setMenuShow(updatedMenuShow);
        localStorage.setItem('menuShow', JSON.stringify(updatedMenuShow));
    };

    const toggleSubMenu = (field) => {
        const isOpened = menuShow[field];
        //console.log(isOpened,!isOpened)
        updateSubMenuState(field, !isOpened);
    };

    const updateSubMenuActiveState = (field, numEcran) => {
        const updatedMenuShow = { ...menuShow };

        for (const key in updatedMenuShow) {
            if (key === field) {
                updatedMenuShow[key] = true;
                updatedMenuShow['selectedSubMenu'] = numEcran;
            } else {
                updatedMenuShow[key] = false; // Reset other keys to false
            }
        }

        setMenuShow(updatedMenuShow);
        localStorage.setItem('menuShow', JSON.stringify(updatedMenuShow));
    };

    const toggleSubMenuActive = (field, numEcran) => {
        //console.log('Before:', menuShow);
        updateSubMenuActiveState(field, numEcran);
        //console.log('After:', menuShow);
    };

    const handleLogOut = () => {
        localStorage.removeItem('authData');
        localStorage.removeItem('menuShow');
        CustomToast(`Déconnexion réussie`, 'success');
    };

    const [menu, setMenu] = useState(false);
    const showMenu = () => {
        setMenu(!menu);
    };

    useEffect(() => {
        //const authData = JSON.parse(localStorage.getItem("authData"));
        const permissions = authData?.permissionsNumEcran || [];
        const estSuperAdmin = authData?.estSuperAdmin || false;
        setDashboard(permissions.includes(1) || estSuperAdmin);

        setListeVehicule(permissions.includes(2) || estSuperAdmin);
        setHistoriqueCompteur(permissions.includes(3) || estSuperAdmin);
        setHistoriqueCarburant(permissions.includes(4) || estSuperAdmin);
        setAffectation(permissions.includes(5) || estSuperAdmin);
        setDepense(permissions.includes(6) || estSuperAdmin);
        setAssurance(permissions.includes(21) || estSuperAdmin);
        setControleTech(permissions.includes(22) || estSuperAdmin);

        setVehicule((permissions.includes(2) || permissions.includes(3) || permissions.includes(4) || permissions.includes(5) || permissions.includes(6) || permissions.includes(21) || permissions.includes(22)) || estSuperAdmin)
        // setVehicule(listeVehicule || historiqueCompteur || historiqueCarburant || affectation || depense || assurance || controleTech)

        setListeInspection(permissions.includes(7) || estSuperAdmin);
        setElementsDefectuex(permissions.includes(8) || estSuperAdmin);
        setFormulaireInspection(permissions.includes(9) || estSuperAdmin);

        setInspection((permissions.includes(7) || permissions.includes(7) || permissions.includes(7)) || estSuperAdmin);
        // setInspection(listeInspection || elementsDefectuex || formulaireInspection);

        setProbleme(permissions.includes(10) || estSuperAdmin);

        setOrdreTravail(permissions.includes(11) || estSuperAdmin);
        setEntreeServce(permissions.includes(12) || estSuperAdmin);
        setTacheService(permissions.includes(13) || estSuperAdmin);
        setRappelService(permissions.includes(14) || estSuperAdmin);

        setService((permissions.includes(11) || permissions.includes(12) || permissions.includes(13) || permissions.includes(14)) || estSuperAdmin);
        // setService(ordreTravail || entreeServce || tacheService || rappelService);

        setApiKeys(estSuperAdmin);
        setParametres(permissions.includes(15) || estSuperAdmin);
        setEmployees(permissions.includes(16) || estSuperAdmin);
        setRoles(permissions.includes(17) || estSuperAdmin);
        setFournisseurs(permissions.includes(18) || estSuperAdmin);
        setProprietaires(permissions.includes(19) || estSuperAdmin);
        setDivers(permissions.includes(20) || estSuperAdmin);

        setDonnees((permissions.includes(15) || permissions.includes(16) || permissions.includes(17) || permissions.includes(18) || permissions.includes(19) || permissions.includes(20)) || estSuperAdmin);
        // setDonnees(parametres || employees || roles || fournisseurs || proprietaires || divers);

    }, [authData]);

    return (
        <>
            <header className="navigation" onClick={() => showDetailsValue(false)}>
                <div className="nav-container">
                    <div className="brand" id="logo-div">
                        <Link to="#">
                            <div className="logo-container">
                                <svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 1137.16 255.12">
                                    <defs>
                                        <style>
                                            {`
                                        .cls-1 {
    letter-spacing: -.04em;
  }

  .cls-2 {
    letter-spacing: -.05em;
  }

  .cls-3 {
    letter-spacing: -.07em;
  }

  .cls-4 {
    fill: #ffc72f;
  }

  .cls-5 {
    letter-spacing: -.06em;
  }

  .cls-6 {
    fill: #65458d;
  }

  .cls-7, .cls-8 {
    fill: none;
  }

  .cls-9 {
    letter-spacing: -.07em;
  }

  .cls-8 {
    clip-path: url(#clippath);
  }

  .cls-10 {
    font-family: Futura-Bold, Futura;
    font-size: 150px;
    font-weight: 700;
    letter-spacing: .04em;
  }

  .cls-11 {
    letter-spacing: -.06em;
  }

  .cls-12 {
    font-family: Axiforma-Light, Axiforma;
    font-size: 28.1px;
    font-weight: 300;
  }

  .cls-13 {
    letter-spacing: -.04em;
  }

  .cls-14 {
    letter-spacing: -.01em;
  }
  `}
                                        </style>
                                        <clipPath id="clippath">
                                            <path className="cls-7" d="m99.1-2v225h220.61V-2H99.1Zm58.65,106.26c-13.3-14.25-34.74-21.84-55.97-17.1,3.58-20.31,12.95-38.51,27.29-53,20.01,1.96,39.18,8.87,55.18,20.26-16.68,8.84-26.31,26.91-26.5,49.84Z" />
                                        </clipPath>
                                    </defs>
                                    <g id="Layer_1-2" data-name="Layer 1">
                                        <text className="cls-10" transform="translate(354.59 205.64)"><tspan x="0" y="0">fleetGo</tspan></text>
                                        <circle className="cls-4" cx="1115.44" cy="69.05" r="21.71" />
                                        <g>
                                            <g>
                                                <path className="cls-6" d="m310.65,72.27c7.05,29.16,11.42,54.52.22,83.94-12.43,32.28-38.61,51.8-71.86,60.49-62.53,16.33-117.17-14.77-133.5-77.31C88.62,74.74,121.32,20.82,185.44,4.07c40.9-10.68,77.39-.48,104.25,32.24l-43.7,33.4c-12.09-17.14-30.43-25.87-51.28-20.43-34.04,8.89-43.24,45.96-35.31,76.31,8.06,30.87,34.74,57.45,68.78,48.56,22.43-5.86,36.55-21.95,32.66-45.46l-42.22,11.03-10.61-40.63,102.64-26.81Z" />
                                                <path className="cls-4" d="m223.4,111.14c16.82,64.38-17.5,121.83-81.36,138.51-63.85,16.68-121.88-16.64-138.7-81.03C-12.37,108.46,29.37,52.46,86.63,37.51c57.26-14.96,121.06,13.48,136.77,73.64Zm-166.17,43.69c8.96,34.3,40.84,52.75,71.71,44.69,30.87-8.06,49.65-39.75,40.69-74.05-7.17-27.44-39.05-45.89-69.92-37.83-30.87,8.06-49.65,39.75-42.49,67.19Z" />
                                                <g className="cls-8">
                                                    <path className="cls-6" d="m310.65,72.27c7.05,29.16,11.42,54.52.22,83.94-12.43,32.28-38.61,51.8-71.86,60.49-62.53,16.33-117.17-14.77-133.5-77.31C88.62,74.74,121.32,20.82,185.44,4.07c40.9-10.68,77.39-.48,104.25,32.24l-43.7,33.4c-12.09-17.14-30.43-25.87-51.28-20.43-34.04,8.89-43.24,45.96-35.31,76.31,8.06,30.87,34.74,57.45,68.78,48.56,22.43-5.86,36.55-21.95,32.66-45.46l-42.22,11.03-10.61-40.63,102.64-26.81Z" />
                                                </g>
                                            </g>
                                            <polygon className="cls-6" points="235.11 112.31 244.79 150.73 206.67 139.9 168.56 129.07 197 101.47 225.44 73.88 235.11 112.31" />
                                        </g>
                                    </g>
                                </svg>
                            </div>
                        </Link>
                    </div>
                    <nav className="nav-header">
                        <div className="nav-mobile">
                            <Link id="header-nav-toggle" to="#" onClick={() => showMenu()} className={`${menu ? 'active' : ''}`}><span></span>
                            </Link>
                        </div>
                        <ul className="nav-list" style={menu ? { display: "block" } : { display: "none" }}>
                            <li>
                                <Link to="#" className='row-inline' onClick={() => { toggleSubMenu('profil'); }}>Profil
                                    <span className={`material-symbols-outlined ${menuShow.profil ? 'down-arrow' : 'right-arrow'}`}>
                                        {menuShow.profil ? 'arrow_drop_down' : 'arrow_right'}
                                    </span>
                                </Link>
                                <ul className="header-nav-dropdown" style={menuShow.profil ? { display: "block" } : { display: "none" }}>
                                    <li>
                                        <Link to="/compte" className="text-muted">
                                            Mon profil
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/login" replace>
                                            Se déconnecter
                                        </Link>
                                    </li>
                                </ul>
                            </li>
                            {dashboard && <li onClick={() => { toggleSubMenu('dashboard'); }}>
                                <Link to="/dashboard" id="dashboard-header-btn" replace>
                                    Dashboard
                                </Link>
                            </li>}
                            {vehicule && <li>
                                <Link to="#" className='row-inline' onClick={() => toggleSubMenu('vehicule')}>
                                    Véhicules
                                    <span
                                        className={`material-symbols-outlined ${menuShow.vehicule ? 'down-arrow' : 'right-arrow'}`}>
                                        {menuShow.vehicule ? 'arrow_drop_down' : 'arrow_right'}
                                    </span>
                                </Link>
                                <ul className="header-nav-dropdown" style={menuShow.vehicule ? { display: "block" } : { display: "none" }}>
                                    {listeVehicule && <li onClick={() => toggleSubMenuActive('vehicule', 'listvehicules')}>
                                        <Link to="/listvehicules" id="vehicule-header-btn" replace>
                                            Liste des véhicules
                                        </Link>
                                    </li>}
                                    {assurance && <li onClick={() => toggleSubMenuActive('vehicule', 'assurances')}>
                                        <Link to="/assurances" id="assurance-header-btn" replace>
                                            Assurances
                                        </Link>
                                    </li>}
                                    {controleTech && <li onClick={() => toggleSubMenuActive('vehicule', 'controletechniques')}>
                                        <Link to="/controletechniques" id="controle-header-btn" replace>
                                            Contrôles techniques
                                        </Link>
                                    </li>}
                                    {affectation && <li onClick={() => toggleSubMenuActive('vehicule', 'listaffectations')}>
                                        <Link to="/listaffectations" id="affectation-header-btn" replace>
                                            Affectations
                                        </Link>
                                    </li>}
                                    {historiqueCompteur && <li onClick={() => toggleSubMenuActive('vehicule', 'listcompteurs')}>
                                        <Link to="/listcompteurs" id="compteur-header-btn" replace>
                                            Historique compteur
                                        </Link>
                                    </li>}
                                    {historiqueCarburant && <li onClick={() => toggleSubMenuActive('vehicule', 'listcarburants')}>
                                        <Link to="/listcarburants" id="carburant-header-btn" replace>
                                            Historique carburant
                                        </Link>
                                    </li>}
                                    {depense && <li onClick={() => toggleSubMenuActive('vehicule', 'listdepenses')}>
                                        <Link to="/listdepenses" id="depense-header-btn" replace>
                                            Historique des dépenses
                                        </Link>
                                    </li>}
                                </ul>
                            </li>}
                            {inspection && <li>
                                <Link to="#" className='row-inline' onClick={() => { toggleSubMenu('inspection'); }}>Inspections
                                    <span className={`material-symbols-outlined ${menuShow.inspection ? 'down-arrow' : 'right-arrow'}`}>
                                        {menuShow.inspection ? 'arrow_drop_down' : 'arrow_right'}
                                    </span>
                                </Link>
                                <ul className="header-nav-dropdown" style={menuShow.inspection ? { display: "block" } : { display: "none" }}>
                                    {listeInspection && <li onClick={() => toggleSubMenuActive('inspection', 'listinspections')}>
                                        <Link to="/listinspections" id="inspection-aside-btn" replace>
                                            Liste des inspections
                                        </Link>
                                    </li>}
                                    {elementsDefectuex && <li onClick={() => toggleSubMenuActive('inspection', 'listinspectionsechecs')}>
                                        <Link to="/listinspectionsechecs" id="elementdefect-aside-btn" replace>
                                            Éléments défectueux
                                        </Link>
                                    </li>}
                                    {formulaireInspection && <li onClick={() => toggleSubMenuActive('inspection', 'listformulaires')}>
                                        <Link to="/listformulaires" id="formulaireinspection-aside-btn" replace>
                                            Formulaires
                                        </Link>
                                    </li>}
                                </ul>
                            </li>}
                            {probleme && <li onClick={() => { toggleSubMenu('probleme'); }}>
                                <Link to="/listproblemes" id="problem-header-btn" replace>
                                    Problèmes
                                </Link>
                            </li>}
                            {service && <li>
                                <Link to="#" className='row-inline' onClick={() => { toggleSubMenu('service'); }}>Service
                                    <span className={`material-symbols-outlined ${menuShow.service ? 'down-arrow' : 'right-arrow'}`}>
                                        {menuShow.service ? 'arrow_drop_down' : 'arrow_right'}
                                    </span>
                                </Link>
                                <ul className="header-nav-dropdown" style={menuShow.service ? { display: "block" } : { display: "none" }}>
                                    {ordreTravail && <li onClick={() => toggleSubMenuActive('service', 'listordres')}>
                                        <Link to="/listordres" id="ordretravail-header-btn" replace>
                                            Ordres de travail
                                        </Link>
                                    </li>}
                                    {entreeServce && <li onClick={() => toggleSubMenuActive('service', 'listentrees')}>
                                        <Link to="/listentrees" id="entreeservice-header-btn" replace>
                                            Entrées de services
                                        </Link>
                                    </li>}
                                    {rappelService && <li onClick={() => toggleSubMenuActive('service', 'listrappels')}>
                                        <Link to="/listrappels" id="rappelservice-header-btn" replace>
                                            Rappels de service
                                        </Link>
                                    </li>}
                                    {tacheService && <li onClick={() => toggleSubMenuActive('service', 'listtaches')}>
                                        <Link to="/listtaches" id="tacheservice-header-btn" replace>
                                            Tâches de service
                                        </Link>
                                    </li>}
                                </ul>
                            </li>}
                            {donnees && <li>
                                <Link to="#" className='row-inline' onClick={() => { toggleSubMenu('donnees'); }}>Données
                                    <span className={`material-symbols-outlined ${menuShow.donnees ? 'down-arrow' : 'right-arrow'}`}>
                                        {menuShow.donnees ? 'arrow_drop_down' : 'arrow_right'}
                                    </span>
                                </Link>
                                <ul className="header-nav-dropdown" style={menuShow.donnees ? { display: "block" } : { display: "none" }}>
                                    {apiKeys && <li onClick={() => toggleSubMenuActive('donnees', 'listclients')}>
                                        <Link to="/listclients" id="parametre-app-btn" replace>
                                            Api clients
                                        </Link>
                                    </li>}
                                    {parametres && <li onClick={() => toggleSubMenuActive('donnees', 'params')}>
                                        <Link to="/params" id="parametreapplication-header-btn" replace>
                                            Paramètres d'application
                                        </Link>
                                    </li>}
                                    {employees && <li onClick={() => toggleSubMenuActive('donnees', 'listemployes')}>
                                        <Link to="/listemployes" id="employe-aside-btn" replace>
                                            Employés
                                        </Link>
                                    </li>}
                                    {roles && <li onClick={() => toggleSubMenuActive('donnees', 'listroles')}>
                                        <Link to="/listroles" id="roledoirtaccee-header-btn" replace>
                                            Rôles et droits d'accès
                                        </Link>
                                    </li>}
                                    {fournisseurs && <li onClick={() => toggleSubMenuActive('donnees', 'listfournisseurs')}>
                                        <Link to="/listfournisseurs" id="fournisseur-header-btn" replace>
                                            Fournisseurs
                                        </Link>
                                    </li>}
                                    {proprietaires && <li onClick={() => toggleSubMenuActive('donnees', 'listproprietaires')}>
                                        <Link to="/listproprietaires" id="proprietaire-header-btn" replace>
                                            Propriétaires
                                        </Link>
                                    </li>}
                                    {divers && <li onClick={() => toggleSubMenuActive('donnees', 'divers')}>
                                        <Link to="/divers" id="divers-header-btn" replace>
                                            Divers
                                        </Link>
                                    </li>}
                                </ul>
                            </li>}
                        </ul>
                    </nav>
                </div>
            </header>

            <aside>
                <div className="top">
                    <div className="logo">
                        <div className="drop_menu" onClick={() => showDetails()}>
                            <div className="menu_info">
                                <h5 className="text-muted">{authData?.nomPrenom}</h5>
                                <span className="material-symbols-outlined arrow-profile">arrow_drop_down</span>
                            </div>
                        </div>
                        <hr />
                        <div className={`detail_info_admin ${(showDetailsInfo) ? 'show' : 'hide'}`}>
                            <div className="settings">
                                <div className="settings_profile">
                                    {authData && <h5 className="circle">{authData.nom.charAt(0) + " " + authData.prenom.charAt(0)}</h5>}
                                    {!authData && <h5 className="circle"></h5>}
                                    <div className="profile">
                                        {authData && <h5>{authData.nomPrenom}</h5>}
                                        {!authData && <h5></h5>}
                                        {authData && authData.email && <p className="text-muted">{authData.email}</p>}
                                        {!authData && <p className="text-muted"></p>}
                                    </div>
                                </div>
                                <div className="settings_details">
                                    <Link to="/compte" className="text-muted" id="user-profile">
                                        <h3>Mon profil</h3>
                                        <span className="material-symbols-outlined">person</span>
                                    </Link>
                                    <Link to="/login" className="text-muted" replace onClick={() => handleLogOut()}>
                                        <h3>Se déconnecter</h3>
                                        <span className="material-symbols-outlined">logout</span>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="close" id="close-btn">
                        <span className="material-symbols-outlined">close</span>
                    </div>
                </div>
                <div className="sidebar" onClick={() => showDetailsValue(false)}>
                    <nav className="nav">
                        <div className="nav-mobile"><Link id="nav-toggle" to="#"><span></span></Link></div>
                        <ul className="nav-list">
                            {dashboard && <li onClick={() => { toggleSubMenu('dashboard'); }}>
                                <Link to="/dashboard" className="fix" id="dashboard-tab" replace>
                                    <span className="material-symbols-outlined">dashboard</span>
                                    Dashboard
                                </Link>
                            </li>}
                            {vehicule && <li>
                                <Link to="#" className="fix" id="client" onClick={() => toggleSubMenu('vehicule')}>
                                    <span className="material-symbols-outlined">directions_car</span>
                                    Véhicules
                                    <span
                                        className={`material-symbols-outlined ${menuShow.vehicule ? 'down-arrow' : 'right-arrow'
                                            }`}
                                    >
                                        {menuShow.vehicule ? 'arrow_drop_down' : 'arrow_right'}
                                    </span>
                                </Link>
                                <ul className="nav-dropdown" style={menuShow.vehicule ? { display: "block" } : { display: "none" }}>
                                    {listeVehicule && <li onClick={() => toggleSubMenuActive('vehicule', 'listvehicules')}>
                                        <Link to="/listvehicules" id="vehicule-aside-btn" replace>
                                            Liste des véhicules
                                        </Link>
                                    </li>}
                                    {assurance && <li onClick={() => toggleSubMenuActive('vehicule', 'assurances')}>
                                        <Link to="/assurances" id="assurance-aside-btn" replace>
                                            Assurances
                                        </Link>
                                    </li>}
                                    {controleTech && <li onClick={() => toggleSubMenuActive('vehicule', 'controletechniques')}>
                                        <Link to="/controletechniques" id="controle-aside-btn" replace>
                                            Contrôles techniques
                                        </Link>
                                    </li>}
                                    {affectation && <li onClick={() => toggleSubMenuActive('vehicule', 'listaffectations')}>
                                        <Link to="/listaffectations" id="affectation-aside-btn" replace>
                                            Affectations
                                        </Link>
                                    </li>}
                                    {historiqueCompteur && <li onClick={() => toggleSubMenuActive('vehicule', 'listcompteurs')}>
                                        <Link to="/listcompteurs" id="compteur-aside-btn" replace>
                                            Historique compteur
                                        </Link>
                                    </li>}
                                    {historiqueCarburant && <li onClick={() => toggleSubMenuActive('vehicule', 'listcarburants')}>
                                        <Link to="/listcarburants" id="carburant-aside-btn" replace>
                                            Historique carburant
                                        </Link>
                                    </li>}
                                    {depense && <li onClick={() => toggleSubMenuActive('vehicule', 'listdepenses')}>
                                        <Link to="/listdepenses" id="depense-aside-btn" replace>
                                            Historique des dépenses
                                        </Link>
                                    </li>}
                                </ul>
                            </li>}
                            {inspection && <li>
                                <Link to="#" className="fix" id="pickup" onClick={() => toggleSubMenu('inspection')}>
                                    <span className="material-symbols-outlined">check_circle</span>
                                    Inspections
                                    <span
                                        className={`material-symbols-outlined ${menuShow.inspection ? 'down-arrow' : 'right-arrow'
                                            }`}
                                    >
                                        {menuShow.inspection ? 'arrow_drop_down' : 'arrow_right'}
                                    </span>
                                </Link>

                                <ul className="nav-dropdown" style={menuShow.inspection ? { display: "block" } : { display: "none" }}>
                                    {listeInspection && <li onClick={() => toggleSubMenuActive('inspection', 'listinspections')}>
                                        <Link to="/listinspections" id="inspection-aside-btn" replace>
                                            Liste des inspections
                                        </Link>
                                    </li>}
                                    {elementsDefectuex && <li onClick={() => toggleSubMenuActive('inspection', 'listinspectionsechecs')}>
                                        <Link to="/listinspectionsechecs" id="elementdefect-aside-btn" replace>
                                            Éléments défectueux
                                        </Link>
                                    </li>}
                                    {formulaireInspection && <li onClick={() => toggleSubMenuActive('inspection', 'listformulaires')}>
                                        <Link to="/listformulaires" id="formulaireinspection-aside-btn" replace>
                                            Formulaires
                                        </Link>
                                    </li>}
                                </ul>
                            </li>}
                            {probleme && <li onClick={() => { toggleSubMenu('probleme'); }}>
                                <Link to="/listproblemes" className="fix" id="problem-aside-btn" replace >
                                    <span className="material-symbols-outlined">warning</span>
                                    Problèmes
                                </Link>
                            </li>}
                            {service && <li>
                                <Link to="#" className="fix" id="service" onClick={() => toggleSubMenu('service')} replace>
                                    <span className="material-symbols-outlined">build</span>
                                    Service
                                    <span
                                        className={`material-symbols-outlined ${menuShow.service ? 'down-arrow' : 'right-arrow'}`}
                                    >
                                        {menuShow.service ? 'arrow_drop_down' : 'arrow_right'}
                                    </span>
                                </Link>
                                <ul className="nav-dropdown" style={menuShow.service ? { display: "block" } : { display: "none" }}>
                                    {ordreTravail && <li onClick={() => toggleSubMenuActive('service', 'listordres')}>
                                        <Link to="/listordres" id="otdretravail-aside-btn" replace>
                                            Ordres de travail
                                        </Link>
                                    </li>}
                                    {entreeServce && <li onClick={() => toggleSubMenuActive('service', 'listentrees')}>
                                        <Link to="/listentrees" id="entreeservice-aside-btn" replace>
                                            Entrées de services
                                        </Link>
                                    </li>}
                                    {rappelService && <li onClick={() => toggleSubMenuActive('service', 'listrappels')}>
                                        <Link to="/listrappels" id="rappelservice-aside-btn" replace>
                                            Rappels de service
                                        </Link>
                                    </li>}
                                    {tacheService && <li onClick={() => toggleSubMenuActive('service', 'listtaches')}>
                                        <Link to="/listtaches" id="tacheservice-aside-btn" replace>
                                            Tâches de service
                                        </Link>
                                    </li>}
                                </ul>
                            </li>}
                            {donnees && <li>
                                <Link to="#" className="fix" id="donnees" onClick={() => toggleSubMenu('donnees')}>
                                    <span className="material-symbols-outlined">alarm_on</span>
                                    Données
                                    <span
                                        className={`material-symbols-outlined ${menuShow.donnees ? 'down-arrow' : 'right-arrow'
                                            }`}
                                    >
                                        {menuShow.donnees ? 'arrow_drop_down' : 'arrow_right'}
                                    </span>
                                </Link>
                                <ul className="nav-dropdown" style={menuShow.donnees ? { display: "block" } : { display: "none" }}>
                                    {apiKeys && <li onClick={() => toggleSubMenuActive('donnees', 'listclients')}>
                                        <Link to="/listclients" id="parametre-app-btn" replace>
                                            Api clients
                                        </Link>
                                    </li>}
                                    {parametres && <li onClick={() => toggleSubMenuActive('donnees', 'params')}>
                                        <Link to="/params" id="parametreapplication-aside-btn" replace>
                                            Paramètres d'application
                                        </Link>
                                    </li>}
                                    {employees && <li onClick={() => toggleSubMenuActive('donnees', 'listemployes')}>
                                        <Link to="/listemployes" id="employe-aside-btn" replace>
                                            Employés
                                        </Link>
                                    </li>}
                                    {roles && <li onClick={() => toggleSubMenuActive('donnees', 'listroles')}>
                                        <Link to="/listroles" id="roledoirtaccee-aside-btn" replace>
                                            Rôles et droits d'accès
                                        </Link>
                                    </li>}
                                    {fournisseurs && <li onClick={() => toggleSubMenuActive('donnees', 'listfournisseurs')}>
                                        <Link to="/listfournisseurs" id="fournisseur-aside-btn" replace>
                                            Fournisseurs
                                        </Link>
                                    </li>}
                                    {proprietaires && <li onClick={() => toggleSubMenuActive('donnees', 'listproprietaires')}>
                                        <Link to="/listproprietaires" id="proprietaire-aside-btn" replace>
                                            Propriétaires
                                        </Link>
                                    </li>}
                                    {divers && <li onClick={() => toggleSubMenuActive('donnees', 'divers')}>
                                        <Link to="/divers" id="divers-aside-btn" replace>
                                            Divers
                                        </Link>
                                    </li>}
                                </ul>
                            </li>}
                            <li onClick={() => handleLogOut()}>
                                <Link to="/login" className="fix" replace>
                                    <span className="material-symbols-outlined">logout</span>
                                    Se déconnecter
                                </Link>
                            </li>
                        </ul>
                    </nav>
                </div>
            </aside>
            <main onClick={() => showDetailsValue(false)}>
                <Outlet />
            </main>

        </>
    );
}

export default Layout;
