import React, { useEffect, useState, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import moment from 'moment/moment';
import { CustomToast } from '../../../../components/Toast/CustomToast';
import ConfirmationDialog from '../../../../components/Confirmation/ConfirmationDialog';
import { axiosInstance, Parc, Proprietaire, Wilaya, Commune } from '../../../../services/api';
import { AuthContext } from '../../../../contexts/AuthContext';
import useDebounce from '../../../../hooks/useDebounce';

const ListParcs = () => {
    const { authData } = useContext(AuthContext);
    const navigate = useNavigate();

    const [parcs, setParcs] = useState([]);
    const [parc, setParc] = useState([]);
    const [proprietaires, setProprietaires] = useState([]);
    const [wilayas, setWilayas] = useState([]);
    const [communes, setCommunes] = useState([]);
    const [filtredParcs, setFiltredParcs] = useState([]);
    const [pageNumber, setPageNumber] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalItems, setTotalItems] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [loading, setLoading] = useState(true);
    const [showAddModal, setShowAddModal] = useState(false);
    const [showUpdateModal, setShowUpdateModal] = useState(false);
    const [showDetailsModal, setShowDetailsModal] = useState(false);
    const [idParcDelete, setIdParcDelete] = useState('');
    const [idParcDeleteDetails, setIdParcDeleteDetails] = useState('');
    const [idParcUpdate, setIdParcUpdate] = useState('');

    const [search, setSearch] = useState('');
    const debouncedSearchText = useDebounce(search, 300);

    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);

    //Table management
    const [sortBy, setSortBy] = useState(''); // Column name to sort by
    const [sortOrder, setSortOrder] = useState('asc'); // 'asc' or 'desc'

    const handleSort = (column) => {
        setSortBy(column);
        setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');

        let sortedTable = [...filtredParcs].sort((a, b) => {
            const aValue = a[sortBy] || '';
            const bValue = b[sortBy] || '';

            if (sortOrder === 'asc') {
                return aValue.localeCompare(bValue);
            } else {
                return bValue.localeCompare(aValue);
            }
        });

        setFiltredParcs(sortedTable);
    };

    const handlePageChange = (page) => {
        setPageNumber(page);
        //consolelog("setPageNumber="+pageNumber)
        const startIndex = (page - 1) * pageSize;
        const endIndex = startIndex + pageSize;
        const paginatedData = parcs.slice(startIndex, endIndex);

        setFiltredParcs(paginatedData);
    };

    const [openAboutList, setOpenAboutList] = useState(null);

    const toggleAboutList = (idMarque) => {
        // console.log(idMarque)
        setOpenAboutList(openAboutList === idMarque ? null : idMarque);
    };

    const handlePerRowsChange = (newPerPage) => {
        setPageSize(newPerPage);
        //console.log("setPageSize="+pageSize);
    };

    const showHideAddModal = (val) => {
        setShowAddModal(val);
        setIdParcUpdate('');
        formik1.resetForm();
        if (val) {
            formik1.values.statut = "actif";
            getProprietaires();
            getWilayas();
            //getCommunes("");
        }
    };

    const showHideUpdateModal = (val, idParc, nom, surface, adresse, responsableParc, interimtResponsableParc, statut, idWilaya, idCommune, idProprietaire) => {
        setShowUpdateModal(val);
        setIdParcUpdate(idParc);
        formik2.resetForm();
        if (val) {
            getProprietaires();
            getWilayas();
            getCommunes(idWilaya);
            // formik2.values.idWilayaUpdate = idWilaya || '';
            // formik2.values.idCommuneUpdate = idCommune || '';           
            // formik2.values.nomUpdate = nom || '';
            // formik2.values.surfaceUpdate = surface|| '';
            // formik2.values.adresseUpdate = adresse|| '';
            // formik2.values.responsableParcUpdate = responsableParc|| '';
            // formik2.values.interimtResponsableParcUpdate = interimtResponsableParc|| '';
            // formik2.values.statutUpdate = statut;   
            // formik2.values.idProprietaireUpdate = idProprietaire || ''; 

            formik2.setValues(prevValues => ({
                ...prevValues,
                idWilayaUpdate: idWilaya || '',
                idCommuneUpdate: idCommune || '',
                nomUpdate: nom || '',
                surfaceUpdate: surface || '',
                adresseUpdate: adresse || '',
                responsableParcUpdate: responsableParc || '',
                interimtResponsableParcUpdate: interimtResponsableParc || '',
                statutUpdate: statut,
                idProprietaireUpdate: idProprietaire || ''
            }));

        }
    };

    const showHideDetailsModal = (val) => {
        setShowDetailsModal(val);
        formik3.resetForm();
        if (val) {
            getParc(idParcDeleteDetails);
        } else {
            setIdParcDeleteDetails('');
            setParc(null);
        }
    };

    //Supprimer parc
    const deleteParc = async (idParc) => {
        try {
            await axiosInstance.delete(`${Parc}/${idParc}`);
            getParcs();
            CustomToast("Le parc a été supprimé avec succès", 'success');
        }
        catch (error) {
            // Handle error
            if (!error?.response) {
                CustomToast("Aucune réponse du serveur", 'error');
            } else if (error.response?.status === 400 && error.response?.data === "Parc is already used. Cannot delete.") {
                CustomToast("Le parc en cours d'utilisation. Impossible de suprimer", 'error');
            } else if (error.response?.status === 401) {
                CustomToast("Non autorisé", 'error');
            } else {
                CustomToast("Demande échoué", 'error');
            }
            console.log(error);
        }
    }

    const handleConfirmDelete = (idParc) => {
        // Show the modal when the confirm delete button is clicked
        //deleteParc(idParc);
        setIdParcDelete(idParc);
        setIdParcDeleteDetails(idParc);
        setConfirmDialogOpen(true);
    };

    const handleDelete = async () => {
        try {
            await axiosInstance.delete(`${Parc}/${idParcDelete}`);
            //getParcs();
            setIdParcDelete('');
            setIdParcDeleteDetails('');
            CustomToast("Le parc a été supprimé avec succès", 'success');
            getParcs();
            setConfirmDialogOpen(false);
            //navigate('/listparcs', { replace: true });
        }
        catch (error) {
            // Handle error
            if (!error?.response) {
                CustomToast("Aucune réponse du serveur", 'error');
            } else if (error.response?.status === 400 && error.response?.data === "Parc is already used. Cannot delete.") {
                CustomToast(<div>Le parc est en cours d'utilisation et ne peut pas être supprimé. <a className='act' style={{ textDecoration: 'underline' }} onClick={() => showHideDetailsModal(true)}>Afficher l'utilisation</a></div>, 'error');
            } else if (error.response?.status === 401) {
                CustomToast("Non autorisé", 'error');
            } else {
                CustomToast("Demande échoué", 'error');
            }
            handleCancelDelete();

            console.log(error);
        }
    }

    const handleCancelDelete = () => {
        setConfirmDialogOpen(false);
    };

    //Load data
    const getParcs = async () => {
        try {
            const response = await axiosInstance.get(`${Parc}/GetParcs`);
            const resp = await response.data;

            setParcs(resp);
            let filteredResult = resp;

            if (search !== '') {
                filteredResult = resp.filter((item) =>
                    Object.values(item).some(
                        (value) =>
                            value &&
                            value.toString().toLowerCase().includes(search.toLowerCase())
                    )
                );
            }

            // Pagination
            const startIndex = (pageNumber - 1) * pageSize;
            const endIndex = startIndex + pageSize;
            const paginatedResult = filteredResult.slice(startIndex, endIndex);

            setFiltredParcs(paginatedResult);
            setPageNumber(1);
            setTotalItems(filteredResult.length);
            setTotalPages(Math.ceil(filteredResult.length / pageSize));
            setLoading(false);
        }
        catch (err) {
            setLoading(false);
            console.log(err);
        }
    }

    const handleSearch = (searchTerm) => {

        const result = parcs?.filter((item) =>
            Object.values(item).some(
                (value) =>
                    value &&
                    value.toString().toLowerCase().includes(searchTerm.toLowerCase())
            )
        );
        //console.log(searchTerm,result)
        // Pagination
        const startIndex = (pageNumber - 1) * pageSize;
        const endIndex = startIndex + pageSize;
        const paginatedResult = result.slice(startIndex, endIndex);
        setFiltredParcs(paginatedResult);
        setPageNumber(1);
        setTotalItems(result.length);
        setTotalPages(Math.ceil(result.length / pageSize));
        setSearch(searchTerm);
    };

    const getParc = async (idParc) => {
        try {
            const queryParams = new URLSearchParams({
                idParc: idParc
            });
            const response = await axiosInstance.get(`${Parc}/GetParcUsage?${queryParams}`);
            const resp = await response.data;
            //formik3.values.countVehicule = resp[0].countVehicule;

            formik3.setValues(prevValues => ({
                ...prevValues,
                countVehicule: resp[0].countVehicule || 0
            }));
        }
        catch (err) {
            console.log(err);
        }
    }

    const getProprietaires = async () => {
        try {
            const queryParams = new URLSearchParams({
                typeProprietaire: "entreprise"
            });

            const response = await axiosInstance.get(`${Proprietaire}/GetProprietaireByType?${queryParams}`);
            //const response = await axiosInstance.get(`${Proprietaire}/GetProprietaireByType/entreprise`);
            const resp = await response.data;
            setProprietaires(resp);
        }
        catch (err) {
            console.log(err);
        }
    }

    const getCommunes = async (idWilaya) => {
        try {
            const response = await axiosInstance.get(`${Commune}/SearchCommunes/${idWilaya}`);
            const resp = await response.data;
            setCommunes(resp);
        }
        catch (err) {
            console.log(err);
        }
    }

    const getWilayas = async () => {
        try {
            const response = await axiosInstance.get(`${Wilaya}`);
            const resp = await response.data;
            setWilayas(resp);
        }
        catch (err) {
            console.log(err);
        }
    }

    const formik1 = useFormik({
        initialValues: {
            nom: '',
            surface: '',
            adresse: '',
            responsableParc: '',
            interimtResponsableParc: '',
            statut: "actif",
            idCommune: '',
            idWilaya: '',
            idProprietaire: '',
        },
        validationSchema: Yup.object({
            nom: Yup.string()
                .required('Champ obligatoire'),
            idWilaya: Yup.string()
                .required('Champ obligatoire'),
            idCommune: Yup.string()
                .required('Champ obligatoire'),
            idProprietaire: Yup.string()
                .required('Champ obligatoire')

        }),
        onSubmit: async (values) => {
            try {
                const postData = {
                    nom: values.nom,
                    surface: values.surface,
                    adresse: values.adresse,
                    responsableParc: values.responsableParc,
                    interimtResponsableParc: values.interimtResponsableParc,
                    statut: values.statut,
                    idCommune: values.idCommune,
                    idProprietaire: values.idProprietaire,
                    idUtilisateur: authData.idUser,
                };

                const response = await axiosInstance.post(Parc, postData);
                CustomToast("Le parc a été créé avec succès", 'success');
                getParcs();
                setShowAddModal(false);
                //navigate('/listparcs', { replace: true });
            } catch (error) {
                // Handle error
                if (!error?.response) {
                    CustomToast("Aucune réponse du serveur", 'error');
                } else if (error.response?.status === 400 && error.response?.data === "Parc existe") {
                    CustomToast("Parc existe", 'error');
                } else if (error.response?.status === 401) {
                    CustomToast("Non autorisé", 'error');
                } else {
                    CustomToast("Demande échoué", 'error');
                }
                console.log(error);
            }
        }
    });

    const formik2 = useFormik({
        initialValues: {
            nomUpdate: '',
            surfaceUpdate: '',
            adresseUpdate: '',
            responsableParcUpdate: '',
            interimtResponsableParcUpdate: '',
            statutUpdate: '',
            idCommuneUpdate: '',
            idWilayaUpdate: '',
            idProprietaireUpdate: '',
        },
        validationSchema: Yup.object({
            nomUpdate: Yup.string()
                .required('Champ obligatoire'),
            idWilayaUpdate: Yup.string()
                .required('Champ obligatoire'),
            idCommuneUpdate: Yup.string()
                .required('Champ obligatoire'),
            idProprietaireUpdate: Yup.string()
                .required('Champ obligatoire')
        }),
        onSubmit: async (values) => {
            try {
                const postData = {
                    idParc: idParcUpdate,
                    nom: values.nomUpdate,
                    surface: values.surfaceUpdate,
                    adresse: values.adresseUpdate,
                    responsableParc: values.responsableParcUpdate,
                    interimtResponsableParc: values.interimtResponsableParcUpdate,
                    statut: values.statutUpdate,
                    idCommune: values.idCommuneUpdate,
                    idProprietaire: values.idProprietaireUpdate,
                    idUtilisateur: authData.idUser,
                };

                //console.log(postData);               
                const response = await axiosInstance.put(Parc, postData);
                CustomToast("Le parc a été modifié avec succès", 'success');
                showHideUpdateModal(false, '', '', '', '', '', '', '', '', '', '', '');
                getParcs();

                //navigate('/listparcs', { replace: true });
            } catch (error) {
                // Handle error
                if (!error?.response) {
                    CustomToast("Aucune réponse du serveur", 'error');
                } else if (error.response?.status === 400 && error.response?.data === "Parc existe") {
                    CustomToast("Parc existe", 'error');
                } else if (error.response?.status === 401) {
                    CustomToast("Non autorisé", 'error');
                } else {
                    CustomToast("Demande échoué", 'error');
                }
                console.log(error);
            }
        }
    });

    const formik3 = useFormik({
        initialValues: {
            countVehicule: 0,
        }
    });

    useEffect(() => {
        //Load Data
        getParcs();
        return () => {
            // Perform any necessary cleanup (e.g., cancel pending requests)
        };
    }, [authData, pageSize]);

    // useEffect(() => {
    //     const result = parcs?.filter((item) =>
    //         Object.values(item).some(
    //             (value) =>
    //                 value &&
    //                 value.toString().toLowerCase().includes(search.toLowerCase())
    //         )
    //     );
    //     setFiltredParcs(result);
    // }, [search, parcs])

    useEffect(() => {
        //Load Data
        handleSearch(debouncedSearchText);
        return () => {
            // Perform any necessary cleanup (e.g., cancel pending requests)
        };
    }, [debouncedSearchText]);

    useEffect(() => {
        //Load communes ajouter Parc
        if (formik1.values.idWilaya != "") {
            getCommunes(formik1.values.idWilaya);
        }
        else {
            setCommunes([]);
        }
        formik1.values.idCommune = "";
    }, [formik1.values.idWilaya])

    useEffect(() => {
        //Load communes update parc
        if (formik2.values.idWilayaUpdate != "") {
            getCommunes(formik2.values.idWilayaUpdate);
        }
        else {
            setCommunes([]);
            formik2.values.idCommuneUpdate = "";
        }
    }, [formik2.values.idWilayaUpdate])

    return (
        <section className="donnee-section" onClick={() => { if (openAboutList !== null) { setOpenAboutList(null) } }}>
            <div className="container diver-section">
                <div className="parc-sec wrap-all">

                    <div className="row row-center">
                        <Link to="/divers" className="retour cursurer" id="vehicule-list-assrc-retour">
                            <div className="arrow-back">
                                <span className="material-symbols-outlined" id="arrow">arrow_back</span>
                            </div>
                        </Link>
                        <Link href="#" className="color-primary-2 decoration-none" onClick={() => showHideAddModal(true)}>
                            <div className="add-new" id="add-vehc">
                                <span className="material-symbols-outlined">add</span>
                                Ajouter un parc
                            </div>
                        </Link>
                    </div>

                    <div className="group-elements">
                        <div className="voir-elements">
                            <p></p>
                            <select
                                name="pageSize"
                                id="pageSize"
                                onChange={(e) => { handlePerRowsChange(e.target.value || 10); setPageNumber(1); }}
                            >
                                <option value="10" defaultValue={true}>10</option>
                                <option value="15">15</option>
                                <option value="20">20</option>
                            </select>
                        </div>
                        <div className="search-element">
                            <p>Rechercher</p>
                            <input
                                type="text"
                                value={search}
                                onChange={(e) => handleSearch(e.target.value)}
                            />
                        </div>
                    </div>
                    {filtredParcs?.length > 0 && <div className="table-wrap">
                            <table>
                                <thead>
                                    <tr>
                                        <th onClick={() => handleSort('nom')}>Nom</th>
                                        <th>Adresse</th>
                                        <th>Wilaya</th>
                                        <th>Commune</th>
                                        <th>Propriétaire</th>
                                        <th>Statut</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {filtredParcs?.map((row) => (
                                        <tr key={row.idParc}>
                                            <td>{row.nom}</td>
                                            <td>{row.adresse}</td>
                                            <td>{row.wilaya}</td>
                                            <td>{row.commune}</td>
                                            <td>{row.proprietaire}</td>
                                            <td>
                                                {
                                                    row.statut === "actif" ? (
                                                        <>
                                                            <svg height="11.87" width="15"><circle cx="6" cy="6" r="6" fill="green" /></svg>Actif
                                                        </>
                                                    ) : (
                                                        <>
                                                            <svg height="11.87" width="15"><circle cx="6" cy="6" r="6" fill="red" /></svg>Inactif
                                                        </>
                                                    )
                                                }
                                            </td>

                                            <td className="txt-center relative">
                                            <span className="material-symbols-outlined about-btn" onClick={() => toggleAboutList(row.idParc)}>
                                                more_horiz
                                            </span>
                                            {openAboutList === row.idParc && (
                                                <div className="about-list">
                                                    <ul>
                                                        <li  onClick={() => showHideUpdateModal(true, row.idParc, row.nom, row.surface, row.adresse, row.responsableParc, row.interimtResponsableParc, row.statut, row.idWilaya, row.idCommune, row.idProprietaire)}>
                                                            <span className="material-symbols-outlined">edit</span>
                                                            <p>Modifier</p>
                                                        </li>
                                                        <li  onClick={() => handleConfirmDelete(row.idParc)}>
                                                            <span className="material-symbols-outlined">delete</span>
                                                            <p>Supprimer</p>
                                                        </li>
                                                    </ul>
                                                </div>
                                            )}
                                        </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>}
                        <div className="pagination">
                            <Link to="#" onClick={() => handlePageChange(pageNumber - 1)}
                                className={(pageNumber === 1) ? 'pgn disabled' : 'pgn'}
                                disabled={(pageNumber === 1) ? true : false}
                            >&laquo;</Link>
                            {Array.from({ length: totalPages }, (_, i) => (
                                <Link
                                    key={i + 1}
                                    to="#"
                                    className={i + 1 === pageNumber ? 'pgn active' : 'pgn'}
                                    onClick={() => handlePageChange(i + 1)}
                                >
                                    {i + 1}
                                </Link>
                            ))}
                            <Link to="#" onClick={() => handlePageChange(pageNumber + 1)}
                                className={((pageNumber + 1) > totalPages) ? 'pgn disabled' : 'pgn'}
                                disabled={((pageNumber + 1) > totalPages) ? true : false}
                            >&raquo;</Link>
                        </div>

                    {showAddModal && <div className="popcard-add">
                        <div className="popcard-add_inside">
                            <Link className="popcard-add_inside-close" to="#" onClick={() => showHideAddModal(false)}>&#10006;</Link>
                            <h2 className="popcard-add_inside-title"></h2>
                            <div className="popcard-add_inside-form">
                                <form onSubmit={formik1.handleSubmit}>
                                    <div className="wrap-info">
                                        <div className="wrap-row">
                                            <div className="wrap-one">
                                                <label>Nom<span className='requiredField'>*</span></label>
                                                <input type="text"
                                                    id="nom"
                                                    name="nom"
                                                    style={(formik1.touched.nom && formik1.errors.nom) ? { borderColor: "red" } : null}
                                                    placeholder=""
                                                    defaultValue={formik1.values.nom}
                                                    onChange={formik1.handleChange}
                                                />
                                                {formik1.touched.nom && formik1.errors.nom ? (
                                                    <div className="invalid-feedback">{formik1.errors.nom}</div>
                                                ) : null}
                                            </div>
                                            <div className="wrap-one">
                                                <label>Surface</label>
                                                <input type="text"
                                                    id="surface"
                                                    name="surface"
                                                    placeholder=""
                                                    defaultValue={formik1.values.surface}
                                                    onChange={formik1.handleChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="wrap-row">
                                            <div className="wrap-one">
                                                <label>Adresse</label>
                                                <input type="text"
                                                    id="adresse"
                                                    name="adresse"
                                                    placeholder=""
                                                    defaultValue={formik1.values.adresse}
                                                    onChange={formik1.handleChange}
                                                />
                                            </div>
                                            <div className="wrap-one">
                                                <label>Responsable</label>
                                                <input type="text"
                                                    id="responsableParc"
                                                    name="responsableParc"
                                                    placeholder=""
                                                    defaultValue={formik1.values.responsableParc}
                                                    onChange={formik1.handleChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="wrap-row">
                                            <div className="wrap-one">
                                                <label>Intérime</label>
                                                <input type="text"
                                                    id="interimtResponsableParc"
                                                    name="interimtResponsableParc"
                                                    placeholder=""
                                                    defaultValue={formik1.values.interimtResponsableParc}
                                                    onChange={formik1.handleChange}
                                                />
                                            </div>
                                            <div className="wrap-one">
                                                <label >Propriétaire<span className='requiredField'>*</span></label>
                                                <select
                                                    id="idProprietaire"
                                                    name="idProprietaire"
                                                    value={formik1.values.idProprietaire}
                                                    onChange={formik1.handleChange}
                                                    style={(formik1.touched.idProprietaire && formik1.errors.idProprietaire) ? { borderColor: "red" } : null}
                                                >
                                                    <option value="">Veuillez sélectionner</option>
                                                    {proprietaires.map((proprietaire) => (
                                                        <option key={proprietaire.idProprietaire} value={proprietaire.idProprietaire}>{proprietaire.raisonSociale}</option>
                                                    ))}
                                                </select>
                                                {formik1.touched.idProprietaire && formik1.errors.idProprietaire ? (
                                                    <div className="invalid-feedback">{formik1.errors.idProprietaire}</div>
                                                ) : null}
                                            </div>
                                        </div>
                                        <div className="wrap-row">
                                            <div className="wrap-one">
                                                <label>Wilaya<span className='requiredField'>*</span></label>
                                                <select
                                                    id="idWilaya"
                                                    name="idWilaya"
                                                    value={formik1.values.idWilaya} onChange={formik1.handleChange}
                                                    style={(formik1.touched.idWilaya && formik1.errors.idWilaya) ? { borderColor: "red" } : null}
                                                >
                                                    <option value="">Veuillez sélectionner</option>
                                                    {wilayas.map((wilaya) => (
                                                        <option key={wilaya.idWilaya} value={wilaya.idWilaya}>{wilaya.nomLatin}</option>
                                                    ))}
                                                </select>
                                                {formik1.touched.idWilaya && formik1.errors.idWilaya ? (
                                                    <div className="invalid-feedback">{formik1.errors.idWilaya}</div>
                                                ) : null}
                                            </div>
                                            <div className="wrap-one">
                                                <label>Commune<span className='requiredField'>*</span></label>
                                                <select
                                                    id="idCommune"
                                                    name="idCommune"
                                                    value={formik1.values.idCommune} onChange={formik1.handleChange}
                                                    style={(formik1.touched.idCommune && formik1.errors.idCommune) ? { borderColor: "red" } : null}
                                                >
                                                    <option value="">Veuillez sélectionner</option>
                                                    {communes.map((commune) => (
                                                        <option key={commune.idCommune} value={commune.idCommune}>{commune.nomLatin}</option>
                                                    ))}
                                                </select>
                                                {formik1.touched.idCommune && formik1.errors.idCommune ? (
                                                    <div className="invalid-feedback">{formik1.errors.idCommune}</div>
                                                ) : null}
                                            </div>
                                            <div className="wrap-one">
                                                <label >Statut<span className='requiredField'>*</span></label>
                                                <select
                                                    id="statut"
                                                    name="statut"
                                                    defaultValue={formik1.values.statut}
                                                    onChange={formik1.handleChange}
                                                >
                                                    <option value="actif">Actif</option>
                                                    <option value="inactif">Inactif</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="popcard-operation-pickup-details_inside-zone">
                                        <div className="buttons">
                                            <input type="submit" value="Enregistrer" className="submit-btn" />
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>}

                    {showUpdateModal && <div className="popcard-add">
                        <div className="popcard-add_inside">
                            <Link className="popcard-add_inside-close" to="#" onClick={() => showHideUpdateModal(false, '', '', '', '', '', '', '', '', '', '', '')}>&#10006;</Link>
                            <h2 className="popcard-add_inside-title"></h2>
                            <div className="popcard-add_inside-form">
                                <form onSubmit={formik2.handleSubmit}>

                                    <div className="wrap-info">
                                        <div className="wrap-row">
                                            <div className="wrap-one">
                                                <label>Nom<span className='requiredField'>*</span></label>
                                                <input type="text"
                                                    id="nomUpdate"
                                                    name="nomUpdate"
                                                    style={(formik2.touched.nomUpdate && formik2.errors.nomUpdate) ? { borderColor: "red" } : null}
                                                    placeholder=""
                                                    defaultValue={formik2.values.nomUpdate}
                                                    onChange={formik2.handleChange}
                                                />
                                                {formik2.touched.nomUpdate && formik2.errors.nomUpdate ? (
                                                    <div className="invalid-feedback">{formik2.errors.nomUpdate}</div>
                                                ) : null}
                                            </div>
                                            <div className="wrap-one">
                                                <label>Surface</label>
                                                <input type="text"
                                                    id="surfaceUpdate"
                                                    name="surfaceUpdate"
                                                    placeholder=""
                                                    defaultValue={formik2.values.surfaceUpdate}
                                                    onChange={formik2.handleChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="wrap-row">
                                            <div className="wrap-one">
                                                <label>Adresse</label>
                                                <input type="text"
                                                    id="adresseUpdate"
                                                    name="adresseUpdate"
                                                    placeholder=""
                                                    defaultValue={formik2.values.adresseUpdate}
                                                    onChange={formik2.handleChange}
                                                />
                                            </div>
                                            <div className="wrap-one">
                                                <label>Responsable</label>
                                                <input type="text"
                                                    id="responsableParcUpdate"
                                                    name="responsableParcUpdate"
                                                    placeholder=""
                                                    defaultValue={formik2.values.responsableParcUpdate}
                                                    onChange={formik2.handleChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="wrap-row">
                                            <div className="wrap-one">
                                                <label>Intérime</label>
                                                <input type="text"
                                                    id="interimtResponsableParcUpdate"
                                                    name="interimtResponsableParcUpdate"
                                                    placeholder=""
                                                    defaultValue={formik2.values.interimtResponsableParcUpdate}
                                                    onChange={formik2.handleChange}
                                                />
                                            </div>
                                            <div className="wrap-one">
                                                <label >Propriétaire<span className='requiredField'>*</span></label>
                                                <select
                                                    id="idProprietaireUpdate"
                                                    name="idProprietaireUpdate"
                                                    value={formik2.values.idProprietaireUpdate}
                                                    onChange={formik2.handleChange}
                                                    style={(formik2.touched.idProprietaireUpdate && formik2.errors.idProprietaireUpdate) ? { borderColor: "red" } : null}
                                                >
                                                    <option value="">Veuillez sélectionner</option>
                                                    {proprietaires.map((proprietaire) => (
                                                        <option key={proprietaire.idProprietaire} value={proprietaire.idProprietaire}>{proprietaire.raisonSociale}</option>
                                                    ))}
                                                </select>
                                                {formik2.touched.idProprietaireUpdate && formik2.errors.idProprietaireUpdate ? (
                                                    <div className="invalid-feedback">{formik2.errors.idProprietaireUpdate}</div>
                                                ) : null}
                                            </div>
                                        </div>
                                        <div className="wrap-row">
                                            <div className="wrap-one">
                                                <label>Wilaya<span className='requiredField'>*</span></label>
                                                <select
                                                    id="idWilayaUpdate"
                                                    name="idWilayaUpdate"
                                                    value={formik2.values.idWilayaUpdate} onChange={formik2.handleChange}
                                                    style={(formik2.touched.idWilayaUpdate && formik2.errors.idWilayaUpdate) ? { borderColor: "red" } : null}
                                                >
                                                    <option value="">Veuillez sélectionner</option>
                                                    {wilayas.map((wilaya) => (
                                                        <option key={wilaya.idWilaya} value={wilaya.idWilaya}>{wilaya.nomLatin}</option>
                                                    ))}
                                                </select>
                                                {formik2.touched.idWilayaUpdate && formik2.errors.idWilayaUpdate ? (
                                                    <div className="invalid-feedback">{formik2.errors.idWilayaUpdate}</div>
                                                ) : null}
                                            </div>
                                            <div className="wrap-one">
                                                <label>Commune<span className='requiredField'>*</span></label>
                                                <select
                                                    id="idCommuneUpdate"
                                                    name="idCommuneUpdate"
                                                    value={formik2.values.idCommuneUpdate} onChange={formik2.handleChange}
                                                    style={(formik2.touched.idCommuneUpdate && formik2.errors.idCommuneUpdate) ? { borderColor: "red" } : null}
                                                >
                                                    <option value="">Veuillez sélectionner</option>
                                                    {communes.map((commune) => (
                                                        <option key={commune.idCommune} value={commune.idCommune}>{commune.nomLatin}</option>
                                                    ))}
                                                </select>
                                                {formik2.touched.idCommuneUpdate && formik2.errors.idCommuneUpdate ? (
                                                    <div className="invalid-feedback">{formik2.errors.idCommuneUpdate}</div>
                                                ) : null}
                                            </div>
                                            <div className="wrap-one">
                                                <label >Statut<span className='requiredField'>*</span></label>
                                                <select
                                                    id="statutUpdate"
                                                    name="statutUpdate"
                                                    defaultValue={formik2.values.statutUpdate}
                                                    onChange={formik2.handleChange}
                                                >
                                                    <option value="actif">Actif</option>
                                                    <option value="inactif">Inactif</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="popcard-operation-pickup-details_inside-zone">
                                        <div className="buttons">
                                            <input type="submit" value="Enregistrer" className="submit-btn" />
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>}

                    {showDetailsModal && <div className="popcard-add">
                        <div className="popcard-add_inside">
                            <Link className="popcard-add_inside-close" to="#" onClick={() => setShowDetailsModal(false)}>&#10006;</Link>
                            <h2 className="popcard-add_inside-title">Utilisation du parc</h2>
                            <div className="popcard-add_inside-form">
                                <div className="wrap-info">
                                    <div className="wrap-one">
                                        <label>Véhicule(s) : {formik3.values.countVehicule}</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>}

                    <ConfirmationDialog
                        open={confirmDialogOpen}
                        onClose={handleCancelDelete}
                        onConfirm={handleDelete}
                        titleMessage="Confirmer la suppression du parc"
                        bodyMessage="Voulez-vous vraiment supprimer ce parc? Toutes les données seront définitivement perdues."
                        btnCancelText="Annuler"
                        btnConfirmText="Supprimer le parc"
                    />

                </div>
            </div>
        </section>
    );
}

export default ListParcs;
