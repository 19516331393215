import React, { useEffect, useState, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import moment from 'moment/moment';
import { CustomToast } from '../../../../components/Toast/CustomToast';
import ConfirmationDialog from '../../../../components/Confirmation/ConfirmationDialog';
import { axiosInstance, UniteMesure } from '../../../../services/api';
import { AuthContext } from '../../../../contexts/AuthContext';
import useDebounce from '../../../../hooks/useDebounce';

const ListUdm = () => {
    const { authData } = useContext(AuthContext);
    const navigate = useNavigate();

    const [unites, setUnites] = useState([]);
    const [filtredUnites, setFiltredUnites] = useState([]);
    const [pageNumber, setPageNumber] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalItems, setTotalItems] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [loading, setLoading] = useState(true);
    const [showAddModal, setShowAddModal] = useState(false);
    const [showUpdateModal, setShowUpdateModal] = useState(false);
    const [idUniteMesureDelete, setIdUniteMesureDelete] = useState('');
    const [idUniteMesureUpdate, setIdUniteMesureUpdate] = useState('');

    const [search, setSearch] = useState('');
    const debouncedSearchText = useDebounce(search, 300);

    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);

    //Table management
    const [sortBy, setSortBy] = useState(''); // Column name to sort by
    const [sortOrder, setSortOrder] = useState('asc'); // 'asc' or 'desc'

    const handleSort = (column) => {
        setSortBy(column);
        setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');

        let sortedTable = [...filtredUnites].sort((a, b) => {
            const aValue = a[sortBy] || '';
            const bValue = b[sortBy] || '';

            if (sortOrder === 'asc') {
                return aValue.localeCompare(bValue);
            } else {
                return bValue.localeCompare(aValue);
            }
        });

        setFiltredUnites(sortedTable);
    };

    const handlePageChange = (page) => {
        setPageNumber(page);
        //consolelog("setPageNumber="+pageNumber)
        const startIndex = (page - 1) * pageSize;
        const endIndex = startIndex + pageSize;
        const paginatedData = unites.slice(startIndex, endIndex);

        setFiltredUnites(paginatedData);
    };

    const [openAboutList, setOpenAboutList] = useState(null);

    const toggleAboutList = (idMarque) => {
        // console.log(idMarque)
        setOpenAboutList(openAboutList === idMarque ? null : idMarque);
    };

    const handlePerRowsChange = (newPerPage) => {
        setPageSize(newPerPage);
        //console.log("setPageSize="+pageSize);
    };

    const showHideAddModal = (val) => {
        setShowAddModal(val);
        setIdUniteMesureUpdate('');
        formik1.resetForm();
    };

    const showHideUpdateModal = (val, idUniteMesure, libelleUniteMesure, codeUniteMesure, codeTypeUniteMesure) => {
        setShowUpdateModal(val);
        setIdUniteMesureUpdate(idUniteMesure);
        formik2.resetForm();
        if (val) {
            formik2.values.libelleUniteMesureUpdate = libelleUniteMesure;
            formik2.values.codeUniteMesureUpdate = codeUniteMesure;
            formik2.values.codeTypeUniteMesureUpdate = codeTypeUniteMesure;
        }
    };

    //Supprimer unité
    const deleteUnite = async (idUniteMesure) => {
        try {
            const response = await axiosInstance.delete(`${UniteMesure}/${idUniteMesure}`);
            getUnites();
            CustomToast("L'unité de mesure a été supprimée avec succès", 'success');
        }
        catch (error) {
            // Handle error
            if (!error?.response) {
                CustomToast("Aucune réponse du serveur", 'error');
            } else if (error.response?.status === 401) {
                CustomToast("Non autorisé", 'error');
            } else {
                CustomToast("Demande échoué", 'error');
            }
            console.log(error);
        }
    }

    const handleConfirmDelete = (idUniteMesure) => {
        // Show the modal when the confirm delete button is clicked
        //deleteWilaya(idUniteMesure);
        setIdUniteMesureDelete(idUniteMesure);
        setConfirmDialogOpen(true);
    };

    const handleDelete = async () => {
        try {
            const response = await axiosInstance.delete(`${UniteMesure}/${idUniteMesureDelete}`);
            //getUnites();
            setIdUniteMesureDelete('');
            CustomToast("L'unité de mesure a été supprimée avec succès", 'success');
            getUnites();
            setConfirmDialogOpen(false);
            //navigate('/listudm', { replace: true });
        }
        catch (error) {
            // Handle error
            if (!error?.response) {
                CustomToast("Aucune réponse du serveur", 'error');
            } else if (error.response?.status === 401) {
                CustomToast("Non autorisé", 'error');
            } else {
                CustomToast("Demande échoué", 'error');
            }
            console.log(error);
        }
    }

    const handleCancelDelete = () => {
        setConfirmDialogOpen(false);
    };

    //Load data
    const getUnites = async () => {
        try {
            const response = await axiosInstance.get(`${UniteMesure}`);
            const resp = await response.data;

            setUnites(resp);
            let filteredResult = resp;

            if (search !== '') {
                filteredResult = resp.filter((item) =>
                    Object.values(item).some(
                        (value) =>
                            value &&
                            value.toString().toLowerCase().includes(search.toLowerCase())
                    )
                );
            }

            // Pagination
            const startIndex = (pageNumber - 1) * pageSize;
            const endIndex = startIndex + pageSize;
            const paginatedResult = filteredResult.slice(startIndex, endIndex);

            setFiltredUnites(paginatedResult);
            setPageNumber(1);
            setTotalItems(filteredResult.length);
            setTotalPages(Math.ceil(filteredResult.length / pageSize));
            setLoading(false);

            setLoading(false);
        }
        catch (err) {
            setLoading(false);
            console.log(err);
        }
    }

    const handleSearch = (searchTerm) => {

        const result = unites?.filter((item) =>
            Object.values(item).some(
                (value) =>
                    value &&
                    value.toString().toLowerCase().includes(searchTerm.toLowerCase())
            )
        );
        //console.log(searchTerm,result)
        // Pagination
        const startIndex = (pageNumber - 1) * pageSize;
        const endIndex = startIndex + pageSize;
        const paginatedResult = result.slice(startIndex, endIndex);
        setFiltredUnites(paginatedResult);
        setPageNumber(1);
        setTotalItems(result.length);
        setTotalPages(Math.ceil(result.length / pageSize));
        setSearch(searchTerm);
    };

    const formik1 = useFormik({
        initialValues: {
            libelleUniteMesure: '',
            codeUniteMesure: '',
            codeTypeUniteMesure: '',
        },
        validationSchema: Yup.object({
            libelleUniteMesure: Yup.string()
                .required('Champ obligatoire'),
            codeUniteMesure: Yup.string()
                .required('Champ obligatoire'),
            codeTypeUniteMesure: Yup.string()
                .required('Champ obligatoire')
        }),
        onSubmit: async (values) => {
            try {
                const postData = {
                    libelleUniteMesure: values.libelleUniteMesure,
                    codeUniteMesure: values.codeUniteMesure,
                    codeTypeUniteMesure: values.codeTypeUniteMesure,
                    idUtilisateur: authData.idUser,
                };

                const response = await axiosInstance.post(UniteMesure, postData);
                CustomToast("L'unité de mesure a été créée avec succès", 'success');
                getUnites();
                setShowAddModal(false);
                //navigate('/listudm', { replace: true });
            } catch (error) {
                // Handle error
                if (!error?.response) {
                    CustomToast("Aucune réponse du serveur", 'error');
                } else if (error.response?.status === 400 && error.response?.data === "UDM existe") {
                    CustomToast("Unité de mesure existe", 'error');
                } else if (error.response?.status === 401) {
                    CustomToast("Non autorisé", 'error');
                } else {
                    CustomToast("Demande échoué", 'error');
                }
                console.log(error);
            }
        }
    });

    const formik2 = useFormik({
        initialValues: {
            libelleUniteMesureUpdate: '',
            codeUniteMesureUpdate: '',
            codeTypeUniteMesureUpdate: '',
        },
        validationSchema: Yup.object({
            libelleUniteMesureUpdate: Yup.string()
                .required('Champ obligatoire'),
            codeUniteMesureUpdate: Yup.string()
                .required('Champ obligatoire'),
            codeTypeUniteMesureUpdate: Yup.string()
                .required('Champ obligatoire')
        }),
        onSubmit: async (values) => {
            try {
                const postData = {
                    idUniteMesure: idUniteMesureUpdate,
                    libelleUniteMesure: values.libelleUniteMesureUpdate,
                    codeUniteMesure: values.codeUniteMesureUpdate,
                    codeTypeUniteMesure: values.codeTypeUniteMesureUpdate,
                    idUtilisateur: authData.idUser,
                };

                //console.log(postData);               
                const response = await axiosInstance.put(UniteMesure, postData);
                CustomToast("L'unité de mesure a été modifiée avec succès", 'success');
                showHideUpdateModal(false, '', '', '', '');
                getUnites();

                //navigate('/listudm', { replace: true });
            } catch (error) {
                // Handle error
                if (!error?.response) {
                    CustomToast("Aucune réponse du serveur", 'error');
                } else if (error.response?.status === 400 && error.response?.data === "Wilaya existe") {
                    CustomToast("Wilaya existe", 'error');
                } else if (error.response?.status === 401) {
                    CustomToast("Non autorisé", 'error');
                } else {
                    CustomToast("Demande échoué", 'error');
                }
                console.log(error);
            }
        }
    });

    useEffect(() => {
        //Load Data
        getUnites();
        return () => {
            // Perform any necessary cleanup (e.g., cancel pending requests)
        };
    }, [authData, pageSize]);

    useEffect(() => {
        //Load Data
        handleSearch(debouncedSearchText);
        return () => {
            // Perform any necessary cleanup (e.g., cancel pending requests)
        };
    }, [debouncedSearchText]);

    // useEffect(() => {
    //     const result = unites?.filter((item) =>
    //         Object.values(item).some(
    //             (value) =>
    //                 value &&
    //                 value.toString().toLowerCase().includes(search.toLowerCase())
    //         )
    //     );
    //     setFiltredUnites(result);
    // }, [search, unites])

    return (
        <section className="donnee-section" onClick={() => { if (openAboutList !== null) { setOpenAboutList(null) } }}>
            <div className="container diver-section">
                <div className="mesure-sec wrap-all">
                    <div className="row row-center">
                        <Link to="/divers" className="retour cursurer" id="vehicule-list-assrc-retour">
                            <div className="arrow-back">
                                <span className="material-symbols-outlined" id="arrow">arrow_back</span>
                            </div>
                        </Link>
                        <Link href="#" className="color-primary-2 decoration-none" onClick={() => showHideAddModal(true)}>
                            <div className="add-new" id="add-vehc">
                                <span className="material-symbols-outlined">add</span>
                                Ajouter une unité de mesure
                            </div>
                        </Link>
                    </div>

                    <div className="group-elements">
                        <div className="voir-elements">
                            <p></p>
                            <select
                                name="pageSize"
                                id="pageSize"
                                onChange={(e) => { handlePerRowsChange(e.target.value || 10); setPageNumber(1); }}
                            >
                                <option value="10" defaultValue={true}>10</option>
                                <option value="15">15</option>
                                <option value="20">20</option>
                            </select>
                        </div>
                        <div className="search-element">
                            <p>Rechercher</p>
                            <input
                                type="text"
                                value={search}
                                onChange={(e) => handleSearch(e.target.value)}
                            />
                        </div>
                    </div>
                    {filtredUnites?.length > 0 && <div className="table-wrap">
                            <table>
                                <thead>
                                    <tr>
                                        <th onClick={() => handleSort('libelleUniteMesure')}>Nom</th>
                                        <th onClick={() => handleSort('codeUniteMesure')}>code</th>
                                        <th onClick={() => handleSort('codeTypeUniteMesure')}>Type</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {filtredUnites?.map((row) => (
                                        <tr key={row.idUniteMesure}>
                                            <td>{row.libelleUniteMesure}</td>
                                            <td>{row.codeUniteMesure}</td>
                                            <td>
                                                {
                                                    (() => {
                                                        switch (row.codeTypeUniteMesure) {
                                                            case "longueur":
                                                                return <>Longueur</>;
                                                            case "masse":
                                                                return <>Masse</>;
                                                            case "temps":
                                                                return <>Temps</>;
                                                            case "courant":
                                                                return <>Courant électrique</>;
                                                            case "température":
                                                                return <>Température</>;
                                                            case "volume":
                                                                return <>Volume</>;
                                                            default:
                                                                return <></>;
                                                        }
                                                    })()
                                                }
                                            </td>
                                            <td className="txt-center relative">
                                            <span className="material-symbols-outlined about-btn" onClick={() => toggleAboutList(row.idUniteMesure)}>
                                                more_horiz
                                            </span>
                                            {openAboutList === row.idUniteMesure && (
                                                <div className="about-list">
                                                    <ul>
                                                        <li onClick={() => showHideUpdateModal(true, row.idUniteMesure, row.libelleUniteMesure, row.codeUniteMesure, row.codeTypeUniteMesure)}>
                                                            <span className="material-symbols-outlined">edit</span>
                                                            <p>Modifier</p>
                                                        </li>
                                                        <li onClick={() => handleConfirmDelete(row.idUniteMesure)}>
                                                            <span className="material-symbols-outlined">delete</span>
                                                            <p>Supprimer</p>
                                                        </li>
                                                    </ul>
                                                </div>
                                            )}
                                        </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>}
                        <div className="pagination">
                            <Link to="#" onClick={() => handlePageChange(pageNumber - 1)}
                                className={(pageNumber === 1) ? 'pgn disabled' : 'pgn'}
                                disabled={(pageNumber === 1) ? true : false}
                            >&laquo;</Link>
                            {Array.from({ length: totalPages }, (_, i) => (
                                <Link
                                    key={i + 1}
                                    to="#"
                                    className={i + 1 === pageNumber ? 'pgn active' : 'pgn'}
                                    onClick={() => handlePageChange(i + 1)}
                                >
                                    {i + 1}
                                </Link>
                            ))}
                            <Link to="#" onClick={() => handlePageChange(pageNumber + 1)}
                                className={((pageNumber + 1) > totalPages) ? 'pgn disabled' : 'pgn'}
                                disabled={((pageNumber + 1) > totalPages) ? true : false}
                            >&raquo;</Link>
                        </div>
                    {showAddModal && <div className="popcard-add">
                        <div className="popcard-add_inside">
                            <Link className="popcard-add_inside-close" to="#" onClick={() => showHideAddModal(false)}>&#10006;</Link>
                            <h2 className="popcard-add_inside-title"></h2>
                            <div className="popcard-add_inside-form">
                                <form onSubmit={formik1.handleSubmit}>
                                    <div className="wrap-info">
                                        <div className="wrap-one">
                                            <label>Nom<span className='requiredField'>*</span></label>
                                            <input type="text"
                                                id="libelleUniteMesure"
                                                name="libelleUniteMesure"
                                                style={(formik1.touched.libelleUniteMesure && formik1.errors.libelleUniteMesure) ? { borderColor: "red" } : null}
                                                placeholder=""
                                                defaultValue={formik1.values.libelleUniteMesure}
                                                onChange={formik1.handleChange}
                                            />
                                            {formik1.touched.libelleUniteMesure && formik1.errors.libelleUniteMesure ? (
                                                <div className="invalid-feedback">{formik1.errors.libelleUniteMesure}</div>
                                            ) : null}
                                        </div>
                                        <div className="wrap-one">
                                            <label>Code<span className='requiredField'>*</span></label>
                                            <input type="text"
                                                id="codeUniteMesure"
                                                name="codeUniteMesure"
                                                style={(formik1.touched.codeUniteMesure && formik1.errors.codeUniteMesure) ? { borderColor: "red" } : null}
                                                placeholder=""
                                                defaultValue={formik1.values.codeUniteMesure}
                                                onChange={formik1.handleChange}
                                            />
                                            {formik1.touched.codeUniteMesure && formik1.errors.codeUniteMesure ? (
                                                <div className="invalid-feedback">{formik1.errors.codeUniteMesure}</div>
                                            ) : null}
                                        </div>
                                        <div className="wrap-one">
                                            <label >Type<span className='requiredField'>*</span></label>
                                            <select
                                                id="codeTypeUniteMesure"
                                                name="codeTypeUniteMesure"
                                                value={formik1.values.codeTypeUniteMesure}
                                                onChange={formik1.handleChange}
                                            >
                                                <option value="" defaultValue={true}>Veuillez sélectionner</option>
                                                <option value="longueur">Longueur</option>
                                                <option value="masse">Masse</option>
                                                <option value="volume">Volume</option>
                                                <option value="temps">Temps</option>
                                                <option value="température">Température</option>
                                                <option value="courant">Courant électrique</option>
                                            </select>
                                            {formik1.touched.codeTypeUniteMesure && formik1.errors.codeTypeUniteMesure ? (
                                                <div className="invalid-feedback">{formik1.errors.codeTypeUniteMesure}</div>
                                            ) : null}
                                        </div>
                                    </div>

                                    <div className="popcard-operation-pickup-details_inside-zone">
                                        <div className="buttons">
                                            <input type="submit" value="Enregistrer" className="submit-btn" />
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>}

                    {showUpdateModal && <div className="popcard-add">
                        <div className="popcard-add_inside">
                            <Link className="popcard-add_inside-close" to="#" onClick={() => showHideUpdateModal(false, '', '', '', '')}>&#10006;</Link>
                            <h2 className="popcard-add_inside-title"></h2>
                            <div className="popcard-add_inside-form">
                                <form onSubmit={formik2.handleSubmit}>
                                    <div className="wrap-info">
                                        <div className="wrap-one">
                                            <label>Nom<span className='requiredField'>*</span></label>
                                            <input type="text"
                                                id="libelleUniteMesureUpdate"
                                                name="libelleUniteMesureUpdate"
                                                style={(formik2.touched.libelleUniteMesureUpdate && formik2.errors.libelleUniteMesureUpdate) ? { borderColor: "red" } : null}
                                                placeholder=""
                                                defaultValue={formik2.values.libelleUniteMesureUpdate}
                                                onChange={formik2.handleChange}
                                            />
                                            {formik2.touched.libelleUniteMesureUpdate && formik2.errors.libelleUniteMesureUpdate ? (
                                                <div className="invalid-feedback">{formik2.errors.libelleUniteMesureUpdate}</div>
                                            ) : null}
                                        </div>
                                        <div className="wrap-one">
                                            <label>Code<span className='requiredField'>*</span></label>
                                            <input type="text"
                                                id="codeUniteMesureUpdate"
                                                name="codeUniteMesureUpdate"
                                                style={(formik2.touched.codeUniteMesureUpdate && formik2.errors.codeUniteMesureUpdate) ? { borderColor: "red" } : null}
                                                placeholder=""
                                                defaultValue={formik2.values.codeUniteMesureUpdate}
                                                onChange={formik2.handleChange}
                                            />
                                            {formik2.touched.codeUniteMesureUpdate && formik2.errors.codeUniteMesureUpdate ? (
                                                <div className="invalid-feedback">{formik2.errors.codeUniteMesureUpdate}</div>
                                            ) : null}
                                        </div>
                                        <div className="wrap-one">
                                            <label >Type<span className='requiredField'>*</span></label>
                                            <select
                                                id="codeTypeUniteMesureUpdate"
                                                name="codeTypeUniteMesureUpdate"
                                                value={formik2.values.codeTypeUniteMesureUpdate}
                                                onChange={formik2.handleChange}
                                            >
                                                <option value="" defaultValue={true}>Veuillez sélectionner</option>
                                                <option value="longueur">Longueur</option>
                                                <option value="masse">Masse</option>
                                                <option value="volume">Volume</option>
                                                <option value="temps">Temps</option>
                                                <option value="température">Température</option>
                                                <option value="courant">Courant électrique</option>
                                            </select>
                                            {formik2.touched.codeTypeUniteMesureUpdate && formik2.errors.codeTypeUniteMesureUpdate ? (
                                                <div className="invalid-feedback">{formik2.errors.codeTypeUniteMesureUpdate}</div>
                                            ) : null}
                                        </div>
                                    </div>

                                    <div className="popcard-operation-pickup-details_inside-zone">
                                        <div className="buttons">
                                            <input type="submit" value="Enregistrer" className="submit-btn" />
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>}

                    <ConfirmationDialog
                        open={confirmDialogOpen}
                        onClose={handleCancelDelete}
                        onConfirm={handleDelete}
                        titleMessage="Confirmer la suppression de l'unité de mesure"
                        bodyMessage="Voulez-vous vraiment supprimer cette unté de mesure? Toutes les données seront définitivement perdues."
                        btnCancelText="Annuler"
                        btnConfirmText="Supprimer l'unité"
                    />

                </div>
            </div>
        </section>
    );
}

export default ListUdm;
