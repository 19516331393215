import React, { useEffect, useState, useContext } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import moment from 'moment/moment';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { axiosInstance, VehiculeInspection } from '../../services/api';
import { AuthContext } from '../../contexts/AuthContext';

const ApercuInspection = () => {
    const { authData } = useContext(AuthContext);
    const navigate = useNavigate();
    const { id } = useParams();//idVehiculeInspection

    const [isLoaded, setIsLoaded] = useState();

    // State to manage visibility of comment areas
    const [commentVisibility, setCommentVisibility] = useState([]);

    // Function to toggle comment area visibility
    const toggleCommentVisibility = (index) => {
        //console.log(commentVisibility);
        setCommentVisibility((prevVisibility) => {
            const updatedVisibility = [...prevVisibility];
            updatedVisibility[index] = !updatedVisibility[index];
            return updatedVisibility;
        });
    };

    //Load data
    const formik = useFormik({
        initialValues: {
            dateInspection: '',
            inspectionItems: [],
            vehiculeNom: '',
            vehiculeImmatriculation: '',
            vehiculeStatut: '',
            vehiculeMarque: '',
            vehiculeModele: '',
            vehiculeType: '',
            vehiculePathImage: '',
            conducteurNom: '',
            conducteurPrenom: '',
            formulaireTitre: ''
        }
    });

    const getInspection = async (idVehiculeInspection) => {
        try {
            const queryParams = new URLSearchParams({
                pageNumber: 1,
                pageSize: 1,
                idInspection: idVehiculeInspection
            });

            if (authData?.wilayas != null) {
                const wilayasFilter = authData?.wilayas
                queryParams.append("wilayas", wilayasFilter.join(','));
            }
            else {
                return
            }

            if (authData?.flottesAutorises != null) {
                const flottesAutorisesFilter = authData?.flottesAutorises
                queryParams.append("flottesAutorises", flottesAutorisesFilter.join(','));
            }
            else {
                return
            }

            const response = await axiosInstance.get(`${VehiculeInspection}/GetInspections?${queryParams}`);
            const resp = await response.data;
            setIsLoaded(resp.data[0]);
            formik.setValues(prevValues => ({
                ...prevValues,
                dateInspection: resp.data[0].dateInspection || '',
                inspectionItems: resp.data[0].inspectionItems || [],
                vehiculeNom: resp.data[0].vehiculeNom || '',
                vehiculeImmatriculation: resp.data[0].vehiculeImmatriculation || '',
                vehiculeStatut: resp.data[0].vehiculeStatut || '',
                vehiculeMarque: resp.data[0].vehiculeMarque || '',
                vehiculeModele: resp.data[0].vehiculeModele || '',
                vehiculeType: resp.data[0].vehiculeType || '',
                vehiculePathImage: resp.data[0].vehiculePathImage || '',
                conducteurNom: resp.data[0].conducteurNom || '',
                conducteurPrenom: resp.data[0].conducteurPrenom || '',
                formulaireTitre: resp.data[0].formulaireTitre
            }));
        }
        catch (error) {
            // Handle error
            console.log(error);
        }
    }

    useEffect(() => {
        //Load Data
        getInspection(id);
        return () => {
            // Perform any necessary cleanup (e.g., cancel pending requests)
        };
    }, [id, authData]);

    return (
        <section className="inspection-section">
            <div className="detailinspection-sec container">
                <div className="grid grid--2-cols">
                    <div className="inspection-card">
                        <h3 className="heading-primary">Détails de l'inspection</h3>
                        <div className="wrap">
                            <div className="row">
                                <p className="primary">Véhicule</p>
                                <div className="row-1">
                                    <svg height="11.87" width="15">
                                        <circle cx="6" cy="6" r="6" fill={formik.values.vehiculeStatut === "disponible" ? "green" : formik.values.vehiculeStatut === "indisponible" ? "orangered" : formik.values.vehiculeStatut === "archivé" ? "grey" : formik.values.vehiculeStatut === "vendu" ? "blue" : formik.values.vehiculeStatut === "en cours d'utilisation" ? "yellow" : formik.values.vehiculeStatut === "hors service" ? "red" : null} />
                                    </svg>
                                    <p>{formik.values.vehiculeNom + ' ' + formik.values.vehiculeImmatriculation}</p>
                                </div>
                            </div>
                            <div className="row">
                                <p className="primary">Formulaire d'inspection</p>
                                <p>{formik.values.formulaireTitre}</p>
                            </div>
                            <div className="row">
                                <p className="primary">Date d'inspection</p>
                                <p>{moment(formik.values.dateInspection).format('DD-MM-YYYY HH:mm')}</p>
                            </div>
                            <div className="row">
                                <p className="primary">Utilisateur</p>
                                <p>{formik.values.conducteurNom + ' ' + formik.values.conducteurPrenom}</p>
                            </div>
                        </div>
                    </div>
                    <div className="inspection-card">
                        <h3 className="heading-primary">Éléments d'inspection</h3>
                        <div className="wrap">
                            {formik.values.inspectionItems?.map((item, index) => (
                                <div className="row row-center" key={index}>
                                    <p className="primary">{item?.itemTitre}</p>
                                    <div className="row-1">
                                        <span className={item?.statut === "fail" ? "material-symbols-outlined danger" : "material-symbols-outlined success"}>
                                            {item?.statut === "fail" ? "close" : "done"}
                                        </span>
                                        <p>{item?.statut === "fail" ? "Échouer" : "Passer"}</p>
                                        <span
                                            className={item?.commentaire ? "material-symbols-outlined primary cursurer dec" : "material-symbols-outlined primary dec"}
                                            id={`descp${index}`}
                                            onClick={() => toggleCommentVisibility(index)}
                                        >
                                            description
                                        </span>
                                        {item.commentaire && <div
                                            className={(commentVisibility[index] && item?.commentaire) ? "commentaire-fnt-size" : "none commentaire-fnt-size"}
                                            id={`comment${index}`}
                                        >
                                            <p className="descrp-details">{item?.commentaire}</p>
                                        </div>}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default ApercuInspection;
