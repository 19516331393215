import React, { useEffect, useState, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import moment from 'moment/moment';
import { CustomToast } from '../../../../components/Toast/CustomToast';
import ConfirmationDialog from '../../../../components/Confirmation/ConfirmationDialog';
import { axiosInstance, VehiculeType } from '../../../../services/api';
import { AuthContext } from '../../../../contexts/AuthContext';
import useDebounce from '../../../../hooks/useDebounce';

const ListTypeVehicules = () => {
    const { authData } = useContext(AuthContext);
    const navigate = useNavigate();

    const [typesVehicules, setTypesVehicules] = useState([]);
    const [filtredTypesVehicules, setFiltredTypesVehicules] = useState([]);
    const [pageNumber, setPageNumber] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalItems, setTotalItems] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [loading, setLoading] = useState(true);
    const [showAddModal, setShowAddModal] = useState(false);
    const [showUpdateModal, setShowUpdateModal] = useState(false);
    const [idTypeVehiculeDelete, setIdTypeVehiculeDelete] = useState('');
    const [idTypeVehiculeUpdate, setIdTypeVehiculeUpdate] = useState('');

    const [search, setSearch] = useState('');
    const debouncedSearchText = useDebounce(search, 300);

    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);


    //Table management
    const [sortBy, setSortBy] = useState(''); // Column name to sort by
    const [sortOrder, setSortOrder] = useState('asc'); // 'asc' or 'desc'

    const handleSort = (column) => {
        setSortBy(column);
        setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');

        let sortedTable = [...filtredTypesVehicules].sort((a, b) => {
            const aValue = a[sortBy] || '';
            const bValue = b[sortBy] || '';

            if (sortOrder === 'asc') {
                return aValue.localeCompare(bValue);
            } else {
                return bValue.localeCompare(aValue);
            }
        });

        setFiltredTypesVehicules(sortedTable);
    };

    const handlePageChange = (page) => {
        setPageNumber(page);
        //consolelog("setPageNumber="+pageNumber)
        const startIndex = (page - 1) * pageSize;
        const endIndex = startIndex + pageSize;
        const paginatedData = typesVehicules.slice(startIndex, endIndex);

        setFiltredTypesVehicules(paginatedData);
    };

    const [openAboutList, setOpenAboutList] = useState(null);

    const toggleAboutList = (idMarque) => {
        // console.log(idMarque)
        setOpenAboutList(openAboutList === idMarque ? null : idMarque);
    };

    const handlePerRowsChange = (newPerPage) => {
        setPageSize(newPerPage);
        //console.log("setPageSize="+pageSize);
    };

    const showHideAddModal = (val) => {
        setShowAddModal(val);
        setIdTypeVehiculeUpdate('');
        formik1.resetForm();
    };

    const showHideUpdateModal = (val, idVehiculeType, libelleVehiculeType, statutVehiculeType) => {
        setShowUpdateModal(val);
        setIdTypeVehiculeUpdate(idVehiculeType);
        formik2.resetForm();
        if (val) {
            formik2.values.libelleVehiculeTypeUpdate = libelleVehiculeType;
            formik2.values.statutVehiculeTypeUpdate = statutVehiculeType;
        }
    };

    //Supprimer type de véhicule
    const deleteVehiculeType = async (idVehiculeType) => {
        try {
            const response = await axiosInstance.delete(`${VehiculeType}/${idVehiculeType}`);
            getTypesVehicules();
            CustomToast("Le type de véhicule a été supprimé avec succès", 'success');
        }
        catch (error) {
            // Handle error
            if (!error?.response) {
                CustomToast("Aucune réponse du serveur", 'error');
            } else if (error.response?.status === 400 && error.response?.data === "Vehicle type is already used. Cannot delete.") {
                CustomToast("Le type de véhicule est déjà utilisé. Impossible de suprimer", 'error');
            } else if (error.response?.status === 401) {
                CustomToast("Non autorisé", 'error');
            } else {
                CustomToast("Demande échoué", 'error');
            }
            console.log(error);
        }
    }

    const handleConfirmDelete = (idVehiculeType) => {
        // Show the modal when the confirm delete button is clicked
        //deleteVehiculeType(idVehiculeType);
        setIdTypeVehiculeDelete(idVehiculeType);
        setConfirmDialogOpen(true);
    };

    const handleDelete = async () => {
        try {
            const response = await axiosInstance.delete(`${VehiculeType}/${idTypeVehiculeDelete}`);
            //getTypesVehicules();
            setIdTypeVehiculeDelete('');
            CustomToast("Le type de véhicule a été supprimé avec succès", 'success');
            getTypesVehicules();
            setConfirmDialogOpen(false);
            //navigate('/listtypevehicules', { replace: true });
        }
        catch (error) {
            // Handle error
            if (!error?.response) {
                CustomToast("Aucune réponse du serveur", 'error');
            } else if (error.response?.status === 400 && error.response?.data === "Vehicle type is already used. Cannot delete.") {
                CustomToast("Le type de véhicule est déjà utilisé. Impossible de suprimer", 'error');
            } else if (error.response?.status === 401) {
                CustomToast("Non autorisé", 'error');
            } else {
                CustomToast("Demande échoué", 'error');
            }
            console.log(error);
        }
    }

    const handleCancelDelete = () => {
        setConfirmDialogOpen(false);
    };

    //Load data
    const getTypesVehicules = async () => {
        try {
            const response = await axiosInstance.get(`${VehiculeType}/GeVehiculeTypeUsage`);
            const resp = await response.data;
            console.log("getTypesVehicules");
            setTypesVehicules(resp);
            let filteredResult = resp;

            if (search !== '') {
                filteredResult = resp.filter((item) =>
                    Object.values(item).some(
                        (value) =>
                            value &&
                            value.toString().toLowerCase().includes(search.toLowerCase())
                    )
                );
            }

            // Pagination
            const startIndex = (pageNumber - 1) * pageSize;
            const endIndex = startIndex + pageSize;
            const paginatedResult = filteredResult.slice(startIndex, endIndex);

            setFiltredTypesVehicules(paginatedResult);
            setPageNumber(1);
            setTotalItems(filteredResult.length);
            setTotalPages(Math.ceil(filteredResult.length / pageSize));
            setLoading(false);
        }
        catch (err) {
            setLoading(false);
            console.log(err);
        }
    }

    const handleSearch = (searchTerm) => {

        const result = typesVehicules?.filter((item) =>
            Object.values(item).some(
                (value) =>
                    value &&
                    value.toString().toLowerCase().includes(searchTerm.toLowerCase())
            )
        );
        //console.log(searchTerm,result)
        // Pagination
        const startIndex = (pageNumber - 1) * pageSize;
        const endIndex = startIndex + pageSize;
        const paginatedResult = result.slice(startIndex, endIndex);
        setFiltredTypesVehicules(paginatedResult);
        setPageNumber(1);
        setTotalItems(result.length);
        setTotalPages(Math.ceil(result.length / pageSize));
        setSearch(searchTerm);
    };


    const formik1 = useFormik({
        initialValues: {
            libelleVehiculeType: '',
            statutVehiculeType: ''
        },
        validationSchema: Yup.object({
            libelleVehiculeType: Yup.string()
                .required('Champ obligatoire')
        }),
        onSubmit: async (values) => {
            try {
                const postData = {
                    libelleVehiculeType: values.libelleVehiculeType,
                    statutVehiculeType: values.statutVehiculeType,
                    idUtilisateur: authData.idUser,
                };

                const response = await axiosInstance.post(VehiculeType, postData);
                CustomToast("Le type de véhicule a été créé avec succès", 'success');
                getTypesVehicules();
                setShowAddModal(false);
                //navigate('/listtypevehicules', { replace: true });
            } catch (error) {
                // Handle error
                if (!error?.response) {
                    CustomToast("Aucune réponse du serveur", 'error');
                } else if (error.response?.status === 400 && error.response?.data === "Type vehicule existe") {
                    CustomToast("Type de véhicule existe", 'error');
                } else if (error.response?.status === 401) {
                    CustomToast("Non autorisé", 'error');
                } else {
                    CustomToast("Demande échoué", 'error');
                }
                console.log(error);
            }
        }
    });

    const formik2 = useFormik({
        initialValues: {
            libelleVehiculeTypeUpdate: '',
            statutVehiculeTypeUpdate: ''
        },
        validationSchema: Yup.object({
            libelleVehiculeTypeUpdate: Yup.string()
                .required('Champ obligatoire')
        }),
        onSubmit: async (values) => {
            try {

                const postData = {
                    idVehiculeType: idTypeVehiculeUpdate,
                    libelleVehiculeType: values.libelleVehiculeTypeUpdate,
                    statutVehiculeType: values.statutVehiculeTypeUpdate,
                    idUtilisateur: authData.idUser,
                };

                //console.log(postData);               
                const response = await axiosInstance.put(VehiculeType, postData);
                CustomToast("Le type de véhicule a été modifié avec succès", 'success');
                showHideUpdateModal(false, '', '');
                getTypesVehicules();

                //navigate('/listtypevehicules', { replace: true });
            } catch (error) {
                // Handle error
                if (!error?.response) {
                    CustomToast("Aucune réponse du serveur", 'error');
                } else if (error.response?.status === 400 && error.response?.data === "Type vehicule existe") {
                    CustomToast("Type de véhicule existe", 'error');
                } else if (error.response?.status === 401) {
                    CustomToast("Non autorisé", 'error');
                } else {
                    CustomToast("Demande échoué", 'error');
                }
                console.log(error);
            }
        }
    });

    useEffect(() => {
        //Load Data
        getTypesVehicules();
        //console.log("First");
        return () => {
            // Perform any necessary cleanup (e.g., cancel pending requests)
        };
    }, [authData, pageSize]);

    useEffect(() => {
        //Load Data
        handleSearch(debouncedSearchText);
        return () => {
            // Perform any necessary cleanup (e.g., cancel pending requests)
        };
    }, [debouncedSearchText]);

    // useEffect(() => {
    //     // const result=vehicules.filter(row=>{
    //     //     return row.vehiculeNom && row.vehiculeNom.toLowerCase().match(search.toLowerCase());   
    //     // })
    //     //console.log("search");
    //     const result = typesVehicules?.filter((item) =>
    //         Object.values(item).some(
    //             (value) =>
    //                 value &&
    //                 value.toString().toLowerCase().includes(search.toLowerCase())
    //         )
    //     );
    //     setFiltredTypesVehicules(result);
    // }, [search, typesVehicules])

    return (
        <section className="donnee-section" onClick={() => { if (openAboutList !== null) { setOpenAboutList(null) } }}>
            <div className="container diver-section">
                <div className="typeveh-sec wrap-all">
                    <div className="row row-center">
                        <Link to="/divers" className="retour cursurer" id="vehicule-list-assrc-retour">
                            <div className="arrow-back">
                                <span className="material-symbols-outlined" id="arrow">arrow_back</span>
                            </div>
                        </Link>
                        <Link href="#" className="color-primary-2 decoration-none" onClick={() => showHideAddModal(true)}>
                            <div className="add-new" id="add-vehc">
                                <span className="material-symbols-outlined">add</span>
                                Ajouter un type de véhicule
                            </div>
                        </Link>
                    </div>

                     <div className="group-elements">
                        <div className="voir-elements">
                            <p></p>
                            <select
                                name="pageSize"
                                id="pageSize"
                                onChange={(e) => { handlePerRowsChange(e.target.value || 10); setPageNumber(1); }}
                            >
                                <option value="10" defaultValue={true}>10</option>
                                <option value="15">15</option>
                                <option value="20">20</option>
                            </select>
                        </div>
                        <div className="search-element">
                            <p>Rechercher</p>
                            <input
                                type="text"
                                value={search}
                                onChange={(e) => handleSearch(e.target.value)}
                            />
                        </div>
                    </div>
                    {filtredTypesVehicules?.length > 0 && <div className="table-wrap">
                            <table>
                                <thead>
                                    <tr>
                                        <th onClick={() => handleSort('libelleVehiculeType')}>Nom</th>
                                        <th>Statut</th>
                                        <th>Véhicules</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {filtredTypesVehicules?.map((row) => (
                                        <tr key={row.idVehiculeType}>
                                            <td>{row.libelleVehiculeType}</td>
                                            <td>
                                                {
                                                    row.statutVehiculeType === "actif" ? (
                                                        <>
                                                            <svg height="11.87" width="15"><circle cx="6" cy="6" r="6" fill="green" /></svg>Actif
                                                        </>
                                                    ) : (
                                                        <>
                                                            <svg height="11.87" width="15"><circle cx="6" cy="6" r="6" fill="red" /></svg>Inactif
                                                        </>
                                                    )
                                                }
                                            </td>

                                            <td>{row.vehiculeCount + " véhicules(s)"}</td>
                                            <td className="txt-center relative">
                                            <span className="material-symbols-outlined about-btn" onClick={() => toggleAboutList(row.idVehiculeType)}>
                                                more_horiz
                                            </span>
                                            {openAboutList === row.idVehiculeType && (
                                                <div className="about-list">
                                                        <ul>
                                                            <li onClick={() => showHideUpdateModal(true, row.idVehiculeType, row.libelleVehiculeType, row.statutVehiculeType)}>
                                                                <span className="material-symbols-outlined">edit</span>
                                                                <p>Modifier</p>
                                                            </li>
                                                            {(row.vehiculeCount == 0) && <li onClick={() => handleConfirmDelete(row.idVehiculeType)}>
                                                                <span className="material-symbols-outlined">delete</span>
                                                                <p>Supprimer</p>
                                                            </li>}
                                                            {(row.vehiculeCount > 0) &&  <li style={{ textDecoration: "none", color: "#BEBEBE",cursor:'not-allowed' }} onClick={(event) => event.preventDefault()} title="Type de véhicule en cours d'utilisation">
                                                                <span className="material-symbols-outlined">delete</span>
                                                                <p>Supprimer</p>
                                                            </li>}
                                                        </ul>
                                                </div>
                                            )}
                                        </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>}
                        <div className="pagination">
                            <Link to="#" onClick={() => handlePageChange(pageNumber - 1)}
                                className={(pageNumber === 1) ? 'pgn disabled' : 'pgn'}
                                disabled={(pageNumber === 1) ? true : false}
                            >&laquo;</Link>
                            {Array.from({ length: totalPages }, (_, i) => (
                                <Link
                                    key={i + 1}
                                    to="#"
                                    className={i + 1 === pageNumber ? 'pgn active' : 'pgn'}
                                    onClick={() => handlePageChange(i + 1)}
                                >
                                    {i + 1}
                                </Link>
                            ))}
                            <Link to="#" onClick={() => handlePageChange(pageNumber + 1)}
                                className={((pageNumber + 1) > totalPages) ? 'pgn disabled' : 'pgn'}
                                disabled={((pageNumber + 1) > totalPages) ? true : false}
                            >&raquo;</Link>
                        </div>


                    {showAddModal && <div className="popcard-add">
                        <div className="popcard-add_inside">
                            <Link className="popcard-add_inside-close" to="#" onClick={() => showHideAddModal(false)}>&#10006;</Link>
                            <h2 className="popcard-add_inside-title"></h2>
                            <div className="popcard-add_inside-form">
                                <form onSubmit={formik1.handleSubmit}>
                                    <div className="wrap-info">
                                        <div className="wrap-one">
                                            <label>Nom<span className='requiredField'>*</span></label>
                                            <input type="text"
                                                id="libelleVehiculeType"
                                                name="libelleVehiculeType"
                                                style={(formik1.touched.libelleVehiculeType && formik1.errors.libelleVehiculeType) ? { borderColor: "red" } : null}
                                                placeholder=""
                                                defaultValue={formik1.values.libelleVehiculeType}
                                                onChange={formik1.handleChange}
                                            />
                                            {formik1.touched.libelleVehiculeType && formik1.errors.libelleVehiculeType ? (
                                                <div className="invalid-feedback">{formik1.errors.libelleVehiculeType}</div>
                                            ) : null}
                                        </div>
                                        <div className="wrap-one">
                                            <label >Statut<span className='requiredField'>*</span></label>
                                            <select
                                                id="statutVehiculeType"
                                                name="statutVehiculeType"
                                                value={formik1.values.statutVehiculeType}
                                                onChange={formik1.handleChange}
                                            >
                                                <option value="actif" defaultValue={true}>Actif</option>
                                                <option value="inactif">Inactif</option>
                                            </select>
                                        </div>
                                    </div>

                                    <div className="popcard-operation-pickup-details_inside-zone">
                                        <div className="buttons">
                                            <input type="submit" value="Enregistrer" className="submit-btn" />
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>}

                    {showUpdateModal && <div className="popcard-add">
                        <div className="popcard-add_inside">
                            <Link className="popcard-add_inside-close" to="#" onClick={() => showHideUpdateModal(false, '', '')}>&#10006;</Link>
                            <h2 className="popcard-add_inside-title"></h2>
                            <div className="popcard-add_inside-form">
                                <form onSubmit={formik2.handleSubmit}>
                                    <div className="wrap-info">
                                        <div className="wrap-one">
                                            <label>Nom<span className='requiredField'>*</span></label>
                                            <input type="text"
                                                id="libelleVehiculeTypeUpdate"
                                                name="libelleVehiculeTypeUpdate"
                                                style={(formik2.touched.libelleVehiculeTypeUpdate && formik2.errors.libelleVehiculeTypeUpdate) ? { borderColor: "red" } : null}
                                                placeholder=""
                                                defaultValue={formik2.values.libelleVehiculeTypeUpdate}
                                                onChange={formik2.handleChange}
                                            />
                                            {formik2.touched.libelleVehiculeTypeUpdate && formik2.errors.libelleVehiculeTypeUpdate ? (
                                                <div className="invalid-feedback">{formik2.errors.libelleVehiculeTypeUpdate}</div>
                                            ) : null}
                                        </div>
                                        <div className="wrap-one">
                                            <label >Statut<span className='requiredField'>*</span></label>
                                            <select
                                                id="statutVehiculeTypeUpdate"
                                                name="statutVehiculeTypeUpdate"
                                                value={formik2.values.statutVehiculeTypeUpdate}
                                                onChange={formik2.handleChange}
                                            >
                                                <option value="actif" defaultValue={true}>Actif</option>
                                                <option value="inactif">Inactif</option>
                                            </select>
                                        </div>
                                    </div>

                                    <div className="popcard-operation-pickup-details_inside-zone">
                                        <div className="buttons">
                                            <input type="submit" value="Enregistrer" className="submit-btn" />
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>}

                    <ConfirmationDialog
                        open={confirmDialogOpen}
                        onClose={handleCancelDelete}
                        onConfirm={handleDelete}
                        titleMessage="Confirmer la suppression du type de véhicule"
                        bodyMessage="Voulez-vous vraiment supprimer ce type de véhicule ? Toutes les données seront définitivement perdues."
                        btnCancelText="Annuler"
                        btnConfirmText="Supprimer le type de véhicule"
                    />

                </div>
            </div>
        </section>
    );
}

export default ListTypeVehicules;
