import React, { useEffect, useState, useContext, useRef } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import moment from 'moment/moment';
import { CustomToast } from '../../components/Toast/CustomToast';
import { axiosInstance, Vehicule, VehiculeControleTechnique } from '../../services/api';
import { AuthContext } from '../../contexts/AuthContext';

const ModifierControle = () => {
    const { authData } = useContext(AuthContext);
    const navigate = useNavigate();
    const { id } = useParams();//idVehiculeControleTechnique

    const [isLoaded, setIsLoaded] = useState();

    const [pathImageControle, setPathImageControle] = useState(null);
    const [pathImageControleName, setPathImageControleName] = useState('');
    const pathImageControleRef = useRef(null);

    const handlePathImageControleChange = (e) => {
        setPathImageControle(e.target.files[0]);
        setPathImageControleName('');
    };

    const getImageFileName = (file) => {
        return file ? file.name : 'No file selected';
    };

    //Load data     

    const getDossier = async (idVehiculeControleTechnique) => {
        try {
            const response = await axiosInstance.get(`${VehiculeControleTechnique}/${idVehiculeControleTechnique}`);
            const resp = await response.data;
            setIsLoaded(resp);

            setValues(prevValues => ({
                ...prevValues,
                idVehicule: resp.idVehicule,
                natureControle: resp.natureControle || '',
                numControle: resp.numControle || '',
                resultatsControle: resp.resultatsControle || '',
                dateControle: moment(resp.dateControle).isValid() ? moment(resp.dateControle).format('YYYY-MM-DD') : '' || '',
                dateProchainControle: moment(resp.dateProchainControle).isValid() ? moment(resp.dateProchainControle).format('YYYY-MM-DD') : '' || '',
                uniteIntervalleTemps: resp.uniteIntervalleTemps || 'jour',
                echeanceTemps: resp.echeanceTemps || '',
                vehicule: ''
            }));

            setPathImageControleName(resp.pathImageControle || '');
        }
        catch (error) {
            // Handle error
            console.log(error);
        }
    }

    const { handleChange, values, handleSubmit, touched, errors, setFieldValue, setValues } = useFormik({
        initialValues: {
            vehicule: '',
            idVehicule: '',
            natureControle: '',
            numControle: '',
            resultatsControle: '',
            dateControle: '',
            dateProchainControle: '',
            uniteIntervalleTemps: 'jour',
            echeanceTemps: ''
        },
        validationSchema: Yup.object({
            dateControle: Yup.string()
                .required("Champ obligatoire"),
            dateProchainControle: Yup.string()
                .required("Champ obligatoire"),
            echeanceTemps: Yup.string()
                .required("Champ obligatoire"),
        }),
        onSubmit: async (values) => {
            try {
                //console.log(values);
                const formData = new FormData();

                formData.append('idVehiculeControleTechnique', id);
                formData.append('idVehicule', values.idVehicule);
                formData.append('natureControle', values.natureControle);
                formData.append('numControle', values.numControle);
                formData.append('resultatsControle', values.resultatsControle);
                formData.append('dateControle', values.dateControle);
                formData.append('dateProchainControle', values.dateProchainControle);
                formData.append('uniteIntervalleTemps', values.uniteIntervalleTemps);
                formData.append('echeanceTemps', values.echeanceTemps);
                formData.append('pathImageControle', pathImageControle);
                formData.append('pathImageControleName', pathImageControleName);
                formData.append('idUtilisateurAction', authData.idUser);

                const response = await axiosInstance.put(VehiculeControleTechnique, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });

                // //console.log(postData);               
                CustomToast("Le dossier a été modifié avec succès", 'success');
                navigate('/controletechniques', { replace: true });
            } catch (error) {
                // Handle error
                if (!error?.response) {
                    CustomToast("Aucune réponse du serveur", 'error');
                } else if (error.response?.status === 400 && error.response?.data === "Overlap existing controle") {
                    CustomToast("Overlap existing controle", 'error');
                } else if (error.response?.status === 400 && error.response?.data === "Controle not found") {
                    CustomToast("Controle not found", 'error');
                } else if (error.response?.status === 401) {
                    CustomToast("Non autorisé", 'error');
                } else {
                    CustomToast("Demande échoué", 'error');
                }
                console.log(error);
            }
        }
    });

    const fetchData = async () => {
        try {
            // Fetch vehicle data
            if (values.idVehicule !== '') {
                const vehicleResponse = await axiosInstance.get(`${Vehicule}/GetById/${values.idVehicule}`);
                const vehicleData = vehicleResponse.data;

                setValues((prevValues) => ({
                    ...prevValues,
                    vehicule: vehicleData.nom + ' ' + vehicleData.immatriculation || '',
                }));
            }
        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        getDossier(id);
        // const fetchData = async () => {
        //     try {
        //         // Fetch vehicle data
        //         if (values.idVehicule !== '') {
        //             const vehicleResponse = await axiosInstance.get(`${Vehicule}/GetById/${values.idVehicule}`);
        //             const vehicleData = vehicleResponse.data;

        //             setValues((prevValues) => ({
        //                 ...prevValues,
        //                 vehicule: vehicleData.nom + ' ' + vehicleData.immatriculation || '',
        //             }));
        //         }
        //     } catch (err) {
        //         console.log(err);
        //     }
        // };
        // fetchData();
        return () => {
            // Perform any necessary cleanup (e.g., cancel pending requests)
        };
    }, [id, values.idVehicule]);

    useEffect(() => {
        fetchData();
        return () => {
            // Perform any necessary cleanup (e.g., cancel pending requests)
        };
    }, [isLoaded]);

    return (
        <section className="vehicles-section">
            <div className="container">
                    <form onSubmit={handleSubmit} className="controletech-form">
                        <div className="">
                            <div className="grid grid--2-cols">
                                <div className="form-sec">
                                <div className="retour" id="retour-controletech">
                                    <Link to="/controletechniques" style={{ textDecoration: "none" }}>
                                        <div className="arrow-back" id="arrow-back-affectation-form">
                                            <span className="material-symbols-outlined" id="arrow">arrow_back</span>
                                            <h3 className="heading-primary"></h3>
                                        </div>
                                    </Link>
                                </div>
                                    {isLoaded && <div className="form-details">
                                        <div className="detail-form" id="identification-form">
                                            <div className="col">
                                                <div className="grp">
                                                    <div className="gp">
                                                        <label >Véhicule<span className='requiredField'>*</span></label>
                                                        <input
                                                            id="vehicule"
                                                            name="vehicule"
                                                            type='text'
                                                            defaultValue={values.vehicule}
                                                            disabled={true}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="grp">
                                                    <div className="gp">
                                                        <label >Nature du contrôle</label>
                                                        <input
                                                            id="natureControle"
                                                            name="natureControle"
                                                            type="text"
                                                            value={values.natureControle}
                                                            onChange={handleChange}
                                                        />
                                                    </div>
                                                    <div className="gp">
                                                        <label >N° du contrôle</label>
                                                        <input
                                                            id="numControle"
                                                            name="numControle"
                                                            type="text"
                                                            value={values.numControle}
                                                            onChange={handleChange}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="grp">
                                                    <div className="gp">
                                                        <label >Date du contrôle<span className='requiredField'>*</span></label>
                                                        <input
                                                            type="date"
                                                            id="dateControle"
                                                            name="dateControle"
                                                            style={(touched.dateControle && errors.dateControle) ? { borderColor: "red" } : null}
                                                            placeholder=""
                                                            defaultValue={values.dateControle}
                                                            onChange={handleChange}
                                                        />
                                                        {touched.dateControle && errors.dateControle ? (
                                                            <div className="invalid-feedback">{errors.dateControle}</div>
                                                        ) : null}
                                                    </div>
                                                    <div className="gp">
                                                        <label >Date de prochain contrôle<span className='requiredField'>*</span></label>
                                                        <input
                                                            type="date"
                                                            id="dateProchainControle"
                                                            name="dateProchainControle"
                                                            style={(touched.dateProchainControle && errors.dateProchainControle) ? { borderColor: "red" } : null}
                                                            placeholder=""
                                                            defaultValue={values.dateProchainControle}
                                                            onChange={handleChange}
                                                        />
                                                        {touched.dateProchainControle && errors.dateProchainControle ? (
                                                            <div className="invalid-feedback">{errors.dateProchainControle}</div>
                                                        ) : null}
                                                    </div>
                                                </div>
                                                <div className="grp">
                                                    <div className="gp" title="Le temps à l'avance pendant lequel vous considérez le rappel comme étant '' à échéance prochaine''">
                                                        <label >Seuil d'échéance prochaine<span className='requiredField'>*</span></label>
                                                        <input
                                                            type="number"
                                                            id="echeanceTemps"
                                                            name="echeanceTemps"
                                                            style={(touched.echeanceTemps && errors.echeanceTemps) ? { borderColor: "red" } : null}
                                                            placeholder=""
                                                            defaultValue={values.echeanceTemps}
                                                            onChange={handleChange}
                                                        />
                                                        {touched.echeanceTemps && errors.echeanceTemps ? (
                                                            <div className="invalid-feedback">{errors.echeanceTemps}</div>
                                                        ) : null}
                                                    </div>
                                                    <div className="gp">
                                                        <select
                                                            id="uniteIntervalleTemps"
                                                            name="uniteIntervalleTemps"
                                                            value={values.uniteIntervalleTemps}
                                                            style={(touched.uniteIntervalleTemps && errors.uniteIntervalleTemps) ? { borderColor: "red" } : null}
                                                            onChange={handleChange}
                                                        >
                                                            <option value="jour" defaultValue={true}>Jour(s)</option>
                                                            <option value="mois">Mois</option>
                                                        </select>
                                                        {touched.uniteIntervalleTemps && errors.uniteIntervalleTemps ? (
                                                            <div className="invalid-feedback">{errors.uniteIntervalleTemps}</div>
                                                        ) : null}
                                                    </div>
                                                </div>
                                                <div className="grp">
                                                    <div className="gp">
                                                        <label >Document</label>
                                                        <label className="upload-file">
                                                        upload file
                                                        <input
                                                            ref={pathImageControleRef}
                                                            id="pathImageControle"
                                                            name="pathImageControle"
                                                            type="file"
                                                            onChange={handlePathImageControleChange}
                                                        />
                                                    </label>
                                                        {pathImageControle && <span style={{ fontSize: "10px" }}>{getImageFileName(pathImageControle)}
                                                            <span className="material-symbols-outlined" onClick={(e) => { setPathImageControle(null); if (pathImageControleRef.current) { pathImageControleRef.current.value = ''; } }}>delete</span>
                                                        </span>}
                                                        {pathImageControleName && <span style={{ fontSize: "10px" }}>{pathImageControleName}
                                                            <span className="material-symbols-outlined" onClick={(e) => { setPathImageControle(null); setPathImageControleName(''); if (pathImageControleRef.current) { pathImageControleRef.current.value = ''; } }}>delete</span>
                                                        </span>}
                                                    </div>
                                                </div>
                                                <div className="grp">
                                                    <div className="gp">
                                                        <label >Résultats du contrôle</label>
                                                        <textarea
                                                            id="resultatsControle"
                                                            name="resultatsControle"
                                                            type="text"
                                                            value={values.resultatsControle}
                                                            onChange={handleChange}
                                                            rows={4}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>}
                                    {isLoaded && <div className="submit-container">
                                        <button type="submit" className="submit-btn">Enregistrer</button>
                                    </div>}
                                </div>
                            </div>
                        </div>
                    </form>
            </div>
        </section>
    );
}

export default ModifierControle;
