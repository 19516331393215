import React, { useEffect, useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment/moment';
import Select from "react-select";
import { CustomToast } from '../../components/Toast/CustomToast';
import useDebounce from '../../hooks/useDebounce';
import ConfirmationDialog from '../../components/Confirmation/ConfirmationDialog';
import { axiosInstance, VehiculeAffectation, Vehicule, Employee } from '../../services/api';
import { AuthContext } from '../../contexts/AuthContext';
const ListAffectations = () => {
    const { authData } = useContext(AuthContext);

    const [affectations, setAffectations] = useState([]);
    const [vehicules, setVehicules] = useState([]);
    const [drivers, setDrivers] = useState([]);
    const [filtredAffectations, setFiltredAffectations] = useState([]);
    const [pageNumber, setPageNumber] = useState(1);//Definit par user
    const [pageSize, setPageSize] = useState(10);//Definit par user
    const [totalItems, setTotalItems] = useState(0);//Calculé dans BackEnd: nombre totale d'éléments
    const [totalPages, setTotalPages] = useState(0);//Calculé dans BackEnd
    const [loading, setLoading] = useState(true);
    const [idAffectatonDelete, setIdAffectationDelete] = useState('');

    const [selectedVehicle, setSelectedVehicle] = useState('');
    const [selectedVehicleId, setSelectedVehicleId] = useState('');

    const [selectedConducteur, setSelectedConducteur] = useState('');
    const [selectedIdConducteur, setSelectedIdConducteur] = useState('');

    const [confirmDialogOpenDelete, setConfirmDialogOpenDelete] = useState(false);

    const [filters, setFilters] = useState({
        idVehicule: '',
        vehicule: '',
        idConducteur: '',
        conducteur: '',
        statut: '',
        dateDebutAffectation: moment("00", "hh").add(-4, 'days').format('YYYY-MM-DD'),
        dateFinAffectation: moment("00", "hh").add(3, 'days').format('YYYY-MM-DD')
    });

    const [search, setSearch] = useState("");

    const [enteredVehicleText, setEnteredVehicleText] = useState('');
    const [enteredDriverText, setEntredDriverText] = useState('');
    const debouncedVehiculeText = useDebounce(enteredVehicleText, 300);
    const debouncedDriverText = useDebounce(enteredDriverText, 300);

    //table management
    const handlePageChange = (page) => {
        setPageNumber(page);
        //consolelog("setPageNumber="+pageNumber)
    };

    const handlePerRowsChange = (newPerPage) => {
        setPageSize(newPerPage);
        //console.log("setPageSize="+pageSize);
    };

    const [sortBy, setSortBy] = useState(''); // Column name to sort by
    const [sortOrder, setSortOrder] = useState('asc'); // 'asc' or 'desc'

    const handleSort = (column) => {
        setSortBy(column);
        setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');

        let sortedTable = [...filtredAffectations].sort((a, b) => {
            const aValue = a[sortBy] || '';
            const bValue = b[sortBy] || '';

            if (sortOrder === 'asc') {
                return aValue.localeCompare(bValue);
            } else {
                return bValue.localeCompare(aValue);
            }
        });

        setFiltredAffectations(sortedTable);
    };

    const [openAboutList, setOpenAboutList] = useState(null);

    const toggleAboutList = (idVehicule) => {
        setOpenAboutList(openAboutList === idVehicule ? null : idVehicule);
    };

    const handleVehicleChange = (selectedOption) => {
        setSelectedVehicle(selectedOption);
        setSelectedVehicleId(selectedOption ? selectedOption.idVehicule : '');
        filters.idVehicule = selectedVehicleId;
        filters.vehicule = selectedOption;

        const updatedFilters = { ...filters, idVehicule: selectedOption ? selectedOption.idVehicule : '' };
        setFilters(updatedFilters);
    };

    const handleConducteurChange = (selectedOption) => {
        setSelectedConducteur(selectedOption);
        setSelectedIdConducteur(selectedOption ? selectedOption.idEmploye : '');
        filters.idConducteur = selectedIdConducteur;
        filters.conducteur = selectedOption;

        const updatedFilters = { ...filters, idConducteur: selectedOption ? selectedOption.idEmploye : '' };
        setFilters(updatedFilters);
    };

    //Supprimer affectation
    const handleConfirmDelete = (idVehiculeAffectation) => {
        // Show the modal when the confirm delete button is clicked
        //deleteAffectation(idVehiculeAffectation);
        setIdAffectationDelete(idVehiculeAffectation);
        setConfirmDialogOpenDelete(true);
    };

    const handleDelete = async () => {
        try {
            const request = {
                idVehiculeAffectation: idAffectatonDelete,
                idUtilisateurAction: authData.idUser
            }
            await axiosInstance.delete(`${VehiculeAffectation}`, {
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json'
                },
                data: JSON.stringify(request)
            });
            CustomToast("L'affectation du véhicule a été supprimée avec succès", 'success');
            setIdAffectationDelete('');
            setConfirmDialogOpenDelete(false);
            getAffectations(pageNumber, pageSize);
        }
        catch (error) {
            // Handle error
            if (!error?.response) {
                CustomToast("Aucune réponse du serveur", 'error');
            } else if (error.response?.status === 400 && error.response?.data === "Assignment not found") {
                CustomToast("Affectation introuvable", 'error');
            } else if (error.response?.status === 401) {
                CustomToast("Non autorisé", 'error');
            } else {
                CustomToast("Demande échoué", 'error');
            }
            console.log(error);
        }
    }

    const handleCancelDelete = () => {
        setConfirmDialogOpenDelete(false);
    };

    //Load data
    const getVehicules = async (nomVehicule) => {
        try {
            //console.log("getVehicules")
            const queryParams = new URLSearchParams({
                pageNumber: 1,
                pageSize: 10
            });

            if (authData?.wilayas != null) {
                const wilayasFilter = authData?.wilayas
                queryParams.append("wilayas", wilayasFilter.join(','));
            }
            else {
                return
            }

            if (authData?.flottesAutorises != null) {
                const flottesAutorisesFilter = authData?.flottesAutorises
                queryParams.append("flottesAutorises", flottesAutorisesFilter.join(','));
            }
            else {
                return
            }

            if (nomVehicule !== '') {
                queryParams.append("nom", nomVehicule);
            }
            const response = await axiosInstance.get(`${Vehicule}/GetVehiculesAutocomplete?${queryParams}`);
            const resp = await response.data;
            setVehicules(resp.data);
        }
        catch (err) {
            console.log(err);
        }
    }

    const getDrivers = async (nomConducteur) => {
        try {
            const queryParams = new URLSearchParams({
                pageNumber: 1,
                pageSize: 10,
                estChauffeur: true
            });

            if (nomConducteur !== '') {
                queryParams.append("nom", nomConducteur);
            }
            const response = await axiosInstance.get(`${Employee}/GetEmployeAutocomplete?${queryParams}`);
            const resp = await response.data;
            setDrivers(resp.data);
        }
        catch (err) {
            console.log(err);
        }
    }

    const getAffectations = async (page, newPerPage) => {
        try {
            setLoading(true);

            const queryParams = new URLSearchParams({
                pageNumber: page,
                pageSize: newPerPage
            });

            if (authData?.wilayas != null) {
                const wilayasFilter = authData?.wilayas
                queryParams.append("wilayas", wilayasFilter.join(','));
            }
            else {
                setLoading(false);
                return
            }

            if (authData?.flottesAutorises != null) {
                const flottesAutorisesFilter = authData?.flottesAutorises
                queryParams.append("flottesAutorises", flottesAutorisesFilter.join(','));
            }
            else {
                setLoading(false);
                return
            }

            if (filters.idVehicule !== '') {
                queryParams.append("idVehicule", filters.vehicule.idVehicule);
            }

            if (filters.idConducteur !== '') {
                queryParams.append("idConducteur", filters.conducteur.idEmploye);
            }

            if (filters.statut !== '') {
                queryParams.append("statut", filters.statut);
            }

            if (filters.dateDebutAffectation !== '') {
                queryParams.append("dateDebutAffectation", filters.dateDebutAffectation);
            }

            if (filters.dateFinAffectation !== '') {
                queryParams.append("dateFinAffectation", filters.dateFinAffectation);
            }

            const response = await axiosInstance.get(`${VehiculeAffectation}/GetVehiculeAffectation?${queryParams}`);
            const resp = await response.data;
            setAffectations(resp.data);
            setFiltredAffectations(resp.data);
            setPageNumber(resp.pageNumber);
            setPageSize(resp.pageSize);
            setTotalItems(resp.totalItems);
            setTotalPages(resp.totalPages);
            setLoading(false);
        }
        catch (err) {
            console.log(err);
            setLoading(false);
        }
    }

    //Search
    const handleSearch = (e) => {
        e.preventDefault();
        setPageNumber(1); // Reset page number when new search is triggered
        getAffectations(1, pageSize); // Fetch inspections with updated filters
    };

    useEffect(() => {
        //if (debouncedVehiculeText) {
        getVehicules(debouncedVehiculeText);
        //}
        return () => {
            // Perform any necessary cleanup (e.g., cancel pending requests)
        };
    }, [authData, debouncedVehiculeText]);

    useEffect(() => {
        //if (debouncedDriverText) {
        getDrivers(debouncedDriverText);
        //}
        return () => {
            // Perform any necessary cleanup (e.g., cancel pending requests)
        };
    }, [debouncedDriverText]);

    useEffect(() => {
        //Load Data

        getAffectations(pageNumber, pageSize);
        return () => {
            // Perform any necessary cleanup (e.g., cancel pending requests)
        };
    }, [authData, pageNumber, pageSize]);


    return (
        <section className="vehicles-section" onClick={() => { if (openAboutList !== null) { setOpenAboutList(null) } }}>
            <div className="container">
                <div className="affectation-sec wrap-all">
                    <Link to="/ajouteraffectation" className="affectation-add color-primary-2 decoration-none">
                        <div className="add-new" id="add-vehc">
                            <span className="material-symbols-outlined">add</span>
                            Ajouter une affectation
                        </div>
                    </Link>

                    <form onSubmit={handleSearch}>
                        <div className="filter-wrap">

                            <div className="date-container">
                                <input
                                    type="date"
                                    value={filters.dateDebutAffectation}
                                    onChange={(e) => setFilters({ ...filters, dateDebutAffectation: e.target.value })}
                                    className='date-filter'
                                />
                            </div>

                            <div className="date-container">
                                <input
                                    type="date"
                                    value={filters.dateFinAffectation}
                                    onChange={(e) => setFilters({ ...filters, dateFinAffectation: e.target.value })}
                                    className='date-filter'
                                />
                            </div>

                            <Select
                                id="vehicule"
                                name="vehicule"
                                value={filters.vehicule}
                                onChange={(selectedOption) => {
                                    handleVehicleChange(selectedOption);
                                }}
                                onInputChange={(inputValue) => {
                                    // Update the entered text
                                    setEnteredVehicleText(inputValue);
                                }}
                                options={vehicules}
                                getOptionLabel={(option) => option.marque + '[' + option.modele + '] ' + option.immatriculation}
                                getOptionValue={(option) => option.idVehicule}
                                isClearable
                                placeholder="Sélectionnez véhicule"
                                styles={{
                                    control: (baseStyles, state) => ({
                                        ...baseStyles,
                                        borderRadius: '1rem',
                                        borderWidth: '1px',
                                        height: '3rem',
                                        borderColor: state.isFocused ? 'rgba(115, 128, 236, 0.9)' : '#BEBEBE',
                                        backgroundColor: '#65458d',
                                        cursor: 'pointer',
                                        color: '#fff',
                                    }),
                                    option: (provided, state) => ({
                                        ...provided,
                                        backgroundColor: state.isFocused ? '#2581DC' : '#65458d',
                                        color: '#fff',
                                        cursor: 'pointer',
                                    }),
                                    singleValue: (provided, state) => ({
                                        ...provided,
                                        color: '#fff',
                                    }),
                                    placeholder: (provided, state) => ({
                                        ...provided,
                                        color: '#fff'
                                    }),
                                    input: (provided, state) => ({
                                        ...provided,
                                        color: '#fff',
                                    }),
                                }}
                            />

                            <Select
                                id="conducteur"
                                name="conducteur"
                                value={filters.conducteur}
                                onChange={(selectedOption) => {
                                    handleConducteurChange(selectedOption);
                                }}
                                onInputChange={(inputValue) => {
                                    // Update the entered text
                                    setEntredDriverText(inputValue);
                                }}
                                options={drivers}
                                getOptionLabel={(option) => option.nom + ' ' + option.prenom}
                                getOptionValue={(option) => option.idEmploye}
                                isClearable
                                placeholder="Sélectionnez chauffeur"
                                styles={{
                                    control: (baseStyles, state) => ({
                                        ...baseStyles,
                                        borderRadius: '1rem',
                                        borderWidth: '1px',
                                        height: '3rem',
                                        borderColor: state.isFocused ? 'rgba(115, 128, 236, 0.9)' : '#BEBEBE',
                                        backgroundColor: '#65458d',
                                        cursor: 'pointer',
                                        color: '#fff',
                                    }),
                                    option: (provided, state) => ({
                                        ...provided,
                                        backgroundColor: state.isFocused ? '#2581DC' : '#65458d',
                                        color: '#fff',
                                        cursor: 'pointer',
                                    }),
                                    singleValue: (provided, state) => ({
                                        ...provided,
                                        color: '#fff',
                                    }),
                                    placeholder: (provided, state) => ({
                                        ...provided,
                                        color: '#fff'
                                    }),
                                    input: (provided, state) => ({
                                        ...provided,
                                        color: '#fff',
                                    }),
                                }}
                            />

                            <select
                                name="statut"
                                id="statut"
                                className="select-filter"
                                value={filters.statut || ''}
                                onChange={(e) => setFilters({ ...filters, statut: e.target.value || '' })}
                            >
                                <option value="">Sélectionnez statut</option>
                                <option value="planifié">Planifié</option>
                                <option value="en cours">En cours</option>
                                <option value="terminé">Terminé</option>
                            </select>

                            <div className="filter">
                                {/* <a href="#"><span className="material-symbols-outlined">filter_alt</span></a> */}
                                <button type="submit" className="linkButton">
                                    <span className="material-symbols-outlined">filter_alt</span>
                                </button>
                            </div>
                        </div>
                    </form>

                    {filtredAffectations?.length > 0 && <><div className="group-elements">
                        <div className="voir-elements">
                            <p></p>
                            <select
                                name="pageSize"
                                id="pageSize"
                                onChange={(e) => { handlePerRowsChange(e.target.value || 10); setPageNumber(1); }}
                            >
                                <option value="10" defaultValue={true}>10</option>
                                <option value="15">15</option>
                                <option value="20">20</option>
                            </select>
                        </div>
                        {/* <div className="search-element">
                            <p>Rechercher</p>
                            <input type="text" />
                        </div> */}
                    </div>
                        <div className="table-wrap">
                            <table>
                                <thead>
                                    <tr>
                                        <th onClick={() => handleSort('vehiculeNom')}>Véhicule</th>
                                        <th>Chauffeur</th>
                                        <th>Date début</th>
                                        <th>Date fin</th>
                                        <th>Statut</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {filtredAffectations?.map((vehicule) => (
                                        <tr key={vehicule.idVehiculeAffectation}>
                                            <td>{vehicule.vehiculeNom + ' ' + vehicule.vehiculeImmatriculation}</td>
                                            <td>{vehicule.nom + " " + vehicule.prenom}</td>

                                            <td>{vehicule.dateDebutAffectation ? (moment(vehicule.dateDebutAffectation).format('DD-MM-YYYY HH:mm')) : ''}</td>
                                            <td>{vehicule.dateFinAffectation ? (moment(vehicule.dateFinAffectation).format('DD-MM-YYYY HH:mm')) : ''}</td>
                                            <td>{
                                                vehicule.statutAffectation === "planifié" ?
                                                    <><svg height="11.87" width="15"><circle cx="6" cy="6" r="6" fill="blue" /></svg>Planifié</>
                                                    : vehicule.statutAffectation === "en cours" ?
                                                        <><svg height="11.87" width="15"><circle cx="6" cy="6" r="6" fill="green" /></svg>En cours</>
                                                        : vehicule.statutAffectation === "terminé" ?
                                                            <><svg height="11.87" width="15"><circle cx="6" cy="6" r="6" fill="red" /></svg>Terminé</>
                                                            : null
                                            }</td>
                                            <td className="txt-center relative">
                                                <span className="material-symbols-outlined about-btn" onClick={() => toggleAboutList(vehicule.idVehiculeAffectation)}>
                                                    more_horiz
                                                </span>
                                                {openAboutList === vehicule.idVehiculeAffectation && (
                                                    <div className="about-list">
                                                        <ul>
                                                            <li>
                                                                <Link to={`/modifieraffectation/${vehicule.idVehiculeAffectation}`} style={{ textDecoration: "none" }} className='row row-center'>
                                                                    <span className="material-symbols-outlined">edit</span>Modifier
                                                                </Link>
                                                            </li>
                                                            <li>
                                                                <Link to="#" style={{ textDecoration: "none" }} onClick={() => handleConfirmDelete(vehicule.idVehiculeAffectation)} className='row row-center'>
                                                                    <span className="material-symbols-outlined">delete</span>Supprimer
                                                                </Link>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                )}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        {/* <div className="pagination">
                            <Link to="#" onClick={() => handlePageChange(pageNumber - 1)}
                                className={(pageNumber === 1) ? 'pgn disabled' : 'pgn'}
                                disabled={(pageNumber === 1) ? true : false}
                            >&laquo;</Link>
                            {Array.from({ length: totalPages }, (_, i) => (
                                <Link
                                    key={i + 1}
                                    to="#"
                                    className={i + 1 === pageNumber ? 'pgn active' : 'pgn'}
                                    onClick={() => handlePageChange(i + 1)}
                                >
                                    {i + 1}
                                </Link>
                            ))}
                            <Link to="#" onClick={() => handlePageChange(pageNumber + 1)}
                                className={((pageNumber + 1) > totalPages) ? 'pgn disabled' : 'pgn'}
                                disabled={((pageNumber + 1) > totalPages) ? true : false}
                            >&raquo;</Link>
                        </div> */}
                        <div className="pagination">
                        <Link to="#" onClick={() => handlePageChange(pageNumber - 1)}
                            className={(pageNumber === 1) ? 'pgn disabled' : 'pgn'}
                            disabled={(pageNumber === 1) ? true : false}
                        >&laquo;</Link>
                        {Array.from({ length: Math.min(5, totalPages) }, (_, i) => {
                            const page = pageNumber - 2 + i;
                            return page <= totalPages && page > 0 && (
                                <Link
                                    key={page}
                                    to="#"
                                    className={pageNumber === page ? 'pgn active' : 'pgn'}
                                    onClick={() => handlePageChange(page)}
                                >
                                    {page}
                                </Link>
                            );
                        })}
                        <Link to="#" onClick={() => handlePageChange(pageNumber + 1)}
                            className={(pageNumber === totalPages || totalPages === 0) ? 'pgn disabled' : 'pgn'}
                            disabled={(pageNumber === totalPages || totalPages === 0) ? true : false}
                        >&raquo;</Link>
                        <span> Affichage de {(pageNumber === 1)?1:((pageNumber - 1) * pageSize+1)} à {pageNumber * pageSize} sur {totalItems} entrées</span>
                    </div>
                        </>}
                    <ConfirmationDialog
                        open={confirmDialogOpenDelete}
                        onClose={handleCancelDelete}
                        onConfirm={handleDelete}
                        titleMessage="Confirmer la suppression de l'affectation du véhicule"
                        bodyMessage={"Etes-vous sûr de vouloir supprimer cette affectation de véhicule ? Toutes les données seront définitivement perdues."}
                        btnCancelText="Annuler"
                        btnConfirmText="Supprimer l'affectation du véhicule"
                    />
                </div>
            </div>
        </section>
    );
}

export default ListAffectations;
