import React, { useEffect, useState, useContext } from 'react';
//import './Tab.css'
import { Link } from 'react-router-dom';
import moment from 'moment/moment';
import { CustomToast } from '../../components/Toast/CustomToast';
import { ExportToExcel } from '../../components/ExportToExcel/ExportToExcel';
import ConfirmationDialog from '../../components/Confirmation/ConfirmationDialog';
import { axiosInstance, Vehicule, Groupe, Proprietaire } from '../../services/api';
import { AuthContext } from '../../contexts/AuthContext';
const ListVehicules = () => {
    const { authData } = useContext(AuthContext);

    const [vehicules, setVehicules] = useState([]);
    const [groupes, setGroupes] = useState([]);
    const [proprietaires, setProprietaires] = useState([]);
    const [filtredVehicules, setFiltredVehicules] = useState([]);
    const [pageNumber, setPageNumber] = useState(1);//Definit par user
    const [pageSize, setPageSize] = useState(10);//Definit par user
    const [totalItems, setTotalItems] = useState(0);//Calculé dans BackEnd: nombre totale d'éléments
    const [totalPages, setTotalPages] = useState(0);//Calculé dans BackEnd
    const [loading, setLoading] = useState(true);
    const [idVehiculeDelete, setIdVehiculeDelete] = useState('');
    const [confirmDialogOpenDelete, setConfirmDialogOpenDelete] = useState(false);
    const [confirmDialogOpenArchive, setConfirmDialogOpenArchive] = useState(false);
    const [filters, setFilters] = useState({
        nomMatricule: '',
        statut: '',
        idConducteur: '',
        idGroupe: '',
        idEntreprise: ''
    });

    //const [search, setSearch] = useState("");

    //table management
    const handlePageChange = (page) => {
        setPageNumber(page);
        //consolelog("setPageNumber="+pageNumber)
    };

    const handlePerRowsChange = (newPerPage) => {
        setPageSize(newPerPage);
        //console.log("setPageSize="+pageSize);
    };

    const [sortBy, setSortBy] = useState(''); // Column name to sort by
    const [sortOrder, setSortOrder] = useState('asc'); // 'asc' or 'desc'

    const handleSort = (column) => {
        setSortBy(column);
        setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');

        let sortedTable = [...filtredVehicules].sort((a, b) => {
            const aValue = a[sortBy] || '';
            const bValue = b[sortBy] || '';

            if (sortOrder === 'asc') {
                return aValue.localeCompare(bValue);
            } else {
                return bValue.localeCompare(aValue);
            }
        });

        setFiltredVehicules(sortedTable);
    };

    const [openAboutList, setOpenAboutList] = useState(null);

    const toggleAboutList = (idVehicule) => {
        setOpenAboutList(openAboutList === idVehicule ? null : idVehicule);
    };

    const handleConfirmDelete = (idVehicule) => {
        // Show the modal when the confirm delete button is clicked
        //deleteVehicule(idVehicule);
        setIdVehiculeDelete(idVehicule);
        setConfirmDialogOpenDelete(true);
    };

    const handleDelete = async () => {
        try {
            const response = await axiosInstance.delete(`${Vehicule}/${idVehiculeDelete}`);
            setIdVehiculeDelete('');
            CustomToast('Le véhicule a été supprimé avec succès', 'success');
            getVehicules(pageNumber, pageSize);
            setConfirmDialogOpenDelete(false);
        }
        catch (error) {
            // Handle error
            if (!error?.response) {
                CustomToast("Aucune réponse du serveur", 'error');
            } else if (error.response?.status === 400 && error.response?.data === "Vehicle already used") {
                CustomToast("Véhicule déjà utilisé", 'error');
            } else if (error.response?.status === 400 && error.response?.data === "Vehicle not found") {
                CustomToast("Véhicule introuvable", 'error');
            } else if (error.response?.status === 401) {
                CustomToast("Non autorisé", 'error');
            } else if (error.response?.status === 404) {
                CustomToast("Demande incorrecte", 'error');
            } else {
                CustomToast("Demande échoué", 'error');
            }
            console.log(error);
        }
    }

    const handleCancelDelete = () => {
        setConfirmDialogOpenDelete(false);
    };

    //archiver véhicule
    const handleConfirmArchive = (idVehicule) => {
        // Show the modal when the confirm delete button is clicked
        //archiveVehicule(idVehicule);
        setIdVehiculeDelete(idVehicule);
        setConfirmDialogOpenArchive(true);
    };

    const handleArchive = async () => {
        try {
            const request = {
                idVehicule: idVehiculeDelete,
                idUtilisateur: authData.idUser
            }
            await axiosInstance.put(`${Vehicule}/archive`, request);
            setIdVehiculeDelete('');
            CustomToast('Le véhicule a été archivé avec succès', 'success');
            getVehicules(pageNumber, pageSize);
            setConfirmDialogOpenArchive(false);
        }
        catch (error) {
            // Handle error
            if (!error?.response) {
                CustomToast("Aucune réponse du serveur", 'error');
            } else if (error.response?.status === 400 && error.response?.data === "Vehicle does not exist") {
                CustomToast("Le véhicule n'existe pas", 'error');
            } else if (error.response?.status === 401) {
                CustomToast("Non autorisé", 'error');
            } else {
                CustomToast("Demande échoué", 'error');
            }
            console.log(error);
        }
    }

    const handleCancelArchive = () => {
        setConfirmDialogOpenArchive(false);
    };

    //restaurer véhicule
    const restoreVehicule = async (idVehicule) => {
        try {

            const request = {
                idVehicule: idVehicule,
                idUtilisateur: authData.idUser
            }
            await axiosInstance.put(`${Vehicule}/restore`, request);
            getVehicules(pageNumber, pageSize);
            CustomToast('Le véhicule a été restauré avec succès', 'success');
        }
        catch (err) {
            console.log(err);
        }
    }

    const handleConfirmRestore = (idVehicule) => {
        // Show the modal when the confirm delete button is clicked
        restoreVehicule(idVehicule);
        setIdVehiculeDelete(idVehicule);
    };

    // const handleRestore = async () => {
    //     try {
    //         const request = {
    //             idVehicule: idVehiculeDelete,
    //             idUtilisateur: authData.idUser
    //         }
    //         await axiosInstance.put(`${Vehicule}/restore`, request);
    //         setIdVehiculeDelete('');
    //         CustomToast('Le véhicule a été restauré avec succès', 'success');
    //         getVehicules(pageNumber, pageSize);
    //     }
    //     catch (error) {
    //         // Handle error
    //         if (!error?.response) {
    //             CustomToast("Aucune réponse du serveur", 'error');
    //         } else if (error.response?.status === 400 && error.response?.data === "Vehicle does not exist") {
    //             CustomToast("Le véhicule n'existe pas", 'error');
    //         } else if (error.response?.status === 401) {
    //             CustomToast("Non autorisé", 'error');
    //         } else {
    //             CustomToast("Demande échoué", 'error');
    //         }
    //         console.log(error);
    //     }
    // }

    //Load data
    const getGroupes = async () => {
        try {

            const response = await axiosInstance.get(`${Groupe}`);
            const resp = await response.data;
            setGroupes(resp);
        }
        catch (err) {
            console.log(err);
        }
    }

    const getProprietaires = async () => {
        try {
            const queryParams = new URLSearchParams({
                typeProprietaire: "entreprise,location,freelancer"
            });
            if (authData?.flottesAutorises != null) {
                const flottesAutorisesFilter = authData?.flottesAutorises
                queryParams.append("idProprietaire", flottesAutorisesFilter.join(','));
            }
            else {
                return
            }
            const response = await axiosInstance.get(`${Proprietaire}/GetProprietaireByType?${queryParams}`);
            const resp = await response.data;
            setProprietaires(resp);
        }
        catch (err) {
            console.log(err);
        }
    }

    const getVehicules = async (page, newPerPage) => {
        try {

            setLoading(true);

            const queryParams = new URLSearchParams({
                pageNumber: page,
                pageSize: newPerPage
            });

            // const wilayasFilter = authData?.wilayas || ''; // Provide a default value of an empty string
            // queryParams.append("wilayas", wilayasFilter.join(','));

            if (authData?.wilayas != null) {
                const wilayasFilter = authData?.wilayas
                queryParams.append("wilayas", wilayasFilter.join(','));
            }
            else {
                return
            }

            if (authData?.flottesAutorises != null) {
                const flottesAutorisesFilter = authData?.flottesAutorises
                queryParams.append("flottesAutorises", flottesAutorisesFilter.join(','));
            }
            else {
                return
            }

            if (filters.nomMatricule !== '') {
                queryParams.append("nomMatricule", filters.nomMatricule);
            }

            // if (filters.immatriculation !== '') {
            //     queryParams.append("immatriculation", filters.immatriculation);
            // }

            // if (filters.numChassis !== '') {
            //     queryParams.append("numChassis", filters.numChassis);
            // }

            if (filters.statut !== '') {
                queryParams.append("statut", filters.statut);
            }

            if (filters.idConducteur !== '') {
                queryParams.append("idConducteur", filters.idConducteur);
            }

            if (filters.idGroupe !== '') {
                queryParams.append("idGroupe", filters.idGroupe);
            }

            if (filters.idEntreprise !== '') {
                queryParams.append("idEntreprise", filters.idEntreprise);
            }

            const response = await axiosInstance.get(`${Vehicule}/GetListeVehicules?${queryParams}`);
            const resp = await response.data;
            setVehicules(resp.data);
            setFiltredVehicules(resp.data);
            setPageNumber(resp.pageNumber);
            setPageSize(resp.pageSize);
            setTotalItems(resp.totalItems);
            setTotalPages(resp.totalPages);
            setLoading(false);
        }
        catch (err) {
            setLoading(false);
            console.log(err);
        }
    }

    //Search
    const handleSearch = (e) => {
        e.preventDefault();
        setPageNumber(1); // Reset page number when new search is triggered
        getVehicules(1, pageSize); // Fetch inspections with updated filters
    };

    //Excel
    const exportExcel = async () => {
        try {
            const queryParams = new URLSearchParams({});

            if (authData?.wilayas != null) {
                const wilayasFilter = authData?.wilayas
                queryParams.append("wilayas", wilayasFilter.join(','));
            }
            else {
                return
            }

            if (authData?.flottesAutorises != null) {
                const flottesAutorisesFilter = authData?.flottesAutorises
                queryParams.append("flottesAutorises", flottesAutorisesFilter.join(','));
            }
            else {
                return
            }

            if (filters.nomMatricule !== '') {
                queryParams.append("nomMatricule", filters.nomMatricule);
            }

            if (filters.statut !== '') {
                queryParams.append("statut", filters.statut);
            }

            if (filters.idGroupe !== '') {
                queryParams.append("idGroupe", filters.idGroupe);
            }

            if (filters.idEntreprise !== '') {
                queryParams.append("idEntreprise", filters.idEntreprise);
            }

            const response = await axiosInstance.get(`${Vehicule}/ExportVehicules?${queryParams}`);
            const resp = await response.data;

            const Heading = [
                [
                    "Nom",
                    "Marque",
                    "Modèle",
                    "Immatriculation",
                    "N° châssis",
                    "Statut",
                    "Type",
                    "Propriètaire",
                    "Placement",
                    "Service",
                    "Compteur"
                ]
            ];

            //ExportToExcel(resp, "Véhicules-" + moment().format("YYYYMMDDHHmmss"), "Véhicules", Heading);
            let fileName = "Véhicules-" + moment().format("YYYYMMDDHHmmss");
            const fileExtension = ".xlsx";
            const blob = ExportToExcel(resp, fileName, fileExtension, "Véhicules", Heading);

            // Create a URL for the Blob
            const url = window.URL.createObjectURL(blob);

            CustomToast(<><strong>Terminé : Exportation xlsx de liste de véhicules</strong><br></br><a href={url} download={fileName + fileExtension}>Cliquez ici si le téléchargement ne démarre pas</a></>, 'success', 60000);

            // Clean up the URL object after the download
            //window.URL.revokeObjectURL(url);         

        }
        catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        //Load Data
        getGroupes();
        return () => {
            // Perform any necessary cleanup (e.g., cancel pending requests)
        };
    }, [authData]);

    useEffect(() => {
        //Load Data
        getProprietaires();
        return () => {
            // Perform any necessary cleanup (e.g., cancel pending requests)
        };
    }, [authData]);

    useEffect(() => {
        //Load Data
        //console.log("useEffect getVehicules");
        getVehicules(pageNumber, pageSize);
        //console.log("useEffect getVehicules");
        return () => {
            // Perform any necessary cleanup (e.g., cancel pending requests)
        };
    }, [authData, pageNumber, pageSize]);


    return (
        <section className="vehicles-section" onClick={() => { if (openAboutList !== null) { setOpenAboutList(null) } }}>
            <div className="container">
                <div className="vehicule-sec wrap-all">
                    <div className="row row-center w-full">
                        <Link to="/ajoutervehicule" className="vehicule-add color-primary-2 decoration-none">
                            <div className="add-new" id="add-vehc">
                                <span className="material-symbols-outlined">add</span>
                                Ajouter un véhicule
                            </div>
                        </Link>
                        <button className="excel-btn" onClick={exportExcel}>Exporter véhicules</button>
                    </div>
                    <form onSubmit={handleSearch}>
                        <div className="filter-wrap">
                            <input type="text"
                                id="nomMatricule"
                                name="nomMatricule"
                                placeholder='Nom / Immatriculation / N° de châssis / Wilaya / Marque / Modèle'
                                value={filters.nomMatricule || ''}
                                onChange={(e) => setFilters({ ...filters, nomMatricule: e.target.value || '' })}
                                className='input-filter'
                                title='Nom / Immatriculation / N° de châssis / Wilaya / Marque / Modèle'
                            />

                            <select
                                id="statut"
                                name="statut"
                                className="select-filter"
                                value={filters.statut || ''}
                                onChange={(e) => setFilters({ ...filters, statut: e.target.value || '' })}
                            >
                                <option value="" defaultValue={true}>Sélectionnez statut</option>
                                <option value="disponible">Disponible</option>
                                <option value="en cours d'utilisation">En cours</option>
                                <option value="hors service">Hors service</option>
                                <option value="archivé">Archivé</option>
                                <option value="indisponible">Indisponible</option>
                                <option value="vendu">Vendu</option>
                            </select>

                            <select
                                id="idGroupe"
                                name="idGroupe"
                                className="select-filter"
                                value={filters.idGroupe || ''}
                                onChange={(e) => setFilters({ ...filters, idGroupe: e.target.value || '' })}
                            >
                                <option value="">Sélectionnez groupe</option>
                                {groupes.map((groupe) => (
                                    <option key={groupe.idGroupe} value={groupe.idGroupe}>{groupe.libelleGroupe}</option>
                                ))}
                            </select>

                            <select
                                id="idEntreprise"
                                name="idEntreprise"
                                className="select-filter"
                                value={filters.idEntreprise || ''}
                                onChange={(e) => setFilters({ ...filters, idEntreprise: e.target.value || '' })}
                            >
                                <option value="">Séléctionnez entreprise</option>
                                {proprietaires.map((proprietaire) => (
                                    <option key={proprietaire.idProprietaire} value={proprietaire.idProprietaire}>{proprietaire.typeProprietaire !== "freelancer" ? proprietaire.raisonSociale : proprietaire.nom + " " + proprietaire.prenom}</option>
                                ))}
                            </select>
                            <div className="filter">
                                {/* <Link to="#" type="submit"><span className="material-symbols-outlined">filter_alt</span></Link> */}
                                <button type="submit" className="linkButton">
                                    <span className="material-symbols-outlined">filter_alt</span>
                                </button>
                            </div>
                        </div>
                    </form>
                    <div className="group-elements">
                        <div className="voir-elements">
                            <p></p>
                            <select
                                name="pageSize"
                                id="pageSize"
                                onChange={(e) => { handlePerRowsChange(e.target.value || 10); setPageNumber(1); }}
                            >
                                <option value="10" defaultValue={true}>10</option>
                                <option value="15">15</option>
                                <option value="20">20</option>
                            </select>
                        </div>
                    </div>
                    <div className="table-wrap">
                        <table>
                            <thead>
                                <tr>
                                    <th onClick={() => handleSort('vehiculeNom')}>Nom</th>
                                    <th>Marque</th>
                                    <th>Modèle</th>
                                    <th>Immatriculation</th>
                                    <th>N° châssis</th>
                                    <th>Statut</th>
                                    <th>Type</th>
                                    <th>Groupe</th>
                                    <th>Propriètaire</th>
                                    <th>Placement</th>
                                    <th>Compteur actuel</th>
                                    <th>Chauffeur</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {filtredVehicules?.map((vehicule) => (
                                    <tr key={vehicule.idVehicule}>
                                        <td>{vehicule.vehiculeNom}</td>
                                        <td>{vehicule.vehiculeMarque}</td>
                                        <td>{vehicule.vehiculeModele}</td>
                                        <td>{vehicule.vehiculeImmatriculation}</td>
                                        <td>{vehicule.vehiculeNumChassis}</td>
                                        <td>{
                                            vehicule.vehiculeStatut === "disponible" ?
                                                <><svg height="11.87" width="15"><circle cx="6" cy="6" r="6" fill="green" /></svg>Disponible</>
                                                : vehicule.vehiculeStatut === "indisponible" ?
                                                    <><svg height="11.87" width="15"><circle cx="6" cy="6" r="6" fill="orangered" /></svg>Indisponible</>
                                                    : vehicule.vehiculeStatut === "archivé" ?
                                                        <><svg height="11.87" width="15"><circle cx="6" cy="6" r="6" fill="grey" /></svg>Archivé</>
                                                        : vehicule.vehiculeStatut === "vendu" ?
                                                            <><svg height="11.87" width="15"><circle cx="6" cy="6" r="6" fill="blue" /></svg>Vendu</>
                                                            : vehicule.vehiculeStatut === "en cours d'utilisation" ?
                                                                <><svg height="11.87" width="15"><circle cx="6" cy="6" r="6" fill="yellow" /></svg>En cours</>
                                                                : vehicule.vehiculeStatut === "hors service" ?
                                                                    <><svg height="11.87" width="15"><circle cx="6" cy="6" r="6" fill="red" /></svg>Hors service</>
                                                                    : null
                                        }</td>
                                        <td>{vehicule.vehiculeType}</td>
                                        <td>{vehicule.vehiculeLibelleGroupe}</td>
                                        <td>{vehicule.vehiculeProprietaire}</td>
                                        <td>{vehicule.vehiculePlacement}</td>
                                        <td>{vehicule.vehiculeKilometrageActuel}</td>
                                        <td>{vehicule.nomConducteur}</td>
                                        <td className="txt-center relative">
                                            <span className="material-symbols-outlined about-btn" onClick={() => toggleAboutList(vehicule.idVehicule)}>
                                                more_horiz
                                            </span>
                                            {openAboutList === vehicule.idVehicule && (
                                                <div className="about-list">
                                                    <ul>
                                                        <li>
                                                            <Link to={`/listassurances/${vehicule.idVehicule}`} style={{ textDecoration: "none" }} className='row row-center'>
                                                                <span className="material-symbols-outlined">description</span>Assurance
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <Link to={`/listcontroles/${vehicule.idVehicule}`} style={{ textDecoration: "none" }} className='row row-center'>
                                                                <span className="material-symbols-outlined">build</span>Contrôle technique
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <Link to={`/modifiervehicule/${vehicule.idVehicule}`} style={{ textDecoration: "none" }} className='row row-center'>
                                                                <span className="material-symbols-outlined">edit</span>Modifier
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <Link to="/listvehicules" style={{ textDecoration: "none" }} onClick={() => handleConfirmDelete(vehicule.idVehicule)} className='row row-center'>
                                                                <span className="material-symbols-outlined">delete</span>Supprimer
                                                            </Link>
                                                        </li>
                                                        {(vehicule.vehiculeStatut !== "archivé") && <li>
                                                            <Link to="/listvehicules" style={{ textDecoration: "none" }} onClick={() => handleConfirmArchive(vehicule.idVehicule)} className='row row-center'>
                                                                <span className="material-symbols-outlined">archive</span>Archiver
                                                            </Link>
                                                        </li>}
                                                        {(vehicule.vehiculeStatut === "archivé") && <li>
                                                            <Link to="/listvehicules" style={{ textDecoration: "none" }} onClick={() => handleConfirmRestore(vehicule.idVehicule)} className='row row-center'>
                                                                <span className="material-symbols-outlined">restore</span>Restaurer
                                                            </Link>
                                                        </li>}
                                                    </ul>
                                                </div>
                                            )}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    {/* <div className="pagination">
                        <Link to="#" onClick={() => handlePageChange(pageNumber - 1)}
                            className={(pageNumber === 1) ? 'pgn disabled' : 'pgn'}
                            disabled={(pageNumber === 1) ? true : false}
                        >&laquo;</Link>
                        {Array.from({ length: totalPages }, (_, i) => (
                            <Link
                                key={i + 1}
                                to="#"
                                className={i + 1 === pageNumber ? 'pgn active' : 'pgn'}
                                onClick={() => handlePageChange(i + 1)}
                            >
                                {i + 1}
                            </Link>
                        ))}
                        <Link to="#" onClick={() => handlePageChange(pageNumber + 1)}
                            className={((pageNumber + 1) > totalPages) ? 'pgn disabled' : 'pgn'}
                            disabled={((pageNumber + 1) > totalPages) ? true : false}
                        >&raquo;</Link>
                    </div> */}
                    {/* <div className="pagination">
                        <Link to="#" onClick={() => handlePageChange(pageNumber - 1)}
                            className={(pageNumber === 1) ? 'pgn disabled' : 'pgn'}
                            disabled={(pageNumber === 1) ? true : false}
                        >&laquo;</Link>
                        {Array.from({ length: totalPages }, (_, i) => (
                            <Link
                                key={i + 1}
                                to="#"
                                className={pageNumber === i + 1 ? 'pgn active' : 'pgn'}
                                onClick={() => handlePageChange(i + 1)}
                            >
                                {i + 1}
                            </Link>
                        ))}
                        <Link to="#" onClick={() => handlePageChange(pageNumber + 1)}
                            className={((pageNumber + 1) > totalPages) ? 'pgn disabled' : 'pgn'}
                            disabled={((pageNumber + 1) > totalPages) ? true : false}
                        >&raquo;</Link>
                    </div> */}

                    <div className="pagination">
                        <Link to="#" onClick={() => handlePageChange(pageNumber - 1)}
                            className={(pageNumber === 1) ? 'pgn disabled' : 'pgn'}
                            disabled={(pageNumber === 1) ? true : false}
                        >&laquo;</Link>
                        {Array.from({ length: Math.min(5, totalPages) }, (_, i) => {
                            const page = pageNumber - 2 + i;
                            return page <= totalPages && page > 0 && (
                                <Link
                                    key={page}
                                    to="#"
                                    className={pageNumber === page ? 'pgn active' : 'pgn'}
                                    onClick={() => handlePageChange(page)}
                                >
                                    {page}
                                </Link>
                            );
                        })}
                        <Link to="#" onClick={() => handlePageChange(pageNumber + 1)}
                            className={(pageNumber === totalPages || totalPages === 0) ? 'pgn disabled' : 'pgn'}
                            disabled={(pageNumber === totalPages || totalPages === 0) ? true : false}
                        >&raquo;</Link>
                        <span> Affichage de {(pageNumber === 1)?1:((pageNumber - 1) * pageSize+1)} à {pageNumber * pageSize} sur {totalItems} entrées</span>
                    </div>


                    <ConfirmationDialog
                        open={confirmDialogOpenDelete}
                        onClose={handleCancelDelete}
                        onConfirm={handleDelete}
                        titleMessage="Confirmer la suppression du véhicule"
                        bodyMessage={"Voulez-vous vraiment supprimer ce véhicule ? Toutes les données seront définitivement perdues.<br/><strong>Avertissement :</strong> Cette opération supprimera également tous les enregistrements associés à ce véhicule (entrées de service, ordres de travail, entrées de carburant, etc.) et ne peut pas être annulée."}
                        btnCancelText="Annuler"
                        btnConfirmText="Supprimer le véhicule"
                    />
                    <ConfirmationDialog
                        open={confirmDialogOpenArchive}
                        onClose={handleCancelArchive}
                        onConfirm={handleArchive}
                        titleMessage="Confirmer l'archivage du véhicule"
                        bodyMessage={"Voulez-vous vraiment archiver cet enregistrement ? L'archivage d'un enregistrement ne le supprime pas de <i>FleetGo</i>, mais il ne sera plus utilisable.<br/>L'archivage d'un véhicule mettra fin à toutes les affectations de véhicules en cours et supprimera toutes les affectations de véhicules à date future."}
                        btnCancelText="Annuler"
                        btnConfirmText="Archiver le véhicule"
                    />
                </div>
            </div>
        </section >
    );
}

export default ListVehicules;
