import React, { useEffect, useState, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import moment from 'moment/moment';
import { CustomToast } from '../../../../components/Toast/CustomToast';
import ConfirmationDialog from '../../../../components/Confirmation/ConfirmationDialog';
import { axiosInstance, TypeDepense } from '../../../../services/api';
import { AuthContext } from '../../../../contexts/AuthContext';
import useDebounce from '../../../../hooks/useDebounce';

const ListTypeDepenses = () => {
    const { authData } = useContext(AuthContext);
    const navigate = useNavigate();

    const [typesDepenses, setTypesDepenses] = useState([]);
    const [filtredTypesDepenses, setFiltredTypesDepenses] = useState([]);
    const [pageNumber, setPageNumber] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalItems, setTotalItems] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [loading, setLoading] = useState(true);
    const [showAddModal, setShowAddModal] = useState(false);
    const [showUpdateModal, setShowUpdateModal] = useState(false);
    const [idTypeDepenseDelete, setIdTypeDepenseDelete] = useState('');
    const [idTypeDepenseUpdate, setIdTypeDepenseUpdate] = useState('');

    const [search, setSearch] = useState('');
    const debouncedSearchText = useDebounce(search, 300);

    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);

    //Table management
    const [sortBy, setSortBy] = useState(''); // Column name to sort by
    const [sortOrder, setSortOrder] = useState('asc'); // 'asc' or 'desc'

    const handleSort = (column) => {
        setSortBy(column);
        setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');

        let sortedTable = [...filtredTypesDepenses].sort((a, b) => {
            const aValue = a[sortBy] || '';
            const bValue = b[sortBy] || '';

            if (sortOrder === 'asc') {
                return aValue.localeCompare(bValue);
            } else {
                return bValue.localeCompare(aValue);
            }
        });

        setFiltredTypesDepenses(sortedTable);
    };

    const [openAboutList, setOpenAboutList] = useState(null);

    const toggleAboutList = (idMarque) => {
        // console.log(idMarque)
        setOpenAboutList(openAboutList === idMarque ? null : idMarque);
    };

    const handlePageChange = (page) => {
        setPageNumber(page);
        //consolelog("setPageNumber="+pageNumber)
        const startIndex = (page - 1) * pageSize;
        const endIndex = startIndex + pageSize;
        const paginatedData = typesDepenses.slice(startIndex, endIndex);

        setFiltredTypesDepenses(paginatedData);
    };

    const handlePerRowsChange = (newPerPage) => {
        setPageSize(newPerPage);
        //console.log("setPageSize="+pageSize);
    };

    const showHideAddModal = (val) => {
        setShowAddModal(val);
        setIdTypeDepenseUpdate('');
        formik1.resetForm();
    };

    const showHideUpdateModal = (val, idTypeDepense, libelleTypeDepense, statutTypeDepense) => {
        setShowUpdateModal(val);
        setIdTypeDepenseUpdate(idTypeDepense);
        formik2.resetForm();
        if (val) {
            formik2.values.libelleTypeDepenseUpdate = libelleTypeDepense;
            formik2.values.statutTypeDepenseUpdate = statutTypeDepense;
        }
    };

    //Supprimer type de depense
    const deleteTypeDepense = async (idTypeDepense) => {
        try {
            const response = await axiosInstance.delete(`${TypeDepense}/${idTypeDepense}`);
            getTypesDepenses();
            CustomToast("Le type de dépense a été supprimé avec succès", 'success');
        }
        catch (error) {
            // Handle error
            if (!error?.response) {
                CustomToast("Aucune réponse du serveur", 'error');
            } else if (error.response?.status === 400 && error.response?.data === "Expense type is already used. Cannot delete.") {
                CustomToast("Le type de dépense est déjà utilisé. Impossible de suprimer", 'error');
            } else if (error.response?.status === 401) {
                CustomToast("Non autorisé", 'error');
            } else {
                CustomToast("Demande échoué", 'error');
            }
            console.log(error);
        }
    }

    const handleConfirmDelete = (idTypeDepense) => {
        // Show the modal when the confirm delete button is clicked
        //getTypesDepenses(idTypeDepense);
        setIdTypeDepenseDelete(idTypeDepense);
        setConfirmDialogOpen(true);
    };

    const handleDelete = async () => {
        try {
            console.log(idTypeDepenseDelete)
            const response = await axiosInstance.delete(`${TypeDepense}/${idTypeDepenseDelete}`);
            //getTypesDepenses();
            setIdTypeDepenseDelete('');
            CustomToast("Le type de dépense a été supprimée avec succès", 'success');
            getTypesDepenses();
            setConfirmDialogOpen(false);
            //navigate('/listtypedepenses', { replace: true });
        }
        catch (error) {
            // Handle error
            if (!error?.response) {
                CustomToast("Aucune réponse du serveur", 'error');
            } else if (error.response?.status === 400 && error.response?.data === "Expense type is already used. Cannot delete.") {
                CustomToast("Le type de dépense est déjà utilisé. Impossible de suprimer", 'error');
            } else if (error.response?.status === 401) {
                CustomToast("Non autorisé", 'error');
            } else {
                CustomToast("Demande échoué", 'error');
            }
            console.log(error);
        }
    }

    const handleCancelDelete = () => {
        setConfirmDialogOpen(false);
    };

    //Load data
    const getTypesDepenses = async () => {
        try {
            const response = await axiosInstance.get(`${TypeDepense}/GetTypeDepenseUsage`);
            const resp = await response.data;
            setTypesDepenses(resp);
            let filteredResult = resp;
             if (search !== '') {
                filteredResult = resp.filter((item) =>
                    Object.values(item).some(
                        (value) =>
                            value &&
                            value.toString().toLowerCase().includes(search.toLowerCase())
                    )
                );
            }

            // Pagination
            const startIndex = (pageNumber - 1) * pageSize;
            const endIndex = startIndex + pageSize;
            const paginatedResult = filteredResult.slice(startIndex, endIndex);

            setFiltredTypesDepenses(paginatedResult);
            setPageNumber(1);
            setTotalItems(filteredResult.length);
            setTotalPages(Math.ceil(filteredResult.length / pageSize));
            setLoading(false);
        }
        catch (err) {
            setLoading(false);
            console.log(err);
        }
    }

    const handleSearch = (searchTerm) => {

        const result = typesDepenses?.filter((item) =>
            Object.values(item).some(
                (value) =>
                    value &&
                    value.toString().toLowerCase().includes(searchTerm.toLowerCase())
            )
        );
        //console.log(searchTerm,result)
        // Pagination
        const startIndex = (pageNumber - 1) * pageSize;
        const endIndex = startIndex + pageSize;
        const paginatedResult = result.slice(startIndex, endIndex);
        setFiltredTypesDepenses(paginatedResult);
        setPageNumber(1);
        setTotalItems(result.length);
        setTotalPages(Math.ceil(result.length / pageSize));
        setSearch(searchTerm);
    };

    const formik1 = useFormik({
        initialValues: {
            libelleTypeDepense: '',
            statutTypeDepense: '',
        },
        validationSchema: Yup.object({
            libelleTypeDepense: Yup.string()
                .required('Champ obligatoire')
        }),
        onSubmit: async (values) => {
            try {
                const postData = {
                    libelleTypeDepense: values.libelleTypeDepense,
                    statutTypeDepense: values.statutTypeDepense,
                    idUtilisateur: authData.idUser,
                };

                const response = await axiosInstance.post(TypeDepense, postData);
                CustomToast("Le type de dépense a été créé avec succès", 'success');
                getTypesDepenses();
                setShowAddModal(false);
                //navigate('/listtypedepenses', { replace: true });
            } catch (error) {
                // Handle error
                if (!error?.response) {
                    CustomToast("Aucune réponse du serveur", 'error');
                } else if (error.response?.status === 400 && error.response?.data === "Expense entry type exist") {
                    CustomToast("Type de dépense existe", 'error');
                } else if (error.response?.status === 401) {
                    CustomToast("Non autorisé", 'error');
                } else {
                    CustomToast("Demande échoué", 'error');
                }
                console.log(error);
            }
        }
    });

    const formik2 = useFormik({
        initialValues: {
            libelleTypeDepenseUpdate: '',
            statutTypeDepenseUpdate: '',
        },
        validationSchema: Yup.object({
            libelleTypeDepenseUpdate: Yup.string()
                .required('Champ obligatoire')
        }),
        onSubmit: async (values) => {
            try {
                const postData = {
                    idTypeDepense: idTypeDepenseUpdate,
                    libelleTypeDepense: values.libelleTypeDepenseUpdate,
                    statutTypeDepense: values.statutTypeDepenseUpdate,
                    idUtilisateur: authData.idUser,
                };

                //console.log(postData);               
                const response = await axiosInstance.put(TypeDepense, postData);
                CustomToast("Le type de dépense a été modifié avec succès", 'success');
                showHideUpdateModal(false, '', '', '');
                getTypesDepenses();

                //navigate('/listtypedepenses', { replace: true });
            } catch (error) {
                // Handle error
                if (!error?.response) {
                    CustomToast("Aucune réponse du serveur", 'error');
                } else if (error.response?.status === 400 && error.response?.data === "Expense entry type exist") {
                    CustomToast("Type de dépense existe", 'error');
                } else if (error.response?.status === 401) {
                    CustomToast("Non autorisé", 'error');
                } else {
                    CustomToast("Demande échoué", 'error');
                }
                console.log(error);
            }
        }
    });

    useEffect(() => {
        //Load Data
        getTypesDepenses();
        return () => {
            // Perform any necessary cleanup (e.g., cancel pending requests)
        };
    }, [authData, pageSize]);

    useEffect(() => {
        //Load Data
        handleSearch(debouncedSearchText);
        return () => {
            // Perform any necessary cleanup (e.g., cancel pending requests)
        };
    }, [debouncedSearchText]);

    // useEffect(() => {
    //     // const result=vehicules.filter(row=>{
    //     //     return row.vehiculeNom && row.vehiculeNom.toLowerCase().match(search.toLowerCase());   
    //     // })
    //     const result = typesDepenses?.filter((item) =>
    //         Object.values(item).some(
    //             (value) =>
    //                 value &&
    //                 value.toString().toLowerCase().includes(search.toLowerCase())
    //         )
    //     );
    //     setFiltredTypesDepenses(result);
    // }, [search, typesDepenses])

    return (
        <section className="donnee-section" onClick={() => { if (openAboutList !== null) { setOpenAboutList(null) } }}>
            <div className="container diver-section">
                <div className="typedepense-sec wrap-all">
                    <div className="row row-center">
                        <Link to="/divers" className="retour cursurer" id="vehicule-list-assrc-retour">
                            <div className="arrow-back">
                                <span className="material-symbols-outlined" id="arrow">arrow_back</span>
                            </div>
                        </Link>
                        <Link href="#" className="color-primary-2 decoration-none" onClick={() => showHideAddModal(true)}>
                            <div className="add-new" id="add-vehc">
                                <span className="material-symbols-outlined">add</span>
                                Ajouter un type de dépense
                            </div>
                        </Link>
                    </div>

                    <div className="group-elements">
                        <div className="voir-elements">
                            <p></p>
                            <select
                                name="pageSize"
                                id="pageSize"
                                onChange={(e) => { handlePerRowsChange(e.target.value || 10); setPageNumber(1); }}
                            >
                                <option value="10" defaultValue={true}>10</option>
                                <option value="15">15</option>
                                <option value="20">20</option>
                            </select>
                        </div>
                        <div className="search-element">
                            <p>Rechercher</p>
                            <input
                                type="text"
                                value={search}
                                onChange={(e) => handleSearch(e.target.value)}
                            />
                        </div>
                    </div>
                    {filtredTypesDepenses?.length > 0 &&  <div className="table-wrap">
                            <table>
                                <thead>
                                    <tr>
                                        <th onClick={() => handleSort('libelleTypeDepense')}>Nom</th>
                                        <th>Statut</th>
                                        <th>Usage</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {filtredTypesDepenses?.map((row) => (
                                        <tr key={row.idTypeDepense}>
                                            <td>{row.libelleTypeDepense}</td>
                                            <td>
                                                {
                                                    row.statutTypeDepense === "actif" ? (
                                                        <>
                                                            <svg height="11.87" width="15"><circle cx="6" cy="6" r="6" fill="green" /></svg>Actif
                                                        </>
                                                    ) : (
                                                        <>
                                                            <svg height="11.87" width="15"><circle cx="6" cy="6" r="6" fill="red" /></svg>Inactif
                                                        </>
                                                    )
                                                }
                                            </td>

                                            <td>{row.depenseCount + " entrée(s) de dépense(s)"}</td>
                                            <td className="txt-center relative">
                                            <span className="material-symbols-outlined about-btn" onClick={() => toggleAboutList(row.idTypeDepense)}>
                                                more_horiz
                                            </span>
                                            {openAboutList === row.idTypeDepense && (
                                                <div className="about-list">
                                                    <ul>
                                                        <li onClick={() => showHideUpdateModal(true, row.idTypeDepense, row.libelleTypeDepense, row.statutTypeDepense)}>
                                                            <span className="material-symbols-outlined">edit</span>
                                                            <p>Modifier</p>
                                                        </li>
                                                        {(row.depenseCount == 0) && <li onClick={() => handleConfirmDelete(row.idTypeDepense)}>
                                                            <span className="material-symbols-outlined">delete</span>
                                                            <p>Supprimer</p>
                                                        </li>}
                                                        {(row.depenseCount > 0) && <li style={{ textDecoration: "none", color: "#BEBEBE" }} onClick={(event) => event.preventDefault()} title="Type de dépense en cours d'utilisation">
                                                            <span className="material-symbols-outlined">delete</span>
                                                            <p>Supprimer</p>
                                                        </li>}
                                                    </ul>
                                                </div>
                                            )}
                                        </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>}
                        <div className="pagination">
                            <Link to="#" onClick={() => handlePageChange(pageNumber - 1)}
                                className={(pageNumber === 1) ? 'pgn disabled' : 'pgn'}
                                disabled={(pageNumber === 1) ? true : false}
                            >&laquo;</Link>
                            {Array.from({ length: totalPages }, (_, i) => (
                                <Link
                                    key={i + 1}
                                    to="#"
                                    className={i + 1 === pageNumber ? 'pgn active' : 'pgn'}
                                    onClick={() => handlePageChange(i + 1)}
                                >
                                    {i + 1}
                                </Link>
                            ))}
                            <Link to="#" onClick={() => handlePageChange(pageNumber + 1)}
                                className={((pageNumber + 1) > totalPages) ? 'pgn disabled' : 'pgn'}
                                disabled={((pageNumber + 1) > totalPages) ? true : false}
                            >&raquo;</Link>
                        </div>


                    {showAddModal && <div className="popcard-add">
                        <div className="popcard-add_inside">
                            <Link className="popcard-add_inside-close" to="#" onClick={() => showHideAddModal(false)}>&#10006;</Link>
                            <h2 className="popcard-add_inside-title"></h2>
                            <div className="popcard-add_inside-form">
                                <form onSubmit={formik1.handleSubmit}>
                                    <div className="wrap-info">
                                        <div className="wrap-one">
                                            <label>Nom<span className='requiredField'>*</span></label>
                                            <input type="text"
                                                id="libelleTypeDepense"
                                                name="libelleTypeDepense"
                                                style={(formik1.touched.libelleTypeDepense && formik1.errors.libelleTypeDepense) ? { borderColor: "red" } : null}
                                                placeholder=""
                                                defaultValue={formik1.values.libelleTypeDepense}
                                                onChange={formik1.handleChange}
                                            />
                                            {formik1.touched.libelleTypeDepense && formik1.errors.libelleTypeDepense ? (
                                                <div className="invalid-feedback">{formik1.errors.libelleTypeDepense}</div>
                                            ) : null}
                                        </div>
                                        <div className="wrap-one">
                                            <label >Statut<span className='requiredField'>*</span></label>
                                            <select
                                                id="statutTypeDepense"
                                                name="statutTypeDepense"
                                                value={formik1.values.statutTypeDepense}
                                                onChange={formik1.handleChange}
                                            >
                                                <option value="actif" defaultValue={true}>Actif</option>
                                                <option value="inactif">Inactif</option>
                                            </select>
                                        </div>
                                    </div>

                                    <div className="popcard-operation-pickup-details_inside-zone">
                                        <div className="buttons">
                                            <input type="submit" value="Enregistrer" className="submit-btn" />
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>}

                    {showUpdateModal && <div className="popcard-add">
                        <div className="popcard-add_inside">
                            <Link className="popcard-add_inside-close" to="#" onClick={() => showHideUpdateModal(false, '', '', '')}>&#10006;</Link>
                            <h2 className="popcard-add_inside-title"></h2>
                            <div className="popcard-add_inside-form">
                                <form onSubmit={formik2.handleSubmit}>
                                    <div className="wrap-info">
                                        <div className="wrap-one">
                                            <label>Nom<span className='requiredField'>*</span></label>
                                            <input type="text"
                                                id="libelleTypeDepenseUpdate"
                                                name="libelleTypeDepenseUpdate"
                                                style={(formik2.touched.libelleTypeDepenseUpdate && formik2.errors.libelleTypeDepenseUpdate) ? { borderColor: "red" } : null}
                                                placeholder=""
                                                defaultValue={formik2.values.libelleTypeDepenseUpdate}
                                                onChange={formik2.handleChange}
                                            />
                                            {formik2.touched.libelleTypeDepenseUpdate && formik2.errors.libelleTypeDepenseUpdate ? (
                                                <div className="invalid-feedback">{formik2.errors.libelleTypeDepenseUpdate}</div>
                                            ) : null}
                                        </div>
                                        <div className="wrap-one">
                                            <label >Statut<span className='requiredField'>*</span></label>
                                            <select
                                                id="statutTypeDepenseUpdate"
                                                name="statutTypeDepenseUpdate"
                                                value={formik2.values.statutTypeDepenseUpdate}
                                                onChange={formik2.handleChange}
                                            >
                                                <option value="actif" defaultValue={true}>Actif</option>
                                                <option value="inactif">Inactif</option>
                                            </select>
                                        </div>
                                    </div>

                                    <div className="popcard-operation-pickup-details_inside-zone">
                                        <div className="buttons">
                                            <input type="submit" value="Enregistrer" className="submit-btn" />
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>}

                    <ConfirmationDialog
                        open={confirmDialogOpen}
                        onClose={handleCancelDelete}
                        onConfirm={handleDelete}
                        titleMessage="Confirmer la suppression du type de dépense"
                        bodyMessage="Voulez-vous vraiment supprimer ce type de dépense ? Toutes les données seront définitivement perdues."
                        btnCancelText="Annuler"
                        btnConfirmText="Supprimer le type de dépense"
                    />

                </div>
            </div>
        </section>
    );
}

export default ListTypeDepenses;
