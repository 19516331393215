import React, { useEffect, useState, useRef, useContext } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import moment from 'moment/moment';
import Select from "react-select";
import { CustomToast } from '../../../components/Toast/CustomToast';
import useDebounce from '../../../hooks/useDebounce';
import { axiosInstance, Vehicule, VehiculeServiceRappelle, TacheService } from '../../../services/api';
import { AuthContext } from '../../../contexts/AuthContext';

const ModifierRappel = () => {
    const { authData } = useContext(AuthContext);
    const navigate = useNavigate();
    const { id } = useParams();//idServiceRappelle

    const [vehicules, setVehicules] = useState([]);
    const [taches, setTaches] = useState([]);
    const [isLoaded, setIsLoaded] = useState();

    //Load data
    const getRappel = async (idServiceRappelle) => {
        try {
            const response = await axiosInstance.get(`${VehiculeServiceRappelle}/${idServiceRappelle}`);
            const resp = await response.data;
            setIsLoaded(resp);

            setValues(prevValues => ({
                ...prevValues,
                vehicule: '',
                idServiceRappelle: resp.idServiceRappelle,
                idVehicule: resp.idVehicule,
                idTacheService: resp.idTacheService,
                tacheService: '',
                intervalleTemps: resp.intervalleTemps || '',
                uniteIntervalleTemps: resp.uniteIntervalleTemps || 'jour',
                echeanceTemps: resp.echeanceTemps || '',
                uniteEchanceTemps: resp.uniteEchanceTemps || 'jour',
                intervalleCompteur: resp.intervalleCompteur === '0' ? '' : '' || '',
                echanceCompteur: resp.echanceCompteur === '0' ? '' : '' || '',
                notificationActive: resp.notificationActive || false,
                prochaineDateEcheanceKilometrageManuelle: resp.prochaineDateEcheanceKilometrageManuelle || false,
                prochaineDateEcheance: resp.prochaineDateEcheanceKilometrageManuelle ? moment(resp.prochaineDateEcheanceFin).format('YYYY-MM-DD') : '' || '',
                prochaineCompteurEcheance: resp.prochaineDateEcheanceKilometrageManuelle ? resp.prochaineCompteurEcheanceFin : '' || ''
            }));
        }
        catch (err) {
            console.log(err);
        }
    }

    const getVehicules = async (idVehicule) => {
        try {
            const response = await axiosInstance.get(`${Vehicule}/GetById/${idVehicule}`);
            const resp = await response.data;

            setFieldValue('vehicule', resp.nom + ' ' + resp.immatriculation || '');
        }
        catch (err) {
            console.log(err);
        }
    }

    const getTaches = async (idTacheService) => {
        try {
            const response = await axiosInstance.get(`${TacheService}/${idTacheService}`);
            const resp = await response.data;

            if (resp) {
                setFieldValue('tacheService', resp.libelle || '');
            }

            setTaches(resp);
        }
        catch (err) {
            console.log(err);
        }
    }

    const { handleChange, values, handleSubmit, touched, errors, setFieldValue, setValues } = useFormik({
        initialValues: {
            vehicule: '',
            idServiceRappelle: '',
            idVehicule: '',
            idTacheService: '',
            tacheService: '',
            intervalleTemps: '',
            uniteIntervalleTemps: 'jour',
            echeanceTemps: '',
            uniteEchanceTemps: 'jour',
            intervalleCompteur: '',
            echanceCompteur: '',
            notificationActive: false,
            prochaineDateEcheanceKilometrageManuelle: false,
            prochaineDateEcheance: '',
            prochaineCompteurEcheance: ''
        },
        validationSchema: Yup.object({
            idTacheService: Yup.string()
                .required("Champ obligatoire"),
            intervalleTemps: Yup.number()
                .min(0, "L'intervalle de temps ne peut pas être négative")
                .test('intervalleTemps-validation', 'Invalid intervalleTemps', function (value) {

                    const error = isNaN(this.resolve(Yup.ref('intervalleTemps'))) && isNaN(this.resolve(Yup.ref('intervalleCompteur'))) ? true : false;

                    if (error) {
                        return this.createError({ message: "Veuillez indiquer une valeur du compteur ou un intervalle de temps" });
                    }

                    return true;
                }),
            echeanceTemps: Yup.number()
                .min(0, "Seuil d'échéance ne peut pas être négative")
                .test('echeance-validation', 'Invalid echeance', function (value) {

                    const intervalleTemps = isNaN(this.resolve(Yup.ref('intervalleTemps'))) ? 0 : this.resolve(Yup.ref('intervalleTemps'));
                    const uniteIntervalleTemps = this.resolve(Yup.ref('uniteIntervalleTemps'));
                    const uniteEchanceTemps = this.resolve(Yup.ref('uniteEchanceTemps'));

                    if (intervalleTemps <= 0) {
                        return true;
                    }

                    if (isNaN(value)) {
                        value = 0;
                    }

                    let intervalEnJours = "";

                    switch (this.resolve(Yup.ref('uniteIntervalleTemps'))) {
                        case "mois":
                            intervalEnJours *= 30;
                            break;
                        case "année":
                            intervalEnJours *= 365;
                            break;
                        default:
                            intervalEnJours = intervalleTemps;
                    }

                    let echanceEnJours = "";

                    switch (this.resolve(Yup.ref('uniteEchanceTemps'))) {
                        case "mois":
                            echanceEnJours *= 30;
                            break;
                        case "année":
                            echanceEnJours *= 365;
                            break;
                        default:
                            echanceEnJours = value;
                    }

                    if (echanceEnJours >= intervalEnJours) {
                        return this.createError({ message: "Trop élevé (ne peut pas être égal ou supérieur à l'intervalle de temps)" });
                    }

                    return true;
                }),
            intervalleCompteur: Yup.number()
                .min(0, "L'intervalle du compteur ne peut pas être négative")
                .test('intervalleTemps-validation', 'Invalid intervalleTemps', function (value) {

                    const error = isNaN(this.resolve(Yup.ref('intervalleTemps'))) && isNaN(this.resolve(Yup.ref('intervalleCompteur'))) ? true : false;

                    if (error) {
                        return this.createError({ message: "Veuillez indiquer une valeur du compteur ou un intervalle de temps" });
                    }

                    return true;
                }),
            echanceCompteur: Yup.number()
                .min(0, "Seuil du compteur ne peut pas être négative")
                .test('echeanceCompteur-validation', 'Invalid echeance compteur', function (value) {
                    const intervalleCompteur = isNaN(this.resolve(Yup.ref('intervalleCompteur'))) ? 0 : this.resolve(Yup.ref('intervalleCompteur'));

                    if (intervalleCompteur <= 0) {
                        return true;
                    }

                    if (isNaN(value)) {
                        value = 0;
                    }

                    if (value >= intervalleCompteur) {
                        return this.createError({ message: "Le seuil d'échéance prochaine du compteur est trop élevé (ne peut pas être égal ou supérieur à l'intervalle du compteur)" });
                    }

                    return true;
                }),
        }),
        onSubmit: async (values) => {
            try {
                //console.log(values);
                const postData = {
                    idServiceRappelle: id,
                    idVehicule: values.idVehicule,
                    idTacheService: values.idTacheService,
                    intervalleTemps: values.intervalleTemps.toString() || '',
                    uniteIntervalleTemps: values.uniteIntervalleTemps || 'jour',
                    echeanceTemps: values.echeanceTemps.toString() || '',
                    uniteEchanceTemps: values.uniteEchanceTemps || 'jour',
                    intervalleCompteur: values.intervalleCompteur.toString() || '',
                    echanceCompteur: values.echanceCompteur.toString() || '',
                    notificationActive: values.notificationActive || false,
                    prochaineDateEcheanceKilometrageManuelle: values.prochaineDateEcheanceKilometrageManuelle || false,
                    prochaineDateEcheance: values.prochaineDateEcheanceKilometrageManuelle ? values.prochaineDateEcheance : moment("00", "hh").add(values.intervalleTemps, 'days').format('YYYY-MM-DD') || '',
                    prochaineCompteurEcheance: values.prochaineDateEcheanceKilometrageManuelle ? values.prochaineCompteurEcheance : '' || '',
                    idUtilisateurAction: authData.idUser,
                };

                //console.log(postData);

                await axiosInstance.put(VehiculeServiceRappelle, postData);

                CustomToast("Le rappel de service a été modifié avec succès", 'success');
                navigate('/listrappels', { replace: true });

            } catch (error) {
                // Handle error
                if (!error?.response) {
                    CustomToast("Aucune réponse du serveur", 'error');
                } else if (error.response?.status === 400 && error.response?.data === "Vehicle not found") {
                    CustomToast("Véhicule introuvable", 'error');
                } else if (error.response?.status === 400 && error.response?.data === "Task does not exist") {
                    CustomToast("Tâche de service introuvable", 'error');
                } else if (error.response?.status === 401) {
                    CustomToast("Non autorisé", 'error');
                } else {
                    CustomToast("Demande échoué", 'error');
                }
                console.log(error);
            }
        }
    });

    useEffect(() => {
        getRappel(id);
        //getTaches();
    }, [id])

    useEffect(() => {
        if (values.idVehicule !== '' && values.vehicule === '') {
            getVehicules(values.idVehicule);
        }
    }, [isLoaded])

    useEffect(() => {
        if (values.idTacheService !== '' && values.tacheService === '') {
            getTaches(values.idTacheService);
        }
    }, [isLoaded])

    return (
        <section className="service-section">
            <div className="container">
                <form onSubmit={handleSubmit} className='rappelsrc-form'>
                    <div className="">
                        <div className="grid grid--2-cols">
                            <div className="form-sec">
                                <div className="retour" id="retour-rappeleservice">
                                    <Link to="/listrappels" style={{ textDecoration: "none" }}>
                                        <div className="arrow-back">
                                            <span className="material-symbols-outlined" id="arrow">arrow_back</span>
                                        </div>
                                    </Link>
                                </div>
                                <div className="form-details">
                                    <div className="detail-form" id="detail-entree-service-form">
                                        <div className="col">
                                            <div className="grp">
                                                <div className="gp">
                                                    <label >Véhicule<span className='requiredField'>*</span></label>
                                                    <input
                                                        type="text"
                                                        name="vehicule"
                                                        id="vehicule"
                                                        value={values.vehicule}
                                                        disabled={true} />
                                                </div>
                                            </div>
                                            <div className="grp">
                                                <div className="gp">
                                                    <label>Tâche de service<span className='requiredField'>*</span></label>
                                                    <input
                                                        type="text"
                                                        name="idTacheService"
                                                        id="idTacheService"
                                                        value={values.tacheService}
                                                        disabled={true} />
                                                </div>
                                            </div>
                                            <div className="grp">
                                                <div className="gp" title="Utilisez cette option pour définir un rappel de service basé sur un intervalle de temps. Laissez vide si vous ne souhaitez pas utiliser cette option">
                                                    <label>Intervalle de temps</label>
                                                    <input
                                                        type="number"
                                                        id="intervalleTemps"
                                                        name="intervalleTemps"
                                                        placeholder=""
                                                        defaultValue={values.intervalleTemps}
                                                        onChange={handleChange}
                                                        step="any"
                                                        style={(touched.intervalleTemps && errors.intervalleTemps) ? { borderColor: "red" } : null}
                                                    />
                                                    {errors.intervalleTemps && touched.intervalleTemps && (
                                                        <div className="invalid-feedback">{errors.intervalleTemps}</div>
                                                    )}
                                                </div>
                                                <div className="gp">
                                                    <label className="hd"></label>
                                                    <select
                                                        id="uniteIntervalleTemps"
                                                        name="uniteIntervalleTemps"
                                                        value={values.uniteIntervalleTemps}
                                                        onChange={handleChange}
                                                    >
                                                        <option value="jour" defaultValue={true}>Jour(s)</option>
                                                        <option value="mois">Mois</option>
                                                        <option value="année">Année(s)</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="grp">
                                                <div className="gp" title="Le laps de temps à l’avance que vous considérez comme rappel «dû bientôt»">
                                                    <label>Seuil d'échéance prochaine</label>
                                                    <input
                                                        type="number"
                                                        id="echeanceTemps"
                                                        name="echeanceTemps"
                                                        placeholder=""
                                                        defaultValue={values.echeanceTemps}
                                                        onChange={handleChange}
                                                        step="any"
                                                        style={(touched.echeanceTemps && errors.echeanceTemps) ? { borderColor: "red" } : null}
                                                    />
                                                    {errors.echeanceTemps && touched.echeanceTemps && (
                                                        <div className="invalid-feedback">{errors.echeanceTemps}</div>
                                                    )}
                                                </div>
                                                <div className="gp">
                                                    <label className="hd"></label>
                                                    <select
                                                        id="uniteEchanceTemps"
                                                        name="uniteEchanceTemps"
                                                        value={values.uniteEchanceTemps}
                                                        onChange={handleChange}
                                                    >
                                                        <option value="jour" defaultValue={true}>Jour(s)</option>
                                                        <option value="mois">Mois</option>
                                                        <option value="année">Année(s)</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="grp">
                                                <div className="gp" title="Utilisez cette option pour définir un rappel de service en fonction de valeur de compteur (par exemple, vidange d’huile tous les 10000 k). Laissez vide si vous ne souhaitez pas utiliser cette option">
                                                    <label>Intervalle de valeur du compteur</label>
                                                    <input
                                                        type="number"
                                                        id="intervalleCompteur"
                                                        name="intervalleCompteur"
                                                        placeholder=""
                                                        defaultValue={values.intervalleCompteur}
                                                        onChange={handleChange}
                                                        step="any"
                                                        style={(touched.intervalleCompteur && errors.intervalleCompteur) ? { borderColor: "red" } : null}
                                                    />
                                                    {errors.intervalleCompteur && touched.intervalleCompteur && (
                                                        <div className="invalid-feedback">{errors.intervalleCompteur}</div>
                                                    )}
                                                </div>
                                                <div className="gp" title="Le nombre de kilomètres à l’avance pour lequel vous considérez que ce rappel est «dû bientôt»">
                                                    <label>Seuil d'échéance prochaine</label>
                                                    <input
                                                        type="number"
                                                        id="echanceCompteur"
                                                        name="echanceCompteur"
                                                        placeholder=""
                                                        defaultValue={values.echanceCompteur}
                                                        onChange={handleChange}
                                                        step="any"
                                                        style={(touched.echanceCompteur && errors.echanceCompteur) ? { borderColor: "red" } : null}
                                                    />
                                                    {errors.echanceCompteur && touched.echanceCompteur && (
                                                        <div className="invalid-feedback">{errors.echanceCompteur}</div>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="grp">
                                                <div className="row row-center">
                                                    <input
                                                        type="checkbox"
                                                        className="box"
                                                        id="prochaineDateEcheanceKilometrageManuelle"
                                                        name="prochaineDateEcheanceKilometrageManuelle"
                                                        checked={values.prochaineDateEcheanceKilometrageManuelle}
                                                        onChange={handleChange}
                                                    />
                                                    <label className="small-label"> Définissez manuellement la date d'échéance et/ou le compteur pour le prochain rappel</label>
                                                </div>
                                            </div>
                                            <div className="grp">
                                                {(values.prochaineDateEcheanceKilometrageManuelle && values.intervalleTemps) && <div className="col">
                                                    <label>Prochaine date d'échéance</label>
                                                    <input
                                                        type="date"
                                                        id="prochaineDateEcheance"
                                                        name="prochaineDateEcheance"
                                                        placeholder=""
                                                        defaultValue={values.prochaineDateEcheance}
                                                        onChange={handleChange}
                                                    />
                                                </div>}
                                                {(values.prochaineDateEcheanceKilometrageManuelle && values.intervalleCompteur) && <div className="col">
                                                    <label>Compteur échéance</label>
                                                    <input
                                                        type="number"
                                                        id="prochaineCompteurEcheance"
                                                        name="prochaineCompteurEcheance"
                                                        placeholder=""
                                                        defaultValue={values.prochaineCompteurEcheance}
                                                        onChange={handleChange}
                                                        step="any"
                                                    />
                                                </div>}
                                            </div>

                                        </div>
                                    </div>
                                    <div className="submit-container">
                                        <button type="submit" className="submit-btn">Enregistrer</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </section>
    );
}

export default ModifierRappel;
