import React, { useEffect, useState, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import moment from 'moment/moment';
import { CustomToast } from '../../components/Toast/CustomToast';
import ConfirmationDialog from '../../components/Confirmation/ConfirmationDialog';
import { axiosInstance, Client } from '../../services/api';
import { AuthContext } from '../../contexts/AuthContext';

const ListClients = () => {
    const { authData } = useContext(AuthContext);
    const navigate = useNavigate();

    const [apiKeys, setApiKeys] = useState([]);
    const [filtredApiKeys, setFiltredApiKeys] = useState([]);
    const [loading, setLoading] = useState(true);
    const [showAddModal, setShowAddModal] = useState(false);
    const [showUpdateModal, setShowUpdateModal] = useState(false);
    const [idClientDelete, setIdClientDelete] = useState('');
    const [idClientUpdate, setIdClientUpdate] = useState('');

    const [search, setSearch] = useState('');

    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);

    //Table management
    const [sortBy, setSortBy] = useState(''); // Column name to sort by
    const [sortOrder, setSortOrder] = useState('asc'); // 'asc' or 'desc'

    const handleSort = (column) => {
        setSortBy(column);
        setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');

        let sortedTable = [...filtredApiKeys].sort((a, b) => {
            const aValue = a[sortBy] || '';
            const bValue = b[sortBy] || '';

            if (sortOrder === 'asc') {
                return aValue.localeCompare(bValue);
            } else {
                return bValue.localeCompare(aValue);
            }
        });
        ;
        setFiltredApiKeys(sortedTable);
    };

    const [openAboutList, setOpenAboutList] = useState(null);

    const toggleAboutList = (id) => {
        // console.log(idMarque)
        setOpenAboutList(openAboutList === id ? null : id);
    };
    const showHideAddModal = (val) => {
        setShowAddModal(val);
        setIdClientUpdate('');
        formik1.resetForm();
    };

    const showHideUpdateModal = (val, idClient, nom, domainesApi) => {
        setShowUpdateModal(val);
        setIdClientUpdate(idClient);
        formik2.resetForm();
        if (val) {
            formik2.setValues(prevValues => ({
                ...prevValues,
                nomUpdate: nom,
                domainesApiUpdate: domainesApi
            }));

        }
    };

    //Supprimer mode de paiement
    const handleConfirmDelete = (idClient) => {
        // Show the modal when the confirm delete button is clicked
        setIdClientDelete(idClient);
        setConfirmDialogOpen(true);
    };

    const handleDelete = async () => {
        try {
            const response = await axiosInstance.delete(`${Client}/${idClientDelete}`);
            setIdClientDelete('');
            CustomToast("Le mode de paiement a été supprimé avec succès", 'success');
            getClient();
            setConfirmDialogOpen(false);
        }
        catch (error) {
            // Handle error
            if (!error?.response) {
                CustomToast("Aucune réponse du serveur", 'error');
            } else if (error.response?.status === 401) {
                CustomToast("Non autorisé", 'error');
            } else {
                CustomToast("Demande échoué", 'error');
            }
            console.log(error);
        }
    }

    const handleCancelDelete = () => {
        setConfirmDialogOpen(false);
    };

    //Load data
    const getClient = async () => {
        try {
            const response = await axiosInstance.get(`${Client}`);
            const resp = await response.data;
            setApiKeys(resp);
            if (search !== '') {
                const result = apiKeys?.filter((item) =>
                    Object.values(item).some(
                        (value) =>
                            value &&
                            value.toString().toLowerCase().includes(search.toLowerCase())
                    )
                );
                setFiltredApiKeys(result);
            } else {
                setFiltredApiKeys(resp);
            }

            setLoading(false);
        }
        catch (err) {
            setLoading(false);
            console.log(err);
        }
    }

    const formik1 = useFormik({
        initialValues: {
            nom: '',
            domainesApi: ''
        },
        validationSchema: Yup.object({
            nom: Yup.string()
                .required('Champ obligatoire'),
            domainesApi: Yup.string()
                .required('Champ obligatoire')
        }),
        onSubmit: async (values) => {
            try {
                const postData = {
                    nom: values.nom,
                    domainesApi: values.domainesApi,
                    idUtilisateurAction: authData.idUser,
                };

                await axiosInstance.post(Client, postData);
                CustomToast("Le client api a été créé avec succès", 'success');
                getClient();
                setShowAddModal(false);
                //navigate('/listclients', { replace: true });
            } catch (error) {
                // Handle error
                if (!error?.response) {
                    CustomToast("Aucune réponse du serveur", 'error');
                } else if (error.response?.status === 400 && error.response?.data === "Client existe") {
                    CustomToast("Client existe", 'error');
                } else if (error.response?.status === 401) {
                    CustomToast("Non autorisé", 'error');
                } else {
                    CustomToast("Demande échoué", 'error');
                }
                console.log(error);
            }
        }
    });

    const formik2 = useFormik({
        initialValues: {
            nomUpdate: '',
            domainesApiUpdate: ''
        },
        validationSchema: Yup.object({
            nomUpdate: Yup.string()
                .required('Champ obligatoire'),
            domainesApiUpdate: Yup.string()
                .required('Champ obligatoire')
        }),
        onSubmit: async (values) => {
            try {
                const postData = {
                    idClient: idClientUpdate,
                    nom: values.nomUpdate,
                    domainesApi: values.domainesApiUpdate,
                    idUtilisateurAction: authData.idUser,
                };

                await axiosInstance.put(Client, postData);
                CustomToast("Le client a été modifié avec succès", 'success');
                showHideUpdateModal(false, '', '');
                getClient();
            } catch (error) {
                // Handle error
                if (!error?.response) {
                    CustomToast("Aucune réponse du serveur", 'error');
                } else if (error.response?.status === 400 && error.response?.data === "Client existe") {
                    CustomToast("Client existe", 'error');
                } else if (error.response?.status === 401) {
                    CustomToast("Non autorisé", 'error');
                } else {
                    CustomToast("Demande échoué", 'error');
                }
                console.log(error);
            }
        }
    });

    useEffect(() => {
        //Load Data
        getClient();
        return () => {
            // Perform any necessary cleanup (e.g., cancel pending requests)
        };
    }, [authData]);

    useEffect(() => {
        const result = apiKeys?.filter((item) =>
            Object.values(item).some(
                (value) =>
                    value &&
                    value.toString().toLowerCase().includes(search.toLowerCase())
            )
        );
        setFiltredApiKeys(result);
    }, [search, apiKeys])

    return (
        <section className="donnee-section" onClick={() => { if (openAboutList !== null) { setOpenAboutList(null) } }}>
            <div className="container diver-section">
                <div className="marque-sec wrap-all">

                    <div className="row row-center">
                        <Link href="#" className="color-primary-2 decoration-none" onClick={() => showHideAddModal(true)}>
                            <div className="add-new" id="add-vehc">
                                <span className="material-symbols-outlined">add</span>
                                Ajouter api key
                            </div>
                        </Link>
                    </div>

                    {filtredApiKeys?.length > 0 && <div className="table-wrap">
                        <table>
                            <thead>
                                <tr>
                                    <th onClick={() => handleSort('nom')}>Client</th>
                                    <th>Date création</th>
                                    <th>Domaines</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {filtredApiKeys?.map((row) => (
                                    <tr key={row.idClient}>
                                        <td>{row.nom}</td>
                                        <td>{row.dateCreation ? (moment(row.dateCreation).format('DD-MM-YYYY HH:mm:ss')) : ''}</td>
                                        <td>{row?.domainesApi?.join(',')}</td>
                                        <td className="txt-center relative">
                                            <span className="material-symbols-outlined about-btn" onClick={() => toggleAboutList(row.idClient)}>
                                                more_horiz
                                            </span>
                                            {openAboutList === row.idClient && (
                                                <div className="about-list">
                                                    <ul>
                                                        <li className='row row-center' onClick={() => showHideUpdateModal(true, row.idClient, row.nom, row?.domainesApi?.join(','))}>
                                                            <span className="material-symbols-outlined">edit</span>
                                                            <p>Modifier</p>
                                                        </li>
                                                        <li className='row row-center' onClick={() => handleConfirmDelete(row.idClient)}>
                                                            <span className="material-symbols-outlined">delete</span>
                                                            <p>Supprimer</p>
                                                        </li>
                                                    </ul>
                                                </div>
                                            )}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>}

                        {showAddModal && <div className="popcard-add">
                            <div className="popcard-add_inside">
                                <Link className="popcard-add_inside-close" to="#" onClick={() => showHideAddModal(false)}>&#10006;</Link>
                                <h2 className="popcard-add_inside-title"></h2>
                                <div className="popcard-add_inside-form">
                                    <form onSubmit={formik1.handleSubmit}>
                                        <div className="wrap-info">
                                            <div className="wrap-one">
                                                <label>Nom<span className='requiredField'>*</span></label>
                                                <input type="text"
                                                    id="nom"
                                                    name="nom"
                                                    style={(formik1.touched.nom && formik1.errors.nom) ? { borderColor: "red" } : null}
                                                    placeholder=""
                                                    defaultValue={formik1.values.nom}
                                                    onChange={formik1.handleChange}
                                                />
                                                {formik1.touched.nom && formik1.errors.nom ? (
                                                    <div className="invalid-feedback">{formik1.errors.nom}</div>
                                                ) : null}
                                            </div>
                                            <div className="wrap-one" title="Séparez les domaines par «, » si plusieurs">
                                                <label>Domaines<span className='requiredField'>*</span></label>
                                                <input type="text"
                                                    id="domainesApi"
                                                    name="domainesApi"
                                                    style={(formik1.touched.domainesApi && formik1.errors.domainesApi) ? { borderColor: "red" } : null}
                                                    placeholder=""
                                                    defaultValue={formik1.values.domainesApi}
                                                    onChange={formik1.handleChange}
                                                />
                                                {formik1.touched.domainesApi && formik1.errors.domainesApi ? (
                                                    <div className="invalid-feedback">{formik1.errors.domainesApi}</div>
                                                ) : null}
                                            </div>
                                        </div>

                                        <div className="popcard-operation-pickup-details_inside-zone">
                                            <div className="buttons">
                                                <input type="submit" value="Enregistrer" className="submit-btn" />
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>}

                        {showUpdateModal && <div className="popcard-add">
                            <div className="popcard-add_inside">
                                <Link className="popcard-add_inside-close" to="#" onClick={() => showHideUpdateModal(false, '', '')}>&#10006;</Link>
                                <h2 className="popcard-add_inside-title"></h2>
                                <div className="popcard-add_inside-form">
                                    <form onSubmit={formik2.handleSubmit}>
                                        <div className="wrap-info">
                                            <div className="wrap-one">
                                                <label>Nom<span className='requiredField'>*</span></label>
                                                <input type="text"
                                                    id="nomUpdate"
                                                    name="nomUpdate"
                                                    style={(formik2.touched.nomUpdate && formik2.errors.nomUpdate) ? { borderColor: "red" } : null}
                                                    placeholder=""
                                                    defaultValue={formik2.values.nomUpdate}
                                                    onChange={formik2.handleChange}
                                                />
                                                {formik2.touched.nomUpdate && formik2.errors.nomUpdate ? (
                                                    <div className="invalid-feedback">{formik2.errors.nomUpdate}</div>
                                                ) : null}
                                            </div>
                                            <div className="wrap-one" title="Séparez les domaines par «, » si plusieurs">
                                                <label>Domaines<span className='requiredField'>*</span></label>
                                                <input type="text"
                                                    id="domainesApiUpdate"
                                                    name="domainesApiUpdate"
                                                    style={(formik2.touched.domainesApiUpdate && formik2.errors.domainesApiUpdate) ? { borderColor: "red" } : null}
                                                    placeholder=""
                                                    defaultValue={formik2.values.domainesApiUpdate}
                                                    onChange={formik2.handleChange}
                                                />
                                                {formik2.touched.domainesApiUpdate && formik2.errors.domainesApiUpdate ? (
                                                    <div className="invalid-feedback">{formik2.errors.domainesApiUpdate}</div>
                                                ) : null}
                                            </div>
                                        </div>

                                        <div className="popcard-operation-pickup-details_inside-zone">
                                            <div className="buttons">
                                                <input type="submit" value="Enregistrer" className="submit-btn" />
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>}

                        <ConfirmationDialog
                            open={confirmDialogOpen}
                            onClose={handleCancelDelete}
                            onConfirm={handleDelete}
                            titleMessage="Confirmer la suppression"
                            bodyMessage="Voulez-vous vraiment supprimer ce client? Toutes les données seront définitivement perdues."
                            btnCancelText="Annuler"
                            btnConfirmText="Supprimer le client"
                        />

                    </div>
                </div>
        </section>
    );
}


export default ListClients;
