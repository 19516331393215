import React, { useEffect, useState, useContext, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import moment from 'moment/moment';
import Select from "react-select";
import { CustomToast } from '../../components/Toast/CustomToast';
import useDebounce from '../../hooks/useDebounce';
import { axiosInstance, Vehicule, VehiculeControleTechnique } from '../../services/api';
import { AuthContext } from '../../contexts/AuthContext';
const AjouterControle = () => {
    const { authData } = useContext(AuthContext);
    const navigate = useNavigate();

    const [vehicules, setVehicules] = useState([]);

    //const [searchVehicule, setSearchVehicule] = useState("");
    const [selectedVehicle, setSelectedVehicle] = useState('');
    const [selectedVehicleId, setSelectedVehicleId] = useState('');
    const [enteredVehicleText, setEnteredVehicleText] = useState('');
    const debouncedVehiculeText = useDebounce(enteredVehicleText, 300);

    const [pathImageControle, setPathImageControle] = useState(null);
    const [pathImageControleName, setPathImageControleName] = useState('');
    const pathImageControleRef = useRef(null);

    const handlePathImageControleChange = (e) => {
        setPathImageControle(e.target.files[0]);
        setPathImageControleName('');
    };

    const getImageFileName = (file) => {
        return file ? file.name : 'No file selected';
    };

    //Load data     

    const handleVehicleChange = (selectedOption) => {
        setSelectedVehicle(selectedOption);
        setSelectedVehicleId(selectedOption ? selectedOption.idVehicule : '');
        values.idVehicule = selectedVehicleId;
    };

    const getVehicules = async (nomVehicule) => {
        try {
            const queryParams = new URLSearchParams({
                pageNumber: 1,
                pageSize: 10
            });

            if (authData?.wilayas != null) {
                const wilayasFilter = authData?.wilayas
                queryParams.append("wilayas", wilayasFilter.join(','));
            }
            else {
                return
            }

            if (authData?.flottesAutorises != null) {
                const flottesAutorisesFilter = authData?.flottesAutorises
                queryParams.append("flottesAutorises", flottesAutorisesFilter.join(','));
            }
            else {
                return
            }

            if (nomVehicule !== '') {
                queryParams.append("nom", nomVehicule);
            }
            const response = await axiosInstance.get(`${Vehicule}/GetVehiculesAutocomplete?${queryParams}`);
            const resp = await response.data;
            setVehicules(resp.data);
        }
        catch (err) {
            console.log(err);
        }
    }

    const { handleChange, values, handleSubmit, touched, errors, setFieldValue } = useFormik({
        initialValues: {
            vehicule: '',
            idVehicule: '',
            natureControle: '',
            numControle: '',
            resultatsControle: '',
            dateControle: '',
            dateProchainControle: '',
            uniteIntervalleTemps: 'jour',
            echeanceTemps: ''
        },
        validationSchema: Yup.object({
            vehicule: Yup.object()
                .nullable()
                .required('Champ obligatoire'),
            dateControle: Yup.string()
                .required("Champ obligatoire"),
            dateProchainControle: Yup.string()
                .required("Champ obligatoire"),
            echeanceTemps: Yup.string()
                .required("Champ obligatoire"),
        }),
        onSubmit: async (values) => {
            try {
                //console.log(values);

                const formData = new FormData();

                formData.append('idVehiculeControleTechnique', "");
                formData.append('idVehicule', values.vehicule.idVehicule);
                formData.append('natureControle', values.natureControle);
                formData.append('numControle', values.numControle);
                formData.append('resultatsControle', values.resultatsControle);
                formData.append('dateControle', values.dateControle);
                formData.append('dateProchainControle', values.dateProchainControle);
                formData.append('uniteIntervalleTemps', values.uniteIntervalleTemps);
                formData.append('echeanceTemps', values.echeanceTemps);
                formData.append('pathImageControle', pathImageControle);
                formData.append('idUtilisateurAction', authData.idUser);

                const response = await axiosInstance.post(VehiculeControleTechnique, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });

                CustomToast("Le dossier a été créé avec succès", 'success');
                navigate('/controletechniques', { replace: true });
            } catch (error) {
                // Handle error
                if (!error?.response) {
                    CustomToast("Aucune réponse du serveur", 'error');
                } else if (error.response?.status === 400 && error.response?.data === "Overlap existing controle") {
                    CustomToast("Overlap existing controle", 'error');
                } else if (error.response?.status === 401) {
                    CustomToast("Non autorisé", 'error');
                } else {
                    CustomToast("Demande échoué", 'error');
                }
                console.log(error);
            }
        }
    });

    useEffect(() => {
        //if (debouncedVehiculeText) {
        getVehicules(debouncedVehiculeText);
        //}
        return () => {
            // Perform any necessary cleanup (e.g., cancel pending requests)
        };
    }, [authData, debouncedVehiculeText]);


    return (
        <section className="vehicles-section">
            <div className="container">
                <form onSubmit={handleSubmit} className="controletech-form">
                    <div className="">
                        <div className="grid grid--2-cols">
                            <div className="form-sec">
                                <div className="retour" id="retour-controletech">
                                    <Link to="/controletechniques" style={{ textDecoration: "none" }}>
                                        <div className="arrow-back" id="arrow-back-affectation-form">
                                            <span className="material-symbols-outlined" id="arrow">arrow_back</span>
                                            <h3 className="heading-primary"></h3>
                                        </div>
                                    </Link>
                                </div>
                                <div className="form-details">
                                    <div className="detail-form" id="identification-form">
                                        <h3 className="heading-primary"></h3>
                                        <div className="col">
                                            <div className="grp">
                                                <div className="gp">
                                                    <label >Véhicule<span className='requiredField'>*</span></label>
                                                    <Select
                                                        id="vehicule"
                                                        name="vehicule"
                                                        value={values.vehicule}
                                                        onChange={(option) => { setFieldValue('vehicule', option); }}
                                                        options={vehicules}
                                                        onInputChange={(inputValue) => {
                                                            // Update the entered text
                                                            setEnteredVehicleText(inputValue);
                                                        }}
                                                        getOptionLabel={(option) => option.marque + '[' + option.modele + '] ' + option.immatriculation}
                                                        getOptionValue={(option) => option.idVehicule}
                                                        isClearable
                                                        styles={{
                                                            container: (provided, state) => ({
                                                                ...provided,
                                                                boxShadow: state.isFocused ? '0px 0px 5px 0px #65458d' : errors.vehicule ? 'red' : '#BEBEBE',
                                                            }),
                                                            control: (baseStyles, state) => ({
                                                                ...baseStyles,
                                                                minHeight: '19px',
                                                                outline: '0!important',
                                                                borderRadius: '0.5rem',
                                                                borderWidth: '1px',
                                                                fontSize: '1rem',
                                                                height: '3.1rem',
                                                            }),
                                                            option: (provided, state) => ({
                                                                ...provided,
                                                                fontSize: '1rem'
                                                            }),
                                                            singleValue: (provided, state) => ({
                                                                ...provided,
                                                                fontSize: '1rem',
                                                                margin: '0',
                                                            }),
                                                            valueContainer: (provided, state) => ({
                                                                ...provided,
                                                                alignItems: 'start',
                                                                fontSize: '1rem'
                                                            }),
                                                        }}
                                                    />
                                                    {touched.vehicule && errors.vehicule && (
                                                        <div className="invalid-feedback">{errors.vehicule}</div>
                                                    )}

                                                </div>
                                            </div>
                                            <div className="grp">
                                                <div className="gp">
                                                    <label >Nature du contrôle</label>
                                                    <input
                                                        id="natureControle"
                                                        name="natureControle"
                                                        type="text"
                                                        value={values.natureControle}
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                                <div className="gp">
                                                    <label >N° du contrôle</label>
                                                    <input
                                                        id="numControle"
                                                        name="numControle"
                                                        type="text"
                                                        value={values.numControle}
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                            </div>
                                            <div className="grp">
                                                <div className="gp">
                                                    <label >Date du contrôle<span className='requiredField'>*</span></label>
                                                    <input
                                                        type="date"
                                                        id="dateControle"
                                                        name="dateControle"
                                                        style={(touched.dateControle && errors.dateControle) ? { borderColor: "red" } : null}
                                                        placeholder=""
                                                        defaultValue={values.dateControle}
                                                        onChange={handleChange}
                                                    />
                                                    {touched.dateControle && errors.dateControle ? (
                                                        <div className="invalid-feedback">{errors.dateControle}</div>
                                                    ) : null}
                                                </div>
                                                <div className="gp">
                                                    <label >Date de prochain contrôle<span className='requiredField'>*</span></label>
                                                    <input
                                                        type="date"
                                                        id="dateProchainControle"
                                                        name="dateProchainControle"
                                                        style={(touched.dateProchainControle && errors.dateProchainControle) ? { borderColor: "red" } : null}
                                                        placeholder=""
                                                        defaultValue={values.dateProchainControle}
                                                        onChange={handleChange}
                                                    />
                                                    {touched.dateProchainControle && errors.dateProchainControle ? (
                                                        <div className="invalid-feedback">{errors.dateProchainControle}</div>
                                                    ) : null}
                                                </div>
                                            </div>
                                            <div className="grp">
                                                <div className="gp" title="Le temps à l'avance pendant lequel vous considérez le rappel comme étant '' à échéance prochaine''">
                                                    <label >Seuil d'échéance prochaine<span className='requiredField'>*</span></label>
                                                    <input
                                                        type="number"
                                                        id="echeanceTemps"
                                                        name="echeanceTemps"
                                                        style={(touched.echeanceTemps && errors.echeanceTemps) ? { borderColor: "red" } : null}
                                                        placeholder=""
                                                        defaultValue={values.echeanceTemps}
                                                        onChange={handleChange}
                                                    />
                                                    {touched.echeanceTemps && errors.echeanceTemps ? (
                                                        <div className="invalid-feedback">{errors.echeanceTemps}</div>
                                                    ) : null}
                                                </div>
                                                <div className="gp">
                                                    <select
                                                        id="uniteIntervalleTemps"
                                                        name="uniteIntervalleTemps"
                                                        value={values.uniteIntervalleTemps}
                                                        style={(touched.uniteIntervalleTemps && errors.uniteIntervalleTemps) ? { borderColor: "red" } : null}
                                                        onChange={handleChange}
                                                    >
                                                        <option value="jour" defaultValue={true}>Jour(s)</option>
                                                        <option value="mois">Mois</option>
                                                    </select>
                                                    {touched.uniteIntervalleTemps && errors.uniteIntervalleTemps ? (
                                                        <div className="invalid-feedback">{errors.uniteIntervalleTemps}</div>
                                                    ) : null}
                                                </div>
                                            </div>
                                            <div className="grp">
                                                <div className="gp">
                                                    <label >Document</label>
                                                    <label className="upload-file">
                                                        upload file
                                                        <input
                                                            ref={pathImageControleRef}
                                                            id="pathImageControle"
                                                            name="pathImageControle"
                                                            type="file"
                                                            onChange={handlePathImageControleChange}
                                                        />
                                                    </label>
                                                    {pathImageControle && <span style={{ fontSize: "10px" }}>{getImageFileName(pathImageControle)}
                                                        <span className="material-symbols-outlined" onClick={(e) => { setPathImageControle(null); if (pathImageControleRef.current) { pathImageControleRef.current.value = ''; } }}>delete</span>
                                                    </span>}
                                                </div>
                                            </div>
                                            <div className="grp">
                                                <div className="gp">
                                                    <label >Résultats du contrôle</label>
                                                    <textarea
                                                        id="resultatsControle"
                                                        name="resultatsControle"
                                                        type="text"
                                                        value={values.resultatsControle}
                                                        onChange={handleChange}
                                                        rows={4}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="submit-container">
                                    <button type="submit" className="submit-btn">Enregistrer</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </section>
    );
}

export default AjouterControle;
