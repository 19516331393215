import axios from 'axios';

// Base URL for API PARC
//const BASE_URL = 'http://localhost:8080/';
const BASE_URL = process.env.REACT_APP_BASE_URL;

//const API_KEY = 'Jto0pqDJ1FYjuTx_R8YtoJTnWtMwW*eU';
const API_KEY = process.env.REACT_APP_API_KEY;

const BASE_URL_API = BASE_URL+'api/';

const IMAGE_URL = BASE_URL+'images/';

// Create an instance of Axios with the base URL
export const axiosInstance = axios.create({
    baseURL: BASE_URL_API,
  });

// Add request interceptor
axiosInstance.interceptors.request.use((config) => {
  // Add JWT token to the request header from localStorage
  const authData = JSON.parse(localStorage.getItem('authData'));
  const jwtToken = authData ? authData.token : null;
  if (jwtToken) {
    config.headers['Authorization'] = `Bearer ${jwtToken}`;
  }

  // Add API key to the request header
  config.headers['X-Api-Key'] = API_KEY;
  //Implement api key expiration

  return config;
});

export {BASE_URL};

//API endpoints

export const CategoriePermisConduire=BASE_URL_API+'CategoriePermisConduire';
export const Client=BASE_URL_API+'Client';
export const Commune=BASE_URL_API+'Commune';
export const MyDashboard=BASE_URL_API+'Dashboard';
export const Ecran=BASE_URL_API+'Ecran';
export const Employee=BASE_URL_API+'Employee';
export const Fournisseur=BASE_URL_API+'Fournisseur';
export const Groupe=BASE_URL_API+'Groupe';
export const Marque=BASE_URL_API+'Marque';
export const Modele=BASE_URL_API+'Modele';
export const ModeleInspection=BASE_URL_API+'ModeleInspection';
export const ModePaiement=BASE_URL_API+'ModePaiement';
export const ParametresApplication=BASE_URL_API+'ParametresApplication';
export const Parc=BASE_URL_API+'Parc';
export const Permissions=BASE_URL_API+'Permissions';
export const Profil=BASE_URL_API+'Profil';
export const Proprietaire=BASE_URL_API+'Proprietaire';
export const TacheService=BASE_URL_API+'TacheService';
export const TypeAssuranceVehicule=BASE_URL_API+'TypeAssuranceVehicule';
export const TypeDepense=BASE_URL_API+'TypeDepense';
export const TypePieceIdentite=BASE_URL_API+'TypePieceIdentite';
export const TypeRenouvellementEmployee=BASE_URL_API+'TypeRenouvellementEmployee';
export const TypeRenouvellementVehicule=BASE_URL_API+'TypeRenouvellementVehicule';
export const UniteMesure=BASE_URL_API+'UniteMesure';
export const UserAuthentication=BASE_URL_API+'UserAuthentication';
export const VehiculeAffectation=BASE_URL_API+'VehiculeAffectation';
export const VehiculeAssurance=BASE_URL_API+'VehiculeAssurance';
export const VehiculeCarburant=BASE_URL_API+'VehiculeCarburant';
export const VehiculeCompteur=BASE_URL_API+'VehiculeCompteur';
export const Vehicule=BASE_URL_API+'Vehicule';
export const VehiculeDepense=BASE_URL_API+'VehiculeDepense';
export const VehiculeEntreeService=BASE_URL_API+'VehiculeEntreeService';
export const VehiculeInspection=BASE_URL_API+'VehiculeInspection';
export const VehiculeOrdreTravail=BASE_URL_API+'VehiculeOrdreTravail';
export const VehiculeProbleme=BASE_URL_API+'VehiculeProbleme';
export const VehiculeServiceRappelle=BASE_URL_API+'VehiculeServiceRappelle';
export const VehiculeTypeCarburant=BASE_URL_API+'VehiculeTypeCarburant';
export const VehiculeType=BASE_URL_API+'VehiculeType';
export const Wilaya=BASE_URL_API+'Wilaya';
export const VehiculeControleTechnique=BASE_URL_API+'VehiculeControleTechnique';

//To display images ressources
export const AssuranceImg=IMAGE_URL+'Assurance/';
export const ControleTechniqueImg=IMAGE_URL+'ControleTechnique/';
export const DepenseImg=IMAGE_URL+'Depense/';
export const EmplyeeImg=IMAGE_URL+'Emplyee/';
export const EntreeCarburantImg=IMAGE_URL+'EntreeCarburant/';
export const EntreeCompteurImg=IMAGE_URL+'EntreeCompteur/';
export const EntreeServiceImg=IMAGE_URL+'EntreeService/';
export const FournisseurImg=IMAGE_URL+'Fournisseur/';
export const InspectionImg=IMAGE_URL+'Inspection/';
export const OrdreTravailImg=IMAGE_URL+'OrdreTravail/';
export const ProprietaireImg=IMAGE_URL+'Proprietaire/';
export const RappelleServiceImg=IMAGE_URL+'RappelleService/';
export const VehiculeImg=IMAGE_URL+'Vehicule/';
export const VehiculeCarteGriseImg=IMAGE_URL+'VehiculeCarteGrise/';
export const ProblemeImg=IMAGE_URL+'Probleme/';

//WebSockets-SignalR
export const getJwtToken=()=> {
  // Implement your logic to retrieve the JWT token
  const authData = JSON.parse(localStorage.getItem('authData'));
  return (authData ? authData.token : null);
}

export const DashboardHub=BASE_URL+'dashboardHub';