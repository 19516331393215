import React, { useEffect, useState, useRef, useContext } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import moment from 'moment/moment';
import { CustomToast } from '../../components/Toast/CustomToast';
import {
    axiosInstance, VehiculeType, VehiculeTypeCarburant, Marque, Modele, Proprietaire, Parc, Groupe,
    Fournisseur, TypeAssuranceVehicule, Vehicule, VehiculeAssurance, VehiculeControleTechnique, Wilaya
} from '../../services/api';
import { AuthContext } from '../../contexts/AuthContext';

const ModifierVehicule = () => {
    const { authData } = useContext(AuthContext);
    const navigate = useNavigate();
    const { id } = useParams();//idVehicule

    const [isLoaded, setIsLoaded] = useState();
    const [identification, setIdentification] = useState(true);
    const [cycle, setCycle] = useState(false);
    const [finance, setFinance] = useState(false);
    const [caractirstique, setCaractirstique] = useState(false);
    const [document, setDocument] = useState(false);

    const [identificationClass, setIdentificationClass] = useState("line act");
    const [cycleClass, setCycleClass] = useState("line");
    const [financeClass, setFinanceClass] = useState("line");
    const [caractirstiqueClass, setCaractirstiqueClass] = useState("line");
    const [documentClass, setDocumentClass] = useState("line");

    //Data to load
    const [typesVehicule, setTypesVehicule] = useState([]);
    const [typesCarburant, setTypesCarburant] = useState([]);
    const [marques, setMarques] = useState([]);
    const [modeles, setModeles] = useState([]);
    const [groupes, setGroupes] = useState([]);
    const [proprietaires, setProprietaires] = useState([]);
    const [parcs, setParcs] = useState([]);
    const [wilayas, setWilayas] = useState([]);
    const [fournisseurs, setFournisseurs] = useState([]);
    const [typesAssurance, setTypesAssurance] = useState([]);

    //Vehicule data
    const [pathImageVehicle, setPathImageVehicle] = useState(null);
    const [pathImageVehicleName, setPathImageVehicleName] = useState('');
    const [pathImageCarteGrise, setPathImageCarteGrise] = useState(null);
    const [pathImageCarteGriseName, setPathImageCarteGriseName] = useState('');
    const [pathImageAssurance, setPathImageAssurance] = useState(null);
    const [pathImageAssuranceName, setPathImageAssuranceName] = useState('');
    const [pathImageControle, setPathImageControle] = useState(null);
    const [pathImageControleName, setPathImageControleName] = useState('');

    const [siPret, setSipret] = useState(false);
    const [siLocation, setSiLocation] = useState(false);

    const pathImageVehicleRef = useRef(null);
    const pathImageCarteGriseRef = useRef(null);
    const pathImageAssuranceRef = useRef(null);
    const pathImageControleRef = useRef(null);

    const showIdentification = () => {
        setIdentification(true);
        setCycle(false);
        setFinance(false);
        setCaractirstique(false);
        setDocument(false);

        setIdentificationClass("line act");
        setCycleClass("line");
        setFinanceClass("line");
        setCaractirstiqueClass("line");
        setDocumentClass("line");
    };

    const showToCycle = () => {
        setIdentification(false);
        setCycle(true);
        setFinance(false);
        setCaractirstique(false);
        setDocument(false);

        setIdentificationClass("line");
        setCycleClass("line act");
        setFinanceClass("line");
        setCaractirstiqueClass("line");
        setDocumentClass("line");
    };

    const showFinance = () => {
        setIdentification(false);
        setCycle(false);
        setFinance(true);
        setCaractirstique(false);
        setDocument(false);

        setIdentificationClass("line");
        setCycleClass("line");
        setFinanceClass("line act");
        setCaractirstiqueClass("line");
        setDocumentClass("line");
    };

    const showCaractirstique = () => {
        setIdentification(false);
        setCycle(false);
        setFinance(false);
        setCaractirstique(true);
        setDocument(false);

        setIdentificationClass("line");
        setCycleClass("line");
        setFinanceClass("line");
        setCaractirstiqueClass("line act");
        setDocumentClass("line");
    };

    const showDocument = () => {
        setIdentification(false);
        setCycle(false);
        setFinance(false);
        setCaractirstique(false);
        setDocument(true);

        setIdentificationClass("line");
        setCycleClass("line");
        setFinanceClass("line");
        setCaractirstiqueClass("line");
        setDocumentClass("line act");
    };

    const { handleChange, values, handleSubmit, touched, errors, setValues } = useFormik({
        initialValues: {
            nom: '',
            immatriculation: '',
            numChassis: '',
            anneeFabrication: '',
            kilometrageActuel: '',
            idVehiculeType: '',
            idTypeCarburant: '',
            idMarque: '',
            idModele: "",
            idGroupe: "",
            service: "",
            wilayaPlacement: '',
            idProprietaire: "",
            typeProprietee: "possédé",
            idParc: "",
            couleur: '',
            statut: 'disponible',
            description: '',
            dateMiseEnService: '',
            kilometrageMiseEnService: '',
            dateFinService: '',
            kilometrageFinService: '',
            dureeDeVieEstimeMois: '',
            dureeDeVieEstimeDistance: '',
            idFournisseurAchat: '',
            dateAchat: '',
            prixAchat: '',
            kilometrageAchat: '',
            remarquesAchat: '',
            dateExpirationGarantie: '',
            kilometrageGarantie: '',
            typeFinancement: 'aucun',
            idFournisseurPret: '',
            dateDebutPret: '',
            dateFinPret: '',
            prixPret: '',
            remarquesPret: '',
            idFournisseurLocation: '',
            dateDebutLocation: '',
            dateFinLocation: '',
            prixLocation: '',
            numeroLocation: '',
            remarquesLocation: '',
            longueur: '',
            largeur: '',
            hauteur: '',
            poidsVide: '',
            poidsNominalBrut: '',
            poidsRemorquage: '',
            mouteur: '',
            transmission: '',
            capaciteMaxHuile: '',
            capaciteMaxCarburant: '',
            systemeFreinage: '',
            diametreRoueAvant: '',
            typePneuAvant: '',
            diametreRoueArriere: '',
            typePneuArriere: '',
            nbrPlaces: '',
            nbrPortes: '',
            equipements: '',
            idVehiculeAssurance: "",
            dateDebutAssurance: "",
            dateFinAssurance: "",
            idTypeAssurance: "",
            emailAssurance: "",
            numeroPolice: '',
            montantAssurance: '',
            couvertureAssurance: '',
            compagnieAssurance: '',
            adresseAssurance: '',
            numTelAssurance: '',
            uniteIntervalleTempsAssurance: 'jour',
            echeanceTempsAssurance: '',
            idVehiculeControleTechnique: '',
            natureControle: '',
            numControle: '',
            resultatsControle: '',
            dateControle: '',
            dateProchainControle: '',
            uniteIntervalleTempsControle: 'jour',
            echeanceTempsControle: '',
        },
        validationSchema: Yup.object({
            nom: Yup.string()
                .required("Champ obligatoire"),
            immatriculation: Yup.string()
                .required("Champ obligatoire"),
            numChassis: Yup.string()
                .required("Champ obligatoire"),
            anneeFabrication: Yup.string()
                .required("Champ obligatoire"),
            idVehiculeType: Yup.string()
                .required("Champ obligatoire"),
            idTypeCarburant: Yup.string()
                .required("Champ obligatoire"),
            idMarque: Yup.string()
                .required("Champ obligatoire"),
            idModele: Yup.string()
                .required("Champ obligatoire"),
            service: Yup.string()
                .required("Champ obligatoire"),
            wilayaPlacement: Yup.string()
                .required("Champ obligatoire"),
            idProprietaire: Yup.string()
                .required("Champ obligatoire"),
            idParc: Yup.string()
                .required("Champ obligatoire"),
            dateDebutAssurance: Yup.string()
                .required("Champ obligatoire"),
            dateFinAssurance: Yup.string()
                .required("Champ obligatoire"),
            idTypeAssurance: Yup.string()
                .required("Champ obligatoire"),
            emailAssurance: Yup.string()
                .email("Le champ doit contenir un e-mail valide")
                .max(255, "Le champ doit contenir au maximum 255 caractères"),
            echeanceTempsAssurance: Yup.string()
                .required("Champ obligatoire"),
            dateControle: Yup.string()
                .required("Champ obligatoire"),
            dateProchainControle: Yup.string()
                .required("Champ obligatoire"),
            echeanceTempsControle: Yup.string()
                .required("Champ obligatoire"),
        }),
        onSubmit: async (values) => {
            try {
                //console.log(values)
                const formData = new FormData();
                formData.append('idVehicule', id);
                formData.append('nom', values.nom);
                formData.append('immatriculation', values.immatriculation);
                formData.append('numChassis', values.numChassis);
                formData.append('statut', values.statut);
                formData.append('idProprietaire', values.idProprietaire);
                formData.append('idGroupe', values.idGroupe);
                formData.append('typeProprietee', values.typeProprietee);

                formData.append('service', values.service);
                formData.append('wilayaPlacement', values.wilayaPlacement);
                formData.append('idMarque', values.idMarque);
                formData.append('idModele', values.idModele);
                formData.append('idVehiculeType', values.idVehiculeType);
                formData.append('couleur', values.couleur);
                formData.append('pathImageVehicle', pathImageVehicle);
                formData.append('pathImageVehicleName', pathImageVehicleName);

                formData.append('description', null);
                formData.append('idParc', values.idParc);
                formData.append('dateMiseEnService', values.dateMiseEnService);
                formData.append('kilometrageMiseEnService', values.kilometrageMiseEnService);
                formData.append('dateFinService', values.remarquesAchat);
                formData.append('kilometrageFinService', values.kilometrageFinService);

                formData.append('dureeDeVieEstimeMois', values.dureeDeVieEstimeMois);
                formData.append('dureeDeVieEstimeDistance', values.dureeDeVieEstimeDistance);
                formData.append('anneeFabrication', values.anneeFabrication);
                formData.append('idFournisseurAchat', values.idFournisseurAchat);

                formData.append('dateAchat', values.dateAchat);
                formData.append('prixAchat', values.prixAchat);
                formData.append('kilometrageAchat', values.kilometrageAchat);
                formData.append('remarquesAchat', values.remarquesAchat);
                formData.append('typeFinancement', values.typeFinancement);

                formData.append('idFournisseurLocation', values.idFournisseurLocation);
                formData.append('dateDebutLocation', values.dateDebutLocation);
                formData.append('dateFinLocation', values.dateFinLocation);
                formData.append('prixLocation', values.prixLocation);
                formData.append('numeroLocation', values.numeroLocation);

                formData.append('remarquesLocation', values.remarquesLocation);
                formData.append('idFournisseurPret', values.idFournisseurPret);
                formData.append('dateDebutPret', values.dateDebutPret);
                formData.append('dateFinPret', values.dateFinPret);
                formData.append('prixPret', values.prixPret);
                formData.append('remarquesPret', values.remarquesPret);

                formData.append('dateExpirationGarantie', values.dateExpirationGarantie);
                formData.append('kilometrageGarantie', values.kilometrageGarantie);

                formData.append('pathImageCarteGrise', pathImageCarteGrise);
                formData.append('pathImageCarteGriseName', pathImageCarteGriseName);
                //formData.append('dateExpirationVignette', values.dateExpirationVignette);
                formData.append('largeur', values.largeur);
                formData.append('hauteur', values.hauteur);
                formData.append('longueur', values.longueur);
                formData.append('nbrPlaces', values.nbrPlaces);
                formData.append('nbrPortes', values.nbrPortes);
                formData.append('equipements', values.equipements);

                formData.append('poidsVide', values.poidsVide);
                formData.append('poidsNominalBrut', values.poidsNominalBrut);
                formData.append('poidsRemorquage', values.poidsRemorquage);

                formData.append('mouteur', values.mouteur);
                formData.append('transmission', values.transmission);

                formData.append('systemeFreinage', values.systemeFreinage);
                formData.append('diametreRoueAvant', values.diametreRoueAvant);
                formData.append('typePneuAvant', '');
                formData.append('diametreRoueArriere', values.diametreRoueArriere);
                formData.append('typePneuArriere', '');

                formData.append('idTypeCarburant', values.idTypeCarburant);
                formData.append('capaciteMaxCarburant', values.capaciteMaxCarburant);
                formData.append('capaciteMaxHuile', values.capaciteMaxHuile);
                //formData.append('kilometrageActuel', values.kilometrageActuel);

                formData.append('assurance.idVehiculeAssurance', values.idVehiculeAssurance || '');
                formData.append('assurance.numeroPolice', values.numeroPolice);
                formData.append('assurance.idTypeAssurance', values.idTypeAssurance);
                formData.append('assurance.dateDebut', values.dateDebutAssurance);
                formData.append('assurance.dateFin', values.dateFinAssurance);

                formData.append('assurance.montant', values.montantAssurance);
                formData.append('assurance.couverture', values.couvertureAssurance);
                formData.append('assurance.assureur.compagnie', values.compagnieAssurance);
                formData.append('assurance.assureur.adresse', values.adresseAssurance);
                formData.append('assurance.assureur.numTel', values.numTelAssurance);
                formData.append('assurance.assureur.email', values.emailAssurance);

                formData.append('assurance.uniteIntervalleTemps', values.uniteIntervalleTempsAssurance);
                formData.append('assurance.echeanceTemps', values.echeanceTempsAssurance);
                formData.append('assurance.pathImageAssurance', pathImageAssurance);
                formData.append('assurance.pathImageAssuranceName', pathImageAssuranceName);

                formData.append('controleTechnique.idVehiculeControleTechnique', values.idVehiculeControleTechnique || '');
                formData.append('controleTechnique.natureControle', values.natureControle);
                formData.append('controleTechnique.numControle', values.numControle);
                formData.append('controleTechnique.resultatsControle', values.resultatsControle);
                formData.append('controleTechnique.dateControle', values.dateControle);
                formData.append('controleTechnique.dateProchainControle', values.dateProchainControle);
                formData.append('controleTechnique.uniteIntervalleTemps', values.uniteIntervalleTempsControle);
                formData.append('controleTechnique.echeanceTemps', values.echeanceTempsControle);
                formData.append('controleTechnique.pathImageControle', pathImageControle);
                formData.append('controleTechnique.pathImageControleName', pathImageControleName);

                formData.append('idUtilisateurAction', authData.idUser);
                await axiosInstance.put(Vehicule, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });
                CustomToast('Le véhicule a été mis à jour avec succès', 'success');
                navigate('/listvehicules', { replace: true });
            } catch (error) {
                // Handle error
                if (!error?.response) {
                    CustomToast("Aucune réponse du serveur", 'error');
                } else if (error.response?.status === 400 && error.response?.data === "Another vehicle exists with the same registration number and chassis number") {
                    CustomToast("Un autre véhicule existe avec le même numéro d'immatriculation et numéro de châssis", 'error');
                } else if (error.response?.status === 400 && error.response?.data === "Another vehicle exists with the same registration number") {
                    CustomToast("Un autre véhicule existe avec le même numéro d'immatriculation", 'error');
                } else if (error.response?.status === 400 && error.response?.data === "Another vehicle exists with the same name chassis number") {
                    CustomToast("Un autre véhicule existe avec le même numéro de châssis", 'error');
                } else if (error.response?.status === 401) {
                    CustomToast("Non autorisé", 'error');
                } else {
                    CustomToast("Demande échoué", 'error');
                }
                console.log(error);
                //CustomToast(error.message, 'error');
            }
        }
    });

    //Load data

    const getTypesVehicule = async () => {
        try {
            const response = await axiosInstance.get(`${VehiculeType}`);
            const resp = await response.data;
            setTypesVehicule(resp);
        }
        catch (err) {
            console.log(err);
        }
    }

    const getTypesCarburant = async () => {
        try {
            const response = await axiosInstance.get(`${VehiculeTypeCarburant}`);
            const resp = await response.data;
            setTypesCarburant(resp);
        }
        catch (err) {
            console.log(err);
        }
    }

    const getMarques = async () => {
        try {
            const response = await axiosInstance.get(`${Marque}`);
            const resp = await response.data;
            setMarques(resp);
        }
        catch (err) {
            console.log(err);
        }
    }

    const getModeles = async (idMarque) => {
        try {
            const response = await axiosInstance.get(`${Modele}/SearchModeles/${idMarque}`);
            const resp = await response.data;
            setModeles(resp);
        }
        catch (err) {
            console.log(err);
        }
    }

    const getGroupes = async () => {
        try {
            const response = await axiosInstance.get(`${Groupe}`);
            const resp = await response.data;
            setGroupes(resp);
        }
        catch (err) {
            console.log(err);
        }
    }

    const getProprietaires = async () => {
        try {
            const queryParams = new URLSearchParams({
            });

            if (values.typeProprietee === "freelance") {
                queryParams.append("typeProprietaire", "freelancer");
            } else {
                queryParams.append("typeProprietaire", "entreprise,location");
            }
            const response = await axiosInstance.get(`${Proprietaire}/GetProprietaireByType?${queryParams}`);
            const resp = await response.data;
            setProprietaires(resp);
        }
        catch (err) {
            console.log(err);
        }
    }

    // const getParcs = async (idProprietaire) => {
    //     try {
    //         const response = await axiosInstance.get(`${Parc}/GetParcByIdProprietaire/${idProprietaire}`);
    //         const resp = await response.data;
    //         setParcs(resp);
    //     }
    //     catch (err) {
    //         console.log(err);
    //     }
    // }

    const getParcs = async () => {
        try {
            const response = await axiosInstance.get(`${Parc}`);
            const resp = await response.data;
            setParcs(resp);
        }
        catch (err) {
            console.log(err);
        }
    }

    const getFournisseurs = async () => {
        try {
            const queryParams = new URLSearchParams({
                vehicule: true
            });

            const response = await axiosInstance.get(`${Fournisseur}/GetFournisseurType?${queryParams}`);
            const resp = await response.data;
            setFournisseurs(resp);
        }
        catch (err) {
            console.log(err);
        }
    }

    const getTypesAssurance = async () => {
        try {
            const response = await axiosInstance.get(`${TypeAssuranceVehicule}`);
            const resp = await response.data;
            setTypesAssurance(resp);
        }
        catch (err) {
            console.log(err);
        }
    }

    const getVehicule = async (idVehicule) => {
        try {
            setIsLoaded(false);
            const response = await axiosInstance.get(`${Vehicule}/GetById/${idVehicule}`);
            const resp = await response.data;
            
            setValues(prevValues => ({
                ...prevValues,
                nom: resp.nom,
                immatriculation: resp.immatriculation,
                numChassis: resp.numChassis,
                anneeFabrication: resp.anneeFabrication,
                kilometrageActuel: resp.kilometrageActuel || '0',
                idVehiculeType: resp.idVehiculeType,
                idTypeCarburant: resp.typeCarburant.idTypeCarburant,
                idMarque: resp.idMarque,
                idModele: resp.idModele,
                idGroupe: resp.idGroupe,
                service: resp.service || '',
                wilayaPlacement: resp.wilayaPlacement || '',
                statut: resp.statut || 'disponible',
                idProprietaire: resp.idProprietaire,
                typeProprietee: resp.typeProprietee || 'possédé',
                idParc: resp.idParc,
                couleur: resp.couleur || '',
                description: resp.description || '',
                dateMiseEnService: resp.dateMiseEnService || '',
                kilometrageMiseEnService: resp.kilometrageMiseEnService || '',
                dateFinService: resp.remarquesAchat || '',
                kilometrageFinService: resp.kilometrageFinService || '',
                dureeDeVieEstimeMois: resp.dureeDeVieEstimeMois || '',
                dureeDeVieEstimeDistance: resp.dureeDeVieEstimeDistance || '',
                idFournisseurAchat: resp.idFournisseurAchat || '',
                dateAchat: moment(resp.dateAchat).isValid() ? moment(resp.dateAchat).format('YYYY-MM-DD') : '' || '',
                prixAchat: moment(resp.prixAchat).isValid() ? moment(resp.prixAchat).format('YYYY-MM-DD') : '' || '',
                kilometrageAchat: resp.kilometrageAchat || '',
                remarquesAchat: resp.remarquesAchat || '',
                typeFinancement: resp.typeFinancement,
                idFournisseurLocation: resp.idFournisseurLocation || '',
                dateDebutLocation: moment(resp.dateDebutLocation).isValid() ? moment(resp.dateDebutLocation).format('YYYY-MM-DD') : '' || '',
                dateFinLocation: moment(resp.dateFinLocation).isValid() ? moment(resp.dateFinLocation).format('YYYY-MM-DD') : '' || '',
                prixLocation: resp.prixLocation || '',
                numeroLocation: resp.numeroLocation || '',
                remarquesLocation: resp.remarquesLocation || '',
                idFournisseurPret: resp.idFournisseurPret || '',
                dateDebutPret: moment(resp.dateDebutPret).isValid() ? moment(resp.dateDebutPret).format('YYYY-MM-DD') : '' || '',
                dateFinPret: moment(resp.dateFinPret).isValid() ? moment(resp.dateFinPret).format('YYYY-MM-DD') : '' || '',
                prixPret: resp.prixPret || '',
                remarquesPret: resp.remarquesPret || '',
                dateExpirationGarantie: moment(resp.dateExpirationGarantie).isValid() ? moment(resp.dateExpirationGarantie).format('YYYY-MM-DD') : '' || '',
                kilometrageGarantie: resp.kilometrageGarantie || '',
                largeur: resp.largeur || '',
                hauteur: resp.hauteur || '',
                longueur: resp.longueur || '',
                nbrPlaces: resp.nbrPlaces || '',
                nbrPortes: resp.nbrPortes || '',
                equipements: resp.equipements || '',
                poidsVide: resp.poidsVide || '',
                poidsNominalBrut: resp.poidsNominalBrut || '',
                poidsRemorquage: resp.poidsRemorquage || '',
                mouteur: resp.mouteur || '',
                transmission: resp.transmission || '',
                systemeFreinage: resp.systemeFreinage || '',
                diametreRoueAvant: resp.diametreRoueAvant || '',
                typePneuAvant: resp.typePneuAvant || '',
                diametreRoueArriere: resp.diametreRoueArriere || '',
                typePneuArriere: resp.typePneuArriere || '',
                capaciteMaxCarburant: resp.typeCarburant.capaciteMaxCarburant || '',
                capaciteMaxHuile: resp.capaciteMaxHuile || ''
            }));
            setPathImageVehicleName(resp.pathImageVehicle || '');
            setPathImageCarteGriseName(resp.pathImageCarteGrise || '');
            setIsLoaded(true);
        }
        catch (error) {
            // Handle error
            if (!error?.response) {
                CustomToast("Aucune réponse du serveur", 'error');
            } else if (error.response?.status === 404 && error.response?.data === "Vehicle not found") {
                CustomToast("Véhicule introuvable", 'error');
            } else if (error.response?.status === 404) {
                CustomToast("Véhicule introuvable", 'error');
            } else if (error.response?.status === 401) {
                CustomToast("Non autorisé", 'error');
            } else if (error.response?.status === 400) {
                CustomToast("Demande incorrecte", 'error');
            } else {
                CustomToast("Demande échoué", 'error');
            }
            console.log(error);
            setIsLoaded(true);
            //navigate("/listevehicules", { replace: true });

        }
    }

    const getVehiculeAssurance = async (idVehicule) => {
        try {
            const response = await axiosInstance.get(`${VehiculeAssurance}/GetAssuranceVehiculeByIdVehicule/${idVehicule}`);
            const resp = await response.data;

            setValues(prevValues => ({
                ...prevValues,
                idVehiculeAssurance: resp.idVehiculeAssurance || '',
                numeroPolice: resp.numeroPolice || '',
                idTypeAssurance: resp.idTypeAssurance || '',
                dateDebutAssurance: moment(resp.dateDebut).isValid() ? moment(resp.dateDebut).format('YYYY-MM-DD') : '' || '',
                dateFinAssurance: moment(resp.dateFin).isValid() ? moment(resp.dateFin).format('YYYY-MM-DD') : '' || '',
                montantAssurance: resp.montant || '',
                couvertureAssurance: resp.couverture || '',
                compagnieAssurance: resp.assureur?.compagnie || '',
                adresseAssurance: resp.assureur?.adresse || '',
                numTelAssurance: resp.assureur?.numTel || '',
                emailAssurance: resp.assureur?.email || '',
                uniteIntervalleTempsAssurance: resp.uniteIntervalleTemps || 'jour',
                echeanceTempsAssurance: resp.echeanceTemps || '',
            }));

            setPathImageAssuranceName(resp.pathImageAssurance || '');

            //console.log(resp);
        }
        catch (error) {
            // Handle error
            if (!error?.response) {
                CustomToast("Aucune réponse du serveur", 'error');
            } else if (error.response?.status === 404) {
                CustomToast("Assurance véhicule introuvable ou expirée", 'error');
            } else if (error.response?.status === 401) {
                CustomToast("Non autorisé", 'error');
            } else if (error.response?.status === 400) {
                CustomToast("Demande incorrecte", 'error');
            } else {
                CustomToast("Demande échoué", 'error');
            }
            console.log(error);
            //navigate("/listevehicules", { replace: true });

        }
    }

    const getVehiculeControleTechnique = async (idVehicule) => {
        try {
            const response = await axiosInstance.get(`${VehiculeControleTechnique}/GetControleVehiculeByIdVehicule/${idVehicule}`);
            const resp = await response.data;

            setValues(prevValues => ({
                ...prevValues,
                idVehiculeControleTechnique: resp.idVehiculeControleTechnique || '',
                natureControle: resp.natureControle || '',
                numControle: resp.numControle || '',
                resultatsControle: resp.resultatsControle || '',
                dateControle: moment(resp.dateControle).isValid() ? moment(resp.dateControle).format('YYYY-MM-DD') : '' || '',
                dateProchainControle: moment(resp.dateProchainControle).isValid() ? moment(resp.dateProchainControle).format('YYYY-MM-DD') : '' || '',
                uniteIntervalleTempsControle: resp.uniteIntervalleTemps || 'jour',
                echeanceTempsControle: resp.echeanceTemps || ''
            }));
            setPathImageControleName(resp.pathImageControle || '');
        }
        catch (error) {
            // Handle error
            if (!error?.response) {
                CustomToast("Aucune réponse du serveur", 'error');
            } else if (error.response?.status === 404) {
                CustomToast("Contrôle technique introuvable ou expirée", 'error');
            } else if (error.response?.status === 401) {
                CustomToast("Non autorisé", 'error');
            } else if (error.response?.status === 400) {
                CustomToast("Demande incorrecte", 'error');
            } else {
                CustomToast("Demande échoué", 'error');
            }
            console.log(error);
            //navigate("/listevehicules", { replace: true });

        }
    }

    const getWilayas = async () => {
        try {
            const response = await axiosInstance.get(`${Wilaya}`);
            const resp = await response.data;
            setWilayas(resp);
        }
        catch (err) {
            console.log(err);
        }
    }

    //Vehicule documents
    const handlePathImageVehicleChange = (e) => {
        setPathImageVehicle(e.target.files[0]);
        setPathImageVehicleName('');
    };

    const handlePathImageCarteGriseChange = (e) => {
        setPathImageCarteGrise(e.target.files[0]);
        setPathImageCarteGriseName('');
    };

    const handlePathImageAssuranceChange = (e) => {
        setPathImageAssurance(e.target.files[0]);
        setPathImageAssuranceName('');
    };

    const handlePathImageControleChange = (e) => {
        setPathImageControle(e.target.files[0]);
        setPathImageControleName('');
    };

    const getImageFileName = (file) => {
        return file ? file.name : 'No file selected';
    };

    useEffect(() => {
        //Load type de véhicule
        getTypesVehicule();
        //Load type de carburant
        getTypesCarburant();
        //Load marque
        getMarques();
        //Load Groupe
        getGroupes();
        //Load Parcs
        getParcs();
        //Load Wilayas
        getWilayas();
        //Load Fournisseurs
        getFournisseurs();
        //Load types d'assurance
        getTypesAssurance();
        //Load véhicule
        getVehicule(id);
        //Load assurance
        //getVehiculeAssurance(id);
        //Load controle technique
        //getVehiculeControleTechnique(id);

        if (values.idMarque !== "") {
            getModeles(values.idMarque);
        }
        return () => {
            // Perform any necessary cleanup (e.g., cancel pending requests)
        };
    }, [id])

    useEffect(() => {

        if (isLoaded || id) {
            getVehiculeAssurance(id);
        }
        return () => {
            // Perform any necessary cleanup (e.g., cancel pending requests)
        };
    }, [id])

    useEffect(() => {

        if (isLoaded || id) {
            getVehiculeControleTechnique(id);
        }
        return () => {
            // Perform any necessary cleanup (e.g., cancel pending requests)
        };
    }, [id])

    useEffect(() => {
        //Load modeles
        if (values.idMarque != "") {
            getModeles(values.idMarque);
        }
        else {
            setModeles([]);
            values.idModele = "";
        }
        //values.idModele = "";
        return () => {
            // Perform any necessary cleanup (e.g., cancel pending requests)
        };
    }, [values.idMarque, authData])

    useEffect(() => {
        getProprietaires();
        return () => {
            // Perform any necessary cleanup (e.g., cancel pending requests)
        };
    }, [authData, values.typeProprietee, authData])

    useEffect(() => {
        //
        if (values.typeFinancement == "pret") {
            setSipret(true);
            setSiLocation(false);
            values.idFournisseurLocation = '';
            values.dateDebutLocation = '';
            values.dateFinLocation = '';
            values.prixLocation = '';
            values.numeroLocation = '';
            values.remarquesLocation = '';
        } else if (values.typeFinancement == "location") {
            setSipret(false);
            setSiLocation(true);
            values.idFournisseurPret = '';
            values.dateDebutPret = '';
            values.dateFinPret = '';
            values.prixPret = '';
            values.remarquesPret = '';
        } else {
            setSipret(false);
            setSiLocation(false);
            values.idFournisseurPret = '';
            values.dateDebutPret = '';
            values.dateFinPret = '';
            values.prixPret = '';
            values.remarquesPret = '';
            values.idFournisseurLocation = '';
            values.dateDebutLocation = '';
            values.dateFinLocation = '';
            values.prixLocation = '';
            values.numeroLocation = '';
            values.remarquesLocation = '';
        }
        return () => {
            // Perform any necessary cleanup (e.g., cancel pending requests)
        };
    }, [values.typeFinancement, authData])

    return (
        <section className="vehicles-section">
            <div className="container">
                <form onSubmit={handleSubmit} className="vehicule-list-form">
                    <div className="">
                        <div className="grid grid--form-cols">
                            <div className="form-sec">
                                <div className="retour" id="vehicule-list-retour">
                                    <Link to="/listvehicules" style={{ textDecoration: "none" }}>
                                        <div className="arrow-back">
                                            <span className="material-symbols-outlined" id="arrow">arrow_back</span>
                                            <h3 className="heading-primary"></h3>
                                        </div>
                                    </Link>
                                </div>
                                {isLoaded && <div className="small-menu-sec">
                                    <div className={identificationClass} id="info-menu" onClick={() => showIdentification()}>
                                        <span className="material-symbols-outlined">info</span>
                                        <h3 className="heading-primary">Détails</h3>
                                        {(errors.nom || errors.matricule || errors.numChassis || errors.idVehiculeType
                                            || errors.idTypeCarburant || errors.anneeFabrication || errors.idMarque || errors.idModele
                                            || errors.statut || errors.service || errors.wilayaPlacement || errors.typeProprietee
                                            || errors.idProprietaire || errors.idParc) && <span className="material-symbols-outlined danger">error</span>}
                                    </div>
                                    <div className={cycleClass} id="cycle-menu" onClick={() => showToCycle()}>
                                        <span className="material-symbols-outlined">cycle</span>
                                        <h3 className="heading-primary">Cycle de vie</h3>
                                        {(errors.kilometrageActuel) && <span className="material-symbols-outlined danger">error</span>}
                                    </div>
                                    <div className={financeClass} id="equalizer-menu" onClick={() => showFinance()}>
                                        <span className="material-symbols-outlined">equalizer</span>
                                        <h3 className="heading-primary">Finance</h3>
                                    </div>
                                    <div className={caractirstiqueClass} id="caractirst-menu" onClick={() => showCaractirstique()}>
                                        <span className="material-symbols-outlined">settings</span>
                                        <h3 className="heading-primary">Caractéristiques</h3>
                                    </div>
                                    <div className={documentClass} id="article-menu" onClick={() => showDocument()}>
                                        <span className="material-symbols-outlined">article</span>
                                        <h3 className="heading-primary">Document</h3>
                                        {(errors.idTypeAssurance || errors.dateDebutAssurance || errors.dateFinAssurance
                                            || errors.echeanceTempsAssurance || errors.dateControle || errors.dateProchainControle
                                            || errors.echeanceTempsControle) && <span className="material-symbols-outlined danger">error</span>}
                                    </div>
                                </div>}
                            </div>

                            {isLoaded && <div className="form-sec">
                                <div className="retour" id="retour-login">
                                    <div className="arrow-back">
                                    </div>
                                    <button type="submit" className="submit-btn">Enregistrer</button>
                                </div>
                                <div className="form-details">
                                    {identification && <div className="detail-form" id="identification-form">
                                        <h3 className="heading-primary">Identification</h3>
                                        <div className="col">
                                            <div className="grp">
                                                <div className="gp" title="Entrez un surnom pour distinguer cet équipement dans FleetGo">
                                                    <label >Nom <span className='requiredField'>*</span></label>
                                                    <input type="text"
                                                        id="nom"
                                                        name="nom"
                                                        style={(touched.nom && errors.nom) ? { borderColor: "red" } : null}
                                                        placeholder=""
                                                        defaultValue={values.nom}
                                                        onChange={handleChange}
                                                    />
                                                    {touched.nom && errors.nom ? (
                                                        <div className="invalid-feedback">{errors.nom}</div>
                                                    ) : null}
                                                </div>
                                                <div className="gp">
                                                    <label >Numéro de châssis<span className='requiredField'>*</span></label>
                                                    <input type="text"
                                                        id="numChassis"
                                                        name="numChassis"
                                                        style={(touched.numChassis && errors.numChassis) ? { borderColor: "red" } : null}
                                                        placeholder=""
                                                        defaultValue={values.numChassis}
                                                        onChange={handleChange}
                                                    />
                                                    {touched.numChassis && errors.numChassis ? (
                                                        <div className="invalid-feedback">{errors.numChassis}</div>
                                                    ) : null}
                                                </div>
                                                <div className="gp">
                                                    <label >Matricule<span className='requiredField'>*</span></label>
                                                    <input type="text"
                                                        id="immatriculation"
                                                        name="immatriculation"
                                                        style={(touched.immatriculation && errors.immatriculation) ? { borderColor: "red" } : null}
                                                        placeholder=""
                                                        defaultValue={values.immatriculation}
                                                        onChange={handleChange}
                                                    />
                                                    {touched.immatriculation && errors.immatriculation ? (
                                                        <div className="invalid-feedback">{errors.immatriculation}</div>
                                                    ) : null}
                                                </div>
                                            </div>
                                            <div className="grp">
                                                <div className="gp">
                                                    <label >Type de véhicule<span className='requiredField'>*</span></label>
                                                    <select
                                                        id="idVehiculeType"
                                                        name="idVehiculeType"
                                                        value={values.idVehiculeType}
                                                        onChange={handleChange}
                                                        style={(touched.idVehiculeType && errors.idVehiculeType) ? { borderColor: "red" } : null}
                                                    >
                                                        <option value="">Veuillez sélectionner</option>
                                                        {typesVehicule.map((typeVehicule) => (
                                                            <option key={typeVehicule.idVehiculeType} value={typeVehicule.idVehiculeType}>{typeVehicule.libelleVehiculeType}</option>
                                                        ))}
                                                    </select>
                                                    {touched.idVehiculeType && errors.idVehiculeType ? (
                                                        <div className="invalid-feedback">{errors.idVehiculeType}</div>
                                                    ) : null}
                                                </div>
                                                <div className="gp">
                                                    <label >Type de carburant<span className='requiredField'>*</span></label>
                                                    <select
                                                        id="idTypeCarburant"
                                                        name="idTypeCarburant"
                                                        value={values.idTypeCarburant}
                                                        onChange={handleChange}
                                                        style={(touched.idTypeCarburant && errors.idTypeCarburant) ? { borderColor: "red" } : null}
                                                    >
                                                        <option value="">Veuillez sélectionner</option>
                                                        {typesCarburant.map((typeCarburant) => (
                                                            <option key={typeCarburant.idTypeCarburant} value={typeCarburant.idTypeCarburant}>{typeCarburant.libelleTypeCarburant}</option>
                                                        ))}
                                                    </select>
                                                    {touched.idTypeCarburant && errors.idTypeCarburant ? (
                                                        <div className="invalid-feedback">{errors.idTypeCarburant}</div>
                                                    ) : null}
                                                </div>
                                            </div>
                                            <div className="grp">
                                                <div className="gp">
                                                    <label >Année<span className='requiredField'>*</span></label>
                                                    <input type="number"
                                                        id="anneeFabrication"
                                                        name="anneeFabrication"
                                                        style={(touched.anneeFabrication && errors.anneeFabrication) ? { borderColor: "red" } : null}
                                                        placeholder=""
                                                        defaultValue={values.anneeFabrication}
                                                        onChange={handleChange}
                                                    />
                                                    {touched.anneeFabrication && errors.anneeFabrication ? (
                                                        <div className="invalid-feedback">{errors.anneeFabrication}</div>
                                                    ) : null}
                                                </div>
                                                <div className="gp">
                                                    <label >Marque<span className='requiredField'>*</span></label>
                                                    <select
                                                        id="idMarque"
                                                        name="idMarque"
                                                        value={values.idMarque} onChange={handleChange}
                                                        style={(touched.idMarque && errors.idMarque) ? { borderColor: "red" } : null}
                                                    >
                                                        <option value="">Veuillez sélectionner</option>
                                                        {marques.map((marque) => (
                                                            <option key={marque.idMarque} value={marque.idMarque}>{marque.libelleMarque}</option>
                                                        ))}
                                                    </select>
                                                    {touched.idMarque && errors.idMarque ? (
                                                        <div className="invalid-feedback">{errors.idMarque}</div>
                                                    ) : null}
                                                </div>
                                                <div className="gp">
                                                    <label >Modèle<span className='requiredField'>*</span></label>
                                                    <select
                                                        id="idModele"
                                                        name="idModele"
                                                        value={values.idModele} onChange={handleChange}
                                                        style={(touched.idModele && errors.idModele) ? { borderColor: "red" } : null}
                                                    >
                                                        <option value="">Veuillez sélectionner</option>
                                                        {modeles.map((modele) => (
                                                            <option key={modele.idModele} value={modele.idModele}>{modele.libelleModele}</option>
                                                        ))}
                                                    </select>
                                                    {touched.idModele && errors.idModele ? (
                                                        <div className="invalid-feedback">{errors.idModele}</div>
                                                    ) : null}
                                                </div>
                                            </div>
                                            <div className="grp">
                                                <div className="gp">
                                                    <label >Couleur</label>
                                                    <input
                                                        id="couleur"
                                                        name="couleur"
                                                        type="text"
                                                        value={values.couleur}
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                                <div className="gp">
                                                    <label >Statut<span className='requiredField'>*</span></label>
                                                    <select
                                                        id="statut"
                                                        name="statut"
                                                        value={values.statut}
                                                        onChange={handleChange}
                                                    >
                                                        <option value="disponible" defaultValue={true}>Disponible</option>
                                                        <option value="en cours d'utilisation">En cours</option>
                                                        <option value="hors service">Hors service</option>
                                                        <option value="archivé">Archivé</option>
                                                        <option value="indisponible">Indisponible</option>
                                                        <option value="vendu">Vendu</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="grp">
                                                <div className="gp">
                                                    <label >Image véhicule</label>
                                                    <label className="upload-file">
                                                    Choisir un fichier
                                                        <input
                                                            ref={pathImageVehicleRef}
                                                            id="pathImageVehicle"
                                                            name="pathImageVehicle"
                                                            type="file"
                                                            onChange={handlePathImageVehicleChange}
                                                        />
                                                    </label>
                                                    {pathImageVehicle && <span style={{ fontSize: "10px" }}>{getImageFileName(pathImageVehicle)}
                                                        <span className="material-symbols-outlined" onClick={(e) => { setPathImageVehicle(null); if (pathImageVehicleRef.current) { pathImageVehicleRef.current.value = ''; } }}>delete</span>
                                                    </span>}
                                                    {pathImageVehicleName && <span style={{ fontSize: "10px" }}>{pathImageVehicleName}
                                                        <span className="material-symbols-outlined" onClick={(e) => { setPathImageVehicle(null); setPathImageVehicleName(''); if (pathImageVehicleRef.current) { pathImageVehicleRef.current.value = ''; } }}>delete</span>
                                                    </span>}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    }

                                    {identification && <div className="detail-form" id="classification-form">
                                        <h3 className="heading-primary">Placement</h3>
                                        <div className="col">
                                            <div className="grp">
                                                <div className="gp">
                                                    <label >Service<span className='requiredField'>*</span></label>
                                                    <select
                                                        id="service"
                                                        name="service"
                                                        value={values.service}
                                                        onChange={handleChange}
                                                    >
                                                        <option value="" defaultValue={true}>Veuillez sélectionner</option>
                                                        <option value="ramassage">Ramassage</option>
                                                        <option value="navette">Navette </option>
                                                        <option value="transport">Transport</option>
                                                        <option value="restauration">Restauration </option>
                                                        <option value="citerne">Citerne d'eau</option>
                                                        <option value="benne tasseuse">Benne tasseuse</option>
                                                        <option value="vehicule personnel">Véhicule personnel</option>
                                                        <option value="vehicule service">Véhicule de service</option>
                                                    </select>
                                                    {touched.service && errors.service ? (
                                                        <div className="invalid-feedback">{errors.service}</div>
                                                    ) : null}
                                                </div>
                                                <div className="gp">
                                                    <label >Wilaya<span className='requiredField'>*</span></label>
                                                    <select
                                                        id="wilayaPlacement"
                                                        name="wilayaPlacement"
                                                        value={values.wilayaPlacement}
                                                        onChange={handleChange}
                                                        style={(touched.wilayaPlacement && errors.wilayaPlacement) ? { borderColor: "red" } : null}
                                                    >
                                                        <option value="">Veuillez sélectionner</option>
                                                        {wilayas.map((wilaya) => (
                                                            <option key={wilaya.idWilaya} value={wilaya.idWilaya}>{wilaya.nomLatin}</option>
                                                        ))}
                                                    </select>
                                                    {touched.wilayaPlacement && errors.wilayaPlacement ? (
                                                        <div className="invalid-feedback">{errors.wilayaPlacement}</div>
                                                    ) : null}
                                                </div>
                                            </div>
                                            <div className="grp">
                                                <div className="gp">
                                                    <label >Groupe</label>
                                                    <select
                                                        id="idGroupe"
                                                        name="idGroupe"
                                                        value={values.idGroupe}
                                                        onChange={handleChange}
                                                    >
                                                        <option value="">Veuillez sélectionner</option>
                                                        {groupes.map((groupe) => (
                                                            <option key={groupe.idGroupe} value={groupe.idGroupe}>{groupe.libelleGroupe}</option>
                                                        ))}
                                                    </select>
                                                </div>
                                                <div className="gp">
                                                    <label >Parc<span className='requiredField'>*</span></label>
                                                    <select
                                                        id="idParc"
                                                        name="idParc"
                                                        value={values.idParc}
                                                        onChange={handleChange}
                                                        style={(touched.idParc && errors.idParc) ? { borderColor: "red" } : null}
                                                    >
                                                        <option value="">Veuillez sélectionner</option>
                                                        {parcs.map((parc) => (
                                                            <option key={parc.idParc} value={parc.idParc}>{parc.nom}</option>
                                                        ))}
                                                    </select>
                                                    {touched.idParc && errors.idParc ? (
                                                        <div className="invalid-feedback">{errors.idParc}</div>
                                                    ) : null}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    }

                                    {identification && <div className="detail-form" id="classification-form">
                                        <h3 className="heading-primary">Classification</h3>
                                        <div className="col">
                                            <div className="grp">
                                                <div className="gp">
                                                    <label title='Ownership'>Type propriété<span className='requiredField'>*</span></label>
                                                    <select
                                                        id="typeProprietee"
                                                        name="typeProprietee"
                                                        value={values.typeProprietee}
                                                        onChange={handleChange}
                                                    >
                                                        <option value="possédé" title='Owned' defaultValue={true}>Possédé</option>
                                                        <option value="loué" title='Rented'>Loué</option>
                                                        <option value="freelance">Freelance</option>
                                                    </select>
                                                </div>
                                                <div className="gp">
                                                    <label >Propriétaire<span className='requiredField'>*</span></label>
                                                    <select
                                                        id="idProprietaire"
                                                        name="idProprietaire"
                                                        value={values.idProprietaire}
                                                        onChange={handleChange}
                                                        style={(touched.idProprietaire && errors.idProprietaire) ? { borderColor: "red" } : null}
                                                    >
                                                        <option value="">Veuillez sélectionner</option>
                                                        {proprietaires.map((proprietaire) => (
                                                            <option key={proprietaire.idProprietaire} value={proprietaire.idProprietaire}>{proprietaire.typeProprietaire !== "freelancer" ? proprietaire.raisonSociale : proprietaire.nom + " " + proprietaire.prenom}</option>
                                                        ))}
                                                    </select>
                                                    {touched.idProprietaire && errors.idProprietaire ? (
                                                        <div className="invalid-feedback">{errors.idProprietaire}</div>
                                                    ) : null}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    }

                                    {cycle && <div className="cycle-form" id="cycle-de-vie">
                                        <div className="detail-form">
                                            <h3 className="heading-primary">Mise en service</h3>
                                            <div className="col">
                                                <div className="grp">
                                                    <div className="gp">
                                                        <label >Date de mise en servie</label>
                                                        <input
                                                            type="date"
                                                            id="dateMiseEnService"
                                                            name="dateMiseEnService"
                                                            value={values.dateMiseEnService ? (moment(values.dateMiseEnService).format('YYYY-MM-DD')) : ''}
                                                            onChange={handleChange}
                                                        />
                                                    </div>

                                                    <div className="gp">
                                                        <label >Relevé du compteur</label>
                                                        <input
                                                            type="number"
                                                            id="kilometrageMiseEnService"
                                                            name="kilometrageMiseEnService"
                                                            placeholder=""
                                                            defaultValue={values.kilometrageMiseEnService}
                                                            onChange={handleChange}
                                                            step="any"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="detail-form">
                                            <h3 className="heading-primary">Actuellement</h3>
                                            <div className="col">
                                                <label >Relevé du compteur<span className='requiredField'>*</span></label>
                                                <input type="number"
                                                    id="kilometrageActuel"
                                                    name="kilometrageActuel"
                                                    style={(touched.kilometrageActuel && errors.kilometrageActuel) ? { borderColor: "red" } : null}
                                                    placeholder=""
                                                    defaultValue={values.kilometrageActuel}
                                                    onChange={handleChange}
                                                    disabled={true}
                                                    step="any"
                                                />
                                                {touched.kilometrageActuel && errors.kilometrageActuel ? (
                                                    <div className="invalid-feedback">{errors.kilometrageActuel}</div>
                                                ) : null}
                                            </div>
                                        </div>
                                        <div className="detail-form">
                                            <h3 className="heading-primary">Mise hors service</h3>
                                            <div className="col">
                                                <div className="grp">
                                                    <div className="gp">
                                                        <label >Date de mise hors service</label>
                                                        <input
                                                            type="date"
                                                            id="dateFinService"
                                                            name="dateFinService"
                                                            value={values.dateFinService ? (moment(values.dateFinService).format('YYYY-MM-DD')) : ''}
                                                            onChange={handleChange}
                                                        />
                                                    </div>
                                                    <div className="gp">
                                                        <label >Relevé du compteur</label>
                                                        <input
                                                            type="number"
                                                            id="kilometrageFinService"
                                                            name="kilometrageFinService"
                                                            placeholder=""
                                                            defaultValue={values.kilometrageFinService}
                                                            onChange={handleChange}
                                                            step="any"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="detail-form">
                                            <h3 className="heading-primary">Estimations de la durée de vie</h3>
                                            <div className="col">
                                                <div className="grp">
                                                    <div className="gp">
                                                        <label >Durée de vie estimée en mois</label>
                                                        <input
                                                            type="number"
                                                            id="dureeDeVieEstimeMois"
                                                            name="dureeDeVieEstimeMois"
                                                            placeholder=""
                                                            defaultValue={values.dureeDeVieEstimeMois}
                                                            onChange={handleChange}
                                                        />
                                                    </div>
                                                    <div className="gp">
                                                        <label >Durée de vie estimée par distance</label>
                                                        <input
                                                            type="number"
                                                            id="dureeDeVieEstimeDistance"
                                                            name="dureeDeVieEstimeDistance"
                                                            placeholder=""
                                                            defaultValue={values.dureeDeVieEstimeDistance}
                                                            onChange={handleChange}
                                                            step="any"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    }

                                    {finance && <div className="finance-form" id="finance">
                                        <div className="detail-form">
                                            <h3 className="heading-primary">Détails d'achat</h3>
                                            <div className="col">
                                                <div className="grp">
                                                    <div className="gp">
                                                        <label >Fournisseur</label>
                                                        <select
                                                            id="idFournisseurAchat"
                                                            name="idFournisseurAchat"
                                                            value={values.idFournisseurAchat}
                                                            onChange={handleChange}
                                                        >
                                                            <option value="">Veuillez sélectionner</option>
                                                            {fournisseurs.map((fournisseur) => (
                                                                <option key={fournisseur.idFournisseur} value={fournisseur.idFournisseur}>{fournisseur.raisonSociale}</option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                    <div className="gp">
                                                        <label >Date d'achat</label>
                                                        <input
                                                            type="date"
                                                            id="dateAchat"
                                                            name="dateAchat"
                                                            value={values.dateAchat ? (moment(values.dateAchat).format('YYYY-MM-DD')) : ''}
                                                            onChange={handleChange}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="grp">
                                                    <div className="gp">
                                                        <label >Prix d'achat</label>
                                                        <input
                                                            type="number"
                                                            id="prixAchat"
                                                            name="prixAchat"
                                                            placeholder=""
                                                            defaultValue={values.prixAchat}
                                                            onChange={handleChange}
                                                            step="any"
                                                        />
                                                    </div>
                                                    <div className="gp">
                                                        <label >Relevé du compteur</label>
                                                        <input
                                                            type="number"
                                                            id="kilometrageAchat"
                                                            name="kilometrageAchat"
                                                            placeholder=""
                                                            defaultValue={values.kilometrageAchat}
                                                            onChange={handleChange}
                                                            step="any"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="grp">
                                                    <div className="gp">
                                                        <label >Remarques</label>
                                                        <textarea
                                                            type="text"
                                                            rows={4}
                                                            id="remarquesAchat"
                                                            name="remarquesAchat"
                                                            placeholder=""
                                                            defaultValue={values.remarquesAchat}
                                                            onChange={handleChange}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="detail-form">
                                            <h3 className="heading-primary">Garantie</h3>
                                            <div className="col">
                                                <div className="grp">
                                                    <div className="gp">
                                                        <label >Date d'expiration</label>
                                                        <input
                                                            type="date"
                                                            id="dateExpirationGarantie"
                                                            name="dateExpirationGarantie"
                                                            value={values.dateExpirationGarantie ? (moment(values.dateExpirationGarantie).format('YYYY-MM-DD')) : ''}
                                                            onChange={handleChange}
                                                        />
                                                    </div>
                                                    <div className="gp">
                                                        <label >Valeur maximale du compteur</label>
                                                        <input
                                                            type="number"
                                                            id="kilometrageGarantie"
                                                            name="kilometrageGarantie"
                                                            placeholder=""
                                                            defaultValue={values.kilometrageGarantie}
                                                            onChange={handleChange}
                                                            step="any"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="detail-form">
                                            <h3 className="heading-primary">Prêt/Location</h3>
                                            <div className="col">
                                                <div className="grp">
                                                    <div className="gp">
                                                        <label >Type de financement</label>
                                                        <select
                                                            id="typeFinancement"
                                                            name="typeFinancement"
                                                            value={values.typeFinancement}
                                                            onChange={handleChange}
                                                        >
                                                            <option value="aucun" defaultValue={true} title="Ce véhicule n'est pas financé">Aucun financement</option>
                                                            <option value="pret" title='Ce véhicule est associé à un prêt'>Prêt</option>
                                                            <option value="location" title='Ce véhicule est en location'>Location</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                {siPret && <>
                                                    <div className="grp">
                                                        <div className="gp">
                                                            <label >Prêteur</label>
                                                            <select
                                                                id="idFournisseurPret"
                                                                name="idFournisseurPret"
                                                                value={values.idFournisseurPret}
                                                                onChange={handleChange}
                                                            >
                                                                <option value="">Veuillez sélectionner</option>
                                                                {fournisseurs.map((fournisseur) => (
                                                                    <option key={fournisseur.idFournisseur} value={fournisseur.idFournisseur}>{fournisseur.raisonSociale}</option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                        <div className="gp">
                                                            <label >Date du prêt</label>
                                                            <input
                                                                type="date"
                                                                id="dateDebutPret"
                                                                name="dateDebutPret"
                                                                value={values.dateDebutPret ? (moment(values.dateDebutPret).format('YYYY-MM-DD')) : ''}
                                                                onChange={handleChange}
                                                            />
                                                        </div>
                                                        <div className="gp">
                                                            <label >Date de fin du prêt</label>
                                                            <input
                                                                type="date"
                                                                id="dateFinPret"
                                                                name="dateFinPret"
                                                                value={values.dateFinPret ? (moment(values.dateFinPret).format('YYYY-MM-DD')) : ''}
                                                                onChange={handleChange}
                                                            />
                                                        </div>

                                                    </div>
                                                    <div className="grp">
                                                        <div className="gp">
                                                            <label >Montant du prêt</label>
                                                            <input
                                                                type="number"
                                                                id="prixPret"
                                                                name="prixPret"
                                                                placeholder=""
                                                                defaultValue={values.prixPret}
                                                                onChange={handleChange}
                                                                step="any"
                                                            />
                                                        </div>

                                                    </div>
                                                    <div className="grp">
                                                        <div className="gp">
                                                            <label >Remarques</label>
                                                            <textarea
                                                                type="text"
                                                                rows={4}
                                                                id="remarquesPret"
                                                                name="remarquesPret"
                                                                placeholder=""
                                                                defaultValue={values.remarquesPret}
                                                                onChange={handleChange}
                                                            />
                                                        </div>
                                                    </div>
                                                </>}
                                                {siLocation && <>
                                                    <div className="grp">
                                                        <div className="gp">
                                                            <label >Fournisseur</label>
                                                            <select
                                                                id="idFournisseurLocation"
                                                                name="idFournisseurLocation"
                                                                value={values.idFournisseurLocation}
                                                                onChange={handleChange}
                                                            >
                                                                <option value="">Veuillez sélectionner</option>
                                                                {fournisseurs.map((fournisseur) => (
                                                                    <option key={fournisseur.idFournisseur} value={fournisseur.idFournisseur}>{fournisseur.raisonSociale}</option>
                                                                ))}
                                                            </select>
                                                        </div>

                                                        <div className="gp">
                                                            <label >Date du bail</label>
                                                            <input
                                                                type="date"
                                                                id="dateDebutLocation"
                                                                name="dateDebutLocation"
                                                                value={values.dateDebutLocation ? (moment(values.dateDebutLocation).format('YYYY-MM-DD')) : ''}
                                                                onChange={handleChange}
                                                            />
                                                        </div>
                                                        <div className="gp">
                                                            <label >Date de fin de bail</label>
                                                            <input
                                                                type="date"
                                                                id="dateFinLocation"
                                                                name="dateFinLocation"
                                                                value={values.dateFinLocation ? (moment(values.dateFinLocation).format('YYYY-MM-DD')) : ''}
                                                                onChange={handleChange}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="grp">
                                                        <div className="gp">
                                                            <label >Coût du bail</label>
                                                            <input
                                                                type="number"
                                                                id="prixLocation"
                                                                name="prixLocation"
                                                                value={values.prixLocation}
                                                                onChange={handleChange}
                                                                step="any"
                                                            />
                                                        </div>

                                                        <div className="gp">
                                                            <label >Numéro de bail</label>
                                                            <input
                                                                type="text"
                                                                id="numeroLocation"
                                                                name="numeroLocation"
                                                                value={values.numeroLocation}
                                                                onChange={handleChange}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="grp">
                                                        <div className="gp">
                                                            <label >Remarques</label>
                                                            <textarea
                                                                type="text"
                                                                rows={4}
                                                                id="remarquesLocation"
                                                                name="remarquesLocation"
                                                                placeholder=""
                                                                defaultValue={values.remarquesLocation}
                                                                onChange={handleChange}
                                                            />
                                                        </div>
                                                    </div>
                                                </>}
                                            </div>
                                        </div>
                                    </div>
                                    }

                                    {caractirstique && <div className="setting-form" id="caractirstique">
                                        <div className="detail-form">
                                            <h3 className="heading-primary">Dimensions</h3>
                                            <div className="col">
                                                <div className="grp">
                                                    <div className="gp">
                                                        <label >Largeur</label>
                                                        <input
                                                            type="number"
                                                            id="largeur"
                                                            name="largeur"
                                                            placeholder=""
                                                            defaultValue={values.largeur}
                                                            onChange={handleChange}
                                                            step="any"
                                                        />
                                                    </div>
                                                    <div className="gp">
                                                        <label >Hauteur</label>
                                                        <input
                                                            type="number"
                                                            id="hauteur"
                                                            name="hauteur"
                                                            placeholder=""
                                                            defaultValue={values.hauteur}
                                                            onChange={handleChange}
                                                            step="any"
                                                        />
                                                    </div>
                                                    <div className="gp">
                                                        <label >Longeur</label>
                                                        <input
                                                            type="number"
                                                            id="longueur"
                                                            name="longueur"
                                                            placeholder=""
                                                            defaultValue={values.longueur}
                                                            onChange={handleChange}
                                                            step="any"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="detail-form">
                                            <h3 className="heading-primary">Poids</h3>
                                            <div className="col">
                                                <div className="grp">
                                                    <div className="gp" title='Poids total du véhicule sans les passagers ni la marchandise'>
                                                        <label >Poids à vide</label>
                                                        <input
                                                            type="number"
                                                            id="poidsVide"
                                                            name="poidsVide"
                                                            placeholder=""
                                                            defaultValue={values.poidsVide}
                                                            onChange={handleChange}
                                                            step="any"
                                                        />
                                                    </div>
                                                    <div className="gp" title='Poids nominal brut du véhicule (y compris accessoires, passagers et marchandse)'>
                                                        <label >Poids brut</label>
                                                        <input
                                                            type="number"
                                                            id="poidsNominalBrut"
                                                            name="poidsNominalBrut"
                                                            placeholder=""
                                                            defaultValue={values.poidsNominalBrut}
                                                            onChange={handleChange}
                                                            step="any"
                                                        />
                                                    </div>
                                                    <div className="gp" title='Poids maximal que le véhicule peut tirer'>
                                                        <label >Capacité de remorquage</label>
                                                        <input
                                                            type="number"
                                                            id="poidsRemorquage"
                                                            name="poidsRemorquage"
                                                            placeholder=""
                                                            defaultValue={values.poidsRemorquage}
                                                            onChange={handleChange}
                                                            step="any"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="detail-form">
                                            <h3 className="heading-primary">Moteur et Transmission</h3>
                                            <div className="col">
                                                <div className="grp">
                                                    <div className="gp">
                                                        <label >Résumé du moteur</label>
                                                        <input
                                                            type="text"
                                                            id="mouteur"
                                                            name="mouteur"
                                                            placeholder=""
                                                            defaultValue={values.mouteur}
                                                            onChange={handleChange}
                                                        />
                                                    </div>
                                                    <div className="gp">
                                                        <label >Résumé de transmission</label>
                                                        <input
                                                            type="text"
                                                            id="transmission"
                                                            name="transmission"
                                                            placeholder=""
                                                            defaultValue={values.transmission}
                                                            onChange={handleChange}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="detail-form">
                                            <h3 className="heading-primary">Carburant et Huile</h3>
                                            <div className="col">
                                                <div className="grp">
                                                    <div className="gp">
                                                        <label >Capacité du réservoir de carburant</label>
                                                        <input
                                                            type="number"
                                                            id="capaciteMaxHuile"
                                                            name="capaciteMaxHuile"
                                                            placeholder=""
                                                            defaultValue={values.capaciteMaxHuile}
                                                            onChange={handleChange}
                                                            step="any"
                                                        />
                                                    </div>
                                                    <div className="gp">
                                                        <label >Capacité d'huile</label>
                                                        <input
                                                            type="number"
                                                            id="capaciteMaxCarburant"
                                                            name="capaciteMaxCarburant"
                                                            placeholder=""
                                                            defaultValue={values.capaciteMaxCarburant}
                                                            onChange={handleChange}
                                                            step="any"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="detail-form">
                                            <h3 className="heading-primary">Roues et pneus</h3>
                                            <div className="col">
                                                <div className="grp">
                                                    <div className="gp" title='ex: hydroulique, air, ...etc'>
                                                        <label >Système de freinage</label>
                                                        <input
                                                            type="text"
                                                            id="systemeFreinage"
                                                            name="systemeFreinage"
                                                            placeholder=""
                                                            defaultValue={values.systemeFreinage}
                                                            onChange={handleChange}
                                                        />
                                                    </div>
                                                    <div className="gp">
                                                        <label >Diamètre de la roue avant</label>
                                                        <input
                                                            type="text"
                                                            id="diametreRoueAvant"
                                                            name="diametreRoueAvant"
                                                            placeholder=""
                                                            defaultValue={values.diametreRoueAvant}
                                                            onChange={handleChange}
                                                        />
                                                    </div>
                                                    <div className="gp">
                                                        <label >Diamètre de la roue arrière</label>
                                                        <input
                                                            type="text"
                                                            id="diametreRoueArriere"
                                                            name="diametreRoueArriere"
                                                            placeholder=""
                                                            defaultValue={values.diametreRoueArriere}
                                                            onChange={handleChange}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="detail-form">
                                            <h3 className="heading-primary">Autres</h3>
                                            <div className="col">
                                                <div className="grp">
                                                    <div className="gp" title='ex: hydroulique, air, ...etc'>
                                                        <label >Nombre de places</label>
                                                        <input
                                                            type="number"
                                                            id="nbrPlaces"
                                                            name="nbrPlaces"
                                                            placeholder=""
                                                            defaultValue={values.nbrPlaces}
                                                            onChange={handleChange}
                                                        />
                                                    </div>
                                                    <div className="gp">
                                                        <label >Nombre de portes</label>
                                                        <input
                                                            type="number"
                                                            id="nbrPortes"
                                                            name="nbrPortes"
                                                            placeholder=""
                                                            defaultValue={values.nbrPortes}
                                                            onChange={handleChange}
                                                        />
                                                    </div>

                                                </div>
                                                <div className="grp">
                                                    <div className="gp" title='Les équipements sont des actifs que votre flotte utilise pour effectuer un travail ou dans les opérations quotidiennes.'>
                                                        <label >Équipements</label>
                                                        <textarea
                                                            type="text"
                                                            id="equipements"
                                                            name="equipements"
                                                            placeholder=""
                                                            defaultValue={values.equipements}
                                                            onChange={handleChange}
                                                            rows={4}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>}

                                    {document && <div className="document-form" id="document">
                                        <div className="detail-form">
                                            <h3 className="heading-primary">Carte grise</h3>
                                            <div className="col">
                                                <div className="grp">
                                                    <div className="gp">
                                                        <label >Document</label>
                                                        <label className="upload-file">
                                                        Choisir un fichier
                                                            <input
                                                                ref={pathImageCarteGriseRef}
                                                                id="pathImageCarteGrise"
                                                                name="pathImageCarteGrise"
                                                                type="file"
                                                                onChange={handlePathImageCarteGriseChange}
                                                            />
                                                        </label>
                                                        {pathImageCarteGrise && <span style={{ fontSize: "10px" }}>{getImageFileName(pathImageCarteGrise)}
                                                            <span className="material-symbols-outlined" onClick={(e) => { setPathImageCarteGrise(null); if (pathImageCarteGriseRef.current) { pathImageCarteGriseRef.current.value = ''; } }}>delete</span>
                                                        </span>}
                                                        {pathImageCarteGriseName && <span style={{ fontSize: "10px" }}>{pathImageCarteGriseName}
                                                            <span className="material-symbols-outlined" onClick={(e) => { setPathImageCarteGrise(null); setPathImageCarteGriseName(''); if (pathImageCarteGriseRef.current) { pathImageCarteGriseRef.current.value = ''; } }}>delete</span>
                                                        </span>}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="detail-form">
                                            <h3 className="heading-primary">Assurance</h3>
                                            <div className="col">
                                                <div className="grp">
                                                    <div className="gp">
                                                        <label >Numéro de police</label>
                                                        <input
                                                            id="numeroPolice"
                                                            name="numeroPolice"
                                                            type="text"
                                                            value={values.numeroPolice}
                                                            onChange={handleChange}
                                                        />
                                                    </div>
                                                    <div className="gp">
                                                        <label >Type d'assurance<span className='requiredField'>*</span></label>
                                                        <select
                                                            id="idTypeAssurance"
                                                            name="idTypeAssurance"
                                                            value={values.idTypeAssurance}
                                                            onChange={handleChange}
                                                            style={(touched.idTypeAssurance && errors.idTypeAssurance) ? { borderColor: "red" } : null}
                                                        >
                                                            <option value="">Veuillez sélectionner</option>
                                                            {typesAssurance.map((typeAssurance) => (
                                                                <option key={typeAssurance.idTypeAssurance} value={typeAssurance.idTypeAssurance}>{typeAssurance.libelleTypeAssurance}</option>
                                                            ))}
                                                        </select>
                                                        {touched.idTypeAssurance && errors.idTypeAssurance ? (
                                                            <div className="invalid-feedback">{errors.idTypeAssurance}</div>
                                                        ) : null}
                                                    </div>
                                                </div>
                                                <div className="grp">
                                                    <div className="gp">
                                                        <label >Date début de couverture<span className='requiredField'>*</span></label>
                                                        <input
                                                            type="date"
                                                            id="dateDebutAssurance"
                                                            name="dateDebutAssurance"
                                                            style={(touched.dateDebutAssurance && errors.dateDebutAssurance) ? { borderColor: "red" } : null}
                                                            placeholder=""
                                                            defaultValue={values.dateDebutAssurance ? (moment(values.dateDebutAssurance).format('YYYY-MM-DD')) : ''}
                                                            onChange={handleChange}
                                                        />
                                                        {touched.dateDebutAssurance && errors.dateDebutAssurance ? (
                                                            <div className="invalid-feedback">{errors.dateDebutAssurance}</div>
                                                        ) : null}
                                                    </div>
                                                    <div className="gp">
                                                        <label >Date fin de couverture<span className='requiredField'>*</span></label>
                                                        <input
                                                            type="date"
                                                            id="dateFinAssurance"
                                                            name="dateFinAssurance"
                                                            style={(touched.dateFinAssurance && errors.dateFinAssurance) ? { borderColor: "red" } : null}
                                                            placeholder=""
                                                            defaultValue={values.dateFinAssurance ? (moment(values.dateFinAssurance).format('YYYY-MM-DD')) : ''}
                                                            onChange={handleChange}
                                                        />
                                                        {touched.dateFinAssurance && errors.dateFinAssurance ? (
                                                            <div className="invalid-feedback">{errors.dateFinAssurance}</div>
                                                        ) : null}
                                                    </div>
                                                </div>
                                                <div className="grp">
                                                    <div className="gp" title="Le temps à l'avance pendant lequel vous considérez le rappel comme étant '' à échéance prochaine''">
                                                        <label >Seuil d'échéance prochaine<span className='requiredField'>*</span></label>
                                                        <input
                                                            type="number"
                                                            id="echeanceTempsAssurance"
                                                            name="echeanceTempsAssurance"
                                                            style={(touched.echeanceTempsAssurance && errors.echeanceTempsAssurance) ? { borderColor: "red" } : null}
                                                            placeholder=""
                                                            defaultValue={values.echeanceTempsAssurance}
                                                            onChange={handleChange}
                                                        />
                                                        {touched.echeanceTempsAssurance && errors.echeanceTempsAssurance ? (
                                                            <div className="invalid-feedback">{errors.echeanceTempsAssurance}</div>
                                                        ) : null}
                                                    </div>
                                                    <div className="gp-input">

                                                        <select
                                                            id="uniteIntervalleTempsAssurance"
                                                            name="uniteIntervalleTempsAssurance"
                                                            value={values.uniteIntervalleTempsAssurance}
                                                            style={(touched.uniteIntervalleTempsAssurance && errors.uniteIntervalleTempsAssurance) ? { borderColor: "red" } : null}
                                                            onChange={handleChange}
                                                        >
                                                            <option value="jour" defaultValue={true}>Jour(s)</option>
                                                            <option value="mois">Mois</option>
                                                        </select>
                                                        {touched.uniteIntervalleTempsAssurance && errors.uniteIntervalleTempsAssurance ? (
                                                            <div className="invalid-feedback">{errors.uniteIntervalleTempsAssurance}</div>
                                                        ) : null}
                                                    </div>
                                                </div>
                                                <div className="grp">
                                                    <div className="gp">
                                                        <label >Montant</label>
                                                        <input
                                                            id="montantAssurance"
                                                            name="montantAssurance"
                                                            type="number"
                                                            value={values.montantAssurance}
                                                            onChange={handleChange}
                                                            step="any"
                                                        />
                                                    </div>
                                                    <div className="gp">
                                                        <label >Couverture</label>
                                                        <input
                                                            id="couvertureAssurance"
                                                            name="couvertureAssurance"
                                                            type="text"
                                                            value={values.couvertureAssurance}
                                                            onChange={handleChange}
                                                        />
                                                    </div>
                                                    <div className="gp">
                                                        <label >Compagnie d'assurance</label>
                                                        <input
                                                            id="compagnieAssurance"
                                                            name="compagnieAssurance"
                                                            type="text"
                                                            value={values.compagnieAssurance}
                                                            onChange={handleChange}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="grp">
                                                    <div className="gp">
                                                        <label >Adresse</label>
                                                        <input
                                                            id="adresseAssurance"
                                                            name="adresseAssurance"
                                                            type="text"
                                                            value={values.adresseAssurance}
                                                            onChange={handleChange}
                                                        />
                                                    </div>
                                                    <div className="gp">
                                                        <label >Email</label>
                                                        <input type="email"
                                                            id="emailAssurance"
                                                            name="emailAssurance"
                                                            style={(touched.emailAssurance && errors.emailAssurance) ? { borderColor: "red" } : null}
                                                            placeholder=""
                                                            defaultValue={values.emailAssurance}
                                                            onChange={handleChange}
                                                        />
                                                        {touched.emailAssurance && errors.emailAssurance ? (
                                                            <div className="invalid-feedback">{errors.emailAssurance}</div>
                                                        ) : null}
                                                    </div>
                                                    <div className="gp">
                                                        <label >N° de téléphone</label>
                                                        <input
                                                            id="numTelAssurance"
                                                            name="numTelAssurance"
                                                            type="text"
                                                            value={values.numTelAssurance}
                                                            onChange={handleChange}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="grp">
                                                    <div className="gp">
                                                        <label >Document</label>
                                                        <label className="upload-file">
                                                        Choisir un fichier
                                                            <input
                                                                ref={pathImageAssuranceRef}
                                                                id="pathImageAssurance"
                                                                name="pathImageAssurance"
                                                                type="file"
                                                                onChange={handlePathImageAssuranceChange}
                                                            />
                                                        </label>
                                                        {pathImageAssurance && <span style={{ fontSize: "10px" }}>{getImageFileName(pathImageAssurance)}
                                                            <span className="material-symbols-outlined" onClick={(e) => { setPathImageAssurance(null); if (pathImageAssuranceRef.current) { pathImageAssuranceRef.current.value = ''; } }}>delete</span>
                                                        </span>}
                                                        {pathImageAssuranceName && <span style={{ fontSize: "10px" }}>{pathImageAssuranceName}
                                                            <span className="material-symbols-outlined" onClick={(e) => { setPathImageAssurance(null); setPathImageAssuranceName(''); if (pathImageAssuranceRef.current) { pathImageAssuranceRef.current.value = ''; } }}>delete</span>
                                                        </span>}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="detail-form">
                                            <h3 className="heading-primary">Contrôle technique</h3>
                                            <div className="col">
                                                <div className="grp">
                                                    <div className="gp">
                                                        <label >Nature du contrôle</label>
                                                        <input
                                                            id="natureControle"
                                                            name="natureControle"
                                                            type="text"
                                                            value={values.natureControle}
                                                            onChange={handleChange}
                                                        />
                                                    </div>
                                                    <div className="gp">
                                                        <label >N° du contrôle</label>
                                                        <input
                                                            id="numControle"
                                                            name="numControle"
                                                            type="text"
                                                            value={values.numControle}
                                                            onChange={handleChange}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="grp">
                                                    <div className="gp">
                                                        <label >Date du contrôle<span className='requiredField'>*</span></label>
                                                        <input
                                                            type="date"
                                                            id="dateControle"
                                                            name="dateControle"
                                                            style={(touched.dateControle && errors.dateControle) ? { borderColor: "red" } : null}
                                                            placeholder=""
                                                            defaultValue={values.dateControle ? (moment(values.dateControle).format('YYYY-MM-DD')) : ''}
                                                            onChange={handleChange}
                                                        />
                                                        {touched.dateControle && errors.dateControle ? (
                                                            <div className="invalid-feedback">{errors.dateControle}</div>
                                                        ) : null}
                                                    </div>
                                                    <div className="gp">
                                                        <label >Date de prochain contrôle<span className='requiredField'>*</span></label>
                                                        <input
                                                            type="date"
                                                            id="dateProchainControle"
                                                            name="dateProchainControle"
                                                            style={(touched.dateProchainControle && errors.dateProchainControle) ? { borderColor: "red" } : null}
                                                            placeholder=""
                                                            defaultValue={values.dateProchainControle ? (moment(values.dateProchainControle).format('YYYY-MM-DD')) : ''}
                                                            onChange={handleChange}
                                                        />
                                                        {touched.dateProchainControle && errors.dateProchainControle ? (
                                                            <div className="invalid-feedback">{errors.dateProchainControle}</div>
                                                        ) : null}
                                                    </div>
                                                </div>
                                                <div className="grp">
                                                    <div className="gp" title="Le temps à l'avance pendant lequel vous considérez le rappel comme étant '' à échéance prochaine''">
                                                        <label >Seuil d'échéance prochaine<span className='requiredField'>*</span></label>
                                                        <input
                                                            type="number"
                                                            id="echeanceTempsControle"
                                                            name="echeanceTempsControle"
                                                            style={(touched.echeanceTempsControle && errors.echeanceTempsControle) ? { borderColor: "red" } : null}
                                                            placeholder=""
                                                            defaultValue={values.echeanceTempsControle}
                                                            onChange={handleChange}
                                                        />
                                                        {touched.echeanceTempsControle && errors.echeanceTempsControle ? (
                                                            <div className="invalid-feedback">{errors.echeanceTempsControle}</div>
                                                        ) : null}
                                                    </div>
                                                    <div className="gp-input">

                                                        <select
                                                            id="uniteIntervalleTempsControle"
                                                            name="uniteIntervalleTempsControle"
                                                            value={values.uniteIntervalleTempsControle}
                                                            style={(touched.uniteIntervalleTempsControle && errors.uniteIntervalleTempsControle) ? { borderColor: "red" } : null}
                                                            onChange={handleChange}
                                                        >
                                                            <option value="jour" defaultValue={true}>Jour(s)</option>
                                                            <option value="mois">Mois</option>
                                                        </select>
                                                        {touched.uniteIntervalleTempsControle && errors.uniteIntervalleTempsControle ? (
                                                            <div className="invalid-feedback">{errors.uniteIntervalleTempsControle}</div>
                                                        ) : null}
                                                    </div>
                                                </div>
                                                <div className="grp">
                                                    <div className="gp">
                                                        <label >Document</label>
                                                        <label className="upload-file">
                                                            Choisir un fichier
                                                            <input
                                                                ref={pathImageControleRef}
                                                                id="pathImageControle"
                                                                name="pathImageControle"
                                                                type="file"
                                                                onChange={handlePathImageControleChange}
                                                            />
                                                        </label>
                                                        {pathImageControle && <span style={{ fontSize: "10px" }}>{getImageFileName(pathImageControle)}
                                                            <span className="material-symbols-outlined" onClick={(e) => { setPathImageControle(null); if (pathImageControleRef.current) { pathImageControleRef.current.value = ''; } }}>delete</span>
                                                        </span>}
                                                        {pathImageControleName && <span style={{ fontSize: "10px" }}>{pathImageControleName}
                                                            <span className="material-symbols-outlined" onClick={(e) => { setPathImageControle(null); setPathImageControleName(''); if (pathImageControleRef.current) { pathImageControleRef.current.value = ''; } }}>delete</span>
                                                        </span>}
                                                    </div>
                                                </div>
                                                <div className="grp">
                                                    <div className="gp">
                                                        <label >Résultats du contrôle</label>
                                                        <textarea
                                                            id="resultatsControle"
                                                            name="resultatsControle"
                                                            type="text"
                                                            value={values.resultatsControle}
                                                            onChange={handleChange}
                                                            rows={4}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    }

                                    <div className="submit-container">
                                        <button type="submit" className="submit-btn">Enregistrer</button>
                                    </div>
                                </div>
                            </div>}
                        </div>
                    </div>
                </form>
            </div>
        </section>
    );
}

export default ModifierVehicule;
