import React, { useEffect, useState, useRef, useContext } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import moment from 'moment/moment';
import { CustomToast } from '../../../components/Toast/CustomToast';
import { formatCurrency } from '../../../utils/Utils'
import ModalImage from '../../../components/ModalImage/ModalImage';
import { axiosInstance, VehiculeOrdreTravail, OrdreTravailImg } from '../../../services/api';
import { AuthContext } from '../../../contexts/AuthContext';

const ApercuOrdre = () => {
    const { authData } = useContext(AuthContext);
    const navigate = useNavigate();
    const { id } = useParams();//idOrdreTravail

    const [ordre, setOrdre] = useState({});
    const [showImagePopUp, setShowImagePopUp] = useState(false);
    const [imageSrc, setImageSrc] = useState("");

    const showImage = (documentImagePath) => {
        setImageSrc(documentImagePath);
        setShowImagePopUp(true);
    };

    const handleCancelImage = () => {
        setShowImagePopUp(false);
    };

    const handleOpenInNewTab = () => {
        const imageUrl = new URL(OrdreTravailImg + imageSrc).toString();
        window.open(imageUrl, '_blank');
    };

    //Load data

    const getOrdre = async (idOrdreTravail) => {
        try {
            const queryParams = new URLSearchParams({
                pageNumber: 1,
                pageSize: 1
            });

            if (authData?.wilayas != null) {
                const wilayasFilter = authData?.wilayas
                queryParams.append("wilayas", wilayasFilter.join(','));
            }
            else {
                return
            }

            if (authData?.flottesAutorises != null) {
                const flottesAutorisesFilter = authData?.flottesAutorises
                queryParams.append("flottesAutorises", flottesAutorisesFilter.join(','));
            }
            else {
                return
            }

            queryParams.append("idOrdreTravail", idOrdreTravail);

            const response = await axiosInstance.get(`${VehiculeOrdreTravail}?${queryParams}`);
            const resp = await response.data;

            setOrdre(resp.data[0]);
        }
        catch (err) {
            console.log(err);
        }
    }

    useEffect(() => {

        getOrdre(id);
        return () => {
            // Perform any necessary cleanup (e.g., cancel pending requests)
        };
    }, [id, authData]);

    return (
        <section className="service-section">
            <div className="container">
                <div className="apercu-sec wrap-all">
                    {ordre && <div className="grid grid--2-cols">
                        <div className="card">
                            <h1>Détails</h1>
                            <div className="olumn-1-gap margin-top-2">
                                <div className="card-col">
                                    <div className="row-3-gap">
                                        <h4>Véhicule:
                                            <span>
                                                <svg height="11.87" width="15">
                                                    <circle cx="6" cy="6" r="6" fill={ordre.statutVehicule === "disponible" ? "green" : ordre.statutVehicule === "indisponible" ? "orangered" : ordre.statutVehicule === "archivé" ? "grey" : ordre.statutVehicule === "vendu" ? "blue" : ordre.statutVehicule === "en cours d'utilisation" ? "yellow" : ordre.statutVehicule === "hors service" ? "red" : null} />
                                                </svg>
                                            </span>
                                        </h4>
                                        <p className="color-black">
                                            {ordre.nomVehicule + ' ' + ordre.immatriculationVehicule}
                                        </p>
                                    </div>
                                    <div className="row-3-gap">
                                        <h4>Date d'achèvement:
                                        </h4>
                                        <p className="color-black">
                                            {moment(ordre.dateFinReelTravail).isValid() ? moment(ordre.dateFinReelTravail).format('DD-MM-YYYY') : ''}
                                        </p>
                                    </div>
                                    <div className="row-3-gap">
                                        <h4>Compteur de début:
                                        </h4>
                                        <p className="color-black">
                                            {ordre.valeuCompteur ? ordre.valeuCompteur + ' ' + authData?.unite_distance : ''}
                                        </p>
                                    </div>
                                    <div className="row-3-gap">
                                        <h4>Date de début:
                                        </h4>
                                        <p className="color-black">
                                            {moment(ordre.dateDebutReelTravail).isValid() ? moment(ordre.dateDebutReelTravail).format('DD-MM-YYYY') : ''}
                                        </p>
                                    </div>
                                    <div className="row-3-gap">
                                        <h4>Réparation:
                                        </h4>
                                        <p className="color-black">
                                            {ordre?.typeEntreeService?.charAt(0).toUpperCase()}
                                            {ordre?.typeEntreeService?.slice(1)}
                                        </p>
                                    </div>
                                    <div className="row-3-gap">
                                        <h4>Fournisseur:
                                        </h4>
                                        <p className="color-black">
                                            {ordre?.fournisseur}
                                        </p>
                                    </div>
                                    <div className="row-3-gap">
                                        <h4>Référence:
                                        </h4>
                                        <p className="color-black">
                                            {ordre?.numeroFacture}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card">
                            <h1>Tâches de service</h1>
                            <div className="multi">
                                <div className="">
                                    <div className="table-header">
                                        <table>
                                            <colgroup>
                                                <col style={{ width: '25%' }} />
                                                <col style={{ width: '25%', textAlign: "right" }} />
                                                <col style={{ width: '25%', textAlign: "right" }} />
                                                <col style={{ width: '25%', textAlign: "right" }} />
                                            </colgroup>
                                            <thead>
                                                <tr>
                                                    <th>Tâches</th>
                                                    <th style={{ textAlign: "right" }}>Main d'oeuvre</th>
                                                    <th style={{ textAlign: "right" }}>Pièces</th>
                                                    <th style={{ textAlign: "right" }}>Total</th>
                                                </tr>
                                            </thead>
                                        </table>
                                    </div>
                                    <div className="table-list table-list-base-height">
                                        <table>
                                            <colgroup>
                                                <col style={{ width: '25%' }} />
                                                <col style={{ width: '25%', textAlign: "right" }} />
                                                <col style={{ width: '25%', textAlign: "right" }} />
                                                <col style={{ width: '25%', textAlign: "right" }} />
                                            </colgroup>
                                            <tbody>
                                                {ordre.listeItems?.map((item, index) => (
                                                    <tr key={index}>
                                                        <td>{item?.libelleTacheService}</td>
                                                        <td style={{ textAlign: "right" }}>{formatCurrency(item?.coutMainOeuvre)}</td>
                                                        <td style={{ textAlign: "right" }}>{formatCurrency(item?.coutPiecesRechange)}</td>
                                                        <td style={{ textAlign: "right" }}>{formatCurrency(item?.coutTotal)}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="table-header">
                                        <table>
                                            <colgroup>
                                                <col style={{ width: '25%' }} />
                                                <col style={{ width: '25%', textAlign: "right" }} />
                                                <col style={{ width: '25%', textAlign: "right" }} />
                                                <col style={{ width: '25%', textAlign: "right" }} />
                                            </colgroup>
                                            <thead>
                                                <tr>
                                                    <th>Total {authData?.unite_noneitaire}</th>
                                                    <th style={{ textAlign: "right" }}>{formatCurrency(ordre?.coutMainOeuvre)} {authData?.unite_moneitaire}</th>
                                                    <th style={{ textAlign: "right" }}>{formatCurrency(ordre?.coutPiecesRechange)} {authData?.unite_moneitaire}</th>
                                                    <th style={{ textAlign: "right" }}>{formatCurrency(ordre?.coutTotal)} {authData?.unite_moneitaire}</th>
                                                </tr>
                                            </thead>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card">
                            <h1>Problèmes résolus</h1>
                            <div className="multi">
                                <div className="">
                                    <div className="table-header">
                                        <table>
                                            <colgroup>
                                                <col style={{ width: '35%' }} />
                                                <col style={{ width: '35%' }} />
                                            </colgroup>
                                            <thead>
                                                <tr>
                                                    <th>Problème</th>
                                                    <th>Description</th>
                                                </tr>
                                            </thead>
                                        </table>
                                    </div>
                                    <div className="table-list table-list-med-height">
                                        <table>
                                            <colgroup>
                                                <col style={{ width: '35%' }} />
                                                <col style={{ width: '35%' }} />
                                            </colgroup>
                                            <tbody>
                                                {ordre.listeProblemes?.map((item, index) => (
                                                    <tr key={index}>
                                                        <td>{item?.titre}</td>
                                                        <td>{item?.description}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card">
                            <h1>Commentaires</h1>
                            <div className="multi para-contain">
                                <p className="paragraph">
                                    {ordre?.commentaire}
                                </p>
                            </div>
                            <h3 className='text-start'>Documents</h3>
                            {ordre.listPathDocumentsJustificatifs?.map((item, index) => (
                                <h6 key={index} className="heading-primary text-start" style={{ cursor: "pointer" }} onClick={() => showImage(item, ordre.sourceEntree)}>
                                    {item}
                                </h6>
                            ))}
                        </div>
                    </div>}
                </div>
            </div>
            <ModalImage
                open={showImagePopUp}
                onClose={handleCancelImage}
                onConfirm={handleOpenInNewTab}
                titleMessage="Photo attachée"
                urlImage={OrdreTravailImg + imageSrc}
                btnCancelText="Annuler"
                btnConfirmText="Ouvrir dans un nouvel onglet"
            />
        </section>
    );
}

export default ApercuOrdre;
