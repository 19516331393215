import React, { useEffect, useState, useContext, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import moment from 'moment/moment';
import Select from "react-select";
import { CustomToast } from '../../components/Toast/CustomToast';
import useDebounce from '../../hooks/useDebounce';
import { axiosInstance, Vehicule, VehiculeCarburant, VehiculeTypeCarburant, Fournisseur, ModePaiement } from '../../services/api';
import { AuthContext } from '../../contexts/AuthContext';
const AjouterCarburant = () => {
    const { authData } = useContext(AuthContext);
    const navigate = useNavigate();

    const [vehicules, setVehicules] = useState([]);
    const [typesCarburant, setTypesCarburant] = useState([]);
    const [fournisseurs, setFournisseurs] = useState([]);
    const [modePaiements, setModePaiements] = useState([]);

    const pathImageBonCarburantRef = useRef(null);
    const [pathImageBonCarburant, setPathImageBonCarburant] = useState(null);

    //Errors handle
    const [vehicleNotFound, setVehicleNotFound] = useState(false);
    const [kiloDebLess, setKiloDebLess] = useState({ display: false, msg: "" });
    const [kiloDebGreater, setKiloDebGreater] = useState({ display: false, msg: "" });
    const [kiloDebBetween, setKiloDebBetween] = useState({ display: false, msg: "" });

    const [enteredVehicleText, setEnteredVehicleText] = useState('');
    const debouncedVehiculeText = useDebounce(enteredVehicleText, 300);

    const getImageFileName = (file) => {
        return file ? file.name : 'No file selected';
    };

    //Load data

    const handlePathImageBonCarburantChange = (e) => {
        setPathImageBonCarburant(e.target.files[0]);
    };

    const getVehicules = async (nomVehicule) => {
        try {
            const queryParams = new URLSearchParams({
                pageNumber: 1,
                pageSize: 10
            });

            if (authData?.wilayas != null) {
                const wilayasFilter = authData?.wilayas
                queryParams.append("wilayas", wilayasFilter.join(','));
            }
            else {
                return
            }

            if (authData?.flottesAutorises != null) {
                const flottesAutorisesFilter = authData?.flottesAutorises
                queryParams.append("flottesAutorises", flottesAutorisesFilter.join(','));
            }
            else {
                return
            }

            if (nomVehicule !== '') {
                queryParams.append("nom", nomVehicule);
            }
            const response = await axiosInstance.get(`${Vehicule}/GetVehiculesAutocomplete?${queryParams}`);
            const resp = await response.data;
            setVehicules(resp.data);
        }
        catch (err) {
            console.log(err);
        }
    }

    const getFournisseurs = async () => {
        try {
            const queryParams = new URLSearchParams({
                carburant: true
            });

            const response = await axiosInstance.get(`${Fournisseur}/GetFournisseurType?${queryParams}`);
            const resp = await response.data;
            setFournisseurs(resp);
        }
        catch (err) {
            console.log(err);
        }
    }

    const getTypesCarburant = async () => {
        try {
            const response = await axiosInstance.get(`${VehiculeTypeCarburant}`);
            const resp = await response.data;
            setTypesCarburant(resp);
        }
        catch (err) {
            console.log(err);
        }
    }

    const getTypeCarburant = async (idTypeCarburant) => {
        try {
            const response = await axiosInstance.get(`${VehiculeTypeCarburant}/${idTypeCarburant}`);
            const resp = await response.data;
        }
        catch (err) {
            console.log(err);
        }
    }

    const getTypeCarburantVehicule = async (idVehicule) => {
        try {
            const response = await axiosInstance.get(`${VehiculeTypeCarburant}/GetTypeCarburantByIdVehicule/${idVehicule}`);
            const resp = await response.data;
            //console.log(resp)
            values.idTypeCarburant = resp.idTypeCarburant;
            setFieldValue('idTypeCarburant', resp.idTypeCarburant);
            //setIdTypeCarburant(resp.idTypeCarburant);
        }
        catch (err) {
            console.log(err);
        }
    }

    const getModesPaiements = async () => {
        try {
            const response = await axiosInstance.get(`${ModePaiement}`);
            const resp = await response.data;
            setModePaiements(resp);
        }
        catch (err) {
            console.log(err);
        }
    }

    const { handleChange, values, handleSubmit, touched, errors, setFieldValue } = useFormik({
        initialValues: {
            vehicule: null,
            idTypeCarburant: '',
            quantite: '',
            cout: '',
            dateRemplissage: '',
            valeurCompteur: '',
            idFournisseur: '',
            observation: '',
            reference: '',
            idUtilisateur: '',
            idModePaiement: ''
        },
        validationSchema: Yup.object({
            vehicule: Yup.object()
                .nullable()
                .required('Champ obligatoire'),
            dateRemplissage: Yup.string()
                .required("Champ obligatoire"),
            idTypeCarburant: Yup.string()
                .required("Champ obligatoire"),
            quantite: Yup.number()
                .min(0, "Le volume ne peut pas être négative"),
            cout: Yup.number()
                .required("Champ obligatoire")
                .min(0, "Le coût ne peut pas être négative"),
            valeurCompteur: Yup.number()
                .required("Champ obligatoire")
                .min(0, "Le relevé kilométrique ne peut pas être négative"),

        }),
        onSubmit: async (values) => {
            try {
                // const postData = {
                //     idVehicule: values.vehicule.idVehicule,
                //     idTypeCarburant: values.idTypeCarburant,
                //     quantite: values.quantite,
                //     cout: values.cout.toString() || '',
                //     dateRemplissage: values.dateRemplissage,
                //     valeurCompteur: values.valeurCompteur,
                //     idFournisseur: values.idFournisseur,
                //     observation: values.observation,
                //     reference: values.reference,
                //     pathImageBonCarburant: pathImageBonCarburant,
                //     idUtilisateur: authData.idUser,
                // };
                //console.log(postData);
                const formData = new FormData();
                formData.append('idVehicule', values.vehicule.idVehicule);
                formData.append('idTypeCarburant', values.idTypeCarburant);
                formData.append('quantite', values.quantite);
                formData.append('cout', values.cout.toString());
                formData.append('dateRemplissage', values.dateRemplissage);
                formData.append('valeurCompteur', values.valeurCompteur);
                formData.append('idFournisseur', values.idFournisseur);
                formData.append('observation', values.observation);
                formData.append('reference', values.reference);
                formData.append('idModePaiement', values.idModePaiement);
                formData.append('pathImageBonCarburant', pathImageBonCarburant);
                formData.append('idUtilisateur', authData.idUser);

                await axiosInstance.post(VehiculeCarburant, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });

                CustomToast("L'entrée de carburant a été créée avec succès", 'success');
                navigate('/listcarburants', { replace: true });
            } catch (error) {
                // Handle error
                if (!error?.response) {
                    CustomToast("Aucune réponse du serveur", 'error');
                    setVehicleNotFound(false);

                    setKiloDebGreater({ display: false, msg: "" });
                    setKiloDebLess({ display: false, msg: "" });
                    setKiloDebBetween({ display: false, msg: "" });
                } else if (error.response?.status === 400 && error.response?.data === "Vehicle not found") {
                    CustomToast("Véhicule introuvable", 'error');
                    setVehicleNotFound(true);

                    setKiloDebGreater({ display: false, msg: "" });
                    setKiloDebLess({ display: false, msg: "" });
                    setKiloDebBetween({ display: false, msg: "" });
                } else if (error.response?.status === 400 && error.response?.data?.msg === "Incorrect start value, must be greater") {
                    CustomToast("L'entrée de carburant n'a pas pu être créée", 'error');
                    setKiloDebGreater({ display: true, msg: `Doit être supérieur à ${error.response?.data?.val1}` });
                    setVehicleNotFound(false);

                    setKiloDebLess({ display: false, msg: "" });
                    setKiloDebBetween({ display: false, msg: "" });
                } else if (error.response?.status === 400 && error.response?.data?.msg === "Incorrect start value, must be less") {
                    CustomToast("L'entrée de carburant n'a pas pu être créée", 'error');
                    setKiloDebLess({ display: true, msg: `Doit être inférieur à ${error.response?.data?.val1}` });
                    setVehicleNotFound(false);

                    setKiloDebGreater({ display: false, msg: "" });
                    setKiloDebBetween({ display: false, msg: "" });
                } else if (error.response?.status === 400 && error.response?.data?.msg === "Incorrect start value, must be between") {
                    CustomToast("L'entrée de carburant n'a pas pu être créée", 'error');
                    setKiloDebBetween({ display: true, msg: `Doit être entre ${error.response?.data?.val1} et ${error.response?.data?.val2}` });
                    setVehicleNotFound(false);

                    setKiloDebGreater({ display: false, msg: "" });
                    setKiloDebLess({ display: false, msg: "" });
                } else if (error.response?.status === 400 && error.response?.data?.msg === "Incorrect end value, must be greater") {
                    CustomToast("L'entrée de carburant n'a pas pu être créée", 'error');
                    setKiloDebGreater({ display: true, msg: `Doit être supérieur à ${error.response?.data?.val1}` });
                    setVehicleNotFound(false);

                    setKiloDebLess({ display: false, msg: "" });
                    setKiloDebBetween({ display: false, msg: "" });
                } else if (error.response?.status === 400 && error.response?.data?.msg === "Incorrect end value, must be less") {
                    CustomToast("L'entrée de carburant n'a pas pu être créée", 'error');
                    setKiloDebLess({ display: true, msg: `Doit être inférieur à ${error.response?.data?.val1}` });
                    setVehicleNotFound(false);

                    setKiloDebGreater({ display: false, msg: "" });
                    setKiloDebBetween({ display: false, msg: "" });
                } else if (error.response?.status === 400 && error.response?.data?.msg === "Incorrect end value, must be between") {
                    CustomToast("L'entrée de carburant n'a pas pu être créée", 'error');
                    setKiloDebBetween({ display: true, msg: `Doit être entre ${error.response?.data?.val1} et ${error.response?.data?.val2}` });
                    setVehicleNotFound(false);

                    setKiloDebGreater({ display: false, msg: "" });
                    setKiloDebLess({ display: false, msg: "" });
                } else if (error.response?.status === 401) {
                    CustomToast("Non autorisé", 'error');
                    setVehicleNotFound(false);
                    setKiloDebGreater({ display: false, msg: "" });
                    setKiloDebLess({ display: false, msg: "" });
                    setKiloDebBetween({ display: false, msg: "" });
                } else {
                    CustomToast("Demande échoué", 'error');
                    setVehicleNotFound(false);
                    setKiloDebGreater({ display: false, msg: "" });
                    setKiloDebLess({ display: false, msg: "" });
                    setKiloDebBetween({ display: false, msg: "" });
                }
                console.log(error);
            }
        }
    });

    //Load data

    useEffect(() => {
        //if (debouncedVehiculeText) {
        getVehicules(debouncedVehiculeText);
        //}
        return () => {
            // Perform any necessary cleanup (e.g., cancel pending requests)
        };
    }, [authData, debouncedVehiculeText]);

    useEffect(() => {
        //Load véhicule
        //getVehicules('');
        //Load type de carburant
        getTypesCarburant();
        //Load Fournisseurs
        getFournisseurs();
        // if(values.vehicule){
        //     getTypeCarburantVehicule(values.vehicule.idVehicule);
        // }
        return () => {
            // Perform any necessary cleanup (e.g., cancel pending requests)
        };
    }, [])

    useEffect(() => {
        getModesPaiements();
        return () => {
            // Perform any necessary cleanup (e.g., cancel pending requests)
        };
    }, [])

    useEffect(() => {
        if (values.vehicule) {
            getTypeCarburantVehicule(values.vehicule.idVehicule);
            return
        }
    }, [values.vehicule])

    return (
        <section className="vehicles-section">
            <div className="container">
                <form onSubmit={handleSubmit} className='carburant-form'>
                    <div className="">
                        <div className='grid grid--2-cols'>
                            <div className="form-sec">
                                <div className="retour" id="retour-carburant">
                                    <Link to="/listcarburants" style={{ textDecoration: "none" }}>
                                        <div className="arrow-back" id="arrow-back-affectation-form">
                                            <span className="material-symbols-outlined" id="arrow">arrow_back</span>
                                            <h3 className="heading-primary"></h3>
                                        </div>
                                    </Link>
                                </div>
                                <div className="form-details">
                                    <div className="detail-form" id="identification-form">
                                        <h3 className="heading-primary"></h3>
                                        <div className="col">
                                            <div className="grp">
                                                <div className="gp">
                                                    <label >Véhicule<span className='requiredField'>*</span></label>
                                                    <Select
                                                        id="vehicule"
                                                        name="vehicule"
                                                        value={values.vehicule}
                                                        onChange={(option) => { setFieldValue('vehicule', option); setVehicleNotFound(false) }}
                                                        options={vehicules}
                                                        onInputChange={(inputValue) => {
                                                            // Update the entered text
                                                            setEnteredVehicleText(inputValue);
                                                        }}
                                                        getOptionLabel={(option) => option.marque + '[' + option.modele + '] ' + option.immatriculation}
                                                        getOptionValue={(option) => option.idVehicule}
                                                        isClearable
                                                        styles={{
                                                            container: (provided, state) => ({
                                                                ...provided,
                                                                boxShadow: state.isFocused ? '0px 0px 5px 0px #65458d' : errors.vehicule ? 'red' : '#BEBEBE',
                                                            }),
                                                            control: (baseStyles, state) => ({
                                                                ...baseStyles,
                                                                minHeight: '19px',
                                                                outline: '0!important',
                                                                borderRadius: '0.5rem',
                                                                borderWidth: '1px',
                                                                fontSize: '1rem',
                                                                height: '3.1rem',
                                                            }),
                                                            option: (provided, state) => ({
                                                                ...provided,
                                                                fontSize: '1rem'
                                                            }),
                                                            singleValue: (provided, state) => ({
                                                                ...provided,
                                                                fontSize: '1rem',
                                                                margin: '0',
                                                            }),
                                                            valueContainer: (provided, state) => ({
                                                                ...provided,
                                                                alignItems: 'start',
                                                                fontSize: '1rem'
                                                            }),
                                                        }}
                                                    />
                                                    {touched.vehicule && errors.vehicule && (
                                                        <div className="invalid-feedback">{errors.vehicule}</div>
                                                    )}
                                                    {vehicleNotFound && (
                                                        <div className="invalid-feedback">Véhicule introuvable</div>
                                                    )}
                                                </div>
                                            </div>
                                            {values.vehicule && <><div className="grp">
                                                <div className="gp">
                                                    <label >Date d'entrée du carburant<span className='requiredField'>*</span></label>
                                                    <input
                                                        type="date"
                                                        id="dateRemplissage"
                                                        name="dateRemplissage"
                                                        style={(touched.dateRemplissage && errors.dateRemplissage) ? { borderColor: "red" } : null}
                                                        placeholder=""
                                                        defaultValue={values.dateRemplissage}
                                                        onChange={handleChange}
                                                    />
                                                    {touched.dateRemplissage && errors.dateRemplissage ? (
                                                        <div className="invalid-feedback">{errors.dateRemplissage}</div>
                                                    ) : null}
                                                </div>
                                                <div className="gp">
                                                    <label >Compteur<span className='requiredField'>*</span></label>
                                                    <input
                                                        type="number"
                                                        id="valeurCompteur"
                                                        name="valeurCompteur"
                                                        placeholder=""
                                                        defaultValue={values.valeurCompteur}
                                                        onChange={handleChange}
                                                        step="any"
                                                    />
                                                    {errors.valeurCompteur && touched.valeurCompteur && (
                                                        <div className="invalid-feedback">{errors.valeurCompteur}</div>
                                                    )}
                                                    {kiloDebLess.display && (
                                                        <div className="invalid-feedback">{kiloDebLess.msg}</div>
                                                    )}
                                                    {kiloDebGreater.display && (
                                                        <div className="invalid-feedback">{kiloDebGreater.msg}</div>
                                                    )}
                                                    {kiloDebBetween.display && (
                                                        <div className="invalid-feedback">{kiloDebBetween.msg}</div>
                                                    )}
                                                </div>
                                            </div>
                                                <div className="grp">
                                                    <div className="gp">
                                                        <label >Type de carburant<span className='requiredField'>*</span></label>
                                                        <select
                                                            id="idTypeCarburant"
                                                            name="idTypeCarburant"
                                                            value={values.idTypeCarburant}
                                                            onChange={handleChange}
                                                            style={(touched.idTypeCarburant && errors.idTypeCarburant) ? { borderColor: "red" } : null}
                                                        >
                                                            <option value="">Veuillez sélectionner</option>
                                                            {typesCarburant.map((typeCarburant) => (
                                                                <option key={typeCarburant.idTypeCarburant} value={typeCarburant.idTypeCarburant}>{typeCarburant.libelleTypeCarburant}</option>
                                                            ))}
                                                        </select>
                                                        {touched.idTypeCarburant && errors.idTypeCarburant ? (
                                                            <div className="invalid-feedback">{errors.idTypeCarburant}</div>
                                                        ) : null}
                                                    </div>
                                                    <div className="gp" title='Le volume de carburant'>
                                                        <label>Volume</label>
                                                        <input
                                                            type="number"
                                                            id="quantite"
                                                            name="quantite"
                                                            placeholder=""
                                                            defaultValue={values.quantite}
                                                            onChange={handleChange}
                                                            step="any"
                                                        />
                                                        {errors.quantite && touched.quantite && (
                                                            <div className="invalid-feedback">{errors.quantite}</div>
                                                        )}
                                                    </div>
                                                    <div className="gp">
                                                        <label>Coût<span className='requiredField'>*</span></label>
                                                        <input
                                                            type="number"
                                                            id="cout"
                                                            name="cout"
                                                            placeholder=""
                                                            defaultValue={values.cout}
                                                            onChange={handleChange}
                                                            step="any"
                                                        />
                                                        {errors.cout && touched.cout && (
                                                            <div className="invalid-feedback">{errors.cout}</div>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="grp">
                                                    <div className="gp">
                                                        <label >Mode de paiement</label>
                                                        <select
                                                            id="idModePaiement"
                                                            name="idModePaiement"
                                                            value={values.idModePaiement}
                                                            onChange={handleChange}
                                                        >
                                                            <option value="">Veuillez sélectionner</option>
                                                            {modePaiements.map((modeP) => (
                                                                <option key={modeP.idModePaiement} value={modeP.idModePaiement}>{modeP.libelleModePaiement}</option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                    <div className="gp">
                                                        <label >Fournisseur</label>
                                                        <select
                                                            id="idFournisseur"
                                                            name="idFournisseur"
                                                            value={values.idFournisseur}
                                                            onChange={handleChange}
                                                        >
                                                            <option value="">Veuillez sélectionner</option>
                                                            {fournisseurs.map((fournisseur) => (
                                                                <option key={fournisseur.idFournisseur} value={fournisseur.idFournisseur}>{fournisseur.raisonSociale}</option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                    <div className="gp" title='Par exemple: numéro de facture, ID de transaction, etc'>
                                                        <label>Référence</label>
                                                        <input
                                                            type="text"
                                                            id="reference"
                                                            name="reference"
                                                            placeholder=""
                                                            defaultValue={values.reference}
                                                            onChange={handleChange}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="grp">
                                                    <div className="gp" title='Par exemple: facture, ticket, etc'>
                                                        <label>Justificatif</label>
                                                        <label class="upload-file">
                                                        upload file
                                                        <input
                                                            ref={pathImageBonCarburantRef}
                                                            id="pathImageBonCarburant"
                                                            name="pathImageBonCarburant"
                                                            type="file"
                                                            onChange={handlePathImageBonCarburantChange}
                                                        />
                                                    </label>
                                                        {pathImageBonCarburant && <span style={{ fontSize: "10px" }}>{getImageFileName(pathImageBonCarburant)}
                                                            <span className="material-symbols-outlined" onClick={(e) => { setPathImageBonCarburant(null); if (pathImageBonCarburantRef.current) { pathImageBonCarburantRef.current.value = ''; } }}>delete</span>
                                                        </span>}
                                                    </div>
                                                </div>
                                                <div className="grp">
                                                    <div className="gp">
                                                        <label>Remarques</label>
                                                        <textarea
                                                            type="text"
                                                            rows={4}
                                                            id="observation"
                                                            name="observation"
                                                            placeholder=""
                                                            defaultValue={values.observation}
                                                            onChange={handleChange}
                                                        />
                                                    </div>
                                                </div>
                                            </>}
                                        </div>
                                    </div>
                                    <div className="submit-container">
                                        <button type="submit" className="submit-btn">Enregistrer</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </section>
    );
}

export default AjouterCarburant;
