import React, { useEffect, useState, useContext, useRef } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import moment from 'moment/moment';
import { CustomToast } from '../../components/Toast/CustomToast';
import ConfirmationDialog from '../../components/Confirmation/ConfirmationDialog';
import { axiosInstance, VehiculeControleTechnique, ControleTechniqueImg } from '../../services/api';
import { AuthContext } from '../../contexts/AuthContext';

const ListControles = () => {
    const { authData } = useContext(AuthContext);
    const navigate = useNavigate();
    const { id } = useParams();//idVehiculeControleTechnique

    const [controles, setControles] = useState([]);
    const [filtredControles, setFiltredControles] = useState([]);

    const [pageNumber, setPageNumber] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalItems, setTotalItems] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [loading, setLoading] = useState(true);
    const [showAddModal, setShowAddModal] = useState(false);
    const [showUpdateModal, setShowUpdateModal] = useState(false);
    const [idVehiculeControleTechniqueDelete, setIdVehiculeControleTechniqueDelete] = useState('');
    const [idVehiculeControleTechniqueUpdate, setIdVehiculeControleTechniqueUpdate] = useState('');
    const [pathImageControle, setPathImageControle] = useState(null);
    const [pathImageControleName, setPathImageControleName] = useState('');
    const pathImageControleRef = useRef(null);

    const [search, setSearch] = useState('');
    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);

    //table management
    const [sortBy, setSortBy] = useState(''); // Column name to sort by
    const [sortOrder, setSortOrder] = useState('asc'); // 'asc' or 'desc'

    const handleSort = (column) => {
        setSortBy(column);
        setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');

        let sortedTable = [...filtredControles].sort((a, b) => {
            const aValue = a[sortBy] || '';
            const bValue = b[sortBy] || '';

            if (sortOrder === 'asc') {
                return aValue.localeCompare(bValue);
            } else {
                return bValue.localeCompare(aValue);
            }
        });

        setFiltredControles(sortedTable);
    };

    const handlePageChange = (page) => {
        setPageNumber(page);
        //consolelog("setPageNumber="+pageNumber)
        const startIndex = (page - 1) * pageSize;
        const endIndex = startIndex + pageSize;
        const paginatedData = controles.slice(startIndex, endIndex);

        setFiltredControles(paginatedData);

    };

    const handlePerRowsChange = (newPerPage) => {
        setPageSize(newPerPage);
        //console.log("setPageSize="+pageSize);

    };

    const [openAboutList, setOpenAboutList] = useState(null);

    const toggleAboutList = (idVehicule) => {
        setOpenAboutList(openAboutList === idVehicule ? null : idVehicule);
    };

    const showHideAddModal = (val) => {
        setShowAddModal(val);
        setIdVehiculeControleTechniqueUpdate('');
        formik1.resetForm();
        if (val) {
            formik1.values.idVehicule = controles[0].idVehicule;
        }
    };

    const showHideUpdateModal = (val, idVehiculeControleTechnique, idVehicule, natureControle, numControle, resultatsControle, dateControle, dateProchainControle, uniteIntervalleTemps, echeanceTemps, pathImage) => {
        setShowUpdateModal(val);
        setIdVehiculeControleTechniqueUpdate(idVehiculeControleTechnique);
        formik2.resetForm();
        setPathImageControle(null);
            setPathImageControleName('');
        if (val) {
            // formik2.values.idVehiculeUpdate = idVehicule || '';
            // formik2.values.natureControleUpdate = natureControle || '';
            // formik2.values.numControleUpdate = numControle || '';
            // formik2.values.resultatsControleUpdate = resultatsControle || '';
            // formik2.values.dateControleUpdate = moment(dateControle).isValid() ? moment(dateControle).format('YYYY-MM-DD') : '' || '';
            // formik2.values.dateProchainControleUpdate = moment(dateProchainControle).isValid() ? moment(dateProchainControle).format('YYYY-MM-DD') : '' || '';
            // formik2.values.uniteIntervalleTempsUpdate = uniteIntervalleTemps || 'jour';
            // formik2.values.echeanceTempsUpdate = echeanceTemps == '0' ? '' : echeanceTemps || '';

            formik2.setValues(prevValues => ({
                ...prevValues,
                idVehiculeControleTechnique: idVehiculeControleTechnique,
                idVehiculeUpdate: idVehicule || '',
                natureControleUpdate: natureControle || '',
                numControleUpdate: numControle || '',
                resultatsControleUpdate: resultatsControle || '',
                dateControleUpdate: moment(dateControle).isValid() ? moment(dateControle).format('YYYY-MM-DD') : '' || '',
                dateProchainControleUpdate: moment(dateProchainControle).isValid() ? moment(dateProchainControle).format('YYYY-MM-DD') : '' || '',
                uniteIntervalleTempsUpdate: uniteIntervalleTemps || 'jour',
                echeanceTempsUpdate: echeanceTemps || '',
            }));
            setPathImageControle(null);
            setPathImageControleName(pathImage || '');
            //console.log(formik2.values);
        }
        //console.log(idVehiculeControleTechnique, idVehicule, natureControle, numControle, resultatsControle, dateControle, dateProchainControle, uniteIntervalleTemps, echeanceTemps, pathImage)
    };

    const handlePathImageControleChange = (e) => {
        setPathImageControle(e.target.files[0]);
        setPathImageControleName('');
    };

    const getImageFileName = (file) => {
        return file ? file.name : 'No file selected';
    };
    //Supprimer doddier
    const deleteDossier = async (idVehiculeControleTechnique) => {
        try {
            const response = await axiosInstance.delete(`${VehiculeControleTechnique}/${idVehiculeControleTechnique}`);
            getDossiers();
            CustomToast("Le dossier a été supprimé avec succès", 'success');
        }
        catch (error) {
            // Handle error
            if (!error?.response) {
                CustomToast("Aucune réponse du serveur", 'error');
            } else if (error.response?.status === 401) {
                CustomToast("Non autorisé", 'error');
            } else {
                CustomToast("Demande échoué", 'error');
            }
            console.log(error);
        }
    }

    const handleConfirmDelete = (idVehiculeControleTechnique) => {
        // Show the modal when the confirm delete button is clicked
        //deleteDossier(idVehiculeControleTechnique);
        setIdVehiculeControleTechniqueDelete(idVehiculeControleTechnique);
        setConfirmDialogOpen(true);
    };

    const handleDelete = async () => {
        try {
            const response = await axiosInstance.delete(`${VehiculeControleTechnique}/${idVehiculeControleTechniqueDelete}`);
            //getParcs();
            setIdVehiculeControleTechniqueDelete('');
            CustomToast("Le dossier a été supprimé avec succès", 'success');
            getDossiers();
            setConfirmDialogOpen(false);
        }
        catch (error) {
            // Handle error
            if (!error?.response) {
                CustomToast("Aucune réponse du serveur", 'error');
            } else if (error.response?.status === 401) {
                CustomToast("Non autorisé", 'error');
            } else {
                CustomToast("Demande échoué", 'error');
            }

            handleCancelDelete();

            console.log(error);
        }
    }

    const handleCancelDelete = () => {
        setConfirmDialogOpen(false);
    };

    //Ajouter/Modifier
    const formik1 = useFormik({
        initialValues: {
            idVehicule: '',
            natureControle: '',
            numControle: '',
            resultatsControle: '',
            dateControle: '',
            dateProchainControle: '',
            uniteIntervalleTemps: 'jour',
            echeanceTemps: ''
        },
        validationSchema: Yup.object({
            dateControle: Yup.string()
                .required("Champ obligatoire"),
            dateProchainControle: Yup.string()
                .required("Champ obligatoire"),
            echeanceTemps: Yup.string()
                .required("Champ obligatoire"),
        }),
        onSubmit: async (values) => {
            try {
                //console.log(values);

                const formData = new FormData();

                formData.append('idVehiculeControleTechnique', "");
                formData.append('idVehicule', values.idVehicule);
                formData.append('natureControle', values.natureControle);
                formData.append('numControle', values.numControle);
                formData.append('resultatsControle', values.resultatsControle);
                formData.append('dateControle', values.dateControle);
                formData.append('dateProchainControle', values.dateProchainControle);
                formData.append('uniteIntervalleTemps', values.uniteIntervalleTemps);
                formData.append('echeanceTemps', values.echeanceTemps);
                formData.append('pathImageControle', pathImageControle);
                formData.append('idUtilisateurAction', authData.idUser);

                const response = await axiosInstance.post(VehiculeControleTechnique, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });

                CustomToast("Le dossier a été créé avec succès", 'success');
                getDossiers();
                setShowAddModal(false);
            } catch (error) {
                // Handle error
                if (!error?.response) {
                    CustomToast("Aucune réponse du serveur", 'error');
                } else if (error.response?.status === 400 && error.response?.data === "Overlap existing controle") {
                    CustomToast("Overlap existing controle", 'error');
                } else if (error.response?.status === 401) {
                    CustomToast("Non autorisé", 'error');
                } else {
                    CustomToast("Demande échoué", 'error');
                }
                console.log(error);
            }
        }
    });

    const formik2 = useFormik({
        initialValues: {
            idVehiculeControleTechnique: '',
            idVehiculeUpdate: '',
            natureControleUpdate: '',
            numControleUpdate: '',
            resultatsControleUpdate: '',
            dateControleUpdate: '',
            dateProchainControleUpdate: '',
            uniteIntervalleTempsUpdate: 'jour',
            echeanceTempsUpdate: ''
        },
        validationSchema: Yup.object({
            dateControleUpdate: Yup.string()
                .required("Champ obligatoire"),
            dateProchainControleUpdate: Yup.string()
                .required("Champ obligatoire"),
            echeanceTempsUpdate: Yup.string()
                .required("Champ obligatoire"),
        }),
        onSubmit: async (values) => {
            try {
                //console.log(values);
                const formData = new FormData();

                formData.append('idVehiculeControleTechnique', values.idVehiculeControleTechnique);
                formData.append('idVehicule', values.idVehiculeUpdate);
                formData.append('natureControle', values.natureControleUpdate);
                formData.append('numControle', values.numControleUpdate);
                formData.append('resultatsControle', values.resultatsControleUpdate);
                formData.append('dateControle', values.dateControleUpdate);
                formData.append('dateProchainControle', values.dateProchainControleUpdate);
                formData.append('uniteIntervalleTemps', values.uniteIntervalleTempsUpdate);
                formData.append('echeanceTemps', values.echeanceTempsUpdate);
                formData.append('pathImageControle', pathImageControle);
                formData.append('pathImageControleName', pathImageControleName);
                formData.append('idUtilisateurAction', authData.idUser);

                const response = await axiosInstance.put(VehiculeControleTechnique, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });

                // //console.log(postData);               
                CustomToast("Le dossier a été modifié avec succès", 'success');
                showHideUpdateModal(false, '', '', '', '', '', '', '', '', '', '');
                getDossiers();
            } catch (error) {
                // Handle error
                if (!error?.response) {
                    CustomToast("Aucune réponse du serveur", 'error');
                } else if (error.response?.status === 400 && error.response?.data === "Overlap existing controle") {
                    CustomToast("Overlap existing controle", 'error');
                } else if (error.response?.status === 400 && error.response?.data === "Controle not found") {
                    CustomToast("Controle not found", 'error');
                } else if (error.response?.status === 401) {
                    CustomToast("Non autorisé", 'error');
                } else {
                    CustomToast("Demande échoué", 'error');
                }
                console.log(error);
            }
        }
    });

    //Load data

    const getDossiers = async () => {
        try {
            const response = await axiosInstance.get(`${VehiculeControleTechnique}/GetControleVehicule/${id}`);
            const resp = await response.data;

            setControles(resp);
            if (search != '') {
                const result = controles?.filter((item) =>
                    Object.values(item).some(
                        (value) =>
                            value &&
                            value.toString().toLowerCase().includes(search.toLowerCase())
                    )
                );
                setFiltredControles(result);
            } else {
                setFiltredControles(resp);
            }

            setLoading(false);
        }
        catch (err) {
            setLoading(false);
            console.log(err);
        }
    }

    useEffect(() => {
        //Load Data
        getDossiers();
        return () => {
            // Perform any necessary cleanup (e.g., cancel pending requests)
        };
    }, []);

    useEffect(() => {
        const result = controles?.filter((item) =>
            Object.values(item).some(
                (value) =>
                    value &&
                    value.toString().toLowerCase().includes(search.toLowerCase())
            )
        );
        setFiltredControles(result);
    }, [search, controles])

    return (
        <section className="vehicles-section" onClick={() => { if (openAboutList !== null) { setOpenAboutList(null) } }}>
            <div className="container">
                <div className="vehicule-sec-cntr-about wrap-all">
                    <div className="row row-center w-full">
                        <Link to="/listvehicules" className="retour cursurer" style={{ textDecoration: "none" }}>
                            <div className="arrow-back">
                                <span className="material-symbols-outlined" id="back-assurance">arrow_back</span>
                            </div>
                        </Link>

                        <Link to="#" style={{ textDecoration: "none" }} onClick={() => showHideAddModal(true)} className="color-primary-2 decoration-none">
                            <div className="add-new" id="btn-add-assrance">
                                Ajouter
                                <span className="material-symbols-outlined success">add</span>
                            </div>
                        </Link>
                    </div>

                    <div className="container grid grid--3-cols grid--2-rows">
                        <div className="card grid grid--grid-area-1">
                            <div className="column spec-selec">
                                <div className="row row-center">
                                    <h3 className="heading-primary">Nom</h3>
                                    <p>{controles[0]?.vehiculeNom}</p>
                                </div>
                                <div className="row row-center">
                                    <h3 className="heading-primary">Marque</h3>
                                    <p>{controles[0]?.vehiculeMarque}</p>
                                </div>
                                <div className="row row-center">
                                    <h3 className="heading-primary">Modèle</h3>
                                    <p>{controles[0]?.vehiculeModele}</p>
                                </div>
                                <div className="row row-center">
                                    <h3 className="heading-primary">Matricule</h3>
                                    <p>{controles[0]?.vehiculeImmatriculation}</p>
                                </div>
                                <div className="row row-center">
                                    <h3 className="heading-primary">Statut</h3>
                                    <p>
                                        {(() => {
                                            if (controles[0]?.vehiculeStatut === "disponible") {
                                                return <><svg height="11.87" width="15"><circle cx="6" cy="6" r="6" fill="green" /></svg>Disponible</>;
                                            } else if (controles[0]?.vehiculeStatut === "indisponible") {
                                                return <><svg height="11.87" width="15"><circle cx="6" cy="6" r="6" fill="orangered" /></svg>Indisponible</>;
                                            } else if (controles[0]?.vehiculeStatut === "archivé") {
                                                return <><svg height="11.87" width="15"><circle cx="6" cy="6" r="6" fill="grey" /></svg>Archivé</>;
                                            } else if (controles[0]?.vehiculeStatut === "vendu") {
                                                return <><svg height="11.87" width="15"><circle cx="6" cy="6" r="6" fill="blue" /></svg>Vendu</>;
                                            } else if (controles[0]?.vehiculeStatut === "en cours d'utilisation") {
                                                return <><svg height="11.87" width="15"><circle cx="6" cy="6" r="6" fill="yellow" /></svg>En cours</>;
                                            } else if (controles[0]?.vehiculeStatut === "hors service") {
                                                return <><svg height="11.87" width="15"><circle cx="6" cy="6" r="6" fill="red" /></svg>Hors service</>;
                                            } else {
                                                return "";
                                            }
                                        })()}
                                    </p>
                                </div>
                                <div className="row row-center">
                                    <h3 className="heading-primary">Type</h3>
                                    <p>{controles[0]?.vehiculeType}</p>
                                </div>
                            </div>
                        </div>
                        <div className="card grid grid--grid-area-2">
                            <div className="table-wrap">
                                {filtredControles?.length > 0 &&
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>Nature du contrôle</th>
                                                <th>Date du contrôle</th>
                                                <th>Date de prochain contrôle</th>
                                                <th>Statut</th>
                                                <th>Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {filtredControles?.map((row) => (
                                                <tr key={row.idVehiculeControleTechnique}>
                                                    <td>{row.natureControle}</td>
                                                    <td>{row.dateControle ? (moment(row.dateControle).format('DD-MM-YYYY')) : ''}</td>
                                                    <td>{row.dateProchainControle ? (moment(row.dateProchainControle).format('DD-MM-YYYY')) : ''}</td>
                                                    {/* <td>
                                                        {
                                                            row.statut === "actif" ? (
                                                                <><svg height="11.87" width="15"><circle cx="6" cy="6" r="6" fill="green" /></svg>Actif</>
                                                            ) : row.statut === "expiré" ? (
                                                                <><svg height="11.87" width="15"><circle cx="6" cy="6" r="6" fill="red" /></svg>Expiré</>
                                                            ) : row.statut === "a venir" ? (
                                                                <><svg height="11.87" width="15"><circle cx="6" cy="6" r="6" fill="blue" /></svg>A venir</>
                                                            ) : null
                                                        }
                                                    </td> */}
                                                    <td>{
                                                        row.statut === "actif" ?
                                                            <><svg height="11.87" width="15"><circle cx="6" cy="6" r="6" fill="green" /></svg>Actif</>
                                                            : row.statut === "expiré" ?
                                                                <><svg height="11.87" width="15"><circle cx="6" cy="6" r="6" fill="red" /></svg>Expiré</>
                                                                : row.statut === "bientot expiré" ?
                                                                    <><svg height="11.87" width="15"><circle cx="6" cy="6" r="6" fill="orange" /></svg>Bientôt expiré</>
                                                                    : row.statut === "a venir" ?
                                                                        <><svg height="11.87" width="15"><circle cx="6" cy="6" r="6" fill="blue" /></svg>A venir</>
                                                                        : null
                                                    }</td>

                                                    <td className="txt-center relative">
                                                        <span className="material-symbols-outlined about-btn" onClick={() => toggleAboutList(row.idVehiculeControleTechnique)}>
                                                            more_horiz
                                                        </span>
                                                        {openAboutList === row.idVehiculeControleTechnique && (
                                                            <div className="about-list">
                                                                <ul>
                                                                    <li>
                                                                        <Link className='row row-center' to="#" style={{ textDecoration: "none" }} onClick={() => showHideUpdateModal(true, row.idVehiculeControleTechnique, row.idVehicule, row.natureControle, row.numControle, row.resultatsControle, row.dateControle, row.dateProchainControle, row.uniteIntervalleTemps, row.echeanceTemps, row.pathImageControleName)}>
                                                                            <span className="material-symbols-outlined">edit</span>Modifier
                                                                        </Link>
                                                                    </li>
                                                                    <li>
                                                                        <Link className='row row-center' to="#" style={{ textDecoration: "none" }} onClick={() => handleConfirmDelete(row.idVehiculeControleTechnique)}>
                                                                            <span className="material-symbols-outlined">delete</span>Supprimer
                                                                        </Link>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        )}
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                }
                            </div>
                        </div>
                    </div>

                    {showAddModal && <div className="popcard-add">
                        <div className="popcard-add_inside">
                            <Link className="popcard-add_inside-close" to="#" onClick={() => showHideAddModal(false)}>&#10006;</Link>
                            <h2 className="popcard-add_inside-title"></h2>
                            <div className="popcard-add_inside-form">
                                <form onSubmit={formik1.handleSubmit}>
                                    <div className="wrap-info">
                                        <div className="wrap-row">
                                            <div className="wrap-one">
                                                <label >Nature du contrôle</label>
                                                <input
                                                    id="natureControle"
                                                    name="natureControle"
                                                    type="text"
                                                    value={formik1.values.natureControle}
                                                    onChange={formik1.handleChange}
                                                />
                                            </div>
                                            <div className="wrap-one">
                                                <label >N° du contrôle</label>
                                                <input
                                                    id="numControle"
                                                    name="numControle"
                                                    type="text"
                                                    value={formik1.values.numControle}
                                                    onChange={formik1.handleChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="wrap-row">
                                            <div className="wrap-one">
                                                <label >Date du contrôle<span className='requiredField'>*</span></label>
                                                <input
                                                    type="date"
                                                    id="dateControle"
                                                    name="dateControle"
                                                    style={(formik1.touched.dateControle && formik1.errors.dateControle) ? { borderColor: "red" } : null}
                                                    placeholder=""
                                                    defaultValue={formik1.values.dateControle}
                                                    onChange={formik1.handleChange}
                                                />
                                                {formik1.touched.dateControle && formik1.errors.dateControle ? (
                                                    <div className="invalid-feedback">{formik1.errors.dateControle}</div>
                                                ) : null}
                                            </div>
                                            <div className="wrap-one">
                                                <label >Date de prochain contrôle<span className='requiredField'>*</span></label>
                                                <input
                                                    type="date"
                                                    id="dateProchainControle"
                                                    name="dateProchainControle"
                                                    style={(formik1.touched.dateProchainControle && formik1.errors.dateProchainControle) ? { borderColor: "red" } : null}
                                                    placeholder=""
                                                    defaultValue={formik1.values.dateProchainControle}
                                                    onChange={formik1.handleChange}
                                                />
                                                {formik1.touched.dateProchainControle && formik1.errors.dateProchainControle ? (
                                                    <div className="invalid-feedback">{formik1.errors.dateProchainControle}</div>
                                                ) : null}
                                            </div>
                                        </div>
                                        <div className="wrap-row">
                                            <div className="wrap-one" title="Le temps à l'avance pendant lequel vous considérez le rappel comme étant '' à échéance prochaine''">
                                                <label >Seuil d'échéance prochaine<span className='requiredField'>*</span></label>
                                                <input
                                                    type="number"
                                                    id="echeanceTemps"
                                                    name="echeanceTemps"
                                                    style={(formik1.touched.echeanceTemps && formik1.errors.echeanceTemps) ? { borderColor: "red" } : null}
                                                    placeholder=""
                                                    defaultValue={formik1.values.echeanceTemps}
                                                    onChange={formik1.handleChange}
                                                />
                                                {formik1.touched.echeanceTemps && formik1.errors.echeanceTemps ? (
                                                    <div className="invalid-feedback">{formik1.errors.echeanceTemps}</div>
                                                ) : null}
                                            </div>
                                            <div className='wrap-one'>
                                                <label style={{ opacity: 0 }}>unité</label>
                                                <select
                                                    id="uniteIntervalleTemps"
                                                    name="uniteIntervalleTemps"
                                                    value={formik1.values.uniteIntervalleTemps}
                                                    style={(formik1.touched.uniteIntervalleTemps && formik1.errors.uniteIntervalleTemps) ? { borderColor: "red" } : null}
                                                    onChange={formik1.handleChange}
                                                >
                                                    <option value="jour" defaultValue={true}>Jour(s)</option>
                                                    <option value="mois">Mois</option>
                                                </select>
                                                {formik1.touched.uniteIntervalleTemps && formik1.errors.uniteIntervalleTemps ? (
                                                    <div className="invalid-feedback">{formik1.errors.uniteIntervalleTemps}</div>
                                                ) : null}
                                            </div>
                                        </div>
                                        <div className="">
                                            <div className="wrap-one">
                                                <label >Document</label>
                                                <label className="upload-file">
                                                    upload file
                                                    <input
                                                        ref={pathImageControleRef}
                                                        id="pathImageControle"
                                                        name="pathImageControle"
                                                        type="file"
                                                        onChange={handlePathImageControleChange}
                                                    />
                                                </label>
                                                {pathImageControle && <span style={{ fontSize: "10px" }}>{getImageFileName(pathImageControle)}
                                                    <span className="material-symbols-outlined" onClick={(e) => { setPathImageControle(null); if (pathImageControleRef.current) { pathImageControleRef.current.value = ''; } }}>delete</span>
                                                </span>}
                                            </div>
                                        </div>
                                        <div className="wrap-row">
                                            <div className="wrap-one">
                                                <label >Résultats du contrôle</label>
                                                <textarea
                                                    id="resultatsControle"
                                                    name="resultatsControle"
                                                    type="text"
                                                    value={formik1.values.resultatsControle}
                                                    onChange={formik1.handleChange}
                                                    rows={4}
                                                    cols={50}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="popcard-operation-pickup-details_inside-zone">
                                        <div className="buttons">
                                            <input type="submit" value="Enregistrer" className="submit-btn" />
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>}

                    {showUpdateModal && <div className="popcard-add">
                        <div className="popcard-add_inside">
                            <Link className="popcard-add_inside-close" to="#" onClick={() => showHideUpdateModal(false, '', '', '', '', '', '', '', '', '', '')}>&#10006;</Link>
                            <h2 className="popcard-add_inside-title"></h2>
                            <div className="popcard-add_inside-form">
                                <form onSubmit={formik2.handleSubmit}>
                                    <div className="wrap-info">
                                        <div className="wrap-row">
                                            <div className="wrap-one">
                                                <label >Nature du contrôle</label>
                                                <input
                                                    id="natureControleUpdate"
                                                    name="natureControleUpdate"
                                                    type="text"
                                                    value={formik2.values.natureControleUpdate}
                                                    onChange={formik2.handleChange}
                                                />
                                            </div>
                                            <div className="wrap-one">
                                                <label >N° du contrôle</label>
                                                <input
                                                    id="numControleUpdate"
                                                    name="numControleUpdate"
                                                    type="text"
                                                    value={formik2.values.numControleUpdate}
                                                    onChange={formik2.handleChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="wrap-row">
                                            <div className="wrap-one">
                                                <label >Date du contrôle<span className='requiredField'>*</span></label>
                                                <input
                                                    type="date"
                                                    id="dateControleUpdate"
                                                    name="dateControleUpdate"
                                                    style={(formik2.touched.dateControleUpdate && formik2.errors.dateControleUpdate) ? { borderColor: "red" } : null}
                                                    placeholder=""
                                                    defaultValue={formik2.values.dateControleUpdate}
                                                    onChange={formik2.handleChange}
                                                />
                                                {formik2.touched.dateControleUpdate && formik2.errors.dateControleUpdate ? (
                                                    <div className="invalid-feedback">{formik2.errors.dateControleUpdate}</div>
                                                ) : null}
                                            </div>
                                            <div className="wrap-one">
                                                <label >Date de prochain contrôle<span className='requiredField'>*</span></label>
                                                <input
                                                    type="date"
                                                    id="dateProchainControleUpdate"
                                                    name="dateProchainControleUpdate"
                                                    style={(formik2.touched.dateProchainControleUpdate && formik2.errors.dateProchainControleUpdate) ? { borderColor: "red" } : null}
                                                    placeholder=""
                                                    defaultValue={formik2.values.dateProchainControleUpdate}
                                                    onChange={formik2.handleChange}
                                                />
                                                {formik2.touched.dateProchainControleUpdate && formik2.errors.dateProchainControleUpdate ? (
                                                    <div className="invalid-feedback">{formik2.errors.dateProchainControleUpdate}</div>
                                                ) : null}
                                            </div>
                                        </div>
                                        <div className="wrap-row">
                                            <div className="wrap-one" title="Le temps à l'avance pendant lequel vous considérez le rappel comme étant '' à échéance prochaine''">
                                                <label >Seuil d'échéance prochaine<span className='requiredField'>*</span></label>
                                                <input
                                                    type="number"
                                                    id="echeanceTempsUpdate"
                                                    name="echeanceTempsUpdate"
                                                    style={(formik2.touched.echeanceTempsUpdate && formik2.errors.echeanceTempsUpdate) ? { borderColor: "red" } : null}
                                                    placeholder=""
                                                    defaultValue={formik2.values.echeanceTempsUpdate}
                                                    onChange={formik2.handleChange}
                                                />
                                                {formik2.touched.echeanceTempsUpdate && formik2.errors.echeanceTempsUpdate ? (
                                                    <div className="invalid-feedback">{formik2.errors.echeanceTempsUpdate}</div>
                                                ) : null}
                                            </div>
                                            <div className='wrap-one'>
                                                <label style={{ opacity: 0 }}>unité</label>
                                                <select
                                                    id="uniteIntervalleTempsUpdate"
                                                    name="uniteIntervalleTempsUpdate"
                                                    value={formik2.values.uniteIntervalleTempsUpdate}
                                                    style={(formik2.touched.uniteIntervalleTempsUpdate && formik2.errors.uniteIntervalleTempsUpdate) ? { borderColor: "red" } : null}
                                                    onChange={formik2.handleChange}
                                                >
                                                    <option value="jour" defaultValue={true}>Jour(s)</option>
                                                    <option value="mois">Mois</option>
                                                </select>
                                                {formik2.touched.uniteIntervalleTempsUpdate && formik2.errors.uniteIntervalleTempsUpdate ? (
                                                    <div className="invalid-feedback">{formik2.errors.uniteIntervalleTempsUpdate}</div>
                                                ) : null}
                                            </div>
                                        </div>
                                        <div className="">
                                            <div className="wrap-one">
                                                <label >Document</label>
                                                <label className="upload-file">
                                                    upload file
                                                    <input
                                                        ref={pathImageControleRef}
                                                        id="pathImageControleUpdate"
                                                        name="pathImageControleUpdate"
                                                        type="file"
                                                        onChange={handlePathImageControleChange}
                                                    />
                                                </label>
                                                {pathImageControle && <span style={{ fontSize: "10px" }}>{getImageFileName(pathImageControle)}
                                                    <span className="material-symbols-outlined" onClick={(e) => { setPathImageControle(null); if (pathImageControleRef.current) { pathImageControleRef.current.value = ''; } }}>delete</span>
                                                </span>}
                                                {pathImageControleName && <span style={{ fontSize: "10px" }}>{pathImageControleName}
                                                    <span className="material-symbols-outlined" onClick={(e) => { setPathImageControle(null); setPathImageControleName(''); if (pathImageControleRef.current) { pathImageControleRef.current.value = ''; } }}>delete</span>
                                                </span>}
                                            </div>
                                        </div>
                                        <div className="wrap-row">
                                            <div className="wrap-one">
                                                <label >Résultats du contrôle</label>
                                                <textarea
                                                    id="resultatsControleUpdate"
                                                    name="resultatsControleUpdate"
                                                    type="text"
                                                    value={formik2.values.resultatsControleUpdate}
                                                    onChange={formik2.handleChange}
                                                    rows={4}
                                                    cols={50}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="popcard-operation-pickup-details_inside-zone">
                                        <div className="buttons">
                                            <input type="submit" value="Enregistrer" className="submit-btn" />
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>}

                    <ConfirmationDialog
                        open={confirmDialogOpen}
                        onClose={handleCancelDelete}
                        onConfirm={handleDelete}
                        titleMessage="Confirmer la suppression du dossier"
                        bodyMessage="Voulez-vous vraiment supprimer ce dossier? Toutes les données seront définitivement perdues."
                        btnCancelText="Annuler"
                        btnConfirmText="Supprimer le dossier"
                    />

                </div>
            </div>
        </section>
    );
}

export default ListControles;
