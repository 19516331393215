import React, { useEffect, useState, useRef, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import moment from 'moment/moment';
import { CustomToast } from '../../components/Toast/CustomToast';
import { axiosInstance, Employee, UserAuthentication } from '../../services/api';
import { AuthContext } from '../../contexts/AuthContext';

const Compte = () => {
    const { authData, login } = useContext(AuthContext);

    const [pathImageEmploye, setPathImageEmploye] = useState(null);
    const [pathImageEmployeName, setPathImageEmployeName] = useState(null);

    const pathImageEmployeRef = useRef(null)

    const handlePathImageEmployeChange = (e) => {
        setPathImageEmploye(e.target.files[0]);
    };

    const getImageFileName = (file) => {
        return file ? file.name : 'No file selected';
    };

    const { handleChange, values, handleSubmit, touched, errors, setValues } = useFormik({
        initialValues: {
            idEmploye: '',
            nom: '',
            prenom: '',
            dateNaissance: '',
            lieuNais: '',
            adresse: '',
            numTele1: '',
            numTele2: '',
            genre: '',
            username: '',
            email: '',
        },
        validationSchema: Yup.object({
            nom: Yup.string()
                .required("Champ obligatoire"),
            prenom: Yup.string()
                .required("Champ obligatoire"),
            dateNaissance: Yup.string()
                .required("Champ obligatoire"),
            genre: Yup.string()
                .required("Champ obligatoire"),
            username: Yup.string()
                .required("Champ obligatoire")
                .min(8, "Le champ doit contenir au minimumu 8 caractères")
                .max(255, "Le champ doit contenir au maximum 255 caractères"),
            email: Yup.string()
                .email("Le champ doit contenir un e-mail valide")
                .max(255, "Le champ doit contenir au maximum 255 caractères"),
            password: Yup.string()
                .required("Champ obligatoire")
                .min(8, "Le champ doit contenir au minimumu 8 caractères")
                .max(16, "Le champ doit contenir au maximum 16 caractères"),
        }),
        onSubmit: async (values) => {
            try {

                const formData = new FormData();
                formData.append('idEmploye', authData.idUser);
                formData.append('nom', values.nom);
                formData.append('prenom', values.prenom);
                formData.append('dateNaissance', values.dateNaissance);
                formData.append('pathImageIdentite', pathImageEmploye);
                formData.append('pathImageIdentiteName', pathImageEmployeName);
                formData.append('lieuNais', values.lieuNais);
                formData.append('adresse', values.adresse);
                formData.append('numTele1', values.numTele1);
                formData.append('numTele2', values.numTele2);
                formData.append('genre', values.genre);
                formData.append('username', values.username);
                formData.append('email', values.email);
                formData.append('password', values.password);
                formData.append('actionIdUtilisateur', authData.idUser);

                const response = await axiosInstance.put(`${Employee}/MyProfile`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });

                if (response.status === 200) {
                    // const request = {
                    //     accessToken: authData.token,
                    //     refreshToken: authData.tokenRefresh,
                    // };
                    const request = {
                        refreshToken: authData.tokenRefresh,
                    };
                    //console.log(request)
                    const responseAuth = await axiosInstance.post(`${UserAuthentication}/Refresh`, request);
                    let authInfo = JSON.stringify(responseAuth.data);
                    localStorage.setItem('authData', authInfo);
                    login(JSON.parse(authInfo));
                }
                // let authInfo=JSON.stringify(response.data);
                // localStorage.setItem('authData', authInfo);
                // login(JSON.parse(authInfo));
                CustomToast("Le profil a été mis à jour avec succès", 'success');
            } catch (error) {
                // Handle error
                console.log(error);
                if (!error?.response) {
                    CustomToast("Aucune réponse du serveur", 'error');
                } else if (error.response?.status === 400 && error.response?.data === "Employee does not exist") {
                    CustomToast("Compte n'existe pas", 'error');
                } else if (error.response?.status === 401) {
                    CustomToast("Non autorisé", 'error');
                } else {
                    CustomToast("Demande échoué", 'error');
                }
            }
        }
    });

    //Load data
    const getEmploye = async () => {
        try {
            const queryParams = new URLSearchParams({
                idEmploye: authData.idUser
            });

            const response = await axiosInstance.get(`${Employee}/GetEmployeProfile?${queryParams}`);
            const resp = await response.data;

            setValues(prevValues => ({
                ...prevValues,
                idEmploye: resp.idEmploye,
                nom: resp.nom || '',
                prenom: resp.prenom || '',
                dateNaissance: resp.dateNaissance || '',
                lieuNais: resp.lieuNais || '',
                adresse: resp.adresse || '',
                numTele1: resp.numTele1 || '',
                numTele2: resp.numTele2 || '',
                genre: resp.genre || '',
                username: resp.username,
                email: resp.email || ''
            }));

            setPathImageEmployeName(resp.pathImageIdentite || '')
        }
        catch (error) {
            // Handle error
            if (!error?.response) {
                CustomToast("Aucune réponse du serveur", 'error');
            } else if (error.response?.status === 401) {
                CustomToast("Non autorisé", 'error');
            } else {
                CustomToast("Demande échoué", 'error');
            }
            console.log(error);
            //navigate("/listevehicules", { replace: true });

        }
    }

    useEffect(() => {
        //Load employé
        if (authData) {
            getEmploye();
        }
        return () => {
            // Perform any necessary cleanup (e.g., cancel pending requests)
        };
    }, [authData]); // Make sure to include authData in the dependency array.


    return (
        <section className="vehicles-section">
            <div className="container profile-all-form">
                <form onSubmit={handleSubmit} className="margin-top-4 user-profile-form">
                    <div className="">
                        <div className="grid grid--2-cols">
                            <div className="form-sec">
                                <div className="form-details">
                                    <div className="detail-form" id="identification-form">
                                        <h3>Détails</h3>
                                        <div className="col">
                                            <div className="grp">
                                                <div className="gp">
                                                    <label >Nom<span className='requiredField'>*</span></label>
                                                    <input type="text"
                                                        id="nom"
                                                        name="nom"
                                                        style={(touched.nom && errors.nom) ? { borderColor: "red" } : null}
                                                        placeholder=""
                                                        defaultValue={values.nom}
                                                        onChange={handleChange}
                                                    />
                                                    {touched.nom && errors.nom ? (
                                                        <div className="invalid-feedback">{errors.nom}</div>
                                                    ) : null}
                                                </div>
                                                <div className="gp">
                                                    <label >Prénom<span className='requiredField'>*</span></label>
                                                    <input type="text"
                                                        id="prenom"
                                                        name="prenom"
                                                        style={(touched.prenom && errors.prenom) ? { borderColor: "red" } : null}
                                                        placeholder=""
                                                        defaultValue={values.prenom}
                                                        onChange={handleChange}
                                                    />
                                                    {touched.prenom && errors.prenom ? (
                                                        <div className="invalid-feedback">{errors.prenom}</div>
                                                    ) : null}
                                                </div>
                                            </div>
                                            <div className="grp">
                                                <div className="gp">
                                                    <label >Genre<span className='requiredField'>*</span></label>
                                                    <select
                                                        id="genre"
                                                        name="genre"
                                                        value={values.genre}
                                                        onChange={handleChange}
                                                    >
                                                        <option value="" defaultValue={true}>Veuillez sélectionner</option>
                                                        <option value="H">Homme</option>
                                                        <option value="F">Femme</option>
                                                    </select>
                                                    {touched.genre && errors.genre ? (
                                                        <div className="invalid-feedback">{errors.genre}</div>
                                                    ) : null}
                                                </div>
                                                <div className="gp">
                                                    <label >Date de naissance</label>
                                                    <input
                                                        type="date"
                                                        id="dateNaissance"
                                                        name="dateNaissance"
                                                        value={values.dateNaissance ? (moment(values.dateNaissance).format('YYYY-MM-DD')) : ''}
                                                        onChange={handleChange}
                                                    />
                                                    {touched.dateNaissance && errors.dateNaissance ? (
                                                        <div className="invalid-feedback">{errors.dateNaissance}</div>
                                                    ) : null}
                                                </div>
                                                <div className="gp">
                                                    <label >Lieu de naissance</label>
                                                    <input type="text"
                                                        id="lieuNais"
                                                        name="lieuNais"
                                                        placeholder=""
                                                        defaultValue={values.lieuNais}
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                            </div>
                                            <div className="grp">
                                                <div className="gp">
                                                    <label >Adresse</label>
                                                    <input type="text"
                                                        id="adresse"
                                                        name="adresse"
                                                        placeholder=""
                                                        defaultValue={values.adresse}
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                                <div className="gp">
                                                    <label >N° téléphone 1</label>
                                                    <input type="text"
                                                        id="numTele1"
                                                        name="numTele1"
                                                        placeholder=""
                                                        defaultValue={values.numTele1}
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                                <div className="gp">
                                                    <label >N° téléphone 2</label>
                                                    <input type="text"
                                                        id="numTele2"
                                                        name="numTele2"
                                                        placeholder=""
                                                        defaultValue={values.numTele2}
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                            </div>
                                            <div className="grp">
                                                <div className="gp">
                                                    <label >Photo de profil</label>
                                                    <label class="upload-file">
                                                    Choisir un fichier
                                                        <input
                                                        ref={pathImageEmployeRef}
                                                        className="upload-file"
                                                        id="pathImageIdentite"
                                                        name="pathImageIdentite"
                                                        type="file"
                                                        onChange={handlePathImageEmployeChange}
                                                    />
                                                    </label>
                                                    
                                                    {pathImageEmploye && <span style={{ fontSize: "10px" }}>{getImageFileName(pathImageEmploye)}
                                                        <span className="material-symbols-outlined" onClick={(e) => { setPathImageEmploye(null); if (pathImageEmployeRef.current) { pathImageEmployeRef.current.value = ''; } }}>delete</span>
                                                    </span>}
                                                    {pathImageEmployeName && <span style={{ fontSize: "10px" }}>{pathImageEmployeName}
                                                        <span className="material-symbols-outlined" onClick={(e) => { setPathImageEmploye(null); setPathImageEmployeName(''); if (pathImageEmployeRef.current) { pathImageEmployeRef.current.value = ''; } }}>delete</span>
                                                    </span>}
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="grid grid--2-cols">
                            <div className="form-sec">
                                <div className="form-details">
                                    <div className="detail-form" id="identification-form1">
                                        <h3>Informations de connexion & mot de passe</h3>
                                        <div className="col">
                                            <div className="grp">
                                                <div className="gp">
                                                    <label >Username<span className='requiredField'>*</span></label>
                                                    <input type="text"
                                                        id="username"
                                                        name="username"
                                                        placeholder=""
                                                        autoComplete="false"
                                                        defaultValue={values.username}
                                                        onChange={handleChange}
                                                        style={(touched.username && errors.username) ? { borderColor: "red" } : null}
                                                    />
                                                    {touched.username && errors.username ? (
                                                        <div className="invalid-feedback">{errors.username}</div>
                                                    ) : null}
                                                </div>
                                                <div className="gp">
                                                    <label >Mot de passe<span className='requiredField'>*</span></label>
                                                    <input
                                                        type="password"
                                                        id="password"
                                                        name="password"
                                                        style={(touched.password && errors.password) ? { borderColor: "red" } : null}
                                                        placeholder=""
                                                        defaultValue={values.password}
                                                        onChange={handleChange}
                                                    />
                                                    {touched.password && errors.password ? (
                                                        <div className="invalid-feedback">{errors.password}</div>
                                                    ) : null}
                                                </div>
                                                <div className="gp">
                                                    <label >Email</label>
                                                    <input
                                                        type="email"
                                                        id="email"
                                                        name="email"
                                                        style={(touched.email && errors.email) ? { borderColor: "red" } : null}
                                                        placeholder=""
                                                        defaultValue={values.email}
                                                        onChange={handleChange}
                                                    />
                                                    {touched.email && errors.email ? (
                                                        <div className="invalid-feedback">{errors.email}</div>
                                                    ) : null}
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div className="submit-container">
                                    <button type="submit" className="submit-btn">Enregistrer</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </section>
    );
}

export default Compte;
