const LogoFooter = () => {
    return (
        <>
            <svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 605.51 139.88">
                <defs>
                    <style>
                    {` .cls-1 {
                            fill: #fff;
                }

                        .cls-2, .cls-3 {
                            fill: #001123;
                }

                        .cls-3 {
                            font - family: Futura-Bold, Futura;
                        font-size: 13.14px;
                        font-weight: 700;
                }

                        .cls-4 {
                            fill: #ffc72f;
                }

                        .cls-5 {
                            fill: #65458d;
                } `}
                    </style>
                </defs>
                <g id="Layer_1-2" data-name="Layer 1">
                    <g>
                        <path className="cls-2" d="m207.54,75.64c0,13.23-10.95,20.75-23.49,20.75s-23.49-7.45-23.49-20.75,10.95-20.83,23.49-20.83,23.49,7.52,23.49,20.83Zm-32.38,0c0,4.94,3.27,8.74,8.89,8.74s8.89-3.8,8.89-8.74-3.27-8.82-8.89-8.82-8.89,4.03-8.89,8.82Z" />
                        <path className="cls-2" d="m227.39,56.1v20.83c0,4.94,1.22,8.44,6.92,8.44s6.92-3.5,6.92-8.44v-20.83h13.84v23.49c0,12.85-9.05,16.8-20.75,16.8s-20.75-3.95-20.75-16.8v-23.49h13.84Z" />
                        <path className="cls-2" d="m277.72,62.49h.15c2.66-4.79,7.22-6.92,12.62-6.92h2.66v13.3c-1.9-1.06-3.73-1.29-5.85-1.29-7.68,0-9.58,5.09-9.58,11.78v15.74h-13.84v-39h13.84v6.39Z" />
                        <path className="cls-2" d="m340.13,114.56h-13.84v-23.19c-2.89,3.42-7.6,5.02-12.09,5.02-11.63,0-19.54-9.43-19.54-20.68s8.13-20.9,19.69-20.9c4.56,0,9.35,1.82,11.78,5.7h.15v-4.41h13.84v58.46Zm-31.17-38.92c0,4.94,3.27,8.74,8.89,8.74s8.89-3.8,8.89-8.74-3.27-8.82-8.89-8.82-8.89,4.03-8.89,8.82Z" />
                        <path className="cls-2" d="m362.41,56.1v20.83c0,4.94,1.22,8.44,6.92,8.44s6.92-3.5,6.92-8.44v-20.83h13.83v23.49c0,12.85-9.04,16.8-20.75,16.8s-20.75-3.95-20.75-16.8v-23.49h13.84Z" />
                        <path className="cls-4" d="m413.58,41.13c0,4.26-3.5,7.75-7.75,7.75s-7.75-3.5-7.75-7.75,3.5-7.75,7.75-7.75,7.75,3.5,7.75,7.75Z" />
                        <rect className="cls-2" x="398.9" y="56.1" width="13.84" height="39" />
                        <path className="cls-2" d="m435.7,95.1h-13.84v-63.09h13.84v63.09Z" />
                        <path className="cls-2" d="m487.47,95.1h-13.84v-4.33h-.15c-2.43,3.88-7.22,5.63-11.78,5.63-11.56,0-19.69-9.65-19.69-20.83s7.98-20.75,19.54-20.75c4.48,0,9.2,1.67,12.09,5.09v-3.8h13.84v39Zm-31.17-19.46c0,4.94,3.27,8.74,8.89,8.74s8.89-3.8,8.89-8.74-3.27-8.82-8.89-8.82-8.89,4.03-8.89,8.82Z" />
                        <path className="cls-2" d="m510.51,61.12h.15c3.19-4.71,7.07-6.31,12.47-6.31,10.49,0,14.67,6.61,14.67,16.19v24.1h-13.84v-19.01c0-3.72.61-10.26-6.23-10.26-5.63,0-7.22,4.18-7.22,9.05v20.22h-13.84v-39h13.84v5.02Z" />
                        <path className="cls-2" d="m586.99,77.92h-29.11c0,5.63,2.96,8.67,8.67,8.67,2.97,0,5.09-.99,6.61-3.57h13.3c-2.21,9.2-10.95,13.38-19.84,13.38-12.92,0-22.58-7.3-22.58-20.75s8.89-20.83,21.59-20.83c13.53,0,21.36,8.36,21.36,21.66v1.44Zm-12.85-7.98c-.68-3.72-4.11-6.16-7.83-6.16-4.03,0-7.37,2.13-8.21,6.16h16.04Z" />
                        <path className="cls-5" d="m69.93,0C31.32,0,0,31.32,0,69.93s31.32,69.95,69.93,69.95,69.93-31.32,69.93-69.95S108.55,0,69.93,0Zm0,116.84c-7.98,0-15.5-1.99-22.08-5.52l-.55.14-20.58,5.37,5.3-19.29c-5.66-7.75-8.99-17.29-8.99-27.62,0-25.9,21.01-46.89,46.91-46.89s46.91,21,46.91,46.89-21.01,46.91-46.91,46.91Z" />
                        <text className="cls-3" transform="translate(585.58 46.12)"><tspan x="0" y="0">TM</tspan></text>
                        <path className="cls-1" d="m116.84,69.93c0,25.91-21.01,46.91-46.91,46.91-7.98,0-15.5-1.99-22.08-5.52l-.55.14-20.58,5.37,5.3-19.29c-5.66-7.75-8.99-17.29-8.99-27.62,0-25.9,21.01-46.89,46.91-46.89s46.91,21,46.91,46.89Z" />
                        <circle className="cls-4" cx="69.93" cy="69.94" r="33.17" />
                        <g>
                            <path className="cls-1" d="m57.48,77.57v24.4c-3.01-1.06-5.84-2.52-8.42-4.32v-17.4c2.92-.78,5.73-1.68,8.42-2.68Z" />
                            <path className="cls-1" d="m68.12,72.86v31.12c-2.9-.09-5.72-.54-8.4-1.3v-25.98c2.95-1.18,5.76-2.48,8.4-3.84Z" />
                            <path className="cls-1" d="m78.76,66.56v36.18c-2.69.74-5.51,1.17-8.42,1.25v-32.31c3.04-1.65,5.86-3.39,8.42-5.12Z" />
                            <path className="cls-1" d="m89.39,58.43v39.37c-2.58,1.77-5.41,3.2-8.41,4.25v-37.02c3.29-2.33,6.11-4.61,8.41-6.59Z" />
                        </g>
                        <path className="cls-1" d="m76.36,49.48l18.14.69s-19.93,22.97-51.69,29.75c0,0,25.41-12.6,37.86-24.2.59-.55.54-1.51-.09-2.02l-4.22-4.21Z" />
                    </g>
                </g>
            </svg>
        </>
    );
};

export default LogoFooter;