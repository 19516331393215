import React from 'react';
import DOMPurify from 'dompurify';

const ConfirmationDialog = ({ open, onClose, onConfirm, titleMessage, bodyMessage, btnCancelText, btnConfirmText }) => {

    if (!open) return null;

    return (
        <div className="popcard-add">
            <div className="popcard-add_inside annuler-banner">
                <a className="popcard-add_inside-close" onClick={onClose}>&#10006;</a>
                <h1 className="popcard-add_inside-title">{titleMessage}</h1>
                <div className="popcard-add_inside-form">
                    <div className="wrap-info">
                        <div className="wrap-row">
                            <div className="wrap-one" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(bodyMessage) }} style={{ fontSize: "1.5rem" }}>
                                {/* <p>{bodyMessage}</p> */}
                            </div></div>
                    </div>
                    <div className="popcard-operation-pickup-details_inside-zone float-right row">
                        <div className="buttons">
                            {/* <a href="#wilaya-sec" className="annuler-btn annuler-btn-white" onClick={onClose}>{btnCancelText}</a> */}
                            <input type="submit" value={btnCancelText} className="annuler-btn annuler-btn-white" onClick={onClose} />
                            <input type="submit" value={btnConfirmText} className="annuler-btn annuler-btn-red" onClick={onConfirm} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ConfirmationDialog;
