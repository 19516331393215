import React, { useEffect, useState, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import moment from 'moment/moment';
import { CustomToast } from '../../../../components/Toast/CustomToast';
import ConfirmationDialog from '../../../../components/Confirmation/ConfirmationDialog';
import { axiosInstance, Marque, Modele } from '../../../../services/api';
import { AuthContext } from '../../../../contexts/AuthContext';
import useDebounce from '../../../../hooks/useDebounce';

const ListModeles = () => {
    const { authData } = useContext(AuthContext);
    const navigate = useNavigate();

    const [modeles, setModeles] = useState([]);
    const [marques, setMarques] = useState([]);
    const [filtredModeles, setFiltredModeles] = useState([]);
    const [pageNumber, setPageNumber] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalItems, setTotalItems] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [loading, setLoading] = useState(true);
    const [showAddModal, setShowAddModal] = useState(false);
    const [showUpdateModal, setShowUpdateModal] = useState(false);
    const [idModeleDelete, setIdModeleDelete] = useState('');
    const [idModeleUpdate, setIdModeleUpdate] = useState('');

    const [search, setSearch] = useState('');
    const debouncedSearchText = useDebounce(search, 300);

    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);

   //Table management
   const [sortBy, setSortBy] = useState(''); // Column name to sort by
   const [sortOrder, setSortOrder] = useState('asc'); // 'asc' or 'desc'

   const handleSort = (column) => {
       setSortBy(column);
       setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');

       let sortedTable = [...filtredModeles].sort((a, b) => {
           const aValue = a[sortBy] || '';
           const bValue = b[sortBy] || '';

           if (sortOrder === 'asc') {
               return aValue.localeCompare(bValue);
           } else {
               return bValue.localeCompare(aValue);
           }
       });

       setFiltredModeles(sortedTable);
   };

   const handlePageChange = (page) => {
       setPageNumber(page);
       //consolelog("setPageNumber="+pageNumber)
       const startIndex = (page - 1) * pageSize;
       const endIndex = startIndex + pageSize;
       const paginatedData = modeles.slice(startIndex, endIndex);

       setFiltredModeles(paginatedData);
   };

   const [openAboutList, setOpenAboutList] = useState(null);

    const toggleAboutList = (idMarque) => {
        // console.log(idMarque)
        setOpenAboutList(openAboutList === idMarque ? null : idMarque);
    };

   const handlePerRowsChange = (newPerPage) => {
       setPageSize(newPerPage);
       //console.log("setPageSize="+pageSize);
   };

    const showHideAddModal = (val) => {
        setShowAddModal(val);
        setIdModeleUpdate('');
        formik1.resetForm();
        if(val){
            getMarques();
        }       
    };

    const showHideUpdateModal = (val, idModele, libelleModele,idMarque) => {
        setShowUpdateModal(val);
        setIdModeleUpdate(idModele);
        formik2.resetForm();   
        if (val) {
            getMarques();
            formik2.values.libelleModeleUpdate = libelleModele;
            formik2.values.idMarqueUpdate = idMarque;
        }
    };

    //Supprimer modele
    const deleteModele = async (idModele) => {
        try {
            const response = await axiosInstance.delete(`${Modele}/${idModele}`);
            getModeles();
            CustomToast("Le modèle a été supprimé avec succès", 'success');
        }
        catch (error) {
            // Handle error
            if (!error?.response) {
                CustomToast("Aucune réponse du serveur", 'error');
            } else if (error.response?.status === 400 && error.response?.data === "Modele is already used. Cannot delete.") {
                CustomToast("Le modèle est déjà utilisé. Impossible de suprimer", 'error');
            } else if (error.response?.status === 401) {
                CustomToast("Non autorisé", 'error');
            } else {
                CustomToast("Demande échoué", 'error');
            }
            console.log(error);
        }
    }

    const handleConfirmDelete = (idModele) => {
        // Show the modal when the confirm delete button is clicked
        //deleteModele(idMarque);
        setIdModeleDelete(idModele);
        setConfirmDialogOpen(true);
    };

    const handleDelete = async () => {
        try {
            const response = await axiosInstance.delete(`${Modele}/${idModeleDelete}`);
            //getModeles();
            setIdModeleDelete('');
            CustomToast("Le modèle a été supprimé avec succès", 'success');
            getModeles();
            setConfirmDialogOpen(false);
            //navigate('/listmodeles', { replace: true });
        }
        catch (error) {
            // Handle error
            if (!error?.response) {
                CustomToast("Aucune réponse du serveur", 'error');
            } else if (error.response?.status === 400 && error.response?.data === "Modele is already used. Cannot delete.") {
                CustomToast("Le modèle est déjà utilisé. Impossible de suprimer", 'error');
            } else if (error.response?.status === 401) {
                CustomToast("Non autorisé", 'error');
            } else {
                CustomToast("Demande échoué", 'error');
            }
            console.log(error);
        }
    }

    const handleCancelDelete = () => {
        setConfirmDialogOpen(false);
    };

    //Load data
    const getModeles = async () => {
        try {
            const response = await axiosInstance.get(`${Modele}/GetModelesUsage`);
            const resp = await response.data;
            setModeles(resp);
            let filteredResult = resp;

            if (search !== '') {
                filteredResult = resp.filter((item) =>
                    Object.values(item).some(
                        (value) =>
                            value &&
                            value.toString().toLowerCase().includes(search.toLowerCase())
                    )
                );
            }

            // Pagination
            const startIndex = (pageNumber - 1) * pageSize;
            const endIndex = startIndex + pageSize;
            const paginatedResult = filteredResult.slice(startIndex, endIndex);

            setFiltredModeles(paginatedResult);
            setPageNumber(1);
            setTotalItems(filteredResult.length);
            setTotalPages(Math.ceil(filteredResult.length / pageSize));
            setLoading(false);
        }
        catch (err) {
            setLoading(false);
            console.log(err);
        }
    }

    const getMarques = async () => {
        try {
            const response = await axiosInstance.get(`${Marque}`);
            const resp = await response.data;
            setMarques(resp);
        }
        catch (err) {
            console.log(err);
        }
    }

    const handleSearch = (searchTerm) => {

        const result = modeles?.filter((item) =>
            Object.values(item).some(
                (value) =>
                    value &&
                    value.toString().toLowerCase().includes(searchTerm.toLowerCase())
            )
        );
        //console.log(searchTerm,result)
        // Pagination
        const startIndex = (pageNumber - 1) * pageSize;
        const endIndex = startIndex + pageSize;
        const paginatedResult = result.slice(startIndex, endIndex);
        setFiltredModeles(paginatedResult);
        setPageNumber(1);
        setTotalItems(result.length);
        setTotalPages(Math.ceil(result.length / pageSize));
        setSearch(searchTerm);
    };

    const formik1 = useFormik({
        initialValues: {
            idMarque: '',
            libelleModele: '',
        },
        validationSchema: Yup.object({
            idMarque: Yup.string()
                .required('Champ obligatoire'),
            libelleModele: Yup.string()
                .required('Champ obligatoire')
        }),
        onSubmit: async (values) => {
            try {
                const postData = {
                    libelleModele: values.libelleModele,
                    idMarque: values.idMarque,
                    idUtilisateur: authData.idUser,
                };

                await axiosInstance.post(Modele, postData);
                CustomToast("Le modèle a été créé avec succès", 'success');
                getModeles();
                setShowAddModal(false);
                //navigate('/listmodeles', { replace: true });
            } catch (error) {
                // Handle error
                if (!error?.response) {
                    CustomToast("Aucune réponse du serveur", 'error');
                } else if (error.response?.status === 400 && error.response?.data === "Modele existe") {
                    CustomToast("Modèle existe", 'error');
                } else if (error.response?.status === 401) {
                    CustomToast("Non autorisé", 'error');
                } else {
                    CustomToast("Demande échoué", 'error');
                }
                console.log(error);
            }
        }
    });

    const formik2 = useFormik({
        initialValues: {
            idMarqueUpdate: '',
            libelleModeleUpdate: '',
        },
        validationSchema: Yup.object({
            idMarqueUpdate: Yup.string()
                .required('Champ obligatoire'),
            libelleModeleUpdate: Yup.string()
                .required('Champ obligatoire'),
        }),
        onSubmit: async (values) => {
            try {
                const postData = {
                    idModele: idModeleUpdate,
                    libelleModele: values.libelleModeleUpdate,
                    idMarque: values.idMarqueUpdate,
                    idUtilisateur: authData.idUser,
                };

                //console.log(postData);               
                const response = await axiosInstance.put(Modele, postData);
                CustomToast("Le modèle a été modifié avec succès", 'success');
                showHideUpdateModal(false, '', '');
                getModeles();

                //navigate('/listmodeles', { replace: true });
            } catch (error) {
                // Handle error
                if (!error?.response) {
                    CustomToast("Aucune réponse du serveur", 'error');
                } else if (error.response?.status === 400 && error.response?.data === "Modele existe") {
                    CustomToast("Modèle existe", 'error');
                } else if (error.response?.status === 401) {
                    CustomToast("Non autorisé", 'error');
                } else {
                    CustomToast("Demande échoué", 'error');
                }
                console.log(error);
            }
        }
    });

    useEffect(() => {
        //Load Data
        getModeles();
        //console.log("First");
        return () => {
            // Perform any necessary cleanup (e.g., cancel pending requests)
        };
    }, [authData, pageSize]);

    useEffect(() => {
        //Load Data
        handleSearch(debouncedSearchText);
        return () => {
            // Perform any necessary cleanup (e.g., cancel pending requests)
        };
    }, [debouncedSearchText]);

    // useEffect(() => {
    //     // const result=vehicules.filter(row=>{
    //     //     return row.vehiculeNom && row.vehiculeNom.toLowerCase().match(search.toLowerCase());   
    //     // })
    //     console.log("search");
    //     const result = modeles?.filter((item) =>
    //         Object.values(item).some(
    //             (value) =>
    //                 value &&
    //                 value.toString().toLowerCase().includes(search.toLowerCase())
    //         )
    //     );
    //     setFiltredModeles(result);
    // }, [search, modeles])

    return (
        <section className="donnee-section" onClick={() => { if (openAboutList !== null) { setOpenAboutList(null) } }}>
            <div className="container diver-section">
                <div className="modele-sec wrap-all">
                    <div className="row row-center">
                        <Link to="/divers" className="retour cursurer" id="vehicule-list-assrc-retour">
                            <div className="arrow-back">
                                <span className="material-symbols-outlined" id="arrow">arrow_back</span>
                            </div>
                        </Link>
                        <Link href="#" className="color-primary-2 decoration-none" onClick={() => showHideAddModal(true)}>
                            <div className="add-new" id="add-vehc">
                                <span className="material-symbols-outlined">add</span>
                                Ajouter un modèle
                            </div>
                        </Link>
                    </div>

                    <div className="group-elements">
                        <div className="voir-elements">
                            <p></p>
                            <select
                                name="pageSize"
                                id="pageSize"
                                onChange={(e) => { handlePerRowsChange(e.target.value || 10); setPageNumber(1); }}
                            >
                                <option value="10" defaultValue={true}>10</option>
                                <option value="15">15</option>
                                <option value="20">20</option>
                            </select>
                        </div>
                        <div className="search-element">
                            <p>Rechercher</p>
                            <input
                                type="text"
                                value={search}
                                onChange={(e) => handleSearch(e.target.value)}
                            />
                        </div>
                    </div>
                    {filtredModeles?.length > 0 && <div className="table-wrap">
                            <table>
                                <thead>
                                    <tr>
                                        <th onClick={() => handleSort('libelleModele')}>Nom</th>
                                        <th onClick={() => handleSort('libelleMarque')}>Marque</th>
                                        <th>Véhicules</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {filtredModeles?.map((row) => (
                                        <tr key={row.idModele}>
                                            <td>{row.libelleModele}</td>
                                            <td>{row.libelleMarque}</td>
                                            <td>{
                                            row.vehiculeCount + " véhicules(s)"
                                            }</td>
                                           <td className="txt-center relative">
                                            <span className="material-symbols-outlined about-btn" onClick={() => toggleAboutList(row.idModele)}>
                                                more_horiz
                                            </span>
                                            {openAboutList === row.idModele && (
                                                <div className="about-list">
                                                    <ul>
                                                        <li onClick={() => showHideUpdateModal(true, row.idModele, row.libelleModele,row.idMarque)}>
                                                            <span className="material-symbols-outlined">edit</span>
                                                            <p>Modifier</p>
                                                        </li>
                                                        {(row.vehiculeCount == 0) && <li  onClick={() => handleConfirmDelete(row.idModele)}>
                                                            <span className="material-symbols-outlined">delete</span
                                                            ><p>Supprimer</p>
                                                        </li>}
                                                        {(row.vehiculeCount  > 0) && <li style={{ color: "#BEBEBE", cursor: 'not-allowed', background: 'none' }} onClick={(event) => event.preventDefault()} title="Modèle en cours d'utilisation">
                                                            <span className="material-symbols-outlined">delete</span>
                                                            <p>Supprimer</p>
                                                        </li>}
                                                    </ul>
                                                </div>
                                            )}
                                        </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>}
                        <div className="pagination">
                            <Link to="#" onClick={() => handlePageChange(pageNumber - 1)}
                                className={(pageNumber === 1) ? 'pgn disabled' : 'pgn'}
                                disabled={(pageNumber === 1) ? true : false}
                            >&laquo;</Link>
                            {Array.from({ length: totalPages }, (_, i) => (
                                <Link
                                    key={i + 1}
                                    to="#"
                                    className={i + 1 === pageNumber ? 'pgn active' : 'pgn'}
                                    onClick={() => handlePageChange(i + 1)}
                                >
                                    {i + 1}
                                </Link>
                            ))}
                            <Link to="#" onClick={() => handlePageChange(pageNumber + 1)}
                                className={((pageNumber + 1) > totalPages) ? 'pgn disabled' : 'pgn'}
                                disabled={((pageNumber + 1) > totalPages) ? true : false}
                            >&raquo;</Link>
                        </div>


                    {showAddModal && <div className="popcard-add">
                        <div className="popcard-add_inside">
                            <Link className="popcard-add_inside-close" to="#" onClick={() => showHideAddModal(false)}>&#10006;</Link>
                            <h2 className="popcard-add_inside-title"></h2>
                            <div className="popcard-add_inside-form">
                                <form onSubmit={formik1.handleSubmit}>
                                    <div className="wrap-info">
                                        <div className="wrap-one">
                                            <label>Nom<span className='requiredField'>*</span></label>
                                            <input type="text"
                                                id="libelleModele"
                                                name="libelleModele"
                                                style={(formik1.touched.libelleModele && formik1.errors.libelleModele) ? { borderColor: "red" } : null}
                                                placeholder=""
                                                defaultValue={formik1.values.libelleModele}
                                                onChange={formik1.handleChange}
                                            />
                                            {formik1.touched.libelleModele && formik1.errors.libelleModele ? (
                                                <div className="invalid-feedback">{formik1.errors.libelleModele}</div>
                                            ) : null}
                                        </div>

                                        <div className="wrap-one">
                                            <label>Marque<span className='requiredField'>*</span></label>
                                            <select
                                                id="idMarque"
                                                name="idMarque"
                                                value={formik1.values.idMarque} onChange={formik1.handleChange}
                                                style={(formik1.touched.idMarque && formik1.errors.idMarque) ? { borderColor: "red" } : null}
                                            >
                                                <option value="">Veuillez sélectionner</option>
                                                {marques.map((marque) => (
                                                    <option key={marque.idMarque} value={marque.idMarque}>{marque.libelleMarque}</option>
                                                ))}
                                            </select>
                                            {formik1.touched.idMarque && formik1.errors.idMarque ? (
                                                <div className="invalid-feedback">{formik1.errors.idMarque}</div>
                                            ) : null}
                                        </div>
                                    </div>

                                    <div className="popcard-operation-pickup-details_inside-zone">
                                        <div className="buttons">
                                            <input type="submit" value="Enregistrer" className="submit-btn" />
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>}

                    {showUpdateModal && <div className="popcard-add">
                        <div className="popcard-add_inside">
                            <Link className="popcard-add_inside-close" to="#" onClick={() => showHideUpdateModal(false, '', '')}>&#10006;</Link>
                            <h2 className="popcard-add_inside-title"></h2>
                            <div className="popcard-add_inside-form">
                                <form onSubmit={formik2.handleSubmit}>
                                    <div className="wrap-info">
                                        <div className="wrap-one">
                                            <label>Nom<span className='requiredField'>*</span></label>
                                            <input type="text"
                                                id="libelleModeleUpdate"
                                                name="libelleModeleUpdate"
                                                style={(formik2.touched.libelleModeleUpdate && formik2.errors.libelleModeleUpdate) ? { borderColor: "red" } : null}
                                                placeholder=""
                                                defaultValue={formik2.values.libelleModeleUpdate}
                                                onChange={formik2.handleChange}
                                            />
                                            {formik2.touched.libelleModeleUpdate && formik2.errors.libelleModeleUpdate ? (
                                                <div className="invalid-feedback">{formik2.errors.libelleModeleUpdate}</div>
                                            ) : null}
                                        </div>
                                        <div className="wrap-one">
                                            <label>Marque<span className='requiredField'>*</span></label>
                                            <select
                                                id="idMarqueUpdate"
                                                name="idMarqueUpdate"
                                                value={formik2.values.idMarqueUpdate} onChange={formik2.handleChange}
                                                style={(formik2.touched.idMarqueUpdate && formik2.errors.idMarqueUpdate) ? { borderColor: "red" } : null}
                                            >
                                                <option value="">Veuillez sélectionner</option>
                                                {marques.map((marque) => (
                                                    <option key={marque.idMarque} value={marque.idMarque}>{marque.libelleMarque}</option>
                                                ))}
                                            </select>
                                            {formik2.touched.idMarqueUpdate && formik2.errors.idMarqueUpdate ? (
                                                <div className="invalid-feedback">{formik2.errors.idMarqueUpdate}</div>
                                            ) : null}
                                        </div>
                                    </div>

                                    <div className="popcard-operation-pickup-details_inside-zone">
                                        <div className="buttons">
                                            <input type="submit" value="Enregistrer" className="submit-btn" />
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>}

                    <ConfirmationDialog
                        open={confirmDialogOpen}
                        onClose={handleCancelDelete}
                        onConfirm={handleDelete}
                        titleMessage="Confirmer la suppression de modèle"
                        bodyMessage="Voulez-vous vraiment supprimer ce modèle? Toutes les données seront définitivement perdues."
                        btnCancelText="Annuler"
                        btnConfirmText="Supprimer le modèle"
                    />

                </div>
            </div>
        </section>
    );
}

export default ListModeles;
