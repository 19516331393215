import React, { useEffect, useState, useRef, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import moment from 'moment/moment';
import Select from "react-select";
import { CustomToast } from '../../../components/Toast/CustomToast';
import { formatCurrency } from '../../../utils/Utils'
import useDebounce from '../../../hooks/useDebounce';
import { axiosInstance, Vehicule, Fournisseur, Employee, TacheService, VehiculeServiceRappelle, VehiculeProbleme, VehiculeOrdreTravail } from '../../../services/api';
import { AuthContext } from '../../../contexts/AuthContext';
const AjouterOrdre = () => {
    const { authData } = useContext(AuthContext);
    const navigate = useNavigate();

    const [details, setDetails] = useState(true);
    const [problemes, setProblemes] = useState(false);
    const [taches, setTaches] = useState(false);
    const [documents, setDocuments] = useState(false);

    const [detailsClass, setDetailsClass] = useState("line act");
    const [problemesClass, setProblemesClass] = useState("line");
    const [tachesClass, setTachesClass] = useState("line");
    const [documentsClass, setDocumentsClass] = useState("line");

    const [vehicules, setVehicules] = useState([]);
    const [fournisseurs, setFournisseurs] = useState([]);
    const [techniciens, setTechniciens] = useState([]);
    const [tachesService, setTachesService] = useState([]);
    const [rappels, setRappels] = useState([]);
    const [problemesVehicule, setProblemesVehicule] = useState([]);

    const [vehicleNotFound, setVehicleNotFound] = useState(false);
    const [selectedVehicle, setSelectedVehicle] = useState('');
    const [selectedVehicleId, setSelectedVehicleId] = useState('');

    const [selectedTechnician, setSelectedTechnician] = useState('');
    const [selectedTechnicianId, setSelectedTechnicianId] = useState('');

    const [kiloDebLess, setKiloDebLess] = useState({ display: false, msg: "" });
    const [kiloDebGreater, setKiloDebGreater] = useState({ display: false, msg: "" });
    const [kiloDebBetween, setKiloDebBetween] = useState({ display: false, msg: "" });

    const [kiloFinLess, setKiloFinLess] = useState({ display: false, msg: "" });
    const [kiloFinGreater, setKiloFinGreater] = useState({ display: false, msg: "" });
    const [kiloFinBetween, setKiloFinBetween] = useState({ display: false, msg: "" });

    const [listPathDocumentsJustificatifs, setListPathDocumentsJustificatifs] = useState([]);
    const listPathDocumentsJustificatifsRef = useRef(null);

    const [selectedTache, setSelectedTache] = useState('');
    const [selectedTacheId, setSelectedTacheId] = useState('');

    const [enteredVehicleText, setEnteredVehicleText] = useState('');
    const [enteredTaskText, setEnteredTaskText] = useState('');
    const [enteredTechnicianText, setEnteredTechnicianTextText] = useState('');

    const debouncedVehiculeText = useDebounce(enteredVehicleText, 300);
    const debouncedTaskText = useDebounce(enteredTaskText, 300);
    const debouncedTechText = useDebounce(enteredTechnicianText, 300);

    const showDetails = () => {
        setDetails(true);
        setProblemes(false);
        setTaches(false);
        setDocuments(false);

        setDetailsClass("line act");
        setProblemesClass("line");
        setTachesClass("line");
        setDocumentsClass("line");
    };

    const showProblemes = () => {
        setDetails(false);
        setProblemes(true);
        setTaches(false);
        setDocuments(false);

        setDetailsClass("line");
        setProblemesClass("line act");
        setTachesClass("line");
        setDocumentsClass("line");
    };

    const showTaches = () => {
        setDetails(false);
        setProblemes(false);
        setTaches(true);
        setDocuments(false);

        setDetailsClass("line");
        setProblemesClass("line");
        setTachesClass("line act");
        setDocumentsClass("line");
    };

    const showDocuments = () => {
        setDetails(false);
        setProblemes(false);
        setTaches(false);
        setDocuments(true);

        setDetailsClass("line");
        setProblemesClass("line");
        setTachesClass("line");
        setDocumentsClass("line act");
    };

    const handleVehicleChange = (selectedOption) => {
        setSelectedVehicle(selectedOption);
        setSelectedVehicleId(selectedOption ? selectedOption.idVehicule : '');
        values.idVehicule = selectedVehicleId;
    };

    const handleTechnicianChange = (selectedOption) => {
        setSelectedTechnician(selectedOption);
        setSelectedTechnicianId(selectedOption ? selectedOption.idEmploye : '');
        values.delivrerParId = selectedTechnicianId;
    };

    const onCheck = (id) => {
        //console.log(id);
        setProblemesVehicule((prevProblemes) => {
            return prevProblemes.map((probleme) => {
                if (probleme.idProbleme === id) {
                    return {
                        ...probleme,
                        acces: !probleme.acces, // Toggle the value of the access field
                    };
                }
                return probleme;
            });
        });
    };

    //Upload documents
    const handlePathImageChange = (e) => {
        const files = Array.from(e.target.files); // Convert the FileList to an array
        setListPathDocumentsJustificatifs(files);
    };

    const handleDeleteFile = (indexToDelete) => {
        setListPathDocumentsJustificatifs((prevFiles) =>
            prevFiles.filter((file, index) => index !== indexToDelete)
        );
    };

    const getVehicules = async (nomVehicule) => {
        try {
            const queryParams = new URLSearchParams({
                pageNumber: 1,
                pageSize: 10
            });

            if (authData?.wilayas != null) {
                const wilayasFilter = authData?.wilayas
                queryParams.append("wilayas", wilayasFilter.join(','));
            }
            else {
                return
            }

            if (authData?.flottesAutorises != null) {
                const flottesAutorisesFilter = authData?.flottesAutorises
                queryParams.append("flottesAutorises", flottesAutorisesFilter.join(','));
            }
            else {
                return
            }

            if (nomVehicule !== '') {
                queryParams.append("nom", nomVehicule);
            }
            const response = await axiosInstance.get(`${Vehicule}/GetVehiculesAutocomplete?${queryParams}`);
            const resp = await response.data;
            setVehicules(resp.data);
        }
        catch (err) {
            console.log(err);
        }
    }

    const getFournisseurs = async () => {
        try {
            const queryParams = new URLSearchParams({
                service: true
            });

            const response = await axiosInstance.get(`${Fournisseur}/GetFournisseurType?${queryParams}`);
            const resp = await response.data;
            setFournisseurs(resp);
        }
        catch (err) {
            console.log(err);
        }
    }

    const getProblemes = async (idVehicule) => {
        try {

            const queryParams = new URLSearchParams({
                idVehicule: idVehicule,
                status: "ouvert,en retard"
            });

            const response = await axiosInstance.get(`${VehiculeProbleme}/GetProblemesVehicule?${queryParams}`);
            const resp = await response.data;
            setProblemesVehicule(resp.data);
        }
        catch (err) {
            console.log(err);
        }
    }

    const getTaches = async (nomTache) => {
        try {
            const queryParams = new URLSearchParams({
                pageNumber: 1,
                pageSize: 10
            });

            if (nomTache !== '') {
                queryParams.append("nom", nomTache);
            }
            const response = await axiosInstance.get(`${TacheService}/GetTachesAutocomplete?${queryParams}`);
            //const response = await axiosInstance.get(`${TacheService}`);
            const resp = await response.data;
            //setTachesService(resp);
            setTachesService(resp.data);
        }
        catch (err) {
            console.log(err);
        }
    }

    const getTechiciens = async (nomConducteur) => {
        try {
            const queryParams = new URLSearchParams({
                pageNumber: 1,
                pageSize: 10,
                estTechnicien: true
            });

            if (nomConducteur !== '') {
                queryParams.append("nom", nomConducteur);
            }
            const response = await axiosInstance.get(`${Employee}/GetEmployeAutocomplete?${queryParams}`);
            const resp = await response.data;
            setTechniciens(resp.data);
        }
        catch (err) {
            console.log(err);
        }
    }

    const { handleChange, values, handleSubmit, touched, errors, setFieldValue } = useFormik({
        initialValues: {
            vehicule: null,
            idVehicule: '',
            typeOrdreTravail: 'interne',
            statutOrdreTravail: 'ouvert',
            dateDelivranceOrdreTravail: '',//
            delivrerPar: '',//
            dateDebutReelTravail: '',
            valeuCompteurDebutReelTravail: '',//
            dateFinReelTravail: '',
            valeuCompteurFinReelTravail: '',//
            idFournisseur: '',
            numeroFacture: '',
            numeroBonCommande: '',
            listeProblemes: [],
            listeItems: [],
            remarqesGenerale: '',
            coutMainOeuvre: 0,
            coutPiecesRechange: 0,
            coutTotal: 0,
            commentaire: ''
        },
        validationSchema: Yup.object({
            vehicule: Yup.object()
                .nullable()
                .required('Champ obligatoire'),
            delivrerPar: Yup.object()
                .nullable()
                .required('Champ obligatoire'),
            dateDelivranceOrdreTravail: Yup.string()
                .required('Champ obligatoire'),
            statutOrdreTravail: Yup.string(),
            valeuCompteurDebutReelTravail: Yup.number()
                .required('Champ obligatoire')
                .min(0, "Valeur de compteur ne peut pas être négative"),
            valeuCompteurFinReelTravail: Yup.number()
                .min(0, "Valeur de compteur ne peut pas être négative")
                .test('compteurFin-validation', 'Invalid compteurFin', function (value) {

                    const valeuCompteurDebutReelTravail = parseFloat(this.resolve(Yup.ref('valeuCompteurDebutReelTravail'))?.toString().replace(/,/, '.'));
                    const valeuCompteurFinReelTravail = parseFloat(value);
                    const statutOrdreTravail = this.resolve(Yup.ref('statutOrdreTravail'));

                    // console.log("value",value)
                    // console.log("valeuCompteurDebutReelTravail",valeuCompteurDebutReelTravail)
                    // console.log("valeuCompteurFinReelTravail",valeuCompteurFinReelTravail)
                    // console.log("statutOrdreTravail",statutOrdreTravail)

                    if (!isNaN(valeuCompteurDebutReelTravail) && valeuCompteurFinReelTravail < valeuCompteurDebutReelTravail) {
                        return this.createError({ message: 'Le relevé kilométrique de fin doit être supérieur ou égal au relevé de début' });
                    }

                    if (statutOrdreTravail === "complété") {
                        if (!value) {
                            return this.createError({ message: 'Champ obligatoire' });
                        }
                    }

                    return true;
                }),
            dateDebutReelTravail: Yup.string()
                .required('Champ obligatoire'),
            dateFinReelTravail: Yup.string().test('dateFin-validation', 'Invalid dateFin', function (value) {
                const statutOrdreTravail = this.resolve(Yup.ref('statutOrdreTravail'));
                //console.log(value)
                if (statutOrdreTravail === "complété") {
                    if (!value) {
                        return this.createError({ message: 'Champ obligatoire' });
                    }
                }

                return true;
            }),
        }),
        onSubmit: async (values) => {
            try {
                const listProblemesResolus = problemesVehicule
                    .filter(item => item.acces === true)
                    .map((item) => ({
                        idProbleme: item.idProbleme,
                        titre: item.titre,
                        description: item.description,
                        affecterA: item.affecterA,
                        dateLimit: moment(item.dateLimit).isValid() ? item.dateLimit : '' || ''
                    }));

                const listeItems = items
                    .map((item) => ({
                        typeItem: item.typeItem,
                        idServiceRappelle: item.idServiceRappelle,
                        idTacheService: item.idTacheService,
                        libelleTacheService: item.libelleTacheService,
                        descriptionTacheService: item.descriptionTacheService,
                        remarque: item.remarque,
                        coutMainOeuvre: item.coutMainOeuvre.toString(),
                        coutPiecesRechange: item.coutPiecesRechange.toString(),
                        coutTotal: item.coutTotal.toString(),
                    }));

                const formData = new FormData();
                formData.append('idVehicule', values.vehicule.idVehicule);
                formData.append('typeOrdreTravail', values.typeOrdreTravail);
                formData.append('statutOrdreTravail', values.statutOrdreTravail);
                formData.append('dateDelivranceOrdreTravail', values.dateDelivranceOrdreTravail);

                if (values.delivrerPar) {
                    formData.append('delivrerPar', values.delivrerPar.nom + ' ' + values.delivrerPar.prenom);
                    formData.append('delivrerParId', values.delivrerPar.idEmploye);
                }
                else {
                    formData.append('delivrerPar', authData.nomPrenom);
                    formData.append('delivrerParId', authData.idUser);
                }

                formData.append('dateDebutReelTravail', values.dateDebutReelTravail);
                formData.append('valeuCompteurDebutReelTravail', values.valeuCompteurDebutReelTravail.toString());

                if (values.statutOrdreTravail === "complété") {
                    formData.append('dateFinReelTravail', values.dateFinReelTravail);
                    formData.append('valeuCompteurFinReelTravail', values.valeuCompteurFinReelTravail.toString());
                }


                formData.append('idFournisseur', values.idFournisseur);
                formData.append('numeroFacture', values.numeroFacture);
                formData.append('numeroBonCommande', values.numeroBonCommande);

                for (let i = 0; i < listProblemesResolus.length; i++) {
                    for (let key of Object.keys(listProblemesResolus[i])) {
                        formData.append(`listeProblemes[${i}][${key}]`, listProblemesResolus[i][key]);
                    }
                }

                for (let i = 0; i < listeItems.length; i++) {
                    for (let key of Object.keys(listeItems[i])) {
                        formData.append(`listeItems[${i}][${key}]`, listeItems[i][key]);
                    }
                }

                formData.append('remarqesGenerale', values.remarqesGenerale);
                formData.append('coutMainOeuvre', values.coutMainOeuvre.toString());
                formData.append('coutPiecesRechange', values.coutPiecesRechange.toString());
                formData.append('coutTotal', values.coutTotal.toString());
                formData.append('commentaire', values.commentaire);

                listPathDocumentsJustificatifs.forEach((file, index) => {
                    formData.append('listPathDocumentsJustificatifs', file);
                });

                formData.append('idUtilisateurAction', authData.idUser);
                formData.append('utilisateurAction', authData.nomPrenom);

                // formData.forEach((value, key) => {
                //     console.log(`${key}: ${value}`);
                // });

                await axiosInstance.post(VehiculeOrdreTravail, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });
                CustomToast("L'ordre de travail a été créé avec succès", 'success');
                navigate('/listordres', { replace: true });
            } catch (error) {
                // Handle error
                if (!error?.response) {
                    CustomToast("Aucune réponse du serveur", 'error');
                    setVehicleNotFound(false);

                    setKiloDebGreater({ display: false, msg: "" });
                    setKiloDebLess({ display: false, msg: "" });
                    setKiloDebBetween({ display: false, msg: "" });

                    setKiloFinGreater({ display: false, msg: "" });
                    setKiloFinLess({ display: false, msg: "" });
                    setKiloFinBetween({ display: false, msg: "" });
                } else if (error.response?.status === 400 && error.response?.data === "Vehicle not found") {
                    CustomToast("Véhicule introuvable", 'error');
                    setVehicleNotFound(true);

                    setKiloDebGreater({ display: false, msg: "" });
                    setKiloDebLess({ display: false, msg: "" });
                    setKiloDebBetween({ display: false, msg: "" });

                    setKiloFinGreater({ display: false, msg: "" });
                    setKiloFinLess({ display: false, msg: "" });
                    setKiloFinBetween({ display: false, msg: "" });
                } else if (error.response?.status === 400 && error.response?.data?.msg === "Incorrect start value, must be greater") {
                    CustomToast("L'ordre de travail n'a pas pu être créée", 'error');
                    setKiloDebGreater({ display: true, msg: `Doit être supérieur à ${error.response?.data?.val1}` });
                    setVehicleNotFound(false);

                    setKiloDebLess({ display: false, msg: "" });
                    setKiloDebBetween({ display: false, msg: "" });

                    setKiloFinGreater({ display: false, msg: "" });
                    setKiloFinLess({ display: false, msg: "" });
                    setKiloFinBetween({ display: false, msg: "" });
                } else if (error.response?.status === 400 && error.response?.data?.msg === "Incorrect start value, must be less") {
                    CustomToast("L'ordre de travail n'a pas pu être créée", 'error');
                    setKiloDebLess({ display: true, msg: `Doit être inférieur à ${error.response?.data?.val1}` });
                    setVehicleNotFound(false);

                    setKiloDebGreater({ display: false, msg: "" });
                    setKiloDebBetween({ display: false, msg: "" });

                    setKiloFinGreater({ display: false, msg: "" });
                    setKiloFinLess({ display: false, msg: "" });
                    setKiloFinBetween({ display: false, msg: "" });
                } else if (error.response?.status === 400 && error.response?.data?.msg === "Incorrect start value, must be between") {
                    CustomToast("L'ordre de travail n'a pas pu être créée", 'error');
                    setKiloDebBetween({ display: true, msg: `Doit être entre ${error.response?.data?.val1} et ${error.response?.data?.val2}` });
                    setVehicleNotFound(false);

                    setKiloDebGreater({ display: false, msg: "" });
                    setKiloDebLess({ display: false, msg: "" });

                    setKiloFinGreater({ display: false, msg: "" });
                    setKiloFinLess({ display: false, msg: "" });
                    setKiloFinBetween({ display: false, msg: "" });
                } else if (error.response?.status === 400 && error.response?.data?.msg === "Incorrect end value, must be greater") {
                    CustomToast("L'ordre de travail n'a pas pu être créée", 'error');
                    setKiloFinGreater({ display: true, msg: `Doit être supérieur à ${error.response?.data?.val1}` });
                    setVehicleNotFound(false);

                    setKiloFinLess({ display: false, msg: "" });
                    setKiloFinBetween({ display: false, msg: "" });

                    setKiloDebGreater({ display: false, msg: "" });
                    setKiloDebLess({ display: false, msg: "" });
                    setKiloDebBetween({ display: false, msg: "" });
                } else if (error.response?.status === 400 && error.response?.data?.msg === "Incorrect end value, must be less") {
                    CustomToast("L'ordre de travail n'a pas pu être créée", 'error');
                    setKiloFinLess({ display: true, msg: `Doit être inférieur à ${error.response?.data?.val1}` });
                    setVehicleNotFound(false);

                    setKiloFinGreater({ display: false, msg: "" });
                    setKiloFinBetween({ display: false, msg: "" });

                    setKiloDebGreater({ display: false, msg: "" });
                    setKiloDebLess({ display: false, msg: "" });
                    setKiloDebBetween({ display: false, msg: "" });
                } else if (error.response?.status === 400 && error.response?.data?.msg === "Incorrect end value, must be between") {
                    CustomToast("L'ordre de travail n'a pas pu être créée", 'error');
                    setKiloFinBetween({ display: true, msg: `Doit être entre ${error.response?.data?.val1} et ${error.response?.data?.val2}` });
                    setVehicleNotFound(false);

                    setKiloFinGreater({ display: false, msg: "" });
                    setKiloFinLess({ display: false, msg: "" });

                    setKiloDebGreater({ display: false, msg: "" });
                    setKiloDebLess({ display: false, msg: "" });
                    setKiloDebBetween({ display: false, msg: "" });
                } else if (error.response?.status === 401) {
                    CustomToast("Non autorisé", 'error');
                    setVehicleNotFound(false);

                    setKiloDebGreater({ display: false, msg: "" });
                    setKiloDebLess({ display: false, msg: "" });
                    setKiloDebBetween({ display: false, msg: "" });

                    setKiloFinGreater({ display: false, msg: "" });
                    setKiloFinLess({ display: false, msg: "" });
                    setKiloFinBetween({ display: false, msg: "" });
                } else {
                    CustomToast("Demande échoué", 'error');
                    setVehicleNotFound(false);

                    setKiloDebGreater({ display: false, msg: "" });
                    setKiloDebLess({ display: false, msg: "" });
                    setKiloDebBetween({ display: false, msg: "" });

                    setKiloFinGreater({ display: false, msg: "" });
                    setKiloFinLess({ display: false, msg: "" });
                    setKiloFinBetween({ display: false, msg: "" });
                }
                console.log(error);
            }
        }
    });

    //Ajouter items
    const handleTacheChange = (selectedOption) => {
        setSelectedTache(selectedOption);
        setSelectedTacheId(selectedOption ? selectedOption.idTacheService : '');

        if (!selectedOption) {
            return;
        }

        const newItem = {
            typeItem: 'tache de service',
            idServiceRappelle: '',
            nouveauRappele: 'new',
            idTacheService: selectedOption?.idTacheService || '',
            libelleTacheService: selectedOption?.libelle || '',
            descriptionTacheService: selectedOption?.description || '',
            remarque: '',
            coutMainOeuvre: 0,
            coutPiecesRechange: 0,
            coutTotal: 0,
            isCoutMainOeuvreEnabled: true,
            isCoutPiecesRechangeEnabled: true,
            isCoutTotalEnabled: true,
            showRemarque: true
        };

        addItem(newItem);
        setSelectedTache(null);
    };

    const [items, setItems] = useState([]);

    const addItem = (newItem) => {
        setItems([...items, newItem]);
    };

    const deleteItem = (itemIndex) => {
        const updatedItems = items.filter((item, index) => index !== itemIndex);
        setItems(updatedItems);
    };

    // Function to handle changes in "coutMainOeuvre" input
    const handleCoutMainOeuvreChange = (index, newValue) => {
        const updatedItems = [...items];
        if (!newValue) {
            newValue = 0;
        }
        updatedItems[index].coutMainOeuvre = newValue;
        const coutPiecesRechange = parseFloat(updatedItems[index].coutPiecesRechange.toString().replace(/,/, '.'));
        const coutMainOeuvre = parseFloat(newValue.toString().replace(/,/, '.'));

        // Calculate "coutTotal" based on the updated values
        updatedItems[index].coutTotal = parseFloat(updatedItems[index].coutPiecesRechange.toString().replace(/,/, '.'), 2) + parseFloat(newValue.toString().replace(/,/, '.'), 2);
        // Disable coutTotal if either coutMainOeuvre or coutPiecesRechange is greater than 0
        updatedItems[index].isCoutTotalEnabled = !(coutMainOeuvre > 0 || coutPiecesRechange > 0);

        setItems(updatedItems);
    };

    // Function to handle changes in "coutPiecesRechange" input
    const handleCoutPiecesRechangeChange = (index, newValue) => {
        const updatedItems = [...items];
        if (!newValue) {
            newValue = 0;
        }
        updatedItems[index].coutPiecesRechange = newValue;
        // Calculate "coutTotal" based on the updated values
        const coutPiecesRechange = parseFloat(newValue.toString().replace(/,/, '.'));
        const coutMainOeuvre = parseFloat(updatedItems[index].coutMainOeuvre.toString().replace(/,/, '.'));

        updatedItems[index].coutTotal = parseFloat(newValue.toString().replace(/,/, '.'), 2) + parseFloat(updatedItems[index].coutMainOeuvre.toString().replace(/,/, '.'), 2);
        // Disable coutTotal if either coutMainOeuvre or coutPiecesRechange is greater than 0
        updatedItems[index].isCoutTotalEnabled = !(coutMainOeuvre > 0 || coutPiecesRechange > 0);

        setItems(updatedItems);
    };

    // Function to handle changes in "coutTotal" input
    const handleCoutTotalChange = (index, newValue) => {
        const updatedItems = [...items];
        if (!newValue) {
            newValue = 0;
        }
        updatedItems[index].coutTotal = newValue;
        // Calculate "coutPiecesRechange" based on the updated values
        updatedItems[index].coutPiecesRechange = 0;
        // Calculate "coutMainOeuvre" based on the updated values
        updatedItems[index].coutMainOeuvre = 0;

        // Disable coutMainOeuvre and coutPiecesRechange if coutTotal is greater than 0
        updatedItems[index].isCoutMainOeuvreEnabled = !(newValue > 0);
        updatedItems[index].isCoutPiecesRechangeEnabled = !(newValue > 0);

        setItems(updatedItems);
    };

    // Function to handle changes in "remarque" textarea
    const handleRemarqueChange = (index, newValue) => {
        const updatedItems = [...items];
        updatedItems[index].remarque = newValue;
        setItems(updatedItems);
    };

    //Function to show/hide field "remarque" textarea
    const onShowRemarque = (index) => {
        //console.log(index);
        const updatedItems = [...items];
        updatedItems[index].showRemarque = !updatedItems[index].showRemarque;
        setItems(updatedItems);
    };

    const calculateTotals = () => {
        let totalCoutMainOeuvre = 0;
        let totalCoutPiecesRechange = 0;
        let totalCoutTotal = 0;

        // Iterate through the items to calculate totals
        items.forEach((item) => {
            totalCoutMainOeuvre += parseFloat(item.coutMainOeuvre.toString().replace(/,/, '.'));
            totalCoutPiecesRechange += parseFloat(item.coutPiecesRechange.toString().replace(/,/, '.'));
            totalCoutTotal += parseFloat(item.coutTotal.toString().replace(/,/, '.'));
        });

        return {
            totalCoutMainOeuvre,
            totalCoutPiecesRechange,
            totalCoutTotal,
        };
    };

    const updateTotals = () => {
        const { totalCoutMainOeuvre, totalCoutPiecesRechange, totalCoutTotal } = calculateTotals();

        setFieldValue('coutMainOeuvre', totalCoutMainOeuvre.toFixed(2));
        setFieldValue('coutPiecesRechange', totalCoutPiecesRechange.toFixed(2));
        setFieldValue('coutTotal', totalCoutTotal.toFixed(2));
    };

    //Load data
    useEffect(() => {
        //if (debouncedVehiculeText) {
        getVehicules(debouncedVehiculeText);
        //}
        return () => {
            // Perform any necessary cleanup (e.g., cancel pending requests)
        };
    }, [authData, debouncedVehiculeText]);

    useEffect(() => {
        // Load Data when enteredTechnicianText changes
        getTechiciens(debouncedTechText);
        return () => {
            // Perform any necessary cleanup (e.g., cancel pending requests)
        };
    }, [debouncedTechText]);

    useEffect(() => {
        //if (debouncedTaskText) {
        getTaches(debouncedTaskText);
        //}
        return () => {
            // Perform any necessary cleanup (e.g., cancel pending requests)
        };
    }, [debouncedTaskText]);

    useEffect(() => {
        //getVehicules('');
        getFournisseurs();
        //getTaches('');
        return () => {
            // Perform any necessary cleanup (e.g., cancel pending requests)
        };
    }, [])

    useEffect(() => {
        if (values.vehicule?.idVehicule) {
            getProblemes(values.vehicule.idVehicule);
        }
        return () => {
            // Perform any necessary cleanup (e.g., cancel pending requests)
        };
    }, [values.vehicule?.idVehicule])

    useEffect(() => {
        updateTotals();
        return () => {
            // Perform any necessary cleanup (e.g., cancel pending requests)
        };
    }, [items])


    return (
        <section className="service-section">
            <div className="container">
                <form onSubmit={handleSubmit} className="entreeservice-form">
                    <div className="">
                        <div className="grid grid--form-cols">
                            <div className="form-sec grid--grid-area-3">
                                <div className="retour" id="retour-entreeservice">
                                    <Link to="/listordres" style={{ textDecoration: "none" }}>
                                        <div className="arrow-back">
                                            <span className="material-symbols-outlined" id="arrow">arrow_back</span>
                                        </div>
                                    </Link>
                                </div>
                                <div className="small-menu-sec">
                                    <div id="detail-entree-service" className={detailsClass} onClick={() => showDetails()}>
                                        <span className="material-symbols-outlined">info</span>
                                        <h3 className="heading-primary">Détails</h3>
                                        {(errors.vehicule || errors.dateDelivranceOrdreTravail || errors.valeuCompteurDebutReelTravail || errors.valeuCompteurDebutReelTravail || errors.dateDebutReelTravail || (values.statutOrdreTravail === "complété" && (errors.dateFinReelTravail || errors.valeuCompteurFinReelTravail))) && <span className="material-symbols-outlined danger">error</span>}
                                    </div>
                                    <div className={problemesClass} id="problem-entree-service" onClick={() => showProblemes()}>
                                        <span className="material-symbols-outlined">report</span>
                                        <h3 className="heading-primary">Problèmes</h3>
                                    </div>
                                    <div className={tachesClass} id="tache-service-entree" onClick={() => showTaches()}>
                                        <span className="material-symbols-outlined">settings</span>
                                        <h3 className="heading-primary">Tâches de service</h3>
                                    </div>
                                    <div className={documentsClass} id="tache-service-entree" onClick={() => showDocuments()}>
                                        <span className="material-symbols-outlined">upload_file</span>
                                        <h3 className="heading-primary">Documents</h3>
                                    </div>
                                </div>
                            </div>

                            <div className="form-sec grid--grid-area-4">
                                <div className="retour" id="retour-login">
                                    <div className="arrow-back">

                                    </div>
                                    <button type="submit" className="submit-btn">Enregistrer</button>
                                </div>
                                <div className="form-details">
                                    {details && <div className="detail-form" id="detail-entree-service-form">
                                        <h3 className="heading-primary">Détails</h3>
                                        <div className="col">
                                            <div className="grp">
                                                <div className="gp">
                                                    <label>Véhicule<span className='requiredField'>*</span></label>
                                                    <Select
                                                        id="vehicule"
                                                        name="vehicule"
                                                        value={values.vehicule}
                                                        onChange={(option) => { setFieldValue('vehicule', option); setVehicleNotFound(false) }}
                                                        options={vehicules}
                                                        onInputChange={(inputValue) => {
                                                            // Update the entered text
                                                            setEnteredVehicleText(inputValue);
                                                        }}
                                                        getOptionLabel={(option) => option.marque + '[' + option.modele + '] ' + option.immatriculation}
                                                        getOptionValue={(option) => option.idVehicule}
                                                        isClearable
                                                        styles={{
                                                            container: (provided, state) => ({
                                                                ...provided,
                                                                boxShadow: state.isFocused ? '0px 0px 5px 0px #65458d' : errors.vehicule ? 'red' : '#BEBEBE',
                                                            }),
                                                            control: (baseStyles, state) => ({
                                                                ...baseStyles,
                                                                minHeight: '19px',
                                                                outline: '0!important',
                                                                borderRadius: '0.5rem',
                                                                borderWidth: '1px',
                                                                fontSize: '1rem',
                                                                height: '3.1rem',
                                                            }),
                                                            option: (provided, state) => ({
                                                                ...provided,
                                                                fontSize: '1rem'
                                                            }),
                                                            singleValue: (provided, state) => ({
                                                                ...provided,
                                                                fontSize: '1rem',
                                                                margin: '0',
                                                            }),
                                                            valueContainer: (provided, state) => ({
                                                                ...provided,
                                                                alignItems: 'start',
                                                                fontSize: '1rem'
                                                            }),
                                                        }}
                                                    />
                                                    {touched.vehicule && errors.vehicule && (
                                                        <div className="invalid-feedback">{errors.vehicule}</div>
                                                    )}
                                                    {vehicleNotFound && (
                                                        <div className="invalid-feedback">Véhicule introuvable</div>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="grp">
                                                <div className="gp">
                                                    <label >Statut<span className='requiredField'>*</span></label>
                                                    <select
                                                        id="statutOrdreTravail"
                                                        name="statutOrdreTravail"
                                                        value={values.statutOrdreTravail}
                                                        onChange={handleChange}
                                                    >
                                                        <option value="ouvert" defaultValue={true} title="Statut initial lorsqu'un ordre de travail est créé mais pas encore attribué ou démarré">Ouvert</option>
                                                        <option value="en attente" title=" L'ordre de travail est temporairement suspendu ou retardé pour une raison quelconque, comme l'attente de pièces ou matériaux nécessaires ne sont pas disponibles ou d'informations supplémentaires.">En attente</option>
                                                        <option value="attribué" title=" L'ordre de travail a été attribué à un technicien ou à une équipe spécifique responsable du service.">Attribué</option>
                                                        <option value="en cours" title="Le technicien ou l'équipe désigné a commencé à travailler sur l'entretien du véhicule.">En cours</option>
                                                        <option value="approbation" title="Le travail est terminé, mais il nécessite l'approbation d'un superviseur ou d'un gestionnaire avant de continuer.">Approbation</option>
                                                        <option value="complété" title="L'entretien du véhicule a été effectué avec succès.">Complété</option>
                                                        <option value="en retard" title="L'ordre de travail est en retard sur le calendrier et nécessite une attention particulière pour rattraper son retard.">En retard</option>
                                                        <option value="planifié" title="L'ordre de travail est planifié pour une date et une heure spécifique dans le futur.">Planifié</option>
                                                        <option value="annulé" title="L'ordre de travail est annulé, généralement en raison d'un changement de priorités, d'une entrée en double ou pour d'autres raisons.">Annulé</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="grp">
                                                <div className="gp">
                                                    <label>Date de délivrance<span className='requiredField'>*</span></label>
                                                    <input type="date"
                                                        id="dateDelivranceOrdreTravail"
                                                        name="dateDelivranceOrdreTravail"
                                                        style={(touched.dateDelivranceOrdreTravail && errors.dateDelivranceOrdreTravail) ? { borderColor: "red" } : null}
                                                        placeholder=""
                                                        defaultValue={values.dateDelivranceOrdreTravail}
                                                        onChange={handleChange}
                                                    />
                                                    {touched.dateDelivranceOrdreTravail && errors.dateDelivranceOrdreTravail ? (
                                                        <div className="invalid-feedback">{errors.dateDelivranceOrdreTravail}</div>
                                                    ) : null}
                                                </div>
                                                <div className="gp">
                                                    <label>Délivré par<span className='requiredField'>*</span></label>
                                                    <Select
                                                        id="delivrerPar"
                                                        name="delivrerPar"
                                                        value={values.delivrerPar}
                                                        onChange={(option) => { setFieldValue('delivrerPar', option); }}
                                                        options={techniciens}
                                                        onInputChange={(inputValue) => {
                                                            // Update the entered text
                                                            setEnteredTechnicianTextText(inputValue);
                                                        }}
                                                        getOptionLabel={(option) => option.nom + ' ' + option.prenom}
                                                        getOptionValue={(option) => option.idEmploye}
                                                        isClearable
                                                        styles={{
                                                            control: (baseStyles, state) => ({
                                                                ...baseStyles,
                                                                minHeight: '19px',
                                                                outline: '0!important',
                                                                borderRadius: '0.5rem',
                                                                borderWidth: '1px',
                                                                fontSize: '1rem',
                                                                height: '3.1rem',
                                                            }),
                                                            option: (provided, state) => ({
                                                                ...provided,
                                                                fontSize: '1rem'
                                                            }),
                                                            singleValue: (provided, state) => ({
                                                                ...provided,
                                                                fontSize: '1rem',
                                                                margin: '0',
                                                            }),
                                                            valueContainer: (provided, state) => ({
                                                                ...provided,
                                                                alignItems: 'start',
                                                                fontSize: '1rem'
                                                            }),
                                                        }}
                                                    />
                                                    {touched.delivrerPar && errors.delivrerPar && (
                                                        <div className="invalid-feedback">{errors.delivrerPar}</div>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="grp">
                                                <div className="gp">
                                                    <label>Date de début<span className='requiredField'>*</span></label>
                                                    <input type="date"
                                                        id="dateDebutReelTravail"
                                                        name="dateDebutReelTravail"
                                                        style={(touched.dateDebutReelTravail && errors.dateDebutReelTravail) ? { borderColor: "red" } : null}
                                                        placeholder=""
                                                        defaultValue={values.dateDebutReelTravail}
                                                        onChange={handleChange}
                                                    />
                                                    {touched.dateDebutReelTravail && errors.dateDebutReelTravail ? (
                                                        <div className="invalid-feedback">{errors.dateDebutReelTravail}</div>
                                                    ) : null}
                                                </div>
                                                <div className="gp">
                                                    <label>Compteur<span className='requiredField'>*</span></label>
                                                    <input
                                                        type="number"
                                                        id="valeuCompteurDebutReelTravail"
                                                        name="valeuCompteurDebutReelTravail"
                                                        placeholder=""
                                                        defaultValue={values.valeuCompteurDebutReelTravail}
                                                        onChange={handleChange}
                                                        step="any"
                                                    />
                                                    {errors.valeuCompteurDebutReelTravail && touched.valeuCompteurDebutReelTravail && (
                                                        <div className="invalid-feedback">{errors.valeuCompteurDebutReelTravail}</div>
                                                    )}
                                                    {kiloDebLess.display && (
                                                        <div className="invalid-feedback">{kiloDebLess.msg}</div>
                                                    )}
                                                    {kiloDebGreater.display && (
                                                        <div className="invalid-feedback">{kiloDebGreater.msg}</div>
                                                    )}
                                                    {kiloDebBetween.display && (
                                                        <div className="invalid-feedback">{kiloDebBetween.msg}</div>
                                                    )}
                                                </div>
                                            </div>
                                            {values.statutOrdreTravail === "complété" && <div className="grp">
                                                <div className="gp">
                                                    <label>Date d'achèvement<span className='requiredField'>*</span></label>
                                                    <input type="date"
                                                        id="dateFinReelTravail"
                                                        name="dateFinReelTravail"
                                                        style={(touched.dateFinReelTravail && errors.dateFinReelTravail) ? { borderColor: "red" } : null}
                                                        placeholder=""
                                                        defaultValue={values.dateFinReelTravail}
                                                        onChange={handleChange}
                                                    />
                                                    {touched.dateFinReelTravail && errors.dateFinReelTravail ? (
                                                        <div className="invalid-feedback">{errors.dateFinReelTravail}</div>
                                                    ) : null}
                                                </div>
                                                <div className="gp">
                                                    <label>Compteur<span className='requiredField'>*</span></label>
                                                    <input
                                                        type="number"
                                                        id="valeuCompteurFinReelTravail"
                                                        name="valeuCompteurFinReelTravail"
                                                        placeholder=""
                                                        defaultValue={values.valeuCompteurFinReelTravail}
                                                        onChange={handleChange}
                                                        step="any"
                                                    />
                                                    {errors.valeuCompteurFinReelTravail && touched.valeuCompteurFinReelTravail && (
                                                        <div className="invalid-feedback">{errors.valeuCompteurFinReelTravail}</div>
                                                    )}
                                                    {kiloFinLess.display && (
                                                        <div className="invalid-feedback">{kiloFinLess.msg}</div>
                                                    )}
                                                    {kiloFinGreater.display && (
                                                        <div className="invalid-feedback">{kiloFinGreater.msg}</div>
                                                    )}
                                                    {kiloFinBetween.display && (
                                                        <div className="invalid-feedback">{kiloFinBetween.msg}</div>
                                                    )}
                                                </div>
                                            </div>}
                                            <div className="grp">
                                                <div className="gp">
                                                    <label >Réparation</label>
                                                    <select
                                                        id="typeOrdreTravail"
                                                        name="typeOrdreTravail"
                                                        value={values.typeOrdreTravail}
                                                        onChange={handleChange}
                                                    >
                                                        <option value="interne" defaultValue={true}>Interne</option>
                                                        <option value="externe">Externe</option>
                                                    </select>
                                                </div>
                                                <div className="gp">
                                                    <label >Fournisseur</label>
                                                    <select
                                                        id="idFournisseur"
                                                        name="idFournisseur"
                                                        value={values.idFournisseur}
                                                        onChange={handleChange}
                                                    >
                                                        <option value="">Veuillez sélectionner</option>
                                                        {fournisseurs.map((fournisseur) => (
                                                            <option key={fournisseur.idFournisseur} value={fournisseur.idFournisseur}>{fournisseur.raisonSociale}</option>
                                                        ))}
                                                    </select>
                                                </div>
                                                <div className="gp">
                                                    <label title='ex: N° de facture'>Référence</label>
                                                    <input
                                                        id="numeroFacture"
                                                        name="numeroFacture"
                                                        type="text"
                                                        value={values.numeroFacture}
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>}

                                    {problemes && <div className="detail-form" id="problem-entree-form">
                                        <h3 className="heading-primary">Problèmes</h3>
                                        <label style={{ fontSize: '.1rem', fontStyle: 'italic' }}>Sélectionnez tous les problèmes résolus dans le cadre de ce service</label>
                                        <div className="col">
                                            <div className="grp">
                                                <div className="gp">
                                                    {problemesVehicule && <table className="element-inspec-table">
                                                        <thead>
                                                            <tr>
                                                                <th></th>
                                                                <th>Problème</th>
                                                                <th>Résumé</th>
                                                                <th>Priorité</th>
                                                                <th>Statut</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {problemesVehicule?.map((item, index) => (
                                                                <tr key={index} style={{ fontSize: '1rem' }}>
                                                                    <td>
                                                                        <input type="checkbox" className="box" onClick={() => onCheck(item.idProbleme)} defaultChecked={item.acces ? item.acces : false} />
                                                                    </td>
                                                                    <td>
                                                                        <span>{item.titre.charAt(0).toUpperCase() + item.titre.slice(1)}</span>
                                                                    </td>
                                                                    <td>
                                                                        <span>{item.description.charAt(0).toUpperCase() + item.description.slice(1)}</span>
                                                                    </td>
                                                                    {(item.priorite === "faible") && <td><span className="material-symbols-outlined" style={{ color: "grey", fontSize: '100%' }}>stat_1</span> Faible</td>}
                                                                    {(item.priorite === "moyen") && <td><span className="material-symbols-outlined" style={{ color: "yellow", fontSize: '100%' }}>stat_2</span> Moyen</td>}
                                                                    {(item.priorite === "élevé") && <td><span className="material-symbols-outlined" style={{ color: "orange", fontSize: '100%' }}>stat_3</span> Élevé</td>}
                                                                    {(item.priorite === "critique") && <td><span className="material-symbols-outlined" style={{ color: "red", fontSize: '100%' }}>error</span> Critique</td>}
                                                                    {(item.priorite === "" || item.priorite === "aucune") && <td><span className="material-symbols-outlined" style={{ fontSize: '100%' }}>radio_button_unchecked</span> Aucune</td>}
                                                                    {(item.statut === "ouvert") && <td><svg height="11.87" width="15"><circle cx="6" cy="6" r="6" fill="yellow" /></svg>Ouvert</td>}
                                                                    {(item.statut === "en retard") && <td><svg height="11.87" width="15"><circle cx="6" cy="6" r="6" fill="red" /></svg>En retard</td>}
                                                                    {(item.statut === "") && <td></td>}
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>}
                                                </div>
                                            </div>
                                        </div>
                                    </div>}

                                    {taches && <div className="cycle-form" id="tache-service-form">
                                        <div className="detail-form">
                                            <h3 className="heading-primary">Tâches de service</h3>
                                            <div className="col">
                                                <div className="grp">
                                                    <div className="gp">
                                                        {/* <label>Tâches de service</label> */}
                                                        <Select
                                                            id="tacheService"
                                                            name="tacheService"
                                                            value={selectedTache}
                                                            onChange={(option) => { handleTacheChange(option); }}
                                                            options={tachesService}
                                                            onInputChange={(inputValue) => {
                                                                // Update the entered text
                                                                setEnteredTaskText(inputValue);
                                                            }}
                                                            getOptionLabel={(option) => option.libelle}
                                                            getOptionValue={(option) => option.idTacheService}
                                                            isClearable
                                                            styles={{
                                                                control: (baseStyles, state) => ({
                                                                    ...baseStyles,
                                                                    minHeight: '19px',
                                                                    outline: '0!important',
                                                                    borderRadius: '0.5rem',
                                                                    borderWidth: '1px',
                                                                    fontSize: '1rem',
                                                                    height: '3.1rem',
                                                                }),
                                                                option: (provided, state) => ({
                                                                    ...provided,
                                                                    fontSize: '1rem'
                                                                }),
                                                                singleValue: (provided, state) => ({
                                                                    ...provided,
                                                                    fontSize: '1rem',
                                                                    margin: '0',
                                                                }),
                                                                valueContainer: (provided, state) => ({
                                                                    ...provided,
                                                                    alignItems: 'start',
                                                                    fontSize: '1rem'
                                                                }),
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="grp">
                                                    <div className="gp">
                                                        <table className="element-inspec-table">
                                                            <thead>
                                                                <tr>
                                                                    <th>Tâches</th>
                                                                    <th>Main-d'oeuvre</th>
                                                                    <th>Pièces</th>
                                                                    <th>Total</th>
                                                                    <th></th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {items?.map((item, index) => (
                                                                   <React.Fragment key={index}> <tr key={index}>
                                                                        <td className="row row-center">
                                                                            <p title={items[index]?.descriptionTacheService}>{item.libelleTacheService}</p>
                                                                            <span className="material-symbols-outlined cursurer" onClick={() => onShowRemarque(index)}>
                                                                                {items[index].showRemarque ? 'arrow_drop_down' : 'arrow_right'}
                                                                            </span>
                                                                        </td>
                                                                        <td>
                                                                            <input
                                                                                type="number"
                                                                                name="coutMainOeuvre"
                                                                                id={`coutMainOeuvre-${index}`}
                                                                                value={item.coutMainOeuvre}
                                                                                step="any"
                                                                                style={{ textAlign: 'right' }}
                                                                                className="input-table-form"
                                                                                onChange={(e) => handleCoutMainOeuvreChange(index, e.target.value)}
                                                                                disabled={!item.isCoutMainOeuvreEnabled}
                                                                                title={item.isCoutMainOeuvreEnabled ? '' : 'Cette valeur est automatiquement calculée par les autres colonnes et ne peut pas être directement modifiée.'}
                                                                            />
                                                                        </td>
                                                                        <td>
                                                                            <input
                                                                                type="number"
                                                                                name="coutPiecesRechange"
                                                                                id={`coutPiecesRechange-${index}`}
                                                                                value={item.coutPiecesRechange}
                                                                                step="any"
                                                                                style={{ textAlign: 'right' }}
                                                                                className="input-table-form"
                                                                                onChange={(e) => handleCoutPiecesRechangeChange(index, e.target.value)}
                                                                                disabled={!item.isCoutPiecesRechangeEnabled}
                                                                                title={item.isCoutPiecesRechangeEnabled ? '' : 'Cette valeur est automatiquement calculée par les autres colonnes et ne peut pas être directement modifiée.'}
                                                                            />
                                                                        </td>
                                                                        <td>
                                                                            <input
                                                                                type="number"
                                                                                name="coutTotal"
                                                                                id={`coutTotal-${index}`}
                                                                                value={item.coutTotal ? item.coutTotal : 0}
                                                                                step="any"
                                                                                className="input-table-form"
                                                                                style={{ textAlign: 'right' }}
                                                                                onChange={(e) => handleCoutTotalChange(index, e.target.value)}
                                                                                disabled={!item.isCoutTotalEnabled}
                                                                                title={item.isCoutTotalEnabled ? '' : 'Cette valeur est automatiquement calculée par les autres colonnes et ne peut pas être directement modifiée.'}
                                                                            />
                                                                        </td>
                                                                        <td>
                                                                            <span
                                                                                className="material-symbols-outlined danger" onClick={() => deleteItem(index)}>delete</span>
                                                                        </td>
                                                                    </tr>
                                                                    {items[index].showRemarque && <tr key={`TRremarque-${index}`}>
                                                                            <td colSpan={4}>
                                                                                 <textarea
                                                                                name="remarque"
                                                                                id={`remarque-${index}`}
                                                                                value={item?.remarque}
                                                                                cols="100"
                                                                                rows="3"
                                                                                onChange={(e) => handleRemarqueChange(index, e.target.value)}
                                                                                placeholder='Ajouter des notes ou des détails supplémentaires'
                                                                                style={{width:'100%',height:'20%'}}                                                                           
                                                                           ></textarea>
                                                                            </td>
                                                                        </tr>}
                                                                    </React.Fragment>
                                                                ))}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                                <div className="grp">
                                                    <div className="gp">
                                                        <label>Notes générales</label>
                                                        <textarea
                                                            name="remarqesGenerale"
                                                            id="remarqesGenerale"
                                                            value={values.remarqesGenerale}
                                                            rows={4}
                                                            onChange={handleChange}
                                                            placeholder='Ajouter des notes ou des détails supplémentaires'
                                                        ></textarea>
                                                    </div>
                                                    <div className="gp">
                                                        <label>Résumé des coûts</label>
                                                        <table className='cost-summary'>
                                                            <tbody>
                                                                <tr>
                                                                    <th className='td-left td-weight-normal'>Main-d'œuvre</th>
                                                                    <td className='td-right td-weight-normal'>{formatCurrency(values.coutMainOeuvre)} {authData?.unite_moneitaire}</td>
                                                                </tr>
                                                                <tr>
                                                                    <th className='td-left td-weight-normal'>Pièces</th>
                                                                    <td className='td-right td-weight-normal'>{formatCurrency(values.coutPiecesRechange)} {authData?.unite_moneitaire}</td>
                                                                </tr>
                                                                <tr>
                                                                    <th className='td-left td-weight-bold'>Total</th>
                                                                    <td className='td-right td-weight-bold'>{formatCurrency(values.coutTotal)} {authData?.unite_moneitaire}</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>}

                                    {documents && <div className="detail-form" id="detail-entree-service-form">
                                        <h3 className="heading-primary">Documents</h3>
                                        <div className="col">
                                            <div className="grp">
                                                <div className="gp">
                                                    <label>Commentaires</label>
                                                    <textarea
                                                        id="commentaire"
                                                        name="commentaire"
                                                        type="text"
                                                        value={values.commentaire}
                                                        onChange={handleChange}
                                                        rows={4}
                                                    />
                                                </div>
                                            </div>
                                            <div className="grp">
                                                <div className="gp">
                                                    <label >Documents</label>
                                                    <label className="upload-file">
                                                        upload file
                                                        <input
                                                            ref={listPathDocumentsJustificatifsRef}
                                                            id="listPathDocumentsJustificatifs"
                                                            name="listPathDocumentsJustificatifs"
                                                            type="file"
                                                            onChange={handlePathImageChange}
                                                            multiple
                                                        />
                                                    </label>
                                                    <div style={(listPathDocumentsJustificatifs && listPathDocumentsJustificatifs?.length > 3) ? { fontSize: '.6rem', display: 'flex', flexDirection: 'row' } : { fontSize: '.8rem' }}>
                                                        {listPathDocumentsJustificatifs.map((file, index) => (
                                                            <div key={index}>
                                                                {file.name}
                                                                <span
                                                                    className="material-symbols-outlined"
                                                                    onClick={() => handleDeleteFile(index)}
                                                                    style={{ fontSize: '200%' }}
                                                                >
                                                                    delete
                                                                </span>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>}

                                    <div className="submit-container">
                                        <button type="submit" className="submit-btn">Enregistrer</button>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </section>
    );
}

export default AjouterOrdre;
