import React, { useEffect, useState, useRef, useContext } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import moment from 'moment/moment';
import * as Yup from 'yup';
import { CustomToast } from '../../components/Toast/CustomToast';
import { axiosInstance, Proprietaire, TypePieceIdentite, Wilaya, Commune } from '../../services/api';
import { AuthContext } from '../../contexts/AuthContext';

const ModifierProprietaire = () => {
    const { authData } = useContext(AuthContext);
    const navigate = useNavigate();
    const { id } = useParams();//idProprietaire

    const [isLoaded, setIsLoaded] = useState();

    const [piecesIdentites, setPiecesIdentites] = useState([]);
    const [wilayas, setWilayas] = useState([]);
    const [communes, setCommunes] = useState([]);
    const [siFreelancer, setSiFreelancer] = useState(false);

    const [entreprises, setEntreprises] = useState([]);
    const [statuts, setStatuts] = useState([
        { id: "actif", libelle: "Actif" },
        { id: "inactif", libelle: "Inactif" },
        { id: "archivé", libelle: "Archivé" },
        { id: "suspendu", libelle: "Suspendu" },
        { id: "licencié", libelle: "Licencié" },
    ]);

    const [pathImagePieceIdentite, setPathImagePieceIdentite] = useState(null);
    const [pathImagePieceIdentiteName, setPathImagePieceIdentiteName] = useState('');

    const pathImagePieceIdentiteRef = useRef(null)

    //Load data
    const getProprietaire = async (idProprietaire) => {
        try {
            const response = await axiosInstance.get(`${Proprietaire}/${idProprietaire}`);
            const resp = await response.data;
            setIsLoaded(resp);

            setValues(prevValues => ({
                ...prevValues,
                nom: resp.nom || '',
                prenom: resp.prenom || '',
                raisonSociale: resp.raisonSociale || '',
                adresse: resp.adresse || '',
                numTele1: resp.numTele1 || '',
                numTele2: resp.numTele2 || '',
                nrc: resp.nrc || '',
                siteWeb: resp.siteWeb || '',
                email: resp.email || '',
                idTypePieceIdentite: resp.idTypePieceIdentite || '',
                numPieceIdentite: resp.numPieceIdentite || '',
                numCompteBancaire: resp.numCompteBancaire || '',
                typeProprietaire: resp.typeProprietaire || 'entreprise',
                statut: resp.statut || 'actif',
                idWilaya: resp.idWilaya || '',
                idCommune: resp.idCommune || '',
                idEntrepriseContract: resp.idEntrepriseContract || '',
                dateDebutEmploi: moment(resp.dateDebutEmploi).format('YYYY-MM-DD') || '',
                dateFinEmploi: moment(resp.dateFinEmploi).format('YYYY-MM-DD') || '',
            }));

            setPathImagePieceIdentiteName(resp.pathImagePieceIdentite || '');
        }
        catch (error) {
            // Handle error
            if (!error?.response) {
                CustomToast("Aucune réponse du serveur", 'error');
            } else if (error.response?.status === 404 && error.response?.data === "Proprietaire does not exist") {
                CustomToast("Propriétaire introuvable", 'error');
            } else if (error.response?.status === 401) {
                CustomToast("Non autorisé", 'error');
            } else if (error.response?.status === 400) {
                CustomToast("Demande incorrecte", 'error');
            } else {
                CustomToast("Demande échoué", 'error');
            }
            console.log(error);

        }
    }

    const getTypesPieceIdentites = async () => {
        try {
            const response = await axiosInstance.get(`${TypePieceIdentite}`);
            const resp = await response.data;
            setPiecesIdentites(resp);
        }
        catch (err) {
            console.log(err);
        }
    }

    const getCommunes = async (idWilaya) => {
        try {
            const response = await axiosInstance.get(`${Commune}/SearchCommunes/${idWilaya}`);
            const resp = await response.data;
            setCommunes(resp);
        }
        catch (err) {
            console.log(err);
        }
    }

    const getWilayas = async () => {
        try {
            const response = await axiosInstance.get(`${Wilaya}`);
            const resp = await response.data;
            setWilayas(resp);
        }
        catch (err) {
            console.log(err);
        }
    }

    const getProprietaires = async () => {
        try {
            const queryParams = new URLSearchParams({
                typeProprietaire: "entreprise"
            });
            const response = await axiosInstance.get(`${Proprietaire}/GetProprietaireByType?${queryParams}`);
            const resp = await response.data;
            setEntreprises(resp);
        }
        catch (err) {
            console.log(err);
        }
    }

    //Proprietaire document
    const handlePathImagePieceIdentiteChange = (e) => {
        setPathImagePieceIdentite(e.target.files[0]);
        setPathImagePieceIdentiteName('');
    };

    const getImageFileName = (file) => {
        return file ? file.name : 'No file selected';
    };

    const { handleChange, values, handleSubmit, touched, errors, setValues } = useFormik({
        initialValues: {
            nom: '',
            prenom: '',
            raisonSociale: '',
            adresse: '',
            numTele1: '',
            numTele2: '',
            nrc: '',
            siteWeb: '',
            email: '',
            idTypePieceIdentite: '',
            numPieceIdentite: '',
            numCompteBancaire: '',
            typeProprietaire: 'entreprise',
            statut: 'actif',
            idWilaya: '',
            idCommune: '',
            idEntrepriseContract: '',
            dateDebutEmploi: '',
            dateFinEmploi: '',
        },
        validationSchema: Yup.object({
            typeProprietaire: Yup.string(),
            email: Yup.string()
                .email("Le champ doit contenir un e-mail valide")
                .max(255, "Le champ doit contenir au maximum 255 caractères"),
                raisonSociale: Yup.string()
                .test('isRequired', 'Champ obligatoire', function (value) {
                    const typeProprietaire = this.resolve(Yup.ref('typeProprietaire'));
                    if (typeProprietaire === 'entreprise') {
                        return !!value;
                    }
                    return true;
                }),
            nom: Yup.string()
                .test('isRequired', 'Champ obligatoire', function (value) {
                    const typeProprietaire = this.resolve(Yup.ref('typeProprietaire'));
                    //console.log("nom",value,typeProprietaire)
                    if (typeProprietaire === 'freelancer') {
                        return !!value;
                    }
                    return true;
                }),
            prenom: Yup.string()
                .test('isRequired', 'Champ obligatoire', function (value) {
                    const typeProprietaire = this.resolve(Yup.ref('typeProprietaire'));
                    //console.log("prenom",value,typeProprietaire)
                    if (typeProprietaire === 'freelancer') {
                        return !!value;
                    }
                    return true;
                }),
            idEntrepriseContract: Yup.string()
                .test('isRequired', 'Champ obligatoire', function (value) {
                    const typeProprietaire = this.resolve(Yup.ref('typeProprietaire'));
                    //console.log("idEntrepriseContract",value,typeProprietaire)
                    if (typeProprietaire === 'freelancer') {
                        return !!value;
                    }
                    return true;
                })
        }),
        onSubmit: async (values) => {
            try {
                //console.log(values)
                const formData = new FormData();
                formData.append('idProprietaire', id);
                formData.append('nom', values.nom);
                formData.append('prenom', values.prenom);
                formData.append('raisonSociale', values.raisonSociale);
                formData.append('adresse', values.adresse);
                formData.append('numTele1', values.numTele1);
                formData.append('numTele2', values.numTele2);
                formData.append('nrc', values.nrc);
                formData.append('siteWeb', values.siteWeb);
                formData.append('email', values.email);
                formData.append('typeProprietaire', values.typeProprietaire);

                if (values.typeProprietaire === "freelancer") {
                    formData.append('idTypePieceIdentite', values.idTypePieceIdentite);
                    formData.append('numPieceIdentite', values.numPieceIdentite);
                    formData.append('dateDebutEmploi', values.dateDebutEmploi);
                    formData.append('dateFinEmploi', values.dateFinEmploi);
                    formData.append('pathImagePieceIdentite', pathImagePieceIdentite);
                    formData.append('pathImagePieceIdentiteName', pathImagePieceIdentiteName);
                }

                formData.append('numCompteBancaire', values.numCompteBancaire);
                formData.append('idEntrepriseContract', values.idEntrepriseContract);

                formData.append('statut', values.statut);
                formData.append('idWilaya', values.idWilaya);
                formData.append('idCommune', values.idCommune);
                formData.append('idUtilisateur', authData.idUser);

                await axiosInstance.put(Proprietaire, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });

                CustomToast("Propriètare a été modifié avec succès", 'success');
                navigate('/listproprietaires', { replace: true });
            } catch (error) {
                // Handle error
                console.log(error);
                if (!error?.response) {
                    CustomToast("Aucune réponse du serveur", 'error');
                } else if (error.response?.status === 401) {
                    CustomToast("Non autorisé", 'error');
                } else {
                    CustomToast("Demande échoué", 'error');
                }
            }
        }
    });

    useEffect(() => {
        //Load type pieces d'identité
        getTypesPieceIdentites();
        //Load wilayas
        getWilayas();
        getProprietaire(id);
    }, [])

    useEffect(() => {
        //Load communes
        if (values.idWilaya) {
            getCommunes(values.idWilaya);
        }
        else {
            setCommunes([]);
            values.idCommune = "";
        }
    }, [values.idWilaya])

    useEffect(() => {
        if (values.typeProprietaire === "freelancer") {
            setSiFreelancer(true);
            setStatuts([
                { id: "actif", libelle: "Actif" },
                { id: "inactif", libelle: "Inactif" },
                { id: "archivé", libelle: "Archivé" },
                { id: "suspendu", libelle: "Suspendu" },
                { id: "licencié", libelle: "Licencié" },
            ]);
            getProprietaires();
        } else {
            setSiFreelancer(false);
            setStatuts([
                { id: "actif", libelle: "Actif" },
                { id: "inactif", libelle: "Inactif" },
                { id: "archivé", libelle: "Archivé" },
            ]);
        }

        // values.statut = 'actif';
        // values.raisonSociale = '';
        // values.nom = '';
        // values.prenom = '';
        // values.idTypePieceIdentite = '';
        // values.numPieceIdentite = '';
        // values.idEntrepriseContract = '';
        // values.dateDebutEmploi = '';
        // values.dateFinEmploi = '';

    }, [values.typeProprietaire])

    return (
        <section className="donnee-section">
            <div className="container">
                <form onSubmit={handleSubmit} className="propretaire-form">
                    <div className="">
                        <div className="grid grid--2-cols">
                            <div className="form-sec">
                                <div className="retour" id="retour-login">
                                    <Link to="/listproprietaires" style={{ textDecoration: "none" }}>
                                        <div className="arrow-back">
                                            <span className="material-symbols-outlined" id="arrow">arrow_back</span>
                                        </div>
                                    </Link>
                                    {isLoaded && <button type="submit" className="submit-btn">Enregistrer</button>}
                                </div>
                                {isLoaded && <div className="form-details">
                                    <div className="detail-form" id="detail-proprietaire">
                                        <h3></h3>
                                        <div className="col">
                                            <div className="grp">
                                                <div className="gp">
                                                    <label>Type<span className='requiredField'>*</span></label>
                                                    <select
                                                        id="typeProprietaire"
                                                        name="typeProprietaire"
                                                        value={values.typeProprietaire}
                                                        onChange={handleChange}
                                                    >
                                                        <option value="entreprise" defaultValue={true}>Entreprise</option>
                                                        <option value="freelancer">Freelancer</option>
                                                        <option value="location">Location</option>
                                                    </select>
                                                </div>
                                            </div>
                                            {!siFreelancer && <div className="grp">
                                                <div className="gp">
                                                    <label >Raison sociale<span className='requiredField'>*</span></label>
                                                    <input type="text"
                                                        id="raisonSociale"
                                                        name="raisonSociale"
                                                        style={(touched.raisonSociale && errors.raisonSociale) ? { borderColor: "red" } : null}
                                                        placeholder=""
                                                        defaultValue={values.raisonSociale}
                                                        onChange={handleChange}
                                                    />
                                                    {touched.raisonSociale && errors.raisonSociale ? (
                                                        <div className="invalid-feedback">{errors.raisonSociale}</div>
                                                    ) : null}
                                                </div>
                                            </div>}
                                            {siFreelancer && <div className="grp">
                                                <div className="gp">
                                                    <label >Nom<span className='requiredField'>*</span></label>
                                                    <input type="text"
                                                        id="nom"
                                                        name="nom"
                                                        style={(touched.nom && errors.nom) ? { borderColor: "red" } : null}
                                                        placeholder=""
                                                        defaultValue={values.nom}
                                                        onChange={handleChange}
                                                    />
                                                    {touched.nom && errors.nom ? (
                                                        <div className="invalid-feedback">{errors.nom}</div>
                                                    ) : null}
                                                </div>
                                                <div className="gp">
                                                    <label >Prénom<span className='requiredField'>*</span></label>
                                                    <input type="text"
                                                        id="prenom"
                                                        name="prenom"
                                                        style={(touched.prenom && errors.prenom) ? { borderColor: "red" } : null}
                                                        placeholder=""
                                                        defaultValue={values.prenom}
                                                        onChange={handleChange}
                                                    />
                                                    {touched.prenom && errors.prenom ? (
                                                        <div className="invalid-feedback">{errors.prenom}</div>
                                                    ) : null}
                                                </div>
                                                <div className="gp">
                                                    <label >Type pièce d'identité</label>
                                                    <select
                                                        id="idTypePieceIdentite"
                                                        name="idTypePieceIdentite"
                                                        value={values.idTypePieceIdentite || ''}
                                                        onChange={handleChange}
                                                    >
                                                        <option value="">Veuillez sélectionner</option>
                                                        {piecesIdentites.map((categorie) => (
                                                            <option key={categorie.idTypePieceIdentite} value={categorie.idTypePieceIdentite}>{categorie.libelleTypePieceIdentite}</option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>}
                                            {siFreelancer && <div className="grp">
                                                <div className="gp">
                                                    <label>N° piece d'identité</label>
                                                    <input type="text"
                                                        id="numPieceIdentite"
                                                        name="numPieceIdentite"
                                                        placeholder=""
                                                        defaultValue={values.numPieceIdentite}
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                                <div className="gp">
                                                    <label>Pièce d'identité</label>
                                                    <label className="upload-file">
                                                        upload file
                                                        <input
                                                            ref={pathImagePieceIdentiteRef}
                                                            id="pathImagePieceIdentite"
                                                            name="pathImagePieceIdentite"
                                                            type="file"
                                                            onChange={handlePathImagePieceIdentiteChange}
                                                        />
                                                    </label>
                                                    {pathImagePieceIdentite && <span style={{ fontSize: "10px" }}>{getImageFileName(pathImagePieceIdentite)}
                                                        <span className="material-symbols-outlined" onClick={(e) => { setPathImagePieceIdentite(null); if (pathImagePieceIdentiteRef.current) { pathImagePieceIdentiteRef.current.value = ''; } }}>delete</span>
                                                    </span>}
                                                    {pathImagePieceIdentiteName && <span style={{ fontSize: "10px" }}>{pathImagePieceIdentiteName}
                                                        <span className="material-symbols-outlined" onClick={(e) => { setPathImagePieceIdentite(null); setPathImagePieceIdentiteName(''); if (pathImagePieceIdentiteRef.current) { pathImagePieceIdentiteRef.current.value = ''; } }}>delete</span>
                                                    </span>}
                                                </div>
                                            </div>}
                                            {siFreelancer && <div className="grp">
                                                <div className="gp">
                                                    <label >Contrat avec<span className='requiredField'>*</span></label>
                                                    <select
                                                        id="idEntrepriseContract"
                                                        name="idEntrepriseContract"
                                                        value={values.idEntrepriseContract || ''}
                                                        onChange={handleChange}
                                                        style={(touched.idEntrepriseContract && errors.idEntrepriseContract) ? { borderColor: "red" } : null}
                                                    >
                                                        <option value="">Veuillez sélectionner</option>
                                                        {entreprises.map((proprietaire) => (
                                                            <option key={proprietaire.idProprietaire} value={proprietaire.idProprietaire}>{proprietaire.raisonSociale}</option>
                                                        ))}
                                                    </select>
                                                    {touched.idEntrepriseContract && errors.idEntrepriseContract ? (
                                                        <div className="invalid-feedback">{errors.idEntrepriseContract}</div>
                                                    ) : null}
                                                </div>
                                                <div className="gp">
                                                    <label >Date de début</label>
                                                    <input
                                                        type="date"
                                                        id="dateDebutEmploi"
                                                        name="dateDebutEmploi"
                                                        value={values.dateDebutEmploi}
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                                <div className="gp">
                                                    <label >Date de fin</label>
                                                    <input
                                                        type="date"
                                                        id="dateFinEmploi"
                                                        name="dateFinEmploi"
                                                        value={values.dateFinEmploi}
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                            </div>}
                                            <div className="grp">
                                                <div className="gp">
                                                    <label >Statut<span className='requiredField'>*</span></label>
                                                    <select
                                                        id="statut"
                                                        name="statut"
                                                        value={values.statut}
                                                        onChange={handleChange}
                                                    >
                                                        {statuts.map((item) => (
                                                            <option key={item.id} value={item.id}>{item.libelle}</option>
                                                        ))}
                                                        {/* <option value="actif" defaultValue={true}>Actif</option>
                                                                        <option value="inactif">Inactif</option>
                                                                        <option value="archivé">Archivé</option> */}
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="cycle-form" id="classification-proprietaire">
                                        <div className="detail-form">
                                            <h3></h3>
                                            <div className="col">
                                                <div className="grp">
                                                    <div className="gp">
                                                        <label>Adresse</label>
                                                        <input type="text"
                                                            id="adresse"
                                                            name="adresse"
                                                            placeholder=""
                                                            defaultValue={values.adresse}
                                                            onChange={handleChange}
                                                        />
                                                    </div>

                                                    <div className="gp">
                                                        <label>Wilaya</label>
                                                        <select
                                                            id="idWilaya"
                                                            name="idWilaya"
                                                            value={values.idWilaya} onChange={handleChange}>
                                                            <option value="">Veuillez sélectionner</option>
                                                            {wilayas.map((wilaya) => (
                                                                <option key={wilaya.idWilaya} value={wilaya.idWilaya}>{wilaya.nomLatin}</option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                    <div className="gp">
                                                        <label>Commune</label>
                                                        <select
                                                            id="idCommune"
                                                            name="idCommune"
                                                            value={values.idCommune} onChange={handleChange}>
                                                            <option value="">Veuillez sélectionner</option>
                                                            {communes.map((commune) => (
                                                                <option key={commune.idCommune} value={commune.idCommune}>{commune.nomLatin}</option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="grp">
                                                    <div className="gp">
                                                        <label>N° téléphone 1</label>
                                                        <input type="text"
                                                            id="numTele1"
                                                            name="numTele1"
                                                            placeholder=""
                                                            defaultValue={values.numTele1}
                                                            onChange={handleChange}
                                                        />
                                                    </div>
                                                    <div className="gp">
                                                        <label>N° téléphone 2</label>
                                                        <input type="text"
                                                            id="numTele2"
                                                            name="numTele2"
                                                            placeholder=""
                                                            defaultValue={values.numTele2}
                                                            onChange={handleChange}
                                                        />
                                                    </div>
                                                    <div className="gp">
                                                        <label>Email</label>
                                                        <input type="email"
                                                            id="email"
                                                            name="email"
                                                            placeholder=""
                                                            defaultValue={values.email}
                                                            style={(touched.email && errors.email) ? { borderColor: "red" } : null}
                                                            onChange={handleChange}
                                                        />
                                                        {touched.email && errors.email ? (
                                                            <div className="invalid-feedback">{errors.email}</div>
                                                        ) : null}
                                                    </div>
                                                </div>
                                                <div className="grp">
                                                    <div className="gp">
                                                        <label>Site Internet</label>
                                                        <input type="text"
                                                            id="siteWeb"
                                                            name="siteWeb"
                                                            placeholder=""
                                                            defaultValue={values.siteWeb}
                                                            onChange={handleChange}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="grp">
                                                    <div className="gp">
                                                        <label>N° registre du commerce</label>
                                                        <input type="text"
                                                            id="nrc"
                                                            name="nrc"
                                                            placeholder=""
                                                            defaultValue={values.nrc}
                                                            onChange={handleChange}
                                                        />
                                                    </div>
                                                    <div className="gp">
                                                        <label>N° compte bancaire</label>
                                                        <input type="text"
                                                            id="numCompteBancaire"
                                                            name="numCompteBancaire"
                                                            placeholder=""
                                                            defaultValue={values.numCompteBancaire}
                                                            onChange={handleChange}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div className="submit-container">
                                        <button type="submit" className="submit-btn">Enregistrer</button>
                                    </div>
                                </div>}
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </section>
    );
}

export default ModifierProprietaire;
